import { DropDownList } from "@progress/kendo-react-dropdowns";
import { CardBody } from "@progress/kendo-react-layout";
import { ListView } from "@progress/kendo-react-listview";
import { renderFrequencyItem } from "components/package/PurchasePackageList";
import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useEffect, useRef } from "react";
import { PackageService } from "services/package/index.service";
import { CurrencyFormat } from "utils";
import {
  PackageFrequency,
  PackageFrequencyEnum,
  ScheduleAppointmentkeys,
  SessionTypeEnum,
} from "utils/form-utils";
import useState from "react-usestateref";
import { Loader } from "@progress/kendo-react-indicators";
import { debounce } from "utils/utils";

interface IPackagesList {
  name: ScheduleAppointmentkeys;
  handleChange: (name: ScheduleAppointmentkeys, val: any) => void;
  ServiceType: "Appointment" | "Group"
}
const PackagesList = ({
  handleChange,
  name,
  ServiceType
}: IPackagesList) => {
  const [packageListData, setPackageListData] = useState({
    pageNumber: 0,
    maxPageNumber: 0,
    packageDataData: [],
  });
  const [searchText, setSearchText, searchTextRef] = useState("")
  const [filteredValue, setFilteredValue, filteredValueRef] = useState(0)
  const packageService = new PackageService();
  const [loading, setLoading] = useState(true);
  const [scrollLoading, setScrollLoading] = useState(false);
  const timeoutRef = useRef(null);
  const packageList = [
    { Name: "All", Id: 0 },
    { Name: "Private", Id: 2 },
    { Name: "Pre-set", Id: 3 },
    { Name: "Semi-Private", Id: 4 }
  ];
  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchServices(0);
      setLoading(false);
    })();
  }, []);

  const handleSearch = (val: any) => {
    setSearchText(val);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      fetchServices(0);
    }, 500); // Debounce delay in milliseconds
  }
  const handleFilter = (val: any) => {
    setFilteredValue(val?.Id)
    fetchServices(0)
  }

  const fetchServices = async (pageNumber: number) => {
    const pageSize = 10;
    let req: any = {
      CanClientBookOnline: false,
      SortBy: "Name",
      SortDirection: "ascending",
      PageNumber: pageNumber,
      PageSize: pageSize,
      // Type: ServiceType==="Group" ? 1 : filteredValueRef?.current ===0 ? 6 : filteredValueRef?.current,

    };

    if (searchTextRef?.current?.length > 2) {
      req = {
        SortDirection: "ascending",
        SortBy: "Name",
        PageNumber: pageNumber,
        PageSize: pageSize,
        Type: ServiceType === "Group" ? 1 : undefined,
        Filters: [
          {
            Field: "Name",
            Operator: "contains",
            FieldDataType: "string",
            Value: searchTextRef.current,
          },
        ],
      };
    }

    if (ServiceType === "Group") {
      req.Type = 1;
    } else if (filteredValueRef.current === 0) {
      req.Type = 6;
    } else if (filteredValueRef.current === 3) {
      req.SessionType = 3;
    } else {
      req.Type = filteredValueRef.current
    }

    if (ServiceType === "Appointment") {
      if (filteredValueRef.current === 0) {
        req.CanShowNonGroupPackageTypes = true;
      } else {
        req.CanShowNonGroupPackageTypes = false;
      }
    }
    if (pageNumber !== 0) {
      setScrollLoading(true)
    }
    const servicedet = await packageService.getAllPackages(req);
    setScrollLoading(false)
    const result = servicedet;
    if (result) {
      if (pageNumber === 0) {
        setPackageListData({
          ...packageListData,
          pageNumber: pageNumber,
          maxPageNumber: result?.TotalItems ? Math.ceil(result?.TotalItems / pageSize) - 1 : 0,
          packageDataData: result?.Items || [],
        });
      }
    }
    return result?.Items || [];
  };

  const MyItemRender = (props: any) => {
    const item = props.dataItem;
    const recurrenceUnit = PackageFrequency?.find(
      (i: any) => i?.id === item?.Frequency
    );
    return (
      <div className="card mb-3 packagelistview pointer appthover" onClick={() => handleChange(name, item)}>
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div>  <h5 className="card-title">{item.Name}</h5></div>
            <div>
              {item?.SessionType === SessionTypeEnum.LIMITED
                ? "Limited"
                : item?.SessionType === SessionTypeEnum.UNLIMITED ? "Unlimited" : "Pre-set"}
            </div>
          </div>
          <div className="d-flex justify-content-between">

            <div className="packagedetails">

              <>
                {item?.SessionType !== SessionTypeEnum.PRESET ? (
                  <>
                    {item?.Frequency === PackageFrequencyEnum.ONETIME ? (
                      <>
                        <span>
                          {item?.ExpireOn === 8 ? "Expire On" : "Expire After"}: <span
                            className="bz-provider"
                            style={{ textTransform: "capitalize" }}
                          >
                            {renderFrequencyItem(item, true)}
                          </span>
                        </span>

                      </>
                    ) : (
                      <>
                        <span className="mb-0">Frequency: <span
                          className="bz-provider"
                          style={{ textTransform: "capitalize" }}
                        >
                          {renderFrequencyItem(item, true)}
                        </span></span>

                      </>
                    )}
                  </>
                ) : (
                  <>
                    {recurrenceUnit?.text !== "ONETIME"
                      ? ` 
                        Recurrence: ${recurrenceUnit?.text
                      }, Every: ${item?.Schedules * item?.Cost === item?.DownPayment
                        ? "NA"
                        : item?.RepeatFrequency
                      }`
                      : `Down Payment: ${CurrencyFormat(
                        item?.Schedules * item?.Cost
                      )}`}
                  </>
                )}
              </>
            </div>
            <div>
              <div className="float-right text-right">
                <span className="rateappts">{CurrencyFormat(item.Cost)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const scrollHandler = async (event: any) => {

    const e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      const check = await requestIfNeeded();
      if (check.status) {
        const moreData = await fetchServices(check.page);
        if (moreData.length > 0) {
          const test = packageListData.packageDataData.concat(moreData);
          setPackageListData({
            ...packageListData,
            packageDataData: test,
            pageNumber: check.page,
          });
        }
      }
    }
  };

  const requestIfNeeded = () => {
    const nextPage = packageListData.pageNumber + 1;
    const maxPageNum = packageListData.maxPageNumber;

    if (nextPage <= maxPageNum) {
      return {
        status: true,
        page: nextPage,
      };
    } else {
      return {
        status: false,
        page: nextPage,
      };
    }
  };
  const debouncedScrollHandler = debounce(scrollHandler, 200);
  return (
    <div>
      <>
        {loading ? (
          <BuzopsLoader type={"list"} />
        ) : (
          <>
            <div className="appointmentheader mb-3">
              <h5 className="bz_fs_1_5 bz-avail-providers">Packages</h5>
              <div className="bz-purchase-class-list pb-0">
                {
                  <div className="d-flex bz-purchase-class-filter mb-0 mt-2d">
                    {ServiceType === "Appointment" && <div className="mb-3 mb-sm-0 mr-sm-3">
                      <DropDownList
                        data={packageList}
                        onChange={(e) => handleFilter(e.target.value)}
                        textField="Name"
                        dataItemKey="Id"
                        id="class"
                        name="class"
                        value={packageList?.find((i: any) => i?.Id === filteredValueRef?.current) || { Name: "All", Id: 0 }}
                      />
                    </div>}
                    <div className="form-group bz-packages-search mb-0 bzo-search-service">
                      <span className="fa fa-search form-control-feedback"></span>
                      <input
                        type={"search"}
                        className="form-control"
                        placeholder={"Find Package"}
                        onChange={(e) => handleSearch(e.target.value)}
                        value={searchText}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
            {packageListData?.packageDataData.length === 0 ? (
              <>{"No Packages Available"}</>
            ) : (
              <div
                style={{ height: "74vh", overflowY: "auto", position: 'relative', paddingRight: '15px' }}
                onScroll={debouncedScrollHandler} className="packagelistoverview"
              >
                <ListView
                  data={packageListData.packageDataData || []}
                  item={MyItemRender}
                  style={{ width: "100%" }}
                />

                {
                  scrollLoading &&

                  <Loader type="converging-spinner" className="laoder-full-screen" />

                }
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};
export default PackagesList;
