import React, { useEffect, useRef, useState } from "react";
import { ClassService } from "services/class/index.service";
import AddClient from "./AddClient";
import { renderFrequencyItem } from "components/package/PurchasePackageList";
import PurchaseClassList from "components/class/PurchaseClassList";
import withNotification from "components/Hoc/withNotification";
import moment from "moment";
import { PackageService } from "services/package/index.service";
import Checkout from "components/checkout/Checkout";
import PurchasePackage from "components/package/PurchasePackage";
import { CheckUserMemberStatus, CurrencyFormat, getFullImageUrl } from "utils";
import { TenantService } from "services/tenant/index.service";
import { Button } from "@progress/kendo-react-buttons";
import PackagesList from "components/Appointments/PackagesList";
import {
  GenderTypeEnum,
  PackageFrequency,
  PackageFrequencyEnum,
  SessionTypeEnum,
} from "utils/form-utils";
import { debounce } from "utils/utils";


const intialViewValues = {
  purchaseGroup: false,
  checkoutGroup: false,
  checkoutPackage: false,
};
function GroupScheduleComponent(props: any) {
  const [viewPage, setViewPage] = useState<any>({
    ...intialViewValues,
    purchaseGroup: true,
  });
  const uuid = props.match?.params?.uuid;
  const SessionSignOff = props?.SessionSignOff || false;
  const [selectedToPurchase, setSelectedToPurchase] = useState<any>(null);
  const [purchasedPackage, setPurchasedPackage] = useState<any>(
    props?.packageSelection?.dataItem || null
  );
  const [packageBillingPeriod, setPackageBillingPeriod] = useState<any>(
    props?.packageSelection?.SelectedBillingPeriod || null
  );
  const [purchasedPackagesData, setPurchasedPackagesData] = useState<any>([]);
  const [selectedRadioValue, setSelectedRadioValue] = useState("");
  const [groupViewCheckoutState, setGroupViewCheckoutState] =
    useState<any>(null);
  const [packageViewCheckoutState, setPackageViewCheckoutState] =
    useState<any>(null);
  const [isClientSelected, setIsClientSelected] = useState<boolean>(false);
  const [clientsList, setClientsList] = useState<any>([]);
  const [addMoreSessions, setAddMoreSessions] = useState<any>([]);
  const [clientSearchLoading, setClientSearchLoading] = useState(false);
  const [clientFormValues, setClientFormValues] = useState<any>({});
  const [userDetails, setUserDetails] = useState<any>(
    props?.userDetails || undefined
  );
  const [clientSubmitBtnLoading, setClientSubmitBtnLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState<any>({
    type: "search",
    value: "",
  });
  useEffect(() => {
    fetchUserSession();
  }, []);

  useEffect(() => {
    if (userDetails?.UserMemberId) {
      fetchPackageSummary();
    } else {
      handleClear();
    }
  }, [userDetails]);

  const handleClear = () => {
    if(SessionSignOff){
      props?.handleChangePackage()
    }else{
      setPurchasedPackage(null);
      setSelectedToPurchase(null);
      setAddMoreSessions([]);
      setPackageViewCheckoutState(null);
      setGroupViewCheckoutState(null);
      handleChangeScreen("purchaseGroup", true);
    }
  };

  const fetchUserSession = async () => {
    if (uuid) {
      const service = new ClassService();
      await service.getTenantDetails(uuid);
    }
  };

  const handleGroupCheckoutProps = (obj: any) => {
    setGroupViewCheckoutState({
      state: { ...obj, from: "calender" },
    });
    handleChangeScreen("checkoutGroup", true);
  };

  const handlePackageCheckoutProps = (obj: any) => {
    setPackageViewCheckoutState({
      state: { ...obj, from: "calender" },
    });
    handleChangeScreen("checkoutPackage", true);
  };

  async function autoCompleteClients(str: string) {
    const req = {
      SearchKeyword: str,
    };
    setClientSearchLoading(true);
    const clientservice = new ClassService();
    const result = await clientservice.searchClient(req);
    setClientSearchLoading(false);

    setClientsList(result);
  }

  const debouncedAutoCompleteClients = useRef(
    debounce((value: string) => autoCompleteClients(value), 400)
  ).current;

  const handleClientSubmit = async (dataItem: any) => {
    setClientFormValues(dataItem);
  };

  // handle client selection for class
  const handleClientSelecton = (e: any) => {
    if (e.value.isButton) {
      return;
    }
    if (e.syntheticEvent?.key === "Enter") {
      if (clientsList?.length > 0) {
        setSelectedClient({
          ...selectedClient,
          type: "select",
          value: clientsList[0],
        });
      }
      return true;
    }
    setSelectedClient({
      ...selectedClient,
      type: "search",
      value: e.value,
    });
    if (e.syntheticEvent?.type === "change" && e.value.length >= 3) {
      debouncedAutoCompleteClients(e.value);
    }
    if (e.value.length === 0) {
      setUserDetails(undefined);
    }
    if (e.syntheticEvent?.type === "click") {
      setSelectedClient({
        ...selectedClient,
        type: "select",
        value: e.value,
      });
      const result = e?.value;
      if (result) {
        const clientIntialValues = {
          Email: result?.Email || null,
          FirstName: result?.FirstName || null,
          FullName: result?.FullName || null,
          LastName: result?.LastName || null,
          MemberNumber: result?.MemberNumber || null,
          Phone: result?.Phone || null,
          UserMemberId: result?.UserMemberId || 0,
          UserId: result?.UserId || 0,
          PrimaryMemberId: result?.MainMemberId,
          Status: result?.Status,
          Photo: result?.Photo,
        };
        if (!CheckUserMemberStatus(clientIntialValues)) {
          props?.handleNotificationMessage(
            "Cannot Perform this action until Charge Back (Credit card)/Return (ACH) dispute is resolved. Please contact administrator.",
            "error"
          );
          return;
        }
        setUserDetails(clientIntialValues);
        setIsClientSelected(true);
      } else {
        setUserDetails(undefined);
      }
    }
  };

  const handleAddClient = async (dataItem: any) => {
    const req = {
      FirstName: dataItem?.FirstName,
      LastName: dataItem?.LastName,
      Phone: dataItem?.PhoneNumber,
      Email: dataItem?.Email,
      SalesPersonId: 0,
      UserId: dataItem?.UserId,
      ReferredFrom: dataItem?.ReferredFrom.ReferredFrom,
      SpecifyOthers: dataItem?.ReferredFrom.ReferredFrom==="Other"? dataItem?.SpecifyOthers:null,
      Gender:dataItem?.Gender?.id,
      GenderSpecifyOthers:
        dataItem?.Gender?.id === GenderTypeEnum.Others
          ? dataItem?.GenderSpecifyOthers
          : null,
      IsPrimaryClub:dataItem?.IsPrimaryClub,
    };
    setClientSubmitBtnLoading(true);
    const checkoutClass = new ClassService();
    const result = await checkoutClass.addClient(req);
    if (result.ResponseCode === 100) {
      const userMemberId = result?.Item?.UserMemberId;
      setUserDetails(result?.Item);
      setSelectedRadioValue("pick");
      const addedClient = {
        Email: result?.Item.Email,
        MemberNumber: result?.Item.MemberNumber,
        FullName: `${dataItem.FirstName} ${dataItem.LastName}`,
        MainMemberId: result?.Item.UserMemberId,
        UserId: result?.Item.UserId,
        UserMemberId: result?.Item.UserMemberId,
        FirstName: result?.Item.FirstName,
        LastName: result?.Item.LastName,
        Status: result?.Item.Status,
        Photo: result?.Item?.Photo,
      };
      setSelectedClient({
        ...selectedClient,
        type: "select",
        value: addedClient,
      });
      setUserDetails(addedClient);
      setIsClientSelected(true);
      const successMsg = "Client Added Successfully";
      props?.handleNotificationMessage(successMsg, "success");
    } else {
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
    setClientSubmitBtnLoading(false);
  };

  const handleChangeScreen = (screen: string, val: boolean) => {
    if (screen === "checkoutPackage" && !val) {
      setViewPage({
        ...intialViewValues,
        [screen]: val,
        purchasePackage: true,
      });
    } else if (screen === "purchasePackage" && !val) {
      setViewPage({ ...intialViewValues, [screen]: val, purchaseGroup: true });
    } else {
      setViewPage({ ...intialViewValues, [screen]: val });
    }
  };

  const handleClose = (session: any) => {
    setAddMoreSessions(session);
    handleChangeScreen("purchaseGroup", true);
  };
  const handleClosePackage = (dataItem: any = null) => {
    setPurchasedPackage(null);
    setSelectedToPurchase(null);
    setPackageViewCheckoutState(null);
    handleChangeScreen("purchaseGroup", true);
    if (dataItem) {
      fetchPackageSummary(dataItem);
    } else {
    }
  };
  const unloadFunc = () => {
    // setAddMoreSessions([])
  };

  const fetchPackageSummary = async (dataItem: any = null) => {
    let startDate = moment(new Date()).format("YYYY-MM-DD") + "T00:00:00";
    let ToDate = moment().add(1, "year").format("YYYY-MM-DD") + "T23:59:59";
    if (props?.selectedPackage) {
      startDate =
        moment(props?.selectedPackage?.StartOn).format("YYYY-MM-DD") +
        "T00:00:00";
      ToDate =
        moment(props?.selectedPackage?.StartOn)
          .add(1, "year")
          .format("YYYY-MM-DD") + "T23:59:59";
    }
    const req = {
      UserMemberId: userDetails?.UserMemberId,
      FromDate: startDate,
      ToDate,
      PackageType: "Class",
      IsQuickSignOff: SessionSignOff ? true : undefined
    };
    const service = new PackageService();
    const result = await service.packageInstanceToUse(req);
    setPurchasedPackagesData(result || []);
    if (dataItem) {
      const findItem = result?.find(
        (i: any) => i?.PackageInstanceId === parseInt(dataItem)
      );
      setPurchasedPackage(findItem);
    }
  };
  const [height, setHeight] = useState(window.innerHeight * 0.82);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight * 0.82);
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isLargeScreen = viewportWidth >= 976;

  const handleSelection = (selectedItem: any, Category: string) => {
    if (!CheckUserMemberStatus(userDetails)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    if (Category === "UnPurchased") {
      setSelectedToPurchase(selectedItem);
      handlePackageCheckoutProps({
        package: selectedItem,
        staffPage: true,
        userData: userDetails,
        uuid: uuid,
      });
    } else {
      setPurchasedPackage(selectedItem);
      handleChangeScreen("purchaseGroup", true);
    }
  };

  async function fetchClientData(userMemberId: any) {
    const clientservice = new TenantService();
    const result = await clientservice.getClient(userMemberId);
    if (result) {
      const clientIntialValues = {
        FirstName: result?.User?.FirstName,
        LastName: result?.User?.LastName,
        PhoneNumber: result?.User?.Address?.Phone,
        Email: result?.User?.Email,
      };
      const clientSubmitDetails = {
        Email: result?.User?.Email,
        FirstName: result?.User?.FirstName,
        LastName: result?.User?.LastName,
        Photo: result?.User?.Photo,
        PrimaryMemberId: result?.UserMemberId,
        FullName: `${result?.User.FirstName} ${result?.User.LastName}`,
        UserId: result?.UserId,
        UserMemberId: result?.UserMemberId,
        MemberNumber: result?.MemberNumber,
        Status: result?.Status,
      };
      setUserDetails(clientSubmitDetails);
      setClientFormValues(clientIntialValues);
      setSelectedRadioValue("pick");
      const addedClient = {
        Email: result?.User?.Email,
        MemberNumber: result?.User?.MemberNumber,
        FullName: `${result?.User?.FirstName} ${result?.User?.LastName}`,
        MainMemberId: result?.User?.UserMemberId,
        UserId: result?.User?.UserId,
        UserMemberId: result?.User?.UserMemberId,
        FirstName: result?.User?.FirstName,
        LastName: result?.User?.LastName,
        Photo: result?.User?.Photo,
      };
      setSelectedClient({
        ...selectedClient,
        type: "select",
        value: addedClient,
      });
    }
  }

  const handleRadioButtonChange = () => {
    setSelectedRadioValue("new");
  };

  const handleChangeClient = () => {
    if (!SessionSignOff) {
      setIsClientSelected(false);
      setSelectedClient({
        type: "search",
        value: "",
      });
    } else {
      props?.handleChangeClient();
    }
  };
  const handleChangePackage = () => {

      handleChangeScreen("checkoutPackage", false);
      setSelectedToPurchase(null);
    
  };
  const renderPurchasePackage = () => {
    const recurrenceUnit = PackageFrequency?.find(
      (i: any) => i?.id === selectedToPurchase?.Frequency
    );
    return (
      <div className="mb-3 border clientborder  p-3">
        <div className="d-flex justify-content-end">
          <span
            className="btn btn-link"
            onClick={() => {
              handleChangePackage();
            }}
          >
            Change Package
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <span className="appttitle">{selectedToPurchase?.Name}</span>
            <br />
            {selectedToPurchase?.SessionType !== SessionTypeEnum.PRESET ? (
              <>
                {selectedToPurchase?.Frequency ===
                PackageFrequencyEnum.ONETIME ? (
                  <>
                    <span>
                      {selectedToPurchase?.ExpireOn === 8
                        ? "Expire On"
                        : "Expire After"}
                      :{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {renderFrequencyItem(selectedToPurchase, true)}
                      </span>
                    </span>
                  </>
                ) : (
                  <>
                    <span>Frequency</span>
                    <span
                      className="bz-provider"
                      style={{ textTransform: "capitalize" }}
                    >
                      {renderFrequencyItem(selectedToPurchase, true)}
                    </span>
                  </>
                )}
              </>
            ) : (
              <>
                {recurrenceUnit?.text !== "ONETIME"
                  ? `Down Payment: ${CurrencyFormat(
                      selectedToPurchase?.DownPayment
                    )}, Installments: ${
                      selectedToPurchase?.Occurrence === 0
                        ? "NA"
                        : selectedToPurchase?.Occurrence
                    }, Recurrence Unit: ${
                      recurrenceUnit?.text
                    }, Recurring every: ${
                      selectedToPurchase?.Schedules *
                        selectedToPurchase?.Cost ===
                      selectedToPurchase?.DownPayment
                        ? "NA"
                        : selectedToPurchase?.RepeatFrequency
                    }`
                  : `Down Payment: ${CurrencyFormat(
                      selectedToPurchase?.Schedules * selectedToPurchase?.Cost
                    )}`}
              </>
            )}
          </div>
          <div>
            {selectedToPurchase?.SessionType === 1 ? "Limited" : "Unlimited"}
          </div>
        </div>
      </div>
    );
  };

  const handleSelectPackageBillingPeriod = (value: any) => {
    setPackageBillingPeriod(value);
  };

  const renderPurchasedPackage = () => {
    const selectedPack =
      purchasedPackagesData?.find(
        (i: any) => i?.PackageInstanceId === purchasedPackage?.PackageInstanceId
      ) || purchasedPackage;
    const billingPeriods =
      selectedPack?.SessionType === SessionTypeEnum.LIMITED
        ? selectedPack?.BillingPeriods?.filter(
            (i: any) => i?.SchedulesToUse > 0
          ) || []
        : selectedPack?.BillingPeriods;
    const findItem = packageBillingPeriod;

    return (
      <div className="mb-3 border p-3 clientborder">
        <div className="d-flex justify-content-between">
          <div>
            <span className="appttitle">{selectedPack?.Name}</span>
            {SessionSignOff ? (
              <>
                {/* <DropDownList
                  data={billingPeriods}
                  value={findItem}
                  onChange={(event) =>
                    handleSelectPackageBillingPeriod(event.value)
                  }
                  textField="ExpireFrom"
                  dataItemKey="ExpireFrom"
                  itemRender={(
                    li: React.ReactElement<HTMLLIElement>,
                    itemProps: ListItemProps
                  ) => billingPeriodItemRender(li, itemProps, selectedPack)}
                  valueRender={(
                    el: React.ReactElement<HTMLSpanElement>,
                    value: any
                  ) =>
                    billingPeriodValueRender(el, value, findItem, selectedPack)
                  }
                  style={{ width: "100%" }}
                  className={"customDropdown bz-oneonone-dropdown"}
                  listNoDataRender={() => (
                    <span className={"bzo-noData-found"}>
                      No unused sessions available for sign-off
                    </span>
                  )}
                /> */}
              </>
            ) : (
              <>
                <br />
                Redeemed:{" "}
                <small>
                  {selectedPack.SessionType === 2
                    ? "Unlimited"
                    : `${selectedPack?.SchedulesUsed} of ${
                        selectedPack?.SchedulesToUse +
                        selectedPack?.SchedulesUsed
                      }`}
                </small>
                <br />
                <small className="package-expiry">
                  {`${
                    selectedPack?.ExpireFrom === null
                      ? "N/A"
                      : moment(selectedPack?.ExpireFrom).format("MM/DD/YYYY")
                  } to ${
                    selectedPack?.ExpireOn === null ||
                    selectedPack?.SessionType === SessionTypeEnum.PRESET
                      ? "N/A"
                      : moment(selectedPack?.ExpireOn).format("MM/DD/YYYY")
                  }`}
                </small>
              </>
            )}
          </div>
          <div>
            {selectedPack?.SessionType === SessionTypeEnum.LIMITED
              ? "Limited"
              : selectedPack?.SessionType === SessionTypeEnum.UNLIMITED
              ? "Unlimited"
              : "Preset"}
          </div>
        </div>
        <div className="d-flex justify-content-end">
          {(props?.calendarPage || props?.quicklinks) && <span className="btn btn-link" onClick={() => handleClear()}>
            Change Package
          </span>}
        </div>
      </div>
    );
  };

  const handleCloseCheckout=(val=false)=> {
    if(props?.SessionSignOff || props?.purchaseGroup){
      if(val){
        setPurchasedPackage(null);
        setSelectedToPurchase(null);
        setAddMoreSessions([]);
        setPackageViewCheckoutState(null);
        setGroupViewCheckoutState(null);
      }
      handleChangeScreen("purchaseGroup", true);
    }else{
      props?.handleWindow()
    }
  }
  const renderClientDetails = () => {
    return (
      <div className="d-flex align-items-center mb-3 border clientborder p-3">
        <img
          width={38}
          height={38}
          alt={"userImage"}
          style={{ borderRadius: "50%" }}
          src={
            userDetails?.Photo
              ? getFullImageUrl(userDetails?.Photo)
              : "/images/user.png"
          }
        />
        <div
          className="ml-3 d-flex justify-content-between"
          style={{ width: "100%" }}
        >
          <div>
            <span className="appttitle">{userDetails?.FullName}</span>
            <br />
            {(props?.calendarPage || props?.quicklinks) &&  <span className="btn btn-link" onClick={() => handleChangeClient()}>
              Change Client
            </span>}
          </div>
        </div>
      </div>
    );
  };

  if (!isClientSelected && !SessionSignOff && !props?.purchaseGroup) {
    return (
      <div
        className="d-flex justify-content-center align-items-center flex-column searchboxclientgroup"
        style={{ width: "100%" }}
      >
        <AddClient
          page={"inhouse-class"}
          disabled={false}
          clientFormValues={clientFormValues}
          clientsList={clientsList}
          handleClientSubmit={handleClientSubmit}
          clientSubmitBtnLoading={clientSubmitBtnLoading}
          clientSearchLoading={clientSearchLoading}
          handleAddClient={handleAddClient}
          selectedClient={selectedClient}
          selectedradioValue={selectedRadioValue}
          handleClientSelecton={(val: any) => handleClientSelecton(val)}
          checkout={false}
          fetchClientData={fetchClientData}
          onChangeRadioValue={handleRadioButtonChange}
          width={"400px"}
        />
      </div>
    );
  }

  return (
    <div>
      <div
        className="row scheduleappts mx-2 mt-3 groupapptssettings"
        style={{ gap: "15px" }}
      >
        <div
          className="col-md-4 border-client h80vh"
          style={{ height: isLargeScreen ? `${height}px` : "auto" }}
        >
          <div className="d-flex flex-column mt-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="card-title bz-text-capitalize bz-fw-6 mb-0 ftclient">
                Client
              </h5>
              {!SessionSignOff && (
                <div>
                  {!viewPage?.purchasePackage ? (
                    <Button
                      onClick={() =>
                        handleChangeScreen("purchasePackage", true)
                      }
                      className="k-button mr-0 bnt-radiusaddpackage noaddpck"
                    >
                      <i className="fa fa-plus"></i> Add Package
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        handleChangeScreen("purchasePackage", false)
                      }
                      className="k-button btn-sm bnt-radiusaddpackage  k-primary mr-0"
                    >
                      <i className="fa fa-plus"></i> Add Package
                    </Button>
                  )}
                </div>
              )}
            </div>
            {renderClientDetails()}
            {selectedToPurchase && renderPurchasePackage()}
            {!props?.showOnlySignoffAppts && purchasedPackage && renderPurchasedPackage()}
          </div>
        </div>
        <div
          className="col-md-8 groupappadmin purchasesummaryappts"
          style={{
            height: isLargeScreen ? `${height}px` : "auto",
            flexGrow: "1",
          }}
        >
          {viewPage?.purchaseGroup && (
            <div className="purchasegrouplisting">
              <PurchaseClassList
                from="calender"
                staffPage={true}
                selectedPackage={purchasedPackage}
                packageBillingPeriod={packageBillingPeriod}
                handleGroupCheckoutProps={handleGroupCheckoutProps}
                userData={userDetails}
                addMoreFunctionality={true}
                SessionSignOff={SessionSignOff}
                selectedClasses={addMoreSessions}
                unloadFunc={unloadFunc}
                handleSelectPackageBillingPeriod={handleSelectPackageBillingPeriod}
                isDialogVisible={props?.isDialogVisible || false}
                showOnlySignoffAppts={props?.showOnlySignoffAppts}
              />
            </div>
          )}
          {viewPage?.purchasePackage ? (
            <PackagesList
              name={"PurchasePackage"}
              ServiceType={"Group"}
              handleChange={(name: string, val: any) => {
                handleSelection(val, "UnPurchased");
              }}
            />
          ) : // <PurchasePackageList page={"inhouse"} from="calender" serviceType={1} userDetails={userDetails} staffPage={true} />
          null}
          {viewPage?.checkoutGroup ? (
            <Checkout
              location={groupViewCheckoutState}
              handleWindow={handleCloseCheckout}
              handleClose={handleClose}
              SessionSignOff={SessionSignOff}
            />
          ) : null}
          {viewPage?.checkoutPackage ? (
            // <Checkout
            //   location={packageViewCheckoutState}
            //   handleWindow={props?.handleWindow}
            //   handleClose={handleClosePackage}
            // />
            <PurchasePackage
              page="Package"
              appointmentpage={true}
              package={packageViewCheckoutState?.state?.package}
              packageDatafromAppointment={
                packageViewCheckoutState?.state?.package
              }
              userDatafromAppointment={userDetails}
              onChangePurchaseScreen={handleClosePackage}
              staffPage={true}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default withNotification(GroupScheduleComponent);
