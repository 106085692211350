import { FieldWrapper, FormElement } from "@progress/kendo-react-form";
import { PackagePresetFrequency, recurrenceUnits } from "utils/form-utils";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Label, Error } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { CurrencyFormat, checkHasTaxEnable } from "utils";
import moment from "moment";

export const customDateCell = (props: any) => {
  return (
    <td className={"capitalize"} >
      {moment(props.dataItem.DisplayDate).format("MM/DD/YYYY")}
    </td>
  );
};
export const InstallmentAmtCell = (props: any) => {
  return (
    <td className={"capitalize"}>
      {CurrencyFormat(props.dataItem.InstallmentAmount.toFixed(2))}
    </td>
  );
};

const PreSetDownpaymentForm = (props: any) => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const nextYear = new Date(today);
  nextYear.setFullYear(tomorrow.getFullYear() + 1);

  let ispaidTodayValid = true;
  const {
    paidToday,
    recurrenceUnit,
    recurringStartDate,
    OcurrsEvery,
    installments,
  } = props.downpaymentForm;
  if (paidToday === undefined || paidToday === null || paidToday < 0) {
    ispaidTodayValid = false;
  }
  let disabled = !props.packageData.CanClientOverrideOccurence;
  if (props?.staffPage) {
    disabled = false;
  }

  console.log('props', props);


  const showGrid = () => {
    const CustomDateHeaderCell = (props: any) => (
      <td style={{ padding: '0px', fontWeight: 'bold', borderBottomWidth: 0 }}>
        {props.title}
      </td>
    );

    const CustomInstallmentAmtHeaderCell = (props: any) => (
      <td style={{ padding: '0px', fontWeight: 'bold', borderBottomWidth: 0 }}>
        {props.title}
      </td>
    );

    if (props.tableData.length > 0) {
      let InstallmentAmountTitle = "Amount";
      if (checkHasTaxEnable() && props?.packageData?.CanCollectTaxes && !props?.IsTaxSkipped) {
        InstallmentAmountTitle = `Amount  (Surcharges Applicable)`;
      }

      return (
        <div className={"installemt-grid"}>
          <Grid
            data={props.tableData}
            style={{ maxHeight: "200px", border: 0 }} className="reccurencepacakagetable"
          >
            <Column field="DisplayDate" title={"Dates"} cell={customDateCell} headerCell={CustomDateHeaderCell} />
            <Column
              field="InstallmentAmount"
              title={InstallmentAmountTitle}
              cell={InstallmentAmtCell}
              headerCell={CustomInstallmentAmtHeaderCell}
            />
          </Grid>
        </div>
      );
    }
  };
  return (
    <>
      <FormElement style={{ width: "100%" }}>
        <div className="col-12 paddingpackage">
          <div className="row">
            <div className="col-12">
              <FieldWrapper>
                <Label editorId={"paidToday"} editorValid={ispaidTodayValid}>
                  Amount Pay Today
                </Label>
                <NumericTextBox
                  id={"paidToday"}
                  valid={ispaidTodayValid}
                  value={paidToday}
                  disabled={disabled}
                  format="c2"
                  min={0}
                  onChange={(e) =>
                    props.handleDownPayment(e.value, "paidToday")
                  }
                />
                {!ispaidTodayValid && <Error>Please enter amount.</Error>}
              </FieldWrapper>
            </div>
          </div>
        </div>
        {props.showinstallmentForm && (
          <>
            <div className="col-12 paddingpackage">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <FieldWrapper>
                    <Label editorId={"recurrenceUnit"}>Recurrence Unit</Label>
                    <DropDownList
                      data={PackagePresetFrequency}
                      textField="text"
                      dataItemKey="id"
                      disabled={disabled}
                      value={recurrenceUnit}
                      onChange={(e) =>
                        props.handleDownPayment(e.value, "recurrenceUnit")
                      }
                    />
                    {!recurrenceUnit && (
                      <Error>Please provide Recurrence Unit.</Error>
                    )}
                  </FieldWrapper>
                </div>
                <br />
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <FieldWrapper>
                    <Label editorId={"OcurrsEvery"}>Recurring Every</Label>
                    <NumericTextBox
                      id={"OcurrsEvery"}
                      value={OcurrsEvery}
                      disabled={disabled}
                      step={1}
                      min={1}
                      onChange={(e) =>
                        props.handleDownPayment(e.value, "OcurrsEvery")
                      }
                    />
                    {OcurrsEvery <= 0 && (
                      <Error>Please enter Recurrence.</Error>
                    )}
                  </FieldWrapper>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <FieldWrapper>
                    <Label editorId={"installments"}>No of installments</Label>
                    <NumericTextBox
                      id={"installments"}
                      value={installments}
                      step={1}
                      disabled={disabled}
                      min={1}
                      max={999}
                      onChange={(e) =>
                        props.handleDownPayment(e.value, "installments")
                      }
                    />
                    {installments <= 0 && (
                      <Error>Please enter Intallments.</Error>
                    )}
                  </FieldWrapper>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <FieldWrapper>
                    <Label editorId={"recurringStartDate"}>
                      Recurring Start Date
                    </Label>
                    <DatePicker
                      id={"recurringStartDate"}
                      name={"recurringStartDate"}
                      min={tomorrow}
                      disabled={disabled}
                      max={nextYear}
                      value={recurringStartDate}
                      onChange={(e) =>
                        props.handleDownPayment(e.value, "recurringStartDate")
                      }
                    />
                    {!recurringStartDate && (
                      <Error>Please enter recurring start date</Error>
                    )}
                  </FieldWrapper>
                </div>
                {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <FieldWrapper>
                    <Label editorId={"RecurringAmount"}>Recurring Amount</Label>
                    <NumericTextBox
                      id={"RecurringAmount"}
                      value={props?.recurringAmount}
                      disabled={true}
                      format="c2"
                      min={0}
                    />
                  </FieldWrapper>
                </div> */}
              </div>
            </div>
          </>
        )}
        <br />
      </FormElement>
      {props.showinstallmentForm && showGrid()}
    </>
  );
};

export default PreSetDownpaymentForm;
