import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridItemChangeEvent,
} from "@progress/kendo-react-grid";
import { Badge, BadgeContainer, Loader } from "@progress/kendo-react-indicators";
import {
  MaskedTextBox,
  MaskedTextBoxChangeEvent,
  Switch,
} from "@progress/kendo-react-inputs";
import { Card, CardBody } from "@progress/kendo-react-layout";
import withNotification from "components/Hoc/withNotification";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import { CurrencyFormat, checkHasTaxEnable, getFullImageUrl } from "utils";
import { AuditType, CreditsErrorMessage, cardTypes } from "utils/form-utils";
import images from "utils/images";
import AppointmentTabs from "./AppointmentTabs";
import CreateClient from "./clientCreation/CreateClient";
import ClientNameWithMemberType from "./ClientNameWithMemberType";
import DeclinedPayments from "./DeclinedPayments";
import MyCommandCell from "./MyCommandCell";
import bzImages from "../../Images/index";
import { Tooltip } from "@progress/kendo-react-tooltip";
import ManageNotifications from "./ManageNotifications";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import { BsCCircleFill } from "react-icons/bs";
import { showWaived } from "utils/permission-utils";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import PrimaryMember from "../../PrimaryMember.png";
import { MdLocationPin } from "react-icons/md";
import { SimpleAccessService } from "services/simpleAccess/index.service";
import useSecuritySystemConfigs from "components/CheckIns/Container/SecuritysystemConfigs.hook";
import useCheckinUser from "components/CheckIns/Container/CheckinUser.hook";
import { CheckInService } from "services/checkin/index.service";
import DialogPopup from "atoms/DialogPopup/DialogPopup";
import CheckinUser from "components/CheckIns/CheckinUser";
import { CheckinFrom, CheckinMode } from "components/CheckIns/Utils/constants";
import { useApiClient } from "services/axios-service-utils";
export const renderCardIcon = (item: any, cardId: any, CardTypeId: number) => {
  if (CardTypeId === 0) {
    return (
      <span key={"fa fa-university"}>
        <i className={"fa fa-university mr-1"} aria-hidden="true"></i>
      </span>
    );
  } else if (CardTypeId === -1) {
    return (
      <span key={"fa fa-dollar-sign"}>
        <i className="fa fa-dollar-sign mr-1" aria-hidden="true"></i>
      </span>
    );
  } else if (CardTypeId === 8) {
    return (
      <span title={"Credit"} className="mr-1">
        <BsCCircleFill style={{ fontSize: "1rem", color: "#008db9" }} />
      </span>
    );
  } else {
    return images?.[cardId] ? (
      <span>
        <img width={20} src={images[cardId]} className="" alt="card" />
      </span>
    ) : (
      <span></span>
    );
  }
};

const CellForMobileValidation = (props: GridCellProps) => {
  const handleChange = (e: MaskedTextBoxChangeEvent) => {
    if (props.onChange) {
      props?.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };
  if (props.dataItem.inEdit) {
    return (
      <td>
        <MaskedTextBox
          className="FamilyMemberEdit"
          width={"100%"}
          mask="(999) 000-0000"
          value={props.dataItem.Phone}
          onChange={handleChange}
        />
      </td>
    );
  } else {
    return <td>{props.dataItem.Phone}</td>;
  }
};
function Overview(props: any) {
  const {
    isOpenPathSystem,
    isBarcodeSystem,
    isBrivoPassportPathSystem,
  } = useSecuritySystemConfigs();
  const {
    checkIfUserCanCheckinForBarcode,
    checkIfUserCanCheckinForOpenPath,
    checkIfUserCanCheckinForBrivoPassPort,
  } = useCheckinUser(props)
  if (typeof Node === "function" && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error(
            "Cannot remove a child from a different parent",
            child,
            this
          );
        }
        return child;
      }
      return originalRemoveChild.apply(this, arguments);
    };
  }
  const editField = "inEdit";
  const payNowRef: any = useRef(null);
  const declinedPayRef: any = useRef(null);
  const [addClient, setAddClient] = useState(false);
  const [manageNotifications, setManageNotifications] = useState(false);
  const [insightData, setInsightData] = useState<any>(null);
  const [originalInsightData, setOriginalInsightData] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);
  const [inSightloading, setinSightLoading] = useState<any>(true);
  const [btnloading, setBtnLoading] = useState<any>(false);
  const [checkinloading, setcheckinLoading] = useState<any>(false);

  const showMoreRef = useRef<any>();
  const [selected, setSelected] = React.useState(0);
  const [showDialog, setShowDialog] = useState<any>(false);
  const [showPayNowDialog, setShowPayNowDialog] = useState<any>(false);
  const [paynowItem, setPayNowItem] = useState<any>(null);
  const [selectedPaymentProfile, setSelectedPaymentProfile] =
    useState<any>(undefined);
  const [paymentProfile, setPaymentProfile] = useState<any>([]);
  const [declinedPayments, setDeclinedPayments] = useState<any>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [userSession, setUserSession] = useState<any>(props?.userSession);
  const [selfkioskdialog, setselfkioskdialog] = useState(false);
  const [AllClientsList, setAllClientsList] = useState<any>([]);
  const [selectedUsermemberId, setSelectedUsermemberId] = useState(props?.userData?.UserMemberId);
  const[userData,setUserData]=useState(props?.userData);
  const [clientSelectDialog, setclientSelectDialog] = useState(false);
  const uuid = props.match?.params?.uuid;
  const {axiosRequest}=useApiClient()
  const service = new TenantService();
const {
  EmergencyContactPerson,
  EmergencyRelation,
  EmergencyEmail,
  EmergencyContactNumber,
} = props?.userData || {};
const showEmergenyContactInfo =
  EmergencyContactPerson ||
  EmergencyRelation ||
  EmergencyEmail ||
  EmergencyContactNumber;
  const handleSelect = (val: any) => {
    setSelected(val);
  };


  useEffect(() => {
    fetchAllApis();
  }, [props?.userData]);

  useEffect(() => {
    if (paynowItem) {
      payNowRef?.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      declinedPayRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [paynowItem]);

  const handleClose = () => {
    setAddClient(false);
    setManageNotifications(false);
    fetchAllApis();
  };

  const handleReload = (val: any) => {
    if (val === true) {
      fetchAllApis();
    }
  };

  
  const makePaymentProfileAsDefault = async (dataItem: any) => {
    const req = {
      PaymentGatewayPaymentProfileID: dataItem?.PaymentGatewayPaymentProfileID,
      UserMemberId: props?.userData?.UserMemberId,
      CustomerProfileID:
        dataItem?.CustomerProfileID,
    };
    await axiosRequest.post(
      `PaymentProfile/update-preferred-payment-profile`,
      req,
      {
        successCallBack: async (response: any) => {
          props?.handleNotificationMessage(
            `Selected Payment profile marked as Preferred`,
            "success"
          );
          await fetchPaymentProfiles(props?.userData?.UserMemberId);
        },
        errorCallBack: (response: any) => {
          props?.handleNotificationMessage(
            response?.response?.data?.Messages?.[0] ||
              response?.data?.Messages?.[0] ||
              "Internal server error",
            "error"
          );
        },
      }
    );
  };

  const getAllSubMembers = async (memberId: any) => {
    const tenantService = new TenantService();
    let selectedUserMemberId1 = memberId;
    let parentMemberId = memberId;
    if (props?.location?.state?.userData?.PrimaryMemberId) {
      parentMemberId = props?.location?.state?.userData?.PrimaryMemberId;
    } else if (props?.userDatafromAppointment?.PrimaryMemberId) {
      parentMemberId = props?.userDatafromAppointment?.PrimaryMemberId;
    } else if (props?.location?.state?.userData?.MainUserId) {
      parentMemberId = props?.location?.state?.userData?.MainUserId;
    }
    const res = await tenantService.GetSubMembersWithParent(parentMemberId);
    setAllClientsList(res);
 
    const selUserData = getCurrentClientData(res, selectedUserMemberId1);


    return selUserData?.ParentId || selUserData?.UserMemberId;
  };

  const getCurrentClientData = (allUsersData: any, userMemberId: any) => {
    return (
      allUsersData?.find((val: any) => val.UserMemberId === userMemberId) || {}
    );
  };


  const handleToggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  console.log('props',props);
  const fetchAllApis = async () => {
    let memberId = props?.userData?.UserMemberId;
    if (memberId) {
      setLoading(true);
      fetchInsights(memberId);
      fetchDeclinedPayments();
      fetchPaymentProfiles(memberId);
      getAllSubMembers(memberId);
      setLoading(false);
    }
  };

  const toggleDialog = () => {
    setclientSelectDialog(false);
  };

  async function updateSelectedClientData(userDatas: any) {
    const result = userDatas;
    let clientSubmitDetails = {
      Email: result?.Email,
      FirstName: result?.FirstName,
      LastName: result?.LastName,
      UserId: result?.UserId,
      UserMemberId: result?.UserMemberId,
      MemberNumber: result?.MemberNumber,
      Status: result?.UserMemberStatus || result?.Status,
      MainUserId:result?.ParentId === null ? result?.UserMemberId : result?.ParentId,
      Photo:result?.Photo,
      DateOfBirth:result?.DateOfBirth,
      Phone:result?.Phone,
      MemberType:result?.MemberType,
      PrimaryUserMemberId:result?.ParentId,
      FullName:result?.FullName,
    };
    setUserData(clientSubmitDetails);
    setSelectedUsermemberId(result?.UserMemberId);
}

  console.log('userData',userData);

  const ClientDialog = () => {
    return (
      <>
        <Dialog title={"Select Client for Check-in"} onClose={toggleDialog}>
          <p
            style={{
              margin: "0",
              width: "350px",
              textAlign: "center",
              fontSize: "16px",
            }}
            className="bz-addNotes-textarea"
          >
            <DropDownList
              data={AllClientsList}
              textField="FullName"
              dataItemKey="UserMemberId"
              onChange={(e) => {
                updateSelectedClientData(e.value);
              }}
              disabled={props?.disabled}
              value={
                AllClientsList?.filter(
                  (res: any) => res?.UserMemberId === selectedUsermemberId
                )[0] || null
              }
              style={{ width: "100%" }}
            />
          </p>
          <DialogActionsBar>
            <Button onClick={toggleDialog}>{"Cancel"}</Button>
            <BuzopsButton
              loading={checkinloading}
              label={"Check-In"}
              onClick={() => handleLocationCheckin()}
              disabled={checkinloading}
            />
          </DialogActionsBar>
        </Dialog>
      </>
    );
  };

  console.log('AllCLientList',AllClientsList);

  async function fetchPaymentProfiles(userMemberId: any) {
    const result = await service.paymentProfile(userMemberId);
    if (result) {
      if (result.length > 0) {
        setPaymentProfile(result);
        const defaultPP = result.find((card: any) => card.IsDefault);
        if(defaultPP){
          handleChangeSavedCard(defaultPP)
        }
      }
    }
  }

  const handleRefreshAppointments = () => {
    handleSelect(selected);
  };

  const handleChangeSavedCard = (val: any) => {
    setSelectedPaymentProfile(val);
  };

  const onCloseHandler = () => {
    setselfkioskdialog(false);
  }

  const [page, setPage] = React.useState({
    skip: 0,
    take: 4,
  });
  const handlePageChange = (status = "more") => {
    if (status === "less") {
      setPage({
        skip: 0,
        take: 4,
      });
    } else {
      setPage({
        take: page.take + 4,
        skip: 0,
      });
    }
    if (showMoreRef.current) {
      showMoreRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };
  async function fetchInsights(userMemberId: any) {
    setinSightLoading(true);
    const result = await service.getInsights(userMemberId);
    if (result) {
      setInsightData({ ...result });
      setOriginalInsightData({ ...result });
    }
    setinSightLoading(false);
  }
  async function fetchDeclinedPayments() {
    const req = {
      UserMemberId: props?.userData?.UserMemberId,
      PageNumber: 0,
      PageSize: 32767,
    };

    const result = await service.declinedPayments(req);
    if (result) {
      setDeclinedPayments(result.Items);
    }
  }

  console.log('userSession?.AccessCardType',userSession?.AccessCardType);

  const checkIfUserCanCheckin = (accessCardDetails: any) => {
   
    if(userSession?.AccessCardType !== 0){
      if(isOpenPathSystem()) {
        return checkIfUserCanCheckinForOpenPath(accessCardDetails);
      }
      if(isBarcodeSystem()) {
        return checkIfUserCanCheckinForBarcode(accessCardDetails);
      }
      if(isBrivoPassportPathSystem()) {
        return checkIfUserCanCheckinForBrivoPassPort(accessCardDetails);
      }
      
      
    }
    let doCheckin = true;
    return doCheckin;
   
  };

  const handleLocationCheckin = async () => {
   
    const simpleAccessService = new SimpleAccessService();
    const checkinService = new CheckInService();
    if(AllClientsList.length > 1 && !clientSelectDialog){
      setclientSelectDialog(true);
      return;
    }
    setcheckinLoading(true);
    const req = {
      UserMemberId: selectedUsermemberId,
      PrimaryUserMemberId: props?.userData?.MainMemberId,
      IncludeSubMember: false,
    };
    const response = await simpleAccessService.getMembersWithAccess(req);
    let doCheckin = true;
    if (!response.length) {
      setclientSelectDialog(false);
      props?.handleNotificationMessage(
        "Access card is not assigned to the selected user",
        "error"
      );
      doCheckin = false;
      setcheckinLoading(false);
    } 
    else {
      doCheckin = checkIfUserCanCheckin(response[0].AccessCard);
    }
    if (!doCheckin) {
      setclientSelectDialog(false);
      setcheckinLoading(false);
      return;
    }
    const payload: any = {
      Ocurred: `${moment().format("YYYY-MM-DDTHH:mm:ss")}`,
      Type: 0, //0-Location, 2-GroupClassAppointment, 3-OneOnOneAppointment
      CheckedInUserId: userData?.UserId, //<UserId>
      CheckinMode: 1, //0-AccessControlBrivo, 1-ByStaff, 2-UserFromKiosk, 3-OnlineByUser(future)
    };
    const result = await checkinService?.saveCheckins(payload);
    setclientSelectDialog(false);
    setcheckinLoading(false);
    if (result?.ResponseCode === 300) {
      
      setselfkioskdialog(true);
      props?.handleNotificationMessage(
        "Check-In successfully completed",
        "success"
      );
    } else {
      props?.handleNotificationMessage(
        result?.ErrorMessages?.[0] || "Check-In unsuccessfull",
        "error"
      );
    }
  };

  const handleDeclinedPayments = (dataItem: any) => {
    setShowPayNowDialog(true);
    setPayNowItem(dataItem);
  };
  const submitUnPaidDues = async () => {
    if (paynowItem?.IsWaivePayment || selectedPaymentProfile) {
      if (
        selectedPaymentProfile &&
        !paynowItem?.IsWaivePayment &&
        selectedPaymentProfile?.CardTypeId === 8 &&
        paynowItem?.Amount > selectedPaymentProfile?.Credit
      ) {
        const errorMsg = CreditsErrorMessage;
        props?.handleNotificationMessage(errorMsg, "error");
        return false;
      }
      setBtnLoading(true);
      const req = {
        PaymentProfileId:
          selectedPaymentProfile?.PaymentGatewayPaymentProfileID || 0,
        PaymentGatewayTransactionHistoryId:
          paynowItem?.PaymentGatewayTransactionHistoryId,
        PenaltyAmount: 0,
        Amount: paynowItem?.Amount,
        IsWaivePayment: paynowItem?.IsWaivePayment || false,
        EnablePreviousSession: true,
        WaiveComments: paynowItem?.WaiveComments || "",
        PaymentMadeFor: paynowItem?.PaymentMadeFor,
      };
      const res = await service.saveDeclinedPayments(req);
      setBtnLoading(false);
      if (res.ResponseCode === 100) {
        togglePayNowDialog();
        const successMsg = "Unpaid Dues paid successfully";
        props?.handleNotificationMessage(successMsg, "success");
        await fetchAllApis();
      } else {
        const errorMsg =
          res?.ErrorMessages?.[0] || "Error While Paying Unpaid Dues";
        props?.handleNotificationMessage(errorMsg, "error");
      }
    } else {
      const errorMsg = "Please select payment profile";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
 

  const renderSubscriptions = () => {
    if (inSightloading) {
      return <BuzopsLoader type="list" />;
    }

    if (
      !insightData?.Subscriptions ||
      insightData?.Subscriptions?.length <= 0
    ) {
      return (
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            <div
              className="bz-norecords-available"
              style={{ minHeight: "6rem" }}
            >
              {"No active subscriptions available"}
            </div>
          </div>
        </div>
      );
    }

    const subscriptionsStatus = (dataItem: any) => {
      let status='';
      if(dataItem?.SubscriptionType === 1 || dataItem?.SubscriptionType === 2 ){

        status = (((dataItem?.NextRecurrence !== null  
                    && dataItem?.CancellationDate != null 
                    && dataItem?.CancellationDate < dataItem?.NextRecurrence
                    ) || (dataItem?.NextRecurrence === null && dataItem?.CancellationDate != null)) && dataItem?.StatusDescription == 'Active')
                      ? "Cancel-in-Progress" 
                      : dataItem?.StatusDescription
      }
      else if(dataItem?.SubscriptionType === 5) {
        status = (dataItem?.StatusDescription === 'Active' && dataItem?.CancellationDate) ? "Cancel-in-Progress"  : dataItem?.StatusDescription
      }
      else{
        status=dataItem?.StatusDescription
      }
      return status;
    }

    return (
      <div
        className={
          "bz-subscription-card bz-quick-insight bz-mt-2rem bz-mb-2_5rem"
        }
      >
        <div className="bz-content-text-row overview-grid bz-subscription-overview">
          <span className="bz-content-title text-right">Subscriptions</span>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {insightData?.Subscriptions?.slice(0, page.take).map(
              (item: any, index: number) => {
                const cardTypeName = cardTypes.filter(
                  (card: any) => card.id === item.CardTypeId
                );
                let cardId = cardTypeName[0]?.text;
                if (!cardId) {
                  cardId = "placeholder";
                }
                const selectedItem =
                  AuditType.filter((i: any) => i?.id === item?.AuditType)?.[0]
                    ?.name || "none";
                const noMaskNumber = !item?.MaskedCCNumber
                  ? "bz-nomask-no"
                  : "";
                return (
                  <div className="bz-payment-options ">
                    <h6 className="subscription-name">
                      <span
                        onClick={() => {
                          props.handleSelectionItem(
                            item?.EntityId,
                            item?.StatusDescription,
                            selectedItem,
                            item
                          );
                        }}
                        className="name"
                      >
                        {item?.Name}
                      </span>
                      <span className="mble-subscription-badges">
                        <BuzopsTooltip
                          html={
                            <span
                              title={
                                AuditType.filter(
                                  (i: any) => i?.id === item?.AuditType
                                )?.[0]?.text || "None"
                              }
                              className="badge badge-info"
                            >
                              {AuditType.filter(
                                (i: any) => i?.id === item?.AuditType
                              )?.[0]?.text[0] || "None"}
                            </span>
                          }
                        />
                      </span>
                    </h6>
                    <div className="d-flex align-items-center text-muted bz-member-type bz-fs-12px">
                      <ClientNameWithMemberType
                        memberType={item?.MemberType}
                        clientName={item?.MemberName}
                      />
                    </div>
                    <div className={`overview-col-2 ${noMaskNumber}`}>
                      {item?.MaskedCCNumber !== "" ? (
                        <span className="bz-card-details">
                          {renderCardIcon(item, cardId, item?.CardTypeId)}
                          {item?.CardTypeId === 8
                            ? item?.MaskedCCNumber
                            : item?.MaskedCCNumber?.slice(-4)}
                        </span>
                      ) : (
                        <span></span>
                      )}
                      <span
                        className={`badge badge-text-${subscriptionsStatus(item)}`}
                      >
                        {subscriptionsStatus(item)}
                      </span>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>

        {insightData?.Subscriptions.length > page.take && (
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right"></div>
            <span className="bz-colon text-center"></span>
            <div className="bz-content-body" ref={showMoreRef}>
              <span
                className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                onClick={() => handlePageChange()}
              >
                View More
              </span>
            </div>
          </div>
        )}
        {insightData?.Subscriptions.length <= page.take &&
          insightData?.Subscriptions.length > 4 && (
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right"></div>
              <span className="bz-colon text-center"></span>
              <div className="bz-content-body" ref={showMoreRef}>
                <span
                  className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                  onClick={() => handlePageChange("less")}
                >
                  Show less
                </span>
              </div>
            </div>
          )}

        <div className="bz-content-text-row quicksight-flex">
          <div className="bz-content-title text-right">Unpaid Due</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {CurrencyFormat(insightData?.DeclinedAmount)}
          </div>
        </div>
        <div className="bz-content-text-row quicksight-flex show-tax-content-row">
          <div className="bz-content-title text-right">
            <div className="d-flex flex-column justify-content-end">
              <span>
                Next Billing Amount {checkHasTaxEnable() && <span>*</span>}
              </span>
              {checkHasTaxEnable() && (
                <small style={{ fontSize: "10px", color: "#777" }}>
                  (Exclusion of Surcharges)
                </small>
              )}
            </div>
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {`${CurrencyFormat(insightData?.NextRecurringAmount)} (${
              insightData?.NextBillingDate === "N/A"
                ? "N/A"
                : moment(insightData?.NextBillingDate)?.format("MM/DD/YYYY")
            })`}
          </div>
        </div>
        <div className="bz-content-text-row quicksight-flex  show-tax-content-row">
          <div className="bz-content-title text-right">
            <div className="d-flex flex-column justify-content-end">
              <span>Last Paid *</span>
              <small style={{ fontSize: "10px", color: "#777" }}>
                (Inclusion of Surcharges)
              </small>
            </div>
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {`${CurrencyFormat(insightData?.LastPaid)}`}
          </div>
        </div>
      </div>
    );
  };

  const toggleFamilyMemDialog = () => {
    setShowDialog(false);
  };
  const togglePayNowDialog = () => {
    setShowPayNowDialog(false);
    setPayNowItem(null);
    setSelectedPaymentProfile(undefined);
  };
  const update = async (dataItem: any) => {
    setInsightData((prev: any) => {
      prev.FamilyDetails = insightData.FamilyDetails.map((item: any) =>
        item.UserMemberId === dataItem.UserMemberId
          ? { ...item, inEdit: true }
          : item
      );
      return { ...prev };
    });
    dataItem.inEdit = false;
  };
  const cancel = (dataItem: any) => {
    const originalItem = originalInsightData.FamilyDetails.find(
      (p: any) => p.UserMemberId === dataItem.UserMemberId
    );
    console.log(originalItem);
    setInsightData((prev: any) => {
      prev.FamilyDetails = insightData.FamilyDetails.map((item: any) =>
        item.UserMemberId === originalItem.UserMemberId ? originalItem : item
      );
      return { ...prev };
    });
  };
  const enterEdit = (dataItem: any) => {
    setInsightData((prev: any) => {
      prev.FamilyDetails = insightData.FamilyDetails.map((item: any) =>
        item.UserMemberId === dataItem.UserMemberId
          ? { ...item, inEdit: true }
          : item
      );
      return { ...prev };
    });
  };
  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || "";

    setInsightData((prev: any) => {
      prev.FamilyDetails = insightData.FamilyDetails.map((item: any) =>
        item.UserMemberId === event.dataItem.UserMemberId
          ? { ...item, [field]: event.value }
          : item
      );
      return { ...prev };
    });
  };

  const CommandCell = (props: GridCellProps) => (
    <MyCommandCell
      {...props}
      edit={enterEdit}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );
  const renderFamilyMembers = () => {
    return (
      <Dialog
        className="bz-book-Appoinment-dialog bz-familyMember-dialog"
        title={"Family Members"}
        onClose={() => toggleFamilyMemDialog()}
        width={"38rem"}
      >
        <div style={{ textAlign: "justify" }} className="k-grid bzc-grid">
          <Grid
            dataItemKey="UserMemberId"
            onItemChange={itemChange}
            editField={editField}
            data={insightData.FamilyDetails}
            style={{ maxHeight: "300px", border: 0 }}
          >
            <Column
              field="Name"
              title="Full Name"
              editor="text"
              width="150px"
            />
            <Column
              field="Email"
              title="Email"
              editable={false}
              width="200px"
            />
            <Column
              field="Phone"
              title="Phone Number"
              width="140px"
              cell={CellForMobileValidation}
            />
          </Grid>
        </div>
        <Button
          className={"float-right mt-3"}
          primary={true}
          onClick={() => toggleFamilyMemDialog()}
        >
          Okay
        </Button>
      </Dialog>
    );
  };
  const renderPayNow = () => {
    const handlePayChange = (val: any, name: string) => {
      setPayNowItem({ ...paynowItem, [name]: val });
    };
    if (!paynowItem) {
      return <div></div>;
    }
    return (
      <div>
        <hr />
        <h5 className="mb-3 pt-3 bz_fs_1_5">Pay Unpaid Dues</h5>
        <div className="bz-mt-2rem bz-mb-2_5rem" ref={payNowRef}>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Transaction Id</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">{paynowItem?.TransactionId}</div>
          </div>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Entity Name</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">{paynowItem?.EntityName}</div>
          </div>
          <div className="bz-content-text-row" style={{ fontWeight: 600 }}>
            <div className="bz-content-title text-right">Amount</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {CurrencyFormat(paynowItem?.Amount)}
            </div>
          </div>
          {props?.staffPage && showWaived(paynowItem) ? (
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                Do you want to waive this payment ?
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <Switch
                  checked={paynowItem?.IsWaivePayment || false}
                  onChange={(e) => handlePayChange(e.value, "IsWaivePayment")}
                />
              </div>
            </div>
          ) : null}
          {paynowItem?.IsWaivePayment ? (
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Comments</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <textarea
                  style={{ maxWidth: "300px", fontSize: "14px" }}
                  value={paynowItem?.WaiveComments || ""}
                  onChange={(e) =>
                    handlePayChange(e.target.value, "WaiveComments")
                  }
                ></textarea>
              </div>
            </div>
          ) : (
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Payment Method</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <DropDownList
                  id={"selectedPaymentProfile"}
                  name={"selectedPaymentProfile"}
                  data={paymentProfile}
                  listNoDataRender={() => "No Payment Methods"}
                  textField="MaskedCCNumber"
                  dataItemKey="PaymentProfileID"
                  className={"bz-payUnpaid-dues-dropdown"}
                  valueRender={(
                    el: React.ReactElement<HTMLSpanElement>,
                    value: any
                  ) => PaymentValueRender(el, value, selectedPaymentProfile)}
                  itemRender={(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) =>
                  PaymentItemRender(li, { ...itemProps, makePaymentProfileAsDefault })
                  }
                  onChange={(e) => {
                    handleChangeSavedCard(e.value);
                  }}
                />
              </div>
            </div>
          )}
          <div className="bz-content-text-row mt-4 pt-2">
            <div className="bz-content-title text-right"></div>
            <span className="bz-colon text-center"></span>
            <div className="bz-content-body">
              <Button className="ml-0" onClick={() => togglePayNowDialog()}>
                Cancel
              </Button>
              <BuzopsButton
                primary={true}
                label={"Submit"}
                onClick={() => submitUnPaidDues()}
                disabled={btnloading}
                loading={btnloading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (addClient) {
    return (
      <CreateClient
        handleClose={(success = false) => handleClose()}
        UserMemberId={props?.userData?.UserMemberId}
        parentUser={props?.userData}
        staffPage={props?.staffPage}
        parentUserData={props?.userData}
      />
    ); 
  }
  if (manageNotifications) {
    return (
      <ManageNotifications
        handleClose={() => handleClose()}
        userData={props?.userData}
      />
    );
  }
  

  return (
    <div>
      <h5 className="mb-4 bz_fs_1_5" ref={declinedPayRef}>
        Overview
      </h5>
      {loading ? (
        <BuzopsLoader type={"list"} />
      ) : (
        <>
            {checkinloading && (
              <Loader
                type="infinite-spinner"
                className="laoder-full-screen"
              />
            )}
        <div>
          <div className="row align-items-start bz-profile-overview">
            <div className="col-md-7 col-xl-8">
              <div
                className={`bz-summary-profile ${
                  showEmergenyContactInfo ? "emergency-contact-info" : ""
                }`}
              >
                <div className={"bz-row align-items-sm-center w-100"}>
                  <div className={"user-pic text-center "}>
                    <img
                      width={38}
                      height={38}
                      style={{ borderRadius: "50%" }}
                      src={
                        props?.userData?.Photo
                          ? getFullImageUrl(props?.userData?.Photo)
                          : "/images/user.png"
                      }
                    />
                  </div>
                  <div className={"user-details pl-0"}>
                    <span key={1} className={""}>
                      <div className="username-family-member mb-2">
                        <h6>
                          <ClientNameWithMemberType
                            memberType={props?.userData?.MemberType}
                            clientName={props?.userData?.FullName}
                          />
                        </h6>
                        <div className="align-self-center d-flex gap15">
                          <span
                            className={"align-self-end bz-family-member-icon"}
                          >
                            <BadgeContainer>
                              <i className="fas fa-users"></i>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setShowDialog(true);
                                }}
                              >
                                <Badge>
                                  {insightData?.FamilyDetails?.length}
                                </Badge>
                              </span>
                            </BadgeContainer>
                          </span>
                          {props?.staffPage && (
                                <div className="overveiwcheckin">

                                  <BuzopsTooltip
                                    position={"left"}
                                    html={
                                      <a
                                        title={`Check-in`}
                                        className="pr-2 bz-cursor-pointer checkoverviewbtn"
                                        onClick={handleLocationCheckin}
                                      >
                                        <MdLocationPin
                                          className="icon-color"
                                          //onClick={handleLocationCheckin}
                                          width={'1.2rem'}
                                          style={{marginTop:'-4px'}}

                                        />
                                        Check-in
                                      </a>
                                    }
                                  />

                                </div>
                              )} 
                        </div>
                      </div>
                      <span className="contact mr-2">
                        <i className="fas fa-envelope"></i>
                        {props?.userData?.Email}
                      </span>
                      <span className="contact">
                        <i className="fas fa-phone-volume"></i>
                        {props?.userData?.Phone}
                      </span>
                    </span>
                    {showEmergenyContactInfo && (
                      <div className="emergency-blk">
                        <p className="mt-1" style={{ marginBottom: `${isExpanded ? 0.35 : 0}rem` }}>
                          <span
                            className="bz-cursor-pointer mr-2"
                            style={{ color: "red" }}
                            onClick={handleToggle}
                          >
                            Emergency Details{" "}
                            {isExpanded ? <FaAngleUp /> : <FaAngleDown />}
                          </span>
                        </p>
                        {isExpanded && (
                          <>
                            {(EmergencyContactPerson || EmergencyRelation) && (
                              <h6
                                className="contact mr-2 mb-0"
                                style={{
                                  lineHeight: 1.313,
                                  verticalAlign: "top",
                                }}
                              >
                                <img
                                  title="Primary Client"
                                  src={PrimaryMember}
                                  className="bz-member-icon"
                                  alt=""
                                />
                                {EmergencyContactPerson && (
                                  <span>{EmergencyContactPerson}</span>
                                )}
                                {EmergencyRelation && (
                                  <span>, {EmergencyRelation}</span>
                                )}
                              </h6>
                            )}
                            {EmergencyEmail && (
                              <span className="contact mr-2">
                                <i className="fas fa-envelope mr-1"></i>
                                {EmergencyEmail}
                              </span>
                            )}
                            {EmergencyContactNumber && (
                              <span className="contact">
                                <i className="fas fa-phone-volume mr-1"></i>
                                {EmergencyContactNumber}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 pl-0 col-md-5 col-xl-4 text-md-right ">
              <div>
                <span
                  className={`edit-btn-container overviewdashboard ${
                    props?.userData?.MemberType === 0 ? "staff" : ""
                  }`}
                >
                  {props?.userData?.MemberType === 0 && (
                    <>
                      <button
                        className="btn btn-link bz-overview-add-member-btn"
                        onClick={() => setAddClient(true)}
                      >
                        Add Family Member
                      </button>
                      <span className="bz-vertical-line"></span>
                    </>
                  )}
                  <button
                    className="btn btn-link"
                    onClick={() => props?.goToProfile()}
                  >
                    Edit Profile
                  </button>
                  <span className="bz-vertical-line"></span>
                 
                     
                   
                  <span>
                    <Tooltip anchorElement="target" position="left">
                      <img
                        src={bzImages.notificationIcon}
                        alt="Manage Notifications"
                        title="Manage Notifications"
                        style={{ width: "1.25rem" }}
                        className="bz-cursor-pointer"
                        onClick={() => setManageNotifications(true)}
                      />
                    </Tooltip>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div className="bz-subsection-row bz-overview-page-row bz-appoinments-info-sec">
              <div className="left-section">
                <h5 className="my-3 bz_fs_1_5">Quick Insight</h5>
                {renderSubscriptions()}
                <hr />
              </div>
              {declinedPayments && declinedPayments.length > 0 ? (
                <div className="right-section bz-inactive-error-card">
                  <Card
                    className={"bz-card bz-overview-cards pt-0"}
                    style={{
                      backgroundColor: "transparent",
                      overflowY: "auto",
                    }}
                  >
                    <CardBody className="p-0">
                      <DeclinedPayments
                        declinedPayments={declinedPayments}
                        handleDeclinedPayments={(data: any) =>
                          handleDeclinedPayments(data)
                        }
                      />
                    </CardBody>
                  </Card>
                </div>
              ) : (
                <div className="right-section bz-inactive-error-card"></div>
              )}
            </div>
              <div className="bz-subsection-row bz-overview-page-row bz-no-right-section">
                <div className="left-section">
              <AppointmentTabs
                {...props}
                deleteGs={true}
                paynowGs={true}
                serviceId={null}
                onReload={handleReload}
                  />
                   </div>
            </div>
          </div>
        </div>
        </>
      )}

      {showDialog && renderFamilyMembers()}
      {showPayNowDialog ? renderPayNow() : <div></div>}
      {selfkioskdialog && (
        <DialogPopup
          className="bz-custom-content-height bz-checkins-dialog"
          title={"Client Appointment Check-In"}
          onClose={onCloseHandler}
          height={650}
        >
          <div className="bz-Self-CheckIn">
            <CheckinUser
              userDetails={userData}
              CheckinMode={CheckinMode.ByStaff}
              //{...props}
              from={CheckinFrom.LiveStream}
            />
          </div>
        </DialogPopup>
      )}
      {clientSelectDialog && ClientDialog()}
    </div>
  );
}

export default withNotification(Overview);
