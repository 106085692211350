import { NumericTextBox} from "@progress/kendo-react-inputs";
import { Card } from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ThirdPartyConfigService } from "services/thirdparty-configuration/index.service";
import { ListView } from "@progress/kendo-react-listview";
import moment from "moment";
import { getTimeFormat } from "utils";
import withNotification from "components/Hoc/withNotification";
import BuzopsButton from "generic-components/BuzopsButton";
import { MdContentCopy } from "react-icons/md";

export const BarCodeType: any = {
  CODE_39: 1,
  CODE_93: 2,
  CODABAR: 4,
  CODE_128: 8,
  QR_CODE: 16,
};

export const BarCodeTypes = [
  {
    id: 1,
    text: "CODE_39",
  },
  {
    id: 2,
    text: "CODE_93",
  },
  {
    id: 4,
    text: "CODABAR",
  },

  {
    id: 8,
    text: "CODE_128",
  },
  {
    id: 16,
    text: "QR_CODE",
    disabled: true,
  },
];
export const NotifyOptions = [
  {
    id: 0,
    text: "None",
  },
  {
    id: 1,
    text: "Email",
    default: true,
  },
  {
    id: 2,
    text: "SMS",
  },
];

const SimpleConfiguration = (props: any) => {
  const { securitySystemConfigs, onSubmit } = props;
  const getSecurityConfigData = () => {
    let obj = {};
    if (!securitySystemConfigs?.OneOnOneCheckinPreference?.Enabled) {
      obj = {
        ...obj,
        OneOnOneCheckinPreference: {
          Enabled: true,
        },
      };
    }
    if (!securitySystemConfigs?.GroupClassCheckinPreference?.Enabled) {
      obj = {
        ...obj,
        GroupClassCheckinPreference: {
          Enabled: true,
          // MaxTimeSpanInMinutesBeforeScheduleForCheckin: 60,
        },
      };
    }
    return { ...securitySystemConfigs, ...obj };
  };
  const [formValues, setFormValues] = useState<any>(
    securitySystemConfigs && Object.keys(securitySystemConfigs).length > 0
      ? getSecurityConfigData()
      : {
          SecuritySystemType: 2, //Simple
          CheckinsEnabled: true,
          LocationCheckinPreference: {
            Enabled: true,
          },
          OneOnOneCheckinPreference: {
            Enabled: true,
            MaxTimeSpanInMinutesBeforeScheduleForCheckin: 60,
          },
          GroupClassCheckinPreference: {
            Enabled: true,
            MaxTimeSpanInMinutesBeforeScheduleForCheckin: 60,
          },
          SimpleSecuritySystemPreferences: {
            SupportedBarcodeTypes: [], //Code39, Code93, Code128
            PreferredBarcodeType: 0, //Code93
          },
          HardwareSecuritySystemPreferences: null,
          CheckinNotificationPreference: 1,
          TrainerCheckinNotificationPreference: 0,
          ManualAplhanumCodeCheckinsEnabled: false,
        }
  );
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showQickIdAlert, setShowQuickIdAlert] = useState<boolean>(false);
  const [activityLog, setActivityLog] = useState<any>([]);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [copiedKioskUrl, setCopiedKioskUrl] = useState(false);
  const [
    isManualAplhanumCodeCheckinsEnabled,
    setIsManualAplhanumCodeCheckinsEnabled,
  ] = useState(false);

  useEffect(() => {
    getActivityLog();
  }, []);
  const service = new ThirdPartyConfigService();

  const getActivityLog = async () => {
    try {
      const activityLog = await service.getSecuritySystemActivityLog();
      if (activityLog.success) {
        if (activityLog?.data?.length > 0) {
          setActivityLog(activityLog?.data);
        } else {
          setActivityLog([]);
        }
      } else {
        setActivityLog([]);
      }
    } catch (err) {
      console.log(err);
      setActivityLog([]);
    }
  };

  const setFormData = (key: string, value: any, restrict = true) => {
    const splitKey = key.split(".");
    let firstKey = key;
    let secondKey = null;
    if (splitKey.length > 0) {
      firstKey = splitKey[0];
    }
    if (splitKey.length > 1) {
      secondKey = splitKey[1];
    }
    let currrentChange = { [key]: value };
    switch (firstKey) {
      default:
        currrentChange = { [firstKey]: value };
        if (firstKey && secondKey) {
          currrentChange = {
            [firstKey]: { ...formValues[firstKey], [secondKey]: value },
          };
          if (
            firstKey === "OneOnOneCheckinPreference" ||
            firstKey === "GroupClassCheckinPreference"
          ) {
            currrentChange = {
              ...currrentChange,
              [firstKey]: {
                ...formValues[firstKey],
                [secondKey]: value,
                MaxTimeSpanInMinutesBeforeScheduleForCheckin: !value
                  ? 0
                  : secondKey === "MaxTimeSpanInMinutesBeforeScheduleForCheckin"
                  ? value
                  : 15,
              },
            };
          }
        }
        break;
    }
    setFormValues((prev: any) => {
      return { ...prev, ...currrentChange };
    });
    setShowAlert(false);
  };

  const renderConfirmPopup = () => {
    if (showAlert) {
      return (
        <Dialog title={"Please confirm"} onClose={() => setShowAlert(false)}>
          <p style={{ margin: "25px", textAlign: "center" }}>
            Are you sure you want to continue?
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => setShowAlert(false)}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => setFormData("CheckinsEnabled", false, false)}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      );
    }
  };

  const MyItemRender = (props: any) => {
    let item = props.dataItem;
    return (
      <div
        style={{ backgroundColor: "#fff" }}
        className="py-2 bz-package-audit-history-list"
      >
        {" "}
        <span className="text-muted d-block mb-0 audit-flex">
          <span>
            <i className="far fa-calendar-alt"></i>
          </span>
          <div>
            <span className="bz-activity-time-date text-capitalize">
              {" "}
              {moment(item?.AuditDate).format("L")}{" "}
              {getTimeFormat(item?.AuditDate)}
            </span>
            <span className="bz-activity-dis-div">{item?.Action}</span>
            <label className="bz-requestedBy font-italic bz-fw-600">
              {`${item?.RequestedByFirstName} ${item?.RequestedByLastName}`}
            </label>{" "}
          </div>
        </span>
      </div>
    );
  };

  const requestForConfigs = async () => {
    try {
      console.log(formValues);
      const getValidationErrors = validateAllFormFields();
      if (getValidationErrors.msg) {
        props?.handleNotificationMessage(getValidationErrors.msg, "error");
        return true;
      }
      setBtnLoading(true);
      const saveConfig = await service.saveSecuritySystemConfigs({
        ...formValues,
        SecuritySystemType: 2,
      });
      if (saveConfig.success) {
        onSubmit(saveConfig);
      } else {
        props?.handleNotificationMessage(
          saveConfig.msg || "Error while saving configuration.",
          "error"
        );
      }
      setBtnLoading(false);
    } catch (err) {
      setBtnLoading(false);
      console.log(err);
    }
  };

  const validateAllFormFields = () => {
    let errorMsgObj: any = {
      msg: null,
      type: "",
    };
    if (!formValues?.SimpleSecuritySystemPreferences?.PreferredBarcodeType) {
      errorMsgObj.msg = "Please select preferred code system";
      errorMsgObj.type = "PreferredBarcodeType";
      return errorMsgObj;
    }
    if (formValues?.OneOnOneCheckinPreference?.Enabled) {
      if (
        !(
          formValues?.OneOnOneCheckinPreference
            ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin >= 15 &&
          formValues?.OneOnOneCheckinPreference
            ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin <= 240
        )
      ) {
        errorMsgObj.msg =
          "Please enter Max Time Span In Minutes Before Schedule for One-on-One between 15 and 240 minutes";
        errorMsgObj.type =
          "OneOnOneCheckinPreference.MaxTimeSpanInMinutesBeforeScheduleForCheckin";
        return errorMsgObj;
      }
    }

    if (formValues?.GroupClassCheckinPreference?.Enabled) {
      if (
        !(
          formValues?.GroupClassCheckinPreference
            ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin >= 15 &&
          formValues?.GroupClassCheckinPreference
            ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin <= 240
        )
      ) {
        errorMsgObj.msg =
          "Please enter Max Time Span In Minutes Before Schedule  for group class between 15 and 240 minutes";
        errorMsgObj.type =
          "GroupClassCheckinPreference.MaxTimeSpanInMinutesBeforeScheduleForCheckin";
        return errorMsgObj;
      }
    }

    if (
      !formValues?.CheckinNotificationPreference &&
      formValues?.CheckinNotificationPreference !== 0
    ) {
      errorMsgObj.msg = "Please select customer notify option.";
      errorMsgObj.type = "CheckinNotificationPreference";
      return errorMsgObj;
    }
    return errorMsgObj;
  };

  const checkIfPreferredBarcodeTypeSelected = (barcodeType: number) => {
    if (!formValues?.SimpleSecuritySystemPreferences?.PreferredBarcodeType) {
      return false;
    }
    return (
      formValues?.SimpleSecuritySystemPreferences?.PreferredBarcodeType ===
      barcodeType
    );
  };

  const copyToClipBoard = () => {
    try {
      window.navigator.clipboard.writeText(
        `https://${props?.domain}.${process.env.REACT_APP_DOMAIN}.com/kiosk/login`
      );
    } catch (error) {
      console.log(error);
    }
    setCopiedKioskUrl(true);
  };

  const onChangeQuickIdAlert = (value: boolean) => {
    setShowQuickIdAlert(true);
    setIsManualAplhanumCodeCheckinsEnabled(value);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ marginBottom: "1rem" }}>
          <h6 className="barcodelabel">Input Type</h6>
          <div className="col-lg-12">
            <span dir="ltr" className="selectbarcode selectbarcode1">
              <input
                type="radio"
                id="scanner"
                name="InputType"
                className="k-radio"
                style={{ verticalAlign: "center" }}
                aria-describedby="code39"
                value="false"
                checked={!formValues.ManualAplhanumCodeCheckinsEnabled}
                onChange={() => onChangeQuickIdAlert(false)}
              ></input>
              <label className="k-radio-label barcodelabel" htmlFor="scanner">
                Scanner (Recommended)
              </label>
              <small className="bz-text-muted d-block font-90">
                {" "}
                Choose this option if your users will check-in by scanning a
                Barcode/ QR Code. System will also aid in generating and
                emailing unique Barcode/ QR Code to the user.
              </small>
            </span>
          </div>

          <div className="col-lg-12" style={{ textAlign: "left" }}>
            <span dir="ltr" className="selectbarcode selectbarcode1">
              <input
                type="radio"
                id="manual"
                name="InputType"
                className="k-radio"
                style={{ verticalAlign: "center" }}
                aria-describedby="code93"
                value="true"
                checked={formValues.ManualAplhanumCodeCheckinsEnabled}
                onChange={() => onChangeQuickIdAlert(true)}
              ></input>
              <label className="k-radio-label barcodelabel" htmlFor="manual">
                Manual
              </label>
              <small className="bz-text-muted d-block font-90">
                {" "}
                Choose this option if your users will check-in by entering a 4
                to 6 character unique access code.
              </small>
            </span>
          </div>
        </div>

        {!formValues.ManualAplhanumCodeCheckinsEnabled ? (
          <div className="row" style={{ marginBottom: "1rem" }}>
            <h6 className="barcodelabel">Preferred Scanner Code System</h6>
            <div className="col-lg-2">
              <span dir="ltr" className="selectbarcode selectbarcode1">
                <input
                  type="radio"
                  id="barcode"
                  name="PreferredBarcodeType"
                  className="k-radio"
                  style={{ verticalAlign: "center" }}
                  aria-describedby="code39"
                  value="8"
                  // disabled={!checkIfBarcodeSelected(8)}
                  checked={checkIfPreferredBarcodeTypeSelected(8)}
                  onChange={() =>
                    setFormData(
                      "SimpleSecuritySystemPreferences.PreferredBarcodeType",
                      8
                    )
                  }
                ></input>
                <label className="k-radio-label barcodelabel" htmlFor="barcode">
                  Barcode
                </label>
              </span>
            </div>

            <div className="col-lg-2" style={{ textAlign: "left" }}>
              <span dir="ltr" className="selectbarcode selectbarcode1">
                <input
                  type="radio"
                  id="code93"
                  name="PreferredBarcodeType"
                  className="k-radio"
                  style={{ verticalAlign: "center" }}
                  aria-describedby="code93"
                  value="16"
                  checked={checkIfPreferredBarcodeTypeSelected(16)}
                  onChange={() =>
                    setFormData(
                      "SimpleSecuritySystemPreferences.PreferredBarcodeType",
                      16
                    )
                  }
                ></input>
                <label className="k-radio-label barcodelabel" htmlFor="code93">
                  QR Code
                </label>
              </span>
            </div>
          </div>
        ) : (
          <p className="font-90" style={{ color: "#d78e23" }}>
            Buzops product also internally generates a QR code to identify the
            user, should you wish to use a scanner in future.
          </p>
        )}

       {/* <h6 className="barcodelabel fs14">
          Select services that you want to accept check-in by scanning / typing
          in access code
          <small className="bz-text-muted d-block">
            (It is mandatory that customers should visit the premises)
          </small>
        </h6>

        <div className=" row">
          <div className="col-4 barcodelabel">Service Name:</div>
          {<div className="col-8 barcodelabel">Earliest check-in allowed</div>}
        </div>
        <div className="row my-2 align-items-center ">
          <div className="col-4 service-label">
            <span>Appointment</span>
          </div>
          {
            <div className="col-8 service-label">
              <NumericTextBox
                width={"100px"}
                min={15}
                max={240}
                step={15}
                onChange={(event) => {
                  setFormData(
                    "OneOnOneCheckinPreference.MaxTimeSpanInMinutesBeforeScheduleForCheckin",
                    event.target.value
                  );
                }}
                value={
                  formValues?.OneOnOneCheckinPreference
                    ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin
                }
              />
              <small className="bz-text-muted"> {`minutes before start`}</small>
            </div>
          }
        </div>
        <div className="row my-2 align-items-center">
          <div className="col-4 service-label">
            <span>Group Appointment</span>
          </div>
          {
            <div className="col-8 service-label">
              <NumericTextBox
                width={"100px"}
                min={15}
                max={240}
                step={15}
                onChange={(event) => {
                  setFormData(
                    "GroupClassCheckinPreference.MaxTimeSpanInMinutesBeforeScheduleForCheckin",
                    event.target.value
                  );
                }}
                value={
                  formValues?.GroupClassCheckinPreference
                    ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin
                }
              />
              <small className="bz-text-muted"> {`minutes before start`}</small>
            </div>
          }
        </div>
        <div
          className="row"
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
        >
          <h6 className="barcodelabel">Notify the client check-ins by </h6>
          <div className="col-lg-2" style={{ textAlign: "left" }}>
            <span dir="ltr" className="selectbarcode selectbarcode1">
              <input
                type="radio"
                id="none"
                name="CheckinNotificationPreference"
                className="k-radio"
                style={{ verticalAlign: "center" }}
                aria-describedby="Name"
                value=""
                checked={formValues?.CheckinNotificationPreference === 0}
                onChange={() => setFormData("CheckinNotificationPreference", 0)}
              ></input>
              <label className="k-radio-label barcodelabel" htmlFor="none">
                None
              </label>
            </span>
          </div>
          <div className="col-lg-2" style={{ textAlign: "left" }}>
            <span dir="ltr" className="selectbarcode selectbarcode1">
              <input
                type="radio"
                id="email"
                name="CheckinNotificationPreference"
                className="k-radio"
                style={{ verticalAlign: "center" }}
                aria-describedby="Email"
                value="2"
                checked={formValues?.CheckinNotificationPreference === 1}
                onChange={() => setFormData("CheckinNotificationPreference", 1)}
              ></input>
              <label className="k-radio-label barcodelabel" htmlFor="email">
                Email
              </label>
            </span>
          </div>
          <div className="col-lg-3" style={{ textAlign: "left" }}>
            <span dir="ltr" className="selectbarcode selectbarcode1">
              <input
                disabled={true}
                id="sms"
                type="radio"
                name="CheckinNotificationPreference"
                className="k-radio"
                style={{ verticalAlign: "center" }}
                aria-describedby="SMS"
                checked={formValues?.CheckinNotificationPreference === 2}
                value="3"
                onChange={() => setFormData("CheckinNotificationPreference", 2)}
              ></input>
              <label
                className="k-radio-label barcodelabel"
                htmlFor="sms"
                style={{ opacity: "0.6" }}
              >
                SMS (<i>Coming Soon</i>)
              </label>
            </span>
          </div>
          <div className="col-lg-4" style={{ textAlign: "left" }}>
            <span dir="ltr" className="selectbarcode selectbarcode1">
              <input
                disabled={true}
                id="both"
                type="radio"
                name="CheckinNotificationPreference"
                className="k-radio"
                style={{ verticalAlign: "center" }}
                aria-describedby="both"
                checked={formValues?.CheckinNotificationPreference === 3}
                value="3"
                onChange={() => setFormData("CheckinNotificationPreference", 3)}
              ></input>
              <label
                className="k-radio-label barcodelabel"
                htmlFor="both"
                style={{ opacity: "0.6" }}
              >
                Both Email & SMS (<i>Coming Soon</i>)
              </label>
            </span>
          </div>
        </div>
         <div
          className="row"
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
        >
          <h6 className="barcodelabel">Notify the Staff check-ins by </h6>
          <div className="col-lg-2" style={{ textAlign: "left" }}>
            <span dir="ltr" className="selectbarcode selectbarcode1">
              <input
                id="none"
                type="radio"
                name="TrainerCheckinNotificationPreference"
                className="k-radio"
                style={{ verticalAlign: "center" }}
                aria-describedby="None"
                value="1"
                checked={formValues?.TrainerCheckinNotificationPreference === 0}
                onChange={() =>
                  setFormData("TrainerCheckinNotificationPreference", 0)
                }
              ></input>
              <label className="k-radio-label barcodelabel" htmlFor="none">
                None
              </label>
            </span>
          </div>
          <div className="col-lg-2" style={{ textAlign: "left" }}>
            <span dir="ltr" className="selectbarcode selectbarcode1">
              <input
                type="radio"
                id="trainerEmail"
                name="TrainerCheckinNotificationPreference"
                className="k-radio"
                style={{ verticalAlign: "center" }}
                aria-describedby="Email"
                value="2"
                checked={formValues?.TrainerCheckinNotificationPreference === 1}
                onChange={() =>
                  setFormData("TrainerCheckinNotificationPreference", 1)
                }
              ></input>
              <label
                className="k-radio-label barcodelabel"
                htmlFor="trainerEmail"
              >
                Email
              </label>
            </span>
          </div>
          <div className="col-lg-3" style={{ textAlign: "left" }}>
            <span dir="ltr" className="selectbarcode selectbarcode1">
              <input
                disabled={true}
                id="trainersms"
                type="radio"
                name="TrainerCheckinNotificationPreference"
                className="k-radio"
                style={{ verticalAlign: "center" }}
                aria-describedby="SMS"
                checked={formValues?.TrainerCheckinNotificationPreference === 2}
                value="3"
                onChange={() =>
                  setFormData("TrainerCheckinNotificationPreference", 2)
                }
              ></input>
              <label
                className="k-radio-label barcodelabel"
                htmlFor="trainersms"
                style={{ opacity: "0.6" }}
              >
                SMS (<i>Coming Soon</i>)
              </label>
            </span>
          </div>
          <div className="col-lg-4" style={{ textAlign: "left" }}>
            <span dir="ltr" className="selectbarcode selectbarcode1">
              <input
                disabled={true}
                id="traineboth"
                type="radio"
                name="TrainerCheckinNotificationPreference"
                className="k-radio"
                style={{ verticalAlign: "center" }}
                aria-describedby="Both"
                checked={formValues?.TrainerCheckinNotificationPreference === 3}
                value="3"
                onChange={() =>
                  setFormData("TrainerCheckinNotificationPreference", 3)
                }
              ></input>
              <label
                className="k-radio-label barcodelabel"
                htmlFor="trainersboth"
                style={{ opacity: "0.6" }}
              >
                Both Email & SMS (<i>Coming Soon</i>)
              </label>
            </span>
          </div>
        </div> */}
        <div
          className="row"
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
        >
          <h6 className="barcodelabel">Kiosk URL</h6>
          <div className="col-lg-12" style={{ textAlign: "left" }}>
            <span dir="ltr" className="selectbarcode selectbarcode1">
              <a
                href={`https://${props.domain}.${process.env.REACT_APP_DOMAIN}.com/kiosk/login`}
                target="_blank"
              >
                <span>
                  <i
                    className="fa fa-link"
                    style={{ fontSize: "12px", marginRight: "5px" }}
                  ></i>
                </span>
                &nbsp;
                <span>
                  {`https://${props.domain}.${process.env.REACT_APP_DOMAIN}.com/kiosk/login`}
                </span>
              </a>
            </span>
            &nbsp;
            <span>
              <span className="bz-cursor-pointer">
                <MdContentCopy
                  onClick={copyToClipBoard}
                  title={copiedKioskUrl ? "Copied" : "Copy"}
                />
              </span>
            </span>
          </div>
        </div>
        <div className="float-right">
          <BuzopsButton
            label={"Save"}
            className="btn ml-0"
            loading={btnLoading}
            disabled={btnLoading}
            onClick={() => requestForConfigs()}
          />
        </div>
        <br />

        <div className="logactivity">Activity Log</div>
        <div className="my-2">
          {activityLog.length === 0 && (
            <div className={"bz-norecords-available text-muted"}>
              <span>{"No Activity Log Available"}</span>
            </div>
          )}
          <>
            <Card
              className={
                "bzo-services-container bz-package-audit-history border-0 border-activitylog"
              }
            >
              <ListView
                item={MyItemRender}
                data={showMore ? activityLog : activityLog.slice(0, 2)}
              />
            </Card>

            {activityLog.length > 2 && (
              <div className="text-center">
                <span
                  className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                  onClick={() => setShowMore(!showMore)}
                >
                  {!showMore ? (
                    <span key="down">
                      View More
                      <i className="fas fa-caret-down ml-1"></i>
                    </span>
                  ) : (
                    <span key="up">
                      View Less
                      <i className="fas fa-caret-up ml-1"></i>
                    </span>
                  )}
                </span>
              </div>
            )}
          </>
        </div>
      </div>

      {renderConfirmPopup()}

      {showQickIdAlert && (
        <Dialog
          title={"Please Confirm"}
          onClose={() => setShowQuickIdAlert(false)}
          className="dialogbarcode"
        >
          <p
            style={{ textAlign: "center" }}
            className="k-notification-warning p-2 ft-12"
          >
            {isManualAplhanumCodeCheckinsEnabled
              ? "Are you sure you would like switching to Manual Input of 4 to 6 character access codes? Choosing this option may require you to re-assign access codes for all your users. Please click 'OK' to proceed or 'Cancel' to revert."
              : "Are you sure you would like switching to Scanner input type? Choosing this option will not allow the user to input their 4 to 6 character access code to check-in. The user will need to use their QR code to check-in. Please click 'OK' to proceed or 'Cancel' to revert."}
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => setShowQuickIdAlert(false)}
            >
              Cancel
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={() => {
                setFormData(
                  "ManualAplhanumCodeCheckinsEnabled",
                  isManualAplhanumCodeCheckinsEnabled
                );
                setFormData(
                  "SimpleSecuritySystemPreferences.PreferredBarcodeType",
                  16
                );
                setShowQuickIdAlert(false);
              }}
            >
              Ok
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

export default withNotification(SimpleConfiguration);
