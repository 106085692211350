import { Tooltip } from "@progress/kendo-react-tooltip";
import { TooltipContentPackageService } from "components/checkout/OrderSummary";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import React from "react";
import { checkHasTaxEnable } from "utils";
import { CurrencyFormat } from "utils";

const SubscriptionDetails = (props: any) => {
  if (props?.loading) {
    return <BuzopsLoader type={"list"} />;
  }
  if (props?.selectedPlan === null) {
    return <div>{"No Subscriptions Data Found"}</div>;
  }
  console.log(props?.selectedPlan,'props?.selectedPlan')
  const {
    PlanName,
    PurchasedOn,
    MembershipPlanId,
    StartDate,
    EndDate,
    MonthlyRate,
    SalesPersonName,
    RenewMemberPlanId,
    DurationType,
    CanCollectTaxes=checkHasTaxEnable(),
    RecurringAmount, 
    RecurringDate,
    planStatusDescription,
    PromotionId=null,
    CancellationDate,
    CancellationStatus
  } = props?.selectedPlan;
  let signedUp = SalesPersonName ? SalesPersonName : 'Online'
  if(PromotionId && PromotionId > 0){
    signedUp="Online"
  }
  return (
    <div className="bz-mt-2rem bz-mb-2_5rem bz-content-text-row-mobile" >
      <div className="bz-content-text-row ">
        <div className="bz-content-title text-right">Membership Name</div>
        <span className="bz-colon text-center">:</span>
        <div className="bz-content-body">{PlanName}</div>
      </div>
      <div className="bz-content-text-row">
        <div className="bz-content-title text-right">Membership ID</div>
        <span className="bz-colon text-center">:</span>
        <div className="bz-content-body">{`MP-${MembershipPlanId}`}</div>
      </div>
      <div className="bz-content-text-row">
        <div className="bz-content-title text-right">Start Date</div>
        <span className="bz-colon text-center">:</span>
        <div className="bz-content-body">{moment(StartDate).format("L")}</div>
      </div>
      {!(RecurringDate !== "N/A" && CancellationDate!=null && CancellationDate<RecurringDate) && (
        <div className="bz-content-text-row">
        <div className="bz-content-title text-right">
          <div className="d-flex flex-column justify-content-end">
            <span>{"Next Billing"}{checkHasTaxEnable() && planStatusDescription !=="Suspended" && CanCollectTaxes && RecurringDate !== "N/A" && <span>*</span>}</span>
            {checkHasTaxEnable() && planStatusDescription !=="Suspended" && CanCollectTaxes && RecurringDate !== "N/A" && <small style={{ fontSize: '10px', color: '#777' }}><Tooltip
              content={(tooltipProps: any) => {
                return (
                  <TooltipContentPackageService
                    title={tooltipProps.title}
                    data={props?.PredictedTaxes}
                    TotalTaxAmount={props?.PredictedTotalTaxAmount}
                  />
                );
              }}
              tooltipClassName="bz-tooltip-table"
            >
              <a
                title="Surcharges"
                style={{
                  borderBottom: "1px dotted #0d6efd",
                  cursor: "pointer",
                }}
              >
                {"(Surcharges)"}
              </a>
            </Tooltip></small>}
          </div>
        </div>
        <span className="bz-colon text-center">:</span>
        <div className="bz-content-body">
          {planStatusDescription !=="Suspended" &&  RecurringDate !== "N/A" ?`${CurrencyFormat(RecurringAmount)} on ${moment(RecurringDate).format("L")}` : "NA"}
          {planStatusDescription !=="Suspended" && CanCollectTaxes && RecurringDate !== "N/A" && <small className="pl-1">{`(Exclusion of Surcharges)`}</small>}
        </div>
    </div>
      )}
      {RenewMemberPlanId=== 0 && EndDate !== "N/A" && DurationType !== 2 &&
      <div className="bz-content-text-row">
        <div className="bz-content-title text-right">
          {"End Date"}
        </div>
        <span className="bz-colon text-center">:</span>
        <div className="bz-content-body">
          {moment(EndDate).format("L")}
        </div>
      </div>}
      <div className="bz-content-text-row align-items-start">
        <div className="bz-content-title text-right">
          <div className="d-flex flex-column justify-content-end">
            <span>{DurationType === 3 ? "Rate":"Monthly Rate"} {CanCollectTaxes && <span>*</span>}</span>
            {CanCollectTaxes && <small style={{fontSize:'10px',color:'#777'}}>(Exclusion of Surcharges)</small>}
          </div>
        </div>
        <span className="bz-colon text-center">:</span>
        <div className="bz-content-body">{`${CurrencyFormat(MonthlyRate)}`}
        </div>
      </div>
      <div className="bz-content-text-row">
        <div className="bz-content-title text-right">Signed up by</div>
        <span className="bz-colon text-center">:</span>
        <div className="bz-content-body">{signedUp}</div>
      </div>
      <div className="bz-content-text-row">
        <div className="bz-content-title text-right">Sign up Date</div>
        <span className="bz-colon text-center">:</span>
        <div className="bz-content-body">{moment(PurchasedOn).format("L")}</div>
      </div>
      {(CancellationStatus === 2 && CancellationDate !=null) &&(
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Cancels On</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{moment(CancellationDate).format("L")}</div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionDetails;
