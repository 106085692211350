import { addDates } from "components/checkout/Checkout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useApiClient } from "services/axios-service-utils";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { PackageService } from "services/package/index.service";
import { TenantService } from "services/tenant/index.service";
import { CustomToFixed, getLongDate } from "utils";
import { CreditsErrorMessage } from "utils/form-utils";
import { GetLocalStore } from "utils/storage";
import { Upload } from "@progress/kendo-react-upload";
import { UploadFileInfo, UploadOnAddEvent, UploadOnRemoveEvent } from "@progress/kendo-react-upload";

const initialAdditionalPayment = {
  dialog: false,
  selectedPaymentProfile: null,
};
const usePayRemaingDue = (props: any) => {
  const { axiosRequest } = useApiClient();
  const remainingDueRecDetails = {
    recurrenceUnit: { id: 0, text: "Please Select Frequency" },
    NextRecurrence: undefined,
    OccursEvery: "",
    NoOfInstallments: "",
  };
  const [submitPayRemDue, setSubmitPayRemDue] = useState(false);
  const [refNumber, setRefNumber] = useState<any>(null);
  const [showAdditionalPayment, setShowAdditionalPayment] = useState<any>(
    initialAdditionalPayment
  );
  const [AlternatePaymentProfileId, setAlternatePaymentProfileId] =
    useState<any>(null);
  const [makePayment, setMakePayment] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [IsWaivePayment, setIsWaivePayment] = React.useState(false);
  const [IsTaxSkipped, setIsTaxSkipped] = React.useState(true);
  const [paymentProfile, setPaymentProfile] = React.useState<any>([]);
  const [selectedPaymentProfile, setSelectedPaymentProfile] =
    React.useState<any>(undefined);
  const [payRemainingDue, setPayRemainingDue] = React.useState(0);
  const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
  const [paymentGatewayType, setPaymentGatewayType] =
    React.useState<any>(paymentGatewaytype);
  const [remDueRecurrence, setRemDueRecurrence] = React.useState(false);
  const [submistatus, setSubmitStatus] = React.useState({
    payRemDue: true,
    updateRecurrence: false,
  });
  const [payDueRecurrencetable, setPayDueRecurrencetable] = React.useState<any>(
    []
  );
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [allMembers, setAllMembers] = useState<any>([]);
  const [addNewItem, setAddNewItem] = useState<any>(null);
  const [Taxes, setTaxes] = React.useState<any>([]);
  const [TotalTaxAmount, setTotalTaxAmount] = React.useState<number>(0);
  const [remDueRecurDetails, setRemDueRecurDetails] = React.useState<any>(
    remainingDueRecDetails
  );
  const [payRemDueSubmitLoading, setpayRemDueSubmitLoading] =
    React.useState(false);
  const [serviceData, setserviceData] = React.useState<any>(null);
  const [serviceInstance, setServiceInstance] = React.useState<any>(null);
  React.useEffect(() => {
    updateServiceData();
    getAllSubMembers(props?.userDetails?.UserMemberId);
    fetchTaxes();
  }, []);


  
  //upload
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);

  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);


  const constructFileWithBas64 = (records: any) => {
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
        };
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
    }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };

  //


  const onCardOrBankAdd = (e: any, memberId: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };

  const getAllSubMembers = async (memberId: any) => {
    const tenantService = new TenantService();
    const res = await tenantService.GetSubMembersWithParent(memberId);
    setAllMembers(res);
  };
  

  const fetchServiceInstance = async (val: any) => {
    // const fetchService = new TenantService();
    // const result = await fetchService.getIndividualService(val);
    // if (result) {
    //     setServiceInstance(result)
    //   return result;
    // }
    const req = {
      UserMemberId: props?.userDetails?.UserMemberId,
      PackageInstanceId: val,
    };
    const service = new PackageService();
    const res = await service?.packageInstanceFilter(req);
    console.log(res?.Items[0]);
    if (res?.Items[0]) {
      setServiceInstance(res?.Items[0]);
      return res?.Items[0];
    }
  };
  const updateServiceData = async () => {
    const {
      Cost,
      AmountPaid,
      Name,
      PackageInstanceId,
      Occurrs,
      NoOfInstallments,
      RepeatFrequency,
      CancollectTaxes,
      Frequency,
    } = await fetchServiceInstance(props?.serviceInstance?.PackageInstanceId);
    let diffamount = Cost - AmountPaid;
    diffamount = parseFloat(diffamount.toFixed(2));
    const Servicedetails = {
      PackageCost: Cost,
      PackageCostAfterDiscount: Cost,
      RepeatFrequency: RepeatFrequency,
      Name: Name,
      AmountPaid: AmountPaid,
      PackageInstanceId: PackageInstanceId,
      OcurrsEvery: Occurrs,
      NoOfInstallments: NoOfInstallments,
      CancollectTaxes: CancollectTaxes,
      Frequency: Frequency,
    };
    setIsTaxSkipped(!CancollectTaxes);
    setserviceData(Servicedetails);
    setAmount(diffamount);
    setPayRemainingDue(diffamount);
    setRemDueRecurrence(false);
    await fetchPaymentProfiles(props?.userDetails?.UserMemberId);
  };
  const refreshPaymentProfiles = (modeVal=null) => {
    fetchPaymentProfiles(props?.userDetails?.UserMemberId,true,modeVal);
  };

  
  const makePaymentProfileAsDefault = async (dataItem: any) => {
    const req = {
      PaymentGatewayPaymentProfileID: dataItem?.PaymentGatewayPaymentProfileID,
      UserMemberId: props?.userDetails?.UserMemberId,
      CustomerProfileID:
        dataItem?.CustomerProfileID,
    };
    await axiosRequest.post(
      `PaymentProfile/update-preferred-payment-profile`,
      req,
      {
        successCallBack: async (response: any) => {
          props?.handleNotificationMessage(
            `Selected Payment profile marked as Preferred`,
            "success"
          );
          await fetchPaymentProfiles(props?.userDetails?.UserMemberId);
        },
        errorCallBack: (response: any) => {
          props?.handleNotificationMessage(
            response?.response?.data?.Messages?.[0] ||
              response?.data?.Messages?.[0] ||
              "Internal server error",
            "error"
          );
        },
      }
    );
  };
  //to get the payment profiles
  async function fetchPaymentProfiles(
    userMemberId: any,
    clearAdditionPP = true,
    modeVal=null
  ) {
    const paymentProfileData = new TenantService();
    const result = await paymentProfileData.paymentProfile(userMemberId);
    if (result) {
      if (result.length > 0) {
        if(modeVal){
          const record=result?.find((i:any)=> i?.CardTypeId ===-1)
          if(record){
            const data={
              ...record,
              CardTypeId: -2,
              MaskedCCNumber: modeVal,
              CardDescription: modeVal,
              CardDescriptionWithoutExpiryDate: modeVal,
              PaymentProfileID:parseInt(`2${record?.PaymentProfileID}`)
            };
            const res=[...result]
            res.push(data)
            setPaymentProfile(JSON.parse(JSON.stringify(res)))
            handleChangeSavedCard(data)
          }
        }else{

          const res = result.filter((item: any) => item.CardTypeId !== -1);
          setPaymentProfile(result);
          const test = result.filter(
            (item: any) =>
              item?.PaymentGatewayPaymentProfileID ===
              serviceInstance?.PaymentGatewayProfileId
          );
          if (clearAdditionPP) {
            setSelectedPaymentProfile(test[0]);
            setAlternatePaymentProfileId(null);
          }else{
            const defaultPP = result.find((card: any) => card.IsDefault);
            if(defaultPP){
              handleChangeSavedCard(defaultPP)
            }
          }
          return res;
        }
      }
    }
  }

  //recurrences calculations
  const CalculateRecurrence = (
    item: any,
    totalCost: any,
    paidToday: any,
    check: boolean
  ) => {
    console.log(item, totalCost, paidToday, check, "iteeeeeeeeeeeeeeeeeeee");
    const data = [];
    if (
      paidToday >= 0 &&
      item.recurrenceUnit != undefined &&
      item.NoOfInstallments > 0 &&
      item.NextRecurrence != undefined
    ) {
      if (totalCost >= paidToday) {
        let BalanceAmount = totalCost - paidToday;
        let NoOfInstallments =
          item.NoOfInstallments > 999 ? 999 : item.NoOfInstallments;
        if (BalanceAmount > 0) {
          let InstallmentAmount = BalanceAmount / NoOfInstallments;
          InstallmentAmount = parseFloat(InstallmentAmount.toFixed(2));
          let unit = item.recurrenceUnit.text;
          let InstallmentDate = new Date(item.NextRecurrence);
          let totalAmt = 0;
          for (let i = 0; i < NoOfInstallments; i++) {
            totalAmt = totalAmt + InstallmentAmount;
            if (BalanceAmount !== totalAmt && i === NoOfInstallments - 1) {
              InstallmentAmount =
                InstallmentAmount + (BalanceAmount - totalAmt);
            }

            data.push({
              Date: InstallmentDate,
              DisplayDate: getLongDate(InstallmentDate),
              InstallmentAmount: InstallmentAmount,
            });

            InstallmentDate = addDates(
              new Date(InstallmentDate),
              parseInt(item.OccursEvery),
              unit
            );
          }
        }
      }
    }
    if (!check) {
      setPayDueRecurrencetable(data);
    }
  };
  const handleWaivePayment = (val: boolean) => {
    setIsWaivePayment(val);

    updateServiceData();
  };

  const handleChangeSavedCard = (val: any) => {
    setSelectedPaymentProfile(val);
    setAlternatePaymentProfileId(null);
  };

  const checkPayRemainingDue = (val: any) => {
    let value = val;
    if (!val || val === "") {
      value = 0;
    }
    if (val < 0) {
      value = Math.abs(val);
    }
    const { Cost, AmountPaid } = serviceInstance;
    const totalAmtToPay = Cost - AmountPaid;
    if (value === totalAmtToPay) {
      setPayRemainingDue(value);
      setRemDueRecurrence(false);
      setSubmitStatus({
        ...submistatus,
        payRemDue: true,
      });
      handleTaxCalculation(value);
    } else if (value < totalAmtToPay) {
      setPayRemainingDue(value);
      setRemDueRecurrence(true);
      setSubmitStatus({
        ...submistatus,
        payRemDue: true,
      });
      handleTaxCalculation(value);
    } else {
      setPayRemainingDue(totalAmtToPay);
      setRemDueRecurrence(false);
      setSubmitStatus({
        ...submistatus,
        payRemDue: false,
      });
      handleTaxCalculation(totalAmtToPay);
    }
  };

  React.useEffect(() => {
    if (payRemainingDue >= 0) {
      const amounttoUpdate =
        serviceInstance?.Cost - serviceInstance?.AmountPaid;
      CalculateRecurrence(
        remDueRecurDetails,
        amounttoUpdate,
        payRemainingDue,
        false
      );
    }
  }, [payRemainingDue]);

  const handlePayRemainingDue = (val: any, name: string) => {
    const { Cost, AmountPaid } = serviceInstance;
    const amounttoUpdate = Cost - AmountPaid;
    let value = val;
    if (name !== "NextRecurrence" && val < 0) {
      value = Math.abs(val);
    }
    let data = {
      ...remDueRecurDetails,
      [name]: value,
    };
    if (name === "OccursEvery" && parseInt(value) === 0) {
      data = {
        ...remDueRecurDetails,
        [name]: value,
        NoOfInstallments: 1,
      };
    }
    if (name === "NoOfInstallments" && val > 999) {
      data = {
        ...remDueRecurDetails,
        [name]: 999,
      };
    }
    setRemDueRecurDetails(data);
    setSubmitStatus({
      ...submistatus,
      payRemDue: true,
    });
    CalculateRecurrence(data, amounttoUpdate, payRemainingDue, false);
  };
  const handleReset = () => {
    setRemDueRecurrence(false);
    setSubmitPayRemDue(false)
    fetchApis();
    setMakePayment(false);
    updateServiceData();
  };
  const submitPayRemainigDue = async () => {
    setSubmitPayRemDue(true)
    if (!selectedPaymentProfile && !IsWaivePayment) {
      props?.handleNotificationMessage(
        "Please select Payment Profile",
        "error"
      );
      return;
    }
    let purchaseAmt = !IsTaxSkipped
      ? payRemainingDue + TotalTaxAmount
      : payRemainingDue;
    purchaseAmt = CustomToFixed(purchaseAmt, 2);
    if (
      selectedPaymentProfile?.CardTypeId === 8 &&
      purchaseAmt > selectedPaymentProfile?.Credit &&
      !AlternatePaymentProfileId
    ) {
      const errorMsg = CreditsErrorMessage;
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
      // let defaultPaymentProfile=null
      // const existedPP=paymentProfile?.filter((i:any)=> i?.CardTypeId !== -1 && i.CardTypeId !==8)
      // if(existedPP?.length > 0){
      //   defaultPaymentProfile=existedPP[0]
      // }
      // setShowAdditionalPayment({
      //   dialog:true,
      //   selectedPaymentProfile:defaultPaymentProfile
      // })
      // return
    }

    if (
      remDueRecurrence &&
      (!remDueRecurDetails?.OccursEvery ||
        remDueRecurDetails?.OccursEvery <= 0 ||
        !remDueRecurDetails?.NoOfInstallments ||
        remDueRecurDetails?.NoOfInstallments <= 0 ||
        moment(remDueRecurDetails?.NextRecurrence).format("L") ===
          "Invalid date")
    ) {
      return;
    }

    const AmounttoPay = serviceInstance?.Cost - serviceInstance?.AmountPaid;
    const recurAmt = CustomToFixed(
      (AmounttoPay - payRemainingDue) /
      remDueRecurDetails?.NoOfInstallments
    ,2)

    if((selectedPaymentProfile?.CardTypeId === -1  || selectedPaymentProfile?.CardTypeId === -2)){
      if(AmounttoPay !== payRemainingDue){
        let errormsg = `Can't use cash payments with recurrence service`;
        if(selectedPaymentProfile?.CardTypeId === -2){
          errormsg = `Can't use ${selectedPaymentProfile?.MaskedCCNumber} payments with recurrence service`;
        }
       props?.handleNotificationMessage(errormsg, "error");
       return true;
     }
   }

   let paymentAttributes = null;
   if (selectedPaymentProfile?.CardTypeId === -2) {
     paymentAttributes = {
       Reference: refNumber,
       OfflinePaymentDescription: selectedPaymentProfile?.MaskedCCNumber,
       ReferrenceDocumentName: files[0]?.FileName || "",
       ReferrenceDocumentContent: files[0]?.ImageAsBase64 || "",
     };
   }else if (selectedPaymentProfile?.CardTypeId === -1){
      paymentAttributes = {
        Reference: "",
        OfflinePaymentDescription: "Cash",
        ReferrenceDocumentName: "",
        ReferrenceDocumentContent: "",
      };
   }

    const req: any = {
      PaymentGatewayPaymentProfileId:
        selectedPaymentProfile?.PaymentGatewayPaymentProfileID,
      DownPayment: payRemainingDue,
      CanCollectTaxes: !IsTaxSkipped ? true : false,
      PackageInstanceId: serviceInstance?.PackageInstanceId,
      IsWaivePayment: IsWaivePayment,
      PaymentAttributes:paymentAttributes,
      RecurringDetail:
        AmounttoPay === payRemainingDue
          ? null
          : {
              NoOfInstallments: parseInt(remDueRecurDetails?.NoOfInstallments),
              NextRecurringDate: moment(
                remDueRecurDetails?.NextRecurrence
              ).format("L"),
              Occurrence: parseInt(remDueRecurDetails?.NoOfInstallments),
              RepeatFrequency: parseInt(remDueRecurDetails?.OccursEvery),
              RecurringAmount: recurAmt,
              FrequencyEndType:"0",
              Frequency: remDueRecurDetails?.recurrenceUnit?.id?.toString(),
            },
    };
 
    setpayRemDueSubmitLoading(true)
   
    await axiosRequest.post("Packageinstance/PayRemaining", req, {
      successCallBack: (response: any) => {
        const successMsg = "Remaining Due paid successfully";
        props?.handleNotificationMessage(successMsg, "success");
        props?.onSuccessfullPayment();
        setRemDueRecurrence(false);
        fetchApis();
        setMakePayment(false);
        setSubmitPayRemDue(false)
        setpayRemDueSubmitLoading(false)

      },
      errorCallBack: (response: any) => {
        console.log(response?.response?.data)
        const errorMsg =
          response?.response?.data?.Messages?.[0] || response?.data?.Messages?.[0] ||
          "An error has occurred.";
        props?.handleNotificationMessage(errorMsg, "error");
        setpayRemDueSubmitLoading(false)
      },
    });
  };

  const fetchApis = async () => {
    await updateServiceData();
    await fetchPaymentProfiles(props?.userDetails?.UserMemberId);
  };
  const fetchTaxes = async () => {
    const req = {};
    const taxService = new ManageTaxesService();
    const res = await taxService.getTaxes(req);
    setTaxes(res);
  };

  const handleMakePayment = () => {
    setMakePayment(true);
    handleTaxCalculation(payRemainingDue);
  };
  const handleChangeAdditionalPaymentProfile = async (value: any) => {
    setShowAdditionalPayment({
      ...showAdditionalPayment,
      selectedPaymentProfile: value,
    });
  };

  const handleAddAdditionalPayment = () => {
    if (
      !showAdditionalPayment?.selectedPaymentProfile
        ?.PaymentGatewayPaymentProfileID
    ) {
      const errorMsg = "Please select/add Payment profile";
      props?.handleNotificationMessage(errorMsg, "error");
      return;
    }
    setAlternatePaymentProfileId(
      showAdditionalPayment?.selectedPaymentProfile
        ?.PaymentGatewayPaymentProfileID
    );
    setShowAdditionalPayment(initialAdditionalPayment);
  };

  const handleTaxCalculation = (purchaseAmt: number, TaxItems = Taxes) => {
    const TaxesArray = TaxItems.map((i: any) => {
      const amount = (purchaseAmt * i?.Percentage) / 100;
      const calcAmt = CustomToFixed(amount, 2);
      return {
        TaxId: i?.TaxId,
        TaxName: i?.TaxName,
        Percentage: i?.Percentage,
        Amount: calcAmt,
      };
    });
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
      return acc + currentValue?.Amount;
    }, 0);
    setTaxes(TaxesArray);
    setTotalTaxAmount(taxAmt);
  };

  return {
    makePayment,
    amount,
    IsWaivePayment,
    handleMakePayment,
    handleWaivePayment,
    paymentProfile,
    selectedPaymentProfile,
    handleChangeSavedCard,
    payRemainingDue,
    checkPayRemainingDue,
    remDueRecurrence,
    handlePayRemainingDue,
    remDueRecurDetails,
    payDueRecurrencetable,
    handleReset,
    submistatus,
    payRemDueSubmitLoading,
    submitPayRemainigDue,
    refreshPaymentProfiles,
    Taxes,
    TotalTaxAmount,
    serviceData,
    IsTaxSkipped,
    setIsTaxSkipped,
    setShowPaymentDialog,
    setAddNewItem,
    showPaymentDialog,
    addNewItem,
    onCardOrBankAdd,
    setShowAdditionalPayment,
    handleChangeAdditionalPaymentProfile,
    initialAdditionalPayment,
    handleAddAdditionalPayment,
    showAdditionalPayment,
    fetchPaymentProfiles,
    submitPayRemDue,
    onAdd,
    onRemove,
    originalFiles,
    refNumber,
    setRefNumber,
    makePaymentProfileAsDefault
  };
};

export default usePayRemaingDue;
