import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ListView } from "@progress/kendo-react-listview";
import BuzopsButton from "generic-components/BuzopsButton";
import { FormInput } from "utils/form-components";
import { CurrencyFormat, checkHasTaxEnable } from "utils";
import { Checkbox, Switch } from "@progress/kendo-react-inputs";
import {
  DropDownList,
  ListItemProps,
  MultiSelect,
} from "@progress/kendo-react-dropdowns";
import successCheck from "./../../Images/successCheck.png";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MemberSelection from "components/submemberseletion/MemberSelection";
import { Error, Hint } from "@progress/kendo-react-labels";
import { Popup } from "@progress/kendo-react-popup";
import PackageOrderSummary from "./PackageOrderSummary";
import OneonOneOrderSummary from "./OneonOneOrderSummary";
import GroupOrderSummary from "./GroupOrderSummary";
import { PackageFrequency, PackageFrequencyEnum } from "utils/form-utils";
import bzImages from "./../../Images";
export const TooltipContentPackageService = (propData: any) => {
  if (propData?.data?.length === 0) {
    return <div>{"No Surcharges"}</div>;
  }
  return (
    <>
      <ul className="list-group list-group-flush taxes-list">
        {propData?.Name && (
          <li className="list-group-item" style={{ fontSize: '0.875rem', fontWeight: 600 }}>{propData?.Name}</li>
        )}

        {propData?.data?.map((i: any) => (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>
              {i?.TaxName}&nbsp;({i?.Percentage}%)
            </span>
            <span className="price">{CurrencyFormat(i?.Amount)}</span>
          </li>
        ))}
        {propData?.TotalTaxAmount > 0 && (<li className="list-group-item d-flex justify-content-between align-items-center">
          <span>
            {"Total Surcharges"}
          </span>
          <span className="price">{CurrencyFormat(propData?.TotalTaxAmount)}</span>
        </li>)}
      </ul>
    </>
  );
};
export const CustomPopup = (props: any) => {
  return (
    <Popup
      {...props}
      anchorAlign={{
        horizontal: "center",
        vertical: "top",
      }}
      popupAlign={{
        horizontal: "center",
        vertical: "top",
      }}
    />
  );
};
const OrderSummary = (props: any) => {
  const { allClientsList } = props;

  const renewOptionsFull = [
    {
      id: 0,
      text: "None",
    },
    // {
    //   id: 1,
    //   text: "Renew after Last payment completion",
    // },
    {
      id: 2,
      text: "Renew after Last Appointment Completion",
    },
  ];
  const renewOptionsNOLastPayment = [
    {
      id: 0,
      text: "None",
    },
    {
      id: 2,
      text: "Renew after Last Appointment Completion",
    },
  ];
  const { FirstName, LastName, Email } = props.clientFormValues;

  const {
    appliedCoupons,
    visibleCouponField,
    packageCost,
  } = props;


  const { paidToday } = props.downpaymentForm;
  const MyCouponItemRender = (listItems: any) => {
    let item = listItems.dataItem;
    const amt = item?.couponAmt
    let showDismiss = true
    if (props.page === "online-package") {
      showDismiss = props?.package?.CouponDescription ? false : true
    }
    if (props.page === "service") {
      showDismiss = props?.serviceData?.CouponDescription ? false : true
    }

    return (
      <>
        <div
          className="alert alert-success alert-dismissible bz-apply-coupon-new coupon-oneonone fade show mb-2"
          role="alert"
        >
          <h6 className="alert-heading">
            <img
              src={successCheck}
              style={{ width: "15px", height: "15px" }}
              alt=""
            />
            {item.CouponInformation}
          </h6>
          <p className="bz-coupon-desc">
            <span>Type:&nbsp; {item.TypeDescription},</span>&nbsp;
            <span>Coupon Amount: - {CurrencyFormat(amt)}</span>
          </p>
          {showDismiss && <button
            type="button"
            className="close"
            title="Delete"
            onClick={() => {
              props.handleRemoveCoupon(listItems.index);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>}
        </div>
      </>
    );
  };

  const CouponApplyField = () => {
    return (
      <Form
        render={(couponRenderForm: any) => (
          <>
            <FormElement style={{ width: "100%" }}>
              <div className="row bz-coupon-field mt-0">
                <div className="col-md-12">
                  <div className="input-group mb-0">
                    <Field
                      id={"coupon"}
                      name={"coupon"}
                      component={FormInput}
                      placeholder="Enter Coupon Code"
                      hidelabel={true}
                      onChange={(e) => {
                        props.handleChangeCoupon(e.value);
                      }}
                      value={props.coupon}
                    />
                    <div className="input-group-append">
                      <BuzopsButton
                        primary
                        label={"Apply"}
                        disable={props.disable}
                        loading={props.couponBtnLoading}
                        onClick={() => props.handleCoupon()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </FormElement>
          </>
        )}
      />
    );
  };
  const itemRenderCoupon = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    let itemChildren;
    const index = itemProps?.dataItem?.Amount;
    if (itemProps?.dataItem?.TypeDescription === "Percentage") {
      itemChildren = (
        <span style={{ fontSize: "18px" }}>
          {itemProps?.dataItem.Description} <br />
          {index} {"%"}
        </span>
      );
    } else if (itemProps?.dataItem?.TypeDescription === "Fixed") {
      itemChildren = (
        <span style={{ fontSize: "18px" }}>
          {itemProps?.dataItem.Description} <br />
          {CurrencyFormat(index)}
        </span>
      );
    }

    return React.cloneElement(li, li.props, itemChildren);
  };

  const ApplyMultipleCouponField = () => {
    return (
      <div className="">
        <label className="d-flex justify-content-start">Coupons</label>
        <div>
          <MultiSelect
            data={props?.filterCoupondata}
            textField="Description"
            dataItemKey="CouponId"
            placeholder="Please select coupon"
            onChange={(e) => {
              if (props?.page === "service") {
                props?.handleOneonOneCoupon(e.value);
              } else if (props?.page === "online-class" || props?.page === "inhouse-class") {
                props?.handleGroupCoupon(e.value);
              } else {
                props?.handlePackageCoupon(e.value);
              }
            }}
            
            filterable={true}
            onFilterChange={(e) => props?.filterCouponChange(e)}
            itemRender={itemRenderCoupon}
            value={appliedCoupons}
            className="bz-multiselect-arrow"
          />
        </div>
        <Hint className="d-flex justify-content-start">
          <span className="form-text text-muted text-left">
            Select a coupon from the list applicable for the package selected
            above.
          </span>
        </Hint>
      </div>
    );
  };
  const calculateCost = (arrayItem: any) => {
    const cost = arrayItem?.reduce((acc: Number, currentValue: any) => {
      return acc + currentValue?.Cost;
    }, 0);
    return cost;
  };
  const NewMyItemRender = (itemProps: any) => {
    let item = itemProps.dataItem;
    // const NotWaitList=item?.values?.filter((i:any)=> !i?.CanBookAsWaitlist)
    return item?.values?.length > 0 ? (
      <>
        <div className={"mb-1 order-summary-class-details"}>
          <span className={"order-summary-class-name"}>
            {item?.className}
          </span>
          <span className={"order-summary-class-cost"}>
            {CurrencyFormat(calculateCost(item?.values))}
          </span>
        </div>
        {checkHasTaxEnable() &&
          item?.CanCollectTaxes &&
          (props?.staffPage || props.page === "inhouse-class") && (
            <div
              className={
                "d-flex align-items-center text-left mb-1 buz-text-small buz-font-500 mt-1"
              }
            >
              <Switch
                checked={!item?.IsTaxSkipped}
                defaultChecked={false}
                name={`check${item?.ClassId}`}
                onChange={(e: any) =>
                  props.handleIsSessionTaxSkipped(!item?.IsTaxSkipped, item)
                }
                className="mr-1 d-inline-flex"
              />
              {`Surcharges`}
            </div>
          )}
        
        {item.values?.length > 0 &&
          item.values.map((value: any) => {
            return (
              value.Coupons?.length > 0 &&
              value.Coupons.map((i: any) => (
                <div className="plan-summary-card-row pb-2 groupordercheckoutsummary" key={i?.CouponInformation}>
                  <div className="planleftCol">
                    {i?.CouponInformation}
                  </div>
                  <div className="planrightCol">
                    {`(-${CurrencyFormat(i?.couponAmt)})`}
                  </div>
                </div>
              ))
            );
          })
        }
      </>
    ) : (
      <></>
    );
  };
  const groupServiceCard = () => {
    return (
      <>
        {" "}
        <p className={"text-left pt-1 pb-1 buz-font-600 mt-2"}>
          Group<span className={"float-right"}></span>
        </p>
        <ListView
          data={props.session}
          item={(props) => NewMyItemRender(props)}
          className="mt-2"
        />
      </>
    );
  };
  const termsAndSignatureGrid = (props: any, paidTodayAmt: number) => {
    let checkoutLabel = "Purchase";
    if (props.page === "inhouse-class") {
      checkoutLabel = "Enroll";
    }
    if (props?.SessionSignOff) {
      checkoutLabel = "Enroll";
    }
    return (
      <>
        <div className="signature-terms mt-3">
          <div
            className={`signature-section ${props.page === "inhouse-class" || props.page === "online-class"
              ? "bz-agree-checkbox"
              : ""
              }`}
          >
            {props.page !== "inhouse-class" &&
              props.page !== "online-class" && (
                <div
                  className={
                    props?.signatureType === "type"
                      ? `buz-signature-type mt-3`
                      : `buz-signature mt-3`
                  }
                  onClick={() => {
                    props.handleSignaturePad();
                  }}
                // style={{ display: "flex", justifyContent: "center" }}
                >
                  {props.signature !== "" ? (
                    props.signatureText && props.signatureFont ? (
                      <span
                        className="text-signature"
                        style={{
                          fontFamily: props.signatureFont,
                          alignSelf: "center",
                          fontSize:
                            props?.signatureType === "type" ? `25px` : `36px`,
                          overflow: "visible",
                          padding:
                            props?.signatureType === "type" ? `2px` : `0px`,
                        }}
                      >
                        {props.signatureText}
                      </span>
                    ) : (
                      <img
                        src={props.signature}
                        placeholder={"Please Sign"}
                        width={"100%"}
                        height={"100%"}
                        onClick={() => {
                          props.handleSignaturePad();
                        }}
                      ></img>
                    )
                  ) : (
                    <div className="buz-signature-pad">
                      <img src={bzImages.SignHereImg} alt="Sign-here" />
                    </div>
                  )}
                </div>
              )}
            {props.page !== "inhouse-class" &&
              props.page !== "online-class" && (
                <p
                  className={
                    "text-left mb-1 buz-text-small buz-font-500 agree-copy"
                  }
                >
                  {props.disable ? (
                    <>
                      <a className={"disable_link"}>View Your Agreement Copy</a>
                    </>
                  ) : (
                    <>
                      <a
                        className={"buzops_link "}
                        onClick={() =>
                          props.handleAgreement({
                            renewalType: "",
                            canRenew: false,
                          })
                        }
                      >
                        View Your Agreement Copy
                      </a>
                    </>
                  )}
                </p>
              )}
            <div className={"text-left mb-0 buz-text-small buz-font-500"}>
              <Checkbox
                checked={props.termsandConditions}
                defaultChecked={false}
                disabled={props.disable}
                onChange={() => props.handleTermsConditionDialog(!props.termsandConditions)}
              />{" "}
              I agree to the{" "}
              <span className={"mb-1 buz-text-small buz-font-600 agree-copy"}>
                {/* <a
                      className={"buzops_link "}
                      onClick={() => props.handleTermsConditionDialog()}
                    >
                      {"Terms & Conditions"}
                    </a> */}
                {props.disable ? (
                  <>
                    <a className={"disable_link"}>{"Terms & Conditions"}</a>
                  </>
                ) : (
                  <>
                    <a
                      className={"buzops_link "}
                      onClick={() => props.handleTermsConditionDialog()}
                    >
                      {"Terms & Conditions"}
                    </a>
                  </>
                )}
              </span>
            </div>
          </div>

          <div className="button-submit-section">
            <BuzopsButton
              primary
              label={checkoutLabel}
              disable={props.disable}
              loading={props.btnLoading}
              key={props.btnLoading}
              onClick={() =>
                props.handlePayNow({
                  renewalType: "",
                  canRenew:  false,
                })
              }
            />
          </div>
        </div>
      </>
    );
  };

  const showPageType = (props: any) => {
     if (
      props.page === "inhouse-class" ||
      props.page === "online-class"
    ) {
      return groupServiceCard();
    } else if (
      props.page === "inhouse-package" ||
      props.page === "online-package"
    ) {
      const packageFreq = PackageFrequency?.filter((i: any) => i?.id === props?.package?.Frequency)[0]?.description

      return (
        <>
          <p className={"text-left pt-1 pb-2 buz-font-600 mt-2"}>
            Packages <span className={"float-right"}></span>
          </p>
          <div className={"mb-1 order-summary-class-details"}>
            <span className={"order-summary-class-name"}>
              {props?.package?.Name}
              {props?.package?.Frequency !== PackageFrequencyEnum.ONETIME && <small>{` (${packageFreq})`}</small>}
            </span>
            <span className={"order-summary-class-cost"}>
              {CurrencyFormat(props?.staffPage ? props?.package?.DiscountedCost : props?.package?.Cost)}
            </span>
          </div>
          {props?.package?.IsProrated && props?.package?.ProratedCost > 0 && <div className={"mb-1 order-summary-class-details"}>
            <span className={"order-summary-class-name"}>
              {'Prorated Amount'}
            </span>
            <span className={"order-summary-class-cost"}>
              {CurrencyFormat(props?.package?.ProratedCost)}
            </span>
          </div>}
        </>
      );
    }
  };


  const today1 = new Date();
  const threeMonths = new Date(today1);
  threeMonths.setMonth(threeMonths.getMonth() + 3);

  return (
    <>

      <Card
        className={`text-center order-summary-card order-summary-card border-0 row ${props?.staffPage ? "staff-order-summary-card" : "client-order-summary"
          }`}
      >

        <CardHeader
          className={"text-left pb-0 order-summary-header d-flex align-items-center"}
        >
          <h6 className={"card-heading"}>Order Summary</h6>
        </CardHeader>
        {(props?.page === "inhouse-package" || props?.staffPage) && (
          <div
            className="savedCard bz-salesPerson-select pb-2 pt-0 mt-2 px-0 text-left"
            style={{ marginTop: "-5px" }}
          >
            <label>Sales Person</label>
            <DropDownList
              data={props?.salesPersons}
              textField="UserName"
              dataItemKey="UserId"
              listNoDataRender={() => (
                <span className={"bzo-paymentProfile-noData"}>
                  No Sales Person Available
                </span>
              )}
              value={props?.selectedSalesPerson}
              style={{ width: "100%" }}
              onChange={(e) => props.handleSalesPersonSelection(e.value)}
            />
            {!props?.selectedSalesPerson && (
              <Error>{"Please select SalesPerson"}</Error>
            )}
          </div>
        )}
        <CardBody className="px-0">
          {Object.keys(props.clientFormValues).length !== 0 && (
            <>
              <div>
                <p className={"text-left pb-2 buz-font-600"}>Purchase/Enrollment For*</p>
                {props.page === "inhouse-class" ||
                  props.page === "online-class" ||
                  props.page === "inhouse-package" || props?.IsFromCalendar ? (
                  <>
                    <p className={"text-left buz-font-500 client-name-label "}>
                      {/* <span className="order-summary-title">Purchase/Enrollment For: </span> */}
                      <span className="client-name">
                        {FirstName} {LastName}
                      </span>
                    </p>
                    <p className={"text-left buz-font-500 client-name-label"}>
                      <span className="order-summary-title">Email: </span>
                      <span>{Email}</span>
                    </p>
                  </>
                ) : (
                  <div style={{ display: "flex" }}>
                    <MemberSelection
                      allClientsList={allClientsList}
                      selectedUsermemberId={
                        props.clientFormValues?.UserMemberId
                      }
                      handleUpdateClient={(val: any) =>
                        props?.updateSelectedClientData(val)
                      }
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {showPageType(props)}

        
          {(
              (props.page === "service" && !props?.staffPage) ||
              (!props?.staffPage &&
                props.page === "online-package" &&
                props?.package?.Cost > 0) 
            ) && (
              <>
                <div className="coupon-list mt-3">
                  <ListView data={appliedCoupons} item={MyCouponItemRender} />
                </div>
                {appliedCoupons.length === 0 && CouponApplyField()}
                {appliedCoupons.length > 0 && visibleCouponField && CouponApplyField()}
              </>
            )}


          {((props.page === "service" && props?.staffPage) || (props?.staffPage &&
            props.page === "online-package" &&
            props?.package?.Cost > 0) ) && (
              <>
                <ApplyMultipleCouponField />
                <div className="coupon-list mt-3">
                  <ListView data={appliedCoupons} item={MyCouponItemRender} />
                </div>
              </>
            )}

          {(props.page === "inhouse-package" ||
            props.page === "online-package") && (
              <PackageOrderSummary {...props} />
            )}
          {props.page === "service" && <OneonOneOrderSummary {...props} />}
          {(props.page === "inhouse-class" ||
            props.page === "online-class") && (
              <>
                <GroupOrderSummary {...props} />
              </>
            )}
          {termsAndSignatureGrid(props, paidToday)}
        </CardBody>
      </Card>
    </>
  );
};

export default OrderSummary;
