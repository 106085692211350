import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import BuzopsLoader from "generic-components/BuzopsLoader";
import BuzopsButton from "generic-components/BuzopsButton";
import withNotification from "components/Hoc/withNotification";
import useRefundCredits from "./RefundCredits.hook";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { CurrencyFormat } from "utils";
const descriptionCell = (props: any) => {
  const Attributes = props?.dataItem?.Attributes
    ? JSON.parse(props?.dataItem?.Attributes)
    : null;
  return <td>{Attributes?.OfflinePaymentDescription ?? "N/A"}</td>;
};

const AmountCell = (props: any) => {
  return <td >{CurrencyFormat(props?.dataItem[props?.field])}</td>;
};
const RefundCredits = (props: any) => {
  const { loading, data, btnLoading, handleSubmit } = useRefundCredits(props);

  return (
    <Dialog
      title={"Refundable Transactions"}
      onClose={() => {
        props?.handleCloseDialog("refund");
      }}
      className="bz-addCard-dialog bz-refund-modal"
      width={"65%"}
    >
      {loading ? (
        <BuzopsLoader type="list" />
      ) : (
        <Grid
          data={data}
          className="bzc-grid bz-checkins-grid prospectdetails"
          style={{ maxHeight: "400px", overflow: "auto" }}
        >
          <GridColumn title="Transaction Date" field="TransactionDate" />
          <GridColumn title="Refund Amount" field="RefundAmount"     cell={AmountCell} />
          <GridColumn title="Transaction ID" field="TransactionID" />

          <GridColumn
            title="Payment Description"
            field="Attributes"
            cell={descriptionCell}
          />
        </Grid>
      )}

      <DialogActionsBar>
        <Button
          onClick={() => props?.handleCloseDialog("refund")}
          disabled={loading}
        >
          {"Cancel"}
        </Button>
        <BuzopsButton
          onClick={() => handleSubmit()}
          disabled={btnLoading || loading}
          loading={btnLoading}
          label={"Refund"}
        />
      </DialogActionsBar>
    </Dialog>
  );
};

export default withNotification(RefundCredits);
