import { useState, useEffect } from "react";
import moment from "moment";
import { PackageService } from "services/package/index.service";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { Button } from "@progress/kendo-react-buttons";
import { CheckUserMemberStatus } from "utils";
import withNotification from "components/Hoc/withNotification";
import ClientNameWithMemberType from "../ClientNameWithMemberType";
import {
  PackageInstanceStatus,
  PackageInstanceStatusEnum,
  SessionTypeEnum,
  TypeEnum,
} from "utils/form-utils";
import { Tooltip } from "@progress/kendo-react-tooltip";
import PurchaseClassList from "components/class/PurchaseClassList";
import BuzopsLongNameHandler from "generic-components/BuzopsLongNameHandler";
import { useHistory } from "react-router-dom";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { useSelector } from "react-redux";
import { TenantService } from "services/tenant/index.service";

const PurchasedPackages = (props: any) => {
  const service = new PackageService();
  const history = useHistory();
  const [packagesList, setPackagesList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [groupDialog, setGroupDialog] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<any>(undefined);
  const [packagesToUse, setPackagesToUse] = useState<any>([]);
  const showOnlyPreset = props?.showOnlyPreset || false;
  const redirectionObj = useSelector((state: any) => state?.redirection);

  useEffect(() => {
    getPackages();
    fetchPackagesToUse();
  }, [showOnlyPreset]);
  useEffect(() => {
    if (props?.location?.state?.redeemPackage) {
      setSelectedPackage(props?.location?.state?.packageDetails);
      toggleGroupDialog();
    }
  }, [props?.location?.state?.redeemPackage]);

  const fetchPackagesToUse = async () => {
    let startDate = moment().format("YYYY-MM-DD") + "T00:00:00";
    let ToDate = moment().add(1, "year").format("YYYY-MM-DD") + "T23:59:59";
    const req: any = {
      UserMemberId: props?.userData?.UserMemberId,
      FromDate: startDate,
      ToDate,
      IncludeSubMember: true,
    };

    if (showOnlyPreset) {
      req.SessionType = 3;
    }
    const result = await service.packageInstanceToUse(req);
    setPackagesToUse(result || [null]);
  };

  const checkBillingCycleValidation = (selectedPackageId: any) => {
    const dataItem = packagesToUse?.find(
      (i: any) => i?.PackageInstanceId === selectedPackageId
    );
    const records = dataItem?.BillingPeriods?.filter(
      (i: any) => i?.SchedulesToUse > 0
    );
    if (records?.length > 0 || dataItem?.CanScheduleAppointment) {
      return true;
    }
    return false;
  };
  const checkPresetBillingCycleValidation = (selectedPackageId: any) => {
    const dataItem = packagesToUse?.find(
      (i: any) => i?.PackageInstanceId === selectedPackageId
    );
    if (dataItem?.SchedulesToUse > 0 || dataItem?.CanScheduleAppointment) {
      return true;
    }
    return false;
  };

  const getPackages = async () => {
    let memberId = props?.userData?.UserMemberId;
    const req: any = {
      PageNumber: 0,
      PageSize: 32767,
      UserMemberId: memberId,
      SortBy: "PackageInstanceId",
      SortDirection: "ascending",
    };
    if (showOnlyPreset) {
      req.SessionType = 3;
    }
    if (memberId) {
      setLoading(true);
      const res = await service?.packageInstanceFilter(req);
      setLoading(false);
      setPackagesList(res?.Items || []);
    }
  };
  const handleGotoPurchasePage = (name: string) => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    props?.handlePurchase(name);
  };

  async function fetchClientData(userMemberId: any) {
    const clientservice = new TenantService();
    let result: any = await clientservice.getClient(userMemberId);
    if (result) {
      result = {
        Email: result?.User?.Email,
        FirstName: result?.User?.FirstName,
        LastName: result?.User?.LastName,
        PrimaryMemberId: result?.UserMemberId,
        UserId: result?.UserId,
        UserMemberId: result?.UserMemberId,
        MemberNumber: result?.MemberNumber,
        Status: result?.Status,
      };
    }
    return result;
  }

  const handleTogglePurchasePage = async (
    e: any,
    val: any,
    disabled = false
  ) => {
    e.stopPropagation();
    if (disabled) {
      return false;
    }
    console.log(disabled, "disabled");
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    const userDetails = await fetchClientData(val?.UserMemberId);

    const dataItem = packagesToUse?.find(
      (i: any) => i?.PackageInstanceId === val?.PackageInstanceId
    );
    setSelectedPackage(val);
    if (dataItem?.Type === 1) {
      toggleGroupDialog();
    } else {
      if (props?.staffPage) {
        const urlRedirection = `/member/${props?.uuid}/${props?.userData?.UserMemberId}/scheduleappointment`;
        history.push(urlRedirection, {
          selectedPackageInstanceId: val?.PackageInstanceId,
          staffPage: true,
          userData: userDetails ? userDetails : props?.userData,
          Complimentary: false,
          redirectedFrom: "purchasedpackage",
        });
      } else {
        const obj = {
          userData: props?.userData,
          staffPage: false,
          selectedPackageInstanceId: val?.PackageInstanceId,
          Complimentary: false,
          redirectedFrom: "purchasedpackage",
        };
        if (redirectionObj?.firstPathValue !== "") {
          history.push(
            `/${redirectionObj?.firstPathValue}/scheduleappointment`,
            obj
          );
        } else {
          history.push(`/scheduleappointment`, obj);
        }
      }
    }
  };

  const TooltipContentTemplate = (props: any) => {
    if (props?.data?.Classes?.length === 0) {
      return <div>{"No Used Appoinments"}</div>;
    }
    return (
      <table className="table table-sm table-borderless">
        {props?.data?.Classes?.map((item: any) => (
          <tr className="border-0 p-0" style={{ width: "100%" }}>
            <td>{item?.Name}</td>
            <td className="text-right">{item?.UsedSchedules}</td>
          </tr>
        ))}
      </table>
    );
  };

  const toggleGroupDialog = () => {
    setGroupDialog(!groupDialog);
  };

  const renderfailedCheckinsDialog = () => {
    let memberId = props?.userData?.UserMemberId;
    return (
      <>
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-primary bz-back-btn btn-sm mr-2"
            onClick={() => toggleGroupDialog()}
          >
            <i className="fas fa-arrow-left"></i>
          </button>
          <h5>{`${selectedPackage?.Name}`}</h5>
        </div>
        <PurchaseClassList
          page={"inhouse"}
          userMemberId={memberId}
          handleWindow={() => toggleGroupDialog()}
          selectedPackage={selectedPackage}
          staffPage={props?.staffPage}
        />
      </>
    );
  };

  const getCurrentBillingPeriod = (billingPeriods:any, billingPeriodFrom: Date) => {
    const now = moment(billingPeriodFrom);
    return billingPeriods?.find((period : any) => {
      const start = moment(period.ExpireFrom);
      const end = moment(period.ExpireOn).endOf('day');
      return now.isBetween(start, end, null, '[]');
    });
  };

  if (loading) {
    return <BuzopsLoader type={"list"} />;
  }

  if (groupDialog) {
    return renderfailedCheckinsDialog();
  }
  return (
    <>
      <div className={"mb-2 purchase-btn-appts "}>
        <Button
          onClick={() => handleGotoPurchasePage("package")}
          className="mr-0"
          primary
        >
          {"Purchase"}
        </Button>
      </div>
      {packagesList.length === 0 ? (
        <div className={"bz-norecords-available text-muted"}>
          No Subscribed Packages{" "}
        </div>
      ) : (
        <div className={"row"}>
          {packagesList.map((val: any, ind: any) => {
            const statusDescription = PackageInstanceStatus?.filter(
              (i: any) =>
                i?.id ===
                (val?.Status === 1 && val?.CancelledOn ? 8 : val?.Status)
            )?.[0]?.text;
            const BillingPeriods = packagesToUse?.find(
              (item: any) => item.PackageInstanceId === val?.PackageInstanceId
            );
            const currentBillingPeriod = getCurrentBillingPeriod(
              BillingPeriods?.BillingPeriods, val?.BillingPeriodFrom              
            );
            let schedulesToUse; 
            if(val?.SessionType === SessionTypeEnum.PRESET){
              schedulesToUse =  val?.Schedules;
            }
            else{
              schedulesToUse = currentBillingPeriod
                ? currentBillingPeriod.SchedulesToUse + val?.SchedulesUsed              
                : val?.Schedules;
          }

            const isButtonEnabled =
              ((val.SessionType === SessionTypeEnum.LIMITED &&
                (!val?.ExpireOn ||
                  moment().format("YYYY-MM-DD") <
                    moment(val?.ExpireOn).format("YYYY-MM-DD")) &&
                checkBillingCycleValidation(val.PackageInstanceId)) ||
                (val.SessionType === SessionTypeEnum.PRESET &&
                  checkPresetBillingCycleValidation(val.PackageInstanceId)) ||
                (val.SessionType === SessionTypeEnum.UNLIMITED &&
                  (!val?.ExpireOn ||
                    moment().format("YYYY-MM-DD") <
                      moment(val?.ExpireOn).format("YYYY-MM-DD")))) &&
              (val?.Status === PackageInstanceStatusEnum.ACTIVE ||
                val?.Status === PackageInstanceStatusEnum.FUTURENOTPAID ||
                val?.Status === PackageInstanceStatusEnum.FUTUREPAID ||
                val?.Status === PackageInstanceStatusEnum.CANCELLED) &&
              (val?.Status !== PackageInstanceStatusEnum.CANCELLED ||
                val?.UnusedPaidSchedules > 0);

            let Type = val?.Type;
            let TypeName;
            if (Type === 1) {
              TypeName = "Group";
            } else if (val?.SessionType === 3) {
              TypeName = "Pre-set";
            } else if (Type === 2) {
              TypeName = "Private";
            } else if (Type === 4) {
              TypeName = "Semi-Private";
            }
            return (
              <div
                className={"col-sm-6 col-md-6 col-xl-4 col-xxl-4"}
                key={val?.PackageInstanceId}
              >
                <div
                  className={`card bz-service-card bz-shadow bz-plans-card pkg-card-padding ${statusDescription}`}
                >
                  <div
                    className={"card-body p-3"}
                    onClick={() => {
                      props.handleSelectionItem(
                        val.PackageInstanceId,
                        statusDescription,
                        "package",
                        val
                      );
                    }}
                  >
                    <div
                      className={"bz-service-card-inner-container d-flex"}
                      style={{ minHeight: "100px", flexDirection: "column" }}
                    >
                      <div style={{ minHeight: "95px" }}>
                        <h5 className={"card-title d-flex align-items-center"}>
                          <span className="bz-plan-title">
                            <BuzopsLongNameHandler
                              name={val.Name}
                              maxLength={22}
                              className="taxName-tooltip"
                            />
                          </span>
                          <span
                            className={`badge badge-status-${statusDescription} pb-2`}
                          >
                            {statusDescription}
                          </span>
                        </h5>
                        <p className={"card-subtitle mb-2 text-muted flex-txt"}>
                          <span className="txt-title">Subscribed On</span>
                          <span className="title-column">:</span>
                          <span>
                            {val.NextRecurrence !== "N/A"
                              ? moment(val.PurchasedOn).format("L")
                              : "N/A"}
                          </span>
                        </p>

                        {val?.NextRecurrence && (
                          <p
                            className={"card-subtitle mb-2 text-muted flex-txt"}
                          >
                            <span className="txt-title">Next Recurrence</span>
                            <span className="title-column">:</span>
                            <span>
                              {moment(val.NextRecurrence).format("L")}
                            </span>
                          </p>
                        )}

                        {val?.CancelledOn &&
                          val?.Status == PackageInstanceStatusEnum.ACTIVE && (
                            <p 
                              className={
                                "card-subtitle mb-2 text-muted flex-txt"
                              }
                            >
                              <span className="txt-title">Cancels On</span>
                              <span className="title-column">:</span>
                              <span>{moment(val.ExpireOn).format("L")}</span>
                            </p>
                          )}
                        <p className={"card-subtitle mb-2 text-muted flex-txt"}>
                          <span className="txt-title">Redeem</span>
                          <span className="title-column">:</span>
                          {val?.SessionType !== SessionTypeEnum.PRESET && (
                            <span>
                              <Tooltip
                                content={(props: any) => {
                                  return (
                                    <TooltipContentTemplate
                                      title={props.title}
                                      data={val}
                                    />
                                  );
                                }}
                              >
                                <a
                                  title="No Group"
                                  style={{
                                    borderBottom: "1px dotted #d9d9d9",
                                    cursor: "pointer",
                                    paddingLeft: "1px",
                                  }}
                                >
                                  {val?.SessionType === 2
                                    ? `${val?.SchedulesUsed} of Unlimited`
                                    : `${val?.SchedulesUsed} of ${schedulesToUse}`}
                                </a>
                              </Tooltip>
                            </span>
                          )}
                          {val?.SessionType === SessionTypeEnum.PRESET && (
                            <span>
                              <BuzopsTooltip
                                html={
                                  <a
                                    title="No Group"
                                    style={{
                                      borderBottom: "1px dotted #d9d9d9",
                                      cursor: "pointer",
                                      paddingLeft: "1px",
                                    }}
                                  >
                                    {val?.SessionType === 2
                                      ? `${val?.SchedulesUsed} of Unlimited`
                                      : `${val?.SchedulesUsed} of ${schedulesToUse}`}
                                  </a>
                                }
                                content={() => {
                                  if (val?.SessionAvailableToSchedule === 0) {
                                    return (
                                      <div>{"No Available Appointments"}</div>
                                    );
                                  }
                                  return (
                                    <table className="table table-sm table-borderless">
                                      <tbody>
                                        <tr
                                          className="border-0 p-0"
                                          style={{ width: "100%" }}
                                        >
                                          <td>{"Paid"}</td>
                                          <td className="text-right">
                                            {val?.PaidSchedules}
                                          </td>
                                        </tr>
                                        <tr
                                          className="border-0 p-0"
                                          style={{ width: "100%" }}
                                        >
                                          <td>{"Used"}</td>
                                          <td className="text-right">
                                            {val?.Completed}
                                          </td>
                                        </tr>
                                        <tr
                                          className="border-0 p-0"
                                          style={{ width: "100%" }}
                                        >
                                          <td>{"Unused"}</td>
                                          <td className="text-right">
                                            {val?.UnusedPaidSchedules}
                                          </td>
                                        </tr>
                                        <tr
                                          className="border-0 p-0"
                                          style={{ width: "100%" }}
                                        >
                                          <td>{"Unpaid"}</td>
                                          <td className="text-right">
                                            {" "}
                                            {val?.UnPaidSessionCount}
                                          </td>
                                        </tr>
                                        <tr
                                          className="border-0 p-0"
                                          style={{ width: "100%" }}
                                        >
                                          <td>{"Scheduled"}</td>
                                          <td className="text-right">
                                            {val?.Scheduled}
                                          </td>
                                        </tr>
                                        <tr
                                          className="border-0 p-0"
                                          style={{ width: "100%" }}
                                        >
                                          <td>{"Complimentary"}</td>
                                          <td className="text-right">
                                            {val?.Complimentary}
                                          </td>
                                        </tr>
                                        <tr
                                          className="border-0 p-0"
                                          style={{ width: "100%" }}
                                        >
                                          <td>{"Removed"}</td>
                                          <td className="text-right">
                                            {val?.Removed}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  );
                                }}
                                position={"bottom"}
                              />
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="services-member-schedule">
                        <div className="d-flex align-items-center justify-content-start text-muted bz-fs-12px text-capitalize">
                          <ClientNameWithMemberType
                            memberType={val?.MemberType}
                            clientName={`${val?.FirstName} ${val?.LastName}`}
                          />
                        </div>
                        <div>
                          {((val.SessionType === SessionTypeEnum.LIMITED &&
                            (!val?.ExpireOn ||
                              moment().format("YYYY-MM-DD") <
                                moment(val?.ExpireOn).format("YYYY-MM-DD")) &&
                            checkBillingCycleValidation(
                              val.PackageInstanceId
                            )) ||
                            (val.SessionType === SessionTypeEnum.PRESET &&
                              checkPresetBillingCycleValidation(
                                val.PackageInstanceId
                              )) ||
                            (val.SessionType === SessionTypeEnum.UNLIMITED &&
                              (!val?.ExpireOn ||
                                moment().format("YYYY-MM-DD") <
                                  moment(val?.ExpireOn).format(
                                    "YYYY-MM-DD"
                                  )))) &&
                            (val?.Status === PackageInstanceStatusEnum.ACTIVE ||
                              val?.Status ===
                                PackageInstanceStatusEnum.FUTURENOTPAID ||
                              val?.Status ===
                                PackageInstanceStatusEnum.FUTUREPAID ||
                              val?.Status ===
                                PackageInstanceStatusEnum.CANCELLED) &&
                            (val?.Status !==
                              PackageInstanceStatusEnum.CANCELLED ||
                              val?.UnusedPaidSchedules > 0) && (
                              <span className={"bz-schedule-link-btn2"}>
                                <Button
                                  primary={false}
                                  onClick={(e) =>
                                    handleTogglePurchasePage(
                                      e,
                                      val,
                                      !isButtonEnabled
                                    )
                                  }
                                  disabled={!isButtonEnabled}
                                >
                                  <i
                                    className={
                                      val?.Type === TypeEnum.Class
                                        ? "fas fa-user-plus"
                                        : "fas fa-calendar-alt"
                                    }
                                  ></i>
                                  {val?.Type === TypeEnum.Class
                                    ? "Enroll"
                                    : "Schedule"}
                                </Button>
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                    <span className={"bz-arrow-icon"}>
                      <i className={"fas fa-caret-right"}></i>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default withNotification(PurchasedPackages);
