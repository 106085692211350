import React from 'react'
import AppointmentsList from './AppointmentList';
import { ScheduleAppointmentkeys } from 'utils/form-utils';
import ProvidersList from './SessionSignoffProviderList';
import SessionSignoffSelectedSlots from './SessionSignoffSelectedSlots';
import AppointmentCheckout from './AppointmentCheckout';
import SessionSignoffExistingSlot from './SessionSignoffExistingSlot';
import BookingConfirmation from './BookingConfirmation';


interface ISessionSignoffRightCard {
    userDetails?: any;
    mainState?: any;
    schedulerCalendarState?: any;
    refs?: any;
    callBacks?: any;
    TenantZone?: any;
    // calendarPage: boolean;
    showAdditionalPayment?: boolean;
    iframeGuestPages?: boolean;
    // quicklinks?: boolean;
    // staffPage?: boolean;
    handleChange: (name: ScheduleAppointmentkeys, val: any) => void;
    propValues?: any;
    signOffDialog?: boolean;
    selectedAppt?: any;
    multipleSlots?: any;
    isDialogVisible?: boolean;
    showOnlySignoff?: boolean;
}

const SessionSignoffRightCard = ({
    userDetails,
    mainState,
    schedulerCalendarState,
    handleChange,
    refs,
    callBacks,
    propValues,
    iframeGuestPages,
    showAdditionalPayment,
    TenantZone,
    signOffDialog,
    selectedAppt,
    multipleSlots,
    isDialogVisible,
    showOnlySignoff=false
}: ISessionSignoffRightCard) => {
    const {
        quicklinks,
        calendarPage,
        staffPage = true,
    } = propValues;


    const renderItems = () => {
        if (schedulerCalendarState?.confirmationdialog) {
            return (
                <div className="col-md-9 schduleappointment">
                    <BookingConfirmation
                        mainState={mainState}
                        userDetails={userDetails}
                        TenantZone={TenantZone}
                        Attributes={mainState.Appointment?.Attributes}
                        schedulerCalendarState={schedulerCalendarState}
                        guestPages={iframeGuestPages}
                        calenderPage={true}
                        handleGoToOverview={callBacks?.handleGoToOverview}
                        handleClearAllStates={callBacks?.handleClearAllStates}
                    />
                </div>
            );
        } else if (mainState?.Appointment &&
            mainState?.Provider &&
            schedulerCalendarState?.checkout) {
            return (
                <>
                    <AppointmentCheckout
                        userDetails={userDetails}
                        schedulerCalendarState={schedulerCalendarState}
                        refs={refs}
                        callBacks={callBacks}
                        mainState={mainState}
                        calendarPage={calendarPage}
                        showAdditionalPayment={showAdditionalPayment}
                        staffPage={staffPage}
                        quickCheckout={true}
                        quicklinks={quicklinks}
                    />
                </>
            );
        } else if (mainState?.Appointment && schedulerCalendarState?.availExistingSch.length > 0 && mainState?.selectedSchedules?.length > 0) {
            return (
                <div className="row col-md-12">
                    <div className="col-md-5">
                        {" "}
                        <div className='selectedexistingschedules'>
                            <SessionSignoffExistingSlot
                                callBacks={callBacks}
                                mainState={mainState}
                                refs={refs}
                                checkoutPage={false}
                                schedulerCalendarState={schedulerCalendarState}
                                multipleSlots={multipleSlots}
                                selectedAppt={selectedAppt}
                                signOffDialog={signOffDialog}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-7 selectedslotscheckout">
                        <div className="three-panel m-auto">
                            <SessionSignoffSelectedSlots
                                callBacks={callBacks}
                                mainState={mainState}
                                refs={refs}
                                checkoutPage={false}
                                schedulerCalendarState={schedulerCalendarState}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        else if (mainState?.Appointment && schedulerCalendarState?.availExistingSch.length > 0) {
            return (
                <>
                    <SessionSignoffExistingSlot
                        callBacks={callBacks}
                        mainState={mainState}
                        refs={refs}
                        checkoutPage={false}
                        schedulerCalendarState={schedulerCalendarState}
                        multipleSlots={multipleSlots}
                        selectedAppt={selectedAppt}
                        signOffDialog={signOffDialog}
                        handleChange={handleChange}
                    />
                </>
            )
        }
        else if (mainState?.Appointment && mainState?.Provider && schedulerCalendarState?.selectedSchItems?.length > 0) {
            return (
                <div className="row col-md-12">
                    <div className="col-md-6">
                        {" "}
                        <ProvidersList
                            name={`Provider`}
                            mainState={mainState}
                            handleChange={handleChange}
                            schedulerCalendarState={schedulerCalendarState}
                            signOffDialog={signOffDialog}
                            refs={refs}
                            callBacks={callBacks}
                            selectedAppt={selectedAppt}
                        />
                    </div>
                    <div className="col-md-6  selectedslotscheckout">
                        <div className="three-panel m-auto">
                            <SessionSignoffSelectedSlots
                                callBacks={callBacks}
                                mainState={mainState}
                                refs={refs}
                                checkoutPage={false}
                                schedulerCalendarState={schedulerCalendarState}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        else if (mainState?.Appointment) {
            return (
                <ProvidersList
                    name={`Provider`}
                    mainState={mainState}
                    handleChange={handleChange}
                    schedulerCalendarState={schedulerCalendarState}
                    signOffDialog={signOffDialog}
                    refs={refs}
                    callBacks={callBacks}
                    selectedAppt={selectedAppt}
                    isDialogVisible={isDialogVisible}
                />
            );
        }
        else if (userDetails) {
            return (
                <AppointmentsList
                    name={`Appointment`}
                    userDetails={userDetails}
                    schedulerCalendarState={schedulerCalendarState}
                    mainState={mainState}
                    staffPage={staffPage}
                    handleChange={handleChange}
                    callBacks={callBacks}
                    showOnlySignoff={showOnlySignoff}
                />
            );
        }
    }

    return (
        <>
            <div className="ScheduleAppointmentRightCard pl-4">{renderItems()}</div>
        </>
    );
}

export default SessionSignoffRightCard