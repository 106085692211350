import React, { useState, useRef, useEffect } from 'react';
import { getFullImageUrl } from 'utils';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';
import { Popover } from '@progress/kendo-react-tooltip';
import TrainerSelection from 'components/booking/TrainersList';
import { ScheduleAppointmentkeys } from 'utils/form-utils';

interface ISessionSignoffExistingSlot {
    mainState?: any;
    schedulerCalendarState?: any;
    refs?: any;
    callBacks?: any;
    checkoutPage?: boolean;
    multipleSlots?: any;
    selectedAppt?: any;
    signOffDialog?: any;
    handleChange: (name: ScheduleAppointmentkeys, val: any) => void;
}

const SessionSignoffExistingSlot = ({
    callBacks,
    mainState,
    schedulerCalendarState,
    refs,
    checkoutPage,
    multipleSlots,
    selectedAppt,
    signOffDialog,
    handleChange,
}: ISessionSignoffExistingSlot) => {
    const [popoverIndex, setPopoverIndex] = useState<number | null>(null);
    const [isInteractingWithPopover, setIsInteractingWithPopover] = useState(false);
    const [isInteractingwithDatePicker, setisInteractingwithDatePicker] = useState(false);
    const popoverRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                if (!isInteractingWithPopover) {
                    setPopoverIndex(null);
                }
                setIsInteractingWithPopover(false);
            }
        };

        if (isInteractingwithDatePicker) {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }

    }, [popoverRef, isInteractingWithPopover]);

    useEffect(() => {
        refs.anchor.current = schedulerCalendarState?.availExistingSch.map((_, i) => refs.anchor.current[i] || React.createRef());
    }, [schedulerCalendarState]);

    let schedulesDropdown = schedulerCalendarState?.availExistingSch;
    if (multipleSlots?.length > 0) {
        const schedulesList = multipleSlots?.map((i: any) => i?.ScheduleId);
        schedulesDropdown = schedulerCalendarState?.availExistingSch?.map((i: any) => {
            if (schedulesList.includes(i.ScheduleId)) {
                return { ...i, disabled: true };
            }
            return { ...i, disabled: false };
        });
    }

    const PickedSchedules = schedulesDropdown?.filter((i: any) => i?.disabled);

    const ExistingSlotsList = (slots: any, selectedApptVal: any) => {

        return slots?.map((item: any, index: number) => {
            let selectedVal = false;
            if (selectedApptVal && selectedApptVal?.ScheduleId === item?.ScheduleId) {
                selectedVal = true;
            }

            const cardRef = refs.anchor.current[index];
            const dynamicClass = `existingschedules${index}`;
            const popoverPosition = window.innerWidth <= 976
                ? 'top'
                : (mainState?.selectedSchedules?.length > 0
                    ? (index <= 1 ? 'bottom' : 'top')
                    : (index <= 3 ? 'bottom' : 'top'));

            return (
                <div className={`${mainState?.selectedSchedules?.length > 0 ? "col-xl-12" : "col-md-6"}`} key={item?.ScheduleId}>
                    <div
                        className={`card bz-shadow  w-100 m-0 bz-upcoming-apponments-sm-card existingslots quickcheckout mb-3 ${item?.disabled ? "bz-border-grey" : "bx"} ${dynamicClass} ${mainState?.selectedSchedules?.length > 0 ? 'selectedexistingschedules' : ''}`}
                        style={{
                            fontSize: "12px",
                            backgroundColor: selectedVal ? "#BBF0FB" : item?.disabled ? "#F5F6F7" : "inherit",
                            opacity: item?.disabled ? "0.5" : "1",
                            borderColor: selectedVal ? "#008db9" : item?.disabled ? "#008db9" : "#e7e2e2",
                        }}
                        onClick={(e) => {
                            if (item?.disabled) {
                                return;
                            }
                            e.stopPropagation();
                            callBacks?.handleChangeAppt(item, "PreviousSchedule");
                            setPopoverIndex(popoverIndex === index ? null : index);
                        }}
                        ref={cardRef}
                    >
                        <div className="card-body py-2">
                            <div className={"row align-items-center w-100 test-1"}>
                                <div className={"col-3 text-center"} style={{ width: '60px' }}>
                                    {item?.TrainerPhoto ? (
                                        <img
                                            style={{ borderRadius: "20px" }}
                                            width={38}
                                            alt="TrainerPhoto"
                                            src={getFullImageUrl(item?.TrainerPhoto)}
                                            className=''
                                        />
                                    ) : (
                                        <img width={38} src="/images/user.png" />
                                    )}
                                </div>
                                <div className={"col-9 pl-2 pr-0"} style={{ flexGrow: '1' }}>
                                    <span key={1} className={""}>
                                        <p className="mb-0 providernameexisting">{`${item?.TrainerName}`}</p>
                                        <span className="d-block">{item?.ShortFromDate}</span>
                                        <span>{`${item?.ScheduleName}`}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {popoverIndex === index && (
                            <Popover
                                show={true}
                                anchor={cardRef.current}
                                position={popoverPosition}
                                className={`popoverstaff staff${index} ${index === 0 ? 'popoverstaffirst' : ''} ${mainState?.selectedSchedules?.length > 0 ? 'classselectedslots' : 'noclassselectedslots'}`}
                            >
                                <div className={"dialog-options"} style={{ overflow: "hidden" }} onClick={(e) => {
                                    e.stopPropagation();
                                    setIsInteractingWithPopover(true);
                                }} ref={popoverRef}>
                                    <div className="mb-3">
                                        <div style={{ fontSize: "13px" }}>Select Provider</div>
                                        <TrainerSelection
                                            allStaffList={mainState?.providerList}
                                            selectedUsermemberId={
                                                mainState?.Provider?.UserId
                                                    ? mainState?.Provider?.UserId
                                                    : item?.TrainerUserId
                                            }
                                            handleUpdateStaff={(val: any) => {
                                                handleChange("Provider", val);
                                                setPopoverIndex(index);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <div style={{ fontSize: "13px" }}>Sign Off</div>
                                        <DateTimePicker
                                            value={selectedAppt?.signOffDate}
                                            max={new Date()}
                                            adaptive={true}
                                            min={mainState?.minDate}
                                            size={"small"}
                                            width={"100%"}
                                            onChange={(e) => {
                                                callBacks?.handleChangeAppt(e.target.value, "signOffDate");
                                                setisInteractingwithDatePicker(true);
                                                setPopoverIndex(index);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <div style={{ fontSize: "13px" }}>Notes</div>
                                        <textarea
                                            className="form-control"
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                callBacks?.handleChangeAppt(e.target.value, "notes");
                                                setPopoverIndex(index);
                                            }}
                                            value={selectedAppt?.notes}
                                        ></textarea>
                                    </div>
                                    <div className={"row justify-content-end"}>
                                        <div className={"col-12 text-right"}>
                                            <Button primary={false} onClick={(e) => {
                                                e.stopPropagation();
                                                setPopoverIndex(null);
                                            }}>
                                                Cancel
                                            </Button>{" "}
                                            <Button primary={true} onClick={(e) => {
                                                e.stopPropagation();
                                                callBacks?.handleAddAppointment(false);
                                                setPopoverIndex(null);
                                            }}>
                                                Update Appt
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Popover>
                        )}
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="mb-3 m600">
            <div className={"selected-slot-header"}>
                <div>
                    {" "}
                    <h5 className="card-title mb-0">Existing Schedules</h5>{" "}
                    <span className="small text-muted">
                        Number of picked schedules: &nbsp;
                        {PickedSchedules?.length}
                    </span>
                </div>
            </div>
            <div className="mt-3 bz-selected-appt-quick-check">
                <div className="row">
                    {ExistingSlotsList(schedulesDropdown, selectedAppt?.PreviousSchedule)}
                </div>
            </div>
        </div>
    );
};

export default SessionSignoffExistingSlot;
