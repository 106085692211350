import React from "react";
import BuzopsButton from "generic-components/BuzopsButton";
import { Button } from "@progress/kendo-react-buttons";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { CurrencyFormat } from "utils";
import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import { CouponDurationType, RenewalExpirationTypes } from "utils/form-utils";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import { Checkbox } from "@progress/kendo-react-inputs";

const AutoRenewal = (props: any) => {
  if (props?.loading) {
    return <BuzopsLoader type={"list"} />;
  }
  let enableRenewal = false;
  const {
    RenewalMemberPlanId=0,
    ServicesIsChargedForAfterExpirationDate,
    ServicesNeedToChargeRemainingAmount=0,
    PayInFull,
    CouponsHistory = []
  } = props?.renewalData;
  if (RenewalMemberPlanId === 0) {
    enableRenewal = true;
  }

  return (
    <>
      <h5 className="mb-3 bz_fs_1_5">
        <button
          className="btn btn-primary bz-back-btn btn-sm"
          onClick={() => props?.handleCloseAutoRenewal()}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        &nbsp;Auto Renewal
      </h5>
      <hr />
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          <p>
            {
              "Subscription will be auto renewed after the end of subscription term, enable or disable auto renewal of current subscription."
            }
          </p>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="disable-renewal-btns">
            {/* <div className=""> */}
            {" "}
            <BuzopsButton
              label={`${enableRenewal ? "Enable" : "Disable"} auto renewal `}
              onClick={() => props?.submitAutoRenewal(enableRenewal)}
            />
            {/* </div>
            <div className=""> */}
            {" "}
            <Button
              className="pt-2"
              onClick={() => props?.handleCloseAutoRenewal()}
            >
              Cancel
            </Button>
            {/* </div> */}
          </div>
        </div>
      </div>
      {enableRenewal && CouponsHistory?.length > 0 && props?.selectedPlan?.DurationType === 1 &&
        <div className="row">
          <h6>Coupons</h6>
          <div className="coupon-list-card">
            {CouponsHistory?.map((i: any) => {
              return (<>
                <div className="coupon-card-item">
                  <div className="buz-font-600">{`${i?.CouponName} - ${i?.Type === 1 ? CurrencyFormat(i?.Amount) : `${i?.Amount}%`}`} </div>
                  <div>Duration Type : {CouponDurationType[i?.DurationType]}</div>
                  {i?.DurationType === 3 && <div>Pending Billing Cycles : {i?.PendingBillingCycle}</div>}
                  {i?.DurationType === 3 && <div>Auto Renews:<Checkbox name={`Coupon${i?.MemberCouponId}`} id={`Coupon${i?.MemberCouponId}`} value={i?.RenewType === 1 ? true : false}
                    onChange={(e) => {
                      props?.handleRenewalChange({ selected: e.value, MemberCouponId: i?.MemberCouponId }, "CouponRenewal");
                    }} className="pl-2" /></div>}
                </div>
              </>)
            })}
          </div>
        </div>}
      {(ServicesNeedToChargeRemainingAmount.length > 0 ||
        ServicesIsChargedForAfterExpirationDate.length > 0) && (
          <div className="row">
            <h6>Additional Service Details</h6>
            {ServicesNeedToChargeRemainingAmount.map((item: any) => {
              return (
                <div className="col-12 pb-2">
                  <label>{item?.Name}</label>
                  <p style={{ color: "blue" }}>{`This amount (${CurrencyFormat(
                    item.RemainingAmount
                  )}) will refund on the Membership end date if you disable the auto renewal.`}</p>
                </div>
              );
            })}
            {ServicesIsChargedForAfterExpirationDate.map((item: any) => {
              return (
                <div className="col-12 pb-2 ">
                  <label>{item?.Name}</label>
                  <div className="bz-payment-profile-options">
                    <div className="bzc-provider-name">
                      <div className="">
                        <DropDownList
                          data={RenewalExpirationTypes}
                          textField="text"
                          dataItemKey="id"
                          value={
                            RenewalExpirationTypes?.filter(
                              (i: any) => i?.id === item?.ExpirationType
                            )?.[0]
                          }
                          onChange={(e) =>
                            props?.handleRenewalChange(
                              e?.value?.id,
                              "ExpirationType",
                              item?.EntityId,
                              "ServicesIsChargedForAfterExpirationDate"
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {item?.ExpirationType === 11 && (
                    <p
                      style={{ color: "blue" }}
                      className="pt-2"
                    >{`This amount (${CurrencyFormat(
                      item.RefundAmount
                    )}) will refund on the Membership end date if you disable the auto renewal`}</p>
                  )}
                  {item?.ExpirationType === 10 && (
                    <p
                      style={{ color: "blue" }}
                      className="pt-2"
                    >{`Your Membership will expire on Membership end date without any charge/ refund.`}</p>
                  )}
                </div>
              );
            })}
            {ServicesNeedToChargeRemainingAmount.length > 0 && (
              <div className="col-12">
                <DropDownList
                  id={`PaymentGatewayProfileId`}
                  name={`PaymentGatewayProfileId`}
                  data={props?.paymentProfiles}
                  textField="MaskedCCNumber"
                  valueRender={(
                    el: React.ReactElement<HTMLSpanElement>,
                    value: any
                  ) => PaymentValueRender(el, value, value)}
                itemRender={(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) =>
                 PaymentItemRender(li, { ...itemProps, makePaymentProfileAsDefault:props?.makePaymentProfileAsDefault })
                 }
                  onChange={(e) => {
                    props?.handleRenewalChange(
                      e.value,
                      "PaymentGatewayProfileId"
                    );
                  }}
                />
              </div>
            )}
            {RenewalMemberPlanId > 0 && (
              <div className="col-12">
                <Checkbox
                  name="all"
                  label={"Pay In Full"}
                  value={PayInFull}
                  onChange={(e) => props?.UpdatePIF(e.value)}
                />
              </div>
            )}
          </div>
        )}
    </>
  );
};

export default AutoRenewal;
