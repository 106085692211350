import React, { memo } from "react";
import ClassSessionItem from "./ClassSessionItem";
import { getFullDate, getTimeZoneShortHand } from "utils";
import { GetLocalStore } from "utils/storage";
export const findItem = (obj: any, arr: any) => {
  let allVals: any[] = [];
  arr?.forEach((val: any) => {
    if (val?.values) {
      allVals.push(...val.values);
    }
  });

  const findVal = allVals?.findIndex((item: any) => {
    return item.ClassSessionId === obj.ClassSessionId;
  });
  return findVal;
};
const ClassSessionItemRender = ({
  dataItem,
  redeem,
  staffPage,
  usePackageFilter,
  handleSelectClass,
  selectedClasses,
  handleCopyClick,
  handleSelectGroup,
}: any) => {
  console.log(dataItem,'dataItemdataItemdataItem')
  if (dataItem?.type === "header") {
    return (
      <div
        className="text-center text-lg-center text-md-center bz-fw-600 mb-2"
        key={dataItem?.date?.toString()}
      >
        <div className="text-capitalize">{`Group On ${getFullDate(
          dataItem?.date
        )} ${getTimeZoneShortHand(
          GetLocalStore("tenantUserSession")?.TimeZone?.Id
        )}`}</div>
      </div>
    );
  }
  const item1 = dataItem?.dataItem;

  console.log(item1, "Item1111");
  if (!item1) {
    return <></>;
  }
  const check = findItem(item1, selectedClasses);
  return (
    <ClassSessionItem
      staffPage={staffPage}
      dataItem={item1}
      check={check}
      redeem={redeem}
      usePackageFilter={usePackageFilter}
      handleSelectClass={handleSelectClass}
      key={`${item1.ClassSessionId}`}
      handleCopyClick={handleCopyClick}
      handleSelectGroup={handleSelectGroup}
    />
  );
};

export default memo(ClassSessionItemRender);
