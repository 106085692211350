import { DropDownList } from "@progress/kendo-react-dropdowns";
import { NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import moment from "moment";
import React from "react";
import { CurrencyFormat } from "utils";
import FailedTransactionsGrid from "../FailedTransactionsGrid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TooltipContentRefundTax } from "components/Transactions/Refund";

const AddonsInPlanCancel = (props: any) => {
  let AddonCancellationOnOptions = [];
  AddonCancellationOnOptions.push({ id: 1, text: "Today" });
  console.log(props.planItem)
  if (props?.planItem?.CancelItToday === false) {
    AddonCancellationOnOptions.push({
      id: 2,
      text: moment(props?.planItem?.ImmediateCancellationDate).format("L"),
    });

    if (props?.planItem?.ImmediateCancellation === false) {
      AddonCancellationOnOptions.push({
        id: 3,
        text: moment(props?.planItem?.NormalCancellationDate).format("L"),
      });
    }
    if (props?.planItem?.CancellationOn===4) {
      AddonCancellationOnOptions.push({
        id: 4,
        text: moment(props?.planItem?.CancellationDate).format("L"),
      });
    }
  }
  if(props?.addonItem?.CancellationProcessType === 0 && props?.planItem?.CancellationProcessType !== 0 )
    props.addonItem.CancellationProcessType=props?.planItem?.CancellationProcessType;
  const selectionChange = (event: any,EntityId: number=0) => {
    const dataItem = JSON.parse(JSON.stringify(props?.addonItem));
    const originalDup = dataItem?.FailedTransactions;

    const original = originalDup.map((item: any) => {
      if (item?.TransactionHistoryId === event?.dataItem?.TransactionHistoryId) {
        item.CanWaive = !event.dataItem.CanWaive;
      }
      return item;
    });
    const updatedAddonCancellationDetails = {
      ...dataItem,
      FailedTransactions: original,
    };
    props?.handleAddonItem(
      props?.addonItem?.EntityId,
      updatedAddonCancellationDetails
    );
  };



  const handleChange = async (value: any, name: string) => {
    const dataItem = JSON.parse(JSON.stringify(props?.addonItem));
    let val=value
    if(name==="CancellationFee"){
      val= value || 0
    }
    const updatedAddonCancellationDetails = {
      ...dataItem,
      [name]: val,
    };
    props?.handleAddonItem(
      props?.addonItem?.EntityId,
      updatedAddonCancellationDetails
    );
  };
  const {
    AddOnName,
    StartDate,
    EndDate,
    FailedTransactions,
    RecurringAmount,
    FrequencyDescription,
    RepeatFrequency,
    CancellationOn,
    TodayCancellationRefundAmount,
    ImmediateCancellationRefundAmount,
    CancellationFee,
    NormalCancellationRefundAmount,
    AmountToRefund,
    CancellationProcessType
  } = props?.addonItem;
  let refundAmountValue=0
  let taxAmt=0
  let TaxesArray:any=[]
  switch (CancellationOn) {
    case 1:
      refundAmountValue=TodayCancellationRefundAmount
      break;
    case 2:
      refundAmountValue=ImmediateCancellationRefundAmount
      break;
    case 3:
      refundAmountValue=NormalCancellationRefundAmount
      break;
    default:
      refundAmountValue=NormalCancellationRefundAmount
      break;
  }
  const amtTorefund=refundAmountValue
  if(props?.addonItem?.MetaData && amtTorefund > 0){
    const taxObj=props?.handleCalculateSurcharges(amtTorefund,props?.addonItem?.MetaData)
    taxAmt=taxObj?.taxAmt
    TaxesArray = taxObj?.TaxesArray
  }

  return (
    <>
      
      <div className="bz-mt-2rem">
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Add-on Name</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{AddOnName} </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Start Date</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {StartDate === null
              ? "NA"
              : moment(StartDate).format("L")}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">End Date</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{EndDate === null ? "NA" : moment(EndDate).format("L")}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Add-On Rate</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{CurrencyFormat(RecurringAmount)}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Frequency</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{FrequencyDescription}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Repeat Frequency</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{RepeatFrequency}</div>
        </div>
      </div>
     
      {FailedTransactions?.length > 0 &&
        <FailedTransactionsGrid
        details={FailedTransactions}
        selectionChange={(e: any, id: number) =>
          selectionChange(e, id)
        }
        EntityId={0}
      />}

      <div className="bz-mb-2_5rem">
        <div className="bz-content-text-row align-items-start">
          <div className="bz-content-title text-right">Cancellation On</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            <DropDownList
              data={AddonCancellationOnOptions}
              textField="text"
              dataItemKey="id"
              value={
                AddonCancellationOnOptions?.filter(
                  (i: any) => i?.id === CancellationOn
                )?.[0]
              }
              onChange={(e) =>
                handleChange(e?.value?.id, "CancellationOn")
              }
              style={{ width: "100%" }}
            />
            {CancellationOn === 2 && (
              <p style={{ color: "#FDAC41 " }} className="notificxation-blue mb-0">
                {
                  "Service will cancel on the next recurring billing date and the user will not get charged."
                }
              </p>
            )}
            {CancellationOn === 3 && (
              <p style={{ color: "#FDAC41 " }} className="notificxation-blue mb-0">
                {
                  "Allow for 30-day cancellation notice and will cancel after next recurring service."
                }
              </p>
            )}
            <p style={{ color: "blue" }} className="notificxation-blue mb-0">
              {
                "Disclaimer: You can cancel with plan cancellation date or current date."
              }
            </p>
          </div>
        </div>
        {amtTorefund > 0 && (
          <>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Do you want to refund?</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">                                                  
                  <Switch
                    className="ms-0"
                    name="Refund"
                    onLabel="Do you want to Refund?"
                    checked={CancellationProcessType===2}
                    onChange={(e) =>
                      handleChange( e.target.value ? 2:3,"CancellationProcessType" )
                    }
                  />
                </div>    
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
              <div> {"Amount to Refund"}</div>
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body"> {CurrencyFormat(refundAmountValue)}</div>
            </div>
            {props?.addonItem?.MetaData && amtTorefund > 0 && (
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">
                <Tooltip
                    content={(tooltipProps: any) => {
                      return (
                        <TooltipContentRefundTax
                          title={tooltipProps.title}
                          data={TaxesArray}
                          TotalTaxAmount={taxAmt}
                          Name={AddOnName}
                        />
                      );
                    }}
                    tooltipClassName="bz-tooltip-table"
                  >
                    <a
                      title="Surcharges"
                      style={{
                        borderBottom: "1px dotted #0d6efd",
                        cursor: "pointer",
                      }}
                    >
                      {"Surcharges to be refunded"}
                    </a>
                  </Tooltip>
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body"> {CurrencyFormat(taxAmt)}</div>
              </div>
            )}       
          </>
        )}
        {(CancellationProcessType ===3 || ((props?.planItem?.CancellationOn !==0 && CancellationProcessType!==1) && amtTorefund <= 0)) && (
          <>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Do you want to charge?</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <Switch
                  className="ms-0"
                  name="Charge"
                  onChange={(e) =>
                    handleChange( e.target.value ? 1:3,"CancellationProcessType" )
                  }
                  checked={CancellationProcessType===1}
                />
              </div>
            </div>
          </>
        )}
        {CancellationProcessType===1 && (
          <>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Do you want to charge?</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <Switch
                  className="ms-0"
                  name="Charge"
                  onChange={(e) =>
                    handleChange( e.target.value ? 1:3,"CancellationProcessType" )
                  }
                  checked={CancellationProcessType===1}
                />
              </div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Cancellation Fee</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <NumericTextBox
                  id={`CancellationFee`}
                  name={`CancellationFee`}
                  min={0}
                  format={"c2"}
                  className="k-dropdown-width-100"
                  value={CancellationFee}
                  onChange={(e) =>
                    handleChange(e.target.value, "CancellationFee")
                  }
                />
              </div>
            </div>
          </>
        )} 
      </div>
      <hr className="bz-addons-hr"/>
    </>
  );
};

export default AddonsInPlanCancel;
