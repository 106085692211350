import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import BuzopsLoader from "generic-components/BuzopsLoader";
import ClientNameWithMemberType from "../ClientNameWithMemberType";
import BuzopsWindow from "generic-components/BuzopsWindow";
import AgreementView from "./AgreementView";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import WaiverAgreement from "./WaiverAgreement";
import { useAgreement } from "./useAgreement";
import UploadAgreement from "./UploadAgreement";
import BuzopsButton from "generic-components/BuzopsButton";
import { useApiClient } from "services/axios-service-utils";
import { Input } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { FaShareAlt } from "react-icons/fa";
import { Dialog } from "@progress/kendo-react-dialogs";

const Agreements = (props: any) => {
  const {
    fetchAgreementDetails,
    agreementDetails,
    loading,
    AllClientsList,
    clientSubmitDetails,
    OpenWaiverAgreement,
    handleClientSelection,
    showWaiver,
    handleBack,
    currentagreementDetails,
    toggleView,
    togglePdfView,
    isUploadAgreementVisible,
    toggleUploadAgreement,
    handleEmailChange,
    handleCloseModal,
    email,
    errorMessage,
    shareDialog,
    selectedData,
    setSeletedData,
    setShareDialog,
    setEmail
  } = useAgreement(props);

  console.log('agreementDetails', agreementDetails);
  console.log('currentagreementDetails', currentagreementDetails);
  
  const { axiosRequest } = useApiClient();
  const [rowLoadingStates, setRowLoadingStates] = useState<{ [key: string]: boolean }>({});

  if (loading.page) {
    return <BuzopsLoader type={"list"} />;
  }

  if (isUploadAgreementVisible) {
    return <UploadAgreement
            {...props}
            UserMemberId={clientSubmitDetails?.UserMemberId}
            toggleUploadAgreement={toggleUploadAgreement}
            AllClientsList={AllClientsList}
          />;
  }

  const handleShareMail = async (dataItem: any) => {
    const uniqueId = dataItem.MemberAgreementId; 
    setRowLoadingStates((prevState) => ({
      ...prevState,
      [uniqueId]: true,
    }));
    const req= {
        UserMemberId: props.userData.UserMemberId,
        AgreementId  : dataItem.MemberAgreementId,
        AgreementName: dataItem.DocumentName !==null ? dataItem.DocumentName :dataItem.AgreementName,
        Email:email
      };

    await axiosRequest.post("Agreement/SendAgreement",req,{
      successCallBack:async(response:any) => {
        props?.handleNotificationMessage(
          dataItem.DocumentType===1 ?  `Agreement copy has been shared via email successfully ` : 'Document copy has been shared via email successfully',
          "success"
        );
        setRowLoadingStates((prevState) => ({
          ...prevState,
          [uniqueId]: false,
        }));
      },
      errorCallBack: (error: any) => {
        console.log(error);
        const errorMsg = "Error in sending Email";
        props?.handleNotificationMessage(errorMsg, "error");
        setRowLoadingStates((prevState) => ({
          ...prevState,
          [uniqueId]: false,
        }));
      }
    })
  };

  const AgreementNameCell = (propsData: any) => {
      const uniqueId = propsData.dataItem.PaymentGatewayTransactionHistoryId;
      const isLoading = rowLoadingStates[uniqueId];
  
      return (
          <td>
              <div className="d-flex align-items-center w-100">
                  <span
                  className="bz-custom-link bz-cursor-pointer"
                  onClick={() => fetchAgreementDetails(propsData?.dataItem, "view")}
                >
                  {propsData?.dataItem?.DocumentName ?? propsData?.dataItem?.AgreementName}
                </span>
                {props?.staffPage && (
                  <button
                    onClick={() => {
                        setEmail(props?.userData?.Email);
                        setShareDialog(true);
                        setSeletedData(propsData);
                    }}
                    disabled={isLoading}
                    className="ms-auto p-0 border-0 bg-transparent d-flex align-items-center mr-3"
                    style={{minWidth:'none'}}
                    title="Share"
                  >
                    {isLoading ? (
                        <i className="fa fa-spinner fa-spin fs-5" />
                    ) : (
                        <FaShareAlt className="fs-5" />
                    )}
                  </button>
                )}
                
              </div>
          </td>
      );
  };

  const ShareMailModal = (propsData:any) => {
    const uniqueId = propsData?.dataItem?.MemberAgreementId;;
    const isLoading = rowLoadingStates[uniqueId]; 
    return (
      <>
        <Dialog className="bzo-login-dialog"
          title="Share Agreement Copy"
          onClose={handleCloseModal}
          style={{ zIndex: 999 }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "20px" ,padding:"10px"}}>
            <div style={{ fontSize: "14px", color: "#555" }}>
              The duplicate agreement copy will be shared with the email below. To share with multiple clients, separate email addresses with ‘;’.
            </div>
            <div>
              <Input
                id="email"
                name="email"
                label="Email"
                style={{ width: "100%" }} 
                value={email}
                onChange={(e) => handleEmailChange(e.value)}
              />
              <Error>{errorMessage}</Error>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
              <Button primary={false} onClick={handleCloseModal} disabled={isLoading}>
                Cancel
              </Button>
              <BuzopsButton
                label="Send"
                disabled={errorMessage != null || isLoading}
                onClick={() => handleShareMail(propsData?.dataItem)}
                loading={isLoading}
                key={isLoading?.toString()}
              />
            </div>
          </div>
        </Dialog>
      </>
    );
  };
  
  return (
    <div className={`agreement-parent`}>
     <div className="d-flex align-items-center justify-content-between mb-3">
     <h5 className="mb-0 bz_fs_1_5">Documents</h5>
     
      <div className="d-flex justify-content-end align-items-center">
      <Button
          primary
          onClick={() => toggleUploadAgreement(true)}
        >{`Upload`}</Button>
        {AllClientsList?.length > 1 ? <DropDownButton
          text="Sign Waiver"
          onItemClick={(e: any) => handleClientSelection(e)}
          items={AllClientsList}
          look="flat"
          className="btn btn-link add-new-btn"
        /> : <Button
          primary
          className="float-right mr-0"
          onClick={() => OpenWaiverAgreement()}
        >{`Sign Waiver`}</Button>}
      </div>
     </div>
     
      <Grid data={agreementDetails || []} className="bzc-grid bz-checkins-grid">
        <Column
          field="MemberName"
          title="Member Name"
          cell={({ dataItem }) => {
            return (
              <td>
                <ClientNameWithMemberType
                  memberType={dataItem?.MemberType}
                  clientName={dataItem?.MemberName}
                />
              </td>
            );
          }}
        />
        <Column
          title="Name"
          width={300}
          cell={AgreementNameCell}
        />
        <Column field="AgreementDate" title="Date" />
        <Column field="IsSystemGenerated" title="Is Uploaded"
        cell={({ dataItem }) => {
          return (
            <td>
              <span>
                {dataItem?.IsSystemGenerated ? "Yes" : "No"}
              </span>
            </td>
          );
        }}
         />
        
      </Grid>
      {showWaiver && (
        <BuzopsWindow
          title={
            <>
              <div className={"buzops-window-title"}>
                {" "}
                <div>
                  {"Waiver Agreement"}
                  <br />
                  <small className="text-muted">{`Accept & Sign the Terms and Conditions of a waiver`}</small>
                </div>
              </div>
            </>
          }
          reduceWidth={window.innerWidth < 800 ? 10 : 430}
          visibility={showWaiver}
          key={showWaiver.toString()}
          onWindowClose={() => handleBack()}
          className="bz-agreement-window"
        >
          <WaiverAgreement
            {...props}
            UserMemberId={clientSubmitDetails?.UserMemberId}
            handleBack={handleBack}
          />
        </BuzopsWindow>
      )}
      {currentagreementDetails?.type === "view" && (
        <BuzopsWindow
          title={
            <>
              <div className={"buzops-window-title"}>{ currentagreementDetails.DocumentType === 1 ? "Agreement Copy" : "Document Copy"}</div>
            </>
          }
          reduceWidth={window.innerWidth < 800 ? 10 : 430}
          visibility={toggleView}
          key={toggleView.toString()}
          onWindowClose={() => togglePdfView()}
          className="bz-agreement-window"
        >
          <AgreementView
            Id={currentagreementDetails?.MemberAgreementId}
            Name={currentagreementDetails?.AgreementName}
            Type={currentagreementDetails?.type}
          />
        </BuzopsWindow>
      )}
      {shareDialog && ShareMailModal(selectedData)}
    </div>
  );
};
export default Agreements;
