import { useApiClient } from "services/axios-service-utils";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { TenantService } from "services/tenant/index.service";
import { GetLocalStore } from "utils/storage";
import { Upload } from "@progress/kendo-react-upload";
import { UploadFileInfo, UploadOnAddEvent, UploadOnRemoveEvent } from "@progress/kendo-react-upload";

const schema = Yup.object({
  CreditAmount: Yup.number()
    .typeError("Please enter a valid amount for the credit top-up")
    .min(0.01, "Amount should be greater than 0")
    .max(15000, "Amount should not be greater than 15k")
    .required(),
  PaymentGatewayPaymentProfileID: Yup.string().required(
    "Payment Profile is required"
  ),
});
const useAddCreditsTopUp = (props: any) => {

  const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [selectedPaymentProfile, setSelectedPaymentProfile] =
  React.useState<any>(null);
  const [addNewItem, setAddNewItem] = useState<any>(null);
  const [paymentProfile, setPaymentProfile] = useState<any>([]);
  const paymentProfileData = new TenantService();
  const { axiosRequest } = useApiClient();
  const formik = useFormik({
    initialValues: {
      CreditAmount: 0,
      PaymentGatewayPaymentProfileID: "",
      refNumber:""
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  //upload
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);

  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);


  const constructFileWithBas64 = (records: any) => {
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
        };
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
    }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };

  const makePaymentProfileAsDefault = async (dataItem: any) => {
    const req = {
      PaymentGatewayPaymentProfileID: dataItem?.PaymentGatewayPaymentProfileID,
      UserMemberId: props?.UserMemberId,
      CustomerProfileID:
        dataItem?.CustomerProfileID,
    };
    await axiosRequest.post(
      `PaymentProfile/update-preferred-payment-profile`,
      req,
      {
        successCallBack: async (response: any) => {
          props?.handleNotificationMessage(
            `Selected Payment profile marked as Preferred`,
            "success"
          );
          await fetchPaymentProfiles(props?.UserMemberId);
        },
        errorCallBack: (response: any) => {
          props?.handleNotificationMessage(
            response?.response?.data?.Messages?.[0] ||
              response?.data?.Messages?.[0] ||
              "Internal server error",
            "error"
          );
        },
      }
    );
  };

  const  fetchPaymentProfiles= async(userMemberId: any,modeVal=null)=> {
    const result = await paymentProfileData.paymentProfile(userMemberId);
    if (result) {
      const pp=result?.map((i: any) => {
        if (i?.CardTypeId !== 8) {
          return { ...i };
        } else {
          return { ...i, MaskedCCNumber: "Credits" };
        }
      })

      if(modeVal){
        const record=pp?.find((i:any)=> i?.CardTypeId ===-1)
        if(record){
          const data={
            ...record,
            CardTypeId: -2,
            MaskedCCNumber: modeVal,
            CardDescription: modeVal,
            CardDescriptionWithoutExpiryDate: modeVal,
          };
          const res=[...pp]
          res.push(data)
          setPaymentProfile(JSON.parse(JSON.stringify(res)))
          handleChangeSavedCard(data)
          formik.setFieldValue(
            "PaymentGatewayPaymentProfileID",
            data?.PaymentGatewayPaymentProfileID
          );
        }
      }else {
        setPaymentProfile(pp);
        const defaultPP = result.find((card: any) => card.IsDefault);
        if(defaultPP){
          handleChangeSavedCard(defaultPP)
          formik.setFieldValue(
            "PaymentGatewayPaymentProfileID",
            defaultPP?.PaymentGatewayPaymentProfileID
          );
        }
      }
    }
  }

  useEffect(() => {
    fetchAllApis();
  }, []);
  const fetchAllApis = async () => {
    if (props?.UserMemberId) {
      setLoading(true);
      await fetchPaymentProfiles(props?.UserMemberId);
      setLoading(false);
    }
  };
  const showError = (message: string) => {
    props?.handleNotificationMessage(message, "error");
  };

  const handleSubmit = async(values: any)=>{
    setBtnLoading(true);
    let paymentAttributes = null;
    if (selectedPaymentProfile?.CardTypeId === -2) {
      paymentAttributes = {
        Reference: values.refNumber,
        OfflinePaymentDescription: selectedPaymentProfile?.MaskedCCNumber,
        ReferrenceDocumentName: files[0]?.FileName || "",
        ReferrenceDocumentContent: files[0]?.ImageAsBase64 || "",
      };
    }else if(selectedPaymentProfile?.CardTypeId === -1){
      paymentAttributes = {
        Reference: "",
        OfflinePaymentDescription: "Cash",
        ReferrenceDocumentName: "",
        ReferrenceDocumentContent: "",
      };
    }

    const payload = {
      PaymentAttributes:paymentAttributes,
      UserMemberId: props?.UserMemberId,
      CreditAmount: values?.CreditAmount,
      PaymentGatewayPaymentProfileID: values?.PaymentGatewayPaymentProfileID,
      PaymentGatewayType: paymentGatewaytype,
    };

    await axiosRequest.post("PaymentProfile/addcredit", payload, {
      successCallBack: (response: any) => {
        console.log(response);
        handleReset();
        props?.handleCloseDialog("addTopUp",true);
        setBtnLoading(false);
      },
      errorCallBack: (response: any) => {
        console.log(response?.response, "response");
        showError(
          response?.response?.data?.Messages?.[0] || response?.data?.Messages?.[0] ||
            "Error occured while adding credits to wallet"
        );
        setBtnLoading(false);
      },
    });
  };

  const onCardOrBankAdd = (e: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };
  const handleChangeSavedCard = (val: any) => {
    setSelectedPaymentProfile(val);
  };
  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
  };
  const handleSuccessClosePaymethodDialog = async (msg = null,modeVal = null) => {
    if (msg) {
      props?.handleNotificationMessage(msg, "success");
    }
    setShowPaymentDialog(!showPaymentDialog);
    fetchPaymentProfiles(props?.UserMemberId,modeVal);
  };

  const handleReset = () => {
    formik.resetForm();
    setSelectedPaymentProfile(undefined);
  };

  return {
    handleReset,
    handleSuccessClosePaymethodDialog,
    handleClosePaymethodDialog,
    handleChangeSavedCard,
    onCardOrBankAdd,
    addNewItem,
    loading,
    formik,
    paymentProfile,
    selectedPaymentProfile,
    showPaymentDialog,
    btnLoading,
    onAdd,
    onRemove,
    originalFiles,
    makePaymentProfileAsDefault
  };
};

export default useAddCreditsTopUp;
