import moment from "moment";
import { useEffect, useState } from "react";
import { CheckUserMemberStatus, CurrencyFormat, CustomToFixed } from "utils";
import { TenantService } from "services/tenant/index.service";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { filterBy } from "@progress/kendo-data-query";
import BuzopsButton from "generic-components/BuzopsButton";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import AutoRenewal from "./AutoRenewal";
import SubscriptionDetails from "./SubscriptionDetails";
import Coupons from "../Coupons";
import withNotification from "components/Hoc/withNotification";
import AddonOverView from "../Addons/AddonOverView";
import { useSelector } from "react-redux";
import CustomFieldsUpdate from "components/ManageCustomFields/CustomFieldsUpdate.view";
import useCustomFieldsUpdate from "components/ManageCustomFields/CustomFieldsUpdate.hook";
import { Checkbox, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { Error } from "@progress/kendo-react-labels";
import { Card, CardBody } from "@progress/kendo-react-layout";
import MembershipVisitsBox from "../MembershipVisitsBox";
import { useApiClient } from "services/axios-service-utils";
import AuditHistory from "../AuditHistory";

const PlanOverview = (props: any) => {
  const {axiosRequest}=useApiClient()
  const [couponValue, setCouponValue] = useState<any>(null);
  const [renewalData, setRenewalData] = useState<any>(null);
  const [removeCouponValue, setRemoveCouponValue] = useState<any>(null);
  const [editCouponValue, setEditCouponValue] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showCoupon, setShowCoupon] = useState<boolean>(false);
  const [showAutoRenewal, setShowAutoRenewal] = useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = useState<any>(false);
  const [dialogPIFLoading, setDialogPIFLoading] = useState<any>(false);
  const [couponAddVisible, setCouponAddVisible] = useState<any>(false);
  const [couponEditVisible, setCouponEditVisible] = useState<any>(false);
  const [renewalDisableVisible, setRenewalDisableVisible] =
    useState<any>(false);
  const [PredictedTaxes, setPredictedTaxes] = useState<any>([]);
  const [PredictedTotalTaxAmount, setPredictedTotalTaxAmount] = useState<number>(0);
  const [renewalEnableVisible, setRenewalEnableVisible] = useState<any>(false);
  const [couponRemoveVisible, setCouponRemoveVisible] = useState<any>(false);
  const [userData, setUserData] = useState<any>(props?.userData);
  const [paymentProfiles, setPaymentProfiles] = useState<any>([]);
  const service = new TenantService();
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [CouponsToAdd, setCouponsToAdd] = useState<any>([]);
  const [couponsList, setCouponsList] = useState<any>([]);

  const [showAddonOverview, setShowAddonOverview] = useState<any>(false);
  const [selectedAddon, setSelectedAddon] = useState<any>(false);
  const customFields = useCustomFieldsUpdate({ ...props, CustomFields: selectedPlan?.CustomFields || [], EntityId: selectedPlan?.MemberPlanId })
  const genericReducersubscription = useSelector((state: any) => {
    if (selectedAddon !== state.generic.selectedAddon) {
      setSelectedAddon(state.generic.selectedAddon)
      if (state.generic.selectedAddon) setShowAddonOverview(true)
      else setShowAddonOverview(false)
    }
  })

  // to get User Member Id

  useEffect(() => {
    let memberId = props?.userData?.UserMemberId;
    if (memberId) {
      fetchAllApis(memberId)
    }
  }, []);

  const fetchAllApis=async(memberId:any)=>{
    const taxes=await fetchTaxes() 
    fetchUserDetails(memberId);
    fetchPaymentProfiles(memberId);
    fetchPlanSummary(taxes);
  }

  const fetchTaxes = async () => {
    const req = {};
    const taxService= new ManageTaxesService()
    const res = await taxService.getTaxes(req);
    setPredictedTaxes(res);
    return res
  };

  useEffect(() => {
    if (customFields?.reload) {
      fetchPlanSummary();
    }
  }, [customFields]);
  useEffect(() => {
    if (props?.showAutoRenewal) {
      handleAutoRenewal();
    }
  }, [props?.showAutoRenewal]);

  async function fetchPaymentProfiles(userMemberId: any) {
    const result = await service.paymentProfile(userMemberId);
    setPaymentProfiles(result || []);
  }

  
  const makePaymentProfileAsDefault = async (dataItem: any) => {
    const req = {
      PaymentGatewayPaymentProfileID: dataItem?.PaymentGatewayPaymentProfileID,
      UserMemberId: props?.userData?.UserMemberId,
      CustomerProfileID:
        dataItem?.CustomerProfileID,
    };
    await axiosRequest.post(
      `PaymentProfile/update-preferred-payment-profile`,
      req,
      {
        successCallBack: async (response: any) => {
          props?.handleNotificationMessage(
            `Selected Payment profile marked as Preferred`,
            "success"
          );
          await fetchPaymentProfiles(props?.userData?.UserMemberId);
        },
        errorCallBack: (response: any) => {
          props?.handleNotificationMessage(
            response?.response?.data?.Messages?.[0] ||
              response?.data?.Messages?.[0] ||
              "Internal server error",
            "error"
          );
        },
      }
    );
  };

  const fetchUserDetails = async (userMemberId: any) => {
    setLoading(true);
    const service = new TenantService();
    const res = await service?.getUserInfo(userMemberId);
    setLoading(false);
    const {
      UserMemberId,
      UserId,
      MemberNumber,
      Status,
      User: {
        Email,
        FirstName,
        FullName,
        LastName,
        Photo,
        DateOfBirth,
        Address: { Phone },
      },
    } = res;
    const data = {
      UserMemberId,
      UserId,
      Email,
      FirstName,
      FullName,
      LastName,
      Photo,
      DateOfBirth,
      Phone,
      MemberNumber,
      Status,
    };
    setUserData(data);
  };
  const handleTaxCalculation=(purchaseAmt:number,TaxItems=PredictedTaxes)=>{
    const TaxesArray=TaxItems.map((i:any)=>{
      const amount=purchaseAmt*i?.Percentage/100;
      const calcAmt=CustomToFixed(amount,2)
        return {
          "TaxId": i?.TaxId,
          "TaxName": i?.TaxName,
          "Percentage": i?.Percentage,
          "Amount": calcAmt
        }
    })
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
          return acc + currentValue?.Amount
    }, 0);
      setPredictedTaxes(TaxesArray)
      setPredictedTotalTaxAmount(taxAmt)
  }

  const fetchPlanSummary = async (taxes=PredictedTaxes) => {
    customFields?.setReload(false)
    const req = {
      PageNumber: 0,
      PageSize: 32767,
      UserMemberId: props?.userData?.UserMemberId,
      MemberPlanId: props?.selectedPlanId,
      IncludeCustomFields: true
    };
    setLoading(true);
    const res = await service?.getMemberPlanSummary(req);
    props?.setParentId(res?.ParentMemberPlanId)
    props?.setSelectedSubscription(res);
    setLoading(false);
    let couponsArray = [...res?.Coupons, ...res?.CouponsHistory];

    setSelectedPlan(res);
    setCouponValue(null);
    setShowCoupon(false);
    setCouponsToAdd(res?.CouponsToAdd || []);
    setCouponsList(couponsArray || []);
    handleTaxCalculation(res?.RecurringAmount,taxes)
  };

  const fetchRenewDetails = async () => {
    const req = {
      UserMemberId: props?.userData?.UserMemberId,
      MemberPlanId: props?.selectedPlanId,
    };
    setLoading(true);
    const res = await service?.GetPlanRenewalDetails(req);
    setRenewalData(res);
    setLoading(false);
  };

  const AddCouponToPlan = async () => {
    const req = { ...couponValue, EntityType: 1 };
    setDialogLoading(true);
    const result = await service?.AddCouponToExistingPlan(req);
    setDialogLoading(false);
    if (result?.ResponseCode === 100) {
      const successMsg = result?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");

      await fetchPlanSummary();
    } else {
      const errorMsg = result?.ErrorMessages?.[0] || "Error In Adding Coupon";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const UpdatePIF = async (val: boolean) => {
    const req = { ...renewalData, PayInFull: val };
    setRenewalData({ ...renewalData, PayInFull: val });
    const result = await service?.UpdateRenewalPIF(req);
    if (result?.ResponseCode === 100) {
      const successMsg = result?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");

      handleCloseAutoRenewal();
      await fetchPlanSummary();
    } else {
      const errorMsg = result?.ErrorMessages?.[0] || "Error In Updating PIF";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const submitAutoRenewal = async (enableRenewal: boolean) => {
    if (enableRenewal) {
      //enable api call
      toggleRenewalEnableDialog();
    } else {
      //disable api call
      toggleRenewalDisableDialog();
    }
  };

  const handleSubmitAutoRenewal = async (
    enableRenewal: boolean,
    PIF = false
  ) => {
    let result;
    if (PIF) {
      setDialogPIFLoading(true);
    } else {
      setDialogLoading(true);
    }

    if (enableRenewal) {
      let req = { ...renewalData, PayInFull: PIF };
      if(selectedPlan?.DurationType===3){
        req = { ...renewalData, PayInFull: false };
      }
      //enable api call
      result = await service?.EnablePlanRenewal(req);
    } else {
      //disable api call
      let req = { ...renewalData};
      if(selectedPlan?.DurationType===3){
        req = { ...renewalData, PayInFull: false };
      }
      result = await service?.DisablePlanRenewal(req);
    }
    if (PIF) {
      setDialogPIFLoading(false);
    } else {
      setDialogLoading(false);
      setRenewalDisableVisible(false);
    }
    setRenewalEnableVisible(false);
    if (result?.ResponseCode === 100) {
      const successMsg = result?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");

      handleCloseAutoRenewal();
      await fetchPlanSummary();
    } else {
      const errorMsg =
        result?.ErrorMessages?.[0] || "Error In Updating AutoRenewal";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const handleRemoveCoupon = async () => {
    const req = removeCouponValue;
    setDialogLoading(true);
    const result = await service?.DeActivateMemberCoupon(req);
    setDialogLoading(false);
    if (result?.ResponseCode === 100) {
      const successMsg = result?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");
      await fetchPlanSummary();
    } else {
      const errorMsg = result?.ErrorMessages?.[0] || "Error In Removing Coupon";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const handleEditCoupon = async () => {
    const req = editCouponValue;
    setDialogLoading(true);
    const result = await service?.UpdateRenewalMemberCoupon(req);
    setDialogLoading(false);
    if (result?.ResponseCode === 100) {
      const successMsg = result?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");
      await fetchPlanSummary();
    } else {
      const errorMsg = result?.ErrorMessages?.[0] || "Error In Updating Coupon";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const toggleAddCouponDialog = () => {
    if (!couponValue) {
      const errorMsg = "Please select coupon";
      props?.handleNotificationMessage(errorMsg, "error");
      return;
    }
    if (!props?.staffPage && !CheckUserMemberStatus(userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    setCouponAddVisible(!couponAddVisible);
  };
  const toggleRenewalDisableDialog = () => {
    setRenewalDisableVisible(!renewalDisableVisible);
  };
  const toggleRenewalEnableDialog = () => {
    setRenewalEnableVisible(!renewalEnableVisible);
  };

  const handleAddCouponToPlan = async (val: boolean) => {
    if (val) {
      await AddCouponToPlan();
    }
    toggleAddCouponDialog();
  };
  const toggleRemoveCouponDialog = () => {
    setCouponRemoveVisible(!couponRemoveVisible);
  };
  const toggleEditCouponDialog = () => {
    setCouponEditVisible(!couponEditVisible);
  };

  const handleEditCouponItem = (dataItem: any) => {
    setEditCouponValue(dataItem);
    toggleEditCouponDialog();
  };
  const handleRemoveCouponItem = (dataItem: any) => {
    setRemoveCouponValue(dataItem);
    toggleRemoveCouponDialog();
  };

  const handleRemoveCouponFromPlan = async (val: boolean) => {
    if (val) {
      await handleRemoveCoupon();
    }
    toggleRemoveCouponDialog();
  };
  const handleEditCouponForPlan = async (val: boolean) => {
    if (val) {
      await handleEditCoupon();
    }
    toggleEditCouponDialog();
  };


  const handleCoupon = (val: any) => {
    setCouponValue(val);
  };

  const CouponfilterChange = (e: any) => {
    const filteredData = CouponFilterData(e.filter);
    setCouponsToAdd(filteredData);
  };

  const CouponFilterData = (filter: any) => {
    const data = selectedPlan?.CouponsToAdd.slice();
    return filterBy(data, filter);
  };

  const handleAutoRenewal = async () => {
    if (!props?.staffPage && !CheckUserMemberStatus(userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    if(props?.showAutoRenewal){
      await fetchPlanSummary()
    }
    await fetchRenewDetails();
    setShowAutoRenewal(!showAutoRenewal);
  };
  const handleCloseAutoRenewal = async () => {
    setShowAutoRenewal(false);
    props?.handleCloseAutoRenewal()
  };

  const handleRenewalChange = (
    val: any,
    name: string,
    entityId: number = 0,
    parent = null
  ) => {
    if (name === "PaymentGatewayProfileId") {
      const PaymentGatewayPaymentProfileID =
        val?.PaymentGatewayPaymentProfileID;
      setRenewalData({
        ...renewalData,
        [name]: PaymentGatewayPaymentProfileID,
      });
    } else if (name === "CouponRenewal") {
      const couponsUpdatedList = renewalData?.CouponsHistory.map((i: any) => {
        if (i?.MemberCouponId === val?.MemberCouponId) {
          if (val?.selected) {
            return { ...i, RenewType: 1 }
          } else {
            return { ...i, RenewType: 0 }
          }
        }
        return { ...i }
      })
      setRenewalData({ ...renewalData, CouponsHistory: couponsUpdatedList });
    } else if (parent !== null) {
      const test = renewalData[parent]?.map((i: any) => {
        if (i?.EntityId === entityId) {
          return { ...i, [name]: val };
        } else {
          return { ...i };
        }
      });
      setRenewalData({ ...renewalData, [parent]: test });
    } else {
      setRenewalData({ ...renewalData, [name]: val });
    }
  };
  const renderAutoRenewal = () => {
    let autoRenewEveryText=""
    if(selectedPlan?.DurationType ===1){
      autoRenewEveryText=`Every ${selectedPlan?.Duration} ${selectedPlan?.Duration===1 ? "month":"months"}`
    }else if(selectedPlan?.DurationType ===2){
      autoRenewEveryText=`Every Month`
    }else{
      autoRenewEveryText=`Every ${selectedPlan?.Duration} ${selectedPlan?.Duration===1 ? "day":"days"}`
    }
    return (
      <>
        <div className="bz-content-text-row-mobile">
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">{"Auto Renews"}</div>
            <span className="bz-colon text-center">{":"}</span>
            <div className="bz-content-body">
              {selectedPlan?.RenewMemberPlanId === 0 ? "Off" : "On"}{" "}
              {props?.staffPage ? <button
                className="btn btn-link"
                onClick={() => handleAutoRenewal()}
              >
                <i className="fas fa-pencil-alt"></i>
              </button> : null}
            </div>
          </div>
        </div>

       
            <div className="bz-content-text-row-mobile">
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">{"Next Renewal"}</div>
                <span className="bz-colon text-center">{":"}</span>
                <div className="bz-content-body">
                  {selectedPlan?.RenewMemberPlanId > 0 && selectedPlan?.EndDate !== "N/A"
                    ? moment(selectedPlan?.EndDate).format("L")
                    : `NA`}
                </div>
              </div>
            </div>

        {selectedPlan?.RenewMemberPlanId !== 0 && selectedPlan?.DurationType !== 3 && (
          <>
            <div className="bz-content-text-row-mobile">
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">{"Renewal Amount"}</div>
                <span className="bz-colon text-center">{":"}</span>
                <div className="bz-content-body">
                  {selectedPlan?.AutoRenewPayInFull ? `${CurrencyFormat(selectedPlan?.MonthlyRate)}` : `${CurrencyFormat(selectedPlan?.MonthlyRate)}`}
                </div>
              </div>
            </div>


            <div className="bz-content-text-row-mobile">
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">{"Type of Renewal"}</div>
                <span className="bz-colon text-center">{":"}</span>
                <div className="bz-content-body">
                  {selectedPlan?.AutoRenewPayInFull ? 'PIF' : 'Recurring Monthly'}
                </div>
              </div>
            </div>
          </>
        )}

        {selectedPlan?.RenewMemberPlanId > 0 ? (<> 
          <div className="bz-content-text-row-mobile">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">{"Renews"}</div>
              <span className="bz-colon text-center">{":"}</span>
              <div className="bz-content-body">
                {autoRenewEveryText}
              </div>
            </div>
          </div></>
        ):(<>
         <div className="bz-content-text-row-mobile">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">{"Expires"}</div>
              <span className="bz-colon text-center">{":"}</span>
              <div className="bz-content-body">
              {selectedPlan?.EndDate !== "N/A"
                  ? moment(selectedPlan?.EndDate).format("L")
                  : `NA`}
              </div>
            </div>
          </div>
        </>)}

        {selectedPlan?.RenewedCount > 0 && (
          <div className="bz-content-text-row-mobile">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">{"Renewals Completed"}</div>
              <span className="bz-colon text-center">{":"}</span>
              <div className="bz-content-body">
                {selectedPlan?.RenewedCount}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {!showAddonOverview ? (
        <div>
          {showAutoRenewal ? (
            <AutoRenewal
              renewalData={renewalData}
              handleCloseAutoRenewal={handleCloseAutoRenewal}
              submitAutoRenewal={submitAutoRenewal}
              loading={loading}
              UpdatePIF={UpdatePIF}
              paymentProfiles={paymentProfiles}
              handleRenewalChange={handleRenewalChange}
              selectedPlan={selectedPlan}
              makePaymentProfileAsDefault={makePaymentProfileAsDefault}
            />
          ) : (
            <>
              <h5 className="mb-3 bz_fs_1_5">Membership Overview</h5>
              <hr />
              <div className="bz-subsection-row bz-plan-overview">
                <div className="left-section">
                  <h5 className="mb-3 pt-3 bz_fs_1_5">Subscription Info  <span
                              className={`badge badge-status-${(((selectedPlan?.RecurringDate !== "N/A" 
                                && selectedPlan?.CancellationDate!=null 
                                && selectedPlan?.CancellationDate<selectedPlan?.RecurringDate 
                                ) || (selectedPlan?.RecurringDate === "N/A" && selectedPlan?.CancellationDate!=null) && selectedPlan?.planStatusDescription == 'Active')) ?  "Cancel-in-Progress" : selectedPlan?.planStatusDescription}`}
                            >
                              {(((selectedPlan?.RecurringDate !== "N/A" 
                                && selectedPlan?.CancellationDate!=null 
                                && selectedPlan?.CancellationDate<selectedPlan?.RecurringDate 
                                ) || (selectedPlan?.RecurringDate === "N/A" && selectedPlan?.CancellationDate!=null) && selectedPlan?.planStatusDescription == 'Active')) ?  "Cancel-in-Progress" : selectedPlan?.planStatusDescription}
                            </span></h5>
                  <SubscriptionDetails
                    selectedPlan={selectedPlan}
                    loading={loading}
                    staffPage={props?.staffPage}
                    PredictedTaxes={PredictedTaxes}
                    PredictedTotalTaxAmount={PredictedTotalTaxAmount}
                  />
                  
                  {props?.staffPage ?  <>
                    <hr />

                    <div className="applied-coupons">
                      <h5 className="mb-3 pt-3 bz_fs_1_5">Applied Coupons</h5>
                      <Coupons
                        loading={loading}
                        status={selectedPlan?.planStatusDescription}
                        showCoupon={showCoupon}
                        selectedPlan={selectedPlan}
                        setShowCoupon={setShowCoupon}
                        CouponsToAdd={CouponsToAdd}
                        handleCoupon={handleCoupon}
                        couponValue={couponValue}
                        CouponfilterChange={CouponfilterChange}
                        couponsList={couponsList}
                        toggleAddCouponDialog={toggleAddCouponDialog}
                        handleRemoveCouponItem={handleRemoveCouponItem}
                        handleEditCouponItem={handleEditCouponItem}
                      />
                    </div>
                  </>:null}
                  {selectedPlan?.RenewalApplicable && (
                    <>
                    <hr />
                      <h5 className="mb-3 pt-3 bz_fs_1_5">Renewal Details</h5>
                      {renderAutoRenewal()}
                    </>
                  )}
                 {selectedPlan &&  selectedPlan?.DurationType === 3 && selectedPlan?.NoOfVisits!=null && selectedPlan?.NoOfVisits >= 0 &&(
                  <div><hr />
                  <h5 className="mb-3 pt-3 bz_fs_1_5">Visit History</h5>
                  <AuditHistory
                    {...props}
                    EntityId={selectedPlan?.MemberPlanId}
                    UserMemberId={userData?.UserMemberId}
                    Type={2} 
                    TypeOfChange={524288}                   
                    heading={false}
                    userData={userData}
                    uuid={props?.uuid}
                    staffPage={props?.clientId ? true : false}
                    auditHistoryView={true}
                  />
                  </div>)}
                  {customFields?.fieldsData?.length > 0 && (<>
                    <hr />
                    <h5 className="mb-3 pt-3 bz_fs_1_5">Additional Information</h5>
                    {loading ? <BuzopsLoader type={"list"} /> : <CustomFieldsUpdate {...customFields} update={true} />}
                  </>)}


                </div>

                 <div className="right-section mt-3">                  
                    {selectedPlan &&  selectedPlan?.DurationType === 3 && selectedPlan?.NoOfVisits!=null && selectedPlan?.NoOfVisits >= 0 &&(
                      <Card
                        className={
                          "bz-card bz-overview-cards bz-appoinments-overview-card pt-0"
                        }
                        style={{ backgroundColor: "#eff4f7" }}
                      >
                        <CardBody>
                          <MembershipVisitsBox
                            selectedPlan={selectedPlan}
                            // handleAppointmentsEdit={handleAppointmentsEdit}
                            staffPage={props?.staffPage}
                          />
                        </CardBody>
                      </Card>
                    )}
                  </div> 
              </div>
            </>
          )}
          {renewalDisableVisible && (
            <Dialog onClose={() => toggleRenewalDisableDialog()}>
              <p className={"dialogContainer mb-0"}>
                Do you want to disable renewal ?
              </p>
              <DialogActionsBar>
                <Button onClick={() => toggleRenewalDisableDialog()}>
                  {"Cancel"}
                </Button>
                <BuzopsButton
                  label="Ok"
                  loading={dialogLoading}
                  disabled={dialogLoading}
                  onClick={() => handleSubmitAutoRenewal(false)}
                />
              </DialogActionsBar>
            </Dialog>
          )}
          {renewalEnableVisible && (
            <Dialog onClose={() => toggleRenewalEnableDialog()}>
              <p className={"dialogContainer"}>
                {selectedPlan?.DurationType===3 ? "Do you want to enable renewal ?":"Do you want to enable?"}
              </p>
              <DialogActionsBar>
                <BuzopsButton
                  label="Yes"
                  loading={dialogPIFLoading}
                  disabled={dialogPIFLoading}
                  onClick={() => handleSubmitAutoRenewal(true, true)}
                />
                {/* {selectedPlan?.DurationAudit HistoryType !==3 && <BuzopsButton
                  label="No"
                  loading={dialogLoading}
                  disabled={dialogLoading}
                  onClick={() => handleSubmitAutoRenewal(true, false)}
                />} */}
                <Button onClick={() => toggleRenewalEnableDialog()}>
                  {"Cancel"}
                </Button>
              </DialogActionsBar>
            </Dialog>
          )}
          {couponAddVisible && (
            <Dialog
              className="bz-book-Appoinment-dialog bz-applyCoupon-dialog"
              title={"Are you sure you want to apply the selected coupon?"}
              onClose={() => toggleAddCouponDialog()}
              width={"32rem"}
            >
              {selectedPlan?.Coupons?.some((i:any)=>i?.Type===couponValue?.Type) && <div className={"alert alert-warning py-2 px-3"}>
                <strong>Note:</strong> It will override if there is any existing same type of coupon.
              </div>}
              <div className="ready-to-book-appt-dialog">
                <div className="column-1-section text-right">
                  Name<span className="purchased-dots px-3">:</span>
                </div>
                <div className="column-2-section" style={{fontWeight:600}}>{couponValue?.Description}</div>
                {couponValue?.DurationType === 3 && <>
                  <div className="column-1-section text-right">
                    Auto Renews<span className="purchased-dots px-3">:</span>
                  </div>
                  <div className="column-2-section"><Checkbox name={`Coupon${couponValue?.CouponId}`} id={`Coupon${couponValue?.CouponId}`} value={couponValue?.RenewType === 1 ? true : false}
                    onChange={(e) => {
                      setCouponValue({ ...couponValue, RenewType: e.value ? 1 : 0 });
                    }} /></div></>}
              </div>
              <DialogActionsBar>
                <Button onClick={() => handleAddCouponToPlan(false)}>
                  {"Cancel"}
                </Button>
                <BuzopsButton
                  label="Ok"
                  loading={dialogLoading}
                  disabled={dialogLoading}
                  onClick={() => handleAddCouponToPlan(true)}
                />
              </DialogActionsBar>
            </Dialog>
          )}
          {couponRemoveVisible && (
            <Dialog onClose={() => toggleRemoveCouponDialog()}>
              <p className={"dialogContainer"}>Are you sure to Deactivate ?</p>
              <DialogActionsBar>
                <Button onClick={() => handleRemoveCouponFromPlan(false)}>
                  {"Cancel"}
                </Button>
                <BuzopsButton
                  label="Ok"
                  loading={dialogLoading}
                  disabled={dialogLoading}
                  onClick={() => handleRemoveCouponFromPlan(true)}
                />
              </DialogActionsBar>
            </Dialog>
          )}
          {couponEditVisible && (
            <Dialog
              className="bz-book-Appoinment-dialog"
              title={"Update Coupon Renewal"}
              onClose={() => toggleEditCouponDialog()}
              width={"35%"}
            >
              <div className="ready-to-book-appt-dialog">
                <div className="column-1-section">
                  Name<span className="purchased-dots">:</span>
                </div>
                <div className="column-2-section">{editCouponValue?.CouponName}</div>
                {editCouponValue?.DurationType === 3 && <>
                  <div className="column-1-section">
                    Auto Renews<span className="purchased-dots">:</span>
                  </div>
                  <div className="column-2-section"><Checkbox name={`Coupon${editCouponValue?.MemberCouponId}`} id={`Coupon${editCouponValue?.MemberCouponId}`} value={editCouponValue?.RenewType === 1 ? true : false}
                    onChange={(e) => {
                      setEditCouponValue({ ...editCouponValue, RenewType: e.value ? 1 : 0 });
                    }} /></div></>}
              </div>
              <DialogActionsBar>
                <Button onClick={() => handleEditCouponForPlan(false)}>
                  {"Cancel"}
                </Button>
                <BuzopsButton
                  label="Ok"
                  loading={dialogLoading}
                  disabled={dialogLoading}
                  onClick={() => handleEditCouponForPlan(true)}
                />
              </DialogActionsBar>
            </Dialog>
          )}
        </div>)
        : <div>
          {<AddonOverView
            {...props}
            selectedAddonId={selectedAddon.id}
            userData={userData}
            staffPage={props?.staffPage}
            goToProfile={() => props?.goToProfile()}
          />}
        </div>}
    </>
  );
};

export default withNotification(PlanOverview);
