import React, { useMemo, memo } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import {
  billingPeriodItemRender,
  billingPeriodValueRender,
} from "components/Appointments/SessionSignOff";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import moment from "moment";
import { SessionTypeEnum } from "utils/form-utils";

interface BillingPeriodsDialogProps {
  packageBillingCycle: any[];
  selectedPackage: any;
  packageBillingPeriod: any;
  handleSelectPackageBillingPeriod: (val: any) => void;
  handleSelectClass: (
    item: any,
    type?: any,
    pickPackage?: any,
    selectBillingPeriod?: boolean
  ) => void;
  selectedClassSession: any;
  setPopoverIndex: (val: number | null) => void;
  popoverIndex: number | null;
}

const RenderBillingPeriodsDialog: React.FC<BillingPeriodsDialogProps> = ({
  packageBillingCycle,
  selectedPackage,
  packageBillingPeriod,
  handleSelectPackageBillingPeriod,
  handleSelectClass,
  selectedClassSession,
  setPopoverIndex,
  popoverIndex,
}) => {
  const isOpen = !!popoverIndex;

  const billingPeriods = useMemo(() => {
    if (!selectedPackage) return [];
    if (selectedPackage.SessionType === SessionTypeEnum.LIMITED) {
      return (
        packageBillingCycle?.filter((i: any) => i?.redeemableSchedules > 0) ??
        []
      );
    }
    return packageBillingCycle ?? [];
  }, [packageBillingCycle, selectedPackage]);

  const findBillingItem = useMemo(() => {
    if (!packageBillingPeriod?.ExpireFrom) return null;
    return billingPeriods.find(
      (i: any) =>
        moment(i?.ExpireFrom).format("YYYY-MM-DD") ===
        moment(packageBillingPeriod?.ExpireFrom).format("YYYY-MM-DD")
    );
  }, [billingPeriods, packageBillingPeriod]);

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      // onClose={() => setPopoverIndex(null)}
    >
      <div
        className="dialog-options"
        style={{ overflow: "hidden" }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mb-3">
          <div className="d-flex align-items-center">
            <small className="mb-1 lablesignoff">
              Select a Period with Unused Sessions
            </small>
            <BuzopsTooltip
              position="right"
              html={
                <a
                  title="Choose the time period where the unused sessions were originally credited. This allows you to sign off and apply them for use."
                  className="pl-2 bz-cursor-pointer"
                >
                  <i
                    style={{ fontSize: "16px" }}
                    className="fa fa-info-circle"
                  />
                </a>
              }
            />
          </div>
          <DropDownList
            data={billingPeriods}
            value={findBillingItem}
            onChange={(event) => handleSelectPackageBillingPeriod(event.value)}
            textField="ExpireFrom"
            dataItemKey="ExpireFrom"
            itemRender={(
              li: React.ReactElement<HTMLLIElement>,
              itemProps: ListItemProps
            ) =>
              billingPeriodItemRender(
                li,
                itemProps,
                selectedPackage,
                "redeemableSchedules"
              )
            }
            valueRender={(
              el: React.ReactElement<HTMLSpanElement>,
              value: any
            ) =>
              billingPeriodValueRender(
                el,
                value,
                findBillingItem,
                selectedPackage,
                "redeemableSchedules"
              )
            }
            style={{ width: "100%" }}
            className="customDropdown bz-oneonone-dropdown"
            listNoDataRender={() => (
              <span className="bzo-noData-found">
                No unused sessions available for sign-off
              </span>
            )}
          />
        </div>

        <div className="row justify-content-end">
          <div className="col-12 text-right">
            <Button onClick={() => setPopoverIndex(null)}>Cancel</Button>
            <Button
              primary
              onClick={() => {
                handleSelectClass(selectedClassSession, "add", null, true);
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default memo(RenderBillingPeriodsDialog);
