import AddClient from "components/booking/AddClient";
import useState from "react-usestateref";
import React, { useEffect, useRef } from "react";
import withNotification from "components/Hoc/withNotification";
import { ClassService } from "services/class/index.service";
import { TenantService } from "services/tenant/index.service";
import { GetLocalStore } from "utils/storage";
import { CheckUserMemberStatus, CustomToFixed, checkHasTaxEnable, getFullImageUrl, getTimeZoneById, getUserTimeZoneLong } from "utils";
import SessionSignoffLeftCard from "./SessionSignoffLeftCard";
import { GenderTypeEnum, PackageInstanceStatusEnum, PackageTypesList, PrivateServiceTypeEnum, ScheduleAppointmentkeys, SessionTypeEnum, TypeEnum } from "utils/form-utils";
import { useHistory, useLocation } from "react-router-dom";
import SessionSignoffRightCard from "./SessionSignoffRightCard";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import moment from "moment";
import { useApiClient } from "services/axios-service-utils";
import { PackageService } from "services/package/index.service";
import { ListView } from "@progress/kendo-react-listview";
import BuzopsLoader from "generic-components/BuzopsLoader";
import TimeZoneSetup from "utils/time-zone-setup";
import { debounce } from "utils/utils";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { DropDownList, DropDownListOpenEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import GroupScheduleComponent from "components/booking/GroupScheduleComponent";
import bzImages from "Images";
import { Error } from "@progress/kendo-react-labels";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";


export interface IPropValues {
  selectedService?: any
  selectedPackage?: any
  selectedProvider?: any
  welcomePage?: any
  selectedPackageInstanceId?: any
  Complimentary?: any
  enrollPackage?: any
  quicklinks?: any
  quickcheckout?: any
  selectedEvent?: any
  UpdateAppointment?: any
  userData?: any
  staffPage?: any
  calendarPage?: any
  EditEvents?: any
  uuid?: any
  clientId?: any
  showPackageSelection?: any
  Type?:any;
  purchaseGroup?:any;
}


interface ISessionSignOff {
  handleClientSelection: (val: boolean) => void;
  handleNotificationMessage: (val: string, mes: string) => void;
  isClientSelected: boolean;
  propValues: IPropValues;
  handleGoToOverview: () => void;
  isDialogVisible: boolean;
  showOnlySignoff: boolean;
  handleWindow?: any
}

export const billingPeriodValueRender = (
  element: React.ReactElement<HTMLSpanElement>,
  value: any,
  selectedValue: any,
  selectedPackage: any,
  key = "SchedulesToUse"
) => {
  let children = [<></>];
  if (selectedValue) {
    const schedulesToUse = selectedPackage?.SessionType === SessionTypeEnum?.LIMITED ? selectedValue?.[key] : "UNLIMITED";
    const schedules = selectedPackage?.SessionType === SessionTypeEnum?.LIMITED ? selectedValue?.Schedules : "UNLIMITED";
    children = [
      <div className={"row align-items-center w-100"}>
        <span className="primary-heading">
          {`${selectedValue.ExpireFrom === null
            ? "N/A"
            : moment(selectedValue.ExpireFrom).format("MMM D")
            } - ${selectedValue.ExpireOn === null
              ? "N/A"
              : moment(selectedValue.ExpireOn).format("MMM D, YYYY")
            }`}
        </span>
        <br />
        <small className="package-schedules ">
          {schedulesToUse !== "UNLIMITED" ? `(${schedulesToUse} unused sessions of ${schedules})` : `Available Appointments: ${schedulesToUse}`}
        </small>
      </div>,
    ];
  } else {
    children = [
      <div className={"row align-items-center w-100"}>
        <span>Select a Time Period</span>
      </div>,
    ];
  }

  return React.cloneElement(element, { ...element.props }, children);
};

export const billingPeriodItemRender = (
  li: React.ReactElement<HTMLLIElement>,
  itemProps: ListItemProps,
  selectedPackage: any,
  key = "SchedulesToUse"
) => {
  const item = itemProps.dataItem;
  const schedulesToUse = selectedPackage?.SessionType === SessionTypeEnum?.LIMITED ? item?.[key] : "UNLIMITED";
  const schedules = selectedPackage?.SessionType === SessionTypeEnum?.LIMITED ? item?.Schedules : "UNLIMITED";
  const itemChildren = (
    <div className={"row align-items-center w-100"}>
      <span className="primary-heading">
        {`${item.ExpireFrom === null
          ? "N/A"
          : moment(item.ExpireFrom).format("MMM D")
          } - ${item.ExpireOn === null
            ? "N/A"
            : moment(item.ExpireOn).format("MMM D, YYYY")
          } `}
      </span>
      <br />
      <small className="package-schedules ">
        {schedulesToUse !== "UNLIMITED" ? `(${schedulesToUse} unused sessions of ${schedules})` : `Available Appointments: ${schedulesToUse}`}
      </small>
    </div>
  );

  return React.cloneElement(li, li.props, itemChildren);
};
const SessionSignOff = (props: ISessionSignOff) => {
  const {
    isClientSelected = false,
    propValues,
    handleGoToOverview,
    isDialogVisible,
    showOnlySignoff = false,
  } = props;
  const slotRef: any = useRef(null);
  const anchor: any = useRef([]);
  const history = useHistory();
  const { axiosRequest } = useApiClient();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const iframeGuestPages = query.get("layout") === "iframeguest";
  const [clientFormValues, setClientFormValues] = useState<any>({});
  const [clientsList, setClientsList] = useState<any>([]);
  const [clientSubmitBtnLoading, setClientSubmitBtnLoading] = useState(false);
  const [clientSearchLoading, setClientSearchLoading] = useState(false);
  const [showPackageDialog, setShowPackageDialog] = useState(false);
  const [showOnlySignoffAppts, setShowOnlySignOffAppts] = useState(showOnlySignoff);
  const [startSessionSignOff, setStartSessionSignOff] = useState({
    isGroup: false,
    value: false
  });
  const [packageSelection, setPackageSelection, packageSelectionRef] = useState<any>({
    dataItem: null,
    SelectedBillingPeriod: null
  });
  const [selectedClient, setSelectedClient] = useState<any>({
    type: "search",
    value: "",
  });
  const [userDetails, setUserDetails, userDetailsRef] = useState<any>(propValues?.userData ? propValues?.userData : null);
  const [selectedradioValue, setselectedradioValue] = useState("");
  const tenantDataValues = GetLocalStore("tenantUserSession");
  const defaultTimezone = getTimeZoneById(tenantDataValues?.TimeZone?.Id);
  const initialSchedulerCalendarState = {
    tz: {
      timezoneValue: defaultTimezone,
      initial: true,
    },
    selectedSchItems: [],
    btnLoading: false,
    checkout: false,
    appliedCoupons: [],
    packageCostAfterDiscount: 0,
    taxes: [],
    TotalTaxAmount: 0,
    IsTaxSkipped: false,
    couponAmount: 0,
    confirmationdialog: false,
    showDialog: false,
    selectedPaymentProfile: {
      selectedPayment: undefined,
      AlternatePaymentProfileId: null,
    },
    paymentSubmitProfile: null,
    paymentProfile: null,
    filterCoupondata: [],

    selectedSalesPerson: null,
    termsandConditions: false,
    termsandConditionsDialog: false,
    coupon: null,
    packageLoading: false,
    providersLoading: false,
    appointmentsLoading: false,
    mainLoading: false,
    pickPackage: {
      showDialog: false,
      selectedAppt: null
    },
    availExistingSch: [],
  };
  const [
    schedulerCalendarState,
    setSchedulerCalendarState,
    schedulerCalendarStateRef,
  ] = useState<any>(initialSchedulerCalendarState);

  const [signOffDialog, setsignOffDialog, signOffDialogRef] = useState(false);
  const initialAdditionalPayment = {
    dialog: false,
    selectedPaymentProfile: null,
  };
  const [showAdditionalPayment, setShowAdditionalPayment] = useState<any>(
    initialAdditionalPayment
  );

  const timeoutRef = useRef(null);

  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const day = new Date().getDate();
  const oneYearAgo = new Date(year, month - 12, day);
  const initialMainState = {
    Appointment: null,
    Provider: null,
    Package: null,
    originalPackage: null,
    ProviderCounter: 0,
    selectedSchedules: [],
    showAppointment: false,
    AddPackage: false,
    ShowPurchasedPackages: true,
    // main
    AvailablePackages: [],
    providerList: [],
    appointmentList: {
      pageNumber: 0,
      maxPageNumber: 0,
      appointmentData: [],
    },
    salesPersons: [],
    SelectedApptFilter: { Id: 0, Name: 'All' },
    PackageClassIds: {},
    minDate: oneYearAgo,
    selectedPackage: null,

  };

  const [mainState, setMainState, mainStateRef] =
    useState<any>(initialMainState);

  const initialApptValues = {
    provider: undefined,
    notes: "",
    signOffDate: new Date(moment().format()),
    PreviousSchedule: undefined,
    BillingPeriodFrom: null,
    BillingPeriodTo: null,
  };


  const [selectedAppt, setSelectedAppt, selectedApptRef] = useState<any>(initialApptValues);
  const [multipleSlots, setMultipleSlots] = useState<any>([]);

  let userSessionUserId: any;
  if (propValues?.staffPage || propValues?.calendarPage || propValues?.quicklinks) {
    const tenantUserSession = GetLocalStore("tenantUserSession");
    userSessionUserId = tenantUserSession?.UserId;
  }
  useEffect(() => {
    TimeZoneSetup(tenantDataValues?.TenantTime, tenantDataValues);
  }, []);


  useEffect(() => {
    if (userDetailsRef?.current) {
      fetchAllAPis()
    }
  }, [userDetailsRef?.current]);

  useEffect(() => {
    if (propValues?.userData?.UserMemberId) {
      fetchUserDetails(propValues?.userData?.UserMemberId)
    }
  }, [propValues?.userData])

  useEffect(() => {
    if (propValues?.showPackageSelection) {
      setShowPackageDialog(propValues?.showPackageSelection)
    }
  }, [propValues?.showPackageSelection])

  const fetchUserDetails = async (UserMemberId: any) => {
    const clientservice = new TenantService();
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      mainLoading: true,
    });
    const result = await clientservice.getClient(UserMemberId);
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      mainLoading: true,
    });
    const clientSubmitDetails = {
      Email: result?.User?.Email,
      FirstName: result?.User?.FirstName,
      LastName: result?.User?.LastName,
      Photo: result?.User?.Photo,
      PrimaryMemberId: result?.ParentId || result?.UserMemberId,
      FullName: `${result?.User.FirstName} ${result?.User.LastName}`,
      UserId: result?.UserId,
      UserMemberId: result?.UserMemberId,
      MemberNumber: result?.MemberNumber,
      Status: result?.Status,
      Phone: result?.User?.Address?.Phone,
    };

    setUserDetails(clientSubmitDetails);
  }

  const fetchAllAPis = async () => {
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      mainLoading: true,
    });
    if(propValues?.Type === TypeEnum.Class && propValues?.selectedPackageInstanceId && propValues?.quickcheckout){
      const PackageInstanceId = propValues?.selectedPackageInstanceId ? propValues?.selectedPackageInstanceId : null
      await fetchPackagesToUse(PackageInstanceId)
    }else{
      await fetchSalesPerson()
      await fetchTaxes()
      if (propValues?.selectedService) {
        await fetchPackagesToUse()
        await fetchServices(0);
        setMainState({
          ...mainStateRef?.current,
          Appointment: { ...propValues?.selectedService, ProratedRate: propValues?.selectedService?.Rate }
        })
        const trainerId = propValues?.selectedProvider?.TrainerId ? propValues?.selectedProvider?.TrainerId : null
        await fetchTrainers(trainerId)
        await fetchCoupons()
      }
      else if (propValues?.selectedPackageInstanceId) {
        const PackageInstanceId = propValues?.selectedPackageInstanceId ? propValues?.selectedPackageInstanceId : null
        await fetchPackagesToUse(PackageInstanceId)
      }
      else {
        await fetchPackagesToUse()
        await fetchServices(0);
  
      }
    }
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      mainLoading: false,
    });
  }

  const fetchTaxes = async () => {
    const req = {};
    const taxService = new ManageTaxesService();
    const res = await taxService.getTaxes(req);
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      taxes: res,
    });
  };

  const fetchServices = async (pageNumber: number) => {
    let pageSize = 100;
    if (mainStateRef?.current?.Package || propValues?.UpdateAppointment || propValues?.selectedPackageInstanceId) {
      pageSize = 32657;
    }
    let req: any = {
      IncludeHostOnlineOnly: (propValues?.staffPage || propValues?.calendarPage || propValues?.quicklinks) ? false : true,
      PageNumber: pageNumber,
      UserMemberId: userDetailsRef?.current?.UserMemberId,
      PageSize: pageSize,
      PrivateServiceType:
        parseInt(mainStateRef?.current?.SelectedApptFilter?.Id) === 0 ? undefined : parseInt(mainStateRef?.current?.SelectedApptFilter?.Id),
      Name: mainStateRef?.current?.ApptNameSearch,
      IsImmediateSignOff: true,
      IsComplimentary: propValues?.Complimentary,

    };

    if (mainStateRef?.current?.SelectedApptFilter?.Id === 3) {
      req.IncludePreset = true;
      req.PrivateServiceType = null;
    } else {
      req.IncludePreset = false;
    }

    const tenantService = new TenantService();
    if (pageNumber === 0) {

      setSchedulerCalendarState({
        ...schedulerCalendarStateRef?.current,
        appointmentsLoading: true,
      });
    }
    const result = await tenantService.getAppointmentDetailsWithTotalCount(req);
    if (pageNumber === 0) {
      setSchedulerCalendarState({
        ...schedulerCalendarStateRef?.current,
        appointmentsLoading: false,
      });
    }
    if (result) {
      if (mainStateRef?.current?.Package) {
        const filteredData = result?.Items?.filter((service: any) =>
          mainStateRef?.current?.Package?.ClassIds.includes(service.PrivateServiceId)
        );
        setMainState({
          ...mainStateRef.current,
          appointmentList: {
            pageNumber: pageNumber,
            maxPageNumber: result?.TotalItems ? Math.ceil(result?.TotalItems / pageSize) - 1 : 0,
            appointmentData: filteredData || [],
          },
          Appointment: mainStateRef?.current?.Package?.SessionType === SessionTypeEnum.PRESET ? filteredData[0] : null,
        })
        if (mainStateRef?.current?.Package?.SessionType === SessionTypeEnum.PRESET) {
          await fetchTrainers();
          setSchedulerCalendarState({
            ...schedulerCalendarStateRef?.current,
            IsTaxSkipped:
              mainStateRef?.current?.Appointment?.CanCollectTaxes &&
                checkHasTaxEnable()
                ? false
                : true,
          });
        }
      } else {
        if (pageNumber === 0) {
          setMainState({
            ...mainStateRef.current,
            appointmentList: {
              pageNumber: pageNumber,
              maxPageNumber: result?.TotalItems ? Math.ceil(result?.TotalItems / pageSize) - 1 : 0,
              appointmentData: result?.Items || [],
            }
          })
        }
      }
    }
    return result?.Items || [];
  };
  const [height, setHeight] = useState(window.innerHeight * 0.82);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight * 0.82);
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isLargeScreen = viewportWidth >= 977;
  const checkBillingCycleValidation = (billingPeriodData: any) => {
    const records = billingPeriodData?.filter(
      (i: any) => i?.SchedulesToUse > 0
    );
    if (records?.length > 0) {
      return true;
    }
    return false;
  };
  const fetchPackagesToUse = async (PackageInstanceId: any = null) => {
    const service = new PackageService();
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      packageLoading: true,
    });
    const startDate = moment().format("YYYY-MM-DD") + "T00:00:00";
    const ToDate = moment().add(1, "year").format("YYYY-MM-DD") + "T23:59:59";
    const req = {
      UserMemberId: userDetailsRef?.current?.UserMemberId,
      FromDate: startDate,
      ToDate,
      IsQuickSignOff: true
    };
    const res = await service.packageInstanceToUse(req);
    const resValue= res?.filter((i:any)=> i?.Status !== PackageInstanceStatusEnum.FUTURENOTPAID)
    const result = resValue?.filter((i: any) => {
      if (i?.SessionType !== SessionTypeEnum.PRESET) {
        if (i?.BillingPeriods?.length > 0) {
          if (i?.SessionType === SessionTypeEnum.UNLIMITED) {
            return true
          }
          return checkBillingCycleValidation(i?.BillingPeriods)
        }
        return i?.SchedulesToUse > 0
      } else {
        return i?.SchedulesToUse > 0
      }
    })
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      packageLoading: false,
    });
    const classIdsTemp: any = {};
    result.forEach((val: any) => {
      val?.ClassIds.forEach((clsIds: any) => {
        classIdsTemp[clsIds] = clsIds;
      });
    });

    let data = {
      ...mainStateRef?.current,
      AvailablePackages: resValue || [],
      PackageClassIds: !showOnlySignoffAppts ? classIdsTemp : {}
    }
    setMainState(data);
    if (PackageInstanceId) {
      const selectedPackage = resValue.find((packageInstance: any) => {
        return packageInstance.PackageInstanceId == PackageInstanceId;
      });
      data = {
        ...mainStateRef?.current,
        AvailablePackages: resValue || [],
        Package: selectedPackage,
        originalPackage: selectedPackage,
        PackageClassIds: classIdsTemp,
        PurchasePackage: null, AddPackage: false,
        minDate: oneYearAgo,
        // minDate: selectedPackage?.SessionType === SessionTypeEnum.PRESET ? oneYearAgo : new Date(selectedPackage?.ExpireFrom),
      }
      setPackageSelection({
        ...packageSelectionRef?.current,
        dataItem: selectedPackage
      })
      fetchServices(0);
      setMainState(data)
      fetchPackageSummary();


    }


  }


  const fetchSalesPerson = async () => {
    const service = new TenantService();
    const includeAffiliates = propValues?.staffPage ? false : true;
    const res = await service.SalesPerson(includeAffiliates);
    let selectedSalesPersonValue = null
    if (userSessionUserId && res) {
      const salesPersonRecord = res?.find(
        (item: any) => item?.UserId === userSessionUserId
      );
      if (salesPersonRecord) {
        selectedSalesPersonValue = salesPersonRecord
      }
    }
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      selectedSalesPerson: selectedSalesPersonValue,
    });
    setMainState({
      ...mainStateRef?.current,
      salesPersons: res || []
    });
  };


  async function fetchTrainers(trainerId = null) {
    const trainers = new TenantService();
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      providersLoading: true,
    });
    const getTrainers = await trainers.getTrainers();
    const result = getTrainers?.filter((i: any) => i?.IsActive)
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      providersLoading: false,
    });

    if (result) {
      if (trainerId) {
        const selectedTrainer = result?.find((i: any) => i?.AssociateId === trainerId)
        setMainState({
          ...mainStateRef.current,
          providerList: result,
          Provider: selectedTrainer
        })
      } else {
        setMainState({
          ...mainStateRef.current,
          providerList: result
        })
      }
    }
  }

  async function fetchCoupons() {
    const service = new TenantService();
    let req;

    req = {
      UserMemberId: userDetailsRef?.current?.UserMemberId,
      EntityId: mainStateRef?.current?.Appointment?.PrivateServiceId ? mainStateRef?.current?.Appointment?.PrivateServiceId : 0,
      EntityType: 6,
      CouponType: 1,
    };
    const result = await service.AvailableCouponsToApply(req);
    if (result) {
      setSchedulerCalendarState({
        ...schedulerCalendarStateRef?.current,
        filterCoupondata: result,
      });
    }
  }

  const handleClientSubmit = async (dataItem: any) => {
    setClientFormValues(dataItem);
  };


  const handleAddClient = async (dataItem: any) => {
    const req = {
      FirstName: dataItem?.FirstName,
      LastName: dataItem?.LastName,
      Phone: dataItem?.PhoneNumber,
      Email: dataItem?.Email,
      SalesPersonId: 0,
      UserId: dataItem?.UserId,
      ReferredFrom: dataItem?.ReferredFrom?.ReferredFrom,
      SpecifyOthers: dataItem?.ReferredFrom?.ReferredFrom==="Other"? dataItem?.SpecifyOthers:null,
      Gender:dataItem?.Gender?.id,
      GenderSpecifyOthers:dataItem?.Gender?.id === GenderTypeEnum.Others ? dataItem?.GenderSpecifyOthers : null,
      IsPrimaryClub:dataItem?.IsPrimaryClub,
    };
    setClientSubmitBtnLoading(true);
    const checkoutClass = new ClassService();
    const result = await checkoutClass.addClient(req);
    if (result.ResponseCode === 100) {
      setClientSubmitBtnLoading(false);
      setselectedradioValue("pick");
      const addedClient = {
        Email: result?.Item.Email,
        MemberNumber: result?.Item.MemberNumber,
        FullName: `${dataItem.FirstName} ${dataItem.LastName}`,
        MainMemberId: result?.Item.UserMemberId,
        UserId: result?.Item.UserId,
        UserMemberId: result?.Item.UserMemberId,
        FirstName: result?.Item.FirstName,
        LastName: result?.Item.LastName,
        Photo: result?.Item?.Photo,
        Phone: dataItem?.PhoneNumber,
      };
      setUserDetails(addedClient);

      setSelectedClient({
        ...selectedClient,
        type: "select",
        value: addedClient,
      });
      props?.handleClientSelection(true);
      if (propValues?.showPackageSelection) {
        setShowPackageDialog(propValues?.showPackageSelection)
      }
      const successMsg = "Client Added Successfully";
      props?.handleNotificationMessage(successMsg, "success");
    } else {
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
      setClientSubmitBtnLoading(false);
    }
  };

  const handleClientSelecton = (e: any) => {
    if (e.value.isButton) {
      return;
    }
    if (e.syntheticEvent?.key === "Enter") {
      if (clientsList?.length > 0) {
        setSelectedClient({
          ...selectedClient,
          type: "select",
          value: clientsList[0],
        });
      }
      return true;
    }
    setSelectedClient({
      ...selectedClient,
      type: "search",
      value: e.value,
    });
    if (e.syntheticEvent?.type === "change" && e.value.length >= 3) {
      debouncedAutoCompleteClients(e.value);
    }
    if (e.value.length === 0) {
      setUserDetails(null);
    }
    if (e.syntheticEvent?.type === "click") {
      setSelectedClient({
        ...selectedClient,
        type: "select",
        value: e.value,
      });
      const result = e?.value;
      const res = {
        Email: result?.Email || null,
        FirstName: result?.FirstName || null,
        LastName: result?.LastName || null,
        Phone: result?.Phone || null,
        FullName: `${result?.FirstName} ${result?.LastName}`,
        UserMemberId: result?.UserMemberId || 0,
        PrimaryMemberId: result?.MainMemberId,
        UserId: result?.UserId,
        Status: result?.Status,
        Photo: result?.Photo,
      };
      if (res?.Email) {
        if (!propValues?.staffPage && !CheckUserMemberStatus(res)) {
          props?.handleNotificationMessage("Cannot Perform this action until Charge Back (Credit card)/Return (ACH) transaction is paid, Please contact administrator.", "error");
          return;
        } else if (propValues?.staffPage && !CheckUserMemberStatus(res)) {
          props?.handleNotificationMessage("Cannot Perform this action until Charge Back (Credit card)/Return (ACH) dispute is resolved. Please contact administrator.", "error");
          return;
        }
        setUserDetails(res);
        props?.handleClientSelection(true);
        if (propValues?.showPackageSelection) {
          setShowPackageDialog(propValues?.showPackageSelection)
        }
      }
    }
  };

  async function autoCompleteClients(str: string) {
    const req = {
      SearchKeyword: str,
    };
    setClientSearchLoading(true);
    const clientservice = new ClassService();
    const result = await clientservice.searchClient(req);
    setClientSearchLoading(false);
    setClientsList(result);
  }

  const debouncedAutoCompleteClients = useRef(
    debounce((value: string) => autoCompleteClients(value), 400)
  ).current;

  async function fetchClientData(userMemberId: any) {
    const clientservice = new TenantService();
    const result = await clientservice.getClient(userMemberId);
    if (result) {
      const clientIntialValues = {
        FirstName: result?.User?.FirstName,
        LastName: result?.User?.LastName,
        PhoneNumber: result?.User?.Address?.Phone,
        Email: result?.User?.Email,
      };
      const clientSubmitDetails = {
        Email: result?.User?.Email,
        FirstName: result?.User?.FirstName,
        LastName: result?.User?.LastName,
        Photo: result?.User?.Photo,
        PrimaryMemberId: result?.UserMemberId,
        FullName: `${result?.User.FirstName} ${result?.User.LastName}`,
        UserId: result?.UserId,
        UserMemberId: result?.UserMemberId,
        MemberNumber: result?.MemberNumber,
        Status: result?.Status,
        Phone: result?.User?.Address?.Phone,
      };
      setUserDetails(clientSubmitDetails);
      setClientFormValues(clientIntialValues);
      setselectedradioValue("pick");
      const addedClient = {
        Email: result?.User?.Email,
        MemberNumber: result?.User?.MemberNumber,
        FullName: `${result?.User?.FirstName} ${result?.User?.LastName}`,
        MainMemberId: result?.User?.UserMemberId,
        UserId: result?.User?.UserId,
        UserMemberId: result?.User?.UserMemberId,
        FirstName: result?.User?.FirstName,
        LastName: result?.User?.LastName,
        Photo: result?.User?.Photo,
        Phone: result?.User?.Address?.Phone,
      };
      setSelectedClient({
        ...selectedClient,
        type: "select",
        value: addedClient,
      });
      props?.handleClientSelection(true);
      if (propValues?.showPackageSelection) {
        setShowPackageDialog(propValues?.showPackageSelection)
      }
    }
  }

  const fetchExistingSchedules = async (value: any) => {
    const maximum = moment().format('YYYY-MM-DDTHH:mm:ss');
    let schedulesReq: any = {
      FromDate: maximum,
      CanSearchThirdPartyCalendar: false,
      Providers: [],
      UserMemberId: userDetails?.UserMemberId,
      ExcludeUnavailable: true,
      Status: 1,
      ShowPaidOnly: true,
    };

    if (mainStateRef?.current?.Package) {
      schedulesReq.EntityId = mainStateRef.current.Package.PackageInstanceId;
    } else {
      schedulesReq.PrivateServiceId = mainStateRef?.current?.Appointment?.PrivateServiceId ? mainStateRef?.current?.Appointment?.PrivateServiceId : undefined
    }

    const tenantService = new TenantService();
    const schedulesRes = await tenantService.getUpcomingAppointments(schedulesReq);

    if (schedulesRes?.Result?.length > 0) {
      const filtered = schedulesRes.Result.filter((appointment: any) => appointment.IsPaid !== false);
      let filteredSchedules = filtered || [];


      if (mainStateRef?.current?.Package && mainStateRef?.current?.selectedPackage?.SessionType === SessionTypeEnum.PRESET) {
        filteredSchedules = filteredSchedules.filter(
          (item: any) =>
            item.PackageInstanceId === mainStateRef.current.Package.PackageInstanceId
        );
      } else if (mainStateRef?.current?.Package && mainStateRef?.current?.selectedPackage?.SessionType !== SessionTypeEnum.PRESET) {
        filteredSchedules = filteredSchedules.filter(
          (item: any) =>
            item.PackageInstanceId === mainStateRef.current.Package.PackageInstanceId &&
            item.ServiceId === mainStateRef?.current?.Appointment?.PrivateServiceId
        );
      }

      setSchedulerCalendarState({
        ...schedulerCalendarStateRef?.current,
        availExistingSch: filteredSchedules,
      });
    }

  }


  const handleRadioButtonChange = () => {
    setselectedradioValue("new");
  };

  const handleChange = async (name: ScheduleAppointmentkeys, value: any) => {
    if (!value) {
      switch (name) {
        case "Appointment":
          setMainState({ ...mainStateRef?.current, [name]: value, Provider: null, selectedSchedules: [] });
          setSchedulerCalendarState({
            ...initialSchedulerCalendarState,
            selectedSalesPerson: schedulerCalendarStateRef.current.selectedSalesPerson,
            paymentProfile: schedulerCalendarStateRef.current.paymentProfile,
          });
          setMultipleSlots([]);
          if (mainStateRef?.current?.Package) {
            await fetchServices(0)
          }
          break;
        case "Provider":
          setMainState({ ...mainStateRef?.current, [name]: value });
          break;
        // case "ShowPurchasedPackages":
        //   setMainState({ ...mainStateRef?.current, [name]: value,Package:null });
        //   setSchedulerCalendarState(initialSchedulerCalendarState);
        //   await fetchServices(0)
        //   break;
        case "Package":
          if (propValues?.showPackageSelection) {
            handleChangePackage()
            await fetchServices(0)
          } else {
            const filteredPackages = mainStateRef?.current?.AvailablePackages?.filter((obj: any) =>
              obj.ClassIds.includes(mainStateRef?.current?.Appointment?.PrivateServiceId)
            );
            setMultipleSlots([]);
            setMainState({
              ...mainStateRef?.current, [name]: value,
              originalPackage: value, ShowPurchasedPackages: true, Provider: null, Appointment: null, minDate: oneYearAgo
            });
            setSchedulerCalendarState({
              ...initialSchedulerCalendarState,
              selectedSalesPerson: schedulerCalendarStateRef.current.selectedSalesPerson,
              paymentProfile: schedulerCalendarStateRef.current.paymentProfile,
              taxes: schedulerCalendarStateRef.current.taxes,
              pickPackage: {
                showDialog: filteredPackages.length > 1 ? true : false,
                selectedAppt: mainState.Appointment,
              }
            });
          }
          await fetchServices(0)
          break;
        case "AddPackage":
          setMainState({ ...mainStateRef?.current, [name]: value, PurchasePackage: null });
          break;
        default:
          setMainState({ ...mainStateRef?.current, [name]: value });
          break;
      }
    } else {
      if (name === "Provider") {
        if (mainStateRef?.current?.Provider !== value) {
          onProviderSelected(value);
          handleChangeAppt(value, "provider");
        } else if (mainStateRef?.current?.Provider === value && !signOffDialog) {
          setsignOffDialog(true);
        }

        //  setsignOffDialog (true);
      } else if (name === "Appointment") {
        setMainState({ ...mainStateRef?.current, [name]: value });
        // if(mainStateRef?.current?.Package?.SchedulesToUse === 0){
        if (!mainStateRef?.current?.Package && !propValues?.Complimentary) {
          fetchExistingSchedules(value);
        }
        //}
        await fetchTrainers()
        await fetchCoupons()

      } else if (name === "AddPackage") {
        setMainState({ ...initialMainState, [name]: value, AvailablePackages: mainStateRef?.current?.AvailablePackages, appointmentList: mainStateRef?.current?.appointmentList });
        setSchedulerCalendarState(initialSchedulerCalendarState);
      } else if (name === "Package") {
        setMainState({ ...mainStateRef?.current, [name]: value, originalPackage: value });
        setSchedulerCalendarState(initialSchedulerCalendarState);
        //await fetchServices(0)
      } else if (name === "ShowPurchasedPackages") {
        setMainState({ ...mainStateRef?.current, [name]: value, AddPackage: false });
      } else {
        setMainState({ ...mainStateRef?.current, [name]: value });
      }
    }
  };


  const handleChangePackage = () => {
    setShowPackageDialog(true)
    setStartSessionSignOff({
      isGroup: false,
      value: false
    })
    setPackageSelection({
      dataItem: null,
      SelectedBillingPeriod: null
    })
    setShowOnlySignOffAppts(false)
  }


  const onAppointmentSelect = (dataItem: any) => {
    if (!mainStateRef?.current?.Package && dataItem?.PackageInstanceId > 0) {
      const selectedPackage = mainStateRef?.current.AvailablePackages?.find((x: any) => x.PackageInstanceId === dataItem.PackageInstanceId);
      setMainState({
        ...mainStateRef?.current,
        Appointment: { ...dataItem, ProratedRate: dataItem?.Rate },
        Package: selectedPackage,
        originalPackage: selectedPackage,
        minDate: oneYearAgo,
        // minDate: selectedPackage?.SessionType === SessionTypeEnum.PRESET ? oneYearAgo : new Date(selectedPackage?.ExpireFrom),
      });
      fetchPackageSummary();
      fetchTrainers();
      fetchCoupons();
      setSchedulerCalendarState({
        ...schedulerCalendarStateRef?.current,
        IsTaxSkipped:
          mainStateRef?.current?.Appointment?.CanCollectTaxes &&
            checkHasTaxEnable()
            ? false
            : true,
      });
    }

    else if (!mainStateRef?.current?.Package && (mainStateRef?.current?.PackageClassIds[dataItem?.PrivateServiceId] && mainStateRef?.current?.ShowPurchasedPackages) && (dataItem?.PrivateServiceType ===
      PrivateServiceTypeEnum.PrivatePaid ||
      dataItem?.PrivateServiceType ===
      PrivateServiceTypeEnum.SemiPrivatePaid)) {
      const filteredPackages = mainStateRef?.current?.AvailablePackages?.filter((obj: any) =>
        obj.ClassIds.includes(dataItem?.PrivateServiceId)
      );
      if (filteredPackages.length === 1) {
        setSchedulerCalendarState({
          ...schedulerCalendarStateRef?.current,
          pickPackage: {
            showDialog: false,
            selectedAppt: dataItem
          }
        })
        handlePackageSelection(filteredPackages[0]);
      } else {
        setSchedulerCalendarState({
          ...schedulerCalendarStateRef?.current,
          pickPackage: {
            showDialog: true,
            selectedAppt: dataItem
          }
        })
      }

    } else {
      handleChange("Appointment", dataItem)
    }
  }


  const handleApptFilter = (val: any) => {
    setMainState({
      ...mainStateRef?.current,
      SelectedApptFilter: val
    })
    fetchServices(0)
  }

  const requestIfNeeded = () => {
    const nextPage = mainStateRef.current?.appointmentList.pageNumber + 1;
    const maxPageNum = mainStateRef.current?.appointmentList.maxPageNumber;

    if (nextPage <= maxPageNum) {
      return {
        status: true,
        page: nextPage,
      };
    } else {
      return {
        status: false,
        page: nextPage,
      };
    }
  };

  const scrollHandler = async (event: any) => {
    const e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      const check = await requestIfNeeded();
      if (check.status) {
        const moreData = await fetchServices(check.page);
        if (moreData.length > 0) {
          const test = mainStateRef.current?.appointmentList.appointmentData.concat(moreData);

          setMainState({
            ...mainStateRef.current,
            appointmentList: {
              ...mainStateRef.current?.appointmentList,
              appointmentData: test,
              pageNumber: check.page,
            }
          })
        }
      }
    }
  };

  const handleNavigateBack = () => {
    if (propValues?.UpdateAppointment) {
      if (propValues?.staffPage) {
        history.push(`/member/${propValues?.uuid}/${propValues?.clientId}`);
      } else {
        history.push(`/summary`);
      }
    } else {
      if (propValues?.staffPage) {
        history.push(`/member/${propValues?.uuid}/${propValues?.clientId}`, {
          purchaseoneonone: true,
        });
      } else if (propValues?.quicklinks) {
        history.push(`/app/quicklinks/${propValues?.uuid}`);
      } else {
        history.push(`/summary`, { purchaseoneonone: true });
      }
    }
  };


  const handleRemoveCoupon = (index: number) => {
    const array = [...schedulerCalendarStateRef?.current?.appliedCoupons];
    if (index !== -1) {
      array.splice(index, 1);
    }
    handleOneonOneCoupon(array);
  };

  const handleChangeCoupon = (val: any) => {
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      coupon: val,
    });
  };

  const handleClearAllStates = () => {
    setMultipleSlots([]);
    setSchedulerCalendarState({
      ...initialSchedulerCalendarState,
      selectedSalesPerson: schedulerCalendarStateRef.current.selectedSalesPerson,
    });
    setselectedradioValue("");
    setMainState({ ...initialMainState, salesPersons: mainStateRef.current.salesPersons });
    setUserDetails(null);
    setSelectedClient({
      type: "search",
      value: "",
    });
    setStartSessionSignOff({
      isGroup: false,
      value: false
    })
    setPackageSelection({
      dataItem: null,
      SelectedBillingPeriod: null
    })
    setShowOnlySignOffAppts(false)
    props?.handleClientSelection(false);
  }

  const handleChangeClient = () => {
    handleClearAllStates();
  };

  const onProviderSelected = async (e: any) => {

    setMainState({
      ...mainStateRef?.current,
      Provider: e,
      providerCounter: mainStateRef?.current?.providerCounter + 1,
    });

  };

  function validateAndAddSlot(selectedSlot: any, packageData: any) {
    const selectedDate = new Date(selectedSlot);
    const billingPeriods = packageData.BillingPeriods;

    if (
      packageData.SessionType === SessionTypeEnum.LIMITED
    ) {
      for (let i = 0; i < billingPeriods.length; i++) {
        const expireFrom = new Date(billingPeriods[i].ExpireFrom);
        const expireOn = new Date(billingPeriods[i].ExpireOn);
        //expireOn.setDate(expireOn.getDate() + 1);
        expireOn.setHours(23);
        expireOn.setMinutes(59);
        expireOn.setSeconds(0);

        if (selectedDate >= expireFrom && selectedDate <= expireOn || packageData.SessionType === SessionTypeEnum.PRESET) {
          if (!schedulerCalendarStateRef?.current?.updateAppointment) {
            // Slot falls within the billing period
            if (!billingPeriods[i].AvailedSlots) {
              billingPeriods[i].AvailedSlots = 1;
              if (
                billingPeriods[i].AvailedSlots >
                billingPeriods[i].SchedulesToUse
              ) {
                billingPeriods[i].AvailedSlots--;
                throw new Error(
                  "Availed all slots in the given particular time period"
                );
              }
            } else {
              billingPeriods[i].AvailedSlots++;

              // Check if AvailedSlots is greater than SchedulesToUse
              if (
                billingPeriods[i].AvailedSlots >
                billingPeriods[i].SchedulesToUse
              ) {
                billingPeriods[i].AvailedSlots--;
                throw new Error(
                  "Availed all slots in the given particular time period"
                );
              }
            }
          }
          return true; // Slot validated and added
        }
      }
      // If no billing period matched the selected slot, throw an exception or handle as needed
      throw new Error("Selected slot does not fall within any billing period");
    } else if (
      packageData.SessionType === SessionTypeEnum.UNLIMITED &&
      packageData.ExpireOn != null
    ) {
      const expireFromUnlimited = new Date(packageData.ExpireFrom);
      const expireOnUnlimited = new Date(packageData.ExpireOn);
      expireOnUnlimited.setDate(expireOnUnlimited.getDate() + 1);

      if (
        selectedDate >= expireFromUnlimited &&
        selectedDate <= expireOnUnlimited
      ) {
        return true;
      } else {
        throw new Error(
          "Selected slot does not fall within the billing period"
        );
      }
    }
  }


  const validateIndividualAppointmentsOverlap = async (req: any) => {
    let duration = mainStateRef?.current?.Appointment?.Duration;
    if (typeof duration !== 'number') {
      duration = mainStateRef?.current?.Appointment?.DurationInMinutes;
    }
    const selectedDate = req.date;
    const selectedTime = req.time;

    const initialDateTime = moment(`${selectedDate} ${selectedTime}`, "YYYY-MM-DD h:mm A");
    const StartDateTime = initialDateTime.format("YYYY-MM-DD HH:mm:ss")
    const endDateTime = initialDateTime.clone().add(duration, "minutes");
    const EndDateTime = endDateTime.format("YYYY-MM-DD HH:mm:ss")

    let scheduleId = 0;
    if (
      schedulerCalendarStateRef?.current?.updateAppointment &&
      schedulerCalendarStateRef?.current?.updateAppointmentData
    ) {
      scheduleId =
        schedulerCalendarStateRef?.current?.updateAppointmentData.ScheduleId;
    }

    const selectedValue = {
      StartDateTime: StartDateTime,
      StartDate: selectedDate,
      EndDateTime: EndDateTime,
      ScheduleId: scheduleId,
      PackageInstanceId: mainStateRef?.current?.Package
        ? mainStateRef?.current?.Package.PackageInstanceId
        : 0,
      IsComplimentary: propValues?.Complimentary ? true : false,
    };
    const tzvalueName =
      schedulerCalendarStateRef?.current?.tz.timezoneValue?.value ??
      schedulerCalendarStateRef?.current?.tz.timezoneValue;
    // const tzlabelVal = allTimeZones[tzvalueName];
    const tzlabelVal = getUserTimeZoneLong(tzvalueName);

    const reqData = {
      ScheduleId: scheduleId,
      Schedules: [selectedValue],
      UserAssociateId: mainStateRef?.current?.Provider?.AssociateId,
      UserId: userDetailsRef?.current?.UserId,
      PrivateServiceId: mainStateRef?.current?.Appointment?.PrivateServiceId,
      TrainerUserId: mainStateRef?.current?.Provider?.UserId,
      IsThirdPartyCalendarEnabled:
        mainStateRef?.current?.Provider?.IsThirdPartyCalendarEnabled,
      UserTimeZoneId: tzlabelVal,
    };

    // const validateAppointments = new TenantService();
    // const result = await validateAppointments.validateAppointmentsOverlap(
    //   reqData
    // );
    // if (result) {
    //   return result;
    // }
  };


  const handleAddAppointment = async (isAddMore: any = false) => {
    // if (
    //   selectedAppt.provider === undefined
    // ) {
    //   props?.handleNotificationMessage("Please Select the Staff", "error");
    //   return;
    // }
    if (mainStateRef?.current?.Package && mainStateRef?.current?.Package?.SessionType !== SessionTypeEnum.PRESET) {
      if (!selectedAppt?.BillingPeriodFrom) {
        props?.handleNotificationMessage("Please Select a Time Period", "error");
        return;
      }

    }

    let apptInfo = { ...selectedAppt };
    let apptsList = multipleSlots?.length > 0 ? [...multipleSlots] : [];

    if (
      mainStateRef?.current?.Appointment?.PrivateServiceType === PrivateServiceTypeEnum.PrivateUnpaid ||
      mainStateRef?.current?.Appointment?.PrivateServiceType === PrivateServiceTypeEnum.SemiPrivateUnpaid || propValues?.Complimentary
    ) {
      apptsList = [];
    }

    // if(!complimentary){
    //   if (availOriginalSchedules.length > 0) {
    //     if (
    //       availOriginalSchedules.length === apptsList.length &&
    //       !packag
    //     ) {
    //       const errorMsg = `You are adding more than existing (${availOriginalSchedules?.length}) schedules`;
    //       props?.handleNotificationMessage(errorMsg, "error");
    //       return true;
    //     }
    //   }

    //   if (availOriginalSchedules.length > 0 && !packag) {
    //     if (!selectedAppt?.PreviousSchedule) {
    //       props?.handleNotificationMessage(
    //         "Please pick the existing schedule to add appointment",
    //         "error"
    //       );
    //       return;
    //     }
    //   }

    //   if(packag && packag?.SessionType === 3 && selectedPackage?.Scheduled === selectedPackage?.UnusedPaidSchedules){
    //     if (!selectedAppt?.PreviousSchedule) {
    //       props?.handleNotificationMessage(
    //         "Please pick the existing schedule to add appointment",
    //         "error"
    //       );
    //       return;
    //     }
    //   }

    //   if(packag && packag?.SessionType === 3 && (selectedPackage?.Scheduled === selectedPackage?.UnusedPaidSchedules - multipleSlots.length || showExpresetAppt)){
    //     if (!selectedAppt?.PreviousSchedule) {
    //       props?.handleNotificationMessage(
    //         "Please pick the existing schedule to add appointment",
    //         "error"
    //       );
    //       return;
    //     }
    //   }
    // }


    apptsList.push(apptInfo);
    let transformedData;

    const date = moment(apptInfo.signOffDate).format("YYYY-MM-DD");
    const time = moment(apptInfo.signOffDate).format("h:mm A");

    let scheduledData = {
      date: date,
      time: time,
      IsAvailable: true,
    };

    let datetimeA = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm").format()
    let packageCopy = mainStateRef?.current?.Package;

    if (mainStateRef?.current?.Package && !propValues?.Complimentary) {
      const currentDate = new Date();
      const newDate = new Date(currentDate);
      newDate.setMinutes(currentDate.getMinutes() + 3);
      const selectedDate = new Date(datetimeA);
      const expireFrom = new Date(mainStateRef?.current?.Package.ExpireFrom);
      if (selectedDate > newDate) {
        props?.handleNotificationMessage("Selected Slot should be less than the current date", "error");
        return;
      }
      // if (mainStateRef?.current?.Package?.SessionType !== SessionTypeEnum.PRESET) {
      //   if (selectedDate < expireFrom) {
      //     props?.handleNotificationMessage("Selected Slot should be falls within the package purchased date", "error");
      //     return;
      //   }
      // }
    }

    if (mainStateRef?.current?.Package && propValues?.Complimentary) {
      const currentDate = new Date();
      const newDate = new Date(currentDate);
      newDate.setMinutes(currentDate.getMinutes() + 3);
      const selectedDate = new Date(datetimeA);
      const expireFrom = new Date(mainStateRef?.current?.Package.ExpireFrom);

      if (selectedDate > newDate) {
        props?.handleNotificationMessage("Selected Slot should be less than the current date", "error");
        return;
      }
      // if (mainStateRef?.current?.Package?.SessionType !== SessionTypeEnum.PRESET) {
      //   if (selectedDate < expireFrom) {
      //     props?.handleNotificationMessage("Selected Slot should be falls within the package purchased date", "error");
      //     return;
      //   }
      // }

    }


    if (mainStateRef?.current?.selectedPackage?.SessionType === SessionTypeEnum.PRESET && mainStateRef?.current?.selectedPackage?.UnusedPaidSchedules === 0 && !propValues?.Complimentary) {
      props?.handleNotificationMessage("Availed all paid schedules in the package", "error");
      return;
    }

    if (((multipleSlots.length + 1) > mainStateRef?.current?.selectedPackage?.UnusedPaidSchedules) && mainStateRef?.current?.selectedPackage?.SessionType === SessionTypeEnum.PRESET && !propValues?.Complimentary) {
      props?.handleNotificationMessage("Availed all paid schedules in the package", "error");
      return;
    }


    if (mainStateRef?.current?.selectedPackage?.SessionType === SessionTypeEnum.PRESET && schedulerCalendarStateRef?.current?.availExistingSch.length === 0 && (multipleSlots.length) + mainStateRef?.current?.selectedPackage?.Scheduled - mainStateRef?.current?.selectedPackage?.UnusedPaidSchedules === 0 && !propValues?.Complimentary) {
      props?.handleNotificationMessage("Availed all paid schedules in the package", "error");
      return;
    }



    //For the Package is selected and needs to do the validation for the Slots
    if (mainStateRef?.current?.Package && !selectedAppt?.PreviousSchedule && !propValues?.Complimentary
    ) {
      try {

        // validateAndAddSlot(datetimeA, packageCopy);
      } catch (error: any) {
        let errorMessage = error.message;

        props?.handleNotificationMessage(errorMessage, "error");
        setsignOffDialog(false);
        return; // Stop execution if an error occurs
      }
    }

    //setvalidateBtnLoading(true);
    if (apptsList.length > 0) {
      // Check if apptsList is not empty
      transformedData = apptsList.map((appt) => ({
        date: appt.date ? appt.date : moment(apptInfo.signOffDate).format("YYYY-MM-DD"),
        time: appt.time ? appt.time : new Date(appt.signOffDate).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        trainerId: appt.provider.AssociateId,
        provider: appt.provider,
        notes: appt.notes,
        PrivateServiceInstanceId: appt?.PreviousSchedule?.EntityId || 0,
        ScheduleId: appt?.PreviousSchedule?.ScheduleId || appt?.ScheduleId,
        BillingPeriodFrom: appt?.BillingPeriodFrom?.ExpireFrom || appt?.BillingPeriodFrom,
        BillingPeriodTo: appt?.BillingPeriodFrom?.ExpireOn || appt?.BillingPeriodTo
      }));

      const result = await validateIndividualAppointmentsOverlap(scheduledData);
      apptInfo.IsAvailable = result?.[0]?.IsAvailable;
      apptInfo.Description = result?.[0]?.Description;

      setMultipleSlots(transformedData);

    }
    let totalCost = apptsList.length * mainStateRef?.current?.Appointment.Rate;


    const PackageBillingPeriods = packageCopy?.BillingPeriods?.map(
      (i: any) => {
        if (
          selectedAppt?.BillingPeriodFrom?.ExpireFrom &&
          moment(i?.ExpireFrom)?.format("YYYY-MM-DD") ===
          moment(selectedAppt?.BillingPeriodFrom?.ExpireFrom).format("YYYY-MM-DD")
        ) {
          return { ...i, SchedulesToUse: i?.SchedulesToUse - 1 };
        }
        return { ...i };
      }
    );
    setMainState({
      ...mainStateRef?.current,
      Appointment: {
        ...mainStateRef?.current?.Appointment,
        NumberOfSessions: apptsList.length,
        ProratedRate: mainStateRef?.current?.Appointment?.Rate,
        RateAfterDiscount: mainStateRef?.current?.Appointment?.Rate,
      },
      selectedSchedules: transformedData,
      Package: mainStateRef?.current?.Package ? {
        ...mainStateRef?.current?.Package,
        BillingPeriods: PackageBillingPeriods,
      } : null,
    });
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      calendarKey: schedulerCalendarStateRef?.current?.calendarKey + 1,
      selectedSchItems: transformedData,
      oldSchItems: transformedData,
      packageCostAfterDiscount: totalCost,
    });

    if (!isAddMore) {
      setSelectedAppt({
        ...selectedApptRef?.current,
        PreviousSchedule: undefined,
        notes: "",
        BillingPeriodFrom: null,
        BillingPeriodTo: null
      });
      setsignOffDialog(false);
    } else {
      if (mainStateRef?.current?.Package?.SessionType === SessionTypeEnum.UNLIMITED) {
        setSelectedAppt({
          ...selectedApptRef?.current,
          PreviousSchedule: undefined,
          notes: "",
        });
      } else {
        const findBillingItem = PackageBillingPeriods?.find(
          (i: any) => {
            if (
              selectedAppt?.BillingPeriodFrom?.ExpireFrom &&
              moment(i?.ExpireFrom)?.format("YYYY-MM-DD") ===
              moment(selectedAppt?.BillingPeriodFrom?.ExpireFrom).format("YYYY-MM-DD")
            ) {
              return true
            }
            return false
          }
        );
        if (findBillingItem?.SchedulesToUse === 0) {
          setSelectedAppt({
            ...selectedApptRef?.current,
            PreviousSchedule: undefined,
            notes: "",
            BillingPeriodFrom: null,
            BillingPeriodTo: null
          });
        } else {
          setSelectedAppt({
            ...selectedApptRef?.current,
            PreviousSchedule: undefined,
            notes: "",
            SchedulesToUse: selectedApptRef?.current?.BillingPeriodFrom?.SchedulesToUse - 1
          });
        }
      }
    }

  };
  const handleCloseSessionSignOff = () => {
    setSelectedAppt({
      ...selectedApptRef?.current,
      PreviousSchedule: undefined,
      notes: "",
      BillingPeriodFrom: null,
      BillingPeriodTo: null
    });
  }

  const handleChangeAppt = async (val: any, name: string) => {
    if (name === "PreviousSchedule") {
      if (val?.disabled) {
        return;
      }
      if (selectedAppt?.PreviousSchedule?.ScheduleId === val?.ScheduleId) {
        setSelectedAppt({
          ...selectedApptRef?.current,
          [name]: val,
        });
        //setsignOffDialog(!signOffDialogRef?.current);
      } else {
        const providerDetails = mainState?.providerList.find((item: any) => item.UserId === val?.TrainerUserId);

        setSelectedAppt({
          ...selectedApptRef?.current,
          [name]: val,
          provider: selectedAppt.provider === undefined ? providerDetails : selectedAppt?.provider,
        });
        setsignOffDialog(true);
      }
      setsignOffDialog(true);

    } else {
      setSelectedAppt({
        ...selectedApptRef?.current,
        [name]: val,
      });
    }
    if (name === "provider" && mainState.Provider !== val) {
      setsignOffDialog(!signOffDialogRef?.current);
    }

  };

  const onSubmit = () => {
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      btnLoading: true,
    });
    if (
      !userDetailsRef?.current ||
      userDetailsRef?.current?.UserMemberId === 0 ||
      !userDetailsRef?.current?.UserMemberId
    ) {
      props?.handleNotificationMessage("Please Select the Client", "error");
      setSchedulerCalendarState({
        ...schedulerCalendarStateRef?.current,
        btnLoading: false,
      });
      return;
    }

    const purchaseAmt =
      mainStateRef?.current.Appointment?.ProratedRate *
      mainStateRef?.current.Appointment?.NumberOfSessions;
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      btnLoading: false,
      packageCostAfterDiscount: purchaseAmt,
      checkout: true,
      disable: false,
      termsandConditions: true,
      termsandConditionsDialog: false,
    });

    if (mainStateRef?.current?.Appointment?.CanCollectTaxes) {
      handleTaxCalculation(purchaseAmt);
    }
    if (schedulerCalendarStateRef?.current?.appliedCoupons.length > 0) {
      handleOneonOneCoupon(schedulerCalendarStateRef?.current?.appliedCoupons);
    }
  };

  const handleOneonOneCoupon = async (
    value = schedulerCalendarStateRef?.current?.appliedCoupons,
    numberofSessions = 0
  ) => {
    let final_coup_data: any[] = [];
    let NoofSessions = mainStateRef?.current?.Appointment?.NumberOfSessions;
    if (numberofSessions !== 0) {
      NoofSessions = numberofSessions;
    }
    const couponsList = value;
    const couponFixed = couponsList.filter(
      (res: any) => res?.TypeDescription === "Fixed"
    );
    if (couponFixed.length > 0) {
      final_coup_data.push(couponFixed[couponFixed.length - 1]);
    }
    const couponPercentage = couponsList.filter(
      (res: any) => res?.TypeDescription === "Percentage"
    );
    if (couponPercentage.length > 0) {
      final_coup_data.push(couponPercentage[couponPercentage.length - 1]);
    }
    let offerAmt = 0;
    let cost = NoofSessions * mainStateRef?.current?.Appointment?.ProratedRate;
    if (numberofSessions !== 0) {
      cost = numberofSessions * mainStateRef?.current?.Appointment?.ProratedRate;
    }
    let downpayment = NoofSessions * mainStateRef?.current?.Appointment?.ProratedRate;
    if (numberofSessions !== 0) {
      downpayment = numberofSessions * mainStateRef?.current?.Appointment?.ProratedRate;
    }
    if (final_coup_data.length > 0) {
      final_coup_data = final_coup_data.map((item: any) => {
        if (item.TypeDescription === "Fixed") {
          const coupAmt = item.Amount;
          cost = cost <= coupAmt ? 0 : cost - coupAmt;
          offerAmt += coupAmt;
          return { ...item, couponAmt: coupAmt };
        } else if (item.TypeDescription === "Percentage") {
          const coupvalue = cost * (item.Amount / 100);
          const coupAmount = parseFloat(coupvalue.toFixed(2));
          cost = item.Amount === 100 ? 0 : cost - coupAmount;
          offerAmt += coupAmount;
          return { ...item, couponAmt: coupAmount };
        }
      });
      downpayment = downpayment <= offerAmt ? 0 : downpayment - offerAmt;
      let packagecostAfterDiscount = 0;
      const rateAfterDiscount = cost === 0 ? 0 : cost / NoofSessions;
      packagecostAfterDiscount = rateAfterDiscount * NoofSessions;

      setSchedulerCalendarState({
        ...schedulerCalendarStateRef?.current,
        couponAmount: offerAmt,
        packageCostAfterDiscount: packagecostAfterDiscount,
        appliedCoupons: final_coup_data,
      });

      if (mainStateRef?.current?.Appointment?.CanCollectTaxes) {
        handleTaxCalculation(
          downpayment,
          schedulerCalendarStateRef?.current?.taxes
        );
      }
    } else {
      if (
        mainStateRef?.current?.Appointment?.CanCollectTaxes &&
        checkHasTaxEnable()
      ) {
        handleTaxCalculation(downpayment);
      }
      setSchedulerCalendarState({
        ...schedulerCalendarStateRef?.current,
        IsTaxSkipped:
          mainStateRef?.current?.Appointment?.CanCollectTaxes &&
            checkHasTaxEnable()
            ? false
            : schedulerCalendarStateRef?.current?.IsTaxSkipped,
        packageCostAfterDiscount: downpayment,
        appliedCoupons: final_coup_data,
      });
    }
  };

  const handleChangeSavedCard = (val: any, dataItem: any = null) => {
    let paymentDetails: any = {
      CustomerProfileID: val?.CustomerProfileID,
      PaymentProfileID: val?.PaymentProfileID,
      PaymentGatewayPaymentProfileID: val?.PaymentGatewayPaymentProfileID,
      MaskedCCNumber: val?.MaskedCCNumber,
      CardTypeId: val?.CardTypeId,
      CardDescription: val?.CardDescription,
      BankAccountType: val?.BankAccountType,
      Credit: val?.Credit,
    };
    if (dataItem) {
      paymentDetails = {
        ...paymentDetails, Reference: dataItem?.Reference,
        ReferrenceDocument: dataItem?.ReferrenceDocument
      }
    }

    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      selectedPaymentProfile: {
        selectedPayment: val,
        AlternatePaymentProfileId: null,
      },
      paymentSubmitProfile: paymentDetails,
    });
  };

  const handleSalesPersonSelection = (dataItem: any) => {
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      selectedSalesPerson: dataItem,
    });
  };

  const handleIsTaxSkipped = () => {
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      IsTaxSkipped: !schedulerCalendarStateRef?.current?.IsTaxSkipped,
    });
  };

  const handleSearch = (val: any) => {
    setMainState({
      ...mainStateRef?.current,
      ApptNameSearch: val
    })
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      fetchServices(0);
    }, 500); // Debounce delay in milliseconds
  }

  const handleTaxCalculation = (
    purchaseAmt: number,
    TaxItems = schedulerCalendarStateRef?.current?.taxes
  ) => {
    const TaxesArray = TaxItems.map((i: any) => {
      const amount = (purchaseAmt * i?.Percentage) / 100;
      const calcAmt = CustomToFixed(amount, 2);
      return {
        TaxId: i?.TaxId,
        TaxName: i?.TaxName,
        Percentage: i?.Percentage,
        Amount: calcAmt,
      };
    });
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
      return acc + currentValue?.Amount;
    }, 0);
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      taxes: TaxesArray,
      TotalTaxAmount: taxAmt,
    });
  };

  const checkoutValidation = (checkTerms = true) => {
    if (
      Object.keys(schedulerCalendarStateRef?.current?.paymentSubmitProfile || {})
        .length === 0 || !schedulerCalendarStateRef?.current?.paymentSubmitProfile?.PaymentGatewayPaymentProfileID
    ) {
      const errorMsg = "Please select/add Payment profile";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    } else {
      let purchaseAmt =
        schedulerCalendarStateRef?.current?.packageCostAfterDiscount +
        schedulerCalendarStateRef?.current?.TotalTaxAmount;
      purchaseAmt = CustomToFixed(purchaseAmt, 2);
      if (
        schedulerCalendarStateRef?.current?.paymentSubmitProfile?.CardTypeId ===
        8 &&
        purchaseAmt >
        schedulerCalendarStateRef?.current?.paymentSubmitProfile?.Credit &&
        !schedulerCalendarStateRef?.current?.selectedPaymentProfile
          ?.AlternatePaymentProfileId
      ) {
        let defaultPaymentProfile = null;
        const existedPP =
          schedulerCalendarStateRef?.current?.paymentProfile?.filter(
            (i: any) => i?.CardTypeId !== -1 && i.CardTypeId !== 8
          );
        if (existedPP?.length > 0) {
          defaultPaymentProfile = existedPP[0];
        }
        setShowAdditionalPayment({
          dialog: true,
          selectedPaymentProfile: defaultPaymentProfile,
        });
        setSchedulerCalendarState({
          ...schedulerCalendarStateRef?.current,
          btnLoading: false,
        });
        return true;
      }
    }
    if (checkTerms && !schedulerCalendarStateRef?.current?.termsandConditions) {
      const errorMsg = "Please check the Terms & Conditions";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }

    return false;
  };
  const handleTermsConditions = (val: boolean) => {
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      termsandConditions: val,
      termsandConditionsDialog: false,
    });
  };

  const handleTermsConditionDialog = (val: any) => {
    const check = checkoutValidation(false);
    if (check) {
      return;
    }
    if (val === true || val === false) {
      setSchedulerCalendarState({
        ...schedulerCalendarStateRef?.current,
        termsandConditions: val,
      });
    } else {
      setSchedulerCalendarState({
        ...schedulerCalendarStateRef?.current,
        termsandConditionsDialog:
          !schedulerCalendarStateRef?.current?.termsandConditionsDialog,
      });
    }
  };

  const handleChangeAdditionalPaymentProfile = async (value: any) => {
    setShowAdditionalPayment({
      ...showAdditionalPayment,
      selectedPaymentProfile: value,
    });
  };

  const handleAddAdditionalPayment = () => {
    if (
      !showAdditionalPayment?.selectedPaymentProfile
        ?.PaymentGatewayPaymentProfileID
    ) {
      const errorMsg = "Please select/add Payment profile";
      props?.handleNotificationMessage(errorMsg, "error");
      return;
    }
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      selectedPaymentProfile: {
        ...schedulerCalendarStateRef?.current?.selectedPaymentProfile,
        AlternatePaymentProfileId:
          showAdditionalPayment?.selectedPaymentProfile
            ?.PaymentGatewayPaymentProfileID,
      },
    });
    setShowAdditionalPayment(initialAdditionalPayment);
  };


  const handleCheckOut = () => {
    if (
      !schedulerCalendarStateRef?.current?.paymentSubmitProfile ||
      Object.keys(schedulerCalendarStateRef?.current?.paymentSubmitProfile)
        .length === 0
    ) {
      props?.handleNotificationMessage(
        "Please Select the Payment Profile",
        "error"
      );

      return;
    }
    if (propValues?.staffPage && !schedulerCalendarStateRef?.current?.selectedSalesPerson) {
      const errorMsg = "Please select Sales Person";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    if (!schedulerCalendarStateRef?.current?.termsandConditions) {
      const errorMsg = "Please check the Terms & Conditions";
      props?.handleNotificationMessage(errorMsg, "error");

      return true;
    }
    createAgreementObj(mainStateRef?.current?.Appointment);
  };

  const createAgreementObj = async (serviceDet: any) => {
    let payload: any = [];
    const tzvalueName =
      schedulerCalendarStateRef?.current?.tz.timezoneValue?.value ??
      schedulerCalendarStateRef?.current?.tz.timezoneValue;
    // const tzlabelVal = allTimeZones[tzvalueName];



    const tzlabelVal = getUserTimeZoneLong(tzvalueName);

    schedulerCalendarStateRef?.current?.selectedSchItems.forEach(
      (slot: any, index: number) => {
        let duration =
          mainStateRef?.current?.Appointment?.Duration;
        if (typeof duration !== 'number') {
          duration = mainStateRef?.current?.Appointment?.DurationInMinutes;
        }
        const selectedDate = slot.date;
        const selectedTime = slot.time;

        const initialDateTime = moment(`${selectedDate} ${selectedTime}`, "YYYY-MM-DD h:mm A");
        const StartDateTime = initialDateTime.format("YYYY-MM-DD HH:mm:ss")
        const endDateTime = initialDateTime.clone().add(duration, "minutes");
        const EndDateTime = endDateTime.format("YYYY-MM-DD HH:mm:ss")

        const req: any = {
          FromTime: StartDateTime,
          ToTime: EndDateTime,
          ScheduleId: 0,
          TrainerId: slot.trainerId,
          Notes: slot.notes,
          PrivateServiceInstanceId: slot.PrivateServiceInstanceId,
        };

        if (mainStateRef?.current?.Package) {
          req.PackageInstanceId =
            mainStateRef?.current?.Package?.PackageInstanceId;

          if (
            mainStateRef?.current?.Package?.SessionType ===
            SessionTypeEnum.PRESET
          ) {
            req.BillingPeriodFrom = mainStateRef?.current?.Package?.ExpireFrom;
          } else {
            req.BillingPeriodFrom =
              slot?.BillingPeriodFrom || slot?.BillingPeriodFrom?.ExpireFrom;
          }
        }
        payload.push(req);
      }
    );

    const AttributeValues =
      mainStateRef?.current?.Appointment?.Attributes &&
        typeof mainStateRef?.current?.Appointment?.Attributes === "string"
        ? JSON.parse(mainStateRef?.current?.Appointment?.Attributes)
        : mainStateRef?.current?.Appointment?.Attributes;

    let purchaseAmt =
      schedulerCalendarStateRef?.current?.packageCostAfterDiscount +
      schedulerCalendarStateRef?.current?.TotalTaxAmount;

    let buildagreement: any = {
      AlternatePaymentProfileId: null,
      AmountChargeByAlternatePaymentProfile: null,
      CanCollectTaxes: !schedulerCalendarStateRef?.current?.IsTaxSkipped,
      Coupon: {
        AppliedCoupons: schedulerCalendarStateRef?.current?.appliedCoupons,
      },
      DownPayment: serviceDet?.ProratedRate * serviceDet?.NumberOfSessions,
      IsOnDemandSale: serviceDet?.ProratedRate !== serviceDet.Rate ? true : false,
      PaymentGatewayPaymentProfileId:
        schedulerCalendarStateRef?.current?.paymentSubmitProfile
          .PaymentGatewayPaymentProfileID,
      PromotionId: null,
      SalesPersonId:
        propValues?.staffPage || propValues?.calendarPage || propValues?.quicklinks
          ? schedulerCalendarStateRef?.current?.selectedSalesPerson?.UserId
          : 0,
      Service: {
        Description: serviceDet.Description,
        Duration: serviceDet.DurationInMinutes,
        Name: serviceDet.Name,
        NumberOfSessions: serviceDet.NumberOfSessions,
        PrivateServiceId: serviceDet.PrivateServiceId,
        PrivateServiceType: serviceDet.PrivateServiceType,
        Rate: serviceDet.Rate,
        ProratedRate: serviceDet?.ProratedRate,
      },
      UserMemberId: userDetailsRef?.current?.UserMemberId,
      Appointment: {
        Attribute: AttributeValues,
        IsComplementary: propValues?.Complimentary || false,
        UserTimeZoneId: tzlabelVal,
        Timings: payload,
        IsQuickCheckout: true,
      },
    };

    if (
      schedulerCalendarStateRef?.current?.paymentSubmitProfile?.CardTypeId ===
      8 &&
      purchaseAmt >
      schedulerCalendarStateRef?.current?.paymentSubmitProfile?.Credit
    ) {
      const AlternatePaymentProfileId =
        schedulerCalendarStateRef?.current?.selectedPaymentProfile
          ?.AlternatePaymentProfileId;
      const AmountChargeByAlternatePaymentProfile = CustomToFixed(
        purchaseAmt -
        schedulerCalendarStateRef?.current?.paymentSubmitProfile?.Credit,
        2
      );
      buildagreement.AlternatePaymentProfileId = AlternatePaymentProfileId;
      buildagreement.AmountChargeByAlternatePaymentProfile =
        AmountChargeByAlternatePaymentProfile;
    }

    let paymentAttributes = null;
    if (schedulerCalendarStateRef?.current?.paymentSubmitProfile?.CardTypeId === -2) {
      paymentAttributes = {
        Reference: schedulerCalendarStateRef?.current?.paymentSubmitProfile?.Reference,
        OfflinePaymentDescription: schedulerCalendarStateRef?.current?.paymentSubmitProfile?.MaskedCCNumber,
        ReferrenceDocumentName: schedulerCalendarStateRef?.current?.paymentSubmitProfile?.ReferrenceDocument?.FileName || "",
        ReferrenceDocumentContent: schedulerCalendarStateRef?.current?.paymentSubmitProfile?.ReferrenceDocument?.ImageAsBase64 || "",
      };
    } else if (schedulerCalendarStateRef?.current?.paymentSubmitProfile?.CardTypeId === -1) {
      paymentAttributes = {
        Reference: "",
        OfflinePaymentDescription: "Cash",
        ReferrenceDocumentName: "",
        ReferrenceDocumentContent: "",
      };
    }
    buildagreement = {
      ...buildagreement,
      PaymentAttributes: paymentAttributes
    }

    await handlePayNow(buildagreement);
  };


  const handlePayNow = async (dataItem: any) => {
    let check = checkoutValidation();
    if (check) {
      return;
    }
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      btnLoading: true,
    });
    const { selectedSchItems, updateAppointmentData } =
      schedulerCalendarStateRef?.current;
    try {
      await axiosRequest.post("Appointments/Purchase", dataItem, {
        successCallBack: (response: any) => {
          const successMsg = "Session signoff Purchased Successfully";
          props?.handleNotificationMessage(successMsg, "success");

          const guestPages = iframeGuestPages;
          if (propValues?.calendarPage) {
            setTimeout(() => {
              setSchedulerCalendarState({
                ...schedulerCalendarStateRef?.current,
                btnLoading: false,
              });
              handleGoToOverview();
            }, 1500);
          } else if (propValues?.quicklinks) {
            setTimeout(() => {
              setSchedulerCalendarState({
                ...schedulerCalendarStateRef?.current,
                btnLoading: false,
              });
              history.push(`/member/${propValues?.uuid}/${userDetails.UserMemberId}`);
            }, 1500);
          } else if (!guestPages) {
            setTimeout(() => {
              setSchedulerCalendarState({
                ...schedulerCalendarStateRef?.current,
                btnLoading: false,
              });
              history.push(`/member/${propValues?.uuid}/${userDetails.UserMemberId}`);
            }, 1500);
          } else {
            setTimeout(() => {
              setSchedulerCalendarState({
                ...schedulerCalendarStateRef?.current,
                btnLoading: false,
              });
              history.push(`/member/${propValues?.uuid}/${userDetails.UserMemberId}`);
            }, 1500);
          }
        },
        errorCallBack: (error: any) => {
          setSchedulerCalendarState({
            ...schedulerCalendarStateRef?.current,
            btnLoading: false,
          });
          const errorMsg =
            error?.response?.data?.Messages?.[0] || error?.data?.Messages?.[0] || "Internal Server Error";
          props?.handleNotificationMessage(errorMsg, "error");
        },
      });
    } catch (error) {
      setSchedulerCalendarState({
        ...schedulerCalendarStateRef?.current,
        btnLoading: false,
      });
      // Handle any unexpected errors here
      console.error("An unexpected error occurred:", error);
      props?.handleNotificationMessage("An unexpected error occurred", "error");
    }
  };

  const handleUpdate = (event: any, clientData: any = null) => {

    let payload: any = [];
    let serviceDet = mainState?.Appointment;

    let PrivateServiceInstanceId = schedulerCalendarStateRef?.current?.availExistingSch.length > 0 ? schedulerCalendarStateRef?.current?.availExistingSch[0]?.EntityId : mainStateRef?.current?.Appointment?.PrivateServiceInstanceId;
    let PrivateServiceId = mainState?.Appointment?.PrivateServiceId;



    let duration =
      mainStateRef?.current?.Appointment?.Duration;
    if (typeof duration !== 'number') {
      duration = mainStateRef?.current?.Appointment?.DurationInMinutes;
    }
    const tzvalueName =
      schedulerCalendarStateRef?.current?.tz.timezoneValue?.value ??
      schedulerCalendarStateRef?.current?.tz.timezoneValue;
    // const tzlabelVal = allTimeZones[tzvalueName];
    const tzlabelVal = getUserTimeZoneLong(tzvalueName);

    schedulerCalendarStateRef?.current?.selectedSchItems.forEach((slot: any, index: number) => {

      const selectedDate = slot.date;
      const selectedTime = slot.time;

      const initialDateTime = moment(`${selectedDate} ${selectedTime}`, "YYYY-MM-DD h:mm A");
      const StartDateTime = initialDateTime.format("YYYY-MM-DD HH:mm:ss")
      const endDateTime = initialDateTime.clone().add(duration, "minutes");
      const EndDateTime = endDateTime.format("YYYY-MM-DD HH:mm:ss")


      const req: any = {
        FromTime: StartDateTime,
        ToTime: EndDateTime,
        ScheduleId: slot.ScheduleId,
        TrainerId: slot.trainerId,
        Notes: slot.notes,
        PrivateServiceInstanceId: PrivateServiceInstanceId,
        IsNeedToDelete: false,

      };

      if (mainStateRef?.current?.Package) {
        req.PackageInstanceId =
          mainStateRef?.current?.Package?.PackageInstanceId;
        if (mainStateRef?.current?.Package?.SessionType === SessionTypeEnum.PRESET) {
          req.BillingPeriodFrom = mainStateRef?.current?.Package?.ExpireFrom
        } else {
          req.BillingPeriodFrom = slot?.BillingPeriodFrom?.ExpireFrom || slot?.BillingPeriodFrom
        }

      }


      payload.push(req);
    });


    const AttributeValues =
      mainState?.Appointment?.Attributes &&
        typeof mainState?.Appointment?.Attributes === "string"
        ? JSON.parse(mainState?.Appointment?.Attributes)
        : mainState?.Appointment?.Attributes;

    let buildagreement: any = {
      PromotionId: null,
      Service: {
        Description: serviceDet.Description,
        Duration: duration,
        Name: serviceDet.ServiceName,
        NumberOfSessions: serviceDet.NumberOfSessions,
        PrivateServiceId: PrivateServiceId,
        PrivateServiceType: serviceDet.PrivateServiceType,
        PrivateServiceInstanceId: PrivateServiceInstanceId,
      },
      UserMemberId: userDetails?.UserMemberId,
      Appointment: {
        Attribute: AttributeValues,
        UserTimeZoneId: tzlabelVal,
        Timings: payload,
        IsQuickCheckout: true,
        IsComplementary: propValues?.Complimentary || false
      },
    };

    rescheduleappointment(buildagreement);
  };

  const rescheduleappointment = async (dataItem: any) => {
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      btnLoading: true,
    });

    await axiosRequest.post("Appointments/Schedule", dataItem, {
      successCallBack: (response: any) => {
        let successMsg = "";

        successMsg = `Session signoff completed sucessfully`;

        props?.handleNotificationMessage(successMsg, "success");

        const guestPages = iframeGuestPages;
        if (propValues?.calendarPage) {
          setTimeout(() => {
            setSchedulerCalendarState({
              ...schedulerCalendarStateRef?.current,
              btnLoading: false,
            });
            handleGoToOverview();
          }, 1500);
        } else if (propValues?.quicklinks) {
          setTimeout(() => {
            setSchedulerCalendarState({
              ...schedulerCalendarStateRef?.current,
              btnLoading: false,
            });
            history.push(`/member/${propValues?.uuid}/${userDetails.UserMemberId}`);
          }, 1500);
        } else if (!guestPages) {
          setTimeout(() => {
            setSchedulerCalendarState({
              ...schedulerCalendarStateRef?.current,
              btnLoading: false,
            });
            history.push(`/member/${propValues?.uuid}/${userDetails.UserMemberId}`);
          }, 1500);
        } else {
          setTimeout(() => {
            setSchedulerCalendarState({
              ...schedulerCalendarStateRef?.current,
              btnLoading: false,
            });
            history.push(`/member/${propValues?.uuid}/${userDetails.UserMemberId}`);
          }, 1500);
        }
      },
      errorCallBack: (error: any) => {
        // Check if error.response exists and contains error messages

        setSchedulerCalendarState({
          ...schedulerCalendarStateRef?.current,
          btnLoading: false,
        });
        const errorMsg =
          error?.response?.data?.Messages?.[0] || error?.data?.Messages?.[0] || "Internal Server Error";
        props?.handleNotificationMessage(errorMsg, "error");
      },
    });
  };


  const handleDeleteSlotforQC = (index: number) => {
    const values = [...multipleSlots];
    if (values?.length === 1) {
      handleClearAllSlots()
    } else {

      values.splice(index, 1);
      setMultipleSlots(values);

      const scheduledItems = schedulerCalendarState?.selectedSchItems;

      // Remove the slot at the specified index
      const deletedSlot = scheduledItems.splice(index, 1)[0];

      // Decrease AvailedSlots for the corresponding BillingPeriod
      if (mainStateRef?.current?.Package && deletedSlot) {
        // const selectedDate = new Date(`${deletedSlot.date} ${deletedSlot.time}`);
        const billingPeriods = mainStateRef.current.Package.BillingPeriods;

        // Create a clone of the current state to avoid direct mutation
        const newState = { ...mainStateRef.current, Package: { ...mainStateRef.current.Package } };


        const newBillingPeriods = billingPeriods?.map(
          (i: any) => {
            if (
              deletedSlot?.BillingPeriodFrom &&
              moment(i?.ExpireFrom)?.format("YYYY-MM-DD") ===
              moment(deletedSlot?.BillingPeriodFrom).format("YYYY-MM-DD")
            ) {
              return { ...i, SchedulesToUse: i?.SchedulesToUse + 1 };
            }
            return { ...i };
          }
        );

        // for (let i = 0; i < newBillingPeriods.length; i++) {
        //   const expireFrom = new Date(newBillingPeriods[i].ExpireFrom);
        //   const expireOn = new Date(newBillingPeriods[i].ExpireOn);
        //   expireOn.setHours(23);
        //   expireOn.setMinutes(59);
        //   expireOn.setSeconds(0);

        //   if (selectedDate >= expireFrom && selectedDate <= expireOn) {
        //     if (newBillingPeriods[i].AvailedSlots && newBillingPeriods[i].AvailedSlots > 0) {
        //       newBillingPeriods[i].AvailedSlots--;
        //     }
        //     break; // No need to check further billing periods
        //   }
        // }

        // Update the newState with the modified billing periods
        newState.Package.BillingPeriods = newBillingPeriods;

        // Set the new state
        setMainState(newState);
      }

      const totalCost = values.length * mainState?.Appointment?.Rate;
      setSchedulerCalendarState({
        ...schedulerCalendarStateRef?.current,
        packageCostAfterDiscount: totalCost,
      });

      if (mainState?.Appointment?.CanCollectTaxes) {
        handleTaxCalculation(totalCost);
      }

      if (schedulerCalendarStateRef?.current?.appliedCoupons.length > 0) {
        handleOneonOneCoupon(schedulerCalendarStateRef?.current?.appliedCoupons, values.length);
      }
    }
  };


  const handleClearAllSlots = () => {
    setMultipleSlots([]);
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      selectedSchItems: [],
      packageCostAfterDiscount: 0,
      checkout: false,
    });

    if (mainStateRef?.current?.Package) {
      setMainState({
        ...mainStateRef.current,
        Package: mainStateRef.current?.originalPackage,
        selectedSchedules: [],
      });
    }

    //timeRef?.current?.scrollIntoView({ behavior: "smooth" });
  };


  const handleClosePickPackageDialog = () => {
    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      pickPackage: {
        showDialog: false,
        selectedAppt: null
      }
    })
  }
  const handleClosePackageSelectionDialog = () => {
    handleChangeClient()
  }

  const fetchPackageSummary = async () => {
    const req = {
      UserMemberId: userDetails?.UserMemberId,
      PackageInstanceId: mainStateRef?.current?.Package?.PackageInstanceId,
    };

    const service = new PackageService();
    const getPackageDetails = await service.packageInstanceFilter(req);
    const data = { ...getPackageDetails?.Items[0] }
    setMainState({
      ...mainStateRef?.current,
      selectedPackage: data,
    })

    if (!propValues?.Complimentary) {
      if (data?.SessionType === SessionTypeEnum.PRESET) {
        if (data?.Schedules - data?.SchedulesUsed === 0 && data?.UnusedPaidSchedules > 0) {
          await fetchExistingSchedules(schedulerCalendarStateRef?.current?.pickPackage?.selectedAppt)
        }
        else if ((data?.Schedules - data?.Scheduled === data?.UnPaidSessionCount) && data?.UnusedPaidSchedules > 0) {
          fetchExistingSchedules(schedulerCalendarStateRef?.current?.pickPackage?.selectedAppt)
        }
        else if ((data?.Schedules - data?.SchedulesUsed === data?.UnPaidSessionCount) && data?.UnusedPaidSchedules > 0) {
          fetchExistingSchedules(schedulerCalendarStateRef?.current?.pickPackage?.selectedAppt)
        }
        else if ((data?.SchedulesUsed === data?.UnPaidSessionCount) && data?.UnusedPaidSchedules > 0) {
          fetchExistingSchedules(schedulerCalendarStateRef?.current?.pickPackage?.selectedAppt)
        }
        else if (data?.Scheduled > data?.UnusedPaidSchedules && data?.UnusedPaidSchedules > 0) {
          fetchExistingSchedules(schedulerCalendarStateRef?.current?.pickPackage?.selectedAppt)
        }
      }
      else {
        if (data?.SchedulesUsed - data?.Schedules === 0) {
          fetchExistingSchedules(schedulerCalendarStateRef?.current?.pickPackage?.selectedAppt)
        }
      }
    }


  };

  const handleProratedRate = (value: number) => {
    setMainState({
      ...mainStateRef?.current,
      Appointment: {
        ...mainStateRef?.current?.Appointment,
        ProratedRate: value,
      },
    });

    const purchaseAmt = value * mainStateRef?.current.Appointment?.NumberOfSessions;


    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      packageCostAfterDiscount: purchaseAmt,
    });

    if (mainStateRef?.current?.Appointment?.CanCollectTaxes) {
      handleTaxCalculation(purchaseAmt);
    }
    if (schedulerCalendarStateRef?.current?.appliedCoupons.length > 0) {
      handleOneonOneCoupon(schedulerCalendarStateRef?.current?.appliedCoupons);
    }
  }

  const handlePackageSelection = (dataItem: any, SelectedBillingPeriod: any = null) => {
    setMainState({
      ...mainStateRef?.current,
      Appointment: schedulerCalendarStateRef?.current?.pickPackage?.selectedAppt,
      Package: dataItem,
      originalPackage: dataItem,
      minDate: oneYearAgo,
      // minDate: dataItem?.SessionType === SessionTypeEnum.PRESET ? oneYearAgo : new Date(dataItem?.ExpireFrom),
    })

    setSchedulerCalendarState({
      ...schedulerCalendarStateRef?.current,
      pickPackage: {
        showDialog: false,
        selectedAppt: null
      }
    })
    if (SelectedBillingPeriod) {
      setSelectedAppt({
        ...selectedApptRef?.current,
        BillingPeriodFrom: SelectedBillingPeriod,
        BillingPeriodTo: SelectedBillingPeriod?.ExpireOn,
      });
    }

    fetchTrainers();
    fetchPackageSummary();

    // if (dataItem?.SchedulesToUse === mainStateRef?.current?.selectedPackage?.UnPaidSessionCount) {
    //   fetchExistingSchedules(schedulerCalendarStateRef?.current?.pickPackage?.selectedAppt)
    // }

  }

  const handleSelection = (dataItem: any) => {
    setPackageSelection({
      ...packageSelectionRef.current,
      dataItem: dataItem,
      // SelectedBillingPeriod:null
    });
  };

  const MyItemRender = (props: any) => {
    const item = props.dataItem;
    const schedulesToUse =
      item.SessionType === 2
        ? "Unlimited"
        : `${item?.SchedulesUsed} of ${item?.SchedulesToUse + item?.SchedulesUsed
        }`;
    return (
      <div className="card mb-1 mb-3 packagelistview pointer appthover"
        onClick={() => handlePackageSelection(item)}
      >
        <div className="card-body">
          <div className="package-details">
            <div className="package-name">
              <span className="primary-heading">{item?.Name}</span>
            </div>
            <div className="package-expiry-schedules">
              <small className="package-schedules ">
                Redeemed: {`${schedulesToUse}`}
              </small>
              <br />
              {item?.SessionType !== 3 && (
                <small className="package-expiry">
                  {`${item?.ExpireFrom === null
                    ? "N/A"
                    : moment(item?.ExpireFrom).format("L")
                    } to ${item?.ExpireOn === null || item?.PackageExpireOn === null
                      ? "N/A"
                      : moment(item?.ExpireOn).format("L")
                    }`}
                </small>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleSelectPackageBillingPeriod = (value: any, dataItem: any) => {
    setPackageSelection({
      ...packageSelectionRef.current,
      SelectedBillingPeriod: value
    });
  }
  const handleCardClick = (e: React.MouseEvent, selectedItem: any) => {
    e.preventDefault()
    const target = e.target as HTMLElement;
    if (
      target.closest(
        ".k-widget.k-dropdown.customDropdown.bz-oneonone-dropdown"
      )
    ) {
      return;
    }

    handleSelection(selectedItem);
  };
  const handleDropdownOpen = (event: DropDownListOpenEvent) => {
    event.nativeEvent.stopPropagation();
  };
  const MyPackagesItemRender = (props: any) => {
    const { dataItem, isSelected, handleSelection } = props;
    const item = dataItem;
    const schedulesToUse =
      item.SessionType === 2
        ? "Unlimited"
        : `${item?.SchedulesUsed} of ${item?.SchedulesToUse + item?.SchedulesUsed}`;

    const billingPeriods =
      item?.SessionType === SessionTypeEnum.LIMITED
        ? item?.BillingPeriods?.filter((i: any) => i?.SchedulesToUse > 0) || []
        : item?.BillingPeriods;

    const PackageType = PackageTypesList.find(
      (i: any) => i?.Id === item?.Type
    )?.Name;

    const findItem = isSelected ? packageSelection?.SelectedBillingPeriod : null;

    return (
      <div
        key={item?.PackageInstanceId?.toString()}
        className={`card mb-1 mb-3 packagelistview pointer appthover`}
        style={isSelected ? { borderColor: "#008db9" } : {}}
        onClick={(e) => handleCardClick(e, item)}
      >
        <div className="card-body">
          <div className="package-details">
            <div className="flex items-center">
              <div>
                <div className="package-name d-flex justify-content-between">
                  <span className="">{PackageType}</span>
                  <input
                    type="radio"
                    name="package-selection"
                    checked={isSelected}
                    onChange={() => handleSelection(item)}
                    className="mr-2"
                  />
                </div>
                <div className="package-name">
                  <span className="primary-heading">{item?.Name}</span>
                </div>
                {item?.SessionType === SessionTypeEnum.PRESET ? (
                  <div className="package-expiry-schedules">
                    <small className="package-schedules ">
                      Redeemed: {`${schedulesToUse}`}
                    </small>
                    <br />
                    {item?.SessionType !== 3 && (
                      <small className="package-expiry">
                        {`${item?.ExpireFrom === null
                          ? "N/A"
                          : moment(item?.ExpireFrom).format("L")
                          } to ${item?.ExpireOn === null ||
                            item?.PackageExpireOn === null
                            ? "N/A"
                            : moment(item?.ExpireOn).format("L")
                          }`}
                      </small>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="d-flex align-items-center mb-1">
                      <small className="" style={{ fontSize: "11px" }}>
                        {"Select a Period with Unused Sessions"}
                      </small>
                      <BuzopsTooltip
                        position={"bottom"}
                        html={
                          <a
                            title={`Choose the time period where the unused sessions were originally credited. This allows you to sign off and apply them for use.`}
                            className="pl-2 bz-cursor-pointer"
                          >
                            <i
                              style={{ fontSize: "16px" }}
                              className="fa fa-info-circle"
                            ></i>
                          </a>
                        }
                      />
                    </div>
                    <DropDownList
                      data={billingPeriods}
                      value={findItem}
                      disabled={!isSelected}
                      onChange={(event) => {
                        event.nativeEvent.stopPropagation();
                        handleSelectPackageBillingPeriod(event?.value, item);
                      }}
                      textField="ExpireFrom"
                      dataItemKey="ExpireFrom"
                      itemRender={(
                        li: React.ReactElement<HTMLLIElement>,
                        itemProps: ListItemProps
                      ) => billingPeriodItemRender(li, itemProps, item)}
                      valueRender={(
                        el: React.ReactElement<HTMLSpanElement>,
                        value: any
                      ) => billingPeriodValueRender(el, value, findItem, item)}
                      style={{ width: "100%" }}
                      className={"customDropdown bz-oneonone-dropdown"}
                      onOpen={handleDropdownOpen}
                      listNoDataRender={() => (
                        <span className={"bzo-noData-found"}>
                          No unused sessions available for sign-off
                        </span>
                      )}
                    />
                    {isSelected && !packageSelection?.SelectedBillingPeriod && (
                      <Error>{"Please select the Billing Period"}</Error>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const PurchasedPackagesDialog = () => {
    const filteredPackages = mainStateRef?.current?.AvailablePackages?.filter((obj: any) =>
      obj.ClassIds.includes(schedulerCalendarStateRef?.current?.pickPackage?.selectedAppt?.PrivateServiceId)
    );
    return (
      <Dialog title={"Packages"} onClose={handleClosePickPackageDialog} width={"35%"} height={'550px'}>
        <ListView
          data={filteredPackages || []}
          item={MyItemRender}
          style={{ width: "100%" }}
        />
      </Dialog>
    );
  };

  const handleProceed = (withPackages = true) => {
    if (withPackages && !packageSelection?.dataItem) {
      const errorMsg = "Please Select Package";
      props?.handleNotificationMessage(errorMsg, "error");
      return;
    }
    else if (
      withPackages &&
      packageSelection?.dataItem?.BillingPeriods?.length > 0 &&
      !packageSelection?.SelectedBillingPeriod &&
      packageSelection?.dataItem?.SessionType !== SessionTypeEnum.PRESET
    ) {
      return;
    } else if (withPackages) {
      setShowPackageDialog(false);
      setShowOnlySignOffAppts(false);
      setStartSessionSignOff({
        isGroup: packageSelection?.dataItem?.Type === 1 ? true : false,
        value: true,
      });
      handlePackageSelection(
        packageSelection?.dataItem,
        packageSelection?.SelectedBillingPeriod
      );
    } else {
      if (startSessionSignOff?.isGroup) {
        handleSessionSignoffForGroup();
      } else {
        handleSessionSignoffForAppts();
      }
    }
  };

  const handleSessionSignoffForAppts = () => {
    setShowOnlySignOffAppts(true)
    setShowPackageDialog(false)
    setMainState({
      ...mainStateRef?.current,
      Package: null,
      originalPackage: null,
      ShowPurchasedPackages: false
    })
    setStartSessionSignOff({
      isGroup: false,
      value: true
    })
  }

  const handleSessionSignoffForGroup = () => {
    setShowOnlySignOffAppts(true)
    setShowPackageDialog(false)
    setMainState({
      ...mainStateRef?.current,
      Package: null,
      originalPackage: null,
      ShowPurchasedPackages: false
    })
    setStartSessionSignOff({
      isGroup: true,
      value: true
    })
    setPackageSelection({
      dataItem: null,
      SelectedBillingPeriod: null
    })
  }

  const handleSelectionSessionSignOff = (val: boolean) => {
    setStartSessionSignOff({
      isGroup: val,
      value: false
    })
  }

  const PackagesDialog = () => {
    const filteredPackages = mainStateRef?.current?.AvailablePackages;
    // const filteredPackages :any= []

    return (
      <Dialog
        title={"Packages"}
        onClose={handleClosePackageSelectionDialog}
        width={"35%"}
        height={"550px"}
      >
        <div className="d-flex align-items-center mb-1 p-3">
          <img
            width={38}
            height={38}
            alt={"userImage"}
            style={{ borderRadius: "50%" }}
            src={
              userDetails?.Photo
                ? getFullImageUrl(userDetails?.Photo)
                : "/images/user.png"
            }
          />
          <div
            className="ml-3 d-flex justify-content-between"
            style={{ width: "100%" }}
          >
            <div>
              <span className="appttitle">{userDetails?.FullName}</span>
              <br />
              {(propValues?.calendarPage || propValues?.quicklinks) && (
                <span
                  className="btn btn-link"
                  onClick={() => handleChangeClient()}
                >
                  Change Client
                </span>
              )}
            </div>
          </div>
        </div>
        <div className=" mb-1">
          {filteredPackages?.length > 0 ? (
            <span>
              {
                "Use your remaining sessions from the below packages to schedule past appointments or past group sessions."
              }
            </span>
          ) : (
            <span>
              {
                "You currently don't have any available packages. Please select an appointment session sign-off  or a group session sign-off to proceed"
              }
            </span>
          )}
        </div>
        {filteredPackages?.length > 0 ? (
          <>
            {filteredPackages?.map((i: any) => (
              <MyPackagesItemRender
                key={i?.PackageInstanceId}
                dataItem={i}
                isSelected={
                  packageSelection?.dataItem?.PackageInstanceId ===
                  i?.PackageInstanceId
                }
                handleSelection={handleSelection}
              />
            ))}
          </>
        ) : (
          <>
            <div
              className={`card mb-1 mb-3 packagelistview pointer appthover`}
              style={
                !startSessionSignOff?.isGroup ? { borderColor: "#008db9" } : {}
              }
              onClick={() => handleSelectionSessionSignOff(false)}
            >
              <div className="card-body">
                <div className="package-details">
                  <div className="flex items-center">
                    <div>
                        <div className="package-name d-flex justify-content-between align-items-center">
                          <div className="d-flex justify-content-between align-items-center">
                            <div><img style={{ width: "20px", marginRight:'4px' }} src={bzImages.privateIcon} alt="Private Icon" /></div>
                        <div className="primary-heading  mb-0">
                          {"Appointment Session Sign-Off"}
                        </div>
                        </div>
                        <input
                          type="radio"
                          name="package-selection"
                          checked={!startSessionSignOff?.isGroup}
                          onChange={() => handleSelectionSessionSignOff(false)}
                          className="mr-2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`card mb-1 mb-3 packagelistview pointer appthover`}
              style={
                startSessionSignOff?.isGroup ? { borderColor: "#008db9" } : {}
              }
              onClick={() => handleSelectionSessionSignOff(true)}

            >
              <div className="card-body">
                <div className="package-details">
                  <div className="flex items-center">
                    <div>
                        <div className="package-name d-flex justify-content-between align-items-center">
                          
                        <div className="d-flex justify-content-between align-items-center">
                            <div><img style={{ width: "18px", marginRight:'6px' }} src={bzImages.groupIcon} alt="Group Icon" /></div>
                        <div className="primary-heading  mb-0">
                        {"Group Session Sign-Off"}
                        </div>
                        </div>
                       
                        <input
                          type="radio"
                          name="package-selection"
                          checked={startSessionSignOff?.isGroup}
                          onChange={() => handleSelectionSessionSignOff(true)}
                          className="mr-2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <DialogActionsBar>
          <div className="d-flex w-100 justify-content-between align-items-end">
            <div>
              {filteredPackages?.length > 0 && (
                <>

                  <div>
                    <div className="d-flex mb-2 align-items-center">  <p className="ft12 mb-0">  Onetime Sign-Off   </p>
                    <BuzopsTooltip
                        position={"top"}
                        html={
                          <a
                            title={`Sign-off appointments or group sessions without utilizing package`}
                            className="pl-2 bz-cursor-pointer"
                          >
                            <i
                              style={{ fontSize: "13px" }}
                              className="fa fa-info-circle"
                            ></i>
                          </a>
                        }
                      /></div>
                   
                    <div className="d-flex"> 
                      <div>
                      <Button
                      className="btn btn-link d-block text-start text-left k-button k-primary ml-0"
                      onClick={() => handleSessionSignoffForAppts()}
                    >
                     Appointment  
                    </Button>
                      </div>
                      <div>
                      <Button
                      className="btn btn-link d-block text-start text-left k-button k-primary"
                      onClick={() => handleSessionSignoffForGroup()}
                    >
                       Group  
                    </Button>
                      </div>
                    </div>
                   
                    
                  </div>
                </>
              )}
            </div>
            <div>
              <Button primary={true} onClick={() => handleProceed(filteredPackages?.length > 0 ? true : false)}>
                Proceed
              </Button>
            </div>
          </div>
        </DialogActionsBar>
      </Dialog>
    );
  };


  if (!isClientSelected && !propValues?.clientId) {
    return (
      <div>
        <AddClient
          page={"inhouse-class"}
          disabled={false}
          clientFormValues={clientFormValues}
          clientsList={clientsList}
          handleClientSubmit={handleClientSubmit}
          clientSubmitBtnLoading={clientSubmitBtnLoading}
          clientSearchLoading={clientSearchLoading}
          handleAddClient={handleAddClient}
          selectedClient={selectedClient}
          selectedradioValue={selectedradioValue}
          handleClientSelecton={(val: any) => handleClientSelecton(val)}
          checkout={false}
          fetchClientData={fetchClientData}
          onChangeRadioValue={handleRadioButtonChange}
          width={"400px"}
        />
      </div>
    );
  }

  if (schedulerCalendarStateRef?.current?.mainLoading) {
    return <BuzopsLoader type="list" />
  }

  if (showPackageDialog) {
    return (<>
      {PackagesDialog()}
    </>)
  }
  if (
    !showPackageDialog &&
    ((propValues?.showPackageSelection &&
      (packageSelection?.dataItem?.Type === TypeEnum.Class || (startSessionSignOff?.isGroup && startSessionSignOff?.value))) || mainState?.Package?.Type === TypeEnum.Class || propValues?.Type === TypeEnum.Class)
  ) {
    return (
      <GroupScheduleComponent
        calendarPage={propValues?.calendarPage || false}
        handleGoToOverview={handleGoToOverview}
        SessionSignOff={propValues?.purchaseGroup ? false: true}
        userDetails={userDetailsRef?.current}
        packageSelection={propValues?.purchaseGroup ? {
          dataItem: null,
          SelectedBillingPeriod: null
        }: packageSelection}
        handleChangeClient={handleChangeClient}
        handleChangePackage={handleChangePackage}
        isDialogVisible={isDialogVisible}
        showOnlySignoffAppts={showOnlySignoffAppts}
        purchaseGroup={propValues?.purchaseGroup}
      
      />
    );
  }

  return (
    <>
      <div className="row scheduleappts mx-2">
        <div className="col-md-4 border-client h80vh" style={{ height: isLargeScreen ? `${height}px` : 'auto' }}>
          {!schedulerCalendarStateRef?.current?.confirmationdialog && (
            <SessionSignoffLeftCard
              userDetails={userDetailsRef?.current}
              propValues={propValues}
              handleChange={handleChange}
              handleNavigateBack={handleNavigateBack}
              schedulerCalendarState={schedulerCalendarStateRef?.current}
              mainState={mainStateRef?.current}
              handleChangeClient={handleChangeClient}

            //handleFieldChange={handleFieldChange}
            />
          )}
        </div>
        <div className="col-md-8 flex-grow" style={{ height: isLargeScreen ? `${height}px` : 'auto' }}>
          <SessionSignoffRightCard
            userDetails={userDetailsRef?.current}
            handleChange={handleChange}
            mainState={mainStateRef?.current}
            refs={{ slotRef, anchor }}
            schedulerCalendarState={schedulerCalendarStateRef?.current}
            signOffDialog={signOffDialog}
            selectedAppt={selectedApptRef?.current}
            multipleSlots={multipleSlots}
            showOnlySignoff={showOnlySignoffAppts}
            callBacks={{
              scrollHandler,

              handleApptFilter,
              onAppointmentSelect,
              onSubmit,
              handleChangeSavedCard,
              handleSalesPersonSelection,
              handleCloseSessionSignOff,
              handleRemoveCoupon,
              handleChangeCoupon,
              handleOneonOneCoupon,
              handleChangeAppt,
              handleAddAppointment,
              handleTermsConditions,
              handleTermsConditionDialog,
              handleCheckOut,
              handleAddAdditionalPayment,
              handleDeleteSlotforQC,
              handleClearAllSlots,
              handleUpdate,
              handleGoToOverview,
              handleChangeAdditionalPaymentProfile,
              handleSearch,
              handleIsTaxSkipped,
              handleProratedRate
            }}
            propValues={propValues}
            isDialogVisible={isDialogVisible}
          />
        </div>
      </div>
      {schedulerCalendarStateRef?.current?.pickPackage?.showDialog &&
        PurchasedPackagesDialog()}

    </>
  )
};

export default withNotification(SessionSignOff);
