import { Button } from '@progress/kendo-react-buttons';
import BuzopsButton from 'generic-components/BuzopsButton';
import React, { useState } from 'react'

const CouponApplyField = (props:any) => {
    const [couponCode, setCouponCode] = useState(props?.item1.CouponsText || '');
  
    const handleInputChange = (e:any) => {
      setCouponCode(e.target.value);
    };
  
    const handleApplyClick = () => {
      props?.handleGroupChangeCoupon(couponCode, props?.item1);
    };

    const handleRemoveClick = () => {
        props?.handleRemoveGroupCoupon(props?.item1);
    }
  
    return (
        <div className="d-flex align-items-center mt-2">
            <input
                type="text"
                placeholder="Enter Coupon Code"
                className="form-control coupon-input"
                style={{ width: "70%" }}
                onChange={handleInputChange}
                value={couponCode}
            />
            <BuzopsButton
                primary
                label={"Apply"}
                onClick={handleApplyClick}
            />
            {/* <Button
                onClick={handleRemoveClick}
            >
                {"Remove"}
            </Button> */}
        </div>
    );
  };
  

export default CouponApplyField