import React, { useEffect } from "react";
import useState from "react-usestateref";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { CheckUserMemberStatus, CurrencyFormat, CustomToFixed, checkHasTaxEnable } from "utils";
import {
  DropDownList,
  ListItemProps,
  MultiSelect,
} from "@progress/kendo-react-dropdowns";
import moment from "moment";
import { filterBy } from "@progress/kendo-data-query";
import {
  Checkbox,
  Input,
  NumericTextBox,
  RadioGroup,
  Switch,
} from "@progress/kendo-react-inputs";
import { TenantService } from "services/tenant/index.service";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import BuzopsWindow from "generic-components/BuzopsWindow";
import SignaturePad from "components/signaturePad";
import images from "utils/images";
import { GetLocalStore } from "utils/storage";
import { Hint } from "@progress/kendo-react-labels";
import withNotification from "components/Hoc/withNotification";
import PaymentCheckout from "components/PaymentCheckout/PaymentCheckout";
import { useDispatch } from "react-redux";
import { SetNavigateToAPageOnButtonClick } from "redux-slices/genericSlice";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { Tooltip } from "@progress/kendo-react-tooltip";
import TermsAndConditions from "components/checkout/TermsAndConditions";
import bzImages from "./../../../Images";
import AdditionalPayment from "components/AdditionalPayment/AdditionalPayment";
import { useApiClient } from "services/axios-service-utils";
import { shouldSetAsDefaultPaymentProfile } from "utils/utils";

const AssignAddon = (props: any) => {
  const {axiosRequest}=useApiClient()
  const PrimaryUserMemberId=props?.userData?.ParentId || props?.userData?.UserMemberId
  const dispatch = useDispatch()
  const [Taxes, setTaxes] = useState<any>([]);
  const [selected, setSelected] = useState<any>("5");
  const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
  const [finaldata, setFInalData] = useState<any>([]);
  const [disable, setDisable] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [termsandConditions, setTermsandConditions] = useState<boolean>(true);
  const [termsandConditionsDialog, setTermsandConditionsDialog] =
    useState<boolean>(false);
  const [paymentGatewayType, setPaymentGatewayType] =
    useState<any>(paymentGatewaytype);
  //  payment profile card initial form values
  const cardIntialValues = {
    MaskedCCNumber: undefined,
    CardTypeId: undefined,
    ExpiryMonthYear: undefined,
    ExpiryMonth: undefined,
    ExpiryYear: undefined,
    ZipCode: undefined,
    PaymentType: "Card",
    UserMemberId: PrimaryUserMemberId,
  };
  const [cardFormValues, setCardFormValues] = useState<any>(cardIntialValues);

  // credit cards image placeholder
  const [cardImageIcon, setcardImageIcon] = useState(images.placeholder);
  //  payment profile bank initial form values
  const bankIntialValues = {
    AccountName: undefined,
    BankAccountType: undefined,
    BankName: undefined,
    RoutingNumber: undefined,
    AccountNumber: undefined,
    UserMemberId: PrimaryUserMemberId,
    PaymentType: "Bank",
  };
  const [bankFormValues, setBankFormValues] = useState<any>(bankIntialValues);
  //selected payment profile
  const [selectedPaymentProfile, setSelectedPaymentProfile] = useState<any>({
    selectedPayment: undefined,
    AlternatePaymentProfileId:null,
  });

  //payment profiles to submit
  const [paymentSubmitProfile, setPaymentSubmitProfile] = useState<any>({});

  //to auto populate latest submitted payment profile
  const [populateProfile, setPopulateProfile] = useState<any>(null);

  const [paymentProfile, setPaymentProfiles,paymentProfileref] = useState<any>([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [visibleAgreement, setVisibleAgreement] = useState(false);
  const [agreementTemplate, setAgreementTemplate] = useState("");
  const [visibleSignPad, setVisibleSignPad] = useState(false);
  //dirty Check
  //base64 encode signature
  const [signature, setSignature] = useState("");

  //for buzops button loading for card submit
  const [cardSubmitBtnLoading, setCardSubmitBtnLoading] = useState(false);

  //for buzops button loading for bank submit
  const [bankSubmitBtnLoading, setBankSubmitBtnLoading] = useState(false);

  const [signatureText, setSignatureText] = useState<any>("");
  const [signatureFont, setSignatureFont] = useState<any>("");
  const [signatureType, setSignatureType] = useState<any>("");

  const [loading, setLoading] = useState(false);

  const [addondata, setAddonData] = useState<any>([]);
  const [filterAddondata, setFilterAddonData] = useState<any>([]);
  const service = new TenantService();
  const [data, setData] = useState<any>([]);
  // to show payment profile type
  const [paymentMethod, setPaymentMethod] = useState({
    card: false,
    bank: false,
  });
  //
  const initialAdditionalPayment={
    dialog:false,
    selectedPaymentProfile:null
  }
  const [showAdditionalPayment, setShowAdditionalPayment] = useState<any>(initialAdditionalPayment);

  useEffect(() => {
    const { selectedPayment } = selectedPaymentProfile;
    const { bank, card } = paymentMethod;

    if (
      populateProfile &&
      !selectedPayment &&
      paymentProfile &&
      !bank &&
      !card && paymentProfile?.length > 0
    ) {
      const index = paymentProfile.findIndex((i:any)=> i?.PaymentGatewayPaymentProfileID === populateProfile?.PaymentGatewayPaymentProfileID)
      if (index !== -1) {
        const selectPayment: any = paymentProfile[index];
        setSelectedPaymentProfile({
          ...selectedPaymentProfile,
          selectedPayment: selectPayment,
          AlternatePaymentProfileId:null
        });
        generatePaymentProfileObj(selectPayment);
      }
    }
  }, [paymentProfile]);
  const generatePaymentProfileObj = (val: any) => {
    const paymentDetails = {
      CustomerProfileID: val?.CustomerProfileID,
      PaymentProfileID: val?.PaymentProfileID,
      PaymentGatewayPaymentProfileID: val?.PaymentGatewayPaymentProfileID,
      MaskedCCNumber: val?.MaskedCCNumber,
      CardTypeId: val?.CardTypeId,
      CardDescription: val?.CardDescription,
      BankAccountType: val?.BankAccountType,
      Credit: val?.Credit,
    };
    setPaymentSubmitProfile(paymentDetails);
    setPopulateProfile(null)
  };

  // to select the payment profile
  const handleChangeSavedCard = (val: any) => {
    setSelectedPaymentProfile({
      ...selectedPaymentProfile,
      selectedPayment: val,
      AlternatePaymentProfileId:null
    });
    setCardFormValues(cardIntialValues);
    setBankFormValues(bankIntialValues);
    generatePaymentProfileObj(val);
    setPaymentMethod({
      ...paymentMethod,
      card: false,
      bank: false,
    });
    setSelected("0");
  };

  // handle bank form submit
  const handleBankSubmit = async (dataItem: any) => {
    setBankSubmitBtnLoading(true);
    const saveBankDetails = new TenantService();
    let req={...dataItem,BankAccountType:dataItem?.BankAccountType?.id}
    if(shouldSetAsDefaultPaymentProfile(paymentProfile)){
      req={...req,IsDefault:true}
    }
    const result = await saveBankDetails.createPaymentProfile(req);
    setBankSubmitBtnLoading(false);
    if (result?.ResponseCode === 100) {
      setPopulateProfile(result?.Item);
      setSelected("0");
      props?.handleNotificationMessage(result?.SuccessMessage, "success");
      setPaymentMethod({
        ...paymentMethod,
        card: false,
        bank: false,
      });
      fetchPaymentProfiles(PrimaryUserMemberId);
      handleSubmit("text");
    } else {
      setBankFormValues(dataItem);
      setCardFormValues(cardIntialValues);
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  // handle payment profile submit
  const handleSubmit = (dataItem: any = null) => {
    if (selected === "0") {
      setSelected(undefined);
    } else {
      setSelected("3");
    }
  };
  // to handle panel bar expand and collpase
  const onSelect = (e: any) => {
    setSelected(e?.target?.props?.id);
  };

  // to handle credit card image icon
  const handleCardImageIcon = (imageUrl: any) => {
    setcardImageIcon(imageUrl);
  };

  // handle card form submit
  const handleCardSubmit = async (dataItem: any) => {
    setCardSubmitBtnLoading(true);
    const saveCardDetails = new TenantService();
    if (paymentGatewayType === 1) {
      dataItem.MaskedCCNumber = dataItem.MaskedCCNumber.split(" ").join("");
    }
    if(shouldSetAsDefaultPaymentProfile(paymentProfile)){
      dataItem.IsDefault=true
    }
    const result = await saveCardDetails.createPaymentProfile(dataItem);
    setCardSubmitBtnLoading(false);
    if (result?.ResponseCode === 100) {
      setPopulateProfile(result?.Item);
      setSelected("0");
      const successMsg = result?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");

      setPaymentMethod({
        ...paymentMethod,
        card: false,
        bank: false,
      });
      setcardImageIcon(images.placeholder);
      fetchPaymentProfiles(PrimaryUserMemberId);
      handleSubmit("text");
    } else {
      setCardFormValues(dataItem);
      setBankFormValues(bankIntialValues);
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleChangeAdditionalPaymentProfile = async (value: any) => {
    setShowAdditionalPayment({
      ...showAdditionalPayment,selectedPaymentProfile:value
    })
  }

  const handleAddAdditionalPayment=()=>{
    if(!showAdditionalPayment?.selectedPaymentProfile?.PaymentGatewayPaymentProfileID){
      const errorMsg = "Please select/add Payment profile";
      props?.handleNotificationMessage(errorMsg, "error");
      return
    }
    setSelectedPaymentProfile({
      ...selectedPaymentProfile,AlternatePaymentProfileId:showAdditionalPayment?.selectedPaymentProfile?.PaymentGatewayPaymentProfileID
    })
    setShowAdditionalPayment(initialAdditionalPayment)
  }

  // handle switching between saved payment  & card & bank forms
  const handleForm = (form: string) => {
    setcardImageIcon(images.placeholder);
    setSelectedPaymentProfile({
      ...selectedPaymentProfile,
      selectedPayment: undefined,
      AlternatePaymentProfileId:null
    });
    setPaymentSubmitProfile({});
    if (form === "bank") {
      setPaymentMethod({ ...paymentMethod, bank: true, card: false });
    } else {
      setPaymentMethod({ ...paymentMethod, bank: false, card: true });
    }
  };

  // dropdown value
  const databilling = [
    {
      text: "First Day Of Month",
      value: 1,
    },
    {
      text: "Specific Day Of Month",
      value: 2,
    },
  ];

  const endafter_addon = [
    {
      label: "Never",
      value: 0,
    },
    {
      label: "No of occurrence",
      value: 1,
    },
  ];
  const Frequencytype_addon = [
    { text: "ONE TIME", id: 1 },
    { text: "DAILY", id: 2 },
    { text: "MONTHLY", id: 4 },
    { text: "YEARLY", id: 5 },
  ];

  //to saving signature
  const generatedImage = (
    imageString: string,
    text?: string,
    font?: string,
    Type?:string
  ) => {
    setSignature(imageString);
    setSignatureText(text);
    setSignatureFont(font);
    setSignatureType(Type || "");
  };
  //handel sign

  //handle signature pad popup
  const handleSignaturePad = () => {
    setVisibleSignPad(true);
  };

  //main state values
  const initialValues = {
    planname: "",
    planid: 0,
    PrimaryUserMemberId: PrimaryUserMemberId, //need to ask
    PrimaryMemberNumber: "", //need
    PrimaryMemberName: "",
    PrimaryUserId: 0, //need
    PrimaryUserInternalDataId: "",
    State: "",
    AgreementContent: null,
    Signature: null,
    TermsAndConditionAccepted: false,
    ClubBillingType: 2,
    clientdata: {},
    SalesPersonId: 0,
    MembershipPlanDetail: {}, //plan
    SelectedAddons: [],
    Coupon: [],
    StartDate: moment().format("L"),
    prospect_amount: 0,
    coupon_amount_total: 0,
    total_amt: 0,
    PaymentGatewayPaymentProfileId: "0",
    BillingType: 0,
    client: 0,
    clientname: "",
    //ProratedAmount
    Result: [
      {
        UserMemberId: props?.UserMemberId,
        MemberNumber: "",
        MemberType: 0,
        MemberTypeDescription: "",
        MemberName: "",
        Email: "",
        AgreementContent: null,
        AdditionalFee: 0,
        CanFeesWaived: false,
        StartDate: moment().format("L"),
        EndDate: null,
        RecurrenceEndDate: "",
        IsEndDateOverridden: false,
        NextRecurrence: "2022-06-08T01:32:07.729Z",
        RecurringAmount: 0,
        CanPayInFull: false,
        PayLater: false,
        HasAutoRenewal: true,
        CanAutoRenewalPayInFull: false,
        CardDescription: "",
        PlanStatus: 0,
        PaymentGatewayPaymentProfileId: "",
        CustomerProfileId: null,
        PaymentProfileId: null,
        MaskedCCNumber: null,
        DiscountAmount: 0,
        DownPayment: 0,
        MembershipDownPayment: 0,
        RefundAmount: 0,
        PreviousPlanRemainingAmount: 0,
        TransactionStatus: null,
        IsTransactionSucced: false,
        PaymentGatewayTransactionHistoryId: 0,
        ShowPayInFull: true,
        BillingType: 1,
        SelectedAddons: [],
        BillingDay: 0,
        DisableAutoRenewal: false,
        SalesPersonId: 0,
        Photo: null,
        MemberPlanId: 0,
        DisablePayInFull: false,
        DisableAutoRenewalPayInFull: false,
        DisablePayLater: true,
        Coupons: [],
        DisableCoupon: false,
      },
    ],
    //
    ClubId: 0, //need to ask below 3
    ClubInternalId: "00000000-0000-0000-0000-000000000000",
    UserAssociateId: 0,
  };

  useEffect(() => {
    fetchAllApis();
  }, []);
  const filterAddonChange = (e: any) => {
    const filteredData = filterData(e.filter, addondata);
    setFilterAddonData(filteredData);
  };
  const filterData = (filter: any, itemData: any) => {
    const data = itemData.slice();
    return filterBy(data, filter);
  };
  const getAddonData = async () => {
    let EntityId = props?.EntityId;
    let memberId = props?.UserMemberId;

    if (memberId) {
      const req = {
        MemberPlanId: memberId,
        PrimaryUserMemberId: EntityId,
      };
      const res = await service.AvailableAddonsToSubscribe(req);
      const updatedResult=res?.map((i:any)=>{
        if(i?.Name==="Custom"){
          return {...i,CanCollectTaxes:checkHasTaxEnable()}
        }else{
          return {...i}
        }
      }) 
      setAddonData(updatedResult);
      setFilterAddonData(updatedResult);
    }
  };

  async function fetchPaymentProfiles(userMemberId: any) {
    const result = await service.paymentProfile(userMemberId);
    setPaymentProfiles(result || []);
  }
  const getCouponData = async () => {
    let EntityId = props?.EntityId;
    let memberId = props?.UserMemberId;

    if (memberId) {
      const req = {
        UserMemberId: memberId,
        EntityType: 1,
        EntityId: EntityId,
        CouponType:1
      };
      const res = await service.AvailableCouponsToApply(req);
    }
  };

  const fetchAllApis = async () => {
    setLoading(true);
    await fetchPaymentProfiles(PrimaryUserMemberId);
    await getSignUpData();
    await getAddonData();
    await getCouponData();
    await fetchTaxes()
    setLoading(false);
  };
  const fetchTaxes = async () => {
    const req = {};
    const taxService= new ManageTaxesService()
    const res = await taxService.getTaxes(req);
    setTaxes(res);
  };

  const getClientData = async (initial: any) => {
    let memberId = PrimaryUserMemberId;
    if (memberId) {
      const res = await service.GetSubMembersWithParent(memberId);
      if (res.length >= 1) {
        let initialVal = initial;
        
        var cli = res.filter(
          (res1: any) => res1?.UserMemberId === initial?.clientUserMemberId
        )[0];

        initialVal = {
          ...initial,
          client: cli?.UserId,
          clientdata: cli,
        };
        setData(initialVal);
      }
    }
  };

  const getSignUpData = async () => {
    let selectedPlanId = props?.selectedPlanId;
    let memberId = props?.UserMemberId;

    let initialVal:any = initialValues;
    if (memberId) {
      const req = {
        UserMemberId: memberId,
        MemberPlanId: selectedPlanId,
        PrimaryUserMemberId: PrimaryUserMemberId,
      };
      const res = await service.SignUpDetails(req); 
      const paymentData= paymentProfileref?.current?.find((i:any)=> i?.PaymentGatewayPaymentProfileID ===res?.Result[0]?.PaymentGatewayPaymentProfileId)
      
      const paymentDetails = {
        CustomerProfileID: res?.Result[0]?.CustomerProfileId,
        PaymentProfileID: res?.Result[0]?.PaymentProfileId,
        PaymentGatewayPaymentProfileID:
          res?.Result[0]?.PaymentGatewayPaymentProfileId,
        MaskedCCNumber: res?.Result[0]?.MaskedCCNumber,
        CardTypeId: res?.Result[0]?.CardTypeId,
        Credit: paymentData?.Credit,
        CardDescription: res?.Result[0]?.CardDescription,
      };
      initialVal = {
        ...initialVal,
        planname: res?.Result[0]?.MembershipPlanDetail?.Name,
        planid: res?.Result[0]?.MembershipPlanDetail?.MembershipPlanId,
        planStartDate: res?.Result[0]?.StartDate,
        clientname: res?.Result[0]?.MemberName,
        clientperson: res?.Result[0]?.MemberNumber,
        clientUserMemberId: res?.Result[0]?.UserMemberId,
      };
      setData(initialVal);
      setFInalData(res);
      setPaymentSubmitProfile(paymentDetails);
      await getClientData(initialVal);
    }
  };

  const getCouponDataAddon = async (e: any) => {
    let memberId = props?.UserMemberId;
    let initialVal = data;
    initialVal.SelectedAddons = e;
    if (memberId && e.length !== 0) {
      const req = {
        UserMemberId: memberId,
        EntityType: e[e.length - 1]?.EndType,
        EntityId: e[e.length - 1]?.AddonTemplateId,
        CouponType:1
      };
      const res = await service.AvailableCouponsToApply(req);

      initialVal.SelectedAddons[e.length - 1].AllCoupons = res;

      initialVal = {
        ...initialVal,
        SelectedAddons: initialVal?.SelectedAddons,
      };
    } else {
      initialVal = {
        ...initialVal,
        SelectedAddons: initialVal?.SelectedAddons,
      };
    }
    const addonsData = initialVal?.SelectedAddons.map((i: any) => {
      return { ...i, StartDate: moment().format("L") };
    });
    initialVal = {
      ...initialVal,
      SelectedAddons: addonsData,
    };
    updateTotalAmount(initialVal);
  };

  const TooltipContentTemplate = (propData: any) => {
    return (<>
       <>
        <ul className="list-group list-group-flush taxes-list">

          {data?.SelectedAddons?.length > 0 &&
            data?.SelectedAddons?.map((val: any, indx: any) => {
              if (
                checkHasTaxEnable() &&
                !val?.IsTaxSkipped &&
                val?.PredictedTaxes?.length
              ) {
                return (
                  <>
                    <li className="list-group-item text-align-center" style={{fontSize:'0.875rem',fontWeight:600}}>{val?.Name}</li>
                    {val?.PredictedTaxes.map((i: any) => {
                      return (
                        <>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span>
                              {i?.TaxName}&nbsp;({i?.Percentage}%)
                            </span>
                            <span className="price">
                              {CurrencyFormat(i?.Amount)}
                            </span>
                          </li>
                        </>
                      );
                    })}
                  </>
                );
              }
            })}
            {propData?.TotalTaxAmount > 0 && (<li className="list-group-item d-flex justify-content-between align-items-center">
          <span>
            {"Total Surcharges"}
          </span>
          <span className="price">{CurrencyFormat(propData?.TotalTaxAmount)}</span>
      </li>)}
        </ul>
      </>
    </>);
  };

  const handleChange = (e: any, from: any) => {
    let initialVal = data;
    if (from === "addon") {
      let values = e;
      if (initialVal?.SelectedAddons?.length === 0) {
        values = e;
      } else if (e?.length > initialVal?.SelectedAddons?.length) {
        //add
        const originalArray = initialVal?.SelectedAddons;
        originalArray.push(e[e.length - 1]);
        values = originalArray;
      } else if (e?.length < initialVal?.SelectedAddons?.length) {
        //removed
        const updateArray = initialVal?.SelectedAddons?.filter((i: any) => {
          if (e?.find((j: any) => j?.AddonTemplateId === i?.AddonTemplateId)) {
            return true;
          }
          return false;
        });
        values = updateArray;
      }

      initialVal = {
        ...data,
        SelectedAddons: values,
      };
      getCouponDataAddon(values);
      return;
    } else if (from === "salesperson") {
      initialVal = {
        ...data,
        SalesPersonId: e,
      };
    } else if (from === "client") {
      initialVal = {
        ...data,
        clientperson: e,
        client: e?.UserId,
      };
      initialVal = {
        ...data,
        clientdata: e,
        clientperson: e,
        clientname: e?.FullName,
      };
    }
    updateTotalAmount(initialVal);
  };
  const calculateProratedAmount=(startDate:any,AddonCharge:any)=>{
    const currentDate = new Date(startDate);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth()  + 1;
    const currentMonthLastDate = new Date(
      currentYear,
      currentMonth,
      0
    ).getDate();

    const daysLeftInMonth = currentMonthLastDate - currentDate.getDate() + 1;

    const plan_per_day_cost = AddonCharge / currentMonthLastDate;
    const pros = daysLeftInMonth * plan_per_day_cost;
    return pros;
  }
  
  const calculatePaidToday = () => {
    const addons=data?.SelectedAddons
    if(PaidLaterAddonsLength() > 0 && PaidLaterAddonsLength()===addons?.length){
      return 0
    }else{
      const initialValue = 0;
      const records=addons?.filter((i:any)=>{
        if(moment(new Date()).format("L") >=
        moment(i?.StartDate).format("L")){
          return true
        }
        return false
      })
      if(records?.length > 0){
        const addonValue = records.map((itm: any) => {
          const addonCoupons = itm?.Coupon?.map((i: any) => {
            return calculateAddonCouponAmt(i, itm)
          })
          const intialValue = 0
      
          const sumWithaddoncoupon = addonCoupons?.reduce(
            (accumulator: any, currentValue: any) => accumulator + currentValue,
            intialValue
          );
          if (itm?.IsProrated && itm?.ProratedAmount !== 0) {
            return itm?.ProratedAmount - (sumWithaddoncoupon || 0) > 0 ? itm?.ProratedAmount - (sumWithaddoncoupon || 0):0
          }
          else {
            return itm?.Charge - (sumWithaddoncoupon || 0) > 0 ? itm?.Charge - (sumWithaddoncoupon || 0):0
          }
        })
        const addOnSum = addonValue.reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue,
          initialValue
        );
        return addOnSum > 0 ? addOnSum : 0
      }
      return 0
    }
  }

  const PaidLaterAddonsLength=()=>{
    const addons=data?.SelectedAddons
    const records=addons?.filter((i:any)=>{
      if(moment(new Date()).format("L") <
      moment(i?.StartDate).format("L")){
        return true
      }
      return false
    })
    return records?.length || 0
  }


  const handleChangeAddonElement = (e: any, from: any, index: any) => {
    let initialVal = data;
    if (from === "EndType") {
      initialVal.SelectedAddons[index].EndType = e;
      if (e === 1) {
        initialVal.SelectedAddons[index].Occurrence = 2;
      }
    }
    if (from === "IsTaxSkipped")
      initialVal.SelectedAddons[index].IsTaxSkipped = e;

    if (from === "Charge") {
      initialVal.SelectedAddons[index].Charge = e;
      if(initialVal.SelectedAddons[index].IsProrated){
        const pros=calculateProratedAmount(initialVal.SelectedAddons[index].StartDate,e)
        initialVal.SelectedAddons[index].ProratedAmount = pros;
      }
    }

    if (from === "Occurrence") initialVal.SelectedAddons[index].Occurrence = e;

    if (from === "StartDate") {
      initialVal.SelectedAddons[index].StartDate = moment(e).format("L");
      if(initialVal.SelectedAddons[index].IsProrated){
        const pros=calculateProratedAmount(moment(e).format("L"),initialVal.SelectedAddons[index]?.Charge)
        initialVal.SelectedAddons[index].ProratedAmount = pros;
      }
    }
    if (from === "Description")
      initialVal.SelectedAddons[index].Description = e;

    if (from === "Frequency") {
      initialVal.SelectedAddons[index].Frequency = e?.id;
      if (e.id === 1) {
        initialVal.SelectedAddons[index].EndType = 0;
        initialVal.SelectedAddons[index].Occurrence = 0;
        initialVal.SelectedAddons[index].RepeatFrequency = 0;
      }else {
        initialVal.SelectedAddons[index].EndType = 0;
        initialVal.SelectedAddons[index].RepeatFrequency = 1;
      }
    }

    if (from === "RepeatFrequency")
      initialVal.SelectedAddons[index].RepeatFrequency = e;

    if (from === "IsProrated") {
      if (e) {
        initialVal.SelectedAddons[index].IsProrated = e;
        const pros=calculateProratedAmount(initialVal.SelectedAddons[index].StartDate,initialVal.SelectedAddons[index]?.Charge)
        initialVal.SelectedAddons[index].ProratedAmount = pros;
      } else {
        initialVal.SelectedAddons[index].IsProrated = e;
        initialVal.SelectedAddons[index].ProratedAmount = 0;
      }
    }

    initialVal = {
      ...initialVal,
      SelectedAddons: initialVal?.SelectedAddons,
    };

    updateTotalAmount(initialVal);
  };

  const updateTotalAmount = async (statedata: any) => {
    let total = 0;
    //addon
    let coup_addon = 0,
      coup_addon_temp = 0,
      addon_total_with_payLater = 0;
      let updatedAddons=[]
    if (statedata?.SelectedAddons?.length > 0) {

      updatedAddons=statedata?.SelectedAddons.map((item: any) => {
        const x = new Date();
        const y = new Date(item?.StartDate);
        let taxableAmt=0
        let predictedTaxableAmt=0
        if (x < y) {
          //change
          predictedTaxableAmt=item?.Charge
          addon_total_with_payLater=addon_total_with_payLater+item?.Charge
          if (item?.coupamt) {
            coup_addon = coup_addon + item?.coupamt;
            taxableAmt=0
            predictedTaxableAmt=predictedTaxableAmt-item?.coupamt
          }
        } else {
        //addon ProratedAmount
          if (item?.IsProrated) {
            total = total + item?.ProratedAmount;
            taxableAmt=item?.ProratedAmount;
            addon_total_with_payLater = addon_total_with_payLater + item?.ProratedAmount;
          } else {
            total = total + item?.Charge;
            taxableAmt=item?.Charge;
            addon_total_with_payLater = addon_total_with_payLater + item?.Charge;
          }
          predictedTaxableAmt=taxableAmt
          if (item?.coupamt) {
            coup_addon_temp = coup_addon_temp + item?.coupamt;
            coup_addon = coup_addon + item?.coupamt;
            taxableAmt=taxableAmt-item?.coupamt
            predictedTaxableAmt=predictedTaxableAmt-item?.coupamt
          }
   
        }
        if(checkHasTaxEnable() && item?.CanCollectTaxes  && !item?.IsTaxSkipped){
          if(taxableAmt <=0){
            taxableAmt=0
          }
          if(predictedTaxableAmt <=0){
            predictedTaxableAmt=0
          }
          const taxCal=handleTaxCalculation(taxableAmt)
          const predictedTaxCal=handleTaxCalculation(predictedTaxableAmt)
          return {...item,Taxes:taxCal?.TaxesArray,TotalTaxAmount:taxCal?.taxAmt,IsTaxSkipped:item?.IsTaxSkipped || false,PredictedTaxes:predictedTaxCal?.TaxesArray,PredictedTotalTaxAmount:predictedTaxCal?.taxAmt}
        }
        return {...item,Taxes:[],TotalTaxAmount:0,IsTaxSkipped:true,PredictedTaxes:[],PredictedTotalTaxAmount:0}
      });
    }

    total = total - coup_addon_temp;
    addon_total_with_payLater = addon_total_with_payLater - coup_addon;
    if (total <= 0) {
      total = 0;
    }
    if (addon_total_with_payLater < 0) {
      addon_total_with_payLater = 0;
    }
    const total_with_paylater=addon_total_with_payLater
    let initialVal = {
      ...statedata,
      coupon_amount_total: coup_addon,
      total_amt: total,
      TotalBeforeSurcharges: total_with_paylater,
      SelectedAddons:updatedAddons
    };
    setData(initialVal);
  };

  const calculateTotalTaxAmount = (prediction=false) => {
    const addonTax = data?.SelectedAddons?.reduce(
      (accumulator: any, currentValue: any) => {
        if(!currentValue?.IsTaxSkipped){
          if(!prediction){
            return accumulator + CustomToFixed(currentValue?.TotalTaxAmount,2)
          }else{
            return accumulator + CustomToFixed(currentValue?.PredictedTotalTaxAmount,2)
          }
        }else{
          return accumulator + 0
        }
      },
      0
    );
    return addonTax
  }

  const checkTaxApplicable = () => {
    let check=false
    const addonCheck = data?.SelectedAddons?.some((i:any)=>{
      if(i?.CanCollectTaxes && !i?.IsTaxSkipped){
        return true
      }else{
        return false
      }
    })
    if(Taxes?.length > 0){
      check=addonCheck
    }
    return check
  }
  const handleTaxCalculation=(purchaseAmt:number,TaxItems=Taxes)=>{
    const TaxesArray=TaxItems.map((i:any)=>{
      const amount=purchaseAmt*i?.Percentage/100;
      const calcAmt=CustomToFixed(amount,2)
        return {
          "TaxId": i?.TaxId,
          "TaxName": i?.TaxName,
          "Percentage": i?.Percentage,
          "Amount": calcAmt
        }
    })
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
      return acc + currentValue?.Amount
    }, 0);
    return {taxAmt,TaxesArray}
  }
  const handleChangeAddonCoupon = (e: any, index: any) => {
    let initialVal = data;
    if (data?.SelectedAddons?.length !== 0) {
      if (e?.length !== 0) {
        let coup = 0;
        e.forEach((res: any) => {
          if (res?.TypeDescription === "Percentage") {
            let percentageAmt = 0;
            if (
              data?.SelectedAddons[index]?.IsProrated &&
              data?.SelectedAddons[index].ProratedAmount !== 0
            ) {
              coup =
                coup +
                (data?.SelectedAddons[index]?.ProratedAmount / 100) *
                  res?.Amount;
              percentageAmt =
                (data?.SelectedAddons[index]?.ProratedAmount / 100) *
                res?.Amount;
            } else {
              coup =
                coup +
                (data?.SelectedAddons[index]?.Charge / 100) * res?.Amount;
              percentageAmt =
                (data?.SelectedAddons[index]?.Charge / 100) * res?.Amount;
            }
            initialVal.SelectedAddons[index].PercentageCoupon = percentageAmt;
          } else if (res?.TypeDescription === "Fixed") {
            coup = coup + res?.Amount;
            initialVal.SelectedAddons[index].FixedCoupon = res?.Amount;
          }
        });

        if (coup > data?.SelectedAddons[index]?.Charge) {
          coup = data?.SelectedAddons[index]?.Charge;
        }

        initialVal.SelectedAddons[index].coupamt = coup;

        let final_coup_data: any[] = [];
        const couponsList = e.sort(function (a: any, b: any) {
          return b.Amount - a.Amount;
        });
        const couponPercentage = couponsList.filter(
          (res: any) => res?.TypeDescription === "Percentage"
        );
        if (couponPercentage.length > 0) {
          final_coup_data.push(couponPercentage[0]);
        }
        const couponFixed = couponsList.filter(
          (res: any) => res?.TypeDescription === "Fixed"
        );

        if (couponFixed.length > 0) {
          final_coup_data.push(couponFixed[0]);
        }

        //adding coupon data
        initialVal.SelectedAddons[index].Coupon = final_coup_data;

        initialVal = {
          ...initialVal,
          SelectedAddons: initialVal?.SelectedAddons,
        };

        setData(initialVal);
      } else {
        initialVal.SelectedAddons[index].coupamt = 0;
        initialVal.SelectedAddons[index].Coupon = [];

        initialVal = {
          ...initialVal,
          SelectedAddons: initialVal?.SelectedAddons,
        };
      }
    }

    updateTotalAmount(initialVal);
  };
  const calculateAddonCouponAmt = (e:any,addon:any) =>{
    if (e?.DurationType === 3) {
      if(!addon?.IsProrated){
        return (e?.Type === 2
          ? addon?.PercentageCoupon
          : addon?.FixedCoupon)
      }
      else {
        return 0
      }
    }
    else {
      return (e?.Type === 2
        ? addon?.PercentageCoupon
        : addon?.FixedCoupon)
    }
  }

  const itemRenderCoupon = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    let itemChildren;
    const index = itemProps?.dataItem?.Amount;
    if (itemProps?.dataItem?.TypeDescription === "Percentage") {
      itemChildren = (
        <span style={{ fontSize: "18px" }}>
          {itemProps?.dataItem.Description} <br />
          {index} {"%"}
        </span>
      );
    } else if (itemProps?.dataItem?.TypeDescription === "Fixed") {
      itemChildren = (
        <span style={{ fontSize: "18px" }}>
          {itemProps?.dataItem.Description} <br />
          {CurrencyFormat(index)}
        </span>
      );
    }

    return React.cloneElement(li, li.props, itemChildren);
  };

  const handleValidation = (type: string,checkTerms=true) => {
    let errormsg = "";
    const paymentData= paymentProfile?.find((i:any)=> i?.PaymentGatewayPaymentProfileID ===paymentSubmitProfile?.PaymentGatewayPaymentProfileID)
    if (data?.SelectedAddons.length === 0) {
      errormsg = "Please Add Addon";
      return errormsg;
    } else if (data?.SelectedAddons.length !== 0) {
      const currentDate  = moment();

      data?.SelectedAddons.forEach((item: any) => {
        //date condition
        const x = moment(item?.StartDate); //adon
        const y = moment(data?.planStartDate); //plan
        const startDate = moment(item?.StartDate);

        if (x.diff(y, "days") < 0) {
          errormsg =
            "Select Addon date greater than plan start date for addon " +
            item?.Name;
        }

        if (item?.Charge === 0) {
          errormsg = "Select Price for addon " + item?.Name;
        } else if (item?.EndType === 1 && item?.Occurrence === 0) {
            errormsg = "Select No of Occurrence for addon " + item?.Name;
        } else if (item?.Frequency === 0) {
          errormsg = "Select Frequency for addon  " + item?.Name;
        } else if (item?.Frequency > 1 && item?.RepeatFrequency === 0) {
            errormsg = "Select Repeat Frequency for addon  " + item?.Name;
        } else if (item?.Frequency !== 1 && (paymentData?.CardTypeId === 8 || paymentData?.CardTypeId === -1)){
           errormsg=`Can't use cash/wallet with recurring addons`;
        } else if(startDate.isAfter(currentDate)  && (paymentData?.CardTypeId === 8 || paymentData?.CardTypeId === -1)){
           errormsg=`Can't use cash/wallet payments for pay later/ recurring addons`;
        }
      });
      if(errormsg){
        return errormsg;
      }
    }

    if(finaldata && finaldata?.Result?.[0]?.PaymentGatewayPaymentProfileId === 0 && (!paymentSubmitProfile || !paymentSubmitProfile?.PaymentGatewayPaymentProfileID || paymentSubmitProfile?.PaymentGatewayPaymentProfileID ===0)){
      errormsg = "Please select payment profile.";
      return errormsg;
    }

    if (type === "submit") {
      if (signature === null || !signature) {
        errormsg = "Please provide Signature";
        return errormsg;
      }
      if(checkTerms && !termsandConditions){
        errormsg=`Please Accept Terms and Conditions`
        return errormsg;
      }
    }

    return errormsg;
  };

  const calculateGrandTotal = () =>{
  const initialValue = 0;

  const addonValue = data?.SelectedAddons.map((itm: any) => {
    const addonCoupons = itm?.Coupon?.map((i: any) => {
      return calculateAddonCouponAmt(i, itm)
    })
    const intialValue = 0

    const sumWithaddoncoupon = addonCoupons?.reduce(
      (accumulator: any, currentValue: any) => accumulator + currentValue,
      intialValue
    );
    if (itm?.IsProrated && itm?.ProratedAmount !== 0) {
      return itm?.ProratedAmount - (sumWithaddoncoupon || 0) > 0 ? itm?.ProratedAmount - (sumWithaddoncoupon || 0) : 0
    }
    else {
      return itm?.Charge - (sumWithaddoncoupon || 0) > 0 ? itm?.Charge - (sumWithaddoncoupon || 0) :0
    }
  })
  const addOnSum = addonValue.reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue,
    initialValue
  );
  return addOnSum > 0 ? addOnSum : 0
  }

  const handleTermsConditions = (val:boolean) => {
    setTermsandConditions(val);
    setTermsandConditionsDialog(false);
  };

  const handleTermsConditionDialog = () => {
    let errorvalidatin = handleValidation("submit",false);
    if (errorvalidatin !== "") {
      props?.handleNotificationMessage(errorvalidatin, "error");
      return;
    }
    setTermsandConditionsDialog(!termsandConditionsDialog);
  };

  //handel submit
  const handleFinalSubmit = async (type: string) => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    let errorvalidatin = handleValidation(type);
    if (errorvalidatin !== "") {
      props?.handleNotificationMessage(errorvalidatin, "error");

      return;
    }
    const selectedAddons=data?.SelectedAddons.map((i:any)=>{
      if(i?.IsTaxSkipped){
      return {...i,CanCollectTaxes:!i?.IsTaxSkipped,Taxes:[],TotalTaxAmount:0}
      }
      return {...i,CanCollectTaxes:!i?.IsTaxSkipped}
    })
    const paymentData= paymentProfile?.find((i:any)=> i?.PaymentGatewayPaymentProfileID ===paymentSubmitProfile?.PaymentGatewayPaymentProfileID)

    let purchaseAmt = (parseFloat(calculatePaidToday()) + calculateTotalTaxAmount(false))
    console.log('purchaseAmt',purchaseAmt);
    console.log('paymentSubmitProfile',paymentSubmitProfile);
    console.log('selectedPaymentProfile',selectedPaymentProfile);
    
    
    if(paymentData?.CardTypeId === 8 && purchaseAmt > paymentData?.Credit && !selectedPaymentProfile?.AlternatePaymentProfileId){
      let defaultPaymentProfile=null
      const existedPP=paymentProfile?.filter((i:any)=> i?.CardTypeId !== -1 && i.CardTypeId !==8)
      if(existedPP?.length > 0){
        defaultPaymentProfile=existedPP[0]
      }
      setShowAdditionalPayment({
        dialog:true,
        selectedPaymentProfile:defaultPaymentProfile
      })
      return true;
    }
    console.log(paymentSubmitProfile,'paymentSubmitProfile')
    console.log(paymentProfile,'paymentProfile')
    console.log(paymentData,'paymentData')
    const req = data;
    req.Result[0].StartDate = data?.planStartDate;
    req.Result[0].PaymentGatewayPaymentProfileId =
      paymentData?.PaymentGatewayPaymentProfileID;
    req.Result[0].MaskedCCNumber = paymentData?.MaskedCCNumber;
    req.Result[0].PaymentProfileId = paymentData?.PaymentProfileID;
    req.Result[0].CustomerProfileId = paymentData?.CustomerProfileID;
    req.Result[0].CardDescription = paymentData?.CardDescription;
    req.Result[0].CardTypeId = paymentData?.CardTypeId;
    req.Result[0].Credit = paymentData?.Credit;
    req.Result[0].SelectedAddons = selectedAddons;
    req.Result[0].Coupon = data?.Coupon;
    req.Result[0].MembershipPlanDetail =
      finaldata?.Result[0]?.MembershipPlanDetail;
    req.Result[0].BillingType = data?.BillingType?.value;
    req.Result[0].MemberNumber = data?.clientdata?.MemberNumber;
    req.Result[0].MemberType = data?.clientdata?.MemberType;
    req.Result[0].MemberName = data?.clientname;
    req.Result[0].Email = data?.clientdata?.Email;
    req.Result[0].Photo = data?.clientdata?.Photo;
    req.Result[0].UserMemberId = data?.clientdata?.UserMemberId || props?.UserMemberId;
    req.Result[0].PrimaryUserMemberId = PrimaryUserMemberId;
    let req_final = finaldata;
    req_final.PrimaryUserMemberId = PrimaryUserMemberId;
    req_final.Result = [];

    if (selectedPaymentProfile?.AlternatePaymentProfileId !== null && paymentData?.CardTypeId === 8) {
      const AmountChargeByAlternatePaymentProfile=CustomToFixed(purchaseAmt-paymentData?.Credit,2)
      
      req.Result[0] = {
        ...req.Result[0],
        AlternatePaymentProfiles: [
          {
            AlternatePaymentProfileId: selectedPaymentProfile?.AlternatePaymentProfileId,
            AmountChargeByAlternatePaymentProfile: AmountChargeByAlternatePaymentProfile,
          }
        ]
      };
    }
    req_final.Result = req.Result;
    req_final.IsPurchaseAddon = true;
    if (type === "agreement") {
      if (signature) {
        req_final.Signature = signature;
      }
      setDisable(true);
      const res = await service.BuildAgreement(req_final);
      setDisable(false);
      if (res?.ResponseCode === 100) {
        let template = await res?.Item?.AgreementContent;
        setAgreementTemplate(template);
        setVisibleAgreement(true);
      } else {
        const errorMsg =
          res?.ErrorMessages.length > 0 ? res?.ErrorMessages[0] : "Error ";
        props?.handleNotificationMessage(errorMsg, "error");
      }
    } else if (type === "submit") {
      setBtnLoading(true);
      req_final.AgreementContent = agreementTemplate;
      req_final.Signature = signature;
      req_final.TermsAndConditionAccepted = true;
      setDisable(true);
      const res = await service.CompleteSignUp(req_final);
      setDisable(false);
      setBtnLoading(false);
      if (res?.ResponseCode === 100) {
        props?.handleNotificationMessage(
          "Addon Created Successfully",
          "success"
        );
        handleRedirection();
      } else {
        const errorMsg =
          res?.ErrorMessages.length > 0 ? res?.ErrorMessages[0] : "Error ";
        props?.handleNotificationMessage(errorMsg, "error");
      }
    }
  };

    const calculateAddonStartDate = (PlanStartDate:any, addonItem:any) => {
      const currentDate = moment(new Date());
      const planStartDate = moment(PlanStartDate);
      const RepeatFrequency = addonItem?.RepeatFrequency;
      let addonStartDate=planStartDate
    
      switch (addonItem?.Frequency) {
        case 1:
          addonStartDate = currentDate;
          break;
        case 2:
          addonStartDate = currentDate.clone().subtract(RepeatFrequency, 'days').add(1,'day');;
          break;
        case 4:
          addonStartDate = currentDate.clone().subtract(RepeatFrequency, 'months').add(1,'day');
          break;
        case 5:
          addonStartDate = currentDate.clone().subtract(RepeatFrequency, 'years').add(1,'day');
          break;
        default:
          addonStartDate = planStartDate;
          break;
      }
    
      return planStartDate.isAfter(addonStartDate) ? planStartDate : addonStartDate;
    };
  const makePaymentProfileAsDefault = async (dataItem: any) => {
    const req = {
      PaymentGatewayPaymentProfileID: dataItem?.PaymentGatewayPaymentProfileID,
      UserMemberId: PrimaryUserMemberId,
      CustomerProfileID:
        dataItem?.CustomerProfileID,
    };
    await axiosRequest.post(
      `PaymentProfile/update-preferred-payment-profile`,
      req,
      {
        successCallBack: async (response: any) => {
          props?.handleNotificationMessage(
            `Selected Payment profile marked as Preferred`,
            "success"
          );
          await fetchPaymentProfiles(PrimaryUserMemberId);
        },
        errorCallBack: (response: any) => {
          props?.handleNotificationMessage(
            response?.response?.data?.Messages?.[0] ||
              response?.data?.Messages?.[0] ||
              "Internal server error",
            "error"
          );
        },
      }
    );
  };


  const handleRedirection = () => {
    props?.goToAddonPage();
  };


  const FrequencyDescription=(addonVal:any)=>{
    let Description=""
    const freq=addonVal?.Frequency
    if(freq===1){
      return `One Time`
    }
    if(addonVal?.RepeatFrequency > 0){
      switch (freq) {
        case 2:
          if(addonVal?.RepeatFrequency > 1){
            Description=`For Every ${addonVal?.RepeatFrequency} Days`
          }else{
            Description=`For Every ${addonVal?.RepeatFrequency} Day`
          }
          break;
        case 4:
          if(addonVal?.RepeatFrequency > 1){
            Description=`For Every ${addonVal?.RepeatFrequency} Months`
          }else{
            Description=`For Every ${addonVal?.RepeatFrequency} Month`
          }
          break;
        case 5:
          if(addonVal?.RepeatFrequency > 1){
            Description=`For Every ${addonVal?.RepeatFrequency} Years`
          }else{
            Description=`For Every ${addonVal?.RepeatFrequency} Year`
          }
          break;
      
        default:
          break;
      } 
    }
    return Description
  }


  const widthOfDevice = window.innerWidth;

  if (loading) {
    return <BuzopsLoader />;
  }

  const maximum = new Date(moment().add(18, "month").format("L"));

  return (
    <>
      <h6 className="mb-2 bz_fs_1_5 pl-0">
        <button
          className="btn btn-primary bz-back-btn btn-sm"
          onClick={() =>
            dispatch(
              SetNavigateToAPageOnButtonClick({ id: 136, parent: false })
            )
          }
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        &nbsp;Purchase Addon
      </h6>
      <div className="plan-details">
        <div className="row">
          <div
            className={
              data?.SelectedAddons?.length !== 0
                ? `col-md-8 col-lg-8`
                : `col-md-12 col-lg-8`
            }
          >
            <div className="card" style={{ padding: "12px" }}>
              <div className="row">
                <div className="row bz-subscribe-addon">
                  <div className="col-md-6">
                    <label>Membership Name</label>
                    <span className="bz-colon">:</span>
                    <p>{data?.planname}</p>
                  </div>

                  <div className="col-md-6">
                    <label>Membership ID</label>
                    <span className="bz-colon">:</span>
                    <p>{data?.planid}</p>
                  </div>
                </div>

                <div className="row">
                  <label>Add on</label>
                  <div>
                    <MultiSelect
                      data={filterAddondata}
                      textField="Name"
                      dataItemKey="AddonTemplateId"
                      placeholder="Please select addon"
                      onChange={(e) => {
                        handleChange(e.value, "addon");
                      }}
                      filterable={true}
                      onFilterChange={(e) => filterAddonChange(e)}
                      allowCustom={false}
                      autoClose={true}
                      className="bz-multiselect-arrow"
                    />
                  </div>
                  <Hint>
                    <span className="form-text text-muted">
                      You may add mutiple Add-Ons to this client account.
                    </span>
                  </Hint>
                </div>
              </div>
            </div>
            {data?.SelectedAddons?.map((val: any, indx: any) => {
              return (
                <div className="card" style={{ padding: "12px" }}>
                  <div
                    style={{
                      height: "40px",
                      background: "#008db9",
                      position: "relative",
                      top: "50%",
                    }}
                  >
                    <h5 style={{ color: "white", margin: "8px" }}>
                      {val?.Name}
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 pb-1">
                        <label>Description*</label>
                        <div>
                          <Input
                            id={`Description`}
                            name={`Description`}
                            style={{ width: "100%" }}
                            value={val?.Description}
                            onChange={(e) => {
                              handleChangeAddonElement(
                                e.target.value,
                                "Description",
                                indx
                              );
                            }}
                            disabled={val?.Name === "Custom" ? false : true}
                          />
                        </div>
                        <Hint>
                          <span className="form-text text-muted">
                            Additional information about the Add-On selected.
                          </span>
                        </Hint>
                      </div>
                      <div className="col-md-6 pb-1">
                        <label>Price*</label>
                        <div>
                          <NumericTextBox
                            id={`price`}
                            name={`price`}
                            min={0}
                            width={"100%"}
                            value={val?.Charge}
                            format={"c2"}
                            onChange={(e) => {
                              handleChangeAddonElement(e.value, "Charge", indx);
                            }}
                            disabled={val?.Name === "Custom" ? false : true}
                          />
                        </div>
                        <Hint>
                          <span className="form-text text-muted">
                            Amount to be charged on every billing cycle.
                          </span>
                        </Hint>
                      </div>
                      <div className="col-md-6 pb-1">
                        <label>Frequency *</label>
                        <div>
                          <DropDownList
                            data={Frequencytype_addon}
                            textField="text"
                            dataItemKey="id"
                            value={
                              Frequencytype_addon.filter(
                                (res: any) => res.id === val?.Frequency
                              )[0] || { text: "Please Select Frequency", id: 0 }
                            }
                            onChange={(e) => {
                              handleChangeAddonElement(
                                e.value,
                                "Frequency",
                                indx
                              );
                            }}
                            disabled={val?.Name === "Custom" ? false : true}
                          />
                        </div>
                        <Hint>
                          <span className="form-text text-muted">
                            The billing frequency for the Add-On.
                          </span>
                        </Hint>
                      </div>
                      <div className="col-md-6 pb-1">
                        <label>Start Date*</label>
                        <div>
                          <DatePicker
                            min={new Date(calculateAddonStartDate(data?.planStartDate,val).format("L"))}
                            max={maximum}
                            value={new Date(val?.StartDate)}
                            className="form-control"
                            onChange={(e) => {
                              handleChangeAddonElement(
                                e.value,
                                "StartDate",
                                indx
                              );
                            }}
                          />
                        </div>
                        <Hint>
                          <span className="form-text text-muted">
                            Choose a date from which you want to start this
                            Add-On.
                          </span>
                        </Hint>
                      </div>
                      {val?.Frequency === 1 ? (
                        <></>
                      ) : (
                        <>
                          <div className="col-md-6 pb-1">
                            <label>End After*</label>
                            <div>
                              <RadioGroup
                                data={endafter_addon}
                                disabled={val?.Name === "Custom" ? false : true}
                                onChange={(e: any) => {
                                  handleChangeAddonElement(
                                    e.value,
                                    "EndType",
                                    indx
                                  );
                                }}
                                value={val?.EndType}
                              />
                            </div>
                            <Hint>
                              <span className="form-text text-muted">
                                Maximum no.of recurring Add-Ons for a billing
                                cycle.
                              </span>
                            </Hint>
                          </div>
                          {val?.EndType === 1 && (
                            <div className="col-md-6 pb-1">
                              <label>No of occurrence*</label>
                              <div>
                                <NumericTextBox
                                  id={`Occurrence`}
                                  name={`Occurrence`}
                                  width={"100%"}
                                  min={1}
                                  value={val?.Occurrence}
                                  onChange={(e) => {
                                    handleChangeAddonElement(
                                      e.value,
                                      "Occurrence",
                                      indx
                                    );
                                  }}
                                  disabled={
                                    val?.Name === "Custom" ? false : true
                                  }
                                />
                              </div>
                            </div>
                          )}
                          <div className="col-md-6 pb-1">
                            <label>Repeat Every*</label>
                            <div>
                              <NumericTextBox
                                id={`repeat`}
                                name={`repeat`}
                                width={"100%"}
                                min={1}
                                value={val?.RepeatFrequency}
                                onChange={(e) => {
                                  handleChangeAddonElement(
                                    e.value,
                                    "RepeatFrequency",
                                    indx
                                  );
                                }}
                                disabled={val?.Name === "Custom" ? false : true}
                              />
                            </div>
                            <Hint>
                              <span className="form-text text-muted">
                                Select the number of times the client would want
                                to repeat the Add-Ons based on frequency.
                              </span>
                            </Hint>
                          </div>
                        </>
                      )}
                      <div className="col-md-6 pb-1">
                        <label>Coupons</label>
                        <div>
                          <MultiSelect
                            data={val?.AllCoupons}
                            value={val?.Coupon}
                            textField="Description"
                            placeholder="Please select coupon"
                            dataItemKey="CouponId"
                            onChange={(e) => {
                              handleChangeAddonCoupon(e.value, indx);
                            }}
                            itemRender={itemRenderCoupon}
                            filterable={true}
                            allowCustom={false}
                            autoClose={false}
                          />
                        </div>
                        <Hint>
                          <span className="form-text text-muted">
                            Select a coupon to apply (if applicable).
                          </span>
                        </Hint>
                      </div>
                      {val?.Frequency === 4 && val?.RepeatFrequency === 1 && (
                        <div className="col-md-6 pb-1">
                          <label>Is Prorated</label>
                          <div>
                            <Checkbox
                              style={{
                                height: "25px",
                                width: "25px",
                              }}
                              onChange={(e) => {
                                handleChangeAddonElement(
                                  e.value,
                                  "IsProrated",
                                  indx
                                );
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            {finaldata &&
              (finaldata?.Result?.[0]?.PaymentGatewayPaymentProfileId === 0 ||
                !finaldata?.Result?.[0]?.PaymentGatewayPaymentProfileId) && (
                <>
                  <PaymentCheckout
                    UserMemberId={props?.UserMemberId}
                    handleSubmit={handleSubmit}
                    handleForm={(val: string) => handleForm(val)}
                    paymentMethod={paymentMethod}
                    selected={selected}
                    staffPage={true}
                    paymentGatewayType={paymentGatewayType}
                    bankFormValues={bankFormValues}
                    cardImageIcon={cardImageIcon}
                    selectedPaymentProfile={selectedPaymentProfile}
                    handleBankSubmit={handleBankSubmit}
                    cardFormValues={cardFormValues}
                    paymentProfile={paymentProfile}
                    handleCardSubmit={handleCardSubmit}
                    cardSubmitBtnLoading={cardSubmitBtnLoading}
                    bankSubmitBtnLoading={bankSubmitBtnLoading}
                    handleChangeSavedCard={(val: any) =>
                      handleChangeSavedCard(val)
                    }
                    handleCardImageIcon={(url: any) => handleCardImageIcon(url)}
                    onSelect={(e: any) => onSelect(e)}
                    makePaymentProfileAsDefault={makePaymentProfileAsDefault}
                  />
                </>
              )}
          </div>
          {data?.SelectedAddons?.length !== 0 && (
            <>
              <div className="col-md-4">
                <div className="card" style={{ borderRadius: "5px" }}>
                  <div
                    style={{
                      height: "auto",
                      background: "#008db9",
                      position: "relative",
                      top: "50%",
                    }}
                  >
                    <h5
                      className="capitaltxt"
                      style={{ color: "white", margin: "8px" }}
                    >
                      Order Summary
                    </h5>
                  </div>

                  <div style={{ padding: "0 8px" }}>
                    <h4>{data?.clientname}</h4>
                    <h6 className="buz-font-600 bz-summary-block">
                      Addon
                    </h6>
                    {data?.SelectedAddons?.length > 0 &&
                      data?.SelectedAddons?.map((val: any, indx: any) => {
                        return (
                          <>
                            <div className="plan-summary-card-row pb-1">
                              <div>
                                <span className="buz-font-600">
                                  {val?.Name}{val?.Frequency > 0 && FrequencyDescription(val) && <small className="text-capitalize">{` (${FrequencyDescription(val)})`}</small>}
                                </span>
                              </div>
                              <div>{CurrencyFormat(val?.Charge)}</div>
                            </div>
                            {val?.IsProrated && val?.ProratedAmount !== 0 && (
                              <div className="plan-summary-card-row pb-2">
                                <div>Prorated Amount</div>
                                <div>
                                  {CurrencyFormat(
                                    val?.ProratedAmount.toFixed(2)
                                  )}
                                </div>
                              </div>
                            )}
                                {moment(new Date()).format("L") <
                            moment(val?.StartDate).format("L") && (
                                  <div className="plan-summary-card-row pb-2">
                                    <div className="planleftCol">
                                      Next EFT
                                    </div>
                                    <div className="planrightCol">
                                    {val?.IsProrated && val?.ProratedAmount !== 0 ? CurrencyFormat(
                                        val?.ProratedAmount?.toFixed(2)) : CurrencyFormat(val?.Charge)}
                                    </div>
                                  </div>
                                )}
                            <>
                              {val?.Coupon?.length > 0 &&
                                val?.Coupon?.map((i: any) => {
                                  return (
                                    <>
                                      <div className="plan-summary-card-row pb-2">
                                        <div>{i?.CouponInformation}</div>
                                        <div>{`(-${CurrencyFormat(
                                          calculateAddonCouponAmt(i, val)
                                        )})
                                            
                                            `}</div>
                                      </div>
                                    </>
                                  );
                                })}
                            </>
                            {Taxes?.length > 0 && val?.CanCollectTaxes && <div
                                  className={"text-left mb-2 buz-text-small buz-font-500"}>
                                  <Switch
                                    checked={!val?.IsTaxSkipped}
                                    defaultChecked={false}
                                    className="pr-1"
                                    onChange={(e) => {
                                      handleChangeAddonElement(
                                        !val?.IsTaxSkipped,
                                        "IsTaxSkipped",
                                        indx
                                      );
                                    }}
                                  />{`Surcharges`}</div>}
                          </>
                        );
                      })}
                    <hr />
                      {checkTaxApplicable()  && (<>
                    <div className="plan-summary-card-row pb-2">
                      <div className="buz-font-600">Total before Surcharges</div>
                      {data?.SelectedAddons?.length > 0 && (
                        <div>{CurrencyFormat(data?.TotalBeforeSurcharges)}</div>
                      )}
                    </div>
                    {calculateTotalTaxAmount() > 0 && (<>
                        <div className={"plan-summary-card-row pb-2"}>
                          <div className="buz-font-600 planleftCol">
                            <Tooltip
                              content={(tooltipProps: any) => {
                                return (
                                  <TooltipContentTemplate
                                    title={tooltipProps.title}
                                    data={data}
                                    TotalTaxAmount={calculateTotalTaxAmount(true)}
                                  />
                                );
                              }}
                              tooltipClassName="bz-tooltip-table"
                            >
                              <a
                                title="Surcharges"
                                style={{
                                  borderBottom: "1px dotted #0d6efd",
                                  cursor: "pointer",
                                }}
                              >
                                {"Surcharges to be collected"}
                              </a>
                            </Tooltip>
                          </div>
                          <div
                            className="planrightCol"
                            style={{ fontWeight: 600 }}
                          >
                            {CurrencyFormat(calculateTotalTaxAmount(true))}
                          </div>
                        </div>
                      </>)
                      }
                      <hr/>
                      </>)}
                      
                    <div className="plan-summary-card-row pb-2">
                      <div className="buz-font-600">Order Total</div>
                      {data?.SelectedAddons?.length > 0 && (
                        <div>
                          {CurrencyFormat(data?.TotalBeforeSurcharges + calculateTotalTaxAmount(true))}
                        </div>
                      )}
                    </div>
                    <div className={"plan-summary-card-row pb-2"}>
                      <div className="buz-font-600 planleftCol">{'Down Payment'}</div>
                      <div
                        className="planrightCol"
                        style={{ fontWeight: 600 }}
                      >
                      {CurrencyFormat(parseFloat(calculatePaidToday()) + calculateTotalTaxAmount(false))}
                      </div>
                    </div>
                    {PaidLaterAddonsLength() > 0 &&
                        <h6 className="buz-font-400 bz-summary-block">
                          Pay On
                        </h6>
                    }
                    {PaidLaterAddonsLength() > 0 && (
                        data?.SelectedAddons?.map((itm: any) => {
                          if(moment(new Date()).format("L") <
                          moment(itm?.StartDate).format("L")){
                            const addonCoupons = itm?.Coupon?.map((i:any)=>{
                              return calculateAddonCouponAmt(i,itm)
                            })
                            const intialValue = 0 
                            const sumWithInitial = addonCoupons?.reduce(
                                (accumulator: any, currentValue: any) => accumulator + currentValue,
                                intialValue
                              );
                              return (
                                <div className="plan-summary-card-row pb-3">
                                  <div className="buz-font-600 planleftCol">
                                    {`${itm?.Name} - ${itm?.StartDate} `}
                                  </div>
                                  <div
                                    className="planrightCol"
                                    style={{ fontWeight: 600 }}
                                  >
                                    {itm?.IsProrated &&
                                    itm?.ProratedAmount !== 0
                                      ? itm?.ProratedAmount?.toFixed(2) -
                                          (sumWithInitial || 0)+itm?.PredictedTotalTaxAmount >
                                        0
                                        ? CurrencyFormat(
                                            itm?.ProratedAmount?.toFixed(2) -
                                              (sumWithInitial || 0) +itm?.PredictedTotalTaxAmount
                                          )
                                        : CurrencyFormat(0)
                                      : itm?.Charge - (sumWithInitial || 0) +itm?.PredictedTotalTaxAmount > 0
                                      ? CurrencyFormat(
                                          itm?.Charge - (sumWithInitial || 0) +itm?.PredictedTotalTaxAmount
                                        )
                                      : CurrencyFormat(0)}
                                  </div>
                                </div>
                              );
                          }else{
                            return (<></>)
                          }
                        })
                      )}
                    {visibleAgreement && <div className="k-overlay"></div>}
                    <BuzopsWindow
                      key={visibleAgreement.toString()}
                      reduceWidth={widthOfDevice < 800 ? 10 : 430}
                      onWindowClose={() => setVisibleAgreement(false)}
                      title={"Agreement"}
                      className={"bzo-window bzo-agreement-window"}
                      visibility={visibleAgreement}
                    >
                      <div
                        className="bzo-agreement"
                        dangerouslySetInnerHTML={{ __html: agreementTemplate }}
                      />
                    </BuzopsWindow>
                    {visibleSignPad && (
                      <SignaturePad
                        staffPage={props?.staffPage}
                        generatedImage={(imageString, text, font, typeVal) =>
                          generatedImage(imageString, text, font, typeVal)
                        }
                        key={visibleSignPad.toString()}
                        onClose={() => setVisibleSignPad(false)}
                      />
                    )}
                  </div>
                </div>

                <div>
                  <div
                    className={
                      signatureType === "type"
                        ? `buz-signature-type mt-3`
                        : `buz-signature mt-3`
                    }
                    onClick={() => {
                      handleSignaturePad();
                    }}
                    style={{ display: "flex", justifyContent: "left" }}
                  >
                    {signature !== "" ? (
                      signatureText && signatureFont ? (
                        <span
                          style={{
                            fontFamily: signatureFont,
                            alignSelf: "center",
                            fontSize:
                              signatureType === "type" ? `25px` : `36px`,
                            overflow: "visible",
                            padding: signatureType === "type" ? `2px` : `0px`,
                          }}
                        >
                          {signatureText}
                        </span>
                      ) : (
                        <img
                          src={signature}
                          placeholder={"Please Sign"}
                          width={"100%"}
                          height={"100%"}
                          onClick={() => {
                            handleSignaturePad();
                          }}
                        ></img>
                      )
                    ) : (
                      <div className="buz-signature-pad">
                          <img src={bzImages.SignHereImg} alt="Sign-here"/>
                      </div>
                    )}
                  </div>
                  <p
                    className={
                      "text-left mb-1 buz-text-small buz-font-500 agree-copy"
                    }
                  >
                    {disable ? (
                      <>
                        <a className={"disable_link"}>
                          View Your Agreement Copy
                        </a>
                      </>
                    ) : (
                      <>
                        <a
                          className={"buzops_link "}
                          onClick={() => handleFinalSubmit("agreement")}
                        >
                          View Your Agreement Copy
                        </a>
                      </>
                    )}
                  </p>
                  <div
                      className={"text-left mb-4 buz-text-small buz-font-500"}
                    >
                      <Checkbox
                        checked={termsandConditions}
                        defaultChecked={false}
                        disabled={disable}
                        onChange={() => handleTermsConditionDialog()}
                      />{" "}
                      I agree to the{" "}
                      <span
                        className={
                          "mb-1 buz-text-small buz-font-600 agree-copy"
                        }
                      >
                        {disable ? (
                          <>
                            <a className={"disable_link"}>
                              {"Terms & Conditions"}
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              className={"buzops_link "}
                              onClick={() => handleTermsConditionDialog()}
                            >
                              {"Terms & Conditions"}
                            </a>
                          </>
                        )}
                      </span>
                    </div>
                  <BuzopsButton
                    loading={btnLoading}
                    disabled={btnLoading || disable}
                    label={"Submit"}
                    onClick={() => handleFinalSubmit("submit")}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {termsandConditionsDialog && <TermsAndConditions handleTermsConditionDialog={handleTermsConditionDialog} handleTermsConditions={handleTermsConditions} />}
      {showAdditionalPayment?.dialog &&
        <AdditionalPayment
          fetchPaymentProfiles={fetchPaymentProfiles}
          userMemberId={props?.UserMemberId}
          setShowAdditionalPayment={setShowAdditionalPayment}
          paymentProfile={paymentProfile}
          PurchaseAmount={(parseFloat(calculatePaidToday()) + calculateTotalTaxAmount(false))}
          CreditAmount={paymentSubmitProfile?.Credit}
          showAdditionalPayment={showAdditionalPayment}
          handleChangeAdditionalPaymentProfile={handleChangeAdditionalPaymentProfile}
          handleAddAdditionalPayment={handleAddAdditionalPayment}
        />}
    </>
  );
};
export default withNotification(AssignAddon);
