import { Button } from "@progress/kendo-react-buttons";
import { SelectedLocationType } from "components/class/ClassTypes";
import LocationDD from "components/class/LocationDD";
import { renderFrequencyItem } from "components/package/PurchasePackageList";
import BuzopsButton from "generic-components/BuzopsButton";
import moment from "moment";
import React from "react";
import { CurrencyFormat, getFullImageUrl } from "utils";
import {
  PackageFrequency,
  PackageFrequencyEnum,
  PrivateServiceTypeEnum,
  ScheduleAppointmentkeys,
  SessionTypeEnum,
} from "utils/form-utils";
import { IPropValues } from "./ScheduleAppointment";
import ClientCreation from "components/booking/ClientCreation";
import { LocationForBooking } from "components/Location/Location";
import BuzopsLongNameHandler from "generic-components/BuzopsLongNameHandler";
import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
interface ISessionSignoffLeftCard {
  userDetails?: any;
  mainState?: any;
  propValues?: IPropValues;
  schedulerCalendarState?: any;
  handleChange: (name: ScheduleAppointmentkeys, val: any) => void;
  handleChangeClient: () => void;
  handleFieldChange?: (name: string, val: any) => void;
  handleNavigateBack: () => void;
}
const BackButtonComponent = (props: any) => {
  return (
    <button
      type="button"
      className="btn btn-primary buz-back-btn btn-sm mb-1"
      onClick={() => props?.onClick()}
    >
      <i className="fas fa-arrow-left"></i>
    </button>
  );
};
const SessionSignoffLeftCard = ({
  userDetails,
  mainState,
  propValues,
  handleNavigateBack,
  schedulerCalendarState,
  handleChange,
  handleChangeClient,
  handleFieldChange,
}: ISessionSignoffLeftCard) => {
  const restrictActions =
    schedulerCalendarState?.updateAppointment || propValues?.welcomePage;
  const renderClientDetails = () => {
    return (
      <div className="d-flex align-items-center mb-3 border clientborder  p-3">
        <img
          width={38}
          height={38}
          alt={"userImage"}
          style={{ borderRadius: "50%" }}
          src={
            userDetails?.Photo
              ? getFullImageUrl(userDetails?.Photo)
              : "/images/user.png"
          }
        />
        <div
          className="ml-3 d-flex justify-content-between"
          style={{ width: "100%" }}
        >
          <div>
            <span className="appttitle">{userDetails?.FullName}</span>
            <br />
            {!restrictActions &&
              (propValues?.calendarPage || propValues?.quicklinks) && (
                <span
                  className="btn btn-link"
                  onClick={() => handleChangeClient()}
                >
                  Change Client
                </span>
              )}
          </div>
          {/* {!restrictActions && mainState?.AvailablePackages?.length > 0 ? (
            <div className="justify-self-end">
              {!mainState?.ShowPurchasedPackages && !mainState?.Package ? (
                <Button
                  onClick={() => handleChange("ShowPurchasedPackages", true)}
                >
                  Use Package
                </Button>
              ) : (
                <BuzopsButton
                  label={"Using Package"}
                  onClick={() => handleChange("ShowPurchasedPackages", false)}
                />
              )}
            </div>
          ) : null} */}
        </div>
      </div>
    );
  };

  const renderAppointmentDetails = () => {
    return (
      <div className="mb-3 border p-3 clientborder">
        <div className="d-flex justify-content-between">
          <div className="apptinfoLeft">
            <span className="appttitle">
              {" "}
              <BuzopsLongNameHandler
                name={mainState?.Appointment?.Name}
                maxLength={24}
                className="taxName-tooltip"
              />
            </span>
            <br />
            {/* {mainState?.Appointment?.PrivateServiceType}{" "} */}
            {(mainState?.Appointment?.PrivateServiceType ===
              PrivateServiceTypeEnum.PrivatePaid ||
              mainState?.Appointment?.PrivateServiceType ===
                PrivateServiceTypeEnum.PrivateUnpaid) &&
              "Private"}
            {(mainState?.Appointment?.PrivateServiceType ===
              PrivateServiceTypeEnum.SemiPrivatePaid ||
              mainState?.Appointment?.PrivateServiceType ===
                PrivateServiceTypeEnum.SemiPrivateUnpaid) &&
              "Semi-Private"}
          </div>
          <div>{mainState?.Appointment?.DurationInMinutes} mins</div>
        </div>
        {mainState?.Appointment?.Attributes && (
          <div className="mb-3">
            {!propValues?.staffPage &&
            !propValues?.calendarPage &&
            !propValues?.quicklinks ? (
              <div className="text-muted d-flex align-items-center location-blk-text calendardesktop">
                <LocationForBooking
                  Attributes={mainState?.Appointment?.Attributes}
                  showDetails={false}
                  showToolTip={
                    propValues?.staffPage ||
                    propValues?.calendarPage ||
                    propValues?.quicklinks
                  }
                  VirtualMeetingLink={
                    schedulerCalendarState?.updateAppointment === true
                      ? mainState?.Appointment?.VirtualMeetingLink
                      : mainState?.Provider?.VirtualMeetingLink
                  }
                  userDetails={userDetails}
                  clientPhnNum={userDetails?.Phone}
                />
              </div>
            ) : (
              <LocationDD
                width={"100%"}
                label={true}
                Phone={userDetails?.Phone}
                disabled={false}
                Attributes={mainState?.Appointment?.Attributes}
                showPhoneCallIWillCall={true}
                handleUpdateLocation={(
                  selectedLocation: SelectedLocationType
                ) => handleUpdateLocation(selectedLocation)}
              />
            )}
          </div>
        )}
        {!restrictActions && (
          <div className="d-flex justify-content-end">
            <span
              className="btn btn-link"
              onClick={() => handleChange("Appointment", null)}
            >
              Change Appt
            </span>
          </div>
        )}
      </div>
    );
  };
  const renderPurchasePackage = () => {
    const recurrenceUnit = PackageFrequency?.find(
      (i: any) => i?.id === mainState?.PurchasePackage?.Frequency
    );
    return (
      <div className="mb-3 border p-3 clientborder">
        <div className="d-flex justify-content-between">
          <div className="apptinfoLeft">
            <span className="appttitle">
              {" "}
              <BuzopsLongNameHandler
                name={mainState?.PurchasePackage?.Name}
                maxLength={24}
                className="taxName-tooltip"
              />
            </span>
            <br />
            {mainState?.PurchasePackage?.SessionType !==
            SessionTypeEnum.PRESET ? (
              <>
                {mainState?.PurchasePackage?.Frequency ===
                PackageFrequencyEnum.ONETIME ? (
                  <>
                    <span>
                      {mainState?.PurchasePackage?.ExpireOn === 8
                        ? "Expire On"
                        : "Expire After"}
                      :{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {renderFrequencyItem(mainState?.PurchasePackage, true)}
                      </span>
                    </span>
                  </>
                ) : (
                  <>
                    <span>Frequency</span>
                    <span
                      className="bz-provider"
                      style={{ textTransform: "capitalize" }}
                    >
                      {renderFrequencyItem(mainState?.PurchasePackage, true)}
                    </span>
                  </>
                )}
              </>
            ) : (
              <>
                {recurrenceUnit?.text !== "ONETIME"
                  ? `Down Payment: ${CurrencyFormat(
                      mainState?.PurchasePackage?.DownPayment
                    )}, Installments: ${
                      mainState?.PurchasePackage?.Occurrence === 0
                        ? "NA"
                        : mainState?.PurchasePackage?.Occurrence
                    }, Recurrence Unit: ${
                      recurrenceUnit?.text
                    }, Recurring every: ${
                      mainState?.PurchasePackage?.Schedules *
                        mainState?.PurchasePackage?.Cost ===
                      mainState?.PurchasePackage?.DownPayment
                        ? "NA"
                        : mainState?.PurchasePackage?.RepeatFrequency
                    }`
                  : `Down Payment: ${CurrencyFormat(
                      mainState?.PurchasePackage?.Schedules *
                        mainState?.PurchasePackage?.Cost
                    )}`}
              </>
            )}
          </div>
          <div>
            {mainState?.PurchasePackage?.SessionType === 1
              ? "Limited"
              : mainState?.PurchasePackage?.SessionType === 2
              ? "Unlimited"
              : "Pre-set"}
          </div>
        </div>
        {!restrictActions && (
          <div className="d-flex justify-content-end">
            <span
              className="btn btn-link"
              onClick={() => handleChange("PurchasePackage", null)}
            >
              Change Package
            </span>
          </div>
        )}
      </div>
    );
  };



  const getCurrentBillingPeriod = (billingPeriods: any) => {
    const now = moment();
    return billingPeriods?.find((period: any) => {
      const start = moment(period.ExpireFrom);
      const end = moment(period.ExpireOn).endOf("day");
      return now.isBetween(start, end, null, "[]");
    });
  };

  const renderPurchasedPackage = () => {
    console.log(mainState?.Package, "mainState?.Package");

    const currentBillingPeriod = getCurrentBillingPeriod(
      mainState?.Package?.BillingPeriods
    );
    const schedulesToUse = currentBillingPeriod
      ? currentBillingPeriod.SchedulesToUse
      : mainState?.Package?.SchedulesToUse;
    return (
      <div className="mb-3 border p-3 clientborder">
        <div className="d-flex justify-content-between">
          <div className="apptinfoLeft">
            <span className="appttitle">
              {" "}
              <BuzopsLongNameHandler
                name={mainState?.Package?.Name}
                maxLength={24}
                className="taxName-tooltip"
              />
            </span>
            <br />
            {propValues?.Complimentary ? (
              <span className={`bzo-status-Complimentary`}>
                {"Complimentary"}
              </span>
            ) : (
              <>
                {mainState?.Package?.SessionType === SessionTypeEnum.PRESET && (
                  <span>
                    Redeemed:{" "}
                    <small>
                      {mainState?.Package.SessionType === 2
                        ? "Unlimited"
                        : `${mainState?.Package?.SchedulesUsed} of ${
                            schedulesToUse + mainState?.Package?.SchedulesUsed
                          }`}
                    </small>
                  </span>
                )}
              </>
            )}
            
            {mainState?.Package?.SessionType === SessionTypeEnum.PRESET && (
              <>
                <br />
                <small className="package-expiry">
                  {`${
                    mainState?.Package?.ExpireFrom === null
                      ? "N/A"
                      : moment(mainState?.Package?.ExpireFrom).format(
                          "MM/DD/YYYY"
                        )
                  } to ${
                    mainState?.Package?.ExpireOn === null ||
                    mainState?.Package?.PackageExpireOn === null ||
                    mainState?.Package?.SessionType ===
                      SessionTypeEnum.PRESET ||
                    moment(mainState?.Package?.ExpireOn).year() === 2099
                      ? "N/A"
                      : moment(mainState?.Package?.ExpireOn).format(
                          "MM/DD/YYYY"
                        )
                  }`}
                </small>
              </>
            )}
          </div>
          <div className="d-flex flex-column align-items-end">
            <div>
              {mainState?.Package?.SessionType === SessionTypeEnum.LIMITED
                ? "Limited"
                : mainState?.Package?.SessionType === SessionTypeEnum.UNLIMITED
                ? "Unlimited"
                : "Pre-set"}
            </div>
            {mainState?.Appointment &&
              mainState?.Package?.SessionType === SessionTypeEnum.PRESET && (
                <div>{mainState?.Appointment?.DurationInMinutes} mins</div>
              )}
          </div>
        </div>
        {mainState?.Appointment?.Attributes &&
          mainState?.Package?.SessionType === SessionTypeEnum.PRESET && (
            <div className="mb-3">
              {!propValues?.staffPage &&
              !propValues?.calendarPage &&
              !propValues?.quicklinks ? (
                <div className="text-muted d-flex align-items-center location-blk-text calendardesktop">
                  <LocationForBooking
                    Attributes={mainState?.Appointment?.Attributes}
                    showDetails={false}
                    showToolTip={
                      propValues?.staffPage ||
                      propValues?.calendarPage ||
                      propValues?.quicklinks
                    }
                    VirtualMeetingLink={
                      schedulerCalendarState?.updateAppointment === true
                        ? mainState?.Appointment?.VirtualMeetingLink
                        : mainState?.Provider?.VirtualMeetingLink
                    }
                    userDetails={userDetails}
                    clientPhnNum={userDetails?.Phone}
                  />
                </div>
              ) : (
                <LocationDD
                  width={"100%"}
                  label={true}
                  Phone={userDetails?.Phone}
                  disabled={false}
                  Attributes={mainState?.Appointment?.Attributes}
                  showPhoneCallIWillCall={true}
                  handleUpdateLocation={(
                    selectedLocation: SelectedLocationType
                  ) => handleUpdateLocation(selectedLocation)}
                />
              )}
            </div>
          )}
        {!restrictActions && (
          <div className="d-flex justify-content-end">
            <span
              className="btn btn-link"
              onClick={() => handleChange("Package", null)}
            >
              Change Package
            </span>
          </div>
        )}
      </div>
    );
  };

  const renderGuestDetails = () => {
    //return <ClientCreation onChange={handleFieldChange} />;
  };

  const handleUpdateLocation = (selectedLocation: SelectedLocationType) => {
    handleChange("Appointment", {
      ...mainState.Appointment,
      Attributes: selectedLocation,
    });
  };
  return (
    <div className="ScheduleAppointmentLeftCard">
      <div className="mt-3">
        <div className="card border-0">
          <div className="card-body p-0">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="card-title bz-text-capitalize bz-fw-6 ftclient mb-0">
                Client
              </h5>
              {/* {!restrictActions && (
                <div>
                  {!mainState?.AddPackage ? (
                    <Button onClick={() => handleChange("AddPackage", true)} className="k-button k-primary mr-0">
                      + Add Package
                    </Button>
                  ) : (
                    <BuzopsButton
                      label={"+ Add Package"}
                      onClick={() => handleChange("AddPackage", false)}
                    />
                  )}
                </div>
              )} */}
            </div>
            {userDetails && !propValues?.welcomePage && renderClientDetails()}
            {propValues?.welcomePage && renderGuestDetails()}
            {mainState?.PurchasePackage && renderPurchasePackage()}
            {mainState?.Package && renderPurchasedPackage()}
            {mainState?.Appointment &&
              mainState?.Package?.SessionType !== SessionTypeEnum.PRESET &&
              renderAppointmentDetails()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionSignoffLeftCard;
