import moment from "moment";
import React from "react";
import { getDateFormatWithDay, getFullDate } from "utils";

interface ISelectedSlotListCard {
  mainState?: any;
  schedulerCalendarState?: any;
  refs?: any;
  callBacks?: any;
  checkoutPage: boolean;
}
const compareDates = (a: any, b: any) => {
  // Compare dates first
  if (a.date < b.date) return -1;
  if (a.date > b.date) return 1;
  // If dates are equal, compare times
  if (a.time < b.time) return -1;
  if (a.time > b.time) return 1;
  return 0;
};

const getSlotsSortByDate = (Items: any) => {
  const sortedSlots = Items.sort(compareDates);
  const groupedSlots = sortedSlots.reduce((acc: any, slot: any) => {
    if (!acc[slot.date]) {
      acc[slot.date] = [];
    }
    acc[slot.date].push(slot);
    return acc;
  }, {});
  return groupedSlots;
};
const getRecurrenceSlotsSortByDate = (Items: any) => {
  const sortedSlots = Items.sort(compareDates);
  const groupedSlots = sortedSlots.reduce((acc: any, slot: any) => {
    const date = moment(slot.StartDateTime).format("MM/DD/YYYY");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(slot);
    return acc;
  }, {});
  return groupedSlots;
};

const SelectedSlotListCard = ({
  callBacks,
  mainState,
  schedulerCalendarState,
  refs,
  checkoutPage,
}: ISelectedSlotListCard) => {
  const selectedSlotList = () => {
    // const result=getSlotsSortByDate(schedulerCalendarState?.selectedSchItems)

    // return Object.keys(result).map((date) => {
    //   // if (
    //   //   moment(date)?.format("L") ===
    //   //   moment(schedulerCalendarState?.getDateValue)?.format("L") && !checkoutPage
    //   // ) {
    //   //   return <></>;
    //   // }
    //   return (
    //     <div key={date}>
    //       <span className="small text-muted bz-text-capitalize">
    //         {getFullDate(date)}
    //       </span>
    //       {result[date].map((item: any) => {
    //         const className = item.IsAvailable ? "available" : "not-available";
    //         let description = "Available";
    //         if (!item.IsAvailable) {
    //           if (item.Description !== "") {
    //             description = item.Description;
    //           } else {
    //             description = "Provider not available";
    //           }
    //         }
    //         return (
    //           <div className="card bz-shadow bz-border-blue bz-upcoming-apponments-sm-card mb-3">
    //             <div className="card-body py-2">
    //               <div className="media pr-4">
    //                 <div className="media-body">
    //                   <div style={{ lineHeight: 1.2 }}>
    //                     {/* <span className="text-muted mb-0">
    //                       {" "}
    //                       {moment(item.date).format("MM/DD/YYYY")}
    //                     </span> */}
    //                     <div className="mt-0 mb-0">
    //                       <strong>{item.time}</strong>
    //                     </div>
    //                     <small
    //                       className={`${className}`}
    //                       style={{ fontSize: "13px", fontWeight: "500" }}
    //                     >
    //                       {description}
    //                     </small>
    //                   </div>
    //                 </div>
    //               </div>
    //               <span
    //                 className="bz-close-icon"
    //                 role={"button"}
    //                 onClick={() => callBacks?.handleDeleteSlot(item)}
    //               >
    //                 <i className="far fa-trash-alt"></i>
    //               </span>
    //               {/* )} */}
    //             </div>
    //           </div>
    //         );
    //       })}
    //     </div>
    //   );
    // });

    return schedulerCalendarState?.selectedSchItems?.map(
      (item: any, index: number) => {
        const className = item.IsAvailable ? "available" : "not-available";
        let description = "Available";
        if (!item.IsAvailable) {
          if (item.Description !== "") {
            description = item.Description;
          } else {
            description = "Provider not available";
          }
        }
        return (
          <div className="card bz-shadow bz-border-blue bz-upcoming-apponments-sm-card mb-3">
            <div className="card-body py-2">
              <div className="media pr-4">
                <div className="media-body">
                  <div style={{ lineHeight: 1.2 }}>
                    <span className="text-muted mb-0">
                      {" "}
                      {moment(item.date).format("MM/DD/YYYY")}
                    </span>
                    <div className="mt-0 mb-0">
                      <strong>{item.time}</strong>
                      {/* {(privateServiceType === "SemiPrivateUnpaid" || privateServiceType === "SemiPrivatePaid") && (
                      <span className="badge badge-status-Expired">{item.AvailableSlots}</span>
                    )}  */}
                    </div>
                    <small
                      className={`${className}`}
                      style={{ fontSize: "13px", fontWeight: "500" }}
                    >
                      {description}
                    </small>
                    {/* {privateServiceType === "SemiPrivateUnpaid" || privateServiceType === "SemiPrivatePaid" && (
                    <span className="badge badge-status-Expired">{item.AvailableSlots}</span>
                  )} */}
                  </div>
                </div>
              </div>
              {/* {!checkout && ( */}
              <span
                className="bz-close-icon"
                role={"button"}
                onClick={() => callBacks?.handleDeleteSlot(item)}
              >
                <i className="far fa-trash-alt"></i>
              </span>
              {/* )} */}
            </div>
          </div>
        );
      }
    );
  };
  const selectedRecurrenceList = () => {
    const recurrenceSchedules = mainState?.recurrenceSchedules.filter(
      (item: any) => !item.IsNeedToDelete
    );
    // const result=getRecurrenceSlotsSortByDate(recurrenceSchedules)
    // return Object.keys(result).map((date) => {
    //   // if (
    //   //   moment(date)?.format("L") ===
    //   //   moment(schedulerCalendarState?.getDateValue)?.format("L") && !checkoutPage
    //   // ) {
    //   //   return <></>;
    //   // }
    //   return (
    //     <div key={date}>
    //       <span className="small text-muted bz-text-capitalize">
    //         {getFullDate(date)}
    //       </span>
    //       {result[date].map((item: any) => {
    //         const className = item.IsAvailable ? "available" : "not-available";
    //         let description = "Available";
    //         if (!item.IsAvailable) {
    //           if (item.Description !== "") {
    //             description = item.Description;
    //           } else {
    //             description = "Provider not available";
    //           }
    //         }
    //         const date = moment(item.StartDateTime).format("MM/DD/YYYY");
    //         const time = moment(item.StartDateTime).format("h:mm A");
    //         return (
    //           <div className="card bz-shadow bz-border-blue bz-upcoming-apponments-sm-card mb-3">
    //             <div className="card-body py-2">
    //               <div className="media pr-4">
    //                 <div className="media-body">
    //                   <div style={{ lineHeight: 1.2 }}>
    //                     {/* <span className="text-muted mb-0">
    //                       {" "}
    //                       {moment(item.date).format("MM/DD/YYYY")}
    //                     </span> */}
    //                     <div className="mt-0 mb-0">
    //                       <strong>{time}</strong>
    //                     </div>
    //                     <small
    //                       className={`${className}`}
    //                       style={{ fontSize: "13px", fontWeight: "500" }}
    //                     >
    //                       {description}
    //                     </small>
    //                   </div>
    //                 </div>
    //               </div>
    //               <span
    //                 className="bz-close-icon"
    //                 role={"button"}
    //                 onClick={() => callBacks?.handleDeleteRecurrenceSlot(item)}
    //               >
    //                 <i className="far fa-trash-alt"></i>
    //               </span>
    //             </div>
    //           </div>
    //         );
    //       })}
    //     </div>
    //   );
    // });

    return recurrenceSchedules
      .filter((item: any) => !item.IsNeedToDelete)
      .map((item: any, index: number) => {
        const date = moment(item.StartDateTime).format("MM/DD/YYYY");
        const time = moment(item.StartDateTime).format("h:mm A");
        const className = item.IsAvailable ? "available" : "not-available";
        let description = "Available";
        if (!item.IsAvailable) {
          if (item.Description !== "") {
            description = item.Description;
          } else {
            description = "Provider not available";
          }
        }
        return (
          <div className="card bz-shadow bz-border-blue bz-upcoming-apponments-sm-card mb-3">
            <div className="card-body py-2">
              <div className="media pr-4">
                <div className="media-body">
                  <div style={{ lineHeight: 1.2 }}>
                    <span className="text-muted mb-0"> {date}</span>
                    <div className="mt-0 mb-0">
                      <strong>{time}</strong>
                    </div>
                    <small className={`${className}`}>{description}</small>
                  </div>
                </div>
              </div>
              <span
                className="bz-close-icon"
                role={"button"}
                onClick={() => callBacks?.handleDeleteRecurrenceSlot(index)}
              >
                <i className="far fa-trash-alt"></i>
              </span>
            </div>
          </div>
        );
      });
  };

  return (
    <>
      <div className={"selected-slot-header"}>
        <div>
          {" "}
          <h5 className="card-title mb-0  bz-fw-600">Selected Slots</h5>{" "}
          <span className="small text-muted">
            Number of selected slots: &nbsp;
            {mainState?.recurrenceSchedules.length > 0 &&
              (mainState?.recurrenceDropdownOption?.id !== "doesNotRepeat" ||
                (schedulerCalendarState?.updateAppointment &&
                  schedulerCalendarState?.selectedrescheduleType === 1)) &&
              mainState?.recurrenceSchedules.length}
            {mainState?.recurrenceSchedules.length === 0 &&
              // !mainState?.showAppointment &&
              schedulerCalendarState?.selectedSchItems &&
              schedulerCalendarState?.selectedSchItems.length}
          </span>
        </div>
        <div>
          {" "}
          {!schedulerCalendarState?.checkout && (
            <span
              className=" "
              role={"button"}
              title={"Clear All"}
              onClick={() => callBacks?.handleClearAllSlots()}
            >
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1.2rem" }}
              ></i>
            </span>
          )}
        </div>
      </div>
      <hr className="hrbreak" />
      <div className="bz-selected-appoinment-container" ref={refs.slotRef}>
        {mainState?.recurrenceSchedules.length > 0 &&
          (mainState?.recurrenceDropdownOption?.id !== "doesNotRepeat" ||
            (schedulerCalendarState?.updateAppointment &&
              schedulerCalendarState?.selectedrescheduleType === 1)) &&
          selectedRecurrenceList()}
        {mainState?.recurrenceSchedules.length === 0 &&
          // !mainState?.showAppointment &&
          schedulerCalendarState?.selectedSchItems &&
          selectedSlotList()}
      </div>
    </>
  );
};

export default SelectedSlotListCard;
