import { Button } from "@progress/kendo-react-buttons";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import { Popover } from "@progress/kendo-react-tooltip";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { getFullImageUrl } from "utils";
import { ScheduleAppointmentkeys, SessionTypeEnum } from "utils/form-utils";

interface ISessionSignoffProviderList {
  mainState: any;
  schedulerCalendarState: any;
  name: ScheduleAppointmentkeys;
  handleChange: (name: ScheduleAppointmentkeys, val: any) => void;
  signOffDialog?: boolean;
  refs: any;
  selectedAppt?: any;
  callBacks?: any;
  isDialogVisible?: boolean;
}

const billingPeriodValueRender = (
  element: React.ReactElement<HTMLSpanElement>,
  value: any,
  selectedValue: any,
  selectedPackage:any
) => {
  let children = [<></>];
  if (selectedValue) {
    const schedulesToUse = selectedPackage?.SessionType ===SessionTypeEnum?.LIMITED ?  selectedValue?.SchedulesToUse : "UNLIMITED";
    const schedules = selectedPackage?.SessionType ===SessionTypeEnum?.LIMITED ?  selectedValue?.Schedules : "UNLIMITED";
    children = [
      <div className={"row align-items-center w-100"}>
        <span className="primary-heading">
          {`${selectedValue.ExpireFrom === null
            ? "N/A"
            : moment(selectedValue.ExpireFrom).format("MMM D")
          } - ${selectedValue.ExpireOn === null
            ? "N/A"
            : moment(selectedValue.ExpireOn).format("MMM D, YYYY")
          }`}
        </span>
        <br />
        <small className="package-schedules ">
        {schedulesToUse !=="UNLIMITED"?`(${schedulesToUse} unused sessions of ${schedules})`:`Available Appointments: ${schedulesToUse}`}
        </small>
      </div>,
    ];
  } else {
    children = [
      <div className={"row align-items-center w-100"}>
        <span>Select a Time Period</span>
      </div>,
    ];
  }

  return React.cloneElement(element, { ...element.props }, children);
};

const billingPeriodItemRender = (
  li: React.ReactElement<HTMLLIElement>,
  itemProps: ListItemProps,
  selectedPackage:any
) => {
  const item = itemProps.dataItem;
  const schedulesToUse = selectedPackage?.SessionType ===SessionTypeEnum?.LIMITED ?  item?.SchedulesToUse : "UNLIMITED";
  const schedules = selectedPackage?.SessionType ===SessionTypeEnum?.LIMITED ?  item?.Schedules : "UNLIMITED";
  const itemChildren = (
    <div className={"row align-items-center w-100"}>
      <span className="primary-heading">
        {`${item.ExpireFrom === null
          ? "N/A"
          : moment(item.ExpireFrom).format("MMM D")
        } - ${item.ExpireOn === null
          ? "N/A"
          : moment(item.ExpireOn).format("MMM D, YYYY")
        } `}
      </span>
      <br />
      <small className="package-schedules ">
      {schedulesToUse !=="UNLIMITED"?`(${schedulesToUse} unused sessions of ${schedules})`:`Available Appointments: ${schedulesToUse}`}
      </small>
    </div>
  );

  return React.cloneElement(li, li.props, itemChildren);
};
const SessionSignoffProviderList = ({
  mainState,
  name,
  schedulerCalendarState,
  handleChange,
  signOffDialog,
  refs,
  selectedAppt,
  callBacks,
  isDialogVisible
}: ISessionSignoffProviderList) => {
  const [popoverIndex, setPopoverIndex] = useState<number | null>(null);
  const [isInteractingwithDatePicker, setisInteractingwithDatePicker] =
    useState(false);
  const popoverRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setPopoverIndex(null);
      }
    };

    if (isInteractingwithDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [popoverRef]);

  const showAddMore = (selectedPackage: any, selectedBillingPeriod: any) => {
    if (selectedPackage?.SessionType === SessionTypeEnum.PRESET) {
      return selectedPackage?.SchedulesToUse - schedulerCalendarState.selectedSchItems.length > 1
    } else if (
      selectedPackage?.SessionType === SessionTypeEnum.UNLIMITED &&
      selectedBillingPeriod
    ) {
      return true;
    } else if (selectedPackage?.SessionType === SessionTypeEnum.LIMITED) {
      return selectedBillingPeriod?.SchedulesToUse > 0;
    }
  };

  if (refs.anchor.current.length !== mainState.providerList.length) {
    refs.anchor.current = Array(mainState.providerList.length)
      .fill()
      .map((_, i) => refs.anchor.current[i] || React.createRef());
  }
const billingPeriods=mainState?.Package?.SessionType=== SessionTypeEnum.LIMITED ? mainState?.Package?.BillingPeriods?.filter(
  (i: any) => i?.SchedulesToUse > 0
) || [] : mainState?.Package?.BillingPeriods

       const findBillingItem=  billingPeriods?.find(
          (i: any) => {
            if (
              selectedAppt?.BillingPeriodFrom?.ExpireFrom &&
              moment(i?.ExpireFrom)?.format("YYYY-MM-DD") ===
                moment(selectedAppt?.BillingPeriodFrom?.ExpireFrom).format("YYYY-MM-DD")
            ) {
              return true
            }
            return false
          }
        );
  return (
    <div className={"my-bookings_booking-body_trainers selectprovider"}>
      <h5 className="bz_fs_1_5 bz-avail-providers">Select a provider</h5>
      {schedulerCalendarState?.providersLoading ? (
        <BuzopsLoader type={"list"} />
      ) : mainState?.providerList.length === 0 ? (
        <div className={"bz-norecords-available text-muted"}>
          No Available Providers{" "}
        </div>
      ) : (
        <div className={"row bz-provider-lists"}>
          {mainState?.providerList.map((item: any, index: number) => {
            const titleLength = item?.Title?.length > 0;
            const descHide = !titleLength && "d-none";
            let selected = false;
            if (
              mainState?.Provider &&
              mainState?.Provider?.AssociateId === item?.AssociateId
            ) {
              selected = true;
            }

            const cardRef = refs.anchor.current[index];
            const dynamicClass = `provider${index}`;
            const popoverPosition =
              window.innerWidth <= 976 ? "top" : index <= 2 ? "bottom" : "top";

            return (
              <div
                className={`${!mainState?.Provider
                  ? "col-sm-6 col-md-12 col-xl-6"
                  : "col-md-12"
                  }`}
                key={item?.AssociateId}
              >
                <div
                  className={`card bz-service-card bz-upcoming-apponments-sm-card bz-trainers-card bz-shadow mb-3 ${selected ? "bz-trainer-selected" : ""
                    } ${dynamicClass}`}
                  onClick={() => {
                    handleChange(name, item);
                    setPopoverIndex(popoverIndex === index ? null : index);
                  }}
                  ref={cardRef}
                >
                  <div className="card-body d-flex align-items-center">
                    <div className="media align-items-center pr-4">
                      <img
                        className="bz-user-img align-self-center w-65 mr-2"
                        alt="UserPic"
                        src={
                          item.Photo
                            ? getFullImageUrl(item.Photo)
                            : "/images/user.png"
                        }
                      />
                      <div className="media-body">
                        <div>
                          <h5 className="mt-0 mb-0">{item.FullName}</h5>
                          <span
                            className={`text-muted mb-0 bz-provider-desc ${descHide}`}
                          >
                            {item?.Title}
                          </span>
                        </div>
                      </div>
                    </div>
                    <span className="bz-arrow-icon">
                      <i className="fas fa-caret-right"></i>
                    </span>
                  </div>
                  {!isDialogVisible && (popoverIndex === index) && (
                    <Popover
                      show={true}
                      anchor={cardRef.current}
                      position={popoverPosition}
                      className={`popoverstaff ${index === 0 ? "popoverstaffirst" : ""
                        }`}
                    >
                      <div
                        className={"dialog-options"}
                        style={{ overflow: "hidden" }}
                        onClick={(e) => e.stopPropagation()}
                        ref={popoverRef}
                      >
                        {mainState?.Package && mainState?.Package?.SessionType !==
                          SessionTypeEnum.PRESET && (
                            <>
                              <div className="mb-3">
                              <div className='d-flex align-items-center'>
                                <small className="mb-1 lablesignoff">
                                  {
                                    "Select a Period with Unused Sessions"
                                  }
                                </small>
                                <BuzopsTooltip
                                position={"right"}
                                html={
                                  <a
                                    title={`Choose the time period where the unused sessions were originally credited. This allows you to sign off and apply them for use.`}
                                    className="pl-2 bz-cursor-pointer"
                                  >
                                    <i
                                      style={{ fontSize: "16px" }}
                                      className="fa fa-info-circle"
                                    ></i>
                                  </a>
                                }
                                                        />
                                                        </div>
                                <DropDownList
                                  data={billingPeriods}
                                  value={selectedAppt?.BillingPeriodFrom}
                                  onChange={(event) =>
                                    callBacks?.handleChangeAppt(
                                      event.value,
                                      "BillingPeriodFrom"
                                    )
                                  }
                                  textField="ExpireFrom"
                                  dataItemKey="ExpireFrom"
                                  itemRender={(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps)=> billingPeriodItemRender(li,itemProps,mainState?.Package)}
                                  valueRender={(
                                    el: React.ReactElement<HTMLSpanElement>,
                                    value: any
                                  ) =>
                                    billingPeriodValueRender(
                                      el,
                                      value,
                                      findBillingItem || selectedAppt?.BillingPeriodFrom,
                                      mainState?.Package
                                    )
                                  }
                                  style={{ width: "100%" }}
                                  className={"customDropdown bz-oneonone-dropdown"}
                                  listNoDataRender={() => (
                                    <span className={"bzo-noData-found"}>
                                      No unused sessions available for sign-off
                                    </span>
                                  )}
                                />
                              </div>
                            </>
                          )}
                        <div className="mb-3">
                          <div style={{ fontSize: "13px" }} className="lablesignoff">Sign Off</div>
                          <DateTimePicker
                            //disabled={disableDate}
                            value={selectedAppt?.signOffDate}
                            max={new Date()}
                            min={mainState?.minDate}
                            adaptive={true}
                            size={"small"}
                            width={"100%"}
                            onChange={(e) => {
                              //e.stopPropagation();
                              let newDate = e.target.value as Date;
                              // Clamp the value if it's outside the allowed range
                              if (newDate > new Date()) {
                                newDate = new Date();
                              }
                              if (newDate < mainState?.minDate) {
                                newDate = mainState?.minDate;
                              }
                              callBacks?.handleChangeAppt(
                                newDate,
                                "signOffDate"
                              );
                              setisInteractingwithDatePicker(true);
                              setPopoverIndex(index);
                            }}

                            className="sessionsignoffcalendar"
                            popupSettings={{
                              popupClass: "custom-calendar-popover",
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <div style={{ fontSize: "13px" }} className="lablesignoff">Notes</div>
                          <textarea
                            className="form-control"
                            onChange={(e) => {
                              callBacks?.handleChangeAppt(
                                e.target.value,
                                "notes"
                              );
                              setPopoverIndex(index);
                            }}
                            value={selectedAppt?.notes}
                          ></textarea>
                        </div>
                        <div className={"row justify-content-end"}>
                          <div className={"col-12 text-right"}>
                            <Button onClick={() => {
                              setPopoverIndex(null)
                              callBacks?.handleCloseSessionSignOff()
                            }}>
                              Cancel
                            </Button>
                            {schedulerCalendarState.selectedSchItems.length >
                              0 && (!mainState?.Package || (mainState?.Package && showAddMore(mainState?.Package,selectedAppt?.BillingPeriodFrom)))
                                && (
                                <Button
                                  primary={false}
                                  onClick={(e) => {
                                    callBacks?.handleAddAppointment(true);
                                    setPopoverIndex(index);
                                  }}
                                >
                                  Add More
                                </Button>
                              )}{" "}
                            &nbsp;
                            <Button
                              primary={true}
                              onClick={(e) => {
                                callBacks?.handleAddAppointment(false);
                                // if (
                                //   mainState?.Package?.SessionType !==
                                //   SessionTypeEnum.PRESET &&
                                //   !selectedAppt?.BillingPeriodFrom
                                // ) {
                                //   setPopoverIndex(index);
                                // } else {
                                // }
                                setPopoverIndex(null);
                              }}
                            >
                              Continue
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Popover>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SessionSignoffProviderList;
