import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Upload, UploadFileInfo, UploadOnAddEvent, UploadOnRemoveEvent } from '@progress/kendo-react-upload';
import withNotification from 'components/Hoc/withNotification';
import { PaymentItemRender, PaymentValueRender } from 'components/Payment/PaymentRenderItems';
import PaymentModalCreation from 'components/PaymentCheckout/PaymentModalCreation';
import BuzopsButton from 'generic-components/BuzopsButton';
import React, { useEffect, useRef, useState } from 'react'
import { useApiClient } from 'services/axios-service-utils';
import { TenantService } from 'services/tenant/index.service';
import { CurrencyFormat, checkIfUserAddPaymentGateway, checkIfUserCanAddBankAccount, paymentDropdownItems } from 'utils';
import { CreditsErrorMessage } from 'utils/form-utils';
import { showWaived } from 'utils/permission-utils';
import { GetLocalStore } from 'utils/storage';


const PayNowDeclinedPayments = (props: any) => {
    const {axiosRequest}=useApiClient()
    const [paynowItem, setPayNowItem] = useState<any>(null);
    const [paymentProfile, setPaymentProfile] = useState<any>([]);
    const payNowRef: any = useRef(null);
    const [selectedPaymentProfile, setSelectedPaymentProfile] =
        useState<any>(undefined);
    const [btnloading, setBtnLoading] = useState<any>(false);
    const [showPaymentDialog, setShowPaymentDialog] = useState(false);
    const [addNewItem, setAddNewItem] = useState<any>(null);
    const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
    const [paymentGatewayType, setPaymentGatewayType] =
      React.useState<any>(paymentGatewaytype);
    const service = new TenantService();


  //upload
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);

  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);


  const constructFileWithBas64 = (records: any) => {
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
        };
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
    }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };

  //


    useEffect(() => {
        fetchDeclinedPayments();
        fetchPaymentProfiles(props?.userDetails?.UserMemberId);
    }, []);

    const fetchDeclinedPayments = async () => {
        const req = {
            UserMemberId: props?.userDetails?.UserMemberId,
            EntityId: props?.transactionData.EntityId,
        };

        const service = new TenantService();

        const result = await service.declinedPayments(req);
        if (result) {
            setPayNowItem(result.Items[0]);
        }
    }

    const makePaymentProfileAsDefault = async (dataItem: any) => {
        const req = {
          PaymentGatewayPaymentProfileID: dataItem?.PaymentGatewayPaymentProfileID,
          UserMemberId: props?.userDetails?.UserMemberId,
          CustomerProfileID:
            dataItem?.CustomerProfileID,
        };
        await axiosRequest.post(
          `PaymentProfile/update-preferred-payment-profile`,
          req,
          {
            successCallBack: async (response: any) => {
              props?.handleNotificationMessage(
                `Selected Payment profile marked as Preferred`,
                "success"
              );
              await fetchPaymentProfiles(props?.userDetails?.UserMemberId);
            },
            errorCallBack: (response: any) => {
              props?.handleNotificationMessage(
                response?.response?.data?.Messages?.[0] ||
                  response?.data?.Messages?.[0] ||
                  "Internal server error",
                "error"
              );
            },
          }
        );
      };

    const fetchPaymentProfiles = async (userMemberId: any,modeVal=null) => {
        const result = await service.paymentProfile(userMemberId);
        if (result) {
            if(modeVal){
                const record=result?.find((i:any)=> i?.CardTypeId ===-1)
                if(record){
                  const data={
                    ...record,
                    CardTypeId: -2,
                    MaskedCCNumber: modeVal,
                    CardDescription: modeVal,
                    CardDescriptionWithoutExpiryDate: modeVal,
                  };
                  const res=[...result]
                  res.push(data)
                  setPaymentProfile(JSON.parse(JSON.stringify(res)))
                  handleChangeSavedCard(data)
                }
              }else {
                setPaymentProfile(result);
                const defaultPP = result.find((card: any) => card.IsDefault);
                if(defaultPP){
                  handleChangeSavedCard(defaultPP)
                }
            }
         
        }
    }



    const togglePayNowDialog = () => {
        //setShowPayNowDialog(false);
        setPayNowItem(null);
        setSelectedPaymentProfile(undefined);
        props?.handleWindow();
    };


    console.log('PaymentProfile', paymentProfile);



    const onCardOrBankAdd = (e: any, memberId: any) => {
        setShowPaymentDialog(true);
        setAddNewItem(e.item.key);
    };

    const handleClosePaymethodDialog = async () => {
        setShowPaymentDialog(!showPaymentDialog);
    };
    const handleSuccessClosePaymethodDialog = async (msg = null,modeVal = null) => {
        if (msg) {
            props?.handleNotificationMessage(msg, "success");
          }
        setShowPaymentDialog(!showPaymentDialog);
        fetchPaymentProfiles(props?.userDetails?.UserMemberId,modeVal);
    };

 


    const PaymethodDialog = () => {
        return (
            <>
                <Dialog
                    className="bz-addCard-dialog"
                    title={`${
                        addNewItem !== "addOther"
                          ? checkIfUserCanAddBankAccount()
                            ? "Add Bank or Card"
                            : "Add Card"
                          : "Add Payment Mode"
                      }`}
                    onClose={() => handleClosePaymethodDialog()}
                    style={{ zIndex: 9999 }}
                >
                    <PaymentModalCreation
                        staffPage={true}
                        addNewItem={addNewItem}
                        paymentProfile={paymentProfile}
                        handleClosePaymethodDialog={handleClosePaymethodDialog}
                        handleSuccessClosePaymethodDialog={
                            handleSuccessClosePaymethodDialog
                        }
                        UserMemberId={props?.userDetails?.UserMemberId}
                    />
                </Dialog>
            </>
        );
    };

    const handleChangeSavedCard = (val: any) => {
        setSelectedPaymentProfile(val);
    };

    const submitUnPaidDues = async () => {
        if (paynowItem?.IsWaivePayment || selectedPaymentProfile) {
            if (
                selectedPaymentProfile &&
                !paynowItem?.IsWaivePayment &&
                selectedPaymentProfile?.CardTypeId === 8 &&
                paynowItem?.Amount > selectedPaymentProfile?.Credit
            ) {
                const errorMsg = CreditsErrorMessage;
                props?.handleNotificationMessage(errorMsg, "error");
                return false;
            }
            setBtnLoading(true);

            let paymentAttributes = null;
            if (selectedPaymentProfile?.CardTypeId === -2) {
              paymentAttributes = {
                Reference: paynowItem.refNumber,
                OfflinePaymentDescription: selectedPaymentProfile?.MaskedCCNumber,
                ReferrenceDocumentName: files[0]?.FileName || "",
                ReferrenceDocumentContent: files[0]?.ImageAsBase64 || "",
              };
            }else if(selectedPaymentProfile?.CardTypeId === -1){
                paymentAttributes = {
                    Reference: "",
                    OfflinePaymentDescription: "Cash",
                    ReferrenceDocumentName: "",
                    ReferrenceDocumentContent: "",
                };
            }
            const req = {
                PaymentAttributes:paymentAttributes,
                PaymentProfileId:
                    selectedPaymentProfile?.PaymentGatewayPaymentProfileID || 0,
                PaymentGatewayTransactionHistoryId:
                    paynowItem?.PaymentGatewayTransactionHistoryId,
                PenaltyAmount: 0,
                Amount: paynowItem?.Amount,
                IsWaivePayment: paynowItem?.IsWaivePayment || false,
                // IsWaivePayment: false,
                EnablePreviousSession: true,
                WaiveComments: paynowItem?.WaiveComments || "",
                // WaiveComments: "",
                PaymentMadeFor: paynowItem?.PaymentMadeFor,
            };
            const res = await service.saveDeclinedPayments(req);
            setBtnLoading(false);
            if (res.ResponseCode === 100) {
                //togglePayNowDialog();
                const successMsg = "Unpaid Dues paid successfully";
                props?.handleNotificationMessage(successMsg, "success");
                props?.onSuccessfullPayment();
                //await fetchAllApis();
            } else {
                const errorMsg =
                    res?.ErrorMessages?.[0] || "Error While Paying Unpaid Dues";
                props?.handleNotificationMessage(errorMsg, "error");
            }
        } else {
            const errorMsg = "Please select payment profile";
            props?.handleNotificationMessage(errorMsg, "error");
        }
    };

    const handlePayChange = (val: any, name: string) => {
        setPayNowItem({ ...paynowItem, [name]: val });
    };
    if (!paynowItem) {
        return <div></div>;
    }
    return (
        <>
            <div>
                <hr />
                <h5 className="mb-3 pt-3 bz_fs_1_5">Pay Unpaid Dues</h5>
                <div className="bz-mt-2rem bz-mb-2_5rem" ref={payNowRef}>
                    <div className="bz-content-text-row">
                        <div className="bz-content-title text-right">Transaction Id</div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">{paynowItem?.TransactionId}</div>
                    </div>
                    <div className="bz-content-text-row">
                        <div className="bz-content-title text-right">Entity Name</div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">{paynowItem?.EntityName}</div>
                    </div>
                    <div className="bz-content-text-row" style={{ fontWeight: 600 }}>
                        <div className="bz-content-title text-right">Amount</div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">
                            {CurrencyFormat(paynowItem?.Amount)}
                        </div>
                    </div>
                    {props?.staffPage && showWaived(paynowItem) ? (
                        <div className="bz-content-text-row">
                            <div className="bz-content-title text-right">
                                Do you want to waive this payment ?
                            </div>
                            <span className="bz-colon text-center">:</span>
                            <div className="bz-content-body">
                                <Switch
                                    checked={paynowItem?.IsWaivePayment || false}
                                    onChange={(e) => handlePayChange(e.value, "IsWaivePayment")}
                                />
                            </div>
                        </div>
                    ) : null}
                    {paynowItem?.IsWaivePayment ? (
                        <div className="bz-content-text-row">
                            <div className="bz-content-title text-right">Comments</div>
                            <span className="bz-colon text-center">:</span>
                            <div className="bz-content-body">
                                <textarea
                                    style={{ maxWidth: "300px", fontSize: "14px" }}
                                    value={paynowItem?.WaiveComments || ""}
                                    onChange={(e) =>
                                        handlePayChange(e.target.value, "WaiveComments")
                                    }
                                ></textarea>
                            </div>
                        </div>
                    ) : (
                        <>
                        <div className="bz-content-text-row">
                            <div className="bz-content-title text-right">Payment Method</div>
                            <span className="bz-colon text-center">:</span>
                            <div className="bz-content-body">
                                <div className="d-flex">
                                    <DropDownList
                                        id={"selectedPaymentProfile"}
                                        name={"selectedPaymentProfile"}
                                        data={paymentProfile}
                                        listNoDataRender={() => "No Payment Methods"}
                                        textField="MaskedCCNumber"
                                        dataItemKey="PaymentProfileID"
                                        className={"bz-payUnpaid-dues-dropdown"}
                                        valueRender={(
                                            el: React.ReactElement<HTMLSpanElement>,
                                            value: any
                                        ) => PaymentValueRender(el, value, selectedPaymentProfile)}
                                        itemRender={(li, itemProps) =>
                                        PaymentItemRender(li, { ...itemProps, makePaymentProfileAsDefault })
                                        }
                                        onChange={(e) => {
                                            handleChangeSavedCard(e.value);
                                        }}
                                    />
                                    {checkIfUserAddPaymentGateway() &&  <div className="addNewBtn pl-1">
                                        <DropDownButton
                                            text="Add New"
                                            onItemClick={(e: any) =>
                                                onCardOrBankAdd(e, props?.userDetails?.UserMemberId)
                                            }
                                            items={paymentDropdownItems()?.filter((i: any) => i?.visible === true)}
                                            look="flat"
                                            className="btn btn-link add-new-btn"
                                        />
                                    </div>}
                                </div>
                            </div>
                        </div>
                        {selectedPaymentProfile?.CardTypeId === -2 && (
                        <>
                          <div className="bz-content-text-row bz-notify-row">
                            <div className="bz-content-title text-right">
                              Transaction Reference Number
                            </div>
                            <span className="bz-colon text-center">:</span>
                            <div className="bz-content-body">
                              <div className={"k-form-field-wrap"}>
                                <Input
                                  onChange={(event) =>
                                    handlePayChange(
                                      event?.target?.value,
                                      "refNumber"
                                    )
                                  }
                                  value={paynowItem.refNumber}
                                  type={"text"}
                                  style={{ width: "280px" }}
                                  id={"refNumber"}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="bz-content-text-row bz-notify-row">
                            <div className="bz-content-title text-right">
                            Reference Document
                            </div>
                            <span className="bz-colon text-center">:</span>
                            <div className="bz-content-body">
                              <div className={"k-form-field-wrap"}>
                                {/* <input type="file" /> */}
                                <Upload
                                  autoUpload={false}
                                  batch={false}
                                  restrictions={{
                                    // allowedExtensions: [".jpg", ".png", ".jpeg"],
                                    maxFileSize: 1000000,
                                  }}
                                  showActionButtons={false}
                                  multiple={false}
                                  onAdd={onAdd}
                                  withCredentials={false}
                                  onRemove={onRemove}
                                  files={originalFiles}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}                
                        </>
                    )}
                    <div className="bz-content-text-row mt-4 pt-2">
                        <div className="bz-content-title text-right"></div>
                        <span className="bz-colon text-center"></span>
                        <div className="bz-content-body">
                            <Button className="ml-0" onClick={() => togglePayNowDialog()}>
                                Cancel
                            </Button>
                            <BuzopsButton
                                primary={true}
                                label={"Submit"}
                                onClick={() => submitUnPaidDues()}
                                disabled={btnloading}
                                loading={btnloading}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {showPaymentDialog && PaymethodDialog()}
        </>
    );
};


export default withNotification(PayNowDeclinedPayments);