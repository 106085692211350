import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { getTimeZoneShortHand } from "utils";
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { LocationForBooking } from "components/Location/Location";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
interface IBookingConfirmation {
  mainState?: any;
  schedulerCalendarState?: any;
  userDetails?: any;
  TenantZone?: any;
  Attributes?: any;
  calenderPage?: boolean;
  guestPages?: boolean;
  quicklinks?: boolean;
  match?: any;
  location?: any;
  handleGoToOverview: () => void;
  handleClearAllStates?: () => void
}
const BookingConfirmation = (props: IBookingConfirmation) => {
  const guestPages = props?.guestPages || props?.location?.state?.guestPages;
  const TenantZone = props?.TenantZone || props?.location?.state?.TenantZone;
  const calenderPage =
    props?.calenderPage || props?.location?.state?.calenderPage;
  const Attributes = props?.Attributes || props?.location?.state?.Attributes;
  const quicklinks = props?.quicklinks || props?.location?.state?.quicklinks;
  const userDetails = props?.userDetails || props?.location?.state?.userDetails;
  const redirectedFrom = props?.location?.state?.redirectedFrom;
  const redirectionObj = useSelector((state: any) => state?.redirection);
  const schedulerCalendarState =
    props?.schedulerCalendarState ||
    props?.location?.state?.schedulerCalendarState;

  const selectedSchItems =
    schedulerCalendarState?.selectedSchItems ||
    props?.location?.state?.selectedSchItems;
  const updateAppointmentData =
    schedulerCalendarState?.updateAppointmentData ||
    props?.location?.state?.updateAppointmentData;
  const mainState = props?.mainState || props?.location?.state?.mainState;
  const history = useHistory();
  const UserMemberId = userDetails?.PrimaryMemberId || userDetails?.ParentId || userDetails?.UserMemberId
  const uuid = props?.match?.params?.uuid;
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    if(!props?.mainState && !props?.location?.state?.mainState){
      history.push('/')
    }
  }, [])
  

  useEffect(() => {
    if (calenderPage || quicklinks) {
      const timer = setInterval(() => {
        if (countdown === 0) {
          clearInterval(timer);
          handleConfirmation();
          // schedulerCalendarState?.updateStatus ? props?.handleGoToOverview() : props?.handleClearAllStates?.()
          // props?.handleClearAllStates?.();
          // navigate(`/?schedulerTab=purchasegroup`);
        } else {
          setCountdown(countdown - 1);
        }
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [countdown]);
  const timezoneShorthand = getTimeZoneShortHand(TenantZone);
  let count = 0;
  let schedules=[];
  if (mainState?.recurrenceDropdownOption.id === "doesNotRepeat") {
    count = selectedSchItems.length;
    schedules = [...selectedSchItems];
  } else {
    const records = mainState?.recurrenceSchedules?.filter(
      (i: any) => i?.IsAvailable === true
    );
    count = records?.length;
    schedules = records?.length ?  [...records]:[];
  }
  let duration = mainState?.Appointment?.Duration;
  if (typeof duration !== 'number') {
    duration = schedulerCalendarState?.updateAppointmentData?.Duration || mainState?.Appointment?.DurationInMinutes;
  }

  let trainerEqual = true;

  if (
    schedulerCalendarState?.updateStatus &&
    updateAppointmentData?.TrainerId !== mainState?.Provider?.AssociateId
  ) {
    trainerEqual = false;
  }
  let dateEqual = true;
  if (
    schedulerCalendarState?.updateStatus &&
    moment(updateAppointmentData?.FromTime).format("MM/DD/YYYY") !==
    moment(selectedSchItems[0].date).format("MM/DD/YYYY")
  ) {
    dateEqual = false;
  }
  let timeEqual = true;
  if (
    schedulerCalendarState?.updateStatus &&
    moment(updateAppointmentData?.FromTime).format("hh:mm A") !==
    selectedSchItems[0].time
  ) {
    timeEqual = false;
  }
  const OldAttributes = updateAppointmentData?.Attributes;
  const OldAttributeValues =
    OldAttributes && OldAttributes !== "" && typeof OldAttributes === "string"
      ? JSON.parse(OldAttributes)
      : OldAttributes;
  const NewAttributeValues =
    Attributes && Attributes !== "" && typeof Attributes === "string"
      ? JSON.parse(Attributes)
      : Attributes;
  let locEqual = true;
  if (
    OldAttributeValues?.ScheduleModeOption !==
    NewAttributeValues?.ScheduleModeOption
  ) {
    locEqual = false;
  }

  const handleConfirmation = () => {
    if (!userDetails) {
      if (redirectionObj?.firstPathValue !== "") {
        if (redirectionObj?.firstPathValue === "external") {
          history.push(
            `/${redirectionObj?.firstPathValue}/${redirectionObj?.secondRoute}`
          );
        } else {
          history.push(`/${redirectionObj?.firstPathValue}`);
        }
      } else {
        history.push(`/`);
      }
    } else {
      if (!redirectionObj?.staffPage) {
        if (redirectionObj?.firstPathValue === "external") {
          history.push(`/${redirectionObj?.firstPathValue}/summary`);
        } else if (calenderPage) {
          props?.handleGoToOverview();
        } else if (quicklinks) {
          history.push(`/member/${uuid}/${UserMemberId}`);
        } else {
          if (redirectedFrom) {
            history.push(`/summary`, { redirectedFrom: redirectedFrom });
          } else {
            history.push(`/summary`);
          }
        }
      } else {
        let urlRedirection = `/member/${redirectionObj?.staffuuid}/${UserMemberId}`;
        if (redirectedFrom) {
          urlRedirection = `/member/${redirectionObj?.staffuuid}/${UserMemberId}/${redirectedFrom}`;
        } else if (
          mainState?.Appointment?.PrivateServiceInstanceId &&
          mainState?.Appointment?.PrivateServiceType === 1
        ) {
          urlRedirection = `/member/${redirectionObj?.staffuuid}/${UserMemberId}/pi-${mainState?.Appointment?.PrivateServiceInstanceId}`;
        } else if (props?.location?.state?.UpdateAppointment) {
          if (updateAppointmentData?.PrivateServiceType === 1) {
            urlRedirection = `/member/${redirectionObj?.staffuuid}/${UserMemberId}/pi-${updateAppointmentData?.EntityId}`;
          } else {
            urlRedirection = `/member/${redirectionObj?.staffuuid}/${UserMemberId}/scheduleoneone`;
          }
        }
        history.push(urlRedirection);
      }
    }
  };

  const selctedLoccationPhone =
    Attributes?.ScheduleMode === 4
      ? "align-items-start location-phone"
      : "align-items-center";

  return (
    <div className="success-component">
      <div
        className={`success-info ${props?.calenderPage ? "display-in-calendar" : ""
          }`}
      >
        <div className="card-content">
          <div
            style={{
              background: "#e9fffa",
              padding: "10px",
              borderRadius: "3px",
              width: "100%",
            }}
          >
            Appointment(s) successfully {schedulerCalendarState?.updateStatus ? `rescheduled` : `booked` }
          </div>
          <div className="align-self-start">
            You have scheduled{" "}
            {!schedulerCalendarState?.updateStatus && (
              <>{`${count} Appointment(s)`}</>
            )}{" "}
            with{" "}
            {!trainerEqual && <del>{updateAppointmentData?.TrainerName}</del>}
            <b>{mainState?.Provider?.FullName}</b>
          </div>
          <h4 className="bz-fw-6">
            {schedulerCalendarState?.updateStatus
              ? mainState?.Appointment?.ServiceName
              : mainState?.Appointment?.Name}
          </h4>
          <div className="text-muted bz-fw-6">
            <i className="fas fa-globe" aria-hidden="true"></i> {TenantZone}
          </div>
          <div className="text-capitalize bz-fw-6 text-muted">
            <i className="fas fa-clock" aria-hidden="true"></i>{" "}
            {`${duration} Minutes`}
          </div>

          {!userDetails ||
            schedulerCalendarState?.updateStatus ||
            schedules?.length === 1 ? (
            <>
              {(!userDetails || selectedSchItems?.length === 1) &&
                !schedulerCalendarState?.updateStatus && (
                  <>
                    <div
                      className={`bz-fw-6 text-muted d-flex location-text-blk ${selctedLoccationPhone}`}
                    >
                      <LocationForBooking
                        Attributes={Attributes}
                        showDetails={true}
                        showToolTip={true}
                        VirtualMeetingLink={
                          mainState?.Provider?.VirtualMeetingLink
                        }
                        linkEnable={true}
                        clientPhnNum={userDetails?.Phone}
                        confirmation={true}
                      />
                    </div>
                    <div className="text-capitalize bz-fw-6 text-muted booking-date">
                      <i
                        className="fa fa-calendar"
                        aria-hidden="true"
                        style={{ width: "1rem", marginRight: "0.2rem" }}
                      ></i>
                      {moment(selectedSchItems?.[0]?.date).format(
                        "dddd, MMMM DD, YYYY"
                      )}{" "}
                      {`${selectedSchItems?.[0]?.time} ${timezoneShorthand}`}
                    </div>
                  </>
                )}
              {schedulerCalendarState?.updateStatus && (
                <>
                  {!locEqual && (
                    <div
                      className="bz-fw-6 text-muted d-flex align-items-center location-text-blk"
                      style={{ textDecoration: "line-through" }}
                    >
                      <LocationForBooking
                        Attributes={OldAttributeValues}
                        showDetails={true}
                        showToolTip={true}
                        VirtualMeetingLink={
                          mainState?.Provider?.VirtualMeetingLink
                        }
                        linkEnable={false}
                        clientPhnNum={userDetails?.Phone}
                        confirmation={true}
                      />
                    </div>
                  )}
                  <div className="bz-fw-6 text-muted d-flex align-items-center location-text-blk">
                    <LocationForBooking
                      Attributes={Attributes}
                      showDetails={true}
                      showToolTip={true}
                      VirtualMeetingLink={
                        mainState?.Provider?.VirtualMeetingLink
                      }
                      linkEnable={true}
                      clientPhnNum={userDetails?.Phone}
                      confirmation={true}
                    />
                  </div>
                  <div className="text-capitalize bz-fw-6  text-muted">
                    <i
                      className="pr-1 fa fa-calendar"
                      aria-hidden="true"
                      style={{ width: "1rem" }}
                    ></i>{" "}
                    {!dateEqual && (
                      <del>
                        {moment(
                          schedulerCalendarState?.updateAppointmentData
                            ?.FromTime
                        ).format("MM/DD/YYYY")}
                      </del>
                    )}
                    <span className="text-muted ml-2">
                      {moment(selectedSchItems[0].date).format("MM/DD/YYYY")}
                    </span>{" "}
                    {!timeEqual && (
                      <del>
                        {`${moment(updateAppointmentData?.FromTime).format(
                          "h:mm A"
                        )} ${updateAppointmentData?.CustomTimeZone
                          ? updateAppointmentData?.CustomTimeZone
                          : getTimeZoneShortHand(
                            updateAppointmentData?.UserTimeZoneId
                          )
                          }`}
                      </del>
                    )}
                    <span className="text-muted ml-2">{`${selectedSchItems[0]?.time} ${timezoneShorthand}`}</span>{" "}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div
                className={`bz-fw-6 text-muted d-flex location-text-blk ${selctedLoccationPhone}`}
              >
                <LocationForBooking
                  Attributes={Attributes}
                  showDetails={true}
                  VirtualMeetingLink={
                    mainState?.Provider?.VirtualMeetingLink
                  }
                  linkEnable={true}
                  clientPhnNum={userDetails?.Phone}
                  confirmation={true}
                />
              </div>
              {mainState?.recurrenceDropdownOption.id === "doesNotRepeat" ? (
                <>

                  <Grid
                    data={schedules}
                    className="bzo-appoinmentBooked-grid"
                    style={{ maxHeight: "300px" }}
                  >
                    <Column
                      field="date"
                      title="Date"
                      width="150px"
                      cell={(props: GridCellProps) => (
                        <td>
                          {!dateEqual && (
                            <del>
                              {moment(updateAppointmentData?.FromTime).format(
                                "MM/DD/YYYY"
                              )}
                            </del>
                          )}{" "}
                          {moment(props.dataItem.date).format("MM/DD/YYYY")}
                        </td>
                      )}
                    />
                    <Column
                      field="time"
                      title="Time"
                      width="150px"
                      cell={(props: GridCellProps) => (
                        <td>
                          {!timeEqual && (
                            <del>
                              {`${moment(
                                updateAppointmentData?.FromTime
                              ).format("h:mm A")} ${updateAppointmentData?.CustomTimeZone
                                }`}
                            </del>
                          )}{" "}
                          {`${props.dataItem.time} ${timezoneShorthand}`}
                        </td>
                      )}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    data={schedules}
                    className="bzo-appoinmentBooked-grid"
                    style={{ maxHeight: "300px" }}
                  >
                    <Column
                      field="StartDateTime"
                      title="Date"
                      width="150px"
                      cell={(props: GridCellProps) => (
                        <td>
                          {moment(props.dataItem.StartDateTime).format(
                            "MM/DD/YYYY"
                          )}
                        </td>
                      )}
                    />
                    <Column
                      field="StartDateTime"
                      title="Time"
                      width="150px"
                      cell={(props: GridCellProps) => (
                        <td>
                          {`${moment(props.dataItem.StartDateTime).format(
                            "h:mm A"
                          )} ${timezoneShorthand}`}
                        </td>
                      )}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
          <div className="align-self-start  bz-fw-6 ">
            {"A calendar invitation has been sent to your email address."}
          </div>
          {!guestPages && (
            <div
              style={{ width: "80%" }}
              className="d-flex justify-content-start"
            >
              <Button
                primary={true}
                className={"align-self-end ml-0"}
                style={{ textTransform: "initial" }}
                onClick={() => handleConfirmation()}
              >
                {!userDetails ? "Add Another Appointment" : calenderPage ? "Go to calendar" : "Go to overview"}
              </Button>
            </div>
          )}
          {(calenderPage || quicklinks) &&
            <div className="align-self-start  bz-fw-6 btn-link " onClick={() => handleConfirmation()}>
              {`Redirect to Calendar page in ${countdown} seconds`}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation;
