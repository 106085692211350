import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ListView } from "@progress/kendo-react-listview";
import BuzopsButton from "generic-components/BuzopsButton";
import { FormInput } from "utils/form-components";
import { CurrencyFormat, checkHasTaxEnable } from "utils";
import { NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import {
  DropDownList,
  ListItemProps,
  MultiSelect,
} from "@progress/kendo-react-dropdowns";
import successCheck from "./../../Images/successCheck.png";
import React, { useState } from "react";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import { Popup } from "@progress/kendo-react-popup";
import OneonOneOrderSummary from "./OneonOneOrderSummary";
import { Button } from "@progress/kendo-react-buttons";
import { BiEdit } from "react-icons/bi";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
export const TooltipContentPackageService = (propData: any) => {
  if (propData?.data?.length === 0) {
    return <div>{"No Surcharges"}</div>;
  }
  return (
    <>
      <ul className="list-group list-group-flush taxes-list">
        {propData?.Name && (
          <li
            className="list-group-item"
            style={{ fontSize: "0.875rem", fontWeight: 600 }}
          >
            {propData?.Name}
          </li>
        )}

        {propData?.data?.map((i: any) => (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>
              {i?.TaxName}&nbsp;({i?.Percentage}%)
            </span>
            <span className="price">{CurrencyFormat(i?.Amount)}</span>
          </li>
        ))}
        {propData?.TotalTaxAmount > 0 && (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>{"Total Surcharges"}</span>
            <span className="price">
              {CurrencyFormat(propData?.TotalTaxAmount)}
            </span>
          </li>
        )}
      </ul>
    </>
  );
};
export const CustomPopup = (props: any) => {
  return (
    <Popup
      {...props}
      anchorAlign={{
        horizontal: "center",
        vertical: "top",
      }}
      popupAlign={{
        horizontal: "center",
        vertical: "top",
      }}
    />
  );
};
const OrderSummary = (props: any) => {
  console.log("props", props);

  const { NumberOfSessions, RateAfterDiscount, Name, Rate, ProratedRate } =
    props?.serviceData;
  const { appliedCoupons, visibleCouponField } = props;
  const [displayRateInput, setDisplayInput] = useState(false);
  const [rateValue, setRateValue] = useState(ProratedRate || 0);
  const purchaseCost = NumberOfSessions * ProratedRate;

  const handleApply = () => {
    props?.handleProratedRate(rateValue);
    setDisplayInput(false)
  };

  const handleCancel = () => {
    setRateValue(ProratedRate)
    setDisplayInput(false)
  };

  console.log("PropsinOrder", props);
  //   const { paidToday } = props.downpaymentForm;
  const MyCouponItemRender = (listItems: any) => {
    let item = listItems.dataItem;
    const amt = item?.couponAmt;
    let showDismiss = true;
    if (props.page === "online-package") {
      showDismiss = props?.package?.CouponDescription ? false : true;
    }
    if (props.page === "service") {
      showDismiss = props?.serviceData?.CouponDescription ? false : true;
    }

    return (
      <>
        <div
          className="alert alert-success alert-dismissible bz-apply-coupon-new coupon-oneonone fade show mb-2"
          role="alert"
        >
          <h6 className="alert-heading">
            <img
              src={successCheck}
              style={{ width: "15px", height: "15px" }}
              alt=""
            />
            {item.CouponInformation}
          </h6>
          <p className="bz-coupon-desc">
            <span>Type:&nbsp; {item.TypeDescription},</span>&nbsp;
            <span>Coupon Amount: - {CurrencyFormat(amt)}</span>
          </p>
          {showDismiss && (
            <button
              type="button"
              className="close"
              title="Delete"
              onClick={() => {
                props.handleRemoveCoupon(listItems.index);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </div>
      </>
    );
  };

  const CouponApplyField = () => {
    return (
      <Form
        render={(couponRenderForm: any) => (
          <>
            <FormElement style={{ width: "100%" }}>
              <div className="row bz-coupon-field mt-0">
                <div className="col-md-12">
                  <div className="input-group mb-0">
                    <Field
                      id={"coupon"}
                      name={"coupon"}
                      component={FormInput}
                      placeholder="Enter Coupon Code"
                      hidelabel={true}
                      onChange={(e) => {
                        props.handleChangeCoupon(e.value);
                      }}
                      value={props.coupon}
                    />
                    <div style={{ marginLeft: "15px", marginTop: "15px" }}>
                      <BuzopsButton
                        primary
                        label={"Apply"}
                        disable={props.disable}
                        loading={props.couponBtnLoading}
                        onClick={() => props.handleCoupon()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </FormElement>
          </>
        )}
      />
    );
  };
  const itemRenderCoupon = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    let itemChildren;
    const index = itemProps?.dataItem?.Amount;
    if (itemProps?.dataItem?.TypeDescription === "Percentage") {
      itemChildren = (
        <span style={{ fontSize: "18px" }}>
          {itemProps?.dataItem.Description} <br />
          {index} {"%"}
        </span>
      );
    } else if (itemProps?.dataItem?.TypeDescription === "Fixed") {
      itemChildren = (
        <span style={{ fontSize: "18px" }}>
          {itemProps?.dataItem.Description} <br />
          {CurrencyFormat(index)}
        </span>
      );
    }

    return React.cloneElement(li, li.props, itemChildren);
  };

  const ApplyMultipleCouponField = () => {
    return (
      <div className="mt-2">
        <label className="d-flex justify-content-start">Coupons</label>
        <div className="couponsordersummary">
          <MultiSelect
            data={props?.filterCoupondata}
            textField="Description"
            dataItemKey="CouponId"
            placeholder="Please select coupon"
            onChange={(e) => {
              props?.page === "service"
                ? props?.handleOneonOneCoupon(e.value)
                : props?.handlePackageCoupon(e.value);
            }}
            // filterable={true}
            // onFilterChange={(e) => props?.filterCouponChange(e)}
            itemRender={itemRenderCoupon}
            value={appliedCoupons}
            className="bz-multiselect-arrow"
          />
        </div>
        <Hint className="d-flex justify-content-start">
          <span className="form-text text-muted text-left">
            Select a coupon from the list applicable for the package selected
            above.
          </span>
        </Hint>
      </div>
    );
  };
  const calculateCost = (arrayItem: any) => {
    const cost = arrayItem?.reduce((acc: Number, currentValue: any) => {
      return acc + currentValue?.Cost;
    }, 0);
    return cost;
  };
  const NewMyItemRender = (itemProps: any) => {
    let item = itemProps.dataItem;
    // const NotWaitList=item?.values?.filter((i:any)=> !i?.CanBookAsWaitlist)
    return item?.values?.length > 0 ? (
      <>
        <div className={"mb-1 order-summary-class-details"}>
          <span className={"order-summary-class-name"}>{item?.className}</span>
          <span className={"order-summary-class-cost"}>
            {CurrencyFormat(calculateCost(item?.values))}
          </span>
        </div>
        {checkHasTaxEnable() &&
          item?.CanCollectTaxes &&
          (props?.staffPage || props.page === "inhouse-class") && (
            <div
              className={
                "d-flex align-items-center text-left mb-1 buz-text-small buz-font-500 mt-1"
              }
            >
              <Switch
                checked={!item?.IsTaxSkipped}
                defaultChecked={false}
                name={`check${item?.ClassId}`}
                onChange={(e: any) =>
                  props.handleIsSessionTaxSkipped(!item?.IsTaxSkipped, item)
                }
                className="mr-1 d-inline-flex"
              />
              {`Surcharges`}
            </div>
          )}
      </>
    ) : (
      <></>
    );
  };
  const groupServiceCard = () => {
    return (
      <>
        {" "}
        <p className={"text-left pt-1 pb-1 buz-font-600 mt-2"}>
          Group<span className={"float-right"}></span>
        </p>
        <ListView
          data={props.session}
          item={(props) => NewMyItemRender(props)}
          className="mt-2"
        />
      </>
    );
  };

  const showPageType = (props: any) => {
    return (
      <>
        <div className={"mb-1 order-summary-class-details"}>
          <span className={"order-summary-class-name"}>{Name}</span>
          <span className={"order-summary-class-cost"}>
            {props?.serviceData?.Rate !== props?.serviceData?.ProratedRate ? (
              <span className="">
                {" "}
                {CurrencyFormat(
                  props?.serviceData?.ProratedRate *
                  props?.serviceData?.NumberOfSessions
                )}
              </span>
            ) : (
              <span> {CurrencyFormat(purchaseCost)}</span>
            )}
          </span>
        </div>
      </>
    );
  };

  const today1 = new Date();
  const threeMonths = new Date(today1);
  threeMonths.setMonth(threeMonths.getMonth() + 3);

  return (
    <>
      <Card
        className={`text-center order-summary-card order-summary-card  ${props?.staffPage ? "staff-order-summary-card" : "client-order-summary"
          }`}
      >
        <CardHeader
          className={
            "text-left order-summary-header px-0 d-flex align-items-center"
          }
        >
          <h6 className={"card-heading"}>Order Summary</h6>
        </CardHeader>
        {(props?.page === "inhouse-package" || props?.staffPage) && (
          <div
            className="savedCard bz-salesPerson-select text-left px-0 pb-2 pt-0 mt-2"
            style={{ marginTop: "-5px" }}
          >
            <label>Sales Person</label>
            <DropDownList
              data={props?.salesPersons}
              textField="UserName"
              dataItemKey="UserId"
              listNoDataRender={() => (
                <span className={"bzo-paymentProfile-noData"}>
                  No Sales Person Available
                </span>
              )}
              value={props?.selectedSalesPerson}
              style={{ width: "100%" }}
              onChange={(e) => props.handleSalesPersonSelection(e.value)}
            />
            {!props?.selectedSalesPerson && (
              <Error>{"Please select SalesPerson"}</Error>
            )}
          </div>
        )}
        <CardBody className="mt-0 px-0">
          <small className={"text-left pt-0 d-block text-muted buz-text-small"}>
            {displayRateInput ? (
              <>
                <Label>Per Appointment Rate</Label>
                <div className="d-flex justify-content-between align-items-end">
                  <div>
                    <NumericTextBox
                      name="ProratedRate"
                      width={"100%"}
                      value={rateValue}
                      format={"c2"}
                      onChange={(e) => setRateValue(e.target.value)}
                      min={0}
                    />
                  </div>
                  <Button onClick={() => handleCancel()}>Cancel</Button>
                  <Button primary onClick={() => handleApply()}>
                    Apply
                  </Button>
                </div>
              </>
            ) : (
              <span className="d-flex">
                <span className={`service-titlename2 clientusers ${props?.staffPage ? "staff-order-summary-heading" : "client-order-summaryheading"
                  }`}>{`Per Appointment Rate`}</span>
                <span className="mr-1 d-flex">
                  {" "}

                  {props?.staffPage ? (
                    <span className="d-flex">
                      {props?.serviceData?.Rate !==
                        props?.serviceData?.ProratedRate ? (
                        <span className="d-flex">
                          <span className="cross-word mr-1">
                            {" "}
                            {CurrencyFormat(props?.serviceData?.Rate)}
                          </span>
                          <span className="">
                            {" "}
                            {CurrencyFormat(props?.serviceData?.ProratedRate)}
                          </span>
                        </span>
                      ) : (
                        <span> {CurrencyFormat(props?.serviceData?.Rate)}</span>
                      )}
                      <span className="ml-1">
                        <BuzopsTooltip
                          html={
                            <button
                              onClick={() => setDisplayInput(true)}
                              className="btn btn-icon"
                              title="Edit Per Appointment Rate"
                            >
                              <BiEdit color="#008db9" />
                            </button>
                          }
                        />
                      </span>
                    </span>
                  ) : (
                    <span> <span>:</span>  <span>{`${CurrencyFormat(Rate)}`}</span></span>
                  )}
                </span>
              </span>
            )}


            <span className={`service-titlename2  mb-1 clientusers ${props?.staffPage ? "staff-order-summary-heading" : "client-order-summaryheading"
              }`}>{`Appointments Count`}</span>
            <span>

              <span>:</span>  {NumberOfSessions !== undefined ? NumberOfSessions : 0}
            </span>
            <br />
            {/* {props?.quickCheckout && (
            <>
              <span className="service-titlename2">{`Modified Per Appointment Rate`}</span>
              <span> : {`${CurrencyFormat(ProratedRate)}`}</span>
              </>
            )} */}
          </small>
          {showPageType(props)}
          {((props.page === "service" && !props?.staffPage) ||
            (!props?.staffPage &&
              !props?.calenderPage &&
              props?.package?.Cost > 0)) && (
              <>
                <div className="coupon-list mt-0">
                  <ListView data={appliedCoupons} item={MyCouponItemRender} />
                </div>
                {appliedCoupons.length === 0 && CouponApplyField()}
                {appliedCoupons.length > 0 &&
                  visibleCouponField &&
                  CouponApplyField()}
              </>
            )}

          {((props.page === "service" && props?.staffPage) ||
            props?.package?.Cost > 0) && (
              <>
                <ApplyMultipleCouponField />
                <div className="coupon-list mt-3">
                  <ListView data={appliedCoupons} item={MyCouponItemRender} />
                </div>
              </>
            )}
          {props.page === "service" && <OneonOneOrderSummary {...props} />}
        </CardBody>
      </Card>
    </>
  );
};

export default OrderSummary;
