import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import { NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { Notification as Notify } from "@progress/kendo-react-notification";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import {
  CheckUserMemberStatus,
  CurrencyFormat,
  CustomToFixed,
  checkIfUserCanAddBankAccount
} from "utils";
import { CancellationOnOptions, CreditsErrorMessage ,CancellationProcessTypeOptions} from "utils/form-utils";
import AddonsInPlanCancel from "./AddonsInPlanCancel";
import PlanCancelSummary from "./PlanCancelSummary";
import FailedTransactionsGrid from "../FailedTransactionsGrid";
import withNotification from "components/Hoc/withNotification";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { BiInfoCircle } from "react-icons/bi";
import { TooltipContentRefundTax } from "components/Transactions/Refund";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import { useApiClient } from "services/axios-service-utils";
import { UploadFileInfo, UploadOnAddEvent, UploadOnRemoveEvent } from "@progress/kendo-react-upload";

const PlanCancel = (props: any) => {
  const [cancelDetails, setCancelDetails] = useState<any>({});
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [totalCancellationAmount, setTotalCancellationAmount] =
    useState<any>(0);
  const [paymentProfiles, setPaymentProfiles] = useState<any>([]);
  const [pageType, setPageType] = useState("plan");
  const [loading, setLoading] = useState(true);
  const [addonLoading, setAddonLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [confirmBtnLoading, setConfirmBtnLoading] = useState(false);
  const [nextbtn, setNextBtn] = useState(true);
  const [confirmText, setConfirmText] = useState("");
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [addNewItem, setAddNewItem] = useState<any>(null);
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);
  const service = new TenantService();
  const { axiosRequest } = useApiClient();
  const [canAutoRenew,setCanAutoRenew]=useState(false);
  const PaymentMethodItems = [
    {
      iconClass: "fa fa-credit-card pr-1",
      text: "Add credit card",
      key: "addCard",
      visible:true,
    },
    {
      iconClass: "fa fa-university pr-1",
      text: "Add bank account",
      key: "addBank",
      visible: checkIfUserCanAddBankAccount()
    },
  ];
  useEffect(() => {
    fetchAllApis();
  }, []);

  const fetchAllApis = async () => {
    setLoading(true);
    await fetchPlanSummary();
    await fetchPaymentProfiles(props?.userData?.MainUserId);
    setLoading(false);
  };

  
  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);


  const constructFileWithBas64 = (records: any) => {
    let cancelDataItem=null
    if(cancelDetails?.Result?.[0]){
      cancelDataItem = JSON.parse(JSON.stringify(cancelDetails?.Result?.[0]));
    }
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
          if(cancelDataItem){
            handleChange(
              {
                Reference: cancelDataItem?.PaymentAttributes?.Reference,
                ReferrenceDocument: fileData?.[0],
              }
            ,"PaymentAttributes")
          }
        }
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
      if(cancelDataItem){
        handleChange({
            Reference: cancelDataItem?.PaymentAttributes?.Reference,
            ReferrenceDocument: null,},"PaymentAttributes")
      }
      }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
    
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };


  const fetchPlanSummary = async () => {
    const req = {
      PageNumber: 0,
      PageSize: 32767,
      UserMemberId: props?.userData?.UserMemberId,
      MemberPlanId: props?.selectedPlanId,
    };
    const res = await service?.getMemberPlanSummary(req);
    setSelectedPlan(res);
    await fetchCancellationDetails(res?.MemberNumber);
  };

  const fetchCancellationDetails = async (MemberNumber: any) => {
    const MemberPlanId = props.selectedPlanId;
    const req = {
      InvoiceNumber: MemberNumber,
      MemberPlanId,
      UserMemberId: props?.userData?.UserMemberId,
    };
    const result = await service.GetPlanCancellationDetails(req);
    if (result?.Result?.length > 0) {
      if (result?.Result[0]?.CancellationRequestedId > 0) {
        setCancelDetails(result);
      } else {
        if (result?.Result[0]?.IsNeedToCancel === true) {
          const resultRecords = result?.Result?.map((i: any, index: number) => {
            if (index === 0) {
              if(!i?.MinimumDaysReached){
                return {
                  ...i,
                  IsNeedToCancel: true,
                  CancellationOn: 1,
                  CancellationProcessType:2
                };
              }
              if (
                (i?.DurationType === 1 && i?.Duration === 1) ||
                i?.DurationType === 3
              ) {
                return {
                  ...i,
                  IsNeedToCancel: true,
                  CancellationOn: 1,
                  CancellationProcessType:2
                };
              }
              return { ...i, IsNeedToCancel: true, CancellationOn: 0 };
            } else {
              return { ...i };
            }
          });
          const rec = { ...result, Result: resultRecords };
          await UpdatePlanCancellationDetails(rec);
        } else {
          setCancelDetails(result);
        }
      }
    } else {
      setCancelDetails(null);
    }
  };

  const calculateTotalCancellationAmount = (data: any) => {
    let totalCancellationAmt = 0;
    data?.Result?.forEach((i: any) => {
      totalCancellationAmt += i?.AmountToCollect;
      i?.AddOns?.forEach((addon: any) => {
        totalCancellationAmt += addon?.AmountToCollect;
      });
    });
    setTotalCancellationAmount(totalCancellationAmt);
  };

  const makePaymentProfileAsDefault = async (dataItem: any) => {
    const req = {
      PaymentGatewayPaymentProfileID: dataItem?.PaymentGatewayPaymentProfileID,
      UserMemberId: props?.userData?.MainUserId,
      CustomerProfileID:
        dataItem?.CustomerProfileID,
    };
    await axiosRequest.post(
      `PaymentProfile/update-preferred-payment-profile`,
      req,
      {
        successCallBack: async (response: any) => {
          props?.handleNotificationMessage(
            `Selected Payment profile marked as Preferred`,
            "success"
          );
          await fetchPaymentProfiles(props?.userData?.MainUserId);
        },
        errorCallBack: (response: any) => {
          props?.handleNotificationMessage(
            response?.response?.data?.Messages?.[0] ||
              response?.data?.Messages?.[0] ||
              "Internal server error",
            "error"
          );
        },
      }
    );
  };

  async function fetchPaymentProfiles(userMemberId: any,modeVal=null) {
    const result = await service.paymentProfile(userMemberId);
    if(modeVal){
      const record=result?.find((i:any)=> i?.CardTypeId ===-1)
      if(record){
        const data={
          ...record,
          CardTypeId: -2,
          MaskedCCNumber: modeVal,
          CardDescription: modeVal,
          CardDescriptionWithoutExpiryDate: modeVal,
        };
        const res=[...result]
        res.push(data)
        setPaymentProfiles(JSON.parse(JSON.stringify(res)))
        handleChange(data, "paymentProfile");
      }
    }else{
      setPaymentProfiles(result || []);
      // const defaultPP = result.find((card: any) => card.IsDefault);
      // if(defaultPP){
      //   handleChange(defaultPP, "paymentProfile");
      // }
    }
  }

  const showErrorMessage = (key: any, mainKey: any) => {
    if (
      key === "PaymentGatewayPaymentProfileId" &&
      (totalCancellationAmount > 0 || cancelDetails?.Result[0]?.CancellationProcessType===1 ) && 
        (!cancelDetails[key] || cancelDetails[key] === 0)
    ) {
      return <Error>{`${mainKey} is required`}</Error>;
    } else if (
      key !== "PaymentGatewayPaymentProfileId" &&
      (!cancelDetails[key] || cancelDetails[key].length <= 0)
    ) {
      return <Error>{`${mainKey} is required`}</Error>;
    }
    return <></>;
  };

  const selectionChange = async (event: any, EntityId: number = 0) => {
    const dataItem = JSON.parse(JSON.stringify(cancelDetails?.Result[0]));
    const originalDup = dataItem?.FailedTransactions;

    const original = originalDup.map((item: any) => {
      if (
        item?.TransactionHistoryId === event?.dataItem?.TransactionHistoryId
      ) {
        item.CanWaive = !event.dataItem.CanWaive;
      }
      return item;
    });
    const updatedCancellationDetails = {
      ...dataItem,
      FailedTransactions: original,
    };
    const cancelDet = {
      ...cancelDetails,
      Result: [updatedCancellationDetails],
    };
    setCancelDetails(cancelDet);
    await UpdatePlanCancellationDetails(cancelDet);
  };

  const handleCalculateSurcharges = (refundAmt: any, TaxMetaData: any) => {
    if (TaxMetaData) {
      const mainTaxItems = TaxMetaData?.split(",");
      const TaxesArray = mainTaxItems.map((i: any) => {
        const dataItem = i?.split(":");
        const percentage = parseFloat(dataItem[1]);
        const description = `${dataItem[0]} - (${percentage}%)`;
        const amount = (refundAmt * percentage) / 100;
        const calcAmt = CustomToFixed(amount, 2);
        return {
          Description: description,
          Percentage: percentage,
          Amount: calcAmt,
        };
      });
      const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
        return acc + currentValue?.Amount;
      }, 0);
      return {
        TaxesArray,
        taxAmt,
      };
    } else {
      return {
        TaxesArray: [],
        taxAmt: 0,
      };
    }
  };

  const handleChange = async (value: any, name: string) => {
    let update = false;
    const data = JSON.parse(JSON.stringify(cancelDetails));
    let cancelDet = data;
    if (name === "paymentProfile") {
      const PaymentGatewayPaymentProfileID =
        value?.PaymentGatewayPaymentProfileID;
      cancelDet = {
        ...data,
        PaymentGatewayPaymentProfileId: PaymentGatewayPaymentProfileID,
        CardTypeId: value?.CardTypeId,
        Credit: value?.Credit,
        MaskedCCNumber: value?.MaskedCCNumber,
      };
      const dataItem = JSON.parse(JSON.stringify(cancelDetails?.Result?.[0]));
      let paymentAttributes = null;
      if (value?.CardTypeId === -2) {
        paymentAttributes = {
          Reference: dataItem?.PaymentAttributes?.Reference,
          OfflinePaymentDescription: value?.MaskedCCNumber,
          ReferrenceDocumentName: dataItem?.PaymentAttributes?.ReferrenceDocument?.FileName || "",
          ReferrenceDocumentContent: dataItem?.PaymentAttributes?.ReferrenceDocument?.ImageAsBase64 || "",
        };
      }else if(value?.CardTypeId === -1){
        paymentAttributes = {
          Reference: "",
          OfflinePaymentDescription: "Cash",
          ReferrenceDocumentName: "",
          ReferrenceDocumentContent: "",
        };
      }
      const updatedCancellationDetails = {
        ...dataItem,
        PaymentAttributes: paymentAttributes,
      };
      cancelDet = {
        ...cancelDet,
        Result: [updatedCancellationDetails],
      };
    } else if (name === "Comments") {
      cancelDet = {
        ...data,
        [name]: value,
      };
    } else if (name === "CancellationOn" || name === "CancellationFee") {
      let val = value;
      const dataItem = JSON.parse(JSON.stringify(cancelDetails?.Result[0]));
      const updatedCancellationDetails = {
        ...dataItem,
        [name]: val,
      };
      if (name === "CancellationFee") {
        update = false;
        val = value || 0 ;
        updatedCancellationDetails.CancellationFee = val
        updatedCancellationDetails.AmountToCollect = val
      }
      if (name==='CancellationOn'){
        update = true;
        if (updatedCancellationDetails.CancellationProcessType === 0 && (val===1 || val===4)) 
          updatedCancellationDetails.CancellationProcessType = 2
        else if (val===1)
          updatedCancellationDetails.CancellationProcessType = 2
        if(val==4)
          setNextBtn(false)
        else
          setNextBtn(true)
      }
      cancelDet = {
        ...data,
        Result: [updatedCancellationDetails],
      };
    } 
    else if (name === "AmountToRefund") {
      let val = value;
      const dataItem = JSON.parse(JSON.stringify(cancelDetails?.Result[0]));
      if (name === "AmountToRefund") {
        val = value || 0 ;
      }
      const updatedCancellationDetails = {
        ...dataItem,
        [name]: val,
      };
      cancelDet = {
        ...data,
        Result: [updatedCancellationDetails],
      };
      update = false;
    } 
    else if (name === "CancellationProcessType") {
      let val = value;
      const dataItem = JSON.parse(JSON.stringify(cancelDetails?.Result[0]));
      if (name === "CancellationProcessType") {
        val = value || 0;
        const updatedCancellationDetails = {
          ...dataItem,
          [name]: val,
        };
        if(val !== 1){
          updatedCancellationDetails.AmountToCollect = 0
          updatedCancellationDetails.CancellationFee = 0
        }
        if(val === 1){
          updatedCancellationDetails.AmountToCollect = updatedCancellationDetails.CancellationFee
        }
        cancelDet = {
          ...data,
          Result: [updatedCancellationDetails],
        };
        update = false;
        if(val===2)
          update=true;
      }      
    } 
    else if (name === "CancellationDate") {
      const formattedDate = moment(value).format("YYYY-MM-DDTHH:mm:ss"); // Format date to string    
      let val = formattedDate;
      const dataItem = JSON.parse(JSON.stringify(cancelDetails?.Result[0]));
      const updatedCancellationDetails = {
        ...dataItem,
        [name]: val,
      }
        cancelDet = {
          ...data,
          Result: [updatedCancellationDetails],
        };
        if(updatedCancellationDetails.CancellationProcessType === 3) 
          updatedCancellationDetails.CancellationProcessType=2
        setNextBtn(true)
        update = true;
    } else if(name==="PaymentAttributes"){
      const dataItem = JSON.parse(JSON.stringify(cancelDetails?.Result?.[0]));
      let paymentAttributes = null;
      if (cancelDetails?.CardTypeId === -2) {
        paymentAttributes = {
          Reference: value?.Reference,
          OfflinePaymentDescription: cancelDetails?.MaskedCCNumber,
          ReferrenceDocumentName: value?.ReferrenceDocument?.FileName || "",
          ReferrenceDocumentContent: value?.ReferrenceDocument?.ImageAsBase64 || "",
        };
      }
      const updatedCancellationDetails = {
        ...dataItem,
        [name]: paymentAttributes,
      };
      cancelDet = {
        ...data,
        Result: [updatedCancellationDetails],
      };
    
    }else {
      const dataItem = JSON.parse(JSON.stringify(cancelDetails?.Result[0]));
      const updatedCancellationDetails = {
        ...dataItem,
        [name]: value,
      };
      cancelDet = {
        ...data,
        Result: [updatedCancellationDetails],
      };
    }
    setCancelDetails(cancelDet);
    if (update) {
      await UpdatePlanCancellationDetails(cancelDet);
    }
  };

  const onCardOrBankAdd = (e: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };

  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog)
  }

  const handleSuccessClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog)
    await props?.fetchPaymentProfiles(props?.userData?.MainUserId)
  }

  const handleToggleChange =(name: string) => (event:any) => {
    if(name=='Withdraw'){
      if (event.value) {
        setShowWithdraw(true); 
        const data = JSON.parse(JSON.stringify(cancelDetails));
        let cancelDet = data;
        GetWithdrawalnDetails(cancelDet)
      } else {
        setShowWithdraw(false); 
      }
    }else if(name=='AutoRenew'){
    const data = JSON.parse(JSON.stringify(cancelDetails));
    let cancelDet = data;
    const dataItem = JSON.parse(JSON.stringify(cancelDetails?.Result[0]));
    const updatedCancellationDetails = {
      ...dataItem,
      CanEnableRenewal: event.value,
    };
    cancelDet = {
      ...data,
      Result: [updatedCancellationDetails],
    };
    setCanAutoRenew(event.value);
    setCancelDetails(cancelDet);
    }
  };
  console.log("cancelDetails",cancelDetails)
  const handleWithdrawChange = async (value: any, name: string) => {
    const data = JSON.parse(JSON.stringify(cancelDetails));
    const dataItem = JSON.parse(JSON.stringify(cancelDetails?.Result[0]));
    let cancelDet = data;
    if (name === "paymentProfile") {
      const PaymentGatewayPaymentProfileID =
        value?.PaymentGatewayPaymentProfileID;
      cancelDet = {
        ...data,
        PaymentGatewayPaymentProfileId: PaymentGatewayPaymentProfileID,
        CardTypeId: value?.CardTypeId,
        Credit: value?.Credit,
        CancellationProcessType: 1,
        CancellationProcessTypeDescription: "None"
      };
    }else if(name === "CancellationFee"||name==="paymentProfile"){
      const dataItem = JSON.parse(JSON.stringify(cancelDetails?.Result[0]));
      const updatedCancellationDetails = {
        ...dataItem,
        [name]: value,        
      };
      cancelDet = {
        ...data,
        Result: [updatedCancellationDetails],
      };
    }
    setCancelDetails(cancelDet);
  }

  const TooltipContentTemplate = (propData: any) => {
    return (
      <div>
        <span className="">
          {`If you do not wish to provide a refund or 
    if you would like to collect an amount higher 
    than the refund amount,please specify the 
    cancellation fee in the provided field. 
    This fee will be charged to the user 
    in the event of a cancellation`}
        </span>
      </div>
    );
  };

  const GetWithdrawalnDetails = async (dataItem: any) => {
      try {
          setAddonLoading(true);
          const response = await axiosRequest.post("MemberPlan/GetWithdrawDetails", dataItem);
          setAddonLoading(false);
          const result = response.data?.Item; 
          const resultRecords = result?.Result?.map((i: any, index: number) => {
            if (index === 0) {
            return { ...i, IsNeedToCancel: true };
            } else {
            return { ...i };
            }
            });
          const rec = { ...result, Result: resultRecords };
          await calculateTotalCancellationAmount(rec); 
          setCancelDetails({ ...dataItem, Result: result?.Result });
      } catch (error) {
          const errorMsg = "Error in Getting Cancellation Details of Membership";
          props?.handleNotificationMessage(errorMsg, "error");
      } 
  };

  const UpdatePlanCancellationDetails = async (dataItem: any) => {
    setAddonLoading(true);
    const res = await service.UpdatePlanCancellationDetails(dataItem);
    setAddonLoading(false);
    if (res?.ResponseCode === 100) {
      const result = res?.Item;
      const resultRecords = result?.Result?.map((i: any, index: number) => {
        if (index === 0) {
          if(!i?.MinimumDaysReached){
            return {
              ...i,
              IsNeedToCancel: true,
              CancellationOn: 1,
              CancellationProcessType:2
            };
          }
          if (
            (i?.DurationType === 1 && i?.Duration === 1) ||
            i?.DurationType === 3
          ) {
            return {
              ...i,
              IsNeedToCancel: true,
              CancellationOn: 1,
              CancellationProcessType:2
            };
          }
          return { ...i, IsNeedToCancel: true };
        } else {
          return { ...i };
        }
      });
      const rec = { ...result, Result: resultRecords };
      handleUpdateDataWithSurcharge(rec);
      await calculateTotalCancellationAmount(rec);
    } else {
      const errorMsg =
        res?.ErrorMessages.length > 0
          ? res?.ErrorMessages[0]
          : "Error in Getting Cancellation Details of Membership";
      props?.handleNotificationMessage(errorMsg, "error");
    }
    console.log(dataItem);
  };
  const handleAddonNext = async () => {
    if (cancelDetails?.Result)
      if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
        props?.handleNotificationMessage("", "error", true, "client");
        return;
      } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
        props?.handleNotificationMessage("", "error", false, "staff");
        return;
      }
    setBtnLoading(true);
    const result = await service.UpdatePlanAddOnCancellationDetails(
      cancelDetails
    );
    setBtnLoading(false);
    if (result?.ResponseCode === 100) {
      setPageType("final");
      handleUpdateDataWithSurcharge(result?.Item);
      await calculateTotalCancellationAmount(result?.Item);
    }
  };
  const handleUpdateDataWithSurcharge = (dataItem: any) => {
    const updateData = dataItem?.Result?.map((i: any) => {
      const PlanTaxItem = handleCalculateSurcharges(
        i?.AmountToRefund,
        i?.MetaData
      );
      const AddonItems = i?.AddOns?.map((j: any) => {
        const AddonTaxItem = handleCalculateSurcharges(
          j?.AmountToRefund,
          j?.MetaData
        );
        return {
          ...j,
          TaxesArray: AddonTaxItem?.TaxesArray,
          taxAmt: AddonTaxItem?.taxAmt,
        };
      });
      return {
        ...i,
        TaxesArray: PlanTaxItem?.TaxesArray,
        taxAmt: PlanTaxItem?.taxAmt,
        AddOns: AddonItems,
      };
    });
    setCancelDetails({ ...dataItem, Result: updateData });
  };
  const handleApproveConfirmation = async () => {
    const check = handleValidation();
    if (check) {
      setBtnLoading(true);

      const result = await service.PlanCancelApproveConfirmation(cancelDetails);
      setBtnLoading(false);

      if (result?.ResponseCode === 100) {
        setConfirmDialog(true);
        setConfirmText(result?.Item);
      }
    }
  };

  const handleCompleteWithdrawal = async () => {

    let firstRecord = paymentProfiles.find((i:any) => i.CardTypeId !== -1 && i.CardTypeId !== 8 && i.IsCurrent === true);
    const data = JSON.parse(JSON.stringify(cancelDetails));
    let cancelDet = data;

    if (!firstRecord) {
      firstRecord = paymentProfiles.find((i: any) => i.CardTypeId === -1 && i.IsCurrent === true);
    }

    cancelDet = {
      ...data,
      PaymentGatewayPaymentProfileId: firstRecord.PaymentGatewayPaymentProfileID,
      CardTypeId: firstRecord?.CardTypeId,
      Credit: firstRecord?.Credit,
      CancellationProcessType: 1,
      CancellationProcessTypeDescription: "None"
    };

    try {
        setAddonLoading(true);
        setConfirmBtnLoading(true);
        const response = await axiosRequest.post("MemberPlan/WithdrawCancellation", cancelDet);
        setAddonLoading(false);
        setConfirmBtnLoading(false);
        setConfirmDialog(false);
        setConfirmText("");
        setPageType("plan");
        setShowWithdraw(false);
        setTotalCancellationAmount(0);
        const successMsg = "Your cancellation withdrawal has been applied successfully";
        props?.handleNotificationMessage(successMsg, "success");
        await fetchAllApis();
    } catch (error) {
        const errorMsg = "Error in Getting Cancellation Details of Membership";
        props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleAddonPrevious = async () => {
    setPageType("plan");
  };
  const handleCompleteCancellation = async () => {
    setConfirmBtnLoading(true);
    const result = await service.PlanCompleteCancellation(cancelDetails);
    setConfirmBtnLoading(false);
    setConfirmDialog(false);
    setConfirmText("");
    setPageType("plan");
    setTotalCancellationAmount(0);
    if (result?.ResponseCode === 100) {
    setConfirmBtnLoading(true);
      props?.handleNotificationMessage(result?.SuccessMessage, "success");
      const req = {
        PageNumber: 0,
        PageSize: 32767,
        UserMemberId: props?.userData?.UserMemberId,
        MemberPlanId: props?.selectedPlanId,
      };
      const res = await service?.getMemberPlanSummary(req);
      setConfirmBtnLoading(false);

      props?.reopenPlan(res?.MemberPlanId,res?.planStatusDescription,
        "plan",
        res);
     // await fetchAllApis();
    } else {
      const errorMsg =
        result?.ErrorMessages.length > 0
          ? result?.ErrorMessages[0]
          : "Error in Cancelling Membership";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const renderConfirmationDialog = () => {
    return (
      <Dialog
        className="bz-book-Appoinment-dialog bz-plan-cancellation-dialog"
        title={"Buzops"}
        onClose={() => handleCancel()}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: confirmText,
          }}
        ></div>
        <div className="k-dialog-footer">
          <Button primary={false} onClick={() => handleCancel()}>
            Cancel
          </Button>
          <BuzopsButton
            label={"Ok"}
            loading={confirmBtnLoading}
            onClick={() => handleCompleteCancellation()}
          />
        </div>
      </Dialog>
    );
  };
  const renderWithdrawConfirmationDialog = () => {
    return (
      <Dialog
        className="bz-book-Appoinment-dialog bz-plan-cancellation-dialog"
        title={"Confirmation of Withdraw"}
        onClose={() => handleCancelwithdraw()}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: "If you'd like to undo the cancellation, click 'Yes.' If applicable, we'll charge the remaining amount until your next billing date. Click 'No' to keep the cancellation as is.",
          }}
          className="pb-4"
        ></div>
        <div className="bz-content-text-row-mobile d-inline">
          <div className="bz-content-title text-left mb-4">
            Do you want to enable auto renew?
            <span className="ml-3">
            <Switch
                onChange={handleToggleChange("AutoRenew")}
                checked={canAutoRenew}
              />
            </span>
          </div>
          
        </div>
        <div className="k-dialog-footer">
          
          <Button primary={false} disabled={confirmBtnLoading} onClick={() => handleCancelwithdraw()}>
            No
          </Button>
          <BuzopsButton
            label={"Yes"}
            loading={confirmBtnLoading}
            onClick={() => handleCompleteWithdrawal()}
            key={confirmBtnLoading.toString()}
          />
        </div>
      </Dialog>
    );
  };
  const handleCancel = () => {
    setConfirmDialog(false);
  };
  const handleCancelwithdraw = () => {
    setShowWithdraw(false);
  };

  const handleValidation = () => {
    if (!cancelDetails?.Comments || cancelDetails?.Comments === "") {
      return false;
    }
    if (
      totalCancellationAmount > 0 &&
      (!cancelDetails?.PaymentGatewayPaymentProfileId ||
        cancelDetails?.PaymentGatewayPaymentProfileId === 0)
    ) {
      return false;
    }

    if(cancelDetails?.PaymentGatewayPaymentProfileId && cancelDetails?.CardTypeId === 8 && totalCancellationAmount > cancelDetails?.Credit){
      const errorMsg = CreditsErrorMessage
      props?.handleNotificationMessage(errorMsg, "error");
      return false
    }
    return true;
  };


  const handleAddonItem = (key: any, value: any) => {
    const dataItem = JSON.parse(JSON.stringify(cancelDetails?.Result[0]));
    const addonItems = dataItem?.AddOns?.map((i: any) => {
      if (i?.EntityId === key) {
        return value;
      } else {
        return i;
      }
    });

    const updatedCancellationDetails = {
      ...dataItem,
      AddOns: addonItems,
    };

    const cancelDet = {
      ...cancelDetails,
      Result: [updatedCancellationDetails],
    };
    setCancelDetails(cancelDet);
  };

  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  if (!cancelDetails?.Result) {
    return <div className="bz-norecords-available">{"No Data Found"}</div>;
  }

  const selectedPaymentProfile=paymentProfiles?.find((i:any)=> i?.PaymentGatewayPaymentProfileID===cancelDetails?.PaymentGatewayPaymentProfileId && i?.CardTypeId ===cancelDetails?.CardTypeId)
  return (
    <>
      {cancelDetails?.Result?.map((dataItem: any) => {
        if (dataItem) {
          const {
            PlanName,
            StartDate,
            MemberEndDate,
            FailedTransactions,
            IsNeedToCancel,
            MinimumDaysReached,
            AmountPaid,
            Notification,
            AmountToCollect,
            CancellationOn,
            CancellationDate,
            CancellationProcessType,
            ImmediateCancellationDate,
            NormalCancellationDate,
            CancellationRequestedId,
            CancellationApprovedBy,
            CancellationApprovedDate,
            CancellationComments,
            CancellationFee,
            DurationType,
            Duration,
            AddOns,
            AmountToRefund = 0,
            MetaData = null,
            TaxesArray = [],
            taxAmt = 0,
            HasRenewal,
            MonthlyRate,
            NextRecurrence,
            CancellationStatus
          } = dataItem;
          return (
            <>
              <div className="bz-subsection-row bz-plan-overview bz-addon-cancellation">
                <div className="left-section">
                  <h5 className="mb-3 pt-3 bz_fs_1_5">Membership Details</h5>
                  <div className="bz-mt-2rem bz-content-text-row-mobile">
                    
                  </div>
                  {pageType === "plan" && (
                    <>
                      <div className="bz-content-text-row-mobile">
                        <div className="bz-content-text-row">
                          <div className="bz-content-title text-right">
                            Membership Name
                          </div>
                          <span className="bz-colon text-center">:</span>
                          <div className="bz-content-body">{PlanName}</div>
                        </div>
                        <div className="bz-content-text-row">
                          <div className="bz-content-title text-right">
                            Signup Date
                          </div>
                          <span className="bz-colon text-center">:</span>
                          <div className="bz-content-body">
                            {moment(selectedPlan?.PurchasedOn).format("L")}
                          </div>
                        </div>
                        <div className="bz-content-text-row">
                          <div className="bz-content-title text-right">
                            Start Date
                          </div>
                          <span className="bz-colon text-center">:</span>
                          <div className="bz-content-body">
                            {StartDate === null
                              ? "NA being MTM plan"
                              : moment(StartDate).format("L")}
                          </div>
                        </div>
                        <div className="bz-content-text-row">
                          <div className="bz-content-title text-right">
                            Next BIlling Date
                          </div>
                          <span className="bz-colon text-center">:</span>
                          <div className="bz-content-body">
                            {NextRecurrence === null
                                ? "NA"
                                : moment(NextRecurrence).format("L")}
                          </div>
                        </div>
                        <div className="bz-content-text-row">
                          <div className="bz-content-title text-right">
                            Rate
                          </div>
                          <span className="bz-colon text-center">:</span>
                          <div className="bz-content-body">
                            {CurrencyFormat(MonthlyRate)}
                          </div>
                        </div>
                        {!HasRenewal && (
                          <div className="bz-content-text-row">
                          <div className="bz-content-title text-right">
                            {" "}
                            End Date
                          </div>
                          <span className="bz-colon text-center">:</span>
                          <div className="bz-content-body">
                            {MemberEndDate === null
                              ? DurationType === 2
                                ? "NA being MTM plan"
                                : "NA"
                              : moment(MemberEndDate).format("L")}
                          </div>
                        </div>
                        )}    
                        <hr />
                        <h5 className="mb-3 pt-3 bz_fs_1_5">
                          Cancellation Details
                        </h5>
                        {CancellationRequestedId > 0 && (
                          <>
                            <div className="bz-content-text-row">
                              <div className="bz-content-title text-right">
                                Approved by
                              </div>
                              <span className="bz-colon text-center">:</span>
                              <div className="bz-content-body">
                                {CancellationApprovedBy}
                              </div>
                            </div>
                            <div className="bz-content-text-row">
                              <div className="bz-content-title text-right">
                                Approved Date
                              </div>
                              <span className="bz-colon text-center">:</span>
                              <div className="bz-content-body">
                                {CancellationApprovedDate === null
                                  ? "NA"
                                  : moment(CancellationApprovedDate).format(
                                      "L"
                                    )}
                              </div>
                            </div>
                            <div className="bz-content-text-row">
                              <div className="bz-content-title text-right">
                                Comments of Approval
                              </div>
                              <span className="bz-colon text-center">:</span>
                              <div className="bz-content-body">
                                {CancellationComments}
                              </div>
                            </div>
                            <div className="bz-content-text-row">
                              <div className="bz-content-title text-right">
                                Cancel On
                              </div>
                              <span className="bz-colon text-center">:</span>
                              <div className="bz-content-body cancelon">
                                {
                                  CancellationOnOptions?.filter(
                                    (i: any) => i?.id === CancellationOn
                                  )?.[0]?.text
                                }
                              </div>
                            </div>
                            <div className="bz-content-text-row">
                              <div className="bz-content-title text-right">
                                Cancellation Date
                              </div>
                              <span className="bz-colon text-center">:</span>
                              <div className="bz-content-body">
                                {CancellationDate === null
                                  ? "NA"
                                  : moment(CancellationDate).format("L")}
                              </div>
                            </div>
                            {/* <div className="bz-content-text-row">
                              <div className="bz-content-title text-right">
                                Withdraw Cancellation
                              </div>
                              <span className="bz-colon text-center">:</span>
                              <div className="bz-content-body">
                                <label>
                                  <Switch
                                    onChange={handleToggleChange('Withdraw')}
                                    checked={showWithdraw}
                                  />
                                </label>
                              </div>
                            </div> */}
                          </>
                        )}
                        {/* commented as per #8889 */}
                        {Notification && !IsNeedToCancel && CancellationStatus==0 &&(
                          <div className="bz-content-text-row bz-notify-row">
                            <div className="bz-content-title text-right"></div>
                            <span className="bz-colon text-center"></span>
                            <div className="bz-content-body">
                              {" "}
                              <div className="bz-plan-note">
                                <p className="mb-0">
                                  <Notify key="info" type={{ style: "info" }}>
                                    {Notification}
                                  </Notify>
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        {showWithdraw && renderWithdrawConfirmationDialog()}
                        {!MinimumDaysReached &&
                          CancellationRequestedId === 0 && (
                            <div className="bz-content-text-row bz-notify-row">
                              <div className="bz-content-title text-right"></div>
                              <span className="bz-colon text-center"></span>
                              <div className="bz-content-body">
                                <div className="bz-plan-note bz-cancellation-notification">
                                  <p className="mb-0 bz-black-font">
                                    <Notify key="info" type={{ style: "none" }}>
                                      {`Since you are cancelling the Membership within minimum days, the system will process your ${CurrencyFormat(
                                        AmountPaid
                                      )} refund.`}
                                    </Notify>
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>

                      {FailedTransactions?.length > 0 && CancellationRequestedId <=0 &&
                        pageType === "plan" && (
                          <FailedTransactionsGrid
                            details={FailedTransactions}
                            selectionChange={(e: any, id: number) =>
                              selectionChange(e, id)
                            }
                            EntityId={0}
                          />
                        )}
                      {IsNeedToCancel && (
                        <>
                          <div className="bz-mb-2_5rem">
                            {CancellationRequestedId === 0 &&
                              IsNeedToCancel &&
                              pageType === "plan" && (
                                <>
                                  <div className="bz-content-text-row align-items-start bz-notify-row">
                                    <div className="bz-content-title text-right">
                                      Cancel On{" "}
                                      <span className="required-asterisk">
                                        *
                                      </span>
                                    </div>
                                    <span className="bz-colon text-center">
                                      :
                                    </span>
                                    <div className="d-flex">
                                      <div className="bz-content-body cancelon">
                                        <DropDownList
                                          data={
                                            DurationType === 3
                                              ? CancellationOnOptions.filter(option => option.id === 1 || option.id === 4)
                                              : CancellationOnOptions
                                          }
                                          textField="text"
                                          dataItemKey="id"
                                          disabled={
                                            !MinimumDaysReached || (((DurationType === 1 &&
                                              Duration === 1) ||
                                              DurationType === 3) &&
                                            CancellationOn === 1)
                                          }
                                          value={
                                            CancellationOnOptions?.filter(
                                              (i: any) => i?.id === CancellationOn
                                            )?.[0] || {
                                              id: 0,
                                              text: "Please select Cancellation Type",
                                            }
                                          }
                                          onChange={(e) =>
                                            handleChange(
                                              e?.value?.id,
                                              "CancellationOn"
                                            )
                                          }
                                          style={{ width: "100%" }}
                                        />
                                        {CancellationOn === 0 && (
                                          <Error>
                                            {"Please Select Cancellation On"}
                                          </Error>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {CancellationOn > 0 && (
                                    <div className="bz-content-text-row bz-notify-row">
                                      <div className="bz-content-title text-right">
                                        Cancellation Date {" "}
                                        <span className="required-asterisk">
                                          *
                                        </span>
                                      </div>
                                      <span className="bz-colon text-center">
                                        :
                                      </span>
                                      <div className="bz-content-body plancancel">
                                        {CancellationOn === 1 &&
                                          moment().format("L")}
                                        {CancellationOn === 2 &&
                                          moment(
                                            ImmediateCancellationDate
                                          ).format("L")}
                                        {CancellationOn === 3 &&
                                          moment(NormalCancellationDate).format(
                                            "L"
                                          )}
                                        {/* {CancellationOn === 4 &&(
                                            <div className="bzc-time-sec">
                                            <DatePicker
                                              min={new Date()}
                                              // placeholder={"MM/DD/YYYY"}
                                              placeholder={`Month/Day/Year`}
                                              format="MM/dd/yyyy" 
                                              // formatPlaceholder="formatPattern"
                                              value={nextbtn ? new Date(CancellationDate):null}
                                              id={"canceldate"}
                                              required
                                              className={`form-control membershipcancel`}
                                              name={"canceldate"}
                                              // style={{ width: '250px' }}
                                              width={"250px"}
                                              max={!HasRenewal && MemberEndDate ? new Date(MemberEndDate) : undefined}
                                              onChange={(e) => {
                                                handleChange(e.value, "CancellationDate");
                                              }}
                                            />
                                      </div>)} */}
                                      </div>
                                    </div>
                                  )}
                                  {selectedPlan?.RenewalApplicable && (
                                    <div className="bz-content-text-row bz-notify-row">
                                      <div className="bz-content-title text-right"></div>
                                      <span className="bz-colon text-center"></span>
                                      <div className="bz-content-body plancancel ">
                                        If you want to cancel the membership
                                        after completion of current billing
                                        cycle please{" "}
                                        <a
                                          className="btn btn-link"
                                          onClick={() =>
                                            props?.gotoPlanRenewalPage(true)
                                          }
                                        >
                                          Click Here
                                        </a>
                                      </div>
                                    </div>
                                  )}
                                    {AmountToRefund > 0 && MinimumDaysReached  && (
                                  <div>
                                    <div className="bz-content-text-row bz-notify-row">
                                          <div className="bz-content-title text-right">
                                            <div className="d-flex justify-content-end">
                                              <div>{"Do you want to refund?"}</div>
                                           
                                            </div>
                                          </div>
                                          <span className="bz-colon text-center">
                                            :
                                          </span>
                                          <div className="bz-content-body">
                                           
                                            <Switch
                                              className="ms-0"
                                              name="Refund"
                                              onLabel="Do you want to Refund?"
                                              checked={CancellationProcessType===2}
                                              onChange={(e) =>
                                                handleChange( e.target.value ? 2:3,"CancellationProcessType" )
                                              }
                                            />
                                           </div>                                        
                                    </div>
                                    <div className="bz-content-text-row bz-notify-row">
                                      <div className="bz-content-title text-right">
                                        <div className="d-flex justify-content-end">
                                          <div>{"Amount to Refund"}</div>
                                          <BuzopsTooltip
                                            html={
                                              <button
                                                className="btn btn-icon"
                                                title={
                                                  "Prorated amount for remaining days of current billing cycle"
                                                }
                                              >
                                                <BiInfoCircle color="#008db9" />
                                              </button>
                                            }
                                          />
                                        </div>
                                      </div>
                                      <span className="bz-colon text-center">
                                        :
                                      </span>
                                      <div className="bz-content-body">
                                        {CurrencyFormat(AmountToRefund)}
                                          
                                      </div>                                        
                                    </div>
                                    {taxAmt > 0 && (
                                      <div className="bz-content-text-row bz-notify-row">
                                      <div className="bz-content-title text-right">
                                        <Tooltip
                                          content={(tooltipProps: any) => {
                                            return (
                                              <TooltipContentRefundTax
                                                title={tooltipProps.title}
                                                data={TaxesArray}
                                                TotalTaxAmount={taxAmt}
                                                Name={PlanName}
                                              />
                                            );
                                          }}
                                          tooltipClassName="bz-tooltip-table"
                                        >
                                          <a
                                            title="Surcharges"
                                            style={{
                                              borderBottom: "1px dotted #0d6efd",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {"Surcharges to be refunded"}
                                          </a>
                                        </Tooltip>
                                      </div>
                                      <span className="bz-colon text-center">
                                        :
                                      </span>
                                      <div className="bz-content-body">
                                        {CurrencyFormat(taxAmt)}
                                      </div>                                        
                                    </div>
                                    ) }
                                    
                                  </div>
                                )
                              }

                                    {(CancellationProcessType ===3 || ((CancellationOn !==0 && CancellationProcessType!==1) && AmountToRefund <= 0)) && (
                                      <div className="bz-content-text-row bz-notify-row">
                                      <div className="bz-content-title text-right">
                                        <div className="d-flex justify-content-end">
                                          <div>
                                            <label className={"switch-label"}>{"Do you want to charge?"}</label>
                                          </div>
                                        </div>
                                      </div> 
                                      <span className="bz-colon text-center">:
                                      </span>
                                      <div className="bz-content-body">
                                        <Switch
                                            className="ms-0"
                                            name="Charge"
                                            onChange={(e) =>
                                              handleChange( e.target.value ? 1:3,"CancellationProcessType" )
                                            }
                                            checked={CancellationProcessType===1}
                                          />
                                      </div>                                        
                                    </div>
                                    )}
                                    {(CancellationProcessType ===1 ) && (
                                  <div>
                                     <div className="bz-content-text-row bz-notify-row">
                                        <div className="bz-content-title text-right">
                                          <div className="d-flex justify-content-end">
                                            <div>Do you want to charge?</div>
                                             
                                          </div>
                                        </div>
                                        <span className="bz-colon text-center">
                                          :
                                        </span>
                                        <div className="bz-content-body">
                                          
                                          <Switch
                                            className="ms-0"
                                            name="Charge"
                                            onChange={(e) =>
                                              handleChange( e.target.value ? 1:3,"CancellationProcessType" )
                                            }
                                            checked={CancellationProcessType===1}
                                          /> 
                                           
                                        </div>
                                    </div>
                                    <div className="bz-content-text-row bz-notify-row">
                                        <div className="bz-content-title text-right">
                                          <div className="d-flex justify-content-end">
                                            <div>Cancellation Fee </div>
                                            <BuzopsTooltip
                                              toolTipClass={
                                                "Cancellation-fee-tooltip"
                                              }
                                              position={"top"}
                                              content={(propdata: any) => (
                                                <TooltipContentTemplate
                                                  title={propdata.title}
                                                />
                                              )}
                                              html={
                                                <button
                                                  className="btn btn-icon"
                                                  title={"Cancellation Fee"}
                                                >
                                                  <BiInfoCircle color="#008db9" />
                                                </button>
                                              }
                                            />
                                          </div>
                                        </div>
                                        <span className="bz-colon text-center">
                                          :
                                        </span>
                                        <div className="bz-content-body">
                                          <NumericTextBox
                                            id={`CancellationFee`}
                                            name={`CancellationFee`}
                                            min={0}
                                            format={"c2"}
                                            className="k-dropdown-width-80"
                                            value={CancellationFee}
                                            onChange={(e) =>
                                              handleChange(
                                                e.target.value,
                                                "CancellationFee"
                                              )
                                            }
                                          />
                                          {/* <Switch
                                            className="ms-3"
                                            name="Charge"
                                            onChange={(e) =>
                                              handleChange( e.target.value ? 1:2,"CancellationProcessType" )
                                            }
                                            checked={CancellationProcessType===1}
                                          /> 
                                          <label className={"switch-label"}>{"Do you want to charge?"}</label> */}
                                        </div>
                                      </div>
                                     </div>
                                    )}
                                </>
                              )}
                          </div>
                          <hr />
                        </>
                      )}
                    </>
                  )}
                  {/* addon cancellation */}
                  {addonLoading ? (
                    <BuzopsLoader type="list" />
                  ) : (
                    <>
                      {IsNeedToCancel &&
                        AddOns.length > 0 &&
                        pageType === "plan" && (
                          <>
                            {AddOns?.length && (
                              <h5 className="mb-3 pt-3 bz_fs_1_5">
                                Add-on Details
                              </h5>
                            )}
                            {AddOns?.map((addonItem: any, index: number) => {
                              return (
                                <>
                                  <AddonsInPlanCancel
                                    addonItem={addonItem}
                                    planItem={dataItem}
                                    handleAddonItem={(key: any, value: any) =>
                                      handleAddonItem(key, value)
                                    }
                                    handleCalculateSurcharges={(
                                      array: any,
                                      value: any
                                    ) =>
                                      handleCalculateSurcharges(array, value)
                                    }
                                  />
                                </>
                              );
                            })}
                          </>
                        )}
                    </>
                  )}
                  {/* plan cancel summary section */}

                  {pageType === "final" && (
                    <PlanCancelSummary
                      cancelDetails={dataItem}
                      totalCancellationAmount={totalCancellationAmount}
                      userData={props?.userData}
                      paymentProfiles={paymentProfiles}
                      valueRender={(
                        el: React.ReactElement<HTMLSpanElement>,
                        value: any
                      ) => PaymentValueRender(el, value, selectedPaymentProfile)}
                        // itemRender={(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) =>
                        // PaymentItemRender(li, { ...itemProps, makePaymentProfileAsDefault })
                        // }
                      itemRender={PaymentItemRender}
                      showErrorMessage={(key: any, mainKey: any) =>
                        showErrorMessage(key, mainKey)
                      }
                      handleChange={(val: any, name: string) =>
                        handleChange(val, name)
                      }
                      fetchPaymentProfiles={(usermemberId: any,modeVal=null) =>
                        fetchPaymentProfiles(usermemberId,modeVal)
                      }
                      onAdd={onAdd}
                      onRemove={onRemove}
                      originalFiles={originalFiles}
                      mainData={cancelDetails}
                    />
                  )}

                  <div className="bz-mt-2rem bz-mb-2_5rem">
                    {IsNeedToCancel &&
                      !cancelDetails?.HideNext &&
                      !addonLoading &&
                      pageType === "plan" && (
                        <>
                          <div className="bz-content-text-row">
                            <div className="bz-content-title text-right"></div>
                            <span className="bz-colon text-center"></span>
                            <div className="bz-content-body">
                              <BuzopsButton
                                label={"Next"}
                                loading={btnLoading}
                                disabled={CancellationOn === 0 || !nextbtn}
                                onClick={() => handleAddonNext()}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    {pageType === "final" && (
                      <>
                        <div className="bz-content-text-row">
                          <div className="bz-content-title text-right"></div>
                          <span className="bz-colon text-center"></span>
                          <div className="bz-content-body">
                            <BuzopsButton
                              label={"Previous"}
                              onClick={() => handleAddonPrevious()}
                            />
                            <BuzopsButton
                              label={"Complete"}
                              loading={btnLoading}
                              onClick={() => handleApproveConfirmation()}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {confirmDialog && renderConfirmationDialog()}
            </>
          );
        }
      })}
    </>
  );
};

export default withNotification(PlanCancel);
