import { Button } from "@progress/kendo-react-buttons";
import { DropDownList, ListItemProps, MultiSelect } from "@progress/kendo-react-dropdowns";
import {
  Avatar,
  Card,
  CardBody,
  CardHeader,
} from "@progress/kendo-react-layout";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import BuzopsNotification from "generic-components/BuzopsNotification";
import React, { useEffect, useState } from "react";
import { ClassService } from "services/class/index.service";
import { TenantService } from "services/tenant/index.service";
import {
  CurrencyFormat,
  CustomToFixed,
  checkHasTaxEnable,
  checkIfUserAddPaymentGateway,
  checkIfUserCanAddBankAccount,
  getDayFormat,
  getFullImageUrl,
  getMonthFormat,
  getTimeFormat,
  getWeekdayFormat,
  getYearFormat,
  paymentDropdownItems,
} from "utils";
import moment from "moment";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import Location from "components/Location/Location";
import { PackageService } from "services/package/index.service";
import { Input, Switch } from "@progress/kendo-react-inputs";
import CheckPermissions from "utils/permissions";
import { PermissionEnum } from "utils/PermissionEnum";
import { GetLocalStore } from "utils/storage";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { PaymentItemRender, PaymentValueRender } from "components/Payment/PaymentRenderItems";
import { CouponDurationTypeEnum, CreditsErrorMessage } from "utils/form-utils";
import AdditionalPayment from "components/AdditionalPayment/AdditionalPayment";
import { Upload, UploadFileInfo, UploadOnAddEvent, UploadOnRemoveEvent } from "@progress/kendo-react-upload";
import { ListView } from "@progress/kendo-react-listview";
import successCheck from 'Images/successCheck.png';
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { FormInput } from "utils/form-components";
import { useApiClient } from "services/axios-service-utils";

const PayNowSession = (props: any) => {
  const {axiosRequest}=useApiClient()
  const ComplimentaryPermission = CheckPermissions(
    PermissionEnum["ComplimentaryCheckout"]
  );
  const [selectedPaymentProfile, setSelectedPaymentProfile] =
    useState<any>(undefined);
  const [paymentProfile, setPaymentProfile] = useState([]);
  const [refNumber, setRefNumber] = useState<any>(null);
  const [packagesListToUse, setPackageListToUse] = useState([]);
  const [SelectedPackage, setSelectedPackage] = useState<any>(null);
  const [selectedClass, setSelectedClass] = useState<any>(null);
  const [clientSubmitDetails, setClientSubmitDetails] = useState<any>({});
  const [Taxes, setTaxes] = React.useState<any>([]);
  const [TotalTaxAmount, setTotalTaxAmount] = React.useState<number>(0);
  const [notification, setNotification] = useState({
    error: false,
    success: false,
  });
  const service = new TenantService();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [redeem, setRedeem] = useState(false);
  const [complimentary, setComplimentary] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [IsTaxSkipped, setIsTaxSkipped] = useState(!props?.payNowItem?.CanCollectTaxes)
  const [addNewItem, setAddNewItem] = useState<any>(null);
  const [coupondata, setCouponData] = useState<any>([]);
  const [filterCoupondata, setfilterCouponData] = useState<any>([]);
  const [appliedCoupons, setAppliedCoupons] = useState<any>([]);
  const [couponAmount, setCouponAmount] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [couponBtnLoading,setCouponBtnLoading]=useState(false);
  const [isPercentCoupon, setIsPercentCoupon] = useState(false);

  const initialAdditionalPayment = {
    dialog: false,
    selectedPaymentProfile: null
  }

  const [showAdditionalPayment, setShowAdditionalPayment] = useState<any>(initialAdditionalPayment);
  const [selectedPaymentAddProfile, setSelectedPaymentAddProfile] = useState<any>({
    selectedPayment: undefined,
    AlternatePaymentProfileId:null
  });
  



  //upload
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);

  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);


  const constructFileWithBas64 = (records: any) => {
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
        };
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
    }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };

  //

  const onCardOrBankAdd = (e: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };

  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
  };
  const handleSuccessClosePaymethodDialog = async (msg = null, modeVal = null) => {
    setShowPaymentDialog(false);
    if (msg) {
      setMessage(msg);
      setNotification({ ...notification, success: true });
    }
    const usermeberId = clientSubmitDetails?.PrimaryMemberId || props?.payNowItem?.UserMemberId
    await fetchPaymentProfiles(usermeberId, modeVal);
  };

  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
          className="bz-addCard-dialog"
          title={`${addNewItem !== "addOther"
            ? checkIfUserCanAddBankAccount()
              ? "Add Bank or Card"
              : "Add Card"
            : "Add Payment Mode"
            }`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation
            staffPage={true}
            addNewItem={addNewItem}
            handleClosePaymethodDialog={handleClosePaymethodDialog}
            handleSuccessClosePaymethodDialog={
              handleSuccessClosePaymethodDialog
            }
            UserMemberId={clientSubmitDetails?.PrimaryMemberId || props?.payNowItem?.UserMemberId}
            paymentProfile={paymentProfile}
          />
        </Dialog>
      </>
    );
  };

  useEffect(() => {
    let timer = 1000;
    if (notification.error) {
      timer = 3000;
    }
    let timer1 = setTimeout(() => {
      setNotification({ ...notification, error: false, success: false });
      if (submit) {
        props.handleWindow();
      }
    }, timer);
    return () => {
      clearTimeout(timer1);
    };
  }, [notification.error, notification.success]);

  useEffect(() => {
    if (props?.payNowItem?.ClassId) {
      fetchUserSession();
      fetchCoupons();
    }
  }, [props?.payNowItem]);

  console.log('couponData',coupondata);
  console.log('filteredCouponData',filterCoupondata);
  
  

   async function fetchCoupons() {
      const service = new TenantService();
      let req;
        req = {
          UserMemberId: props?.payNowItem?.UserMemberId,
          EntityId: props?.payNowItem?.ClassId ? props?.payNowItem?.ClassId:0,
          EntityType: 3,
          CouponType:1
        };
      
      const result = await service.AvailableCouponsToApply(req);
      if (result) {
        setCouponData(result);
        setfilterCouponData(result);
      }
    }

  const handleRedeemPackage = (val: any) => {
    if (val && packagesListToUse?.length === 0) {
      const errorMsg = `Packages are not available to redeem`;
      setMessage(errorMsg);
      setNotification({ ...notification, error: true, success: false });
      return;
    }
    setRedeem(val);
    if (val) {
      setComplimentary(false);
    }
    setIsPercentCoupon(false);
    setAppliedCoupons([]);
    setCouponAmount(0);
  };
  const handleComplimentary = (val: any) => {
    setComplimentary(val);
    if (val) {
      setRedeem(false);
    }
    setIsPercentCoupon(false);
    setAppliedCoupons([]);
    setCouponAmount(0);
  };

  const fetchUserSession = async () => {
    setLoading(true);
    const userMemberId = props?.payNowItem?.UserMemberId;
    const userId = props?.payNowItem?.UserId;
    const primaryuserId = props?.payNowItem?.PrimaryUserMemberId || props?.payNowItem?.UserMemberId
    await fetchClassData()
    const res = await getAllSubMembers(primaryuserId, userId);
    const ParentId = res?.ParentId || res?.UserMemberId
    await fetchTaxes();
    await fetchPaymentProfiles(ParentId);
    await fetchPackagesToUse(userMemberId);
    setLoading(false);
  };
  const fetchClassData = async () => {
    const service = new ClassService();
    const res = await service.getClass(props?.payNowItem?.ClassId);
    setSelectedClass(res);
  }
  const fetchTaxes = async () => {
    const req = {};
    const taxService = new ManageTaxesService();
    const res = await taxService.getTaxes(req);
    setTaxes(res);
    handleTaxCalculation(props?.classSession?.Cost, res);
  };
  const getAllSubMembers = async (memberId: any, userId: any) => {
    const tenantService = new TenantService();
    const res = await tenantService.GetSubMembersWithParent(memberId);
    const data = setCurrentUserData(res, userId);
    return data
  };
  const setCurrentUserData = (data: any, userId: any) => {
    const currentUserData = data?.find((val: any) => val.UserId === userId);
    if (currentUserData) {
      fetchClientData(currentUserData);
    }
    return currentUserData
  };

  const handleTaxCalculation = (purchaseAmt: number, TaxItems = Taxes) => {
    const TaxesArray = TaxItems.map((i: any) => {
      const amount = (purchaseAmt * i?.Percentage) / 100;
      const calcAmt = CustomToFixed(amount, 2)
      return {
        TaxId: i?.TaxId,
        TaxName: i?.TaxName,
        Percentage: i?.Percentage,
        Amount: calcAmt,
      };
    });
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
      return acc + currentValue?.Amount
    }, 0);
    setTaxes(TaxesArray);
    setTotalTaxAmount(taxAmt);
  };

  const handlePackageSelectionForClass = (dataItem: any) => {
    setSelectedPackage(dataItem);
  };

  const fetchPackagesToUse = async (memberId: any) => {
    const startDate = moment(props?.classSession?.ClassSessionStartDate).format(
      "YYYY-MM-DD"
    );
    const FromDate = moment(startDate).format("YYYY-MM-DD") + "T00:00:00";
    const ToDate =
      moment(props?.classSession?.ClassSessionEndTime).format("YYYY-MM-DD") +
      "T23:59:59";
    const req = {
      UserMemberId: memberId,
      FromDate,
      ToDate,
    };
    const service = new PackageService();
    const result = await service.packageInstanceToUse(req);
    const updatedResult = result.filter((i: any) => {
      if (
        i.ClassIds.includes(props?.classSession?.ClassId) ||
        i.ClassIds.includes(-1)
      ) {
        return true;
      }
    });
    setPackageListToUse(updatedResult);
    if (updatedResult?.length > 0) {
      const selectedPackage = CheckExactPackageItem(updatedResult,props?.classSession?.ClassSessionStartDate);
      if (selectedPackage) {
        setRedeem(true)
        handlePackageSelectionForClass(selectedPackage);
      }
    }
  };
  const CheckExactPackageItem = (PackagesList: any,ClassSessionStartDate:any) => {
      const packList=PackagesList?.filter((i:any)=>{
      const expireOn=i?.PackageExpireOn ?  i?.PackageExpireOn : i?.ExpireOn
      if(moment(ClassSessionStartDate).format('YYYY-MM-DD') >= moment(i?.ExpireFrom).format('YYYY-MM-DD') && moment(ClassSessionStartDate).format('YYYY-MM-DD') <= moment(expireOn).format('YYYY-MM-DD')){
        return i
      }
    })
    const UnlimitedPackages = packList.filter(
      (i: any) => i?.SessionType === 2
    );
    const LimitedPackages = packList.filter(
      (i: any) => i?.SessionType === 1
    );

    if (UnlimitedPackages?.length === 1) {
      return UnlimitedPackages[0];
    }
    if (UnlimitedPackages?.length > 1) {
      const selectedMinExpireOnPackage = UnlimitedPackages.reduce(
        (min: any, item: any) => {
          return new Date(min.ExpireOn) > new Date(item.ExpireOn) ? item : min;
        },
        UnlimitedPackages[0]
      );
      return selectedMinExpireOnPackage;
    }
    const checkSchedulestoUse=PackagesList?.filter((i:any)=>i?.SessionType === 1 && i?.SchedulesToUse >0)
    const updatedLimitedPackage= LimitedPackages?.filter((j:any)=>{
      const check = checkSchedulestoUse.findIndex((k:any)=>k?.PackageInstanceId===j?.PackageInstanceId);
      if(check ===-1){
        return false
      }
      return true
    })

    if (updatedLimitedPackage?.length > 0) {
      const selectedMinExpireOnPackage = updatedLimitedPackage.reduce(
        (min: any, item: any) => {
          const minExpireOn=min?.PackageExpireOn ?  min?.PackageExpireOn : min?.ExpireOn
          const itemExpireOn=item?.PackageExpireOn ?  item?.PackageExpireOn : item?.ExpireOn

          return new Date(minExpireOn) > new Date(itemExpireOn) ? item : min;
        },
        updatedLimitedPackage[0]
      );
      return selectedMinExpireOnPackage;
    }
    return null;
  };

  // to get client details from api
  async function fetchClientData(currentUserData: any) {
    const clientSubmitDetails = {
      Email: currentUserData?.Email,
      FirstName: currentUserData?.FirstName,
      LastName: currentUserData?.LastName,
      PrimaryMemberId:
        currentUserData?.ParentId || currentUserData?.UserMemberId,
      UserId: currentUserData?.UserId,
      UserMemberId: currentUserData?.UserMemberId,
      MemberNumber: currentUserData?.MemberNumber,
    };
    setClientSubmitDetails(clientSubmitDetails);
  }

  const itemClassValueRender = (
    element: React.ReactElement<HTMLSpanElement>,
    value: any,
    selectedItem: any
  ) => {
    let children: any = [];
    if (selectedItem) {
      const value = selectedItem.Name;
      children = [
        <div className="bz-packageSelect-card mb-0">
          <h6 className="mb-0">{value}</h6>
        </div>,
      ];
    } else {
      children = [<span>Please Select</span>];
    }
    return React.cloneElement(element, { ...element.props }, children);
  };
  const itemClassRender = (li: any, itemProps: any, selectedItem: any) => {
    let selected = false;
    if (
      selectedItem?.PackageInstanceId === itemProps.dataItem?.PackageInstanceId
    ) {
      selected = true;
    }
    let ExpireFrom = itemProps.dataItem.ExpireFrom
      ? moment(itemProps.dataItem.ExpireFrom).format("L")
      : "NA";
    let ExpireOn = itemProps.dataItem.ExpireOn
      ? moment(itemProps.dataItem.ExpireOn).format("L")
      : "NA";

    const value = itemProps.dataItem.Name;
    const schedulesToUse =
      itemProps.dataItem.SessionType === 2
        ? "Unlimited"
        : `${itemProps.dataItem.SchedulesUsed} of ${itemProps.dataItem.SchedulesToUse + itemProps.dataItem.SchedulesUsed
        }`;
    const itemChildren = (
      <div
        className={`bz-packageSelect-card ${selected ? "selected-package" : ""
          }`}
      >
        <h6 className="mb-0">{value}</h6>
        <div className="pack-details">
          <p className="mb-0">
            <small>{`Available Appointments: ${schedulesToUse}`}</small>
          </p>
          <p className="mb-0">
            <small>{`Redeem: ${ExpireFrom} to ${ExpireOn}`}</small>
          </p>
        </div>
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  
  const makePaymentProfileAsDefault = async (dataItem: any) => {
    const req = {
      PaymentGatewayPaymentProfileID: dataItem?.PaymentGatewayPaymentProfileID,
      UserMemberId: clientSubmitDetails?.PrimaryMemberId || props?.payNowItem?.UserMemberId,
      CustomerProfileID:
        dataItem?.CustomerProfileID,
    };
    await axiosRequest.post(
      `PaymentProfile/update-preferred-payment-profile`,
      req,
      {
        successCallBack: async (response: any) => {
          props?.handleNotificationMessage(
            `Selected Payment profile marked as Preferred`,
            "success"
          );
          await fetchPaymentProfiles(clientSubmitDetails?.PrimaryMemberId || props?.payNowItem?.UserMemberId);
        },
        errorCallBack: (response: any) => {
          props?.handleNotificationMessage(
            response?.response?.data?.Messages?.[0] ||
              response?.data?.Messages?.[0] ||
              "Internal server error",
            "error"
          );
        },
      }
    );
  };

  //to get the payment profiles
  async function fetchPaymentProfiles(userMemberId: any, modeVal = null) {
    console.log(clientSubmitDetails, 'clientSubmitDetails')
    const memberId = userMemberId;
    const result = await service.paymentProfile(memberId);
    if (result) {
      let res = result;
      if (props?.isClient) {
        res = result.filter((item: any) => item.CardTypeId !== -1);
      }
      if (modeVal) {
        const record = result?.find((i: any) => i?.CardTypeId === -1)
        if (record) {
          const data = {
            ...record,
            CardTypeId: -2,
            MaskedCCNumber: modeVal,
            CardDescription: modeVal,
            CardDescriptionWithoutExpiryDate: modeVal,
          };
          const res = [...result]
          res.push(data)
          setPaymentProfile(JSON.parse(JSON.stringify(res)))
          handleChangeSavedCard(data)
        }
      } else {
        setPaymentProfile(res || []);
        const defaultPP = result.find((card: any) => card.IsDefault);
        if(defaultPP){
          handleChangeSavedCard(defaultPP)
        }
      }
    }
  }

  const filterCouponChange = (e: any) => {
    const filteredData = filterCoupondata(e.filter, coupondata);
    setfilterCouponData(filteredData);
  };

  const MyCouponItemRender = (listItems: any, item1: any) => {
    let item = listItems.dataItem;
    const amt = item?.couponAmt
    let showDismiss = true
    if (props.page === "online-package") {
      showDismiss = props?.package?.CouponDescription ? false : true
    }
    if (props.page === "service") {
      showDismiss = props?.serviceData?.CouponDescription ? false : true
    }

    return (
      <>
        <div
          className="alert alert-success alert-dismissible bz-apply-coupon-new coupon-oneonone fade show mb-2"
          role="alert"
        >
          <h6 className="alert-heading">
            <img
              src={successCheck}
              style={{ width: "15px", height: "15px" }}
              alt=""
            />
            {item.CouponInformation}
          </h6>
          <p className="bz-coupon-desc">
            <span>Type:&nbsp; {item.TypeDescription},</span>&nbsp;
            <span>Coupon Amount: (- {CurrencyFormat(amt)})</span>
          </p>
          {showDismiss && <button
            type="button"
            className="close"
            title="Delete"
            onClick={() => {
              handleRemoveCoupon(item1);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>}
        </div>
      </>
    );
  };

  const handleRemoveCoupon = (index: number) => {
    const array = [...appliedCoupons];
    let removedcoupon: any = [];
    if (index !== -1) {
      removedcoupon = array.splice(index, 1);
    }
    handleGroupCoupon(array);
  };


  const handleGroupCoupon = (value: any) => {
    let final_coup_data: any[] = [];
    let session = props?.classSession;
    const couponsList = value
    const couponFixed = couponsList.filter(
      (res: any) => res?.TypeDescription === "Fixed"
    );
    if (couponFixed.length > 0) {
      final_coup_data.push(couponFixed[couponFixed.length - 1]);
    }
    const couponPercentage = couponsList.filter(
      (res: any) => res?.TypeDescription === "Percentage"
    );
    if (couponPercentage.length > 0) {
      final_coup_data.push(couponPercentage[couponPercentage.length - 1]);
    }
    let totalCost = session?.Cost;
    let acceptLimitedCoupon = true
    let totalCouponAmt = 0;
    let offerAmt = 0;
    let cost = totalCost;
    let downpayment = totalCost;
    if (final_coup_data.length > 0) {
      final_coup_data = final_coup_data.map((item: any) => {
        if (item.TypeDescription === "Fixed") {
          let coupAmt = item.Amount
          if (item?.DurationType === CouponDurationTypeEnum.LIMITED && !acceptLimitedCoupon) {
            coupAmt = 0
          }
          cost = cost <= coupAmt ? 0 : cost - coupAmt;
          offerAmt += coupAmt;
          return { ...item, couponAmt: coupAmt }
        } else if (item.TypeDescription === "Percentage") {
          const coupvalue = cost * (item.Amount / 100)
          let coupAmt = parseFloat(coupvalue.toFixed(2))
          if (item?.DurationType === CouponDurationTypeEnum.LIMITED && !acceptLimitedCoupon) {
            coupAmt = 0
          }
          cost = item.Amount === 100 ? 0 : cost - coupAmt;
          offerAmt += coupAmt;
          return { ...item, couponAmt: coupAmt }
        }
      });
      downpayment = downpayment <= offerAmt ? 0 : downpayment - offerAmt;
    }
    console.log('finalCouponData', final_coup_data);
    const is100PercentCoupon = final_coup_data.some((c:any) => c.Type == 2 && c.Amount == 100) || false;
    if(is100PercentCoupon){
      setIsPercentCoupon(is100PercentCoupon);
    }
    setAppliedCoupons(final_coup_data)
    setCouponAmount(offerAmt);
  }

  const itemRenderCoupon = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
      ) => {
        let itemChildren;
        const index = itemProps?.dataItem?.Amount;
        if (itemProps?.dataItem?.TypeDescription === "Percentage") {
          itemChildren = (
            <span style={{ fontSize: "18px" }}>
              {itemProps?.dataItem.Description} <br />
              {index} {"%"}
            </span>
          );
        } else if (itemProps?.dataItem?.TypeDescription === "Fixed") {
          itemChildren = (
            <span style={{ fontSize: "18px" }}>
              {itemProps?.dataItem.Description} <br />
              {CurrencyFormat(index)}
            </span>
          );
        }
    
        return React.cloneElement(li, li.props, itemChildren);
      };

  const handleChangeSavedCard = (val: any) => {
    setSelectedPaymentProfile(val);
  };

  const TooltipContentTemplate = (propData: any) => {
    if (propData?.data?.length === 0) {
      return <div className="p-1">{"No Surcharges"}</div>;
    }
    return (
      <ul className="list-group list-group-flush taxes-list">
        <li className="list-group-item text-align-center" style={{ fontSize: '0.875rem', fontWeight: 600 }}>{props?.classSession?.ClassName}</li>
        {propData?.data?.map((i: any) => (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>
              {i?.TaxName}&nbsp;({i?.Percentage}%)
            </span>
            <span className="price">{CurrencyFormat(i?.Amount)}</span>
          </li>
        ))}
        {propData?.TotalTaxAmount > 0 && (<li className="list-group-item d-flex justify-content-between align-items-center">
          <span>
            {"Total Surcharges"}
          </span>
          <span className="price">{CurrencyFormat(propData?.TotalTaxAmount)}</span>
        </li>)}
      </ul>
    );
  };

  const AttendesRender = () => {
    let item = props?.payNowItem;

    let session = props?.classSession;
    const redeemClass = redeem
      ? "redeem-by-pakage"
      : complimentary
        ? "redeem-by-complementary"
        : "";
    const noComplementary = !ComplimentaryPermission
      ? "no-redeem-by-complementary"
      : "";
    const incTaxesClass =
      !IsTaxSkipped && !redeem && !complimentary && checkHasTaxEnable() && selectedClass?.CanCollectTaxes ? "inc-taxes" : "";
    return (
      <div
        className={`bzc-attendees-list bz-paynow-page ${redeemClass} ${noComplementary} ${incTaxesClass}`}
      >
        <div
          className={"card bzc-attndees-card bz-shadow-class bz-default-cursor"}
        >
          <div className="card-body">
            <div >
              <div className={"paynowcont row gap15t"}>
                <div className="col-md-12">
                  <Avatar
                    type="icon"
                    size="large"
                    shape={"circle"}
                    themeColor={"inverse"}
                  >
                    {item?.ProfileImage ? (
                      <img src={getFullImageUrl(item?.ProfileImage)} />
                    ) : (
                      <i className="far fa-user-circle"></i>
                    )}
                  </Avatar>
                  {item.Name}
                </div>
                <div className="col-md-4">
                  <div className="session-price-inc-tax">
                    <div className="bzc-date-sec">
                      <small>Price</small>
                      <h5 className="text-capitalize mb-0">
                        {CurrencyFormat(session?.Cost)}
                      </h5>
                    </div>
                  </div>
                </div>
                {!redeem &&
                  !complimentary && couponAmount > 0 && (
                  <div className="col-md-4">
                  <div className="session-price-inc-tax">
                    <div className="bzc-date-sec">
                      <small>Coupon Amount</small>
                      <h5 className="text-capitalize mb-0">
                        (-{CurrencyFormat(couponAmount)})
                      </h5>
                    </div>
                  </div>
                </div>
                )}
                {IsTaxSkipped &&
                  !redeem &&
                  !complimentary &&
                  checkHasTaxEnable() &&
                  !selectedClass?.CanCollectTaxes && couponAmount > 0 && (
                  <div className="col-md-4">
                  <div className="session-price-inc-tax">
                    <div className="bzc-date-sec">
                      <small>Pay Today</small>
                      <h5 className="text-capitalize mb-0">
                        {CurrencyFormat(couponAmount > session?.Cost ? 0 : session?.Cost - couponAmount)}
                      </h5>
                    </div>
                  </div>
                </div>
                )}
                {!IsTaxSkipped &&
                  !redeem &&
                  !complimentary &&
                  checkHasTaxEnable() &&
                  selectedClass?.CanCollectTaxes && (
                    <div className="col-md-4">
                      <div className="session-price-inc-tax">
                        <div className="bzc-date-sec pt-1">
                          <small>
                            <Tooltip
                              content={(tooltipProps: any) => {
                                return (
                                  <TooltipContentTemplate
                                    title={tooltipProps.title}
                                    data={Taxes}
                                    TotalTaxAmount={TotalTaxAmount}
                                  />
                                );
                              }}
                              tooltipClassName="bz-tooltip-table"
                              position="auto"
                            >
                              <a
                                title="Surcharges"
                                style={{
                                  borderBottom: "1px dotted #0d6efd",
                                  cursor: "pointer",
                                }}
                              >
                                {"Surcharges"}
                                {/* <BiInfoCircle/> */}
                              </a>
                            </Tooltip>
                          </small>
                          <h5 className="text-capitalize mb-0">
                            {CurrencyFormat(TotalTaxAmount)}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
                {!IsTaxSkipped &&
                  !redeem &&
                  !complimentary &&
                  checkHasTaxEnable() &&
                  selectedClass?.CanCollectTaxes && (
                    <div className="col-md-4">
                      <div className="session-price-inc-tax">
                        <div className="bzc-date-sec">
                          <small>Pay Today</small>
                          <h5 className="text-capitalize mb-0">
                            {CurrencyFormat((isPercentCoupon || couponAmount > session?.Cost) ? 0 : TotalTaxAmount + session?.Cost - couponAmount)}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}

                {/* <div className="column-2">
                  <div className="bzc-date-sec">
                    <small>Surcharges</small>
                    <h5 className="text-capitalize mb-0">{CurrencyFormat(10)}</h5>
                  </div>
                </div> */}
                {ComplimentaryPermission && (
                  <div className="col-md-4 complimentary pt-2">
                    <div className="bzc-date-sec bz-text-transform-default">
                      <small>Redeem via Complimentary</small>
                      <div>
                        <Switch
                          name={`Complimentary`}
                          onChange={(e: any) => handleComplimentary(e.value)}
                          checked={complimentary}
                          disabled={btnLoading}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-4 redeem pt-2">
                  <div className="bzc-date-sec bz-text-transform-default">
                    <small>Redeem via Package</small>
                    <div>
                      <Switch
                        name={`UseCredit`}
                        onChange={(e: any) => handleRedeemPackage(e.value)}
                        checked={redeem}
                        disabled={btnLoading}
                      />
                    </div>
                  </div>
                </div>
                {!complimentary && (
                  <>
                     
                      {redeem ? (
                        <div className="bzc-provider-name col-md-4">
                          <small>Packages</small>
                          <div className="bzc-time-sec">
                            <DropDownList
                              id={`packagesSelection`}
                              name={`packagesSelection`}
                              // value={item1?.SelectedPackage}
                              data={packagesListToUse}
                              itemRender={(li: any, itemProps: ListItemProps) =>
                                itemClassRender(li, itemProps, SelectedPackage)
                              }
                              onChange={(e: any) =>
                                handlePackageSelectionForClass(e?.value)
                              }
                              disabled={btnLoading}
                              valueRender={(
                                el: React.ReactElement<HTMLSpanElement>,
                                value: any
                              ) =>
                                itemClassValueRender(el, value, SelectedPackage)
                              }
                              textField="Name"
                              // style={{width:'35%'}}
                              dataItemKey="PackageInstanceId"
                              className="customDropdown packageSelectionDropdown "
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="bzc-provider-name col-md-4">
                            <small>Payment Profile</small>
                            <div className="bzc-time-sec d-flex">
                              <DropDownList
                                className="w-100"
                                id={"selectedPayment"}
                                name={"selectedPayment"}
                                listNoDataRender={() => (
                                  <span className={"bzo-paymentProfile-noData"}>
                                    No Payment Methods
                                  </span>
                                )}
                                data={paymentProfile}
                                textField="MaskedCCNumber"
                                dataItemKey="PaymentProfileID"
                                itemRender={(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) =>
                                PaymentItemRender(li, { ...itemProps, makePaymentProfileAsDefault })
                                }
                                valueRender={(
                                  el: React.ReactElement<HTMLSpanElement>,
                                  value: any
                                ) =>
                                  PaymentValueRender(
                                    el,
                                    value,
                                    selectedPaymentProfile
                                  )
                                }
                                disabled={btnLoading}
                                onChange={(e) => {
                                  handleChangeSavedCard(e.value);
                                }}
                              />
                              {checkIfUserAddPaymentGateway() && (
                                <div className="addNewBtn mt-0">
                                  <DropDownButton

                                    text="Add New"
                                    disabled={btnLoading}
                                    onItemClick={(e: any) => onCardOrBankAdd(e)}
                                    items={paymentDropdownItems()?.filter(
                                      (i: any) => i?.visible === true
                                    )}
                                    look="flat"
                                    className="btn btn-link add-new-btn otherpaybtn"
                                    popupSettings={{
                                      popupClass: "payNow-addCard-popup",
                                      anchorAlign: {
                                        horizontal: "right",
                                        vertical: "bottom",
                                      },
                                      popupAlign: {
                                        horizontal: "right",
                                        vertical: "top",
                                      },
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          {selectedPaymentProfile?.CardTypeId === -2 && (
                      <>
                        <div className="col-md-4">
                          <div className="bzc-provider-name">
                            <small>Transaction Reference Number</small>
                            <div className="bzc-time-sec">
                              <Input
                                onChange={(event) =>
                                  setRefNumber(event?.target?.value)
                                }
                                value={refNumber}
                                type={"text"}
                                style={{ width: "100%" }}
                                id={"refNumber"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 referencedocument">
                          <div className="bzc-provider-name">
                            <small>Reference Document</small>
                            <div className="bzc-time-sec">
                              {/* <input type="file" /> */}
                              <Upload
                                autoUpload={false}
                                batch={false}
                                restrictions={{
                                  // allowedExtensions: [".jpg", ".png", ".jpeg"],
                                  maxFileSize: 1000000,
                                }}
                                showActionButtons={false}
                                multiple={false}
                                onAdd={onAdd}
                                withCredentials={false}
                                onRemove={onRemove}
                                files={originalFiles}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                            {!props?.isClient ? (
                              <>
                                <div className="bzc-provider-name col-md-4">
                                  <small>Select Coupons</small>
                                  <div className="bzc-time-sec d-flex">
                                    <MultiSelect
                                      data={filterCoupondata}
                                      textField="Description"
                                      dataItemKey="CouponId"
                                      placeholder="Please select coupon"
                                      onChange={(e) => handleGroupCoupon(e.value)}
                                      filterable={true}
                                      onFilterChange={filterCouponChange}
                                      itemRender={itemRenderCoupon}
                                      value={appliedCoupons}
                                      className="bz-multiselect-arrow"
                                    />
                                  </div>
                                </div>
                               
                              </>
                            ) : (
                              <>
                                {appliedCoupons.length > 0 && (
                                <div className="row">
                                  <div className="coupon-list mt-3 col-md-4">
                                    <ListView data={appliedCoupons} item={MyCouponItemRender} />
                                  </div>
                                
                                  </div>
                                )}
                                  {appliedCoupons.length === 0 && CouponApplyField()}
                                </>
                            )}

                          </>
                      )}
                   
                   
  
                  </>
                )}
              </div>
              <div className="row">
                {!props?.isClient && !complimentary && !redeem &&(
                  <div className="col-md-4">
                    {appliedCoupons.length > 0 && (
                      <div className="coupon-list mt-3">
                        <ListView data={appliedCoupons} item={MyCouponItemRender} />
                      </div>
                    )}
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  const handleChangeCoupon = (val: any) => {
    setCoupon(val);
  };

  const handleGroupChangeCoupon = async (val: any) => {
    if (val !== "" && val !== undefined) {
      setCouponBtnLoading(true);
      const couponService = new TenantService();
      let req = {
        UserMemberId: props?.payNowItem?.UserMemberId,
        EntityId: props?.payNowItem?.ClassId,
        EntityType: 3,
        CouponName: val,
      };

      const result = await couponService.findCoupon(req);
      setCouponBtnLoading(false);
      if (result?.ResponseCode === 100) {
        if (result.Items.length === 0) {
          const errorMsg = "Please enter valid coupon";
          setMessage(errorMsg);
          setNotification({ ...notification, error: true, success: false });
          return;
        } else {
          const res = result.Items[0];
          const data = [{
            Amount: res?.Amount,
            CouponId: res?.CouponId,
            CouponInformation: res?.CouponInformation,
            Description: res?.CouponInformation,
            Type: res?.Type,
            TypeDescription: res?.TypeDescription,
            AvailableCount: res?.AvailableCount,
            CouponAppliedCount: res?.CouponAppliedCount,
            DurationType: res?.DurationType,
          }];
          handleGroupCoupon(data);

          const successMsg = "Coupon Applied Successfully";
          setMessage(successMsg);
          setNotification({ ...notification, error: false, success: true });
        }
      } else {
        const errorMsg = result?.ErrorMessages?.[0];
        setMessage(errorMsg);
        setNotification({ ...notification, error: true, success: false });
      }
    } else {
      const errorMsg = "Please enter Coupon";
      setMessage(errorMsg);
      setNotification({ ...notification, error: true, success: false });
    }
  };


  const CouponApplyField = () => {
    return (
      <Form
        render={(couponRenderForm: any) => (
          <>
            <FormElement style={{ width: "100%" }}>
              <div className="row bz-coupon-field mt-0">
                <div className="col-md-12">
                  <div className="input-group mb-0">
                    <Field
                      id={"coupon"}
                      name={"coupon"}
                      component={FormInput}
                      placeholder="Enter Coupon Code"
                      hidelabel={true}
                      onChange={(e) => {
                        handleChangeCoupon(e.value);
                      }}
                      value={props.coupon}
                    />
                    <div style={{ marginLeft: "15px", marginTop: "15px" }}>
                      <BuzopsButton
                        primary
                        label={"Apply"}
                        disable={couponBtnLoading}
                        loading={couponBtnLoading}
                        key={couponBtnLoading.toString()}
                        onClick={() => handleGroupChangeCoupon(coupon)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </FormElement>
          </>
        )}
      />
    );
  };
  

  const handleSubmit = async () => {
    if (!redeem && !complimentary && !selectedPaymentProfile?.PaymentGatewayPaymentProfileID) {
      const errorMsg = `Please select/add Payment profile`;
      setMessage(errorMsg);
      setNotification({ ...notification, error: true, success: false });
      return;
    }

    if (redeem && !SelectedPackage) {
      const errorMsg = `Please select Package to Redeem`;
      setMessage(errorMsg);
      setNotification({ ...notification, error: true, success: false });
      return;
    }
    const {
      DownPayment,
      ClassId,
      ClassSessionId,
      ScheduleId,
    } = props?.payNowItem;
    if(!redeem && !complimentary && selectedPaymentProfile?.CardTypeId === 8 && DownPayment > selectedPaymentProfile?.Credit && !selectedPaymentAddProfile.AlternatePaymentProfileId) {
      // const errorMsg = CreditsErrorMessage
      // setMessage(errorMsg);
      // setNotification({ ...notification, error: true, success: false });
      // return;
      let defaultPaymentProfile=null
        const existedPP=paymentProfile?.filter((i:any)=> i?.CardTypeId !== -1 && i.CardTypeId !==8)
        if(existedPP?.length > 0){
          defaultPaymentProfile=existedPP[0]
        }
        setShowAdditionalPayment({
          dialog:true,
          selectedPaymentProfile:defaultPaymentProfile
        })
        return true;
    }
    let CollectTax = false;
    let paymentDetails: any = {};
    if (!redeem && !complimentary) {
      const {
        CustomerProfileID,
        PaymentProfileID,
        PaymentGatewayPaymentProfileID,
        CardDescription,
        Credit
      } = selectedPaymentProfile;
      paymentDetails = {
        CustomerProfileID,
        PaymentProfileID,
        PaymentGatewayPaymentProfileID,
        CardDescription,
        Credit
      };
      if (checkHasTaxEnable() && selectedClass?.CanCollectTaxes) {
        CollectTax = !IsTaxSkipped;
      }
    }

    let paymentAttributes = null;
    if (selectedPaymentProfile?.CardTypeId === -2) {
      paymentAttributes = {
        Reference: refNumber,
        OfflinePaymentDescription: selectedPaymentProfile?.MaskedCCNumber,
        ReferrenceDocumentName: files[0]?.FileName || "",
        ReferrenceDocumentContent: files[0]?.ImageAsBase64 || "",
      };
    } else if (selectedPaymentProfile?.CardTypeId === -1) {
      paymentAttributes = {
        Reference: "",
        OfflinePaymentDescription: "Cash",
        ReferrenceDocumentName: "",
        ReferrenceDocumentContent: "",
      };
    }
    let TotalAmount = (couponAmount > DownPayment || isPercentCoupon) ? 0 : DownPayment - couponAmount;

    let req = {
      PaymentAttributes: paymentAttributes,
      DownPayment :TotalAmount,
      Client: clientSubmitDetails,
      ClassSessionDetails: [
        {
          ClassId,
          ClassSessionId,
          ScheduleId,
          PayBy: redeem ? 1 : complimentary ? 5 : 0,
          PaymentSourceId: redeem
            ? SelectedPackage?.PackageInstanceId
            : selectedPaymentProfile?.PaymentGatewayPaymentProfileID,
          CanCollectTaxes: CollectTax,
          Taxes: CollectTax ? Taxes : [],
          TotalTaxAmount: CollectTax ? TotalTaxAmount : 0,
          Cost: DownPayment,
          Coupons:appliedCoupons
        },
      ],
      HasTermsAndConditionAccepted: true,
      PaymentProfile: paymentDetails,
    };

    let purchaseAmt= !IsTaxSkipped ? DownPayment + TotalTaxAmount : DownPayment
    purchaseAmt=CustomToFixed(purchaseAmt,2)
    if (selectedPaymentProfile?.CardTypeId === 8 && purchaseAmt > selectedPaymentProfile?.Credit) {
      const AlternatePaymentProfileId = selectedPaymentAddProfile?.AlternatePaymentProfileId
      const AmountChargeByAlternatePaymentProfile = CustomToFixed(purchaseAmt - selectedPaymentProfile?.Credit, 2)
      req = {
        ...req,
        PaymentProfile: {
          ...selectedPaymentProfile,
          AlternatePaymentProfiles: [
            {
              AlternatePaymentProfileId: AlternatePaymentProfileId,
              AmountChargeByAlternatePaymentProfile: AmountChargeByAlternatePaymentProfile
            }
          ]
        }
      };
    }else{
      req = {
        ...req,
        PaymentProfile: {
          ...selectedPaymentProfile,
          AlternatePaymentProfiles: [
            {
              AlternatePaymentProfileId: 0,
              AmountChargeByAlternatePaymentProfile: 0
            }
          ]
        }
      };
    }
    setBtnLoading(true);
    const checkoutClass = new ClassService();
    const result = await checkoutClass.purchaseClass(req);
    setBtnLoading(false);
    if (result?.ResponseCode === 100) {
      let successMsg = `Appointment(s) Purchased Successfully`;
      if (complimentary || redeem) {
        successMsg = `Appointment Redeemed Successfully`;
      }
      setMessage(successMsg);
      setNotification({ ...notification, success: true, error: false });
      setSubmit(true);
      if (typeof props?.onSuccessfullPayment === 'function') {
        props.onSuccessfullPayment();
      }
      
    } else {
      const errorMsg = result?.ErrorMessages?.[0];
      setMessage(errorMsg);
      setNotification({ ...notification, error: true, success: false });
    }
  };

  const handleChangeAdditionalPaymentProfile = async (value: any) => {
    setShowAdditionalPayment({
      ...showAdditionalPayment,selectedPaymentProfile:value
    })
  }

  const handleAddAdditionalPayment=()=>{
    if(!showAdditionalPayment?.selectedPaymentProfile?.PaymentGatewayPaymentProfileID){
      const errorMsg = "Please select/add Payment profile";
      props?.handleNotificationMessage(errorMsg, "error");
      return
    }
    setSelectedPaymentAddProfile({
      ...selectedPaymentProfile,AlternatePaymentProfileId:showAdditionalPayment?.selectedPaymentProfile?.PaymentGatewayPaymentProfileID
    })
    setShowAdditionalPayment(initialAdditionalPayment)
  }

  const handleNotification = (type: string) => {
    setNotification({ ...notification, [type]: false });
  };
  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  const Attributes = props?.classSession?.Attributes;
  return (
    <>
      <Card className="m-0 bz-attendeesList-container bz-cancellation-page">
        <CardHeader className="d-flex justify-content-between px-0 pt-1 pb-0">
          {props?.fromDashboard && (
            <>
              <h5 className="mb-1 bz_fs_1_5">
                <button
                  className="btn btn-primary bz-back-btn btn-sm"
                  onClick={() => props.handleWindow()}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>
                &nbsp;Pay Now
              </h5>
            </>
          )}
        </CardHeader>
        <CardBody className="px-0">
          <>
            <div className="card bzc-sessions-list-card bz-sessionRoaster-card bz-default-cursor mb-3 py-2">
              <div className="bzc-sessions-list-row">
                <div className="bzc-date-sec d-flex">
                  {props?.classSession?.ClassName}
                  {Attributes && Attributes !== "" && Attributes !== "null" && (
                    <Location
                      Attributes={Attributes}
                      showDetails={true}
                      VirtualMeetingLink={
                        props?.classSession?.VirtualMeetingLink
                      }
                      clientPhnNum={props?.classSession?.Phone}
                    />
                  )}
                </div>
                <div className="bzc-date-sec">
                  <i className="far fa-calendar-alt mr-0"></i>
                  {getWeekdayFormat(props?.classSession?.ClassSessionStartDate)}
                  ,&nbsp;
                  {getDayFormat(props?.classSession?.ClassSessionStartDate)}
                  &nbsp;
                  {getMonthFormat(props?.classSession?.ClassSessionStartDate)}
                  &nbsp;
                  {getYearFormat(props?.classSession?.ClassSessionStartDate)}
                </div>
                <div className="bzc-date-sec">
                  <span className={""}>
                    <i className="far fa-clock mr-1"></i>
                    {getTimeFormat(
                      props?.classSession?.ClassSessionStartDate
                    )}{" "}
                    - {getTimeFormat(props?.classSession?.ClassSessionEndTime)}
                  </span>
                </div>
                {!props?.isClient && checkHasTaxEnable() && selectedClass?.CanCollectTaxes && <div className="bzc-date-sec">
                  Surcharges: <Switch
                    checked={!IsTaxSkipped}
                    defaultChecked={false}
                    name={`IsTaxSkipped`}
                    onChange={(e: any) =>
                      setIsTaxSkipped(!IsTaxSkipped)
                    }
                    className="mr-1 d-inline-flex"
                  />
                </div>}

              </div>
            </div>
            {AttendesRender()}
            <div className={"pt-2"}>
              <Button
                onClick={() => props.handleWindow()}
                disabled={btnLoading}
                className="ml-0"
              >
                Cancel
              </Button>
              <BuzopsButton
                label={redeem || complimentary ? "Redeem" : "Pay"}
                disabled={btnLoading}
                loading={btnLoading}
                onClick={handleSubmit}
              />
            </div>
          </>
        </CardBody>
      </Card>
      <BuzopsNotification
        notification={notification}
        message={message}
        handleNotification={(type: any) => handleNotification(type)}
      />
      {showPaymentDialog && PaymethodDialog()}
      {showAdditionalPayment?.dialog &&
        <AdditionalPayment
          fetchPaymentProfiles={fetchPaymentProfiles}
          userMemberId={props?.payNowItem?.UserMemberId}
          setShowAdditionalPayment={setShowAdditionalPayment}
          paymentProfile={paymentProfile}
          PurchaseAmount={!IsTaxSkipped ? TotalTaxAmount + props?.classSession.Cost : props?.classSession}
          CreditAmount={selectedPaymentProfile?.Credit}
          showAdditionalPayment={showAdditionalPayment}
          handleChangeAdditionalPaymentProfile={handleChangeAdditionalPaymentProfile}
          handleAddAdditionalPayment={handleAddAdditionalPayment}
        />}
    </>
  );
};

export default PayNowSession;
