import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import {
  Checkbox,
  NumericTextBox,
} from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import withNotification from "components/Hoc/withNotification";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import { CheckUserMemberStatus } from "utils";
import AddonUnfreeze from "./AddonUnfreeze";
import { useDispatch, useSelector } from "react-redux";
import { SetNavigateToAPageOnButtonClick } from "redux-slices/genericSlice";
import { CreditsErrorMessage } from "utils/form-utils";
import { useApiClient } from "services/axios-service-utils";

const AddonFreeze = (props: any) => {
  const {axiosRequest}=useApiClient()
  const dispatch = useDispatch()
  const [freezeDetails, setFreezeDetails] = useState<any>({});
  const [paymentProfiles, setPaymentProfiles] = useState<any>([]);
  const [selectedPaymentProfile, setSelectedPaymentProfile] =
    useState<any>(undefined);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [unfreezePage, setUnfreezePage] = useState(false);
  const [unfreezeDetails, setUnfreezeDetails] = useState<any>({});
  const service = new TenantService();

  const [selectedAddon, setSelectedAddon] = useState<any>({});
  const genericReducersubscription = useSelector((state : any) => {
    if(selectedAddon !== state.generic.selectedAddon){
      setSelectedAddon(state.generic.selectedAddon)
    }
  })

  useEffect(() => {
    fetchAllApis();
  }, []);

  const fetchAllApis = async () => {
    setLoading(true);
    const res = await fetchFreezeDetails();
    await fetchPaymentProfiles(props?.userData?.MainUserId, res);
    setLoading(false);
  };

  const fetchFreezeDetails = async () => {
    const entityId = selectedAddon? selectedAddon.id : 0
    const req = {
      SubscriptionId: entityId,
    };
    const result = await service.GetAddonFreezeDetails(req);
    setFreezeDetails(result);
    return result;
  };


  async function fetchPaymentProfiles(userMemberId: any, freezeData:any=null) {
    const result = await service.paymentProfile(userMemberId);
    setPaymentProfiles(result || []);
    if (freezeData?.PaymentGatewayProfileId) {
      const test = result.filter(
        (item: any) =>
          item.PaymentGatewayPaymentProfileID ===
          freezeData?.PaymentGatewayProfileId
      )?.[0];
      setSelectedPaymentProfile(test);
    }else{
      const defaultPP = result.find((card: any) => card.IsDefault);
      if(defaultPP){
        setSelectedPaymentProfile(defaultPP)
      }
    }
  }

  const showErrorMessage = (key: any, mainKey: any) => {
    if (
      key === "PaymentGatewayProfileId" &&
      freezeDetails?.FreezeRate > 0 &&
      (!freezeDetails[key] || freezeDetails[key] === 0)
    ) {
      return <Error>{`${mainKey} is required`}</Error>;
    } else if (
      key === "UnfreezeComment" &&
      (!unfreezeDetails[key] || unfreezeDetails[key].length <= 0)
    ) {
      return <Error>{`${mainKey} is required`}</Error>;
    } else if (key === "MaximumBillingCycle") {
      if (freezeDetails?.RemainingCycle <= 0) {
        if (freezeDetails[key] > 100 || freezeDetails[key] < 1) {
          return (
            <Error>{`The Maximum billing cycle should be in between 1 to 100 value.`}</Error>
          );
        }
      } else {
        if (freezeDetails[key] > freezeDetails?.RemainingCycle  || freezeDetails[key] < 1) {
          return (
            <Error>{`The Maximum billing cycle should be in between 1 to ${freezeDetails?.RemainingCycle} value.`}</Error>
          );
        }
      }
    } else if (
      key !== "PaymentGatewayProfileId" &&
      (!freezeDetails[key] || freezeDetails[key].length <= 0)
    ) {
      return <Error>{`${mainKey} is required`}</Error>;
    }
    return <></>;
  };



  const makePaymentProfileAsDefault = async (dataItem: any) => {
    const req = {
      PaymentGatewayPaymentProfileID: dataItem?.PaymentGatewayPaymentProfileID,
      UserMemberId: props?.userData?.MainUserId,
      CustomerProfileID:
        dataItem?.CustomerProfileID,
    };
    await axiosRequest.post(
      `PaymentProfile/update-preferred-payment-profile`,
      req,
      {
        successCallBack: async (response: any) => {
          props?.handleNotificationMessage(
            `Selected Payment profile marked as Preferred`,
            "success"
          );
          await fetchPaymentProfiles(props?.userData?.MainUserId);
        },
        errorCallBack: (response: any) => {
          props?.handleNotificationMessage(
            response?.response?.data?.Messages?.[0] ||
              response?.data?.Messages?.[0] ||
              "Internal server error",
            "error"
          );
        },
      }
    );
  };

  const handleChange = async (value: any, name: string) => {
    let updatedCancellationDetails = freezeDetails;
    if (name === "paymentProfile") {
      const PaymentGatewayPaymentProfileID =
        value?.PaymentGatewayPaymentProfileID;
      updatedCancellationDetails = {
        ...freezeDetails,
        PaymentGatewayProfileId: PaymentGatewayPaymentProfileID,
        CardTypeId: value?.CardTypeId,
        Credit: value?.Credit,
      };
      setSelectedPaymentProfile(value);
    } else if (name === "MaximumBillingCycle") {
      let val = value;
      if (value <= 1) {
        val = 1;
      }
      updatedCancellationDetails = {
        ...freezeDetails,
        [name]: val,
      };
    } else {
      updatedCancellationDetails = {
        ...freezeDetails,
        [name]: value,
      };
    }
    setFreezeDetails(updatedCancellationDetails);
  };
  const handleUnfreezeChange = async (value: any, name: string) => {
    let dataItem = unfreezeDetails;
    if (name === "paymentProfile") {
      const PaymentGatewayPaymentProfileID =
        value?.PaymentGatewayPaymentProfileID;
      dataItem = {
        ...unfreezeDetails,
        PaymentGatewayProfileId: PaymentGatewayPaymentProfileID,
        CardTypeId: value?.CardTypeId,
        Credit: value?.Credit,
      };
      setSelectedPaymentProfile(value);
    } else {
      dataItem = {
        ...unfreezeDetails,
        [name]: value,
      };
    }
    setUnfreezeDetails(dataItem);
  };

  const handleValidation = () => {
    if (!freezeDetails?.Comments || freezeDetails?.Comments === "") {
      return false;
    }
    if (
      freezeDetails?.FreezeRate > 0 &&
      (!freezeDetails?.PaymentGatewayProfileId ||
        freezeDetails?.PaymentGatewayProfileId === 0)
    ) {
      return false;
    }
    if (
      freezeDetails?.FreezeRate > 0 &&
      (freezeDetails?.PaymentGatewayProfileId && freezeDetails?.CardTypeId === 8 && freezeDetails?.FreezeRate > freezeDetails?.Credit)
    ) {
      const errorMsg = CreditsErrorMessage
      props?.handleNotificationMessage(errorMsg, "error");
      return false;
    }

    if(freezeDetails?.IsWithDrawAvailable && (!freezeDetails?.WithdrawComments || freezeDetails?.WithdrawComments === "")){
      return false
    }
    if (freezeDetails?.RemainingCycle <= 0) {
      if (freezeDetails?.MaximumBillingCycle > 100 || freezeDetails?.MaximumBillingCycle < 1) {
        return false;
      }
    } else {
      if (freezeDetails?.MaximumBillingCycle > freezeDetails?.RemainingCycle || freezeDetails?.MaximumBillingCycle < 1) {
        return false;
      }
    }
    return true;
  };
  const handleApproveFreeze = async () => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    const check = handleValidation();
    if (check) {
      setBtnLoading(true);
      const result = await service.ApproveFreeze(freezeDetails);
      setBtnLoading(false);
      if (result?.ResponseCode === 100) {
        const successMsg = result?.SuccessMessage || `Addon Successfully Freezed`;
        props?.handleNotificationMessage(successMsg, "success");
        await fetchAllApis();
      } else {
        const errorMsg =
          result?.ErrorMessages.length > 0
            ? result?.ErrorMessages[0]
            : "Error in Freezing Addon";
        props?.handleNotificationMessage(errorMsg, "error");
      }
    }
  };
  const handleWithdrawFreeze = async () => {
    if (
      !freezeDetails?.WithdrawComments ||
      freezeDetails?.WithdrawComments === ""
    ) {
      return;
    }
    setBtnLoading(true);
    const result = await service.WithDrawFreeze(freezeDetails);
    setBtnLoading(false);
    if (result?.ResponseCode === 100) {
      const successMsg = result?.SuccessMessage || `Addon Successfully Withdrawn Freeze`;
      props?.handleNotificationMessage(successMsg, "success");
      await fetchAllApis();
    } else {
      const errorMsg =
        result?.ErrorMessages.length > 0
          ? result?.ErrorMessages[0]
          : "Error in Withdaw Freeze Addon";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const handleUnfreeze = async () => {
    if (
      !unfreezeDetails?.UnfreezeComment ||
      unfreezeDetails?.UnfreezeComment === ""
    ) {
      return;
    }
    setBtnLoading(true);
    const result = await service.addonUnFreeze(unfreezeDetails);
    setBtnLoading(false);
    if (result?.ResponseCode === 100) {
      const successMsg = result?.SuccessMessage || `Addon Successfully UnFreezed`;
      props?.handleNotificationMessage(successMsg, "success");

      setUnfreezePage(false);
      await fetchAllApis();
    } else {
      const errorMsg =
        result?.ErrorMessages.length > 0
          ? result?.ErrorMessages[0]
          : "Error in Withdaw Freeze Addon";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const RedirectToUnFreeze = async () => {
    const entityId = selectedAddon? selectedAddon.id : 0
    const req = {
      SubscriptionId: entityId,
    };
    const result = await service.GetAddonUnfreezeDetails(req);
    setUnfreezeDetails(result);
    if (result?.PaymentGatewayProfileId) {
      const test = paymentProfiles.filter(
        (item: any) =>
          item.PaymentGatewayPaymentProfileID ===
          result?.PaymentGatewayProfileId
      )?.[0];
      setSelectedPaymentProfile(test);
    }
    setUnfreezePage(true);
  };
  const renderFreezeStartDate = () => {
    const {
      FreezeStatus,
      FreezeStartDate,
      ImmediateFreeze,
      ImmediateFreezeStartDate,
      NormalFreezeStartDate,
    } = freezeDetails;
    let date = null;
    if (FreezeStatus === 2 || FreezeStatus === 4) {
      date = FreezeStartDate;
    } else if (FreezeStatus === 1 && ImmediateFreeze) {
      date = ImmediateFreezeStartDate;
    } else if (FreezeStatus === 1 && !ImmediateFreeze) {
      date = NormalFreezeStartDate;
    }
    return <span>{date === null ? "NA" : moment(date).format("L")}</span>;
  };
  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  if (unfreezePage) {
    return (
      <>
        <AddonUnfreeze
          unfreezeDetails={unfreezeDetails}
          paymentProfiles={paymentProfiles}
          selectedPaymentProfile={selectedPaymentProfile}
          handleUnfreeze={handleUnfreeze}
          handleUnfreezeChange={(val: any, name: string) =>
            handleUnfreezeChange(val, name)
          }
          showErrorMessage={(key: any, mainKey: any) =>
            showErrorMessage(key, mainKey)
          }
          btnLoading={btnLoading}
          makePaymentProfileAsDefault={makePaymentProfileAsDefault}
        />
      </>
    );
  }

  const {
    SubscribedTo,
    MemberName,
    Occurrence,
    RemainingCycle,
    FreezeStatus,
    ImmediateFreeze,
    ApprovedDate,
    ApprovedBy,
    FreezeEndDate,
    DisableImmediateFreeze,
    MaximumBillingCycle,
    FreezeRate,
    Comments,
    WithdrawComments,
    IsWithDrawAvailable,
    WithDrawFee,
    FreezeWith,
  } = freezeDetails;
  return (
    <>
      <h5 className="mb-3 bz_fs_1_5"> <button
          className="btn btn-primary bz-back-btn btn-sm"
          onClick={() => dispatch(SetNavigateToAPageOnButtonClick({ id: 136, parent: false }))}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        &nbsp;Add-On Freeze</h5>
      <hr />
      <div className="bz-subsection-row bz-plan-overview bz-addon-cancellation">
        <div className="left-section">
          <h5 className="mb-3 pt-3 bz_fs_1_5">Add-on Details</h5>
          <div className="bz-mt-2rem bz-mb-2_5rem bz-content-text-row-mobile">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Add-on Name</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">{`${SubscribedTo}`}</div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Client Name</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">{MemberName}</div>
            </div>

            {Occurrence !== 0 && (
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">
                  Remaining billing cycles
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">{RemainingCycle}</div>
              </div>
            )}
            {FreezeStatus !== 0 && FreezeStatus !== 3 && FreezeStatus !== 4 && (
              <>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">
                    Freeze Start Date
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    {renderFreezeStartDate()}
                  </div>
                </div>
              </>
            )}

            {FreezeStatus === 2 && (
              <>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">Approved on</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    {ApprovedDate === null
                      ? "NA"
                      : moment(ApprovedDate).format("L")}
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">Approved By</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">{ApprovedBy}</div>
                </div>
                {Occurrence !== 0 && (
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right">End Date</div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      {FreezeEndDate === null
                        ? "NA"
                        : moment(FreezeEndDate).format("L")}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <hr />
          {FreezeStatus !== 0 && FreezeStatus !== 3 && FreezeStatus !== 4 && (
            <>
              <div className="bz-mt-2rem bz-mb-2_5rem">
                <div className="bz-content-text-row align-items-start">
                  <div className="bz-content-title text-right">
                    Freeze without 1 billing notice
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <Checkbox
                      value={ImmediateFreeze}
                      onChange={(e) => handleChange(e.value, "ImmediateFreeze")}
                      disabled={DisableImmediateFreeze}
                    />
                    {ImmediateFreeze && (
                      <p
                        style={{ color: "blue" }}
                        className="notificxation-blue mb-0"
                      >
                        {
                          "Addon will freeze on the next recurring billing date."
                        }
                      </p>
                    )}
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">
                    Maximum billing cycles on Freeze
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <NumericTextBox
                      id={`MaximumBillingCycle`}
                      name={`MaximumBillingCycle`}
                      min={1}
                      className="k-dropdown-width-100"
                      disabled={
                        FreezeStatus === 0 ||
                        FreezeStatus === 2 ||
                        FreezeStatus === 4
                      }
                      value={MaximumBillingCycle}
                      onChange={(e) =>
                        handleChange(e.target.value, "MaximumBillingCycle")
                      }
                    />
                    {showErrorMessage(
                      "MaximumBillingCycle",
                      "MaximumBillingCycle"
                    )}
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">Freeze Rate</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <NumericTextBox
                      id={`FreezeRate`}
                      name={`FreezeRate`}
                      min={0}
                      disabled={
                        FreezeStatus === 0 ||
                        FreezeStatus === 2 ||
                        FreezeStatus === 4
                      }
                      format={"c2"}
                      className="k-dropdown-width-100"
                      value={FreezeRate}
                      onChange={(e) =>
                        handleChange(e.target.value, "FreezeRate")
                      }
                    />
                  </div>
                </div>
                {FreezeRate > 0 && (
                  <>
                    <div className="bz-content-text-row">
                      <div className="bz-content-title text-right">
                        Payment Profile
                      </div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body">
                        <DropDownList
                          id={`PaymentGatewayProfileId`}
                          name={`PaymentGatewayProfileId`}
                          data={paymentProfiles}
                          textField="MaskedCCNumber"
                          disabled={
                            FreezeStatus === 0 ||
                            FreezeStatus === 2 ||
                            FreezeStatus === 4 ||
                            FreezeRate === 0
                          }
                          valueRender={(
                            el: React.ReactElement<HTMLSpanElement>,
                            value: any
                          ) =>
                            PaymentValueRender(
                              el,
                              value,
                              selectedPaymentProfile
                            )
                          }
                           itemRender={(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) =>
                            PaymentItemRender(li, { ...itemProps, makePaymentProfileAsDefault })
                            }
                          onChange={(e) => {
                            handleChange(e.value, "paymentProfile");
                          }}
                          style={{ width: "100%" }}
                        />
                        {showErrorMessage(
                          "PaymentGatewayProfileId",
                          "Payment Profile"
                        )}
                      </div>
                    </div>
                  </>
                )}

                {FreezeStatus !== 0 &&
                  FreezeStatus !== 3 &&
                  FreezeStatus !== 4 && (
                    <>
                      <div className="bz-content-text-row align-items-start">
                        <div className="bz-content-title text-right">
                          Comments
                        </div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">
                          <textarea
                            className="form-control"
                            onChange={(e) =>
                              handleChange(e.target.value, "Comments")
                            }
                            value={Comments}
                            disabled={
                              FreezeStatus === 0 ||
                              FreezeStatus === 2 ||
                              FreezeStatus === 4
                            }
                          ></textarea>
                          {showErrorMessage("Comments", "Comments")}
                        </div>
                      </div>
                    </>
                  )}

                {IsWithDrawAvailable && (
                  <>
                    <div className="bz-content-text-row">
                      <div className="bz-content-title text-right">
                        Withdraw Comments
                      </div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body">
                        <textarea
                          className="form-control"
                          onChange={(e) =>
                            handleChange(e.target.value, "WithdrawComments")
                          }
                          value={WithdrawComments}
                        ></textarea>
                        {showErrorMessage(
                          "WithdrawComments",
                          "Withdraw Comments"
                        )}
                      </div>
                    </div>

                    {WithDrawFee > 0 && (
                      <div className="bz-content-text-row">
                        <div className="bz-content-title text-right">
                          Prorated Rate
                        </div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">
                          <NumericTextBox
                            id={`WithDrawFee`}
                            name={`WithDrawFee`}
                            min={0}
                            disabled={true}
                            format={"c2"}
                            className="k-dropdown-width-100"
                            value={WithDrawFee}
                            onChange={(e) =>
                              handleChange(e.target.value, "WithDrawFee")
                            }
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
          {FreezeStatus === 1 && (
            <>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right"></div>
                <span className="bz-colon text-center"></span>
                <div className="bz-content-body">
                  <BuzopsButton
                    loading={btnLoading}
                    disabled={btnLoading}
                    label={"Complete"}
                    onClick={() => handleApproveFreeze()}
                  />
                </div>
              </div>
            </>
          )}
          {IsWithDrawAvailable && (
            <>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right"></div>
                <span className="bz-colon text-center"></span>
                <div className="bz-content-body">
                  <BuzopsButton
                    loading={btnLoading}
                    disabled={btnLoading}
                    label={"Withdraw"}
                    onClick={() => handleWithdrawFreeze()}
                  />
                </div>
              </div>
            </>
          )}
          {IsWithDrawAvailable === false &&
            FreezeStatus === 2 &&
            FreezeWith === 1 && (
              <>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right"></div>
                  <span className="bz-colon text-center"></span>
                  <div className="bz-content-body">
                    <BuzopsButton
                      loading={btnLoading}
                      disabled={btnLoading}
                      label={"UnFreeze"}
                      onClick={() => RedirectToUnFreeze()}
                    />
                  </div>
                </div>
              </>
            )}
        </div>
      </div>
    </>
  );
};

export default withNotification(AddonFreeze);
