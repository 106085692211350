import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useEffect } from "react";
import useState from "react-usestateref";
import { PackageService } from "services/package/index.service";
import PaymentMethods from "./../PaymentMethods";
import Transactions from "./../Transactions";
import {
  CheckUserMemberStatus,
  CurrencyFormat,
  CustomToFixed,
  checkHasTaxEnable,
  getLongDate,
} from "utils";
import { NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TooltipContentPackageService } from "components/checkout/OrderSummary";
import { Button } from "@progress/kendo-react-buttons";
import BuzopsButton from "generic-components/BuzopsButton";
import { TenantService } from "services/tenant/index.service";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { Hint, Error } from "@progress/kendo-react-labels";
import moment from "moment";
import { CreditsErrorMessage, PackageInstanceStatusEnum, recurrenceUnits, SessionTypeEnum } from "utils/form-utils";
import CheckPermissions from "utils/permissions";
import { PermissionEnum } from "utils/PermissionEnum";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import {
  customDateCell,
  InstallmentAmtCell,
} from "components/checkout/ShowDownPayment";
import withNotification from "components/Hoc/withNotification";
import { useApiClient } from "services/axios-service-utils";
import { WaivePackagePaymentPermission } from "utils/permission-utils";

const addDates = (date: any, OcurrsEvery: number, unit: string) => {
  if (unit === "Days") {
    let myDate = new Date(date);
    myDate.setDate(myDate.getDate() + OcurrsEvery);
    return myDate;
  } else {
    let myDate = new Date(date);
    myDate.setMonth(myDate.getMonth() + OcurrsEvery);
    return myDate;
  }
};

const PackagePayments = (props: any) => {
  const { axiosRequest } = useApiClient();
  const [loading, setLoading] = useState<any>(true);
  const service = new PackageService();
  const {
    UserMemberId,
    UserId,
    FullName,
    FirstName,
    LastName,
    Email,
    MemberNumber,
    MainUserId,
  } = props?.userData;

  const clientDetails = {
    ClientId: UserMemberId,
    PaymentCards: [],
    UserMemberId: UserMemberId,
    ClientName: FullName,
    UserId: UserId,
    MemberNumber: MemberNumber,
    FirstName: FirstName,
    LastName: LastName,
    PrimaryMemberId: MainUserId || UserMemberId,
    Email: Email,
  };
  const initialAdditionalPayment = {
    dialog: false,
    selectedPaymentProfile: null,
  };
  const [payDueRecurrencetable, setPayDueRecurrencetable] = useState<any>([]);
  const [showAdditionalPayment, setShowAdditionalPayment] = useState<any>(
    initialAdditionalPayment
  );
  // to Installment Amount
  const [installmentAmt, setInstallmentAmt] = useState(0);
  //installment table data to show recurrences
  const [updateRecurrencetable, setUpdateRecurrencetable] = useState<any>([]);
  const [packageInstance, setPackageInstance,packageInstanceRef] = useState<any>(null);
  const [amount, setAmount] = useState(0);
  const [makePayment, setMakePayment] = useState(false);
  const [recurrenceDetails, setRecurrenceDetails] = useState<any>(null);
  const [recurrenceFormDetails, setRecurrenceFormDetails] = useState<any>(null);
  const [IsWaivePayment, setIsWaivePayment] = useState(false);
  const [IsTaxSkipped, setIsTaxSkipped] = useState(true);
  const [payRemDueSubmitLoading, setpayRemDueSubmitLoading] = useState(false);
  const [submistatus, setSubmitStatus] = useState({
    payRemDue: true,
    updateRecurrence: false,
  });
  const [submitPayRemDue, setSubmitPayRemDue] = useState(false);
  //saved payment profiles
  const [paymentProfile, setPaymentProfile] = useState<any>([]);
  //selected payment profile
  const [selectedPaymentProfile, setSelectedPaymentProfile] =
    useState<any>(undefined);
  const [AlternatePaymentProfileId, setAlternatePaymentProfileId] =
    useState<any>(null);
  const [payRemainingDue, setPayRemainingDue] = useState(0);
  const [remDueRecurrence, setRemDueRecurrence] = useState(false);
  const [Taxes, setTaxes] = useState<any>([]);
  const [TotalTaxAmount, setTotalTaxAmount] = useState<number>(0);
  const tommorrow = moment().add(1, "days").format("L");
  const remainingDueRecDetails = {
    recurrenceUnit: { id: 0, text: "Please Select Frequency" },
    NextRecurrence: undefined,
    OccursEvery: "",
    NoOfInstallments: "",
  };
  const [remDueRecurDetails, setRemDueRecurDetails] = useState<any>(
    remainingDueRecDetails
  );
  const [updateRecurrenceSubmitLoading, setUpdateRecurrenceSubmitLoading] =
    useState(false);

  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi = async () => {
    setLoading(true);
    await fetchPackageSummary();
    await fetchTaxes();
    await fetchPaymentProfiles(props?.userData?.MainUserId);
    await fetchRecurringDetails(props?.selectedPackageId);
    setLoading(false);
  };

  const fetchPackageSummary = async () => {
    const req = {
      UserMemberId: props?.userData?.UserMemberId,
      PackageInstanceId: props?.selectedPackageId,
    };
    const res = await service?.packageInstanceFilter(req);
    setPackageInstance(res?.Items[0]);
    const diffAmount = res?.Items[0]?.Cost - res?.Items[0]?.AmountPaid;
    setAmount(diffAmount);
    setPayRemainingDue(diffAmount);
    setIsTaxSkipped(!res?.Items[0]?.CancollectTaxes);
  };

  useEffect(() => {
    if (payRemainingDue >= 0) {
      const amounttoUpdate =
        packageInstance?.Cost - packageInstance?.AmountPaid;
      CalculateRecurrence(
        remDueRecurDetails,
        amounttoUpdate,
        payRemainingDue,
        false
      );
    }
  }, [payRemainingDue]);

  console.log("packageInstance", packageInstance);

  const fetchTaxes = async () => {
    const req = {};
    const taxService = new ManageTaxesService();
    const res = await taxService.getTaxes(req);
    setTaxes(res);
  };

  const fetchRecurringDetails = async (val: any) => {
    const fetchService = new TenantService();
    const result = await fetchService.getPresetPackageService(val);
    if (result) {
      let recurrenceUnit = { id: 2, text: "Days", value: "Day(s)" };
      if (result.RepeatFrequency === 4) {
        recurrenceUnit = { id: 4, text: "Months", value: "Month(s)" };
      }
      const data = {
        recurrenceUnit: recurrenceUnit,
        AmountPending: result?.AmountPending,
        NextRecurrence: result?.NextRecurrence,
        NoOfInstallments: result?.NoOfInstallments,
        OccursEvery: Math.abs(result?.OccursEvery),
        PreviousOccurence: result?.PreviousOccurence,
        PackageInstanceId: result?.PackageInstanceId,
        RecurringCost: result?.RecurringCost,
        UserMemberId: result?.UserMemberId,
        Status: result?.Status,
      };
      setRecurrenceDetails(data);
      setRecurrenceFormDetails(data);
      CalculateRecurrence(data, result?.AmountPending, 0, true);
    }
  };



  //recurrences calculations
  const CalculateRecurrence = (
    item: any,
    totalCost: any,
    paidToday: any,
    check: boolean
  ) => {
    const data = [];
    if (
      paidToday >= 0 &&
      item.recurrenceUnit != undefined &&
      item.NoOfInstallments > 0 &&
      item.NextRecurrence != undefined
    ) {
      if (totalCost >= paidToday) {
        let BalanceAmount = totalCost - paidToday;
        let NoOfInstallments =
          item.NoOfInstallments > 999 ? 999 : item.NoOfInstallments;
        if (BalanceAmount > 0) {
          let InstallmentAmount = BalanceAmount / NoOfInstallments;
          InstallmentAmount = parseFloat(InstallmentAmount.toFixed(2));

          if (!check) {
            setInstallmentAmt(InstallmentAmount);
          }

          let unit = item.recurrenceUnit.text;
          let InstallmentDate = new Date(item.NextRecurrence); //.add(that.Order.Service.OcurrsEvery, unit);
          let totalAmt = 0;
          for (let i = 0; i < NoOfInstallments; i++) {
            totalAmt = totalAmt + InstallmentAmount;
            if (BalanceAmount !== totalAmt && i === NoOfInstallments - 1) {
              InstallmentAmount =
                InstallmentAmount + (BalanceAmount - totalAmt);
            }

            data.push({
              Date: InstallmentDate,
              DisplayDate: getLongDate(InstallmentDate),
              InstallmentAmount: InstallmentAmount,
            });

            InstallmentDate = addDates(
              new Date(InstallmentDate),
              parseInt(item.OccursEvery),
              unit
            );
          }
        }
      }
    }
    if (check) {
      setUpdateRecurrencetable(data);
    } else {
      setPayDueRecurrencetable(data);
    }
  };

  const handleMakePayment = () => {
    setMakePayment(true);
    handleTaxCalculation(payRemainingDue);
  };

  const handleWaivePayment = (val: boolean) => {
    setIsWaivePayment(val);
    //updateServiceData()
  };

  const handleTaxCalculation = (purchaseAmt: number, TaxItems = Taxes) => {
    const TaxesArray = TaxItems.map((i: any) => {
      const amount = (purchaseAmt * i?.Percentage) / 100;
      const calcAmt = CustomToFixed(amount, 2);
      return {
        TaxId: i?.TaxId,
        TaxName: i?.TaxName,
        Percentage: i?.Percentage,
        Amount: calcAmt,
      };
    });
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
      return acc + currentValue?.Amount;
    }, 0);
    setTaxes(TaxesArray);
    setTotalTaxAmount(taxAmt);
  };

  const handleReset = () => {
    setRecurrenceDetails(null);
    setRemDueRecurrence(false);
    setSubmitPayRemDue(false)
    fetchApi();
    setMakePayment(false);
    //updateServiceData()
  };

  //to get the payment profiles
  async function fetchPaymentProfiles(
    userMemberId: any,
    clearAdditionPP = true
  ) {
    const paymentProfileData = new TenantService();
    const result = await paymentProfileData.paymentProfile(userMemberId);
    if (result) {
      if (result.length > 0) {
        const res = result.filter((item: any) => item.CardTypeId !== -1);
        setPaymentProfile(result);
        const test = result.filter(
          (item: any) =>
            item?.PaymentGatewayPaymentProfileID ===
          packageInstanceRef?.current?.PaymentGatewayPaymentProfileId
        );
        if (clearAdditionPP) {
          if (
            test[0]?.CardTypeId !== 8 ||
            (test[0]?.CardTypeId === 8 && test[0]?.Credit > 0)
          ) {
            setSelectedPaymentProfile(test[0]);
          }
          setAlternatePaymentProfileId(null);
          return res;
        }else{
          const defaultPP = result.find((card: any) => card.IsDefault);
          if(defaultPP){
            setSelectedPaymentProfile(defaultPP)
          }
        }
      }
    }
  }

  const handleChangeSavedCard = (val: any) => {
    setSelectedPaymentProfile(val);
    setAlternatePaymentProfileId(null);
  };

  const checkPayRemainingDue = (val: any, packageInstanceItem: any) => {
    let value = val;
    if (!val || val === "") {
      value = 0;
    }
    if (val < 0) {
      value = Math.abs(val);
    }
    const { Cost, AmountPaid } = packageInstanceItem;
    const totalAmtToPay = Cost - AmountPaid;
    if (value === totalAmtToPay) {
      setPayRemainingDue(value);
      setRemDueRecurrence(false);
      setSubmitStatus({
        ...submistatus,
        payRemDue: true,
      });
      handleTaxCalculation(value);
    } else if (value < totalAmtToPay) {
      setPayRemainingDue(value);
      setRemDueRecurrence(true);
      setSubmitStatus({
        ...submistatus,
        payRemDue: true,
      });
      handleTaxCalculation(value);
    } else {
      setPayRemainingDue(totalAmtToPay);
      setRemDueRecurrence(false);
      setSubmitStatus({
        ...submistatus,
        payRemDue: false,
      });
      handleTaxCalculation(totalAmtToPay);
    }
  };

  const handlePayRemainingDue = (val: any, name: string) => {
    const amounttoUpdate = packageInstance?.Cost - packageInstance?.AmountPaid;
    let value = val;
    if (name !== "NextRecurrence" && val < 0) {
      value = Math.abs(val);
    }
    let data = {
      ...remDueRecurDetails,
      [name]: value,
    };
    if (name === "OccursEvery" && parseInt(value) === 0) {
      data = {
        ...remDueRecurDetails,
        [name]: value,
        NoOfInstallments: 1,
      };
    }
    if (name === "NoOfInstallments" && val > 999) {
      data = {
        ...remDueRecurDetails,
        [name]: 999,
      };
    }
    if (name === "NextRecurrence") {
      if (moment(val).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
        data = {
          ...remDueRecurDetails,
          [name]: val,
        };
      } else {
        data = {
          ...remDueRecurDetails,
          [name]: remDueRecurDetails?.NextRecurrence,
        };
      }
    }
    setRemDueRecurDetails(data);
    setSubmitStatus({
      ...submistatus,
      payRemDue: true,
    });
    CalculateRecurrence(data, amounttoUpdate, payRemainingDue, false);
  };

  const showPayRemaingDueErrorMessage = (key: any, mainKey: any) => {
    if (!remDueRecurDetails[key] || remDueRecurDetails[key] <= 0) {
      return <Error>{`Please provide atleast one ${mainKey}`}</Error>;
    }
    return <></>;
  };

  
  const makePaymentProfileAsDefault = async (dataItem: any) => {
    const req = {
      PaymentGatewayPaymentProfileID: dataItem?.PaymentGatewayPaymentProfileID,
      UserMemberId: props?.userData?.MainUserId,
      CustomerProfileID:
        dataItem?.CustomerProfileID,
    };
    await axiosRequest.post(
      `PaymentProfile/update-preferred-payment-profile`,
      req,
      {
        successCallBack: async (response: any) => {
          props?.handleNotificationMessage(
            `Selected Payment profile marked as Preferred`,
            "success"
          );
          await fetchPaymentProfiles(props?.userData?.MainUserId);
        },
        errorCallBack: (response: any) => {
          props?.handleNotificationMessage(
            response?.response?.data?.Messages?.[0] ||
              response?.data?.Messages?.[0] ||
              "Internal server error",
            "error"
          );
        },
      }
    );
  };

  const submitPayRemainigDue = async () => {
    setSubmitPayRemDue(true)
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    if (!selectedPaymentProfile && !IsWaivePayment) {
      props?.handleNotificationMessage(
        "Please select Payment Profile",
        "error"
      );
      return;
    }
    let purchaseAmt = !IsTaxSkipped
      ? payRemainingDue + TotalTaxAmount
      : payRemainingDue;
    purchaseAmt = CustomToFixed(purchaseAmt, 2);
    if (
      selectedPaymentProfile?.CardTypeId === 8 &&
      purchaseAmt > selectedPaymentProfile?.Credit &&
      !AlternatePaymentProfileId
    ) {
      // let defaultPaymentProfile = null;
      // const existedPP = paymentProfile?.filter(
      //   (i: any) => i?.CardTypeId !== -1 && i.CardTypeId !== 8
      // );
      // if (existedPP?.length > 0) {
      //   defaultPaymentProfile = existedPP[0];
      // }
      // setShowAdditionalPayment({
      //   dialog: true,
      //   selectedPaymentProfile: defaultPaymentProfile,
      // });
      const errorMsg = CreditsErrorMessage;
      props?.handleNotificationMessage(errorMsg, "error");
      return;
    }
    const AmounttoPay = packageInstance?.Cost - packageInstance?.AmountPaid;


    if (remDueRecurrence && (!remDueRecurDetails?.OccursEvery || remDueRecurDetails?.OccursEvery <= 0 || !remDueRecurDetails?.NoOfInstallments || remDueRecurDetails?.NoOfInstallments <= 0 || !remDueRecurDetails?.NextRecurrence || moment(remDueRecurDetails?.NextRecurrence).format("L") === "Invalid date"  || remDueRecurDetails?.recurrenceUnit?.id===0)) {
      return
    }
    const recurAmt = CustomToFixed((AmounttoPay-payRemainingDue) /
      remDueRecurDetails?.NoOfInstallments,2)
    if(selectedPaymentProfile?.CardTypeId === -1){
       if(AmounttoPay !== payRemainingDue){
        const errormsg = "Can't use cash payments with recurrence service";
        props?.handleNotificationMessage(errormsg, "error");
        return true;
      }
    }

  
    const req: any = {
      PaymentGatewayPaymentProfileId:
        selectedPaymentProfile?.PaymentGatewayPaymentProfileID,
      DownPayment: payRemainingDue,
      CanCollectTaxes: !IsTaxSkipped ? true : false,
      PackageInstanceId: recurrenceFormDetails?.PackageInstanceId,
      IsWaivePayment: IsWaivePayment,
      RecurringDetail:
        AmounttoPay === payRemainingDue
          ? null
          : {
              NextRecurringDate: moment(
                remDueRecurDetails?.NextRecurrence
              ).format("L"),
              Occurrence: remDueRecurDetails?.NoOfInstallments ? remDueRecurDetails?.NoOfInstallments : 0,
              RecurringAmount: recurAmt,
              FrequencyEndType:"0",
              Frequency: remDueRecurDetails?.recurrenceUnit?.id?.toString(),
              OcurrsEvery: remDueRecurDetails?.OccursEvery ? remDueRecurDetails?.OccursEvery : 0,
              RepeatFrequency: remDueRecurDetails?.OccursEvery ? remDueRecurDetails?.OccursEvery : 0,
              NoOfInstallments: remDueRecurDetails?.NoOfInstallments ? remDueRecurDetails?.NoOfInstallments : 0,
            },
    };

    setpayRemDueSubmitLoading(true);

    await axiosRequest.post("Packageinstance/PayRemaining", req, {
      successCallBack: (response: any) => {
        setpayRemDueSubmitLoading(false);
        const successMsg = "Remaining Due paid successfully";
        props?.handleNotificationMessage(successMsg, "success");
        setRemDueRecurrence(false);
        setSubmitPayRemDue(false)
        fetchApi();
        setMakePayment(false);
      },
      errorCallBack: (response: any) => {
        console.log(response?.response?.data)
        const errorMsg =
          response?.response?.data?.Messages?.[0] || response?.data?.Messages?.[0] ||
          "An error has occurred.";
        props?.handleNotificationMessage(errorMsg, "error");
      },
    });
    setpayRemDueSubmitLoading(false);

  };

  const showRecurrenceDetails = (tableData: any) => {
    if (tableData?.length === 0) {
      return <>{"No Installments found"}</>;
    }
    return (
      <div
        className={"installemt-grid bz-installment-details"}
        style={{ width: "268px", margin: "0px" }}
      >
        <Grid data={tableData} style={{ maxHeight: "300px", border: 0 }}>
          <Column field="DisplayDate" title={"Dates"} cell={customDateCell} />
          <Column
            field="InstallmentAmount"
            title={"Amount"}
            cell={InstallmentAmtCell}
          />
        </Grid>
      </div>
    );
  };

  const renderPayRemainingDue = () => {
    if (amount <= 0) {
      return (
        <>
          {/* <div className="bz-mb-2_5rem">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                Pay Remaining Due
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                {"No Remaining Dues to Pay"}
              </div>
            </div>
          </div> */}
        </>
      );
    }
    return (
      <>
        <h5 className="mb-3 pt-3 bz_fs_1_5">Pay Remaining Dues</h5>
        {!makePayment  && (
          <>
            <div className="bz-content-text-row-new">
              <div className="bz-content-title text-right">
                Remaining Balance
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">{CurrencyFormat(amount)}</div>
            </div>
            <div className="bz-content-text-row-new">
              <div className="bz-content-title text-right">{""}</div>
              <span className="bz-colon text-center"></span>
              <div className="bz-content-body">
                <span
                  className="btn btn-link"
                  onClick={() => {
                    handleMakePayment();
                  }}
                >
                  Make Payment
                </span>
              </div>
            </div>
          </>
        )}
        {makePayment && (
          <div className="bz-mt-2rem bz-mb-2_5rem update-recurrence">
            {props?.staffPage && WaivePackagePaymentPermission() &&(
              <div className="bz-content-text-row-new">
                <div className="bz-content-title text-right">
                  {" "}
                  Do you want to waive this payment ?
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <Switch
                    checked={IsWaivePayment}
                    disabled={payRemDueSubmitLoading || !submistatus?.payRemDue}
                    onChange={(e) => handleWaivePayment(e.value)}
                  />
                </div>
              </div>
            )}
            {props?.staffPage && !IsWaivePayment && checkHasTaxEnable() && (
              <div className="bz-content-text-row-new">
                <div className="bz-content-title text-right">Surcharges</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <Switch
                    checked={!IsTaxSkipped}
                    defaultChecked={false}
                    name={`IsTaxSkipped`}
                    disabled={payRemDueSubmitLoading || !submistatus?.payRemDue}
                    onChange={(e: any) => setIsTaxSkipped(!IsTaxSkipped)}
                    className="mr-1 d-inline-flex"
                  />
                </div>
              </div>
            )}
            {!IsWaivePayment && (
              <div className="bz-content-text-row-new bz-notify-row">
                <div className="bz-content-title text-right">
                  Payment Method
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <DropDownList
                    id={"selectedPaymentProfile"}
                    name={"selectedPaymentProfile"}
                    data={paymentProfile}
                    listNoDataRender={() => "No Payment Methods"}
                    textField="MaskedCCNumber"
                    dataItemKey="PaymentProfileID"
                    className={"form-control col-md-6"}
                    valueRender={(
                      el: React.ReactElement<HTMLSpanElement>,
                      value: any
                    ) => PaymentValueRender(el, value, selectedPaymentProfile)}
                    itemRender={(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) =>
                    PaymentItemRender(li, { ...itemProps, makePaymentProfileAsDefault })
                    }
                    onChange={(e) => {
                      handleChangeSavedCard(e.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            )}

            <div className="bz-content-text-row-new">
              <div className="bz-content-title text-right">Amount</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <NumericTextBox
                  className={"form-control col-md-6"}
                  value={payRemainingDue}
                  format="c2"
                  min={0}
                  disabled={
                    IsWaivePayment}
                  onChange={(e) => {
                    checkPayRemainingDue(e.target.value, packageInstance);
                  }}
                />
                 {(amount - payRemainingDue) > 0 && <Hint>
                <span className="form-text text-muted col-md-6 pl-0">
                  Remaining Amount: {CurrencyFormat(amount - payRemainingDue)}
                </span>
              </Hint>}
                {/* {props.packageInstance?.FailedTransactionAmount > 0 && (
                  <Hint>
                    <span className="form-text text-muted col-md-6 pl-0">
                      Failed Transaction Amount
                    </span>
                  </Hint>
                )} */}
              </div>
            </div>
            {!IsWaivePayment && remDueRecurrence && (
              <>
                <div className="bz-content-text-row-new bz-notify-row">
                  <div className="bz-content-title text-right">
                    Recurrence Start Date
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    {" "}
                    <DatePicker
                      value={remDueRecurDetails?.NextRecurrence ?new Date(remDueRecurDetails?.NextRecurrence):remDueRecurDetails?.NextRecurrence}
                      min={new Date(tommorrow)}
                      format="MM/dd/yyyy" 
                      formatPlaceholder={{ year: "yyyy", month: "mm", day: "dd" }}
                      onChange={(e) =>
                        handlePayRemainingDue(e.target.value, "NextRecurrence")
                      }
                      className={"form-control col-md-6"}
                    />
                   {submitPayRemDue &&(!remDueRecurDetails?.NextRecurrence || moment(remDueRecurDetails?.NextRecurrence).format("L") ===
                      "Invalid date") && (
                      <Error>
                        {"Please select valid Recurrence Start Date"}
                      </Error>
                    )}
                  </div>
                </div>

                <div className="bz-content-text-row-new bz-notify-row">
                  <div className="bz-content-title text-right">Frequency</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <DropDownList
                      data={recurrenceUnits}
                      textField="text"
                      className={"form-control col-md-6"}
                      dataItemKey="id"
                      value={remDueRecurDetails?.recurrenceUnit}
                      onChange={(e) =>
                        handlePayRemainingDue(e.value, "recurrenceUnit")
                      }
                    />
                  {submitPayRemDue && remDueRecurDetails?.recurrenceUnit?.id === 0 && <Error>{`Please Select Frequency`}</Error>}
                  </div>
                </div>
                <div className="bz-content-text-row-new bz-notify-row">
                  <div className="bz-content-title text-right">
                    Recurring Every
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    {" "}
                    <input
                      type="number"
                      min="0"
                      className={"form-control col-md-6"}
                      value={remDueRecurDetails?.OccursEvery}
                      onChange={(e) =>
                        handlePayRemainingDue(e.target.value, "OccursEvery")
                      }
                    />
                     {submitPayRemDue &&  showPayRemaingDueErrorMessage(
                      "OccursEvery",
                      "Recurring Every"
                    )}
                  </div>
                </div>
                <div className="bz-content-text-row-new">
                  <div className="bz-content-title text-right">
                    Number Of Installments
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    {" "}
                    <input
                      type="number"
                      min="0"
                      max="999"
                      className={"form-control col-md-6"}
                      value={remDueRecurDetails?.NoOfInstallments}
                      onChange={(e) =>
                        handlePayRemainingDue(
                          e.target.value,
                          "NoOfInstallments"
                        )
                      }
                    />
                      {submitPayRemDue &&  showPayRemaingDueErrorMessage(
                      "NoOfInstallments",
                      "Installment"
                    )}
                  </div>
                </div>
                <div className="bz-content-text-row align-items-start  bz-notify-row">
                  <div className="bz-content-title text-right">
                    Installment Details
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    {showRecurrenceDetails(payDueRecurrencetable)}
                  </div>
                </div>
              </>
            )}
            {/* <div className="bz-content-text-row-new">
            <div className="bz-content-title text-right">Grand Total</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">{CurrencyFormat(amount)}</div>
          </div> */}
            <div
              className="bz-content-text-row-new"
              style={{ fontWeight: 600 }}
            >
              <div className="bz-content-title text-right">
                {IsWaivePayment ? "Total Waived Amount" : "Pay Today"}
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                {CurrencyFormat(payRemainingDue)}
              </div>
            </div>
            {!IsTaxSkipped && !IsWaivePayment && TotalTaxAmount > 0 && (
              <>
                <div
                  className="bz-content-text-row-new"
                  style={{ fontWeight: 600 }}
                >
                  <div className="bz-content-title text-right">
                    <Tooltip
                      content={(tooltipProps: any) => {
                        return (
                          <TooltipContentPackageService
                            title={tooltipProps.title}
                            data={Taxes}
                            TotalTaxAmount={TotalTaxAmount}
                          />
                        );
                      }}
                      tooltipClassName="bz-tooltip-table"
                    >
                      <a
                        title="Surcharges"
                        style={{
                          borderBottom: "1px dotted #0d6efd",
                          cursor: "pointer",
                        }}
                      >
                        {"Surcharges to be Collected"}
                      </a>
                    </Tooltip>
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    {CurrencyFormat(TotalTaxAmount)}
                  </div>
                </div>
                <div
                  className="bz-content-text-row-new"
                  style={{ fontWeight: 600 }}
                >
                  <div className="bz-content-title text-right">{"Total"}</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    {CurrencyFormat(TotalTaxAmount + payRemainingDue)}
                  </div>
                </div>
              </>
            )}
            <div className="bz-content-text-row-new mt-2 mble-mt-0">
              <div className="bz-content-title text-right"></div>
              <span className="bz-colon text-center"></span>
              <div className="bz-content-body">
                <Button
                  className="ml-0"
                  onClick={() => handleReset()}
                  disabled={payRemDueSubmitLoading || !submistatus?.payRemDue}
                >
                  Cancel
                </Button>
                <BuzopsButton
                  disabled={payRemDueSubmitLoading || !submistatus?.payRemDue}
                  type={"submit"}
                  loading={payRemDueSubmitLoading}
                  label={IsWaivePayment ? "Waive" : "Pay Now"}
                  onClick={() => submitPayRemainigDue()}
                  key={payRemDueSubmitLoading.toString()}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const handleUpdateRecurrence = (val: any, name: string) => {
    const amounttoUpdate = packageInstance?.Cost - packageInstance?.AmountPaid;
    let value = val;
    if (name !== "NextRecurrence" && val < 0) {
      value = Math.abs(val);
    }
    let data = {
      ...recurrenceFormDetails,
      [name]: value,
    };
    if (name === "OccursEvery" && parseInt(value) === 0) {
      data = {
        ...recurrenceFormDetails,
        [name]: value,
        NoOfInstallments: 1,
      };
    }
    if (name === "NoOfInstallments" && val > 999) {
      data = {
        ...recurrenceFormDetails,
        [name]: 999,
      };
    }
    if (name === "NextRecurrence") {
      if (moment(val).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
        data = {
          ...recurrenceFormDetails,
          [name]: val,
        };
      } else {
        data = {
          ...recurrenceFormDetails,
          [name]: recurrenceFormDetails?.NextRecurrence,
        };
      }
    }
    setRecurrenceFormDetails(data);
    setSubmitStatus({
      ...submistatus,
      updateRecurrence: true,
    });
    CalculateRecurrence(data, amounttoUpdate, 0, true);
  };

  const showErrorMessage = (key: any, mainKey: any) => {
    if (!recurrenceFormDetails[key] || recurrenceFormDetails[key] <= 0) {
      return <Error>{`Please provide atleast one ${mainKey}`}</Error>;
    }
    return <></>;
  };

  const submitRecurrence = async () => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    if (
      !recurrenceFormDetails?.OccursEvery ||
      recurrenceFormDetails?.OccursEvery <= 0 ||
      !recurrenceFormDetails?.NoOfInstallments ||
      recurrenceFormDetails?.NoOfInstallments <= 0 ||
      moment(recurrenceFormDetails?.NextRecurrence).format("L") ===
        "Invalid date"
    ) {
      return;
    }
    const recurAmt = (
      recurrenceFormDetails?.AmountPending /
      recurrenceFormDetails?.NoOfInstallments
    ).toFixed(2);
    const occurence = `${recurrenceFormDetails?.OccursEvery} ${recurrenceFormDetails?.recurrenceUnit?.text}`;

    const req = {
      PackageInstanceId: recurrenceFormDetails?.PackageInstanceId,
      MemberFullName: props?.userData?.FullName,
      NextRecurrence: moment(recurrenceFormDetails?.NextRecurrence).format("L"),
      NoOfInstallments: parseInt(recurrenceFormDetails?.NoOfInstallments),
      OccursEvery: parseInt(recurrenceFormDetails?.OccursEvery),
      RepeatFrequency: recurrenceFormDetails?.recurrenceUnit?.id,
      RecurringAmount: parseFloat(recurAmt),
      UserMemberId: recurrenceFormDetails?.UserMemberId,
    };
    setUpdateRecurrenceSubmitLoading(true);
    try {
      const updateRecurrenceService = new TenantService();
      const result = await updateRecurrenceService.updatePackageRecurrence(req);
      setUpdateRecurrenceSubmitLoading(false);

      const successMsg = "Recurrence Updated Successfully";
      props?.handleNotificationMessage(successMsg, "success");
    } catch (error) {
      props?.handleNotificationMessage(error, "error");
    }

    await fetchApi();
  };

  const renderUpdateRecurrence = () => {
    const billingFreq = `For Every ${recurrenceDetails?.OccursEvery} ${recurrenceDetails?.recurrenceUnit?.value}`;
    if (recurrenceDetails?.AmountPending <= 0) {
      return (
        <>
          <div className="bz-mt-2rem">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                Update Recurrence
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                {"There is no pending amount to update recurrence"}
              </div>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <hr />
        <h5 className="mb-3 pt-3">Update Recurrence</h5>
        <div className="bz-mt-2rem bz-mb-2_5rem update-recurrence">
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Amount Pending</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {CurrencyFormat(recurrenceDetails?.AmountPending)}
            </div>
          </div>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">
              Billing Frequency{" "}
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">{billingFreq}</div>
          </div>

          <div className="bz-content-text-row mb-0">
            <div className="bz-content-title text-right align-self-baseline">
              Recurring Cost
            </div>
            <span className="bz-colon text-center  align-self-baseline">:</span>
            <div className="bz-content-body">
              {CurrencyFormat(recurrenceDetails?.RecurringCost)}
            </div>
          </div>
          <div className="bz-content-text-row bz-notify-row">
            <div className="bz-content-title text-right align-self-baseline"></div>
            <span className="bz-colon text-center  align-self-baseline"></span>
            <div className="bz-content-body">
              <Hint>
                <span className="form-text text-muted col-md-6 pl-0">
                  Amount to be charged on every billing cycle.
                </span>
              </Hint>
            </div>
          </div>
          <div className="bz-content-text-row bz-notify-row">
            <div className="bz-content-title text-right">
              Recurrence Start Date
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <DatePicker
                name="NextRecurrence"
                min={new Date(tommorrow)}
                 value={new Date(recurrenceFormDetails?.NextRecurrence)}
                onChange={(e) =>
                  handleUpdateRecurrence(e.target.value, "NextRecurrence")
                }
                className={"form-control col-md-6"}
              />
              {moment(recurrenceFormDetails?.NextRecurrence).format("L") ===
                "Invalid date" && (
                <Error>{"Please select valid Recurrence Start Date"}</Error>
              )}
            </div>
          </div>
          {/* <div className="bz-content-text-row"> */}
          {/* <div className="bz-content-title text-right">
              Recurrence Start Date
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body"> */}
          {/* <input type="date" value={moment(recurrenceFormDetails?.NextRecurrence).format()} onChange={(e) => setRecurrenceFormDetails({ ...recurrenceFormDetails, NextRecurrence: e.target.value})} className={"form-control col-md-4"} /> */}
          {/* </div> */}
          {/* </div> */}
          <div className="bz-content-text-row bz-notify-row">
            <div className="bz-content-title text-right  align-self-baseline">
              Frequency
            </div>
            <span className="bz-colon text-center  align-self-baseline">:</span>
            <div className="bz-content-body">
              <DropDownList
                data={recurrenceUnits}
                textField="value"
                className={"form-control col-md-6"}
                dataItemKey="id"
                name="recurrenceUnit"
                value={recurrenceFormDetails?.recurrenceUnit}
                onChange={(e) =>
                  handleUpdateRecurrence(e.value, "recurrenceUnit")
                }
              />
              <Hint>
                <span className="form-text text-muted col-md-6 pl-0">
                  The billing frequency for the Service.
                </span>
              </Hint>
            </div>
          </div>
          <div className="bz-content-text-row bz-notify-row mb-0">
            <div className="bz-content-title text-right  align-self-baseline">
              Recurring Every
            </div>
            <span className="bz-colon text-center align-self-baseline">:</span>
            <div className="bz-content-body">
              <input
                type="number"
                className={"form-control col-md-6"}
                value={recurrenceFormDetails?.OccursEvery}
                onChange={(e) =>
                  handleUpdateRecurrence(e.target.value, "OccursEvery")
                }
              />
              {showErrorMessage("OccursEvery", "Recurring Every")}
            </div>
          </div>
          <div className="bz-content-text-row bz-notify-row">
            <div className="bz-content-title text-right  align-self-baseline"></div>
            <span className="bz-colon text-center align-self-baseline"></span>
            <div className="bz-content-body">
              <Hint>
                <span className="form-text text-muted col-md-6 pl-0">
                  Select how frequently the client would want to repeat the
                  Service payment based on frequency.
                </span>
              </Hint>
            </div>
          </div>
          <div className="bz-content-text-row bz-notify-row">
            <div className="bz-content-title text-right align-self-baseline">
              Number Of Installments
            </div>
            <span className="bz-colon text-center  align-self-baseline">:</span>
            <div className="bz-content-body">
              <input
                type="number"
                min="0"
                max="999"
                className={"form-control col-md-6"}
                value={recurrenceFormDetails?.NoOfInstallments}
                onChange={(e) =>
                  handleUpdateRecurrence(e.target.value, "NoOfInstallments")
                }
              />
              {showErrorMessage("NoOfInstallments", "Installment")}
              <Hint>
                <span className="form-text text-muted col-md-6 pl-0">
                  Select the number of times the client would want to repeat the
                  Service payment based on frequency.
                </span>
              </Hint>
            </div>
          </div>
          <div className="bz-content-text-row align-items-start bz-notify-row">
            <div className="bz-content-title text-right">
              Installment Details
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {showRecurrenceDetails(updateRecurrencetable)}
            </div>
          </div>
          <div className="bz-content-text-row bz-notify-row mt-4 pt-2 mble-mt-0">
            <div className="bz-content-title text-right"></div>
            <span className="bz-colon text-center"></span>
            <div className="bz-content-body">
              <Button
                className="ml-0"
                onClick={() => handleReset()}
                disabled={
                  updateRecurrenceSubmitLoading ||
                  !submistatus?.updateRecurrence
                }
              >
                Reset
              </Button>
              <BuzopsButton
                disabled={
                  updateRecurrenceSubmitLoading ||
                  !submistatus?.updateRecurrence
                }
                type={"submit"}
                loading={updateRecurrenceSubmitLoading}
                label={"Submit"}
                onClick={() => submitRecurrence()}
              />
            </div>
          </div>
        </div>

        <hr />
      </>
    );
  };

  const renderTransactions = () => {
    return (
      <Transactions
        userData={props?.userData}
        staffPage={props?.staffPage}
        allItems={false}
        EntityId={props?.selectedPackageId}
        FilterType={512}
        onSuccessfullPayment={() => props?.onSuccessfullPayment()}
      />
    );
  };

  const renderPaymentMethods = () => {
    const entityDetails = {
      MemberRecurringDetailId: packageInstanceRef?.current?.MemberRecurringDetailId,
      PaymentGatewayProfileId: packageInstanceRef?.current?.PaymentGatewayPaymentProfileId,
      FilterType: "package",
      UserMemberId: props?.userData?.UserMemberId,
      AuditType: 512,
    };
    return (
      <PaymentMethods
        entityDetails={entityDetails}
        fetchPackageSummary={() => fetchPackageSummary()}
        userData={props?.userData}
        staffPage={props?.staffPage}
      />
    );
  };

  if (loading) {
    return <BuzopsLoader type={"list"} />;
  }
  return (
    <>
      <div className="bz-subsection-row bz-plan-overview">
        <div className="left-section">{renderPaymentMethods()}</div>
      </div>
      {packageInstance?.SessionType === 3 && (
        <div className="bz-subsection-row bz-plan-overview subscription-blk">
          <div className="left-section">
            {recurrenceDetails?.AmountPending > 0 &&
              recurrenceDetails?.RecurringCost > 0 &&
              renderUpdateRecurrence()}
          </div>
        </div>
      )}

      {packageInstance?.SessionType === 3 && (
        <div className="bz-subsection-row bz-plan-overview">
          <div className="left-section">
            {recurrenceDetails?.AmountPending > 0 && packageInstance?.Status !== PackageInstanceStatusEnum.CANCELLED && renderPayRemainingDue()}
          </div>
        </div>
      )}
      <hr />
      {renderTransactions()}
    </>
  );
};

export default withNotification(PackagePayments);
