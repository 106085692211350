import { Tooltip } from "@progress/kendo-react-tooltip";
import React from "react";
import { CurrencyFormat, checkHasTaxEnable } from "utils";

const GroupTooltipContentTemplate = (propData: any) => {
  return (
    <>
      <ul className="list-group list-group-flush taxes-list">
        {propData?.data.map((item: any) => {
          return item?.values?.length > 0 ? (
            <>
              {checkHasTaxEnable() &&
                item?.CanCollectTaxes &&
                !item?.IsTaxSkipped &&
                item?.PredictedTotalTaxAmount > 0 && (
                  <>
                   <li className="list-group-item text-align-center" style={{fontSize:'0.875rem',fontWeight:600}}>{item?.className}</li>
                    {item?.PredictedTaxes.map((i: any) => {
                      return (
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <span>
                            {i?.TaxName}&nbsp;({i?.Percentage}%)
                          </span>
                          <span className="price">
                            {CurrencyFormat(i?.Amount)}
                          </span>
                        </li>
                      );
                    })}
                  </>
                )}
            </>
          ) : (
            <></>
            );
        })}
        {propData?.TotalTaxAmount > 0 && (<li className="list-group-item d-flex justify-content-between align-items-center">
            <span>
              {"Total Surcharges"}
            </span>
            <span className="price">{CurrencyFormat(propData?.TotalTaxAmount)}</span>
        </li>)}
      </ul>
    </>
  );
};
const GroupOrderSummary = (props: any) => {
  const {
    downpaymentForm,
    useCreditAmount,
    useComplimentaryAmount,
    payLaterAmount,
    packageCost,
    session,
    totalDiscountAmt,
    SessionSignOff=false
  } = props;
  const { paidToday } = downpaymentForm;
  return (
    <>
      <p className={"text-left mb-1 buz-font-500 service-para d-flex mt-3"} style={{justifyContent:'space-between'}}>
        <span className="service-titlename2">
          {checkHasTaxEnable() && props?.calculateTotalSessionTax(true) > 0 ? "Total before Surcharges" : "Total"}
        </span>
        <span className={"service-value float-right"}>
          {CurrencyFormat(packageCost - totalDiscountAmt)}
        </span>
      </p>
      {checkHasTaxEnable() && props?.calculateTotalSessionTax(true) > 0 && (
        <>
          <p className={"text-left mb-1 buz-font-500 service-para  d-flex"} style={{justifyContent:'space-between'}}>
            <span className="service-titlename2">
              <Tooltip
                content={(tooltipProps: any) => {
                  return (
                    <GroupTooltipContentTemplate
                      title={tooltipProps.title}
                      data={props.session}
                      TotalTaxAmount={props?.calculateTotalSessionTax(true)}
                    />
                  );
                }}
                tooltipClassName="bz-tooltip-table"
              >
                <a
                  title="Surcharges"
                  style={{
                    borderBottom: "1px dotted #0d6efd",
                    cursor: "pointer",
                  }}
                >
                  {"Surcharges to be collected"}
                </a>
              </Tooltip>
            </span>
            <span className={"service-value float-right"}>
              {CurrencyFormat(props?.calculateTotalSessionTax(true))}
            </span>
          </p>
        </>
      )}
      {/* {totalDiscountAmt > 0 && (
        <p className={"text-left mb-1 buz-font-500 service-para d-flex mt-3"} style={{ justifyContent: 'space-between' }}>
          <span className="service-titlename2">Total Coupon Amount</span>
          <span className={"service-value float-right"}>
            -{CurrencyFormat(totalDiscountAmt)}
          </span>
        </p>
      )} */}

      <p className={"text-left mb-1 buz-font-500 service-para  d-flex"} style={{justifyContent:'space-between'}}>
        <span className="service-titlename2">Redeemed via Package</span>
        <span className={"service-value float-right"}>
          {CurrencyFormat(useCreditAmount)}
        </span>
      </p>
      <p className={"text-left mb-1 buz-font-500 service-para  d-flex"} style={{justifyContent:'space-between'}}>
        <span className="service-titlename2">Redeemed via Complimentary</span>
        <span className={"service-value float-right"}>
          {CurrencyFormat(useComplimentaryAmount)}
        </span>
      </p>
      <hr />
      <p className={"text-left mb-1 buz-font-500 service-para  d-flex"} style={{justifyContent:'space-between'}}>
        <span className="service-titlename2">{"Order Total"}</span>
        <span className={"service-value float-right"}>
          {CurrencyFormat(
            props?.calculateTotalSessionTax(true) + paidToday + payLaterAmount
          )}
        </span>
      </p>
      {
        (props?.selectedClassesType !== "single" || (props?.selectedClassesType === "single" && (session[0]?.values[0]?.PaymentOption !== 1 || session[0]?.values[0]?.CanBookAsWaitlist))) && (payLaterAmount + props?.calculateTotalSessionTax(true) - props?.calculateTotalSessionTax() > 0) && (
          <p className={"text-left mb-1 buz-font-500 service-para  d-flex"} style={{justifyContent:'space-between'}}>
            <span className="service-titlename2">Pay at Class</span>
            <span className={"service-value float-right"}>
              {CurrencyFormat(
                payLaterAmount + props?.calculateTotalSessionTax(true) - props?.calculateTotalSessionTax()
              )}
            </span>
          </p>
        )
      }

      <p className={"text-left mb-1 buz-font-500 service-para  d-flex"} style={{justifyContent:'space-between'}}>
        <span className="service-titlename2">{"Down Payment"}</span>
        <span className={"service-value float-right"}>
          {CurrencyFormat(props?.calculateTotalSessionTax() + paidToday)}
        </span>
      </p>
    </>
  );
};

export default GroupOrderSummary;
