import { ListView } from "@progress/kendo-react-listview";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { LocationForBooking } from "components/Location/Location";
import { CurrencyFormat } from "utils";
import { PrivateServiceTypeEnum, ScheduleAppointmentkeys } from "utils/form-utils";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { CardBody } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import BuzopsButton from "generic-components/BuzopsButton";
import { Loader } from "@progress/kendo-react-indicators";
interface IAppointmentsList {
  userDetails?: any;
  schedulerCalendarState?: any;
  callBacks?: any;
  mainState?: any;
  staffPage?: boolean;
  showOnlySignoff: boolean;
  name: ScheduleAppointmentkeys;
  handleChange: (name: ScheduleAppointmentkeys, val: any) => void;
}
const AppointmentsList = ({
  userDetails,
  name,
  staffPage = false,
  mainState,
  handleChange,
  schedulerCalendarState,
  callBacks,
  showOnlySignoff=false
}: IAppointmentsList) => {
  const PrivateServiceTypeList = [
    { Name: "All", Id: 0 },
    { Name: "Private", Id: 1 },
    { Name: "Semi-Private", Id: 2 },
    { Name: "Pre-set", Id: 3 },
  ];
  const MyItemRender = (props: any) => {
    const item = props.dataItem;
    console.log(item,'itemitemitemitemitem')
    console.log(mainState,'mainStatemainStatemainStatemainStatemainState')
    const IsPackageAvailable = (item?.PackageInstanceId > 0 && mainState?.ShowPurchasedPackages) || (mainState?.PackageClassIds[item?.PrivateServiceId] && mainState?.ShowPurchasedPackages) ? true : false
    if (item?.PackageInstanceId > 0 && !mainState?.ShowPurchasedPackages) {
      return <></>
    }
    return (
      <div className="card mb-3 pointer appthover" onClick={() => callBacks?.onAppointmentSelect(item)}>
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div>
              <h5 className="card-title">{item.Name}{" "}
              </h5>
              <div className="bz-name appointments">
                {(item.PrivateServiceType === PrivateServiceTypeEnum.PrivatePaid || item.PrivateServiceType === PrivateServiceTypeEnum.PrivateUnpaid) && "Private"}
                {(item.PrivateServiceType === PrivateServiceTypeEnum.SemiPrivatePaid || item.PrivateServiceType === PrivateServiceTypeEnum.SemiPrivateUnpaid) && "Semi-Private"}
              </div>

            </div>
            <div>
              <div className="float-right text-right">
                {item.DurationInMinutes} mins
                <br />
                <span className={!showOnlySignoff && IsPackageAvailable ? "cross-word" : "rateappts"}>{CurrencyFormat(item.Rate)}</span>

              </div>
            </div>
          </div>
          <div className="typeapptslocation">

            <div className="text-muted d-flex align-items-center location-blk-text calendardesktop">
              <LocationForBooking
                Attributes={item?.Attributes}
                showDetails={false}
                showToolTip={staffPage}
                VirtualMeetingLink={""}
                userDetails={props?.userDetails}
                clientPhnNum={props?.userDetails?.Phone}
              />

            </div>
            <h6 className="mb-0"> {!showOnlySignoff && IsPackageAvailable ? (<>
              <span className='packageavailable'> {'Package Available'}</span>
            </>) : null} </h6>

          </div>
        </div>
      </div>
    );
  };
  const requestIfNeeded = () => {
    const nextPage = mainState?.appointmentList.pageNumber + 1;
    const maxPageNum = mainState?.appointmentList.maxPageNumber;

    if (nextPage < maxPageNum) {
      return {
        status: true,
        page: nextPage,
      };
    } else {
      return {
        status: false,
        page: nextPage,
      };
    }
  };

  const sortedAppointmentData = mainState?.appointmentList?.appointmentData.sort((a: any, b: any) => {
    const aPackageAvailable = a?.PrivateServiceId === null ? 2 : (mainState?.PackageClassIds[a?.PrivateServiceId] && mainState?.ShowPurchasedPackages ? 1 : 0);
    const bPackageAvailable = b?.PrivateServiceId === null ? 2 : (mainState?.PackageClassIds[b?.PrivateServiceId] && mainState?.ShowPurchasedPackages ? 1 : 0);
    return bPackageAvailable - aPackageAvailable;
  });



  return (
    <div>
      <div className="appointmentheader mb-3">
        <h5 className="bz-avail-providers mb-0">Appointments</h5>
        <div className="bz-purchase-class-list pb-0">
          <div className="d-flex bz-purchase-class-filter mt-0 justify-content-end">
            <div className="form-group mb-0 bz-packages-search bzo-search-service mr-3">
              <span className="fa fa-search form-control-feedback"></span>
              <input
                type="search"
                className="form-control"
                placeholder="Find"
                onChange={(e) => callBacks?.handleSearch(e.target.value)}
                value={mainState?.ApptNameSearch}
              />
            </div>
            <div className="mb-3 mb-sm-0 mr-3 appttype">
              <DropDownList
                data={mainState?.ShowPurchasedPackages ? PrivateServiceTypeList : PrivateServiceTypeList?.filter((i: any) => i?.Id !== 3)}
                onChange={(e) => callBacks?.handleApptFilter(e.target.value)}
                textField="Name"
                dataItemKey="Id"
                id="SelectedApptFilter"
                name="SelectedApptFilter"
                value={mainState?.SelectedApptFilter}
              />
            </div>
            {!showOnlySignoff && !mainState?.Package && mainState?.AvailablePackages?.length > 0 ? (
              <div className="justify-self-end mr-3 usingpackages">
                {!mainState?.ShowPurchasedPackages ? (
                  <Button
                    onClick={() => handleChange("ShowPurchasedPackages", true)}
                    className="bnt-radiusaddpackage m-0 noaddpck maxwithpackage"
                  >
                    Use Package
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleChange("ShowPurchasedPackages", false)}
                    className="bnt-radiusaddpackage m-0  maxwithpackage k-primary"
                  >
                    Using Package
                  </Button>
                )}
              </div>
            ) : null}

          </div>
        </div>
      </div>
      <>
        {schedulerCalendarState?.appointmentsLoading ? (
          <BuzopsLoader type="list" />
        ) : mainState?.appointmentList?.appointmentData.length === 0 ? (
          <>No Appointment Available</>
        ) : (
          <div

            onScroll={callBacks?.scrollHandler}
            className="appointmentlistview"
          >
            <ListView
              data={sortedAppointmentData || []}
              item={MyItemRender}
              style={{ width: "100%" }}
            />
            {
              requestIfNeeded().status &&

              <Loader type="converging-spinner" className="laoder-full-screen" />

            }
          </div>
        )}
      </>
    </div>
  );

};

export default AppointmentsList;
