import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

// Global cache to remember which IDs are visible.
const visibleCache = new Set();

export const ClassSessionItemVirtualScroll = React.memo(function ClassSessionItemVirtualScroll({
  height,
  children,
  uniqueId, // Provide a unique identifier for each item
}: any) {
  // Set up the intersection observer (without triggerOnce) to continuously monitor inView.
  const [ref, inView] = useInView();

  useEffect(() => {
    if (!uniqueId) return;
    let removalTimeout;
    if (inView) {
      // When in view, add to cache and cancel any pending removal.
      visibleCache.add(uniqueId);
    } else {
      // When out of view, delay removal from the cache (e.g. 1 second)
      removalTimeout = setTimeout(() => {
        visibleCache.delete(uniqueId);
      }, 1000);
    }
    return () => {
      if (removalTimeout) clearTimeout(removalTimeout);
    };
  }, [inView, uniqueId]);

  // Render children if the item is in view or if it's still cached.
  const shouldRenderChildren = inView || (uniqueId && visibleCache.has(uniqueId));

  const style = {
    // height: `${height}px`, // Fixed height to avoid layout shifts
    overflow: "hidden",
  };

  return (
    <div style={style} ref={ref}>
      {shouldRenderChildren ? (
        children
      ) : (
        // Fallback placeholder maintaining the same height.
        <div style={{ height: `${height}px` }} />
      )}
    </div>
  );
});
