import React, { useCallback, memo, useMemo } from "react";
import { ListView } from "@progress/kendo-react-listview";
import ClassSessionItemRender from "./ClassSessionItemRender";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { Loader } from "@progress/kendo-react-indicators";
import { ClassSessionItemVirtualScroll } from "./ClassSessionItemVirtualScroll";

const MemoizedVirtualItem = memo(function VirtualItem({
  dataItem,
  redeem,
  staffPage,
  usePackageFilter,
  handleSelectClass,
  handleCopyClick,
  selectedClasses,
  handleSelectGroup,
}: any) {
  console.log(dataItem,'MemoizedVirtualItem')
  let keyvalue=`${dataItem?.type}-${dataItem?.date}`
  if(dataItem?.dataItem?.ClassSessionId){
    keyvalue=`${dataItem?.type}-${dataItem?.dataItem?.ClassSessionId}`
  }
  return (
    <ClassSessionItemVirtualScroll height={120} key={`${keyvalue}-virtual`} uniqueId={`${dataItem?.dataItem?.ClassSessionId || dataItem?.date}`}>
      <ClassSessionItemRender
        dataItem={dataItem}
        redeem={redeem}
        staffPage={staffPage}
        usePackageFilter={usePackageFilter}
        handleSelectClass={handleSelectClass}
        handleCopyClick={handleCopyClick}
        selectedClasses={selectedClasses}
        handleSelectGroup={handleSelectGroup}
      />
    </ClassSessionItemVirtualScroll>
  );
});

const RenderClassSessionsList = ({
  propSelectedPackage,
  staffPage,
  isClassLoading,
  classSessionsList,
  selectedPackage,
  pageLoading,
  usePackageFilter,
  handleSelectClass,
  selectedClasses,
  handlePageChange,
  handleCopyClick,
  handleSelectGroup,
  classSessionId=false
}: any) => {
  let redeem = false;
  if (selectedPackage || propSelectedPackage) {
    redeem = true;
  }

  // Memoize the item renderer so it is not recreated on every render.
  const renderItem = useCallback(
    ({ dataItem }) => (
      <MemoizedVirtualItem
        dataItem={dataItem}
        redeem={redeem}
        staffPage={staffPage}
        usePackageFilter={usePackageFilter}
        handleSelectClass={handleSelectClass}
        handleCopyClick={handleCopyClick}
        selectedClasses={selectedClasses}
        handleSelectGroup={handleSelectGroup}
      />
    ),
    [
      redeem,
      staffPage,
      usePackageFilter,
      handleSelectClass,
      handleCopyClick,
      selectedClasses,
      handleSelectGroup,
    ]
  );
  const memoizedClassSessionData = useMemo(() => classSessionsList.classSessionData, [classSessionsList.classSessionData]);

  if (isClassLoading) {
    return <BuzopsLoader type={"list"} />;
  } else if (classSessionsList.classSessionData.length === 0) {
    return (
      <div className="bz-no-upcoming-appoinments text-muted">
        No Group Available
      </div>
    );
  }

  return (
    <>
      <div className="groupclasslistadmin">
        <ListView
          item={renderItem}
          data={memoizedClassSessionData || []}
          style={{ width: "100%" }}
        />
        {!classSessionId && (
          <div className="d-flex justify-content-center align-items-center bz-services-showMore">
            <div>
              {pageLoading ? (
                <Loader type="converging-spinner" />
              ) : (
                classSessionsList?.startDate && (
                  <span
                    className="btn btn-link align-baseline bz-cursor-pointer p-0"
                    onClick={()=>handlePageChange()}
                  >
                    Next Schedule <i className="fas fa-caret-down"></i>
                  </span>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(RenderClassSessionsList);
