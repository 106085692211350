import { useApiClient } from "services/axios-service-utils";
import { useEffect, useState } from "react";

const useRefundCredits = (props: any) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { axiosRequest } = useApiClient();

  useEffect(() => {
    fetchAllApis();
  }, []);

  const fetchAllApis = async () => {
    if (props?.UserMemberId) {
      await fetchRefundabletransactions(props?.UserMemberId);
    }
  };

  const errorCallBack = (message: string) => {
    props?.handleNotificationMessage(message, "error");
  };

  const fetchRefundabletransactions = async (userMemberId: any) => {
    let req: any = {
      UserMemberId: props?.UserMemberId,
      Amount: props?.walletAmt,
    };
    setLoading(true);
    await axiosRequest.post("payment/refundabletransactions", req, {
      successCallBack: (response: any) => {
        setData(response.data?.Items || []);
        setLoading(false);
      },
      errorCallBack: (response: any) => {
        setLoading(false);
        // errorCallBack(
        //   response?.response?.data?.Messages?.[0] ||
        //     response?.data?.Messages?.[0] ||
        //     "Internal server error"
        // );
      },
    });
  };
  const handleSubmit = async () => {
    setBtnLoading(true);
    const payload = {
      UserMemberId: props?.UserMemberId,
      Transactions: data?.map((i: any) => {
        return {
          RefundAmount: i?.RefundAmount,
          PaymentGatewayTransactionHistoryID:
            i?.PaymentGatewayTransactionHistoryID,
          TransactionID: i?.TransactionID,
        };
      }),
    };
    await axiosRequest.post("payment/processcreditrefund", payload, {
      successCallBack: (response: any) => {
        props?.handleCloseDialog("refund", true);
        setBtnLoading(false);
      },
      errorCallBack: (response: any) => {
        console.log(response?.response, "response");
        errorCallBack(
          response?.response?.data?.Messages?.[0] ||
            response?.data?.Messages?.[0] ||
            "Error occurred while processing refund"
        );
        setBtnLoading(false);
      },
    });
  };

  return {
    loading,
    data,
    btnLoading,
    handleSubmit,
  };
};

export default useRefundCredits;
