import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import ShowBankForm from "./ShowBankForm";
import ShowCardForm from "./ShowCardForm";
import { GetLocalStore } from "utils/storage";
import PurchaseClassPanel from "./PurchaseClassPanel";
import PaymentProfileSelection from "./PaymentProfileSelection";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import StripePayments from "components/stripePayment";
import SquarePaymentForm from "components/square-payment-form/paymentsv2";
import AddClient from "components/booking/AddClient";
import ShowAddOtherPaymentMode from "./ShowAddOtherPaymentMode";
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import { useEffect, useState } from "react";

const PaymentPanelForm = (props: any) => {
  console.log(props?.SessionSignOff, "SessionSignOff");
  const { card, bank, addOther } = props.paymentMethod;
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);

  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);

  const constructFileWithBas64 = (records: any) => {
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
          props?.handleChangeSavedCard(
            props.selectedPaymentProfile?.selectedPayment,
            {
              Reference: props?.paymentSubmitProfile?.Reference,
              ReferrenceDocument: fileData?.[0],
            }
          );
        };
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
      props?.handleChangeSavedCard(
        props.selectedPaymentProfile?.selectedPayment,
        {
          Reference: props?.paymentSubmitProfile?.Reference,
          ReferrenceDocument: null,
        }
      );
    }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };

  const userDetails = GetLocalStore("userDetails");
  const userConfiguration =
    userDetails?.ChargehubSquarePaymentProfile || undefined;
  const selected = props.selected;
  const { FirstName, LastName } = props.clientFormValues;
  const selectedPaymentValue = props.selectedPaymentProfile.selectedPayment;
  const ExpiryDate = props.selectedPaymentProfile.selectedPayment?.IsCreditCard
    ? props.selectedPaymentProfile.selectedPayment?.ExpiryDate
    : "";

  const clientPanelTitle = () => {
    if (Object.keys(props.clientFormValues).length !== 0) {
      return `Client: ${FirstName} ${LastName}`;
    } else {
      return "Client";
    }
  };

  console.log("Props", props.paymentGatewayType);

  const PaymentGatewayForm = () => {
    switch (props.paymentGatewayType) {
      case 0:
        return (
          <>
            <PaymentProfileSelection
            makePaymentProfileAsDefault={props?.makePaymentProfileAsDefault}
              paymentProfile={props.paymentProfile}
              handleChangeSavedCard={(val: any, dataItem = null) =>
                props.handleChangeSavedCard(val, dataItem)
              }
              page={props?.page}
              IsFromCalendar={props?.IsFromCalendar}
              checkPaymentProfileNotRequired={
                props.checkPaymentProfileNotRequired
              }
              selectedPaymentProfile={props.selectedPaymentProfile}
              paymentGatewayType={props.paymentGatewayType}
              paymentMethod={props.paymentMethod}
              handleForm={(val: string) => props.handleForm(val)}
              handleSubmit={props.handleSubmit}
              staffPage={props.staffPage}
            />
            {addOther && (
              <ShowAddOtherPaymentMode
                {...props}
                handleAddPaymentMode={props.handleAddPaymentMode}
              />
            )}
          </>
        );
        break;
      case 1:
        return (
          <>
            <PaymentProfileSelection
            makePaymentProfileAsDefault={props?.makePaymentProfileAsDefault}
              paymentProfile={props.paymentProfile}
              handleChangeSavedCard={(val: any, dataItem = null) =>
                props.handleChangeSavedCard(val, dataItem)
              }
              page={props?.page}
              IsFromCalendar={props?.IsFromCalendar}
              checkPaymentProfileNotRequired={
                props.checkPaymentProfileNotRequired
              }
              selectedPaymentProfile={props.selectedPaymentProfile}
              paymentGatewayType={props.paymentGatewayType}
              paymentMethod={props.paymentMethod}
              handleForm={(val: string) => props.handleForm(val)}
              handleSubmit={props.handleSubmit}
              staffPage={props.staffPage}
            />
            {addOther && (
              <ShowAddOtherPaymentMode
                {...props}
                handleAddPaymentMode={props.handleAddPaymentMode}
              />
            )}
            {bank && (
              <ShowBankForm
                bankFormValues={props.bankFormValues}
                bankSubmitBtnLoading={props.bankSubmitBtnLoading}
                handleBankSubmit={props.handleBankSubmit}
              />
            )}
            {card && (
              <ShowCardForm
                cardFormValues={props.cardFormValues}
                cardSubmitBtnLoading={props.cardSubmitBtnLoading}
                handleCardSubmit={props.handleCardSubmit}
                cardImageIcon={props.cardImageIcon}
                handleCardImageIcon={(url: any) =>
                  props.handleCardImageIcon(url)
                }
              />
            )}
          </>
        );

      case 3:
        return (
          <>
            <PaymentProfileSelection
            makePaymentProfileAsDefault={props?.makePaymentProfileAsDefault}
              paymentProfile={props.paymentProfile}
              checkPaymentProfileNotRequired={
                props.checkPaymentProfileNotRequired
              }
              handleChangeSavedCard={(val: any, dataItem = null) =>
                props.handleChangeSavedCard(val, dataItem)
              }
              page={props?.page}
              IsFromCalendar={props?.IsFromCalendar}
              selectedPaymentProfile={props.selectedPaymentProfile}
              paymentGatewayType={props.paymentGatewayType}
              paymentMethod={props.paymentMethod}
              handleForm={(val: string) => props.handleForm(val)}
              handleSubmit={props.handleSubmit}
              staffPage={props.staffPage}
            />
            {addOther && (
              <ShowAddOtherPaymentMode
                {...props}
                handleAddPaymentMode={props.handleAddPaymentMode}
              />
            )}
            {card && (
              <div className="pl-24 row col-12">
                <SquarePaymentForm
                  UserMemberId={props?.UserMemberId}
                  handleCardSubmit={props.handleCardSubmit}
                  userConfiguration={userConfiguration}
                  cardSubmitBtnLoading={props.cardSubmitBtnLoading}
                />
              </div>
            )}
          </>
        );
      case 4:
        return (
          <>
            <PaymentProfileSelection
            makePaymentProfileAsDefault={props?.makePaymentProfileAsDefault}
              paymentProfile={props.paymentProfile}
              handleChangeSavedCard={(val: any, dataItem = null) =>
                props.handleChangeSavedCard(val, dataItem)
              }
              page={props?.page}
              checkPaymentProfileNotRequired={
                props.checkPaymentProfileNotRequired
              }
              IsFromCalendar={props?.IsFromCalendar}
              selectedPaymentProfile={props.selectedPaymentProfile}
              paymentGatewayType={props.paymentGatewayType}
              paymentMethod={props.paymentMethod}
              handleForm={(val: string) => props.handleForm(val)}
              handleSubmit={props.handleSubmit}
              staffPage={props.staffPage}
            />
            {addOther && (
              <ShowAddOtherPaymentMode
                {...props}
                handleAddPaymentMode={props.handleAddPaymentMode}
              />
            )}
            {bank && (
              <StripePayments
                bankFormValues={props.bankFormValues}
                bankSubmitBtnLoading={props.bankSubmitBtnLoading}
                handleBankSubmit={props.handleBankSubmit}
                type={"bank"}
                UserMemberId={props?.UserMemberId}
              />
            )}
            {card && (
              <StripePayments
                handleCardSubmit={props.handleCardSubmit}
                userConfiguration={userConfiguration}
                type={"card"}
                cardSubmitBtnLoading={props.cardSubmitBtnLoading}
                UserMemberId={props?.UserMemberId}
              />
            )}
          </>
        );

      default:
        return (
          <PaymentProfileSelection
          makePaymentProfileAsDefault={props?.makePaymentProfileAsDefault}
            paymentProfile={props.paymentProfile}
            handleChangeSavedCard={(val: any, dataItem = null) =>
              props.handleChangeSavedCard(val, dataItem)
            }
            IsFromCalendar={props?.IsFromCalendar}
            page={props?.page}
            checkPaymentProfileNotRequired={
              props.checkPaymentProfileNotRequired
            }
            selectedPaymentProfile={props.selectedPaymentProfile}
            paymentGatewayType={props.paymentGatewayType}
            paymentMethod={props.paymentMethod}
            handleForm={(val: string) => props.handleForm(val)}
            handleSubmit={props.handleSubmit}
            staffPage={props.staffPage}
          />
        );
    }
  };

  const NewfirstPanel = (props: any, selected: any) => {
    if (props.page === "inhouse-class" || props.page === "online-class") {
      console.log(props.SessionSignOff, "innnnnnnnnnnnnnnnnnnnnn");
      return (
        <PurchaseClassPanel
          session={props.session}
          PackagesData={props.PackagesData}
          SessionSignOff={props.SessionSignOff}
          selectedAppt={props.selectedAppt}
          handleChangePackage={props.handleChangePackage}
          ComplimentaryPermission={props?.ComplimentaryPermission}
          totalClassSessions={props?.totalClassSessions}
          clientFormValues={props.clientFormValues}
          packageCost={props?.packageCost}
          handlePackageSelectionForClass={(val: any, item: any) =>
            props?.handlePackageSelectionForClass(val, item)
          }
          handleAutoselectPackage={props?.handleAutoselectPackage}
          packageListToUse={props?.packageListToUse}
          UserMemberId={props?.UserMemberId}
          handleUseCredit={(val: any, sessionItem: any) =>
            props.handleUseCredit(val, sessionItem)
          }
          handleComplimentary={(val: any, sessionItem: any) =>
            props.handleComplimentary(val, sessionItem)
          }
          checkUseCredit={(e: any) => props.checkUseCredit(e)}
          handleRemoveSession={(item: any) => props.handleRemoveSession(item)}
          handleUseCreditSubmit={props?.handleUseCreditSubmit}
          staffPage={props.staffPage}
          appliedCoupons={props.appliedCoupons}
          visibleCouponField={props.visibleCouponField}
          page={props.page}
          filterCoupondata={props?.filterCoupondata}
          handleGroupCoupon={(val:any,item:any) => props.handleGroupCoupon(val,item)}
          handleGroupChangeCoupon={(val: string,item:any) => props.handleGroupChangeCoupon(val,item)}
          coupon={props.coupon}
          handleRemoveGroupCoupon={props?.handleRemoveGroupCoupon}
          filterCouponChange={props?.filterCouponChange}
        />
      );
    }
  };
  const PaymentPanel = (props: any, selected: any) => {
    if (props.page === "inhouse-class" || props.page === "online-class") {
      if (
        props?.downpaymentForm?.paidToday > 0 ||
        (props?.downpaymentForm?.paidToday === 0 &&
          !props?.checkPaymentProfileNotRequired())
      ) {
        return (
          <PanelBarItem
            title={PaymentPanelTitle()}
            id="2"
            selected={selected == "2"}
            expanded={selected == "2"}
            focused={selected == "2"}
          >
            {PaymentGatewayForm()}
            {props?.paymentSubmitProfile?.CardTypeId === -2 &&
              !props?.checkOfflineReferenceRequired() && (
                <>
                  <div className="d-flex flex-column">
                    <div className="paymentdropdown col-md-6 my-2">
                      <Label>Transaction Reference Number</Label>
                      <Input
                        onChange={(event) => {
                          props?.handleChangeSavedCard(
                            props.selectedPaymentProfile?.selectedPayment,
                            {
                              Reference: event.target.value,
                              ReferrenceDocument:
                                props?.paymentSubmitProfile?.ReferrenceDocument,
                            }
                          );
                        }}
                        value={props?.paymentSubmitProfile?.Reference}
                        type={"text"}
                        style={{ width: "100%" }}
                        id={"refNumber"}
                      />
                    </div>
                    <div className="paymentdropdown col-md-6 my-2">
                      <Label>Reference Document</Label>
                      {/* <input type="file" /> */}
                      <Upload
                        autoUpload={false}
                        batch={false}
                        restrictions={{
                          // allowedExtensions: [".jpg", ".png", ".jpeg"],
                          maxFileSize: 1000000,
                        }}
                        showActionButtons={false}
                        multiple={false}
                        onAdd={onAdd}
                        withCredentials={false}
                        onRemove={onRemove}
                        files={originalFiles}
                      />
                    </div>
                  </div>
                </>
              )}
          </PanelBarItem>
        );
      }
    } else {
      return (
        <PanelBarItem
          title={PaymentPanelTitle()}
          id="2"
          selected={selected == "2"}
          expanded={selected == "2"}
          focused={selected == "2"}
        >
          {PaymentGatewayForm()}
        </PanelBarItem>
      );
    }
  };
  const PaymentPanelTitle = () => {
    let val = selectedPaymentValue?.MaskedCCNumber;
    let expiryDate = ExpiryDate;
    if (expiryDate === null) {
      expiryDate = "";
    }
    if (selectedPaymentValue?.CardTypeId === -1) {
      expiryDate = "";
      val = selectedPaymentValue?.CardDescriptionWithoutExpiryDate;
    }
    return selectedPaymentValue &&
      Object.keys(selectedPaymentValue).length !== 0
      ? `Payment: ${val} ${expiryDate}`
      : "Payment: Please Select / Add";
  };

  const today = new Date();
  const threeMonths = new Date(today);
  threeMonths.setMonth(threeMonths.getMonth() + 3);

  return (
    <div className="checkout-panelbar-wrapper">
      {NewfirstPanel(props, selected)}
      {((props.page === "inhouse-class" &&
        props?.selectedClassesType === "bulk") ||
        props.page === "online-class") && (
        <span
          className="btn btn-link text-decoration-underline mb-2"
          onClick={() => props?.handleAddMoreClasses()}
        >
          Purchase More Group
        </span>
      )}
      <br />
      <PanelBar
        expandMode={"single"}
        onSelect={(e) => {
          props.onSelect(e);
        }}
        key={selected?.toString()}
        className="payement-panel-header"
      >
        {props.page === "inhouse-class" && (
          <PanelBarItem
            title={clientPanelTitle()}
            id="1"
            selected={selected == "1"}
            expanded={selected == "1"}
            focused={selected == "1"}
          >
            <div className="pt-3 pb-3 px-3">
              <AddClient
                page={"inhouse-class"}
                disabled={false}
                clientFormValues={props.clientFormValues}
                clientsList={props.clientsList}
                handleClientSubmit={props.handleClientSubmit}
                clientSubmitBtnLoading={props.clientSubmitBtnLoading}
                clientSearchLoading={props.clientSearchLoading}
                handleAddClient={props.handleAddClient}
                selectedClient={props.selectedClient}
                //selectedradioValue={selectedradioValue}
                handleClientSelecton={(val: any) =>
                  props?.handleClientSelecton(val)
                }
                checkout={false}
                fetchClientData={props?.fetchClientData}
                //onChangeRadioValue={handleRadioButtonChange}
              />
            </div>
          </PanelBarItem>
        )}
        {PaymentPanel(props, selected)}
      </PanelBar>
    </div>
  );
};

export default PaymentPanelForm;
