import { Tooltip } from "@progress/kendo-react-tooltip";
import { TooltipContentRefundTax } from "components/Transactions/Refund";
import moment from "moment";
import React from "react";
import { CurrencyFormat } from "utils";

const AddonsInPlanCancelOverview = (props: any) => {
  return (
    <>
     
      <div className="bz-mt-2rem bz-mb-2_5rem">
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Add-on Name</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{props?.addonItem?.AddOnName}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Cancellation Date</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {props?.addonItem?.CancellationDate === null
              ? "NA"
              : moment(props?.addonItem?.CancellationDate).format("L")}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Cancellation Fee</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{CurrencyFormat(props?.addonItem?.CancellationFee)}</div>
        </div>
        {props?.addonItem?.FailedTransactionAmount > 0 && (
          <>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Total Failed Transaction Amount</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">{CurrencyFormat(props?.addonItem?.FailedTransactionAmount)}</div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Waived Failed Transaction Amount</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                {CurrencyFormat(
                  props?.addonItem?.WaivedFailedTransactionAmount
                )}
              </div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Failed Transaction Amount To Collect</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                {CurrencyFormat(
                  props?.addonItem?.FailedTransactionAmountToCollect
                )}
              </div>
            </div>
          </>
        )}
        {props?.addonItem?.AmountToRefund > 0 && (
         <>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Amount to Refund</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {CurrencyFormat(props?.addonItem?.AmountToRefund)}
            </div>
          </div>
          {props?.addonItem?.AmountToRefund > 0 && props?.addonItem?.MetaData && (<div className="bz-content-text-row">
            <div className="bz-content-title text-right"><Tooltip
                content={(tooltipProps: any) => {
                  return (
                    <TooltipContentRefundTax
                      title={tooltipProps.title}
                      data={props?.addonItem?.TaxesArray}
                      TotalTaxAmount={props?.addonItem?.taxAmt}
                      Name={props?.addonItem?.AddOnName}
                    />
                  );
                }}
                tooltipClassName="bz-tooltip-table"
              >
                <a
                  title="Surcharges"
                  style={{
                    borderBottom: "1px dotted #0d6efd",
                    cursor: "pointer",
                  }}
                >
                  {"Surcharges to be refunded"}
                </a>
              </Tooltip></div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {CurrencyFormat(props?.addonItem?.taxAmt)}
            </div>
          </div>)}
         </>
        )}
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Amount to Collect</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{CurrencyFormat(props?.addonItem?.AmountToCollect)}</div>
        </div>

      </div>
      <hr/>

      {/* <h6 className="card-title bz-fw-600 bz-article">
        {props?.addonItem?.AddOnName} <small>(AddOn)</small>
      </h6>
      <div className="col-12 col-lg-12">
        <p className="row">
          <span className="col bz-article">Cancellation Date</span>:{" "}
          <span className="col">
            {props?.addonItem?.CancellationDate === null
              ? "NA"
              : moment(props?.addonItem?.CancellationDate).format("L")}
          </span>
        </p>
      </div>
      <div className="col-12 col-lg-12">
        <p className="row">
          <span className="col bz-article">Cancellation Fee</span>:{" "}
          <span className="col">
            {CurrencyFormat(props?.addonItem?.CancellationFee)}
          </span>
        </p>
      </div>
      {props?.addonItem?.FailedTransactionAmount > 0 && (
        <>
          <div className="col-12 col-lg-12">
            <p className="row">
              <span className="col bz-article">
                Total Failed Transaction Amount
              </span>
              :{" "}
              <span className="col">
                {CurrencyFormat(props?.addonItem?.FailedTransactionAmount)}
              </span>
            </p>
          </div>
          <div className="col-12 col-lg-12">
            <p className="row">
              <span className="col bz-article">
                Waived Failed Transaction Amount
              </span>
              :{" "}
              <span className="col">
                {CurrencyFormat(
                  props?.addonItem?.WaivedFailedTransactionAmount
                )}
              </span>
            </p>
          </div>
          <div className="col-12 col-lg-12">
            <p className="row">
              <span className="col bz-article">
                Failed Transaction Amount To Collect
              </span>
              :{" "}
              <span className="col">
                {CurrencyFormat(
                  props?.addonItem?.FailedTransactionAmountToCollect
                )}
              </span>
            </p>
          </div>
        </>
      )}
      {props?.addonItem?.AmountToRefund > 0 && (
        <div className="col-12 col-lg-12">
          <p className="row">
            <span className="col bz-article">Amount to Refund</span>:{" "}
            <span className="col">
              {CurrencyFormat(props?.addonItem?.AmountToRefund)}
            </span>
          </p>
        </div>
      )}

      <div className="col-12 col-lg-12">
        <p className="row">
          <span className="col bz-article">Amount to Collect</span>:{" "}
          <span className="col">
            {CurrencyFormat(props?.addonItem?.AmountToCollect)}
          </span>
        </p>
      </div> */}
    </>
  );
};

export default AddonsInPlanCancelOverview;
