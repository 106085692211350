import React, { useState,useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";

import { Switch } from "@progress/kendo-react-inputs";
import BuzopsButton from "generic-components/BuzopsButton";
import { TenantService } from "services/tenant/index.service";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { GetLocalStore } from "utils/storage";
import withNotification from "components/Hoc/withNotification";

const ManageNotifications = (props: any) => {
  const service= new TenantService();
  const CurrentApplicationType=GetLocalStore("CurrentApplicationType")
  const [btnLoading, setBtnLoading] = useState<any>(false);
  const [loading, setLoading] = useState<any>(true);
  const [AllClientsList, setAllClientsList] = useState<any>([]);
  const [initialloading, setInitialLoading] = useState<any>(true);
  const [modified, setModified] = useState<any>(false);
  const initialValues = {
    UserId: props?.userData?.UserId,
    ApplicationType: CurrentApplicationType,
    AppointmentCancellation: false,
    AppointmentCompletion: false,
    AppointmentConfirmation: false,
    AppointmentModification: false,
    AppointmentWithRecurence: false,
    AppointmentReminder: false,
  };
  const [notificationData,setNotificationData]=useState(initialValues)

  useEffect(() => {
    fetchAllSubmembers()
    fetchUserNotification()
  }, []);

  const fetchUserNotification=async(UserId=notificationData?.UserId)=>{
    setLoading(true)
    const res= await service.GetUserNotifications(UserId)
    const response={...res,ApplicationType: CurrentApplicationType}
    setNotificationData(response)
    setLoading(false)
  }

  const fetchAllSubmembers=async(UserId=notificationData?.UserId)=>{
    setInitialLoading(true)
    const res = await service.GetSubMembersWithParent(props?.userData?.UserMemberId);
    setAllClientsList(res)
    setInitialLoading(false)
  }

  const handleSubmit = async () => {
    let req=notificationData
    //req.UserId=0;
    setBtnLoading(true)
    const result = await service.SaveNotificationSubscription(req)
    setBtnLoading(false)
    if (result?.ResponseCode === 100) {
        props?.handleNotificationMessage(
          `Notifications updated successfully`,
          "success"
        );
        setModified(false)
        fetchUserNotification(notificationData?.UserId)
    } else {
        const errorMsg =
        result?.ErrorMessages.length > 0
        ? result?.ErrorMessages[0]
        : "Error in Saving Notifications";
        props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const handleCancel = () => {
    props?.handleClose();
    setModified(false)
  };

  const handleChange = (val: any, name: string) => {
    if(name==="UserId"){
        fetchUserNotification(val)
        setModified(false)
    }else{
        setModified(true)
        setNotificationData({...notificationData,[name]:val})
    }
  };

  

  return (
    <>
      <h5 className="mb-3 bz_fs_1_5">
        <button
          className="btn btn-primary bz-back-btn btn-sm"
          onClick={() => props?.handleClose()}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        &nbsp;Appointment Notification For clients
      </h5>
      <hr />
      {initialloading ? <BuzopsLoader type="list" /> : (<>
        <div className="bz-subsection-row bz-notification-container">
          <div className="w-100">
            <div className="row bz-mb-2_5rem">
              <div className="col-sm-12 col-xl-12 col-xxl-12 order-2 order-sm-1">
                <div className="d-flex gap15 bz-client-dropdown">
                  <div className="bz-content-title text-right">
                    Client :
                  </div>
                   <div className="bz-content-body  bz-complementary-provider-dropdown">
                     {AllClientsList?.length ===1 ? (<>
                    {AllClientsList[0]?.FullName}
                    </>):(<>
                  <DropDownList
                      data={AllClientsList}
                      textField="FullName"
                      dataItemKey="UserId"
                      style={{width:'100%'}}
                      className={"customDropdown"}
                      onChange={(e) => {
                          handleChange(e.value.UserId, "UserId");
                      }}
                      value={
                        AllClientsList.filter(
                          (res: any) =>
                            res?.UserId ===
                            notificationData?.UserId
                        )[0]
                      }
                    />
                    </>)}
                  </div>
                </div>
                <p className="notification-sub-heading mt-2">{'Appointment Notifications'}</p>
                {loading ? (<>
                <BuzopsLoader type="list" />
                </>) : (<>
                    <div className='row'>
                      <div className="col-md-6">
                        <div className="notificationflex">
                          <div className="bz-content-body">
                            <Switch
                              checked={notificationData?.AppointmentCancellation}
                              onChange={(e: any) => handleChange(e.value, "AppointmentCancellation")}
                            />
                          </div>
                          <div className="bz-content-title  ">
                            Send Appointment Cancellation Email Notifications
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="notificationflex">
                          <div className="bz-content-body">
                            <Switch
                              checked={notificationData?.AppointmentCompletion}
                              onChange={(e: any) => handleChange(e.value, "AppointmentCompletion")}
                            />
                          </div>
                          <div className="bz-content-title ">
                            Send Appointment Completion Email Notifications
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="notificationflex">
                          <div className="bz-content-body">
                            <Switch
                              checked={notificationData?.AppointmentConfirmation}
                              onChange={(e: any) => handleChange(e.value, "AppointmentConfirmation")}
                            />
                          </div>
                          <div className="bz-content-title ">
                            Send Appointment Confirmation Email Notifications
                          </div>
                        </div>
                      </div>


                      <div className="col-md-6">
                        <div className="notificationflex">
                          <div className="bz-content-body">
                            <Switch
                              checked={notificationData?.AppointmentModification}
                              onChange={(e: any) => handleChange(e.value, "AppointmentModification")}
                            />
                          </div>
                          <div className="bz-content-title ">
                            Send Appointment Modification Email Notifications
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="notificationflex">
                          <div className="bz-content-body">
                            <Switch
                              checked={notificationData?.AppointmentReminder}
                              onChange={(e: any) => handleChange(e.value, "AppointmentReminder")}
                            />
                          </div>
                          <div className="bz-content-title ">
                            Send Appointment Reminder Email Notifications
                          </div>
                        </div>
                      </div>


                    </div>

                  
            
                <div className="">
                  <div className="d-flex justify-content-end justify-content-sm-center justify-content-xl-end mt-4 mt-xl-0">
                    <Button onClick={() => handleCancel()} disabled={btnLoading} className="ml-0">
                      Cancel
                    </Button>
                    <BuzopsButton
                      disabled={btnLoading || !modified}
                      type={"submit"}
                      loading={btnLoading}
                      label={"Update"}
                      onClick={() => handleSubmit()}
                    />
                  </div>
                </div>
                </>)}

              </div>
            </div>
          </div>
        </div>
      </>)}
    </>
  );
};

export default withNotification(ManageNotifications)
