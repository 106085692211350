import { Switch } from "@progress/kendo-react-inputs";
import React from "react";
import { Notification as Notify } from "@progress/kendo-react-notification";
import { CurrencyFormat } from "utils";
import FailedTransactionsGrid from "../FailedTransactionsGrid";
import { AddonFrequency } from "utils/form-utils";
const PlanAddonActivate = (props: any) => {
  return (
    <>
      {props?.activationDetails?.Subscriptions?.map((dataItem: any) => {
        const {
          ServiceName,
          Charge,
          Continue,
          ProratedAmount,
          Frequency,
          FrequencyDescription,
          StatusDescription,
          RepeatFrequency,
          NextRecurrence,
          SubscriptionId,
          FailedPayments,
          CanWaiveProratedAmount,
          StartDate,
          EndDate
        } = dataItem;
        return (
          <>
            <div className="bz-mt-2rem bz-mb-2_5rem bz-content-text-row-mobile">
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">
                  Do you want to activate Addon ?
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <Switch
                    checked={Continue}
                    onChange={(e) =>
                      props?.handleChange(e.value, "Continue", SubscriptionId)
                    }
                  />
                </div>
              </div>
              {!Continue && (
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right"></div>
                  <span className="bz-colon text-center"></span>
                  <div className="bz-content-body">
                    <div className="bz-plan-note">
                      <p className="mb-0">
                        <Notify key="info" type={{ style: "info" }}>
                          {"This Addon will cancel today"}
                        </Notify>
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Add-on Name</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">{ServiceName}</div>
              </div>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">
                  Start Date
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">{new Date(StartDate).toLocaleDateString()}</div>
              </div>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Amount</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">{CurrencyFormat(Charge)}</div>
              </div>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Status</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">{StatusDescription}</div>
              </div>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Frequency</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  {Frequency === 1 
                    ? FrequencyDescription 
                    : `${FrequencyDescription} - Every ${RepeatFrequency} ${AddonFrequency.find(addon => addon.id === Frequency)?.shortValue}`}
                </div>
              </div>
              {ProratedAmount > 0 && (<>
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right">Proration Period</div>
                    <span className="bz-colon text-center">:</span>
                    {NextRecurrence && (
                      <div className="bz-content-body">{`${new Date().toLocaleDateString()} - ${new Date(new Date(NextRecurrence).setDate(new Date(NextRecurrence).getDate() - 1)).toLocaleDateString()}`}</div>  
                    )}                      
                  </div>
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right">
                      Prorated Amount
                    </div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      {CurrencyFormat(ProratedAmount)}
                          <Switch
                            className="ms-3"
                            name="Waive"
                            onChange={(e) =>
                              props?.handleChange(e.target.value, "CanWaiveProratedAmount", SubscriptionId)
                            }
                            checked={CanWaiveProratedAmount}
                          />
                          <label className={"switch-label"}>{"Waive"}</label>               
                    </div>
                  </div>
                  </>
              )}      
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">
                  Next Recurrence
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">{new Date(NextRecurrence).toLocaleDateString()}</div>
              </div>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">
                  End Date
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">{(EndDate != null && EndDate !=='') ? `${new Date(EndDate).toLocaleDateString()}`:'N/A'}</div>
              </div>
            </div>
            {FailedPayments?.length > 0 && (
              <FailedTransactionsGrid
                details={dataItem?.FailedPayments}
                selectionChange={(e: any, id: number) =>
                  props.selectionChange(e, SubscriptionId)
                }
                EntityId={SubscriptionId}
              />
            )}
            <hr className="bz-addons-hr" />
          </>
        );
      })}
    </>
  );
};

export default PlanAddonActivate;
