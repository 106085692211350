import { Notification as Notify } from "@progress/kendo-react-notification";
import React from "react";
import { CurrencyFormat } from "utils";
import { AddonFrequency } from "utils/form-utils";

const AddonsInActivationOverview = (props: any) => {
  const {
    ServiceName,
    Charge,
    FrequencyDescription,
    Continue,
    ProratedAmount,
    FailedTransactionAmount,
    WaivedFailedTransactionAmount,
    FailedTransactionAmountToCollect,
    DownPayment,
    Frequency,
    RepeatFrequency,
    NextRecurrence
  } = props?.addonItem;
  return (
    <>
      <div className="bz-mt-2rem bz-mb-2_5rem">
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Add-on Name</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{ServiceName}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Amount</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{CurrencyFormat(Charge)}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Frequency</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {Frequency === 1 
              ? FrequencyDescription 
              : `${FrequencyDescription} - Every ${RepeatFrequency} ${AddonFrequency.find(addon => addon.id === Frequency)?.shortValue}`}
          </div>
        </div>
        {ProratedAmount > 0 && (<>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Proration Period</div>
            <span className="bz-colon text-center">:</span>
            {NextRecurrence && (
              <div className="bz-content-body">{`${new Date().toLocaleDateString()} - ${new Date(new Date(NextRecurrence).setDate(new Date(NextRecurrence).getDate() - 1)).toLocaleDateString()}`}</div>  
            )}                      
          </div>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Prorated Amount</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {CurrencyFormat(ProratedAmount)}
            </div>
          </div>
        </>
        )}
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
            Total Failed Payments
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {CurrencyFormat(FailedTransactionAmount)}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
            Amount to collect
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {CurrencyFormat(FailedTransactionAmountToCollect)}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
            Waived Amount
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {props?.addonItem.CanWaiveProratedAmount ? CurrencyFormat(WaivedFailedTransactionAmount+ProratedAmount) : CurrencyFormat(WaivedFailedTransactionAmount)}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
            Total Due
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{CurrencyFormat(props?.addonItem.CanWaiveProratedAmount ? DownPayment-ProratedAmount : DownPayment)}</div>
        </div>
        {!Continue && (
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right"></div>
            <span className="bz-colon text-center"></span>
            <div className="bz-content-body">
              {" "}
              <div className="bz-plan-note">
                <p className="mb-0">
                  <Notify key="warning" type={{ style: "warning" }}>
                    {"This service will cancel today"}
                  </Notify>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <hr />
    </>
  );
};

export default AddonsInActivationOverview;
