import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";

const TrainerSelection = (props: any) => {
  const { allStaffList = [], selectedUsermemberId,width="100%",trainerfilterChange } = props;
  
  
  return (
    <>
      <DropDownList
        data={allStaffList}
        textField="FullName"
        dataItemKey="UserId"
        onChange={(e) => {
          props?.handleUpdateStaff(e.value);
        }}
        disabled={props?.disabled}
        value={
            allStaffList?.filter(
            (res: any) => res?.UserId === selectedUsermemberId
          )[0] || {UserId:0,FullName:"Select Staff"}
        }
        style={{ width: width }}
        filterable={true}
        onFilterChange={trainerfilterChange}
      />
    </>
  );
};

export default TrainerSelection;
