import BuzopsLoader from "generic-components/BuzopsLoader";
import { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import BuzopsButton from "generic-components/BuzopsButton";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { Error, Hint } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import moment from "moment";

const ChangePlanRate = (props: any) => {
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [updatePlanRate, setUpdatePlanRate] = useState<any>({
    MonthlyRate: 0,
    MemberPlanId: 0,
    UserMemberId: 0,
    Name: "",
  });
  const service = new TenantService();

  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi = async () => {
    setLoading(true);
    await fetchPlanSummary();
    setLoading(false);
  };

  const fetchPlanSummary = async () => {
    const req = {
      PageNumber: 0,
      PageSize: 32767,
      UserMemberId: props?.userData?.UserMemberId,
      MemberPlanId: props?.selectedPlanId,
    };
    const res = await service?.getMemberPlanSummary(req);
    setSelectedPlan(res);
    setUpdatePlanRate({
      MonthlyRate: res?.MonthlyRate,
      MemberPlanId: res?.MemberPlanId,
      UserMemberId: res?.UserMemberId,
      Name: res?.PlanName,
    });
    props?.setSelectedSubscription(res);
  };

  const handleSubmitPlanRateChanges = async () => {
    if (
      updatePlanRate.MonthlyRate === undefined ||
      updatePlanRate.MonthlyRate === null ||
      updatePlanRate?.MonthlyRate < 0 ||
      !updatePlanRate?.Name?.trim()
    ) {
      return;
    }
    setBtnLoading(true);
    const result = await service.UpdatePlanRate(updatePlanRate);
    setBtnLoading(false);
    if (result?.ResponseCode === 100) {
      const successMsg = `The updated membership rate will be effective from ${
        selectedPlan?.RecurringDate !== "N/A"
          ? moment(selectedPlan?.RecurringDate).format("L")
          : "Next Billing"
      }, including recurring and renewal payments.`;
      props?.handleNotificationMessage(successMsg, "success");
      await fetchPlanSummary();
      props?.gotoPlanOverview()
    } else {
      const errorMsg =
        result?.ErrorMessages?.[0] || "Error In Updating Membership";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleChangePlanRate = (name: string, value: any) => {
    let val = value;
    if (name === "MonthlyRate") {
      if (value <= 0) {
        val = 0;
      }
    }
    setUpdatePlanRate({
      ...updatePlanRate,
      [name]: val,
    });
  };

  const handleReset = () => {
    setUpdatePlanRate({
      MonthlyRate: selectedPlan?.MonthlyRate,
      MemberPlanId: selectedPlan?.MemberPlanId,
      UserMemberId: selectedPlan?.UserMemberId,
      Name: selectedPlan?.PlanName,
    });
  };

  return (
    <>
      <>
        {loading ? (
          <>
            <BuzopsLoader type={"list"} />
          </>
        ) : (
          <div className="bz-subsection-row bz-plan-overview">
            <div className="left-section">
              <h5 className="mb-3 pt-3 bz_fs_1_5">
                Change Membership Rate
              </h5>
              <div className={`bz-content-text-row`}>
                <div className="bz-content-title text-right">
                  Membership Name
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  {updatePlanRate?.Name}
                  {/* <div className={"k-form-field-wrap"}>
                    <Input
                      onChange={(event) =>
                        handleChangePlanRate("Name", event?.target?.value)
                      }
                      value={updatePlanRate?.Name}
                      type={"text"}
                      style={{ width: "280px" }}
                      id={"Name"}
                    />
                    {!updatePlanRate?.Name?.trim() && (
                      <Error>
                        <span>{"Please provide the Name"}</span>
                      </Error>
                    )}
                  </div> */}
                </div>
              </div>
              <div className={`bz-content-text-row align-items-start bz-notify-row`}>
                <div className="bz-content-title text-right">
                  Monthly Rate 
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <div className={"k-form-field-wrap"}>
                    <NumericTextBox
                      width={280}
                      defaultValue={0}
                      format="c2"
                      min={0}
                      onChange={(event) => {
                        handleChangePlanRate(
                          "MonthlyRate",
                          event?.target?.value
                        );
                      }}
                      value={updatePlanRate.MonthlyRate}
                    />
                    {(updatePlanRate.MonthlyRate === undefined ||
                      updatePlanRate.MonthlyRate === null ||
                      updatePlanRate?.MonthlyRate < 0) && (
                      <Error>
                        <span>{"Please provide valid Monthly Rate"}</span>
                      </Error>
                    )}
                    <Hint>
                      {`Modified rate will be effective from ${
                        selectedPlan?.RecurringDate !== "N/A"
                          ? moment(selectedPlan?.RecurringDate).format("L")
                          : "Next Billing"
                      }, including recurring and renewal payments.`}
                    </Hint>
                  </div>
                </div>
              </div>

              <div className="bz-content-text-row">
                <div className="bz-content-title text-right"></div>
                <span className="bz-colon text-center"></span>
                <div className="bz-content-body">
                  <Button onClick={() => handleReset()} disabled={btnLoading}>
                    {"Reset"}
                  </Button>
                  <BuzopsButton
                    label="Save"
                    loading={btnLoading}
                    disabled={btnLoading}
                    onClick={() => handleSubmitPlanRateChanges()}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default ChangePlanRate;
