import withNotification from "components/Hoc/withNotification";
import useSecuritySystemConfigs from "./Container/SecuritysystemConfigs.hook";
import CheckinUserBarcode from "./CheckinUserBarcode";
import CheckinUserOpenPath from "./CheckinUserOpenPath";
import BuzopsLoader from "generic-components/BuzopsLoader";
import CheckinUserBrivoPassport from "./CheckinUserBrivoPassport";

const CheckinUser = (props: any) => {
  const {
    securityConfig,
    isOpenPathSystem,
    isBarcodeSystem,
    isBrivoPassportPathSystem
  } = useSecuritySystemConfigs();

  const renderFormBasedOnConfig = () => {
    if(isOpenPathSystem()){
      return <CheckinUserOpenPath {...props} securitySystemConfigs={securityConfig} />
    }
    if(isBarcodeSystem()) {
      return <CheckinUserBarcode {...props} securitySystemConfigs={securityConfig} />
    }
    if(isBrivoPassportPathSystem()) {
      return <CheckinUserBrivoPassport {...props} securitySystemConfigs={securityConfig} />
    }
    if(securityConfig){ 
      return <CheckinUserBrivoPassport {...props} securitySystemConfigs={securityConfig} />
    }
    return<BuzopsLoader/>
  }
  return (
    <>
    {
      renderFormBasedOnConfig()
    }
    </>
  )
};

export default withNotification(CheckinUser);
