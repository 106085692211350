import { filterBy } from "@progress/kendo-data-query";
import { addDates } from "components/checkout/Checkout";
import PackageOrderSummary from "components/package/PackageOrderSummary";
import PackagePaymentPanelForm from "./PackagePaymentPanelForm";
import moment from "moment";
import { useEffect } from "react";
import useState from "react-usestateref";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory } from "react-router-dom";
import { PaymentMethodSelected } from "redux-slices/genericSlice";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { PackageService } from "services/package/index.service";
import { TenantService } from "services/tenant/index.service";
import {
  CheckUserMemberStatus,
  CurrencyFormat,
  CustomToFixed,
  calculatePackageRecurrenceDate,
  checkHasTaxEnable,
  getLongDate,
} from "utils";
import {
  CouponDurationTypeEnum,
  CreditsErrorMessage,
  PackageFrequency,
  PackageFrequencyEnum,
  PackagePresetFrequency,
  SessionTypeEnum,
  TypeEnum,
  recurrenceUnits,
} from "utils/form-utils";
import images from "utils/images";
import { GetLocalStore } from "utils/storage";
import SignaturePad from "components/signaturePad";
import withNotification from "components/Hoc/withNotification";
import { Dialog } from "@progress/kendo-react-dialogs";
import ClientNameWithMemberType from "components/summary/ClientNameWithMemberType";
import { Button } from "@progress/kendo-react-buttons";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsWindow from "generic-components/BuzopsWindow";
import BuzopsLoader from "generic-components/BuzopsLoader";
import MemberSelection from "components/submemberseletion/MemberSelection";
import TermsAndConditions from "components/checkout/TermsAndConditions";
import CheckPermissions from "utils/permissions";
import { PermissionEnum } from "utils/PermissionEnum";
import { Loader } from "@progress/kendo-react-indicators";
import AppointmentRates from "./AppointmentRates";
import AdditionalPayment from "components/AdditionalPayment/AdditionalPayment";
import { useApiClient } from "services/axios-service-utils";
import { shouldSetAsDefaultPaymentProfile } from "utils/utils";

const PurchasePackage = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const redirectionObj = useSelector((state: any) => state?.redirection);
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const userDetails = GetLocalStore("userDetails");
  const [termsandConditions, setTermsandConditions] = useState<boolean>(true);
  const [appointmentRatesDialog, setAppointmentRatesDialog] = useState<boolean>(false);
  const [termsandConditionsDialog, setTermsandConditionsDialog] =
    useState<boolean>(false);
  //payment profiles to submit
  const [paymentSubmitProfile, setPaymentSubmitProfile] = useState<any>({});
  const [recurringAmount, setRecurringAmount] = useState(0);
  const [customLoading, setCustomLoading] = useState(false);
  //installment table data to show recurrences
  const [tableData, setTableData] = useState<any>([]);
  let staffPage = false;
  if (props?.location?.state?.staffPage || props?.staffPage || props?.calenderPage) {
    staffPage = true;
  }

  let calenderPage = false;
  if (props?.calenderPage) {
    calenderPage = true;
  }

  let checkoutType = "online-package";

  const providerId = props.match?.params?.providerId;

  let appointmentpage = props?.appointmentpage;

  const [guestLayout, setGuestLayout] = useState<boolean>(
    providerId ? true : false
  );

  let clientMemberId =
    props?.userDatafromAppointment?.UserMemberId || props?.location?.state?.userData?.UserMemberId ||
    userDetails?.UserSession?.UserMemberId;

  // to get User Member Id
  const [userMemberId, setUserMemberId] = useState<any>(
    clientMemberId || undefined
  );

  let userSessionUserId: any;
  if (checkoutType === "inhouse-package" || staffPage) {
    const tenantUserSession = GetLocalStore("tenantUserSession");
    userSessionUserId = tenantUserSession?.UserId;
  }

  const permissionRight = CheckPermissions(PermissionEnum["AdministratorAccess"]);
  const defaultPaymentMethod={
    card: false,
    bank: false,
    addOther:false
  }
  // to show payment profile type
  const [paymentMethod, setPaymentMethod] = useState(defaultPaymentMethod);
  //saved payment profiles
  const [paymentProfile, setPaymentProfile] = useState([]);

  //client details
  const [clientFormValues, setClientFormValues] = useState<any>({});
  //selected payment profile
  const [selectedPaymentProfile, setSelectedPaymentProfile] = useState<any>({
    selectedPayment: undefined,
    AlternatePaymentProfileId: null,

  });
  const initialAdditionalPayment = {
    dialog: false,
    selectedPaymentProfile: null,
  };
  const [showAdditionalPayment, setShowAdditionalPayment] = useState<any>(
    initialAdditionalPayment
  );

  const InstallmentDate = addDates(new Date(), 1, "Months");

  const downPaymentInitialValues = {
    paidToday: 0,
    recurrenceUnit: {
      text: "MONTHLY",
      id: 4,
      description: "Monthly",
      shortValue: "month",
    },
    recurringStartDate: new Date(InstallmentDate),
    OcurrsEvery: 1,
    installments: 1,
  };


  const [loading, setLoading] = useState(false);
  // to select clientList AutoComplete
  const [AllClientsList, setAllClientsList] = useState<any>([]);
  const tenantService = new TenantService();

  //  payment profile card initial form values
  const cardIntialValues = {
    MaskedCCNumber: undefined,
    CardTypeId: undefined,
    ExpiryMonthYear: undefined,
    ExpiryMonth: undefined,
    ExpiryYear: undefined,
    ZipCode: undefined,
    PaymentType: "Card",
    UserMemberId: userMemberId,
  };
  const [cardFormValues, setCardFormValues] = useState<any>(cardIntialValues);

  //  payment profile bank initial form values
  const bankIntialValues = {
    AccountName: undefined,
    BankAccountType: undefined,
    BankName: undefined,
    RoutingNumber: undefined,
    AccountNumber: undefined,
    UserMemberId: userMemberId,
    PaymentType: "Bank",
  };
  const [bankFormValues, setBankFormValues] = useState<any>(bankIntialValues);

  //To client details to submit
  const [clientSubmitDetails, setClientSubmitDetails] = useState<any>({});
  //SalesPerson
  const [salesPersons, setSalesPersons] = useState<any>([]);
  //selected sales person
  const [selectedSalesPerson, setSelectedSalesPerson] =
    useState<any>(undefined);
  const [showinstallmentForm, setShowinstallmentForm] = useState(false);

  const [Taxes, setTaxes, TaxesRef] = useState<any>([]);
  const [PredictedTaxes, setPredictedTaxes] = useState<any>([]);
  const [TotalTaxAmount, setTotalTaxAmount] = useState<number>(0);
  const [PredictedTotalTaxAmount, setPredictedTotalTaxAmount] =
    useState<number>(0);
  //package cost after discount

  const packageItem = props.page
    ? props.package
      ? props.package
      : null
    : props?.location?.state?.package
      ? props?.location?.state?.package
      : null;
  const { axiosRequest } = useApiClient();

  const minimumDate = new Date(moment().add(1, "day").format("L"));

  let ExpiryDate = minimumDate;
  let ExpireValue = 0;
  if (packageItem?.ExpireOn === 8) {
    ExpireValue = 8;
    ExpiryDate = packageItem?.ExpiryDate;
  }

  const [packag, setPackage, packageRef] = useState({
    ...packageItem,
    StartDate: new Date(),
    Expire: ExpireValue,
    ExpiryDate: ExpiryDate,
    ClientCanSkipPayment: false,
    SignupDate: new Date(),
    BillingType:
      packageItem?.Frequency === PackageFrequencyEnum.MONTHLY &&
        packageItem?.RepeatFrequency === 1
        ? packageItem?.CanProrate
          ? 3
          : 2
        : 2,
    BillingDay: packageItem?.BillingDay ? packageItem?.BillingDay : 1,
    ProratedSchedules: packageItem?.Schedules,
    ProratedCost: 0,
    OptedSchedules: packageItem?.Schedules,
    OptedMaxRollOverSchedules: packageItem?.MaxRollOverSchedules,
    IndividualSessionRate: packageItem?.Cost,
    DownPayment: packageItem?.DownPayment,
    Schedules: packageItem?.Schedules,
    CanClientOverrideRenewalOption: packageItem?.CanClientOverrideRenewalOption,
    RenewalType: packageItem?.CanRenewAfterLastAppointmentCompletion ? 2 : 0,
    Cost:
      packageItem?.SessionType === 3
        ? packageItem?.Schedules * packageItem?.Cost
        : packageItem?.Cost,
    DiscountedCost:
      packageItem?.SessionType === 3
        ? packageItem?.Schedules * packageItem?.Cost
        : packageItem?.Cost,
    PayByRecurring:
      packageItem?.Frequency === 1 && packageItem?.SessionType === 3
        ? true
        : false,
    PackageFeeMappings: null,
  });
  const today = moment(new Date()).format("L");
  const recurringUnit = PackagePresetFrequency?.find(
    (i: any) => i?.id === packageItem?.Frequency
  );
  const nextMonth = calculatePackageRecurrenceDate(
    today,
    packageItem?.RepeatFrequency,
    recurringUnit?.shortValue
  );
  const [downpaymentForm, setDownpaymentForm, downpaymentFormRef] =
    useState<any>({
      paidToday: packageItem?.DownPayment,
      recurrenceUnit: recurringUnit,
      recurringStartDate: new Date(nextMonth),
      OcurrsEvery: packageItem?.RepeatFrequency,
      installments: packageItem?.Occurrence,
    });

  const [packageCostAfterDiscount, setPackageCostAfterDiscount] = useState(
    packageItem?.SessionType === 3
      ? packageItem?.Schedules * packageItem?.Cost
      : packageItem?.Cost
  );
  // entered coupon text
  const [coupon, setCoupon] = useState("");

  //captured coupon details
  const [couponDetails, setCouponDetails] = useState<any>({});

  const [appliedCoupons, setAppliedCoupons, appliedCouponsref] = useState<any>([]);

  //selected coupon amount
  const [couponAmount, setCouponAmount] = useState(0);

  //for buzops button loading for coupon
  const [couponBtnLoading, setCouponBtnLoading] = useState(false);

  //visible coupon fields
  const [visibleCouponField, setvisibleCouponField] = useState(false);
  const [coupondata, setCouponData] = useState<any>([]);
  const [filterCoupondata, setfilterCouponData] = useState<any>([]);

  const [IsTaxSkipped, setIsTaxSkipped] = useState<boolean>(true);

  // to expand and collpase panel bar items
  let panelbarItem = "2";

  const [selected, setSelected] = useState<any>(panelbarItem);

  const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
  const [paymentGatewayType, setPaymentGatewayType] =
    useState<any>(paymentGatewaytype);

  //to auto populate latest submitted payment profile
  const [populateProfile, setPopulateProfile] = useState<any>(null);

  // credit cards image placeholder
  const [cardImageIcon, setcardImageIcon] = useState(images.placeholder);

  //to view  signature popup
  const [visibleSignPad, setVisibleSignPad] = useState(false);

  //base64 encode signature
  const [signature, setSignature] = useState("");

  const [signatureText, setSignatureText] = useState<any>("");
  const [signatureFont, setSignatureFont] = useState<any>("");
  const [signatureType, setSignatureType] = useState<any>("");

  const [confirmClientDialog, setConfirmClientDialog] = useState(false);
  const [purchaseCheckout, setPurchaseCheckout] = useState<any>({
    page: "",
    params: null,
  });

  // to set build agreement
  const [buildagreement, setBuildagreement] = useState({});

  // to get agreement template
  const [agreementTemplate, setAgreementTemplate] = useState("");
  const [disable, setDisable] = useState(false);

  //to handle agreement window
  const [visibleAgreement, setVisibleAgreement] = useState(false);

  //for buzops button loading for paynow and build agreement
  const [btnLoading, setBtnLoading] = useState(false);
  //for buzops button loading for card submit
  const [cardSubmitBtnLoading, setCardSubmitBtnLoading] = useState(false);

  const [checkoutPackage, setCheckoutPackage] = useState<any>({
    showDialog: false,
    package: "",
  });

  //for buzops button loading for bank submit
  const [bankSubmitBtnLoading, setBankSubmitBtnLoading] = useState(false);

  const [packageList, setPackageList] = useState([]);

  

  // to get all initial data while loading
  useEffect(() => {
    fetchAllApis();
  }, []);

  // useEffect(() => {
  //   fetchPackageById(packag);
  // },[packag])

  const fetchPackageById = async (data: any) => {
    const service = new PackageService();

    const res = await service.getPackage(data?.PackageId);
    if (res?.PackageFeeMappings?.length > 0) {
      const updatedPackageFeeMappings = res.PackageFeeMappings.map((fee: any) => ({
        ...fee,
        RevisedAmount: fee.Amount,
        CanFeesRemoved: false,
      }));

      setPackage({
        ...packageRef.current,
        PackageFeeMappings: updatedPackageFeeMappings,
        Duration: res?.Appointment?.Duration || 0
      });
      handleTaxCalculation(downpaymentFormRef?.current?.paidToday, TaxesRef?.current)
      handleTaxCalculation(packageCostAfterDiscount, TaxesRef?.current, true);
      CalculateRecurrence(true);
    } else {
      setPackage({
        ...packageRef.current,
        PackageFeeMappings: [],
        Duration: res?.Appointment?.Duration || 0
      });
    }

  };

  // after submission of card or bank need to update payment profile with latest one
  useEffect(() => {
    const { selectedPayment } = selectedPaymentProfile;
    const { bank, card } = paymentMethod;

    if (
      populateProfile &&
      !selectedPayment &&
      paymentProfile &&
      !bank &&
      !card &&
      paymentProfile?.length > 0
    ) {
      const index = paymentProfile.findIndex(
        (i: any) =>
          i?.PaymentGatewayPaymentProfileID ===
          populateProfile?.PaymentGatewayPaymentProfileID
      );
      if (index !== -1) {
        const selectPayment: any = paymentProfile[index];
        setSelectedPaymentProfile({
          ...selectedPaymentProfile,
          selectedPayment: selectPayment,
        });
        generatePaymentProfileObj(selectPayment);
      }
    }
  }, [paymentProfile]);

  useEffect(() => {
    if (appointmentpage) {
      //if (userDatafromAppointment != userDetails) {
      updateSelectedClientData(props?.userDatafromAppointment);
      //}
      setAppliedCoupons([]);
      fetchPackageData(undefined, props?.packageDatafromAppointment);
    }
  }, [props?.packageDatafromAppointment, props?.userDatafromAppointment]);

  useEffect(() => {
    const { paidToday } = downpaymentForm;
    if (
      paidToday !== null &&
      paidToday !== undefined &&
      paidToday >= 0 &&
      paidToday < packageCostAfterDiscount
    ) {
      setShowinstallmentForm(true);
    } else {
      setShowinstallmentForm(false);
    }
  }, [downpaymentForm.paidToday, packageCostAfterDiscount]);

  useEffect(() => {
    const { installments, recurrenceUnit, recurringStartDate, OcurrsEvery } =
      downpaymentForm;
    if (
      installments &&
      installments > 0 &&
      recurrenceUnit != undefined &&
      recurringStartDate != undefined &&
      OcurrsEvery >= 0
    ) {
      CalculateRecurrence(true);
    } else {
      CalculateRecurrence(false);
    }
  }, [downpaymentForm]);

  const fetchAllApis = async () => {
    if (!packageItem) {
      history.push(`/`)
      return
    }
    setLoading(true);
    let memberId = userMemberId;
    let primaryMemberId = userMemberId;
    if (memberId) {
      const res = await getAllSubMembers(memberId);
      primaryMemberId = res;
    }
    if (staffPage) {
      await fetchSalesPerson();
    }
    if (checkoutType === "inhouse-package") {
      if (memberId) {
        await fetchPaymentProfiles(primaryMemberId);
      }
      await fetchSalesPerson();
      await fetchPackages();
      const taxData = await fetchTaxes();
      await fetchPackageData(taxData);
    } else if (checkoutType === "online-package") {
      if (primaryMemberId) {
        await fetchPaymentProfiles(primaryMemberId);
      }
      await fetchPackages();

      const taxData = await fetchTaxes();
      if (packageRef?.current?.CouponDescription) {
        await handlePromotionCoupon(packageRef?.current?.CouponDescription, taxData);
      } else {
        await fetchPackageData(taxData);
      }
      if (staffPage) {
        await fetchCoupons(primaryMemberId, "package", packageRef?.current?.PackageId);
      }
      await fetchPackageById(packageRef?.current);
    }
    setLoading(false);
  };

  //recurrences calculations
  const CalculateRecurrence = (check: boolean) => {
    const data: any = [];
    // if (selectedPaymentProfile?.selectedPayment?.CardTypeId === -1) {
    //   setTableData(data);
    //   return;
    // }
    if (check) {
      if (
        packageCostAfterDiscount > 0 &&
        downpaymentForm.paidToday >= 0 &&
        downpaymentForm.recurrenceUnit != undefined &&
        downpaymentForm.installments > 0 &&
        downpaymentForm.recurringStartDate != undefined
      ) {
        if (packageCostAfterDiscount >= downpaymentForm.paidToday) {
          let BalanceAmount =
            packageCostAfterDiscount - downpaymentForm.paidToday;
          let NoOfInstallments =
            downpaymentForm.installments > 999
              ? 999
              : downpaymentForm.installments;

          // let totalPackageAdditionalFees = 0;

          // if (packageRef?.current?.PackageFeeMappings?.length > 0) {
          //   const PackageAdditionalFees = packageRef?.current?.PackageFeeMappings
          //     .filter((fee: any) => fee.CanFeesRemoved === false)
          //     .map((fee: any) => ({
          //       ...fee,
          //       RevisedAmount: fee.RevisedAmount ? fee.RevisedAmount : 0
          //     }));

          //   totalPackageAdditionalFees = PackageAdditionalFees.reduce((sum: number, fee: any) => sum + fee.RevisedAmount, 0);
          //   BalanceAmount = BalanceAmount + totalPackageAdditionalFees;
          // }
          if (BalanceAmount > 0) {
            let InstallmentAmount = BalanceAmount / NoOfInstallments;
            InstallmentAmount = parseFloat(InstallmentAmount.toFixed(2));
            setRecurringAmount(InstallmentAmount);
            let unit = downpaymentForm.recurrenceUnit.text;
            let InstallmentDate = downpaymentForm.recurringStartDate; //.add(that.Order.Service.OcurrsEvery, unit);
            let totalAmt = 0;
            for (let i = 0; i < NoOfInstallments; i++) {
              totalAmt = totalAmt + InstallmentAmount;
              if (BalanceAmount !== totalAmt && i === NoOfInstallments - 1) {
                InstallmentAmount =
                  InstallmentAmount + (BalanceAmount - totalAmt);
              }
              data.push({
                Date: InstallmentDate,
                DisplayDate: getLongDate(InstallmentDate),
                InstallmentAmount: InstallmentAmount,
              });
              InstallmentDate = addDates(
                InstallmentDate,
                downpaymentForm.OcurrsEvery,
                unit
              );
            }
          }
        }
      }
    }
    setTableData(data);
  };

  async function fetchPackages() {
    const pageSize = 0;
    let req: any = {
      SortDirection: "ascending",
      SortBy: "Name",
      PageSize: 32657,
      PageNumber: 0,
    };
    const service = new PackageService();

    const newObj: any = new Object();

    if (staffPage) {
      newObj.CanClientBookOnline = false;
    } else {
      newObj.CanClientBookOnline = true;
    }

    const reqObj = { ...req, ...newObj };

    const result = await service.getAllPackages(reqObj);

    if (result?.ResponseCode === 100) {
      setPackageList(result?.Items);
    }
  }

  //to get the package Coupons
  async function fetchCoupons(userMemberId: any, type: string, EntityId: any) {
    const service = new TenantService();
    let req;
    if (type === "package") {
      req = {
        UserMemberId: userMemberId,
        EntityType: 7,
        EntityId: EntityId,
        CouponType: 1,
      };
    } else {
      req = {
        UserMemberId: userMemberId,
        EntityId: EntityId ? EntityId : 0,
        EntityType: 6,
        CouponType: 1,
      };
    }
    const result = await service.AvailableCouponsToApply(req);
    if (result) {
      setCouponData(result);
      setfilterCouponData(result);
    }
  }

  //FetchPackageData
  const fetchPackageData = async (
    taxData = Taxes,
    dataItem = packag,
    isNewCall = true,
    coupons = appliedCouponsref?.current
  ) => {
    let pros = 0;
    let ProratedSchedules = dataItem?.ProratedSchedules || dataItem?.Schedules;
    const currentDate = new Date(dataItem?.StartDate);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentMonthLastDate = new Date(
      currentYear,
      currentMonth,
      0
    ).getDate();
    let packCost = dataItem?.Cost;
    if (staffPage) {
      packCost = dataItem?.DiscountedCost;
    }
    let proratedLimitedZeroDollar = false;
    let canProrateValue = dataItem?.CanProrate;
    if (currentDate.getDate() === dataItem.BillingDay || !dataItem.BillingDay) {
      pros = 0;
      canProrateValue = false;
    } else if (dataItem?.BillingType === 3) {
      if (dataItem?.SessionType === 2) {
        if (
          dataItem?.Frequency === PackageFrequencyEnum.MONTHLY &&
          dataItem?.RepeatFrequency === 1
        ) {
          let package_per_day_cost = 0;
          let daysLeftInMonth = 0;
          if (dataItem.BillingDay > currentDate.getDate()) {
            const totalDaysInMonth = new Date(
              currentYear,
              currentMonth - 1,
              0
            ).getDate();
            daysLeftInMonth = dataItem.BillingDay - currentDate.getDate();
            package_per_day_cost = packCost / totalDaysInMonth;
          } else {
            const totalDaysInMonth = new Date(
              currentYear,
              currentMonth,
              0
            ).getDate();
            daysLeftInMonth =
              currentMonthLastDate -
              currentDate.getDate() +
              1 +
              dataItem.BillingDay -
              1;
            package_per_day_cost = packCost / totalDaysInMonth;
          }
          pros = daysLeftInMonth * package_per_day_cost;
        }
      } else {
        if (
          dataItem?.Frequency === PackageFrequencyEnum.MONTHLY &&
          dataItem?.RepeatFrequency === 1
        ) {
          let totalSchedules = dataItem?.Schedules;
          if (staffPage) {
            totalSchedules = dataItem?.OptedSchedules;
          }
          const singleApptCost = packCost / totalSchedules;
          pros = singleApptCost * ProratedSchedules;
          if (dataItem?.DiscountedCost === 0) {
            proratedLimitedZeroDollar = true;
          }
        }
      }
    }
    pros = CustomToFixed(pros, 2);
    if (pros > 0 || proratedLimitedZeroDollar) {
      canProrateValue = true;
    } else {
      canProrateValue = false;
    }
    if (!staffPage && !dataItem?.CanProrate) {
      canProrateValue = false;
    }

    if (dataItem?.CanCollectTaxes && checkHasTaxEnable() && isNewCall) {
      setIsTaxSkipped(false);
    }
    const today = moment(dataItem?.StartDate).format("L");
    const mainDate =
      dataItem?.BillingType === 3
        ? moment(dataItem?.StartDate).date(dataItem?.BillingDay).format("L")
        : today;
    let NextRecurringDate = null;
    if (dataItem?.Frequency !== PackageFrequencyEnum.ONETIME) {
      const item = PackageFrequency?.filter(
        (i: any) => i?.id === dataItem?.Frequency
      )?.[0];
      const repeatfreqValue = item?.shortValue;
      let nextMonth = calculatePackageRecurrenceDate(
        mainDate,
        dataItem?.RepeatFrequency,
        repeatfreqValue
      );
      if (moment(dataItem?.StartDate).date() < dataItem?.BillingDay) {
        nextMonth = mainDate;
      }
      NextRecurringDate = nextMonth;
    }

    const packageItemVal = {
      ...dataItem,
      ProratedCost: pros,
      IsProrated: canProrateValue,
      NextRecurringDate: NextRecurringDate,
      PayByRecurring:
        dataItem?.Frequency !== 1 && dataItem?.SessionType === 3 ? true : false,
    };

    if (packageItemVal?.SessionType === 3 && packageItemVal?.PayByRecurring) {
      handlePackageCoupon(coupons, packageItemVal, taxData, true);
      const today = moment(dataItem?.StartDate).format("L");

      const recurrenceUnitId = isNewCall
        ? packageItemVal?.Frequency
        : downpaymentFormRef?.current?.recurrenceUnit?.id;



      const recurringUnit = PackagePresetFrequency?.find(
        (i: any) => i?.id === recurrenceUnitId
      );

      const OcurrsEvery = isNewCall ? packageItemVal?.RepeatFrequency : downpaymentFormRef?.current?.OcurrsEvery;
      const nextMonth = calculatePackageRecurrenceDate(
        today,
        OcurrsEvery,
        recurringUnit?.shortValue
      );

      let paidTodayAmt;
      if (downpaymentForm.paidToday !== packageItemVal?.DownPayment && downpaymentForm.paidToday > 0 && !isNewCall) {
        paidTodayAmt = downpaymentForm.paidToday
      } else {
        paidTodayAmt = packageItemVal?.DownPayment;
      }


      if (isNewCall) {
        setDownpaymentForm({
          ...downpaymentFormRef?.current,
          paidToday: CustomToFixed(paidTodayAmt, 2),
          recurrenceUnit: recurringUnit,
          recurringStartDate: new Date(nextMonth),
          OcurrsEvery: packageItemVal?.RepeatFrequency,
          installments: packageItemVal?.Occurrence,
        });
      } else {
        setDownpaymentForm({
          ...downpaymentFormRef?.current,
          recurringStartDate: new Date(nextMonth),
        });
      }
    } else if (packageItemVal?.SessionType === 3) {
      if (isNewCall) {
        setDownpaymentForm({
          ...downpaymentFormRef?.current,
          paidToday: packageItemVal?.DownPayment,
        });
      }
      const packagecostAfterDiscount = CustomToFixed(packageItemVal?.Cost, 2);
      setPackageCostAfterDiscount(packagecostAfterDiscount);
      handlePackageCoupon(coupons, packageItemVal, taxData);
      const today = moment(new Date()).format("L");
      const recurrenceUnitId = isNewCall
        ? packageItemVal?.Frequency
        : downpaymentFormRef?.current?.recurrenceUnit?.id;

      const recurringUnit = PackagePresetFrequency?.find(
        (i: any) => i?.id === recurrenceUnitId
      );

      const OcurrsEvery = isNewCall ? packageItemVal?.RepeatFrequency : downpaymentFormRef?.current?.OcurrsEvery;
      const nextMonth = calculatePackageRecurrenceDate(
        today,
        OcurrsEvery,
        recurringUnit?.shortValue
      );

      let paidTodayAmt;
      if (dataItem?.Frequency === PackageFrequencyEnum.ONETIME) {
        paidTodayAmt = downpaymentFormRef?.current?.paidToday || dataItem?.DiscountedCost || dataItem?.DownPayment;
      } else {
        paidTodayAmt = packageItemVal?.DownPayment;
      }

      if (isNewCall) {
        setDownpaymentForm({
          ...downpaymentFormRef?.current,
          paidToday: CustomToFixed(paidTodayAmt, 2),
          recurrenceUnit: recurringUnit,
          recurringStartDate: new Date(nextMonth),
          OcurrsEvery: packageItemVal?.RepeatFrequency,
          installments: packageItemVal?.Occurrence,
        });
      } else {
        setDownpaymentForm({
          ...downpaymentFormRef?.current,
          recurringStartDate: new Date(nextMonth),
        });
      }
    }
    else {
      const packagecostAfterDiscount = CustomToFixed(packageItemVal?.Cost, 2);
      setPackageCostAfterDiscount(packagecostAfterDiscount);
      handlePackageCoupon(coupons, packageItemVal, taxData);



      const today = moment(new Date()).format("L");
      const recurringUnit = PackageFrequency?.find(
        (i: any) => i?.id === packageItemVal?.Frequency
      );
      const nextMonth = calculatePackageRecurrenceDate(
        today,
        packageItem?.RepeatFrequency,
        recurringUnit?.shortValue
      );
        
      setDownpaymentForm({
        ...downpaymentFormRef?.current,
        paidToday: downpaymentFormRef?.current?.paidToday,
        recurrenceUnit: recurringUnit,
        recurringStartDate: new Date(nextMonth),
        OcurrsEvery: packageItemVal?.RepeatFrequency,
        installments: packageItemVal?.Occurrence,
      });
    }

    setPackage(packageItemVal);
    // CalculatePackageRecurrence(packageItemVal)
  };


  const handlePromotionCoupon = async (promoCoupon = coupon, taxes = Taxes) => {
    if (promoCoupon !== "" && promoCoupon !== undefined) {
      setCouponBtnLoading(true);
      const couponService = new TenantService();

      let req = {
        UserMemberId: userMemberId,
        EntityId: packageRef?.current?.PackageId,
        EntityType: 7,
        CouponName: promoCoupon,
        CouponType: 2,
      };

      const result = await couponService.findCoupon(req);
      setCouponBtnLoading(false);
      if (result?.ResponseCode === 100) {
        if (result.Items.length === 0) {
        } else {
          const res = result.Items[0];
          const data = {
            Amount: res?.Amount,
            CouponId: res?.CouponId,
            CouponInformation: res?.CouponInformation,
            Description: res?.CouponInformation,
            Type: res?.Type,
            TypeDescription: res?.TypeDescription,
            AvailableCount: res?.AvailableCount,
            CouponAppliedCount: res?.CouponAppliedCount,
            DurationType: res?.DurationType,
          };
          const appCoupon = [...appliedCoupons, data];
          setAppliedCoupons(appCoupon);
          setCoupon("");
          setvisibleCouponField(false);

          await fetchPackageData(taxes, undefined, undefined, appCoupon);
        }
      }
    }
  };

  const handlePackageCoupon = (
    value = appliedCouponsref?.current,
    dataItem = packageRef?.current,
    taxData = Taxes,
    isNewCall = false
  ) => {
    let final_coup_data: any[] = [];
    const couponsList = value;
    const couponFixed = couponsList.filter(
      (res: any) => res?.TypeDescription === "Fixed"
    );
    if (couponFixed.length > 0) {
      final_coup_data.push(couponFixed[couponFixed.length - 1]);
    }
    const couponPercentage = couponsList.filter(
      (res: any) => res?.TypeDescription === "Percentage"
    );
    if (couponPercentage.length > 0) {
      final_coup_data.push(couponPercentage[couponPercentage.length - 1]);
    }

    let totalCost = dataItem?.Cost;

    let acceptLimitedCoupon = true;
    if (staffPage) {
      totalCost = dataItem?.DiscountedCost;
    }
    if (dataItem?.IsProrated) {
      totalCost = dataItem?.ProratedCost;
      acceptLimitedCoupon = false;
    }

    let totalPackageAdditionalFees = 0;

    if (packageRef?.current?.PackageFeeMappings?.length > 0) {
      const PackageAdditionalFees = packageRef?.current?.PackageFeeMappings
        .filter((fee: any) => fee.CanFeesRemoved === false)
        .map((fee: any) => ({
          ...fee,
          RevisedAmount: fee.RevisedAmount ? fee.RevisedAmount : 0
        }));

      totalPackageAdditionalFees = PackageAdditionalFees.reduce((sum: number, fee: any) => sum + fee.RevisedAmount, 0);
      totalCost = totalCost + totalPackageAdditionalFees;
    }
    let offerAmt = 0;
    let cost = totalCost;
    let downpayment = isNewCall ? dataItem?.DownPayment : totalCost;
    if (packageRef?.current?.SessionType === SessionTypeEnum.PRESET) {
      if (final_coup_data.length > 0 && appliedCoupons.length > 0 && !isNewCall) {
        const removedCoupon = value.filter((coupon: any) => appliedCoupons.includes(coupon));
        handleCalculateDownPayment(removedCoupon)
      }//while changing the Count and Rate
      else if (packageRef?.current?.IndividualSessionRate !== packag?.IndividualSessionRate || packageRef?.current?.Schedules !== packag?.Schedules) {
        handleCalculateDownPayment(appliedCoupons)
      }
      downpayment = downpaymentFormRef?.current?.paidToday + totalPackageAdditionalFees;
    } else {
      downpayment = totalCost;
    }
    if (final_coup_data.length > 0) {
      final_coup_data = final_coup_data.map((item: any) => {
        if (item.TypeDescription === "Fixed") {
          let coupAmt = item.Amount;
          if (
            item?.DurationType === CouponDurationTypeEnum.LIMITED &&
            !acceptLimitedCoupon
          ) {
            coupAmt = 0;
          }
          cost = cost <= coupAmt ? 0 : cost - coupAmt;
          offerAmt += coupAmt;
          return { ...item, couponAmt: coupAmt };
        } else if (item.TypeDescription === "Percentage") {
          const coupvalue = cost * (item.Amount / 100);
          let coupAmt = parseFloat(coupvalue.toFixed(2));
          if (
            item?.DurationType === CouponDurationTypeEnum.LIMITED &&
            !acceptLimitedCoupon
          ) {
            coupAmt = 0;
          }
          if (item?.DurationType === CouponDurationTypeEnum.LIMITED &&
            !acceptLimitedCoupon && item.Amount === 100) {
            cost = cost <= coupAmt ? 0 : cost - coupAmt;
          } else {
            cost = item.Amount === 100 ? 0 : cost - coupAmt;
          }
          //cost = item.Amount === 100 ? 0 : cost - coupAmt;
          offerAmt += coupAmt;
          return { ...item, couponAmt: coupAmt };
        }
      });
      downpayment = downpayment <= offerAmt ? 0 : downpayment - offerAmt;
    }
    downpayment = downpayment <= totalPackageAdditionalFees ? 0 : downpayment - totalPackageAdditionalFees;
    cost = cost - totalPackageAdditionalFees;
    const packagecostAfterDiscount = CustomToFixed(cost, 2);
    setAppliedCoupons(final_coup_data);
    setCouponAmount(offerAmt);
    setPackageCostAfterDiscount(packagecostAfterDiscount);
    if (dataItem?.ClientCanSkipPayment) {
      setDownpaymentForm({
        ...downpaymentFormRef?.current,
        paidToday: 0,
      });
      handleTaxCalculation(packagecostAfterDiscount, taxData, true);
      handleTaxCalculation(0, taxData);
    } else {
      setDownpaymentForm({
        ...downpaymentFormRef?.current,
        paidToday: CustomToFixed(downpayment, 2),
      });
      handleTaxCalculation(downpayment, taxData);
      handleTaxCalculation(packagecostAfterDiscount, taxData, true);
    }

    if (value.length < appliedCoupons.length && packageRef?.current?.SessionType === SessionTypeEnum.PRESET && !isNewCall) {
      const removedCoupon = appliedCoupons.filter((coupon: any) => !value.includes(coupon));
      handleCalculateDownPayment(removedCoupon);
    }
    setCouponDetails({
      AppliedCoupons: final_coup_data,
    });
  };

  const handleTaxCalculation = (
    purchaseAmt: number,
    TaxItems = Taxes,
    Prediction = false
  ) => {
    let totalPackageAdditionalFees = 0;

    if (packageRef?.current?.PackageFeeMappings?.length > 0) {
      const PackageAdditionalFees = packageRef?.current?.PackageFeeMappings
        .filter((fee: any) => fee.CanFeesRemoved === false)
        .map((fee: any) => ({
          ...fee,
          RevisedAmount: fee.RevisedAmount ? fee.RevisedAmount : 0
        }));

      totalPackageAdditionalFees = PackageAdditionalFees.reduce((sum: number, fee: any) => sum + fee.RevisedAmount, 0);
      purchaseAmt = purchaseAmt + totalPackageAdditionalFees;
    }
    const TaxesArray = TaxItems.map((i: any) => {
      const amount = (purchaseAmt * i?.Percentage) / 100;
      const calcAmt = CustomToFixed(amount, 2);
      return {
        TaxId: i?.TaxId,
        TaxName: i?.TaxName,
        Percentage: i?.Percentage,
        Amount: calcAmt,
      };
    });
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
      return acc + currentValue?.Amount;
    }, 0);
    if (Prediction) {
      setPredictedTaxes(TaxesArray);
      setPredictedTotalTaxAmount(taxAmt);
    } else {
      setTaxes(TaxesArray);
      setTotalTaxAmount(taxAmt);
    }
  };

  const fetchTaxes = async () => {
    const req = {};
    const taxService = new ManageTaxesService();
    const res = await taxService.getTaxes(req);
    setTaxes(res);
    return res;
  };

  const fetchSalesPerson = async () => {
    const service = new TenantService();
    const includeAffiliates = staffPage ? false : true;
    const res = await service.SalesPerson(includeAffiliates);
    setSalesPersons(res || []);
    if (userSessionUserId) {
      const salesPersonRecord = res?.find(
        (item: any) => item?.UserId === userSessionUserId
      );
      if (salesPersonRecord) {
        setSelectedSalesPerson(salesPersonRecord);
      }
    }
  };

  const getAllSubMembers = async (memberId: any) => {
    let selectedUserMemberId = memberId;
    let parentMemberId = memberId;
    if (props?.location?.state?.userData?.PrimaryMemberId) {
      parentMemberId = props?.location?.state?.userData?.PrimaryMemberId;
    } else if (props?.userDatafromAppointment?.PrimaryMemberId) {
      parentMemberId = props?.userDatafromAppointment?.PrimaryMemberId;
    } else if (props?.location?.state?.userData?.MainUserId) {
      parentMemberId = props?.location?.state?.userData?.MainUserId;
    }
    const res = await tenantService.GetSubMembersWithParent(parentMemberId);
    setAllClientsList(res);
    if (!staffPage && !props?.location?.state?.userData) {
      selectedUserMemberId = parseInt(
        userDetails?.UserSession?.AdditionalAttributes?.LogedInMemberId ||
        memberId
      );
    }
    const selUserData = getCurrentClientData(res, selectedUserMemberId);
    if (!appointmentpage) {
      updateSelectedClientData(selUserData);
    }

    return selUserData?.ParentId || selUserData?.UserMemberId;
  };

  const getCurrentClientData = (allUsersData: any, userMemberId: any) => {
    return (
      allUsersData?.find((val: any) => val.UserMemberId === userMemberId) || {}
    );
  };

  const handlefetchCards = (result: any) => {
    setPaymentProfile(result);
  };

  // to get client details from api
  async function updateSelectedClientData(userData: any) {
    const result = userData;
    if (result) {
      let clientIntialValues = {
        FirstName: result?.FirstName,
        LastName: result?.LastName,
        PhoneNumber: result?.Phone,
        Email: result?.Email,
        UserMemberId: result?.UserMemberId,
      };
      let clientSubmitDetails = {
        Email: result?.Email,
        FirstName: result?.FirstName,
        LastName: result?.LastName,
        UserId: result?.UserId,
        UserMemberId: result?.UserMemberId,
        MemberNumber: result?.MemberNumber,
        Status: result?.UserMemberStatus || result?.Status,
        PrimaryMemberId:
          result?.ParentId !== undefined && result?.ParentId !== null
            ? result?.ParentId
            : result?.UserMemberId,
      };

      let PrimeId =
        result?.ParentId != undefined ? result?.ParentId : result?.UserMemberId;

      if (appointmentpage) {
        clientSubmitDetails.PrimaryMemberId =
          result?.PrimaryMemberId != undefined
            ? result?.PrimaryMemberId
            : result?.UserMemberId;
        //clientSubmitDetails.PrimaryMemberId = result?.UserMemberId;
      }
      //    else {
      //     clientSubmitDetails.PrimaryMemberId = (result?.ParentId !== undefined && result?.ParentId !== null) ? result?.ParentId : result?.UserMemberId;

      //   }

      if (clientSubmitDetails?.PrimaryMemberId != undefined) {
        await fetchPaymentProfiles(clientSubmitDetails?.PrimaryMemberId);
      }

      setCardFormValues({
        ...cardFormValues,
        UserMemberId: clientSubmitDetails?.PrimaryMemberId,
      });
      setBankFormValues({
        ...bankFormValues,
        UserMemberId: clientSubmitDetails?.PrimaryMemberId,
      });
      setUserMemberId(result?.UserMemberId);
      setClientFormValues(clientIntialValues);
      setClientSubmitDetails(clientSubmitDetails);
    }
  }

  const makePaymentProfileAsDefault = async (dataItem: any) => {
    const req = {
      PaymentGatewayPaymentProfileID: dataItem?.PaymentGatewayPaymentProfileID,
      UserMemberId: userMemberId,
      CustomerProfileID:
        dataItem?.CustomerProfileID,
    };
    await axiosRequest.post(
      `PaymentProfile/update-preferred-payment-profile`,
      req,
      {
        successCallBack: async (response: any) => {
          props?.handleNotificationMessage(
            `Selected Payment profile marked as Preferred`,
            "success"
          );
          await fetchPaymentProfiles(userMemberId);
        },
        errorCallBack: (response: any) => {
          props?.handleNotificationMessage(
            response?.response?.data?.Messages?.[0] ||
              response?.data?.Messages?.[0] ||
              "Internal server error",
            "error"
          );
        },
      }
    );
  };

  //to get the payment profiles
  async function fetchPaymentProfiles(
    userMemberId: any,
    clearAdditionPP = false,
    modeVal=""
  ) {
    const paymentProfileData = new TenantService();
    const result = await paymentProfileData.paymentProfile(userMemberId);
    if (result) {
      if(modeVal){
        const record=result?.find((i:any)=> i?.CardTypeId ===-1)
        if(record){
          const data={
            ...record,
            CardTypeId: -2,
            MaskedCCNumber: modeVal,
            CardDescription: modeVal,
            CardDescriptionWithoutExpiryDate: modeVal,
          };
          const res=[...result]
          res.push(data)
          setPaymentProfile(JSON.parse(JSON.stringify(res)))
          handleChangeSavedCard(data);
        }
      }else {
        setPaymentProfile(result);
        let selectedCard;
        const defaultPP = result.find((card: any) => card.IsDefault);
        const cardType1 = result.find((card: any) => card.CardTypeId >= 1 && card.CardTypeId < 8);
        const cardType2 = result.find((card: any) => card.CardTypeId === 0);
        const cardType3 = result.find((card: any) => card.CardTypeId === 8 && card.Credit > 0);
        if(!defaultPP){
          if (cardType1) {
            selectedCard = cardType1;
          } else if (cardType2) {
            selectedCard = cardType2;
          } else if (cardType3) {
            selectedCard = cardType3;
          } else if (packageCostAfterDiscount !== 0) {
            selectedCard = result.find((card: any) => card.CardTypeId === -1);
          }
        }else{
          selectedCard=defaultPP
        }
        
      if (selectedCard) {
        handleChangeSavedCard(selectedCard);
      }
      }
    }
    if (clearAdditionPP) {
      return result;
    }
  }
  const BackButtonComponent = (props: any) => {
    return (
      <button
        type="button"
        className="btn btn-primary buz-back-btn btn-sm mb-0"
        onClick={() => props?.onClick()}
      >
        <i className="fas fa-arrow-left"></i>
      </button>
    );
  };

  const handleNotNowRedirection = () => {
    if (guestLayout) {
      if (redirectionObj?.firstPathValue === "external") {
        history.push(`/${redirectionObj?.firstPathValue}/${providerId}`);
      } else if (redirectionObj?.secondRoute !== "") {
        history.push(
          `/${redirectionObj?.firstPathValue}/${redirectionObj?.secondRoute}`
        );
      } else {
        history.push(`/${providerId}`);
      }
    } else {
      if (!staffPage) {
        handleSummaryRedirection({ purchasepackage: true });
      } else {
        const urlRedirection = `/member/${props?.location?.state?.uuid}/${props?.location?.state?.userData?.UserMemberId}`;
        history.push(urlRedirection, { purchasepackage: true });
      }
    }
  };

  // to handle panel bar expand and collpase
  const onSelect = (e: any) => {
    setSelected(e?.target?.props?.id);
  };

  // to handle credit card image icon
  const handleCardImageIcon = (imageUrl: any) => {
    setcardImageIcon(imageUrl);
  };

  // to select the payment profile
  const handleChangeSavedCard = (val: any,dataItem:any=null) => {
    dispatch(PaymentMethodSelected(val?.CardTypeId));
    setIsFormChanged(true);
    setSelectedPaymentProfile({
      ...selectedPaymentProfile,
      selectedPayment: val,
      AlternatePaymentProfileId: null,
    });
    setCardFormValues(cardIntialValues);
    setBankFormValues(bankIntialValues);
    generatePaymentProfileObj(val,dataItem);
    setPaymentMethod(defaultPaymentMethod);
    // if (selected === "0") {
    //   setSelected(undefined);
    // } else {
    //   setSelected("3");
    // }
  };

  //payment profile object to send while build agreement and purchase
  const generatePaymentProfileObj = (val: any,dataItem:any=null) => {
    let paymentDetails:any = {
      CustomerProfileID: val?.CustomerProfileID,
      PaymentProfileID: val?.PaymentProfileID,
      PaymentGatewayPaymentProfileID: val?.PaymentGatewayPaymentProfileID,
      MaskedCCNumber: val?.MaskedCCNumber,
      CardTypeId: val?.CardTypeId,
      CardDescription: val?.CardDescription,
      BankAccountType: val?.BankAccountType,
      Credit: val?.Credit,
    };
    if(dataItem){
      paymentDetails={
        ...paymentDetails,Reference:dataItem?.Reference,
        ReferrenceDocument:dataItem?.ReferrenceDocument
      }
    }
    setPaymentSubmitProfile(paymentDetails);
    setPopulateProfile(null);
  };

  // handle switching between saved payment  & card & bank forms
  const handleForm = (form: string) => {
    setcardImageIcon(images.placeholder);
    setSelectedPaymentProfile({
      ...selectedPaymentProfile,
      selectedPayment: undefined,
      AlternatePaymentProfileId: null,
    });
    setPaymentSubmitProfile({});
    setPaymentMethod({ ...defaultPaymentMethod, [form]: true });
  };

  // handle payment profile submit
  const handleSubmit = (dataItem: any = null) => {
    if (selected === "0") {
      setSelected(undefined);
    } else {
      setSelected("3");
    }
  };

  const filterData = (filter: any, itemData: any) => {
    const data = itemData.slice();
    return filterBy(data, filter);
  };

  const filterCouponChange = (e: any) => {
    const filteredData = filterData(e.filter, coupondata);
    setfilterCouponData(filteredData);
  };

  const handleIsTaxSkipped = () => {
    setIsTaxSkipped(!IsTaxSkipped);
  };

  //to handle coupon validation and api call to filter coupon
  const handleCoupon = async () => {
    if (coupon !== "" && coupon !== undefined) {
      if (appliedCoupons.length >= 1) {
        const check = appliedCoupons.filter(
          (item: any) =>
            item.CouponInformation.toLowerCase() === coupon.toLowerCase()
        );
        if (check.length > 0) {
          const errorMsg = "Coupon already added";
          props?.handleNotificationMessage(errorMsg, "error");
          return;
        }
      }
      setCouponBtnLoading(true);
      const couponService = new TenantService();

      let req = {
        UserMemberId: userMemberId,
        EntityId: packageRef?.current?.PackageId,
        EntityType: 7,
        CouponName: coupon,
      };

      const result = await couponService.findCoupon(req);
      setCouponBtnLoading(false);
      if (result?.ResponseCode === 100) {
        if (result.Items.length === 0) {
          const errorMsg = "Please enter valid coupon";
          props?.handleNotificationMessage(errorMsg, "error");
        } else {
          const res = result.Items[0];
          const data = {
            Amount: res?.Amount,
            CouponId: res?.CouponId,
            CouponInformation: res?.CouponInformation,
            Description: res?.CouponInformation,
            Type: res?.Type,
            TypeDescription: res?.TypeDescription,
            AvailableCount: res?.AvailableCount,
            CouponAppliedCount: res?.CouponAppliedCount,
            DurationType: res?.DurationType,
          };
          const appCoupon = [...appliedCoupons, data];

          setAppliedCoupons(appCoupon);
          setCoupon("");
          setvisibleCouponField(false);

          handlePackageCoupon(appCoupon);

          const successMsg = "Coupon Applied Successfully";
          props?.handleNotificationMessage(successMsg, "success");
        }
      } else {
        const errorMsg = result?.ErrorMessages?.[0];
        props?.handleNotificationMessage(errorMsg, "error");
      }
    } else {
      const errorMsg = "Please enter Coupon";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleSalesPersonSelection = (dataItem: any) => {
    setSelectedSalesPerson(dataItem);
  };

  const handlePackageReccurence = async (name: string, value: any, item: any) => {
    console.log(name, value, 'handlePackageReccurence')
    let packageItemVal = { ...packageRef?.current };
    if (name === "CanFeesRemoved" && value === true) {
      const index = packageItemVal.PackageFeeMappings.findIndex((fee: any) => fee.FeeId === item.FeeId);

      if (index !== -1) {
        packageItemVal.PackageFeeMappings[index] = {
          ...packageItemVal.PackageFeeMappings[index],
          [name]: value,
          RevisedAmount: packageItemVal.PackageFeeMappings[index].Amount
        };
      }
    } else if (name === "RevisedAmount" || name === "CanFeesRemoved") {

      const index = packageItemVal.PackageFeeMappings.findIndex((fee: any) => fee.FeeId === item.FeeId);

      if (index !== -1) {
        packageItemVal.PackageFeeMappings[index] = {
          ...packageItemVal.PackageFeeMappings[index],
          [name]: value
        };
      }
    }
    else if (name === "StartDate") {
      const today1 = new Date();
      const sixMonths = new Date(today1);
      sixMonths.setMonth(sixMonths.getMonth() + 6);
      if (
        moment(value).format("YYYY-MM-DD") <=
        moment(sixMonths).format("YYYY-MM-DD")){

          packageItemVal = {
            ...packageRef?.current,
            [name]: value,
            BillingType: 2,
            ClientCanSkipPayment: moment(value).format("YYYY-MM-DD") <= moment(new Date()).format("YYYY-MM-DD") ? false : packageRef?.current?.ClientCanSkipPayment,
            BillingDay: moment(value).date(),
          };

      }

      // packageItemVal = {
      //   ...packageRef?.current,
      //   [name]: value,
      //   SignupDate:PurchaseDate,
      //   BillingType: 2,
      //   ClientCanSkipPayment: false,
      //   BillingDay: moment(value).date(),
      // };
      // if (
      //   packageRef?.current?.Frequency === PackageFrequencyEnum.ONETIME &&
      //   packageRef?.current?.ExpireOn === 8
      // ) {
      //   if (
      //     moment(value).format("YYYY-MM-DD") <
      //     moment(packageRef?.current?.ExpiryDate).format("YYYY-MM-DD") &&
      //     moment(value).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")
      //   ) {
      //     packageItemVal = {
      //       ...packageRef?.current,
      //       [name]: value,
      //       SignupDate:PurchaseDate,
      //       BillingType: 2,
      //       BillingDay: moment(value).date(),

      //     };
      //   }
      // } else if (
      //   moment(value).format("YYYY-MM-DD") <=
      //   moment(sixMonths).format("YYYY-MM-DD") &&
      //   moment(value).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")
      // ) {
      //   if (
      //     moment(value).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
      //   ) {
      //     packageItemVal = {
      //       ...packageRef?.current,
      //       [name]: value,
      //       ClientCanSkipPayment: false,
      //       SignupDate:PurchaseDate,
      //       BillingType: 2,
      //     BillingDay: moment(value).date(),
      //     };
      //   } else {
      //     packageItemVal = {
      //       ...packageRef?.current,
      //       [name]: value,
      //       SignupDate:PurchaseDate,
      //       BillingType: 2,
      //       BillingDay: moment(value).date(),
      //     };
      //   }
      // }else{
      //   packageItemVal = {
      //     ...packageRef?.current,
      //     [name]: value,
      //     SignupDate:PurchaseDate,
      //     BillingType: 2,
      //     ClientCanSkipPayment: false,
      //     BillingDay: moment(value).date(),
      //   };
      // }
    } else if (name === "SignupDate") {
      packageItemVal = {
        ...packageRef?.current,
        [name]: value,
        StartDate: value,
      };
    } else if (name === "Frequency") {
      if (value === PackageFrequencyEnum.MONTHLY) {
        packageItemVal = {
          ...packageRef?.current,
          [name]: value,
          StartDate:new Date(),
          RepeatFrequency: !packageRef?.current?.RepeatFrequency
            ? 1
            : packageRef?.current?.RepeatFrequency,
          FrequencyEndType: !packageRef?.current?.FrequencyEndType ? 0 : 1,
          Occurrence: !packageRef?.current?.Occurrence ? 2 : packageRef?.current?.Occurrence,
        };
      } else if (value === PackageFrequencyEnum.ONETIME) {
        packageItemVal = {
          ...packageRef?.current,
          [name]: value,
          StartDate:new Date(),
          Expire: packageItem?.ExpireOn === 8 ? 8 : 0,
          ExpireOn: packageItem?.ExpireOn,
          ExpiryDate:
            packageItem?.ExpireOn === 8 ? packageItem?.ExpiryDate : null,
          ExpireAfterDaysOnPurchase: packageItem?.ExpireAfterDaysOnPurchase,
          BillingType: 2,
          BillingDay: moment(packageRef?.current?.StartDate).date(),
        };
      } else {
        packageItemVal = {
          ...packageRef?.current,
          [name]: value,
          BillingType: 2,
          StartDate:new Date(),
          BillingDay: moment(packageRef?.current?.StartDate).date(),
          RepeatFrequency: !packageRef?.current?.RepeatFrequency
            ? 1
            : packageRef?.current?.RepeatFrequency,
          FrequencyEndType: !packageRef?.current?.FrequencyEndType ? 0 : 1,
          Occurrence: !packageRef?.current?.Occurrence ? 2 : packageRef?.current?.Occurrence,
        };
      }
    } else if (name === "RepeatFrequency") {
      if (value === 1) {
        packageItemVal = {
          ...packageRef?.current,
          [name]: value,
          StartDate:new Date(),
        };
      } else {
        packageItemVal = {
          ...packageRef?.current,
          [name]: value,
          BillingType: 2,
          BillingDay: moment(packageRef?.current?.StartDate).date(),
        };
      }
    } else if (name === "FrequencyEndType") {
      if (value === 1) {
        packageItemVal = {
          ...packageRef?.current,
          [name]: value,
          Occurrence: 2,
        };
      } else {
        packageItemVal = {
          ...packageRef?.current,
          [name]: value,
        };
      }
    } else if (name === "OptedSchedules") {
      let optedScheduleValue = value;
      if (value < 1) {
        optedScheduleValue = 1;
      }
      if (packageRef?.current?.ProratedSchedules > optedScheduleValue) {
        packageItemVal = {
          ...packageRef?.current,
          [name]: optedScheduleValue,
          ProratedSchedules: optedScheduleValue,
        };
      } else {
        packageItemVal = {
          ...packageRef?.current,
          [name]: optedScheduleValue,
        };
      }
      if(packageRef?.current?.OptedMaxRollOverSchedules > optedScheduleValue){
        packageItemVal = {
          ...packageItemVal,
          OptedMaxRollOverSchedules: optedScheduleValue,
        };
      }
    } else if (name === "Expire") {
      if (value === 0) {
        packageItemVal = {
          ...packageRef?.current,
          [name]: value,
          StartDate:new Date(),
          ExpireOn: packageItem?.ExpireOn === 8 ? 2 : packageItem?.ExpireOn,
          ExpireAfterDaysOnPurchase: packageItem?.ExpireAfterDaysOnPurchase,
        };
      } else {
        packageItemVal = {
          ...packageRef?.current,
          [name]: value,
          StartDate:new Date(),
          ExpireOn: 8,
          ExpiryDate: packageItem?.ExpiryDate || minimumDate,
        };
      }
    } else if (name === "IndividualSessionRate" && packageRef?.current?.SessionType === 3) {
      packageItemVal = {
        ...packageRef?.current,
        [name]: value,
        Cost: value * packageRef?.current?.Schedules,
        DiscountedCost: value * packageRef?.current?.Schedules,
      };
    } else if (name === "Schedules" && packageRef?.current?.SessionType === 3) {
      const req = {
        ServiceTypeId: 2,
        NoOfServices: value,
        IsActive: true,
      };
      const service = new TenantService();
      setCustomLoading(true);
      const result = await service?.GetSessionCost(req);
      setCustomLoading(false);
      if (result?.ResponseCode === 100) {
        const dataItem = result?.Item;
        const rateOfAppt = dataItem?.Rate ? dataItem?.Rate / value : 0;
        packageItemVal = {
          ...packageRef?.current,
          [name]: value,
          Cost: value * rateOfAppt,
          DiscountedCost: value * rateOfAppt,
          IndividualSessionRate: rateOfAppt
        };
        if (!dataItem?.Rate) {
          packageItemVal = {
            ...packageItemVal,
            DownPayment: rateOfAppt,
            IndividualSessionRate: null
          };
          const errorMsg = <span>The rate per session is not available for the specified number of sessions. Please configure it in the <a className="btn btn-link " style={{ textDecoration: 'underline' }} onClick={() => {
            handleAppointmentRates();
            props?.handleNotification("info");
          }}>Appointment Settings</a></span>
          props?.handleNotificationMessage(errorMsg, "info");
        }
      }
    } else {
      packageItemVal = {
        ...packageRef?.current,
        [name]: value,
      };
    }
    if (name === "RenewalType") {
      setPackage(packageItemVal);
      return;
    } else {
      setPackage(packageItemVal)
    }
    if ((packageItemVal?.Schedules * packageItemVal?.IndividualSessionRate) < downpaymentFormRef?.current?.paidToday && packageItemVal?.PayByRecurring && packageItemVal?.SessionType === SessionTypeEnum.PRESET) {
      setDownpaymentForm({
        ...downpaymentFormRef?.current,
        paidToday: packageItemVal?.Schedules * packageItemVal?.IndividualSessionRate,
      });
    } else if (!packageItemVal?.PayByRecurring && packageItemVal?.SessionType === SessionTypeEnum.PRESET) {
      setDownpaymentForm({
        ...downpaymentFormRef?.current,
        paidToday: packageItemVal?.Schedules * packageItemVal?.IndividualSessionRate,
      });
    }
    fetchPackageData(Taxes, packageItemVal, false);
    if (packageItemVal?.SessionType === SessionTypeEnum.PRESET && packageItemVal?.IndividualSessionRate === 0) {
      setDownpaymentForm({
        ...downpaymentFormRef?.current,
        paidToday: 0,
      });
    }
  };

  // to handle Apply Coupon text and button hide and show
  const handleVisibleCouponField = () => {
    setvisibleCouponField(!visibleCouponField);
  };

  // to save entered coupon to state
  const handleChangeCoupon = (val: any) => {
    setIsFormChanged(true);
    setCoupon(val);
  };

  //to remove the applied coupon
  const handleRemoveCoupon = (index: number) => {
    const array = [...appliedCoupons];
    let removedcoupon: any = [];
    if (index !== -1) {
      removedcoupon = array.splice(index, 1);
    }

    handlePackageCoupon(array, packageRef?.current, Taxes, true);
    handleCalculateDownPayment(removedcoupon);
    if (!staffPage) {
      setvisibleCouponField(true);
    }
  };


  const handleCalculateDownPayment = (value: any) => {
    let final_coup_data: any[] = [];
    const couponsList = value;
    const couponFixed = couponsList.filter(
      (res: any) => res?.TypeDescription === "Fixed"
    );
    if (couponFixed.length > 0) {
      final_coup_data.push(couponFixed[couponFixed.length - 1]);
    }
    const couponPercentage = couponsList.filter(
      (res: any) => res?.TypeDescription === "Percentage"
    );
    if (couponPercentage.length > 0) {
      final_coup_data.push(couponPercentage[couponPercentage.length - 1]);
    }
    let totalCost = packageRef?.current?.Cost;
    let acceptLimitedCoupon = true;
    if (staffPage) {
      totalCost = packageRef?.current?.DiscountedCost;
    }
    if (packageRef?.current?.IsProrated) {
      totalCost = packageRef?.current?.ProratedCost;
      acceptLimitedCoupon = false;
    }
    let offerAmt = 0;
    let cost = totalCost;
    let downpayment;
    if (packageRef?.current?.SessionType === SessionTypeEnum.PRESET) {
      downpayment = downpaymentFormRef?.current.paidToday;
    } else {
      downpayment = totalCost;
    }

    if (final_coup_data.length > 0) {
      final_coup_data = final_coup_data.map((item: any) => {
        if (item.TypeDescription === "Fixed") {
          let coupAmt = item.Amount;
          offerAmt += coupAmt;
          return { ...item, couponAmt: coupAmt };
        } else if (item.TypeDescription === "Percentage") {
          const coupvalue = cost * (item.Amount / 100);
          let coupAmt = parseFloat(coupvalue.toFixed(2));
          offerAmt += coupAmt;
          return { ...item, couponAmt: coupAmt };
        }
      });
      downpayment = downpayment + offerAmt;
    }

    if (downpayment > packageRef?.current.DiscountedCost) {
      downpayment = packageRef?.current.DiscountedCost;
    }

    if (packageRef?.current?.IsProrated && downpayment > packageRef?.current?.ProratedCost) {
      downpayment = packageRef?.current?.ProratedCost;
    }

    if (Number.isInteger(offerAmt)) {
      downpayment = Math.round(downpayment);
    }

    if (packageRef?.current?.Frequency === PackageFrequencyEnum.ONETIME) {
      downpayment = packageRef?.current.DiscountedCost || packageRef?.current?.DownPayment
    }

    setCouponAmount(offerAmt);
    if (packageRef?.current?.ClientCanSkipPayment) {
      setDownpaymentForm({
        ...downpaymentFormRef.current,
        paidToday: 0,
      });
      handleTaxCalculation(0, Taxes);
    } else {
      setDownpaymentForm({
        ...downpaymentFormRef.current,
        paidToday: downpayment,
      });
      handleTaxCalculation(downpayment, Taxes);
    }
  }

  // purchase and build agreement validation
  const checkoutPackageValidation = (checkSign = true, checkTerms = true) => {
    setSelected("0");
    const {
      paidToday,
      installments,
      OcurrsEvery,
      recurringStartDate,
      recurrenceUnit,
    } = downpaymentForm;
    if (Object.keys(clientSubmitDetails).length === 0) {
      setSelected("1");
      const errorMsg = "Please pick/create Client";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    if (
      packageRef?.current?.SessionType === 1 &&
      packageRef?.current?.BillingType === 3 &&
      packageRef?.current?.Frequency === PackageFrequencyEnum.MONTHLY &&
      packageRef?.current?.RepeatFrequency === 1 &&
      (packageRef?.current?.ProratedSchedules < 1 ||
        packageRef?.current?.ProratedSchedules > packageRef?.current?.OptedSchedules) &&
      moment(packageRef?.current?.StartDate).date() !== packageRef?.current?.BillingDay
    ) {
      const errorMsg = `Appointments should not exceed the available allocation, i.e ${packageRef?.current?.OptedSchedules}`;
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    if (
      staffPage &&
      packageRef?.current?.SessionType === 1 &&
      packageRef?.current?.Frequency !== PackageFrequencyEnum.ONETIME &&
      ((packageRef?.current?.OptedMaxRollOverSchedules < 0 ||
        packageRef?.current?.OptedMaxRollOverSchedules > packageRef?.current?.OptedSchedules) && packageRef?.current?.OptedMaxRollOverSchedules <= 100)
    ) {
      const errorMsg = `Max Rollover appointments should not exceed the available allocation, i.e ${packageRef?.current?.OptedSchedules}`;
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    if (
      staffPage &&
      packageRef?.current?.SessionType === 1 &&
      packageRef?.current?.Frequency !== PackageFrequencyEnum.ONETIME &&
      (packageRef?.current?.OptedMaxRollOverSchedules < 0 ||
        packageRef?.current?.OptedMaxRollOverSchedules > 100)
    ) {
      const errorMsg = `Max Rollover appointments should between 0 and 100`;
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    if (
      packageRef?.current?.DiscountedCost > 0 &&
      (Object.keys(paymentSubmitProfile).length === 0 || !paymentSubmitProfile?.PaymentGatewayPaymentProfileID)
    ) {
      setPaymentMethod(defaultPaymentMethod);
      setSelected("2");
      const errorMsg = "Please select/add Payment profile";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    } else {
     
    }
    if (
      (selectedPaymentProfile?.selectedPayment?.CardTypeId === -1 || selectedPaymentProfile?.selectedPayment?.CardTypeId === -2  || selectedPaymentProfile?.selectedPayment?.CardTypeId === 8) &&
      packageRef?.current?.ClientCanSkipPayment
    ) {
       let errormsg = staffPage ? "Can't use cash/wallet with pay later Option" : "Can't use wallet with pay later option";

      if(selectedPaymentProfile?.selectedPayment?.CardTypeId === -2 && selectedPaymentProfile?.selectedPayment?.MaskedCCNumber){
        errormsg = `Can't use ${selectedPaymentProfile?.selectedPayment?.MaskedCCNumber} payments with Pay Later Option`
      }
      props?.handleNotificationMessage(errormsg, "error");
      return true;
    }
    if (
      (selectedPaymentProfile?.selectedPayment?.CardTypeId === -1 || selectedPaymentProfile?.selectedPayment?.CardTypeId === -2 || selectedPaymentProfile?.selectedPayment?.CardTypeId === 8) &&
      packageRef?.current?.SessionType === SessionTypeEnum.PRESET &&
      showinstallmentForm
    ) {
      let errormsg  = staffPage ? "Can't use cash/wallet with recurring packages" : "Can't use wallet with recurring packages";
      if(selectedPaymentProfile?.selectedPayment?.CardTypeId === -2 && selectedPaymentProfile?.selectedPayment?.MaskedCCNumber){
        errormsg = `Can't use ${selectedPaymentProfile?.selectedPayment?.MaskedCCNumber} payments with Recurring packages`
      }
      props?.handleNotificationMessage(errormsg, "error");
      return true;
    }
    if (
      (selectedPaymentProfile?.selectedPayment?.CardTypeId === -1 || selectedPaymentProfile?.selectedPayment?.CardTypeId === -2 || selectedPaymentProfile?.selectedPayment?.CardTypeId === 8) &&
      packageRef?.current?.SessionType === SessionTypeEnum.PRESET &&
      packageRef?.current?.RenewalType === 2 && packageRef?.current?.IndividualSessionRate > 0
    ) {
      let errormsg = staffPage ? "Can't use cash/wallet for renewal packages" : "Can't use wallet for renewal packages";
      if(selectedPaymentProfile?.selectedPayment?.CardTypeId === -2 && selectedPaymentProfile?.selectedPayment?.MaskedCCNumber){
        errormsg = `Can't use ${selectedPaymentProfile?.selectedPayment?.MaskedCCNumber} payments for renewal packages`
      }
      props?.handleNotificationMessage(errormsg, "error");
      return true;
    }

    if (
      (selectedPaymentProfile?.selectedPayment?.CardTypeId === -1 || selectedPaymentProfile?.selectedPayment?.CardTypeId === 8) &&
      (packageRef?.current?.SessionType === 2 || packageRef?.current?.SessionType === 1) &&
      packageRef?.current?.Frequency !== PackageFrequencyEnum.ONETIME
    ) {
      const errormsg = staffPage ? "Can't use cash/wallet with recurring packages" : "Can't use wallet with recurring packages";
      props?.handleNotificationMessage(errormsg, "error");
      return true;
    }

    if (
      paymentSubmitProfile?.CardTypeId === 8 &&
      downpaymentForm.paidToday > paymentSubmitProfile?.Credit && 
      !selectedPaymentProfile.AlternatePaymentProfileId
    ) {
      let defaultPaymentProfile = null;
      const existedPP =
        paymentProfile?.filter(
          (i: any) => i?.CardTypeId !== -1 && i.CardTypeId !== 8
        );
      if (existedPP?.length > 0) {
        defaultPaymentProfile = existedPP[0];
      }
      setShowAdditionalPayment({
        dialog: true,
        selectedPaymentProfile: defaultPaymentProfile,
      });
      return true;
    }
    let packageCost = packageCostAfterDiscount;
    if (packageCostAfterDiscount < 0) {
      packageCost = 0;
    }
    if (packageRef?.current?.SessionType === 3 && packageRef?.current?.PayByRecurring && downpaymentForm?.paidToday > packageCost) {
      const errormsg = "Down payment should be less than or equal to the order total";
      props?.handleNotificationMessage(errormsg, "error");
      return true;
    }
    if (packageRef?.current?.SessionType === 3 && packageRef?.current?.IndividualSessionRate === null) {
      const errormsg = "Please Provide Rate per Session";
      props?.handleNotificationMessage(errormsg, "error");
      return true;
    }
    if (packageRef?.current?.SessionType === 3 && paidToday < 0) {
      setSelected("3");
      return true;
    }
    if (packageRef?.current?.SessionType === 3 && showinstallmentForm) {
      if (
        paidToday < 0 ||
        installments <= 0 ||
        OcurrsEvery <= 0 ||
        !recurringStartDate ||
        !recurrenceUnit
      ) {
        setSelected("3");
        return true;
      }
    }
    if (
      (selectedPaymentProfile?.selectedPayment?.CardTypeId === -1 || selectedPaymentProfile?.selectedPayment?.CardTypeId === -2) &&
      (packageRef?.current?.SessionType === 2 || packageRef?.current?.SessionType === 1) &&
      packageRef?.current?.Frequency !== PackageFrequencyEnum.ONETIME
    ) {
      let errormsg = "Can't use cash/wallet payments with Recurring packages";
      if(selectedPaymentProfile?.selectedPayment?.CardTypeId === -2 && selectedPaymentProfile?.selectedPayment?.MaskedCCNumber){
        errormsg = `Can't use ${selectedPaymentProfile?.selectedPayment?.MaskedCCNumber} payments with Recurring packages`
      }
      props?.handleNotificationMessage(errormsg, "error");
      return true;
    }
    if (
      (staffPage || packageRef?.current?.CanClientOverrideOccurence) &&
      (packageRef?.current?.SessionType === 2 || packageRef?.current?.SessionType === 1)
    ) {
      if (
        packageRef?.current?.Frequency !== PackageFrequencyEnum.ONETIME &&
        packageRef?.current?.RepeatFrequency < 1
      ) {
        setSelected("3");
        return true;
      }
      if (packageRef?.current?.FrequencyEndType === 1 && packageRef?.current?.Occurrence < 2) {
        setSelected("3");
        return true;
      }
      if (packageRef?.current?.Frequency === PackageFrequencyEnum.ONETIME) {
        if (packageRef?.current?.Expire === 0 && packageRef?.current?.ExpireAfterDaysOnPurchase <= 0) {
          setSelected("3");
          return true;
        } else if (packageRef?.current?.Expire === 8) {
          const ExpiryDate = moment(packageRef?.current?.ExpiryDate).format("YYYY-MM-DD");
          if (
            ExpiryDate === "Invalid date" ||
            moment(ExpiryDate).format("YYYY-MM-DD") <
            moment(minimumDate).format("YYYY-MM-DD")
          ) {
            setSelected("3");
            return true;
          }
        }
      }
    }
    if (checkoutType === "inhouse-package" || staffPage) {
      if (!selectedSalesPerson) {
        const errorMsg = "Please select Sales person";
        props?.handleNotificationMessage(errorMsg, "error");
        return true;
      }
    }
    if (checkSign && signature === "") {
      const errorMsg = "Please provide signature";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    if (checkTerms && !termsandConditions) {
      const errorMsg = "Please check the Terms & Conditions";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    return false;
  };

  //to saving signature
  const generatedImage = (
    imageString: string,
    text?: string,
    font?: string,
    Type?: string
  ) => {
    setIsFormChanged(true);
    setSignature(imageString);
    setSignatureText(text);
    setSignatureFont(font);
    setSignatureType(Type || "");
    setTermsandConditions(true);
    setTermsandConditionsDialog(false);
  };

  //handle signature pad popup
  const handleSignaturePad = () => {
    let check = false;

    check = checkoutPackageValidation(false, false);
    if (check) {
      return;
    }
    setVisibleSignPad(true);
  };

  const toggleClientConfirmDialog = () => {
    setConfirmClientDialog(!confirmClientDialog);
  };

  const renderClientConfirmationDialog = () => {
    const selectedClientInfo = AllClientsList?.filter(
      (res: any) => res?.UserMemberId === clientFormValues?.UserMemberId
    )[0];
    return (
      <Dialog
        className="bz-book-Appoinment-dialog"
        title={"Please Confirm"}
        onClose={() => toggleClientConfirmDialog()}
        width={"35%"}
      >
        <div>
          <p className={"dialogContainer"}>
            {`Looks like you have additional family members on account, please confirm you are making purchases for the below client.`}
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <ClientNameWithMemberType
              clientName={selectedClientInfo?.FullName}
              memberType={selectedClientInfo?.MemberType}
            />
          </div>
          <div className="column-button-section book-appt-btns">
            <Button primary={false} onClick={() => toggleClientConfirmDialog()}>
              No
            </Button>
            <BuzopsButton
              label={"Yes"}
              onClick={() =>
                handleCheckoutPage(
                  purchaseCheckout?.page,
                  purchaseCheckout?.params
                )
              }
            />
          </div>
        </div>
      </Dialog>
    );
  };

  const handleSummaryRedirection = (obj: any = {}) => {
    const { firstPathValue, staffuuid, clientMemberId } = redirectionObj;
    if (firstPathValue === "member") {
      history.push(`/${firstPathValue}/${staffuuid}/${clientMemberId}`, obj);
    } else if (firstPathValue === "external") {
      history.push(`/${firstPathValue}/summary`, obj);
    } else {
      history.push(`/summary`, obj);
    }
  };

  const toggleRedeemPackage = (packageDetails: any) => {
    const { firstPathValue } = redirectionObj;
    const user = clientSubmitDetails?.UserMemberId ? clientSubmitDetails : userDetails
    if (staffPage) {
      if (packageRef?.current?.Type === 2 || packageRef?.current?.Type === 4) {
        const urlRedirection = `/member/${props?.location?.state?.uuid}/${props?.location?.state?.userData?.UserMemberId}/scheduleappointment`;
        history.push(urlRedirection, {
          selectedPackageInstanceId: packageDetails?.PackageInstanceId,
          staffPage: true,
          userData: user,
          Complimentary: false,
          enrollPackage: true,
        });
      } else {
        const urlRedirection = `/member/${props?.location?.state?.uuid}/${props?.location?.state?.userData?.UserMemberId}`;
        history.push(urlRedirection, {
          redeemPackage: true,
          packageDetails: packageDetails,
        });
      }
    } else {
      if (packageRef?.current?.Type === 2 || packageRef?.current?.Type === 4) {
        const obj = {
          userData: user,
          staffPage: false,
          selectedPackageInstanceId: packageDetails?.PackageInstanceId,
          Complimentary: false
        }
        if (firstPathValue === "external") {
          history.push(`/${firstPathValue}/scheduleappointment`, obj);
        } else {
          history.push(`/scheduleappointment`, obj);
        }
      } else {
        handleSummaryRedirection({
          redeemPackage: true,
          packageDetails: packageDetails,
        });
      }
    }
  };

  const togglePackageCheckoutDialog = () => {
    setCheckoutPackage({ ...checkoutPackage, showDialog: false });
    if (checkoutType === "inhouse-package") {
      props.handleWindow();
    } else {
      if (!staffPage) {
        handleSummaryRedirection();
      } else {
        const urlRedirection = `/member/${props?.location?.state?.uuid}/${props?.location?.state?.userData?.UserMemberId}`;
        history.push(urlRedirection);
      }
    }
  };

  const renderPackageCheckoutDialog = () => {
    let totalPackageAdditionalFees = 0;

    if (packageRef?.current?.PackageFeeMappings?.length > 0) {
      const PackageAdditionalFees = packageRef?.current?.PackageFeeMappings
        .filter((fee: any) => fee.CanFeesRemoved === false)
        .map((fee: any) => ({
          ...fee,
          RevisedAmount: fee.RevisedAmount ? fee.RevisedAmount : 0
        }));

      totalPackageAdditionalFees = PackageAdditionalFees.reduce((sum: number, fee: any) => sum + fee.RevisedAmount, 0);
    }

    if (appliedCoupons.length > 0) {
      if (packageRef?.current?.Cost + totalPackageAdditionalFees <= couponAmount) {
        totalPackageAdditionalFees = 0;
      }
    }
    const { Name } = checkoutPackage.package;
    const filterClass = checkoutPackage?.package?.Classes?.filter(
      (i: any) => i.ClassId === null
    );
    let AvailbleGS: any = checkoutPackage?.package?.Classes?.length || 0;
    if (filterClass?.length > 0) {
      AvailbleGS = "All";
    }
    return (
      <Dialog
        className="bz-book-Appoinment-dialog bz-purchaseClassSessionSuccess bz-groupEvent-success bz-colon-centered"
        title={"Purchased Packages Successfully"}
        onClose={() => togglePackageCheckoutDialog()}
      >
        <>
          <div className="ready-to-book-appt-dialog">
            <div className="column-1-section"> Package Name</div>
            <span>:</span>
            <div className="column-2-section">{Name}</div>
            <div className="column-1-section">
              {packageRef?.current?.Type === TypeEnum.Class && "Available Group"}
              {packageRef?.current?.Type === TypeEnum.PrivateAppointment && packageRef?.current?.SessionType !== SessionTypeEnum.PRESET && "Available Private Appointment"}
              {packageRef?.current?.Type === TypeEnum.PrivateAppointment && packageRef?.current?.SessionType === SessionTypeEnum.PRESET && "Available Pre-set Appointment"}
              {packageRef?.current?.Type === TypeEnum.SemiPrivateAppointment && "Available Semi-Private Appointment"}
            </div>

            <span>:</span>
            <div className="column-2-section">
              {packageRef?.current?.SessionType === SessionTypeEnum.UNLIMITED ? "Unlimited" : packageRef?.current?.SessionType === SessionTypeEnum.PRESET ? packageRef?.current?.Schedules : packageRef?.current?.ProratedSchedules > 0 ? packageRef?.current?.ProratedSchedules : packageRef?.current?.OptedSchedules}
            </div>
            {checkHasTaxEnable() && !IsTaxSkipped && Taxes?.length > 0 ? (
              <>
                <div className="column-1-section">Pay Today</div>
                <span>:</span>
                <div className="column-2-section">
                  {CurrencyFormat(downpaymentForm.paidToday + TotalTaxAmount + totalPackageAdditionalFees)}
                </div>
              </>
            ) : (
              <>
                <div className="column-1-section">Pay Today</div>
                <span>:</span>
                <div className="column-2-section">
                  {CurrencyFormat(downpaymentForm.paidToday + totalPackageAdditionalFees)}
                </div>
              </>
            )}
            {packageRef?.current?.ClientCanSkipPayment && (
              <>
                <div className="column-1-section">{`Pay On ${moment(
                  packageRef?.current?.StartDate
                ).format("MM/DD/YYYY")}`}</div>
                <span>:</span>
                <div className="column-2-section">
                  {CurrencyFormat(
                    !IsTaxSkipped
                      ? packageCostAfterDiscount + PredictedTotalTaxAmount
                      : packageCostAfterDiscount
                  )}
                </div>
              </>
            )}
          </div>
          <div className="d-flex justify-content-center flex-column align-items-center">
            <div className={"text-center mr-0 mt-3 mt-sm-0 mt-lg-3"}>
              {packageRef?.current?.Type === 1 &&
                "Appointments available for redemption with this Group Package"}
              {packageRef?.current?.Type === 2 && packageRef?.current?.SessionType !== SessionTypeEnum.PRESET &&
                "Appointments available for redemption with this Private Appointment Package"}
              {packageRef?.current?.Type === 2 && packageRef?.current?.SessionType === SessionTypeEnum.PRESET &&
                "Appointments available for redemption with this Pre-set Appointment Package"}
              {packageRef?.current?.Type === 4 &&
                "Appointments available for redemption with this Semi-Private Appointment Package"}
            </div>
            <div className="text-center">
              <a
                className="btn btn-link text-primary mt-2"
                onClick={() => toggleRedeemPackage(checkoutPackage?.package)}
              >
                <u>Click here to {packageRef?.current?.Type === 1 ? "Enroll" : "Schedule"}</u>
              </a>
            </div>
            <div className="text-center">
              <i>(or)</i>
            </div>
            {/* </>)} */}
            <div className="text-center">
              <a
                className={"btn btn-link  mr-0 text-primary "}
                onClick={() => togglePackageCheckoutDialog()}
              >
                <u>Access Dashboard</u>
              </a>
            </div>
          </div>
        </>
      </Dialog>
    );
  };

  // handle card form submit
  const handleCardSubmit = async (dataItem: any) => {
    setIsFormChanged(true);
    setCardSubmitBtnLoading(true);
    const saveCardDetails = new TenantService();
    if (paymentGatewayType === 1) {
      dataItem.MaskedCCNumber = dataItem.MaskedCCNumber.split(" ").join("");
    }
    if(shouldSetAsDefaultPaymentProfile(paymentProfile)){
      dataItem.IsDefault=true
    }
    const result = await saveCardDetails.createPaymentProfile(dataItem);
    setCardSubmitBtnLoading(false);
    if (result?.ResponseCode === 100) {
      setPopulateProfile(result?.Item);
      setSelected("0");
      props?.handleNotificationMessage(
        `Payment creation successfully done`,
        "success"
      );
      setPaymentMethod(defaultPaymentMethod);
      setcardImageIcon(images.placeholder);
      fetchPaymentProfiles(dataItem?.UserMemberId);
      handleSubmit("text");
    } else {
      setCardFormValues(dataItem);
      setBankFormValues(bankIntialValues);
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  // handle bank form submit
  const handleBankSubmit = async (dataItem: any) => {
    setIsFormChanged(true);
    setBankSubmitBtnLoading(true);
    const saveBankDetails = new TenantService();
    let req = { ...dataItem, BankAccountType: dataItem?.BankAccountType?.id };
    if(shouldSetAsDefaultPaymentProfile(paymentProfile)){
      req={...req,IsDefault:true}
    }
    const result = await saveBankDetails.createPaymentProfile(req);
    setBankSubmitBtnLoading(false);
    if (result?.ResponseCode === 100) {
      setPopulateProfile(result?.Item);
      setSelected("0");
      const successMsg = "Payment creation successfully done";
      props?.handleNotificationMessage(successMsg, "success");
      setPaymentMethod(defaultPaymentMethod);
      fetchPaymentProfiles(dataItem?.UserMemberId);
      handleSubmit("text");
    } else {
      setBankFormValues(dataItem);
      setCardFormValues(cardIntialValues);
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleClientConfirmation = (page: string, cbParams: any = null) => {
    const check = checkoutPackageValidation(true, true);
    if (check) {
      return;
    }

    if (AllClientsList?.length > 1 && checkoutType !== "online-class" && !calenderPage) {
      if (props?.location?.state?.userData?.UserMemberId !== clientFormValues?.UserMemberId) {
      setPurchaseCheckout({
        ...purchaseCheckout,
        page: page,
        params: cbParams,
      });
      toggleClientConfirmDialog();
      return;
      } else {
        handleCheckoutPage(page, cbParams);
      }
    } else {
      handleCheckoutPage(page, cbParams);
    }
  };

  const handleAppointmentRates = () => {
    setAppointmentRatesDialog(!appointmentRatesDialog);
  }

  const handleDownPayment = (val: any, name: string) => {
    setIsFormChanged(true);
    if (name === "paidToday") {
      let paidTodayVal = val;
      if (val > packageCostAfterDiscount) {
        paidTodayVal = packageCostAfterDiscount;
      } else if (val <= 0) {
        paidTodayVal = 0;
      } else if (val === undefined || val === null) {
        paidTodayVal = null;
      }
      setDownpaymentForm({ ...downpaymentForm, [name]: paidTodayVal });
      handleTaxCalculation(paidTodayVal);
      handleTaxCalculation(packageCostAfterDiscount, Taxes, true);
    } else if (name === "installments") {
      let installmentVaue = val;
      if (val > 999) {
        installmentVaue = 999;
      }
      if (val <= 0) {
        installmentVaue = 1;
      }
      setDownpaymentForm({ ...downpaymentForm, [name]: installmentVaue });
    } else if (name === "OcurrsEvery") {
      let ocurrsEveryValue = val;
      const recurrUnit = downpaymentForm.recurrenceUnit.id;
      if (recurrUnit === 2) {
        if (val > 30) {
          ocurrsEveryValue = 30;
        }
      } else {
        if (val > 12) {
          ocurrsEveryValue = 12;
        }
      }
      if (val <= 0) {
        ocurrsEveryValue = 1;
      }
      const InstallmentDate = addDates(
        new Date(),
        ocurrsEveryValue,
        downpaymentForm?.recurrenceUnit?.text
      );
      setDownpaymentForm({
        ...downpaymentForm,
        [name]: ocurrsEveryValue,
        recurringStartDate: InstallmentDate,
      });
    } else if (name === "recurrenceUnit") {
      const recurrUnit = val.id;
      let ocurrsEveryVal = downpaymentForm.OcurrsEvery;
      if (recurrUnit === 2) {
        if (ocurrsEveryVal > 30) {
          ocurrsEveryVal = 30;
        }
      } else {
        if (ocurrsEveryVal > 12) {
          ocurrsEveryVal = 12;
        }
      }
      const InstallmentDate = addDates(new Date(), ocurrsEveryVal, val?.text);
      setDownpaymentForm({
        ...downpaymentForm,
        [name]: val,
        OcurrsEvery: ocurrsEveryVal,
        recurringStartDate: InstallmentDate,
      });
    } else if (name === "recurringStartDate") {
      if (moment(val).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
        setDownpaymentForm({ ...downpaymentForm, [name]: val });
      }
    } else {
      setDownpaymentForm({ ...downpaymentForm, [name]: val });
    }
  };

  //to handle build agreement
  const handleCheckoutPage = (page: string, cbParams: any = null) => {
    setConfirmClientDialog(false);
    if (!staffPage && !CheckUserMemberStatus(clientSubmitDetails)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (staffPage && !CheckUserMemberStatus(clientSubmitDetails)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    let check = false;
    if (page === "agreement") {
      check = checkoutPackageValidation(false, false);
    } else {
      check = checkoutPackageValidation(true, true);
    }
    if (check) {
      return;
    }
    createPackageAgreementObj(page);
  };

  const createPackageAgreementObj = (page: string) => {
    const today = moment(packageRef?.current?.StartDate).format("L");
    const mainDate =
      packageRef?.current?.BillingType === 3
        ? moment(packageRef?.current?.StartDate).date(packageRef?.current?.BillingDay).format("L")
        : today;
    let recurringDetail = null;
    let expiryafterDays = packageRef?.current?.ExpireAfterDaysOnPurchase;
    let ExpiryDate = moment(minimumDate).format("L");
    let ExpireOn = packageRef?.current?.ExpireOn;
    if (packageRef?.current?.SessionType !== 3) {
      if (packageRef?.current?.Frequency !== PackageFrequencyEnum.ONETIME) {
        const item = PackageFrequency?.filter(
          (i: any) => i?.id === packageRef?.current?.Frequency
        )?.[0];
        const repeatfreqValue = item?.shortValue;
        let nextMonth = calculatePackageRecurrenceDate(
          mainDate,
          packageRef?.current?.RepeatFrequency,
          repeatfreqValue
        );
        if (moment(packageRef?.current?.StartDate).date() < packageRef?.current?.BillingDay) {
          nextMonth = mainDate;
        }

        expiryafterDays = 0;
        recurringDetail = {
          FrequencyEndType: packageRef?.current?.FrequencyEndType,
          RepeatFrequency: packageRef?.current?.RepeatFrequency,
          Frequency: packageRef?.current?.Frequency,
          Occurrence: packageRef?.current?.FrequencyEndType === 0 ? 0 : packageRef?.current?.Occurrence,
          RecurringAmount: packageRef?.current?.DiscountedCost,
          NextRecurringDate: moment(nextMonth).format("L"),
        };
      } else if (packageRef?.current?.Frequency === PackageFrequencyEnum.ONETIME) {
        recurringDetail = {
          FrequencyEndType: 1,
          RepeatFrequency: 1,
          Frequency: packageRef?.current?.Frequency,
          Occurrence: 1,
          RecurringAmount: 0,
          NextRecurringDate: null,
        };
        if (packageRef?.current?.Expire === 0) {
          ExpireOn = packageRef?.current?.ExpireOn;
        } else {
          ExpiryDate = moment(packageRef?.current?.ExpiryDate).format("L");
          ExpireOn = 8;
        }
      }
    } else {
      const { installments, OcurrsEvery, recurringStartDate, recurrenceUnit } =
        downpaymentForm;
      if (showinstallmentForm) {
        recurringDetail = {
          FrequencyEndType: 1,
          RepeatFrequency: OcurrsEvery,
          Frequency: recurrenceUnit?.id,
          Occurrence: installments,
          RecurringAmount: recurringAmount,
          NextRecurringDate: moment(recurringStartDate).format("L"),
        };
      } else {
        recurringDetail = null;
      }
    }
    let packageCost = packageRef?.current?.Cost;
    if (packageRef?.current?.SessionType === 3) {
      packageCost = packageRef?.current?.IndividualSessionRate * packageRef?.current?.Schedules;
    }



    let packageObj: any = {
      PackageId: packageRef?.current?.PackageId,
      ExpireAfterDaysOnPurchase: expiryafterDays,
      ExpiryDate: ExpiryDate,
      ExpireOn: ExpireOn,
      Name: packageRef?.current?.Name,
      Schedules: packageRef?.current?.Schedules,
      SessionType: packageRef?.current?.SessionType,
      Cost: CustomToFixed(packageCost, 2),
      CanProrate: packageRef?.current?.IsProrated,
      ProratedCost: packageRef?.current?.IsProrated ? packageRef?.current?.ProratedCost : undefined,
      ProratedSchedules: packageRef?.current?.IsProrated
        ? packageRef?.current?.ProratedSchedules
        : undefined,
      DiscountedCost: packageRef?.current?.SessionType === 3 ? packageCostAfterDiscount > 0 ? packageCostAfterDiscount : 0 : CustomToFixed(packageRef?.current?.DiscountedCost, 2),
      OptedSchedules:
        packageRef?.current?.SessionType === 1
          ? staffPage
            ? packageRef?.current?.OptedSchedules
            : packageRef?.current?.Schedules
          : undefined,
      OptedMaxRollOverSchedules:
        packageRef?.current?.SessionType === 1
          ? staffPage
            ? packageRef?.current?.OptedMaxRollOverSchedules
            : packageRef?.current?.MaxRollOverSchedules
          : undefined,
      MaxRollOverSchedules: packageRef?.current?.MaxRollOverSchedules,
      RatePerSession: packageRef?.current?.IndividualSessionRate,
      Duration: packageRef?.current?.SessionType === 3 ? packageRef?.current?.Duration : undefined,
    };

    if (packageRef?.current?.PackageFeeMappings?.length > 0) {
      let updatedAdditionalFees = packageRef?.current?.PackageFeeMappings.map((fee: any) => ({
        PackageId: packageRef?.current?.PackageId,
        Name: fee.FeeType,
        Amount: fee.Amount,
        CanFeesRemoved: fee.CanFeesRemoved,
        IsFeeRevised: fee.RevisedAmount !== fee.Amount,
        RevisedAmount: fee.RevisedAmount
      }));
      packageObj.AdditionalFees = updatedAdditionalFees
    }


    let totalPackageAdditionalFees = 0;
    if (packageRef?.current?.PackageFeeMappings?.length > 0) {
      const PackageAdditionalFees = packageRef?.current?.PackageFeeMappings
        .filter((fee: any) => fee.CanFeesRemoved === false)
        .map((fee: any) => ({
          ...fee,
          RevisedAmount: fee.RevisedAmount ? fee.RevisedAmount : 0
        }));

      totalPackageAdditionalFees = PackageAdditionalFees.reduce((sum: number, fee: any) => sum + fee.RevisedAmount, 0);
    }

    let packageDiscount = packageCostAfterDiscount;
    let downPaymentPayToday = downpaymentForm?.paidToday
    if (page === "agreement") {
      packageDiscount = packageCostAfterDiscount + totalPackageAdditionalFees;
      downPaymentPayToday = downpaymentForm?.paidToday + totalPackageAdditionalFees;
    }

    if (appliedCoupons.length > 0) {
      if (packageCost + totalPackageAdditionalFees <= couponAmount) {
        packageDiscount = 0;
        downPaymentPayToday = 0;
      }
    }



    let clientCanSkipPay = packageRef?.current?.ClientCanSkipPayment;
    if (packageRef?.current?.Cost <= 0) {
      clientCanSkipPay = false;
      if (recurringDetail) {
        recurringDetail = { ...recurringDetail, RecurringAmount: 0 };
      }
    }
    let buildagreement: any = {
      Client: clientSubmitDetails,
      ClientCanSkipPayment: clientCanSkipPay,
      DownPayment: downPaymentPayToday,
      HasTermsAndConditionAccepted: termsandConditions,
      PaymentProfile: paymentSubmitProfile,
      AlternatePaymentProfiles: [
        {
          AlternatePaymentProfileId: 0,
          AmountChargeByAlternatePaymentProfile: 0
        }
      ],
      Package: packageObj,
      Coupons: couponDetails?.AppliedCoupons || [],
      Signature: signature,
      SalesPersonId:
        checkoutType === "inhouse-package" || staffPage
          ? selectedSalesPerson?.UserId
          : 0,
      PackageCostAfterDiscount: packageDiscount,
      RecurringDetail: recurringDetail,
      StartDate: moment(packageRef?.current?.StartDate).format("L"),
      Taxes: checkHasTaxEnable() && !IsTaxSkipped ? Taxes : [],
      CanCollectTaxes: checkHasTaxEnable() ? !IsTaxSkipped : false,
      TotalTaxAmount: checkHasTaxEnable() && !IsTaxSkipped ? TotalTaxAmount : 0,
      // new fields
      BillingDay:
        packageRef?.current?.BillingType === 3
          ? packageRef?.current?.BillingDay
          : moment(packageRef?.current?.StartDate).date(),
      SignupDate: moment(packageRef?.current?.SignupDate).format("L"),
      RenewalType: packageRef?.current?.RenewalType,
    };
    if (
      paymentSubmitProfile?.CardTypeId === 8 &&
      downpaymentFormRef?.current?.paidToday > paymentSubmitProfile?.Credit
    ) {

      const downPaymentAmount = !IsTaxSkipped && packag?.CanCollectTaxes
        ? downpaymentFormRef?.current?.paidToday + TotalTaxAmount
        : downpaymentFormRef?.current?.paidToday;

      const finaldownPaymentAmount = packag?.ClientCanSkipPayment ? 0 : ((packageCostAfterDiscount + totalPackageAdditionalFees === 0) && downpaymentFormRef?.current?.paidToday === 0) ? 0 : downPaymentAmount + totalPackageAdditionalFees;
      const AlternatePaymentProfileId = selectedPaymentProfile?.AlternatePaymentProfileId;
      const AmountChargeByAlternatePaymentProfile = CustomToFixed(finaldownPaymentAmount - paymentSubmitProfile?.Credit, 2);

      buildagreement.AlternatePaymentProfiles[0].AlternatePaymentProfileId = AlternatePaymentProfileId;
      buildagreement.AlternatePaymentProfiles[0].AmountChargeByAlternatePaymentProfile = AmountChargeByAlternatePaymentProfile;

    }
    if (packageRef?.current?.PromotionId) {
      buildagreement = {
        ...buildagreement,
        PromotionId: packageRef?.current?.PromotionId,
        SalesPersonId: packageRef?.current?.AffiliateUserId,
        AffiliateId: packageRef?.current?.AffiliateId,
      };
    }
    let paymentAttributes = null;
    let PaymentObject={...paymentSubmitProfile}
    if (paymentSubmitProfile?.CardTypeId === -2) {
      paymentAttributes = {
        Reference: paymentSubmitProfile?.Reference,
        OfflinePaymentDescription: paymentSubmitProfile?.MaskedCCNumber,
        ReferrenceDocumentName: paymentSubmitProfile?.ReferrenceDocument?.FileName || "",
        ReferrenceDocumentContent: paymentSubmitProfile?.ReferrenceDocument?.ImageAsBase64 || "",
      };
      PaymentObject={...paymentSubmitProfile,CardTypeId:-1}

    }else if(selectedPaymentProfile?.CardTypeId === -1){
      paymentAttributes = {
        Reference: "",
        OfflinePaymentDescription: "Cash",
        ReferrenceDocumentName: "",
        ReferrenceDocumentContent: "",
      };
      PaymentObject={...paymentSubmitProfile,CardTypeId:-1}

    }
    buildagreement= {
      ...buildagreement,
      PaymentAttributes:paymentAttributes,
      PaymentProfile: PaymentObject
    }
    setBuildagreement(buildagreement);
    if (page === "agreement") {
      handleBuildAgreement(buildagreement);
    } else {
      if (agreementTemplate === "") {
        handlePayNow(buildagreement);
      } else {
        const data = {
          ...buildagreement,
          AgreementTemplate: agreementTemplate,
          TermsAndConditionAccepted: true,
        };
        handlePayNow(data);
      }
    }
  };

  function calculateTotalPackageAdditionalFees(packageRef:any) {
    let totalPackageAdditionalFees = 0;
    
    if (packageRef?.current?.PackageFeeMappings?.length > 0) {
      const PackageAdditionalFees = packageRef.current.PackageFeeMappings
        .filter((fee:any) => fee.CanFeesRemoved === false)
        .map((fee:any) => ({
          ...fee,
          RevisedAmount: fee.RevisedAmount ? fee.RevisedAmount : 0
        }));
  
      totalPackageAdditionalFees = PackageAdditionalFees.reduce((sum, fee) => sum + fee.RevisedAmount, 0);
    }
  
    return totalPackageAdditionalFees;
  }
  

  const handleBuildAgreement = async (dataItem: any) => {
    setDisable(true);
    let result = null;

    const buildservice = new PackageService();
    result = await buildservice.buildAgreement(dataItem);

    setDisable(false);
    let template = "";
    if (result?.ResponseCode === 100) {
      template = result?.Item;
      setBuildagreement({
        ...buildagreement,
        AgreementTemplate: template,
        TermsAndConditionAccepted: true,
      });
      setAgreementTemplate(template);
      setVisibleAgreement(true);
    } else {
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handlePayNow = async (dataItem: any) => {
    setBtnLoading(true);
    setDisable(true);
    let result = null;

    const checkoutPurchase = new PackageService();
    result = await checkoutPurchase.purchasePackage(dataItem);

    setBtnLoading(false);
    setDisable(false);
    if (result?.ResponseCode === 100) {
      let successMsg = "";
      successMsg = `Package Purchased Successfully`;
      props?.handleNotificationMessage(successMsg, "success");
      setIsFormChanged(false);
      if (!appointmentpage) {
        setCheckoutPackage({
          ...checkoutPackage,
          showDialog: true,
          package: {
            ...packageRef?.current,
            PackageInstanceId: parseInt(result?.Items[0]),
            UserMemberId: clientSubmitDetails?.UserMemberId,
          },
        });
      } else {
        props?.onChangePurchaseScreen(result?.Items[0]);
      }
    } else {
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const updateSelectedPackage = (val: any) => {
    const minimumDate = new Date(moment().add(1, "day").format("L"));

    let ExpiryDate = minimumDate;
    let ExpireValue = 0;
    if (val?.ExpireOn === 8) {
      ExpireValue = 8;
      ExpiryDate = val?.ExpiryDate;
    }

    const packageData = {
      ...val,
      StartDate: new Date(),
      Expire: ExpireValue,
      ExpiryDate: ExpiryDate,
      ClientCanSkipPayment: false,
      SignupDate: new Date(),
      BillingType:
        val?.Frequency === PackageFrequencyEnum.MONTHLY &&
          val?.RepeatFrequency === 1
          ? val?.CanProrate
            ? 3
            : 2
          : 2,
      BillingDay: val?.BillingDay ? val?.BillingDay : 1,
      ProratedSchedules: val?.Schedules,
      ProratedCost: 0,
      OptedSchedules: val?.Schedules,
      OptedMaxRollOverSchedules: val?.MaxRollOverSchedules,
      IndividualSessionRate: val?.Cost,
      DownPayment: val?.DownPayment,
      Schedules: val?.Schedules,
      CanClientOverrideRenewalOption: val?.CanClientOverrideRenewalOption,
      RenewalType: val?.CanRenewAfterLastAppointmentCompletion ? 2 : 0,
      Cost: val?.SessionType === 3 ? val?.Schedules * val?.Cost : val?.Cost,
      DiscountedCost:
        val?.SessionType === 3 ? val?.Schedules * val?.Cost : val?.Cost,
      PayByRecurring:
        val?.Frequency === 1 && val?.SessionType === 3 ? true : false,
    };
    setAppliedCoupons([]);
    fetchPackageData(undefined, packageData, true);
    fetchPackageById(val);
  };

  const handleTermsConditions = (val: boolean) => {
    setTermsandConditions(val);
    setTermsandConditionsDialog(false);
  };

  const handleTermsConditionDialog = (val: any) => {
    const check = checkoutPackageValidation(true, false);
    if (check) {
      return;
    }
    if (val === true || val === false) {
      setTermsandConditions(val);

    } else {
      setTermsandConditionsDialog(!termsandConditionsDialog);
    }

  };

  const handleChangeAdditionalPaymentProfile = async (value: any) => {
    setShowAdditionalPayment({
      ...showAdditionalPayment,
      selectedPaymentProfile: value,
    });

  };

  const handleAddAdditionalPayment = () => {
    if (
      !showAdditionalPayment?.selectedPaymentProfile
        ?.PaymentGatewayPaymentProfileID
    ) {
      const errorMsg = "Please select/add Payment profile";
      props?.handleNotificationMessage(errorMsg, "error");
      return;
    }
    
    setSelectedPaymentProfile({
      ...selectedPaymentProfile,
      AlternatePaymentProfileId: showAdditionalPayment?.selectedPaymentProfile?.PaymentGatewayPaymentProfileID,
    });
    setShowAdditionalPayment(initialAdditionalPayment);
    
    if(signature === ""){
      setVisibleSignPad(true);
    }
  };
  const handleAddPaymentMode=async(description:string)=> {
    console.log(description,'des')
    setPaymentMethod(defaultPaymentMethod);
    await fetchPaymentProfiles(userMemberId,false,description)
  }


  const widthOfDevice = window.innerWidth;
  if (loading) {
    return (
      <>
        <BuzopsLoader type="list" />
      </>
    );
  }

  const inHouseGroup =
    checkoutType === "inhouse-class" || checkoutType === "inhouse-package"
      ? "bz-inhouse-order-summary"
      : "bz-online-order-summary";

  return (
    <>

      {customLoading && <Loader type="converging-spinner" className="laoder-full-screen" />}
      <div className="row m-0 purchasepackgecheckout">
        {!appointmentpage && (
          <div className="col-md-12 mb-3 p-0">
            <div>
              {!staffPage && (
                <Prompt
                  when={isFormChanged}
                  message={
                    "You have unsaved changes, Are you sure you want to leave?"
                  }
                />
              )}

              <div className="row px-1 align-items-center mb-2 purchasepackebtn ">
                <div className="col-md-2" style={{ width: "50px", marginTop: "18px" }}>
                  {checkoutType !== "inhouse-package" && (
                    <BackButtonComponent
                      onClick={() => handleNotNowRedirection()}
                    />
                  )}
                </div>
                <div className="col-md-4">
                  {" "}
                  <div>Client</div>
                  <MemberSelection
                    allClientsList={AllClientsList}
                    selectedUsermemberId={userMemberId}
                    handleUpdateClient={(val: any) =>
                      updateSelectedClientData(val)
                    }
                  //disabled={updateAppointment === true ? updateAppointment : checkout}
                  />
                </div>
                {/* <div className="col-md-4">
                  {" "}
                  <div>Package</div>
                  <PackageSelection
                    packageList={packageList}
                    selectedPackageId={packageRef?.current?.PackageId}
                    handleUpdatePackage={(val: any) =>
                      updateSelectedPackage(val)
                    }
                  //disabled={updateAppointment === true ? updateAppointment : checkout}
                  />{" "}
                </div> */}
              </div>
            </div>
          </div>
        )}

        <div className="col-md-12 purchasesummaryappts mt-0 packagerelated p-0">
          <div
            className={`col-12 bz-checkout-page bz-checkout-${checkoutType}`}
          >
            <div className="checkout-page row d-flex justify-content-between adminschedule custom-grid-box" style={{ marginBottom: '0' }}  >
              <div className="payment-panel-form col-xs-12 col-sm-12 col-md-12 col-lg-9 mb-3 mb-lg-0">
                <PackagePaymentPanelForm
                  staffPage={staffPage}
                  makePaymentProfileAsDefault={makePaymentProfileAsDefault}
                  UserMemberId={userMemberId}
                  PrimaryUserMemberId={props?.location?.state?.userData?.PrimaryMemberId || props?.userDatafromAppointment?.PrimaryMemberId || userMemberId}
                  handleSubmit={handleSubmit}
                  handlefetchCards={handlefetchCards}
                  handleAddPaymentMode={handleAddPaymentMode}
                  appointmentpage={appointmentpage}
                  handleForm={(val: string) => handleForm(val)}
                  paymentMethod={paymentMethod}
                  selected={selected}
                  page={checkoutType}
                  paymentGatewayType={paymentGatewayType}
                  packageCostAfterDiscount={packageCostAfterDiscount}
                  bankFormValues={bankFormValues}
                  cardImageIcon={cardImageIcon}
                  selectedPaymentProfile={selectedPaymentProfile}
                  handleBankSubmit={handleBankSubmit}
                  handleDownPayment={handleDownPayment}
                  downpaymentForm={downpaymentForm}
                  handleAppointmentRates={handleAppointmentRates}
                  cardFormValues={cardFormValues}
                  tableData={tableData}
                  paymentProfile={paymentProfile}
                  recurringAmount={recurringAmount}
                  handleCardSubmit={handleCardSubmit}
                  cardSubmitBtnLoading={cardSubmitBtnLoading}
                  bankSubmitBtnLoading={bankSubmitBtnLoading}
                  package={packageRef?.current}
                  showinstallmentForm={showinstallmentForm}
                  originalPackage={packageItem}
                  paymentSubmitProfile={paymentSubmitProfile}
                  handlePackageReccurence={(name: string, value: any, item: any) =>
                    handlePackageReccurence(name, value, item)
                  }
                  handleChangeSavedCard={(val: any,dataItem=null) =>
                    handleChangeSavedCard(val,dataItem)
                  }
                  handleCardImageIcon={(url: any) => handleCardImageIcon(url)}
                  onSelect={(e: any) => onSelect(e)}
                  ratepermission={permissionRight}
                  IsTaxSkipped={IsTaxSkipped}
                />
              </div>
              <div
                className={`order-summary-form col-xs-12 col-sm-12 col-md-12 col-lg-3 ${inHouseGroup}`} style={{ border: '1px solid #d9d9d9 ', borderRadius: '5px' }}
              >
                <PackageOrderSummary
                  page={checkoutType}
                  disable={disable}
                  termsandConditions={termsandConditions}
                  Taxes={Taxes}
                  TotalTaxAmount={TotalTaxAmount}
                  PredictedTaxes={PredictedTaxes}
                  PredictedTotalTaxAmount={PredictedTotalTaxAmount}
                  filterCoupondata={filterCoupondata}
                  filterCouponChange={(e: any) => filterCouponChange(e)}
                  handlePackageCoupon={(val: any) => handlePackageCoupon(val)}
                  packageCostAfterDiscount={packageCostAfterDiscount}
                  couponAmount={couponAmount}
                  package={packageRef?.current}
                  handleIsTaxSkipped={handleIsTaxSkipped}
                  appliedCoupons={appliedCoupons}
                  btnLoading={btnLoading}
                  salesPersons={salesPersons}
                  selectedSalesPerson={selectedSalesPerson}
                  downpaymentForm={downpaymentForm}
                  IsTaxSkipped={IsTaxSkipped}
                  visibleCouponField={visibleCouponField}
                  handleCoupon={() => handleCoupon()}
                  handleSalesPersonSelection={(data: any) =>
                    handleSalesPersonSelection(data)
                  }
                  handlePackageReccurence={(name: string, value: any, item: any) =>
                    handlePackageReccurence(name, value, item)
                  }
                  handleRemoveCoupon={(index: number) =>
                    handleRemoveCoupon(index)
                  }
                  handleAgreement={(params: Object) =>
                    handleCheckoutPage("agreement", params)
                  }
                  handleSignaturePad={() => handleSignaturePad()}
                  visibleSignPad={visibleSignPad}
                  signature={signature}
                  signatureText={signatureText}
                  handleTermsConditionDialog={handleTermsConditionDialog}
                  signatureFont={signatureFont}
                  signatureType={signatureType}
                  coupon={coupon}
                  staffPage={
                    staffPage || calenderPage
                  }
                  couponBtnLoading={couponBtnLoading}
                  handleChangeCoupon={(val: string) => handleChangeCoupon(val)}
                  handlePayNow={(params: Object) =>
                    handleClientConfirmation("paynow", params)
                  }
                  ratepermission={permissionRight}
                />
              </div>
              {visibleAgreement && <div className="k-overlay"></div>}
              <BuzopsWindow
                key={visibleAgreement.toString()}
                reduceWidth={widthOfDevice < 800 ? 10 : 430}
                onWindowClose={() => setVisibleAgreement(false)}
                title={"Agreement"}
                className={"bzo-window bzo-agreement-window"}
                visibility={visibleAgreement}
              >
                <div
                  className="bzo-agreement"
                  dangerouslySetInnerHTML={{ __html: agreementTemplate }}
                />
              </BuzopsWindow>
              {visibleSignPad && (
                <SignaturePad
                  staffPage={staffPage}
                  generatedImage={(imageString, text, font, typeVal) =>
                    generatedImage(imageString, text, font, typeVal)
                  }
                  key={visibleSignPad.toString()}
                  onClose={() => setVisibleSignPad(false)}
                />
              )}
              {confirmClientDialog && renderClientConfirmationDialog()}
              {termsandConditionsDialog && <TermsAndConditions handleTermsConditionDialog={handleTermsConditionDialog} handleTermsConditions={handleTermsConditions} />}
              {checkoutPackage.showDialog && renderPackageCheckoutDialog()}
              {appointmentRatesDialog && <AppointmentRates handleAppointmentRates={handleAppointmentRates} />}
              {showAdditionalPayment?.dialog && (
                <AdditionalPayment
                  fetchPaymentProfiles={fetchPaymentProfiles}
                  userMemberId={userDetails.UserMemberId}
                  setShowAdditionalPayment={setShowAdditionalPayment}
                  paymentProfile={paymentProfile}
                  PurchaseAmount={!IsTaxSkipped ? downpaymentFormRef?.current?.paidToday + TotalTaxAmount + calculateTotalPackageAdditionalFees(packageRef): downpaymentFormRef?.current?.paidToday  + calculateTotalPackageAdditionalFees(packageRef)}
                  CreditAmount={paymentSubmitProfile?.Credit}
                  showAdditionalPayment={showAdditionalPayment}
                  handleChangeAdditionalPaymentProfile={
                    handleChangeAdditionalPaymentProfile
                  }
                  handleAddAdditionalPayment={handleAddAdditionalPayment}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withNotification(PurchasePackage);
