import React, { useEffect, useState } from "react";
import BuzopsButton from "generic-components/BuzopsButton";
import { Button } from "@progress/kendo-react-buttons";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { CurrencyFormat, CustomToFixed } from "utils";
import { Error,Hint } from "@progress/kendo-react-labels";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { TenantService } from "services/tenant/index.service";
import Void from "./Void";
import withNotification from "components/Hoc/withNotification";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Notification as Notify } from "@progress/kendo-react-notification";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import RefundCredits from "components/paymentMethod/RefundCredits";

export const TooltipContentRefundTax = (propData: any) => {
  if (propData?.data?.length === 0) {
    return <div>{"No Surcharges"}</div>;
  }
  return (
    <>
      <ul className="list-group list-group-flush taxes-list">
        {propData?.Name && (
          <li className="list-group-item" style={{fontSize:'0.875rem',fontWeight:600}}>{propData?.Name}</li>
        )}
        {propData?.data?.map((i: any) => (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>{i?.Description}</span>
            <span className="price">{CurrencyFormat(i?.Amount)}</span>
          </li>
        ))}
        {propData?.TotalTaxAmount > 0 && (<li className="list-group-item d-flex justify-content-between align-items-center">
          <span>
              {"Total Surcharges"}
            </span>
            <span className="price">{CurrencyFormat(propData?.TotalTaxAmount)}</span>
        </li>)}
      </ul>
    </>
  );
};
const Refund = (props: any) => {
  console.log(props,'propspropspropsprops')
  const [RefundTrans, setrefundtrans] = useState<any>(null);
  const [totalAmountToRefund, setTotalAmountToRefund] = useState<any>(0);
  const [taxAmountToRefund, setTaxAmountToRefund] = useState<number>(0);
  const [taxes, setTaxes] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [submit, setsubmit] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<any>(false);
  const [showRefundDialog, setShowRefundDialog] = useState<any>(false);

  const service = new TenantService();

  useEffect(() => {
    fetchAllApis();
  }, []);

  const fetchAllApis = async () => {
    setLoading(true);
    await getData(props?.UserMemberId);
    setLoading(false);
  };


  const handleDialog=()=> {
    setShowConfirmDialog(!showConfirmDialog)
  }
  async function getData(userMemberId: any) {
    let transactionid = props?.transachisid;

    const result = await service.GetRefundTransaction(transactionid);
    result.AmountPaidTotal = result.AmountPaid;
    result.AmountPaid = result.AmountPaid - result.RefundedAmount;
    const amtToRefund=result.AmountPaidTotal - (result.RefundedAmount + result.TotalTaxAmount)
    const val=CustomToFixed(amtToRefund,2)
    setTotalAmountToRefund(val);
    setrefundtrans(result || []);
  }

  const handleChange = async (value: any, name: string) => {
    setsubmit(true);
    const data = JSON.parse(JSON.stringify(RefundTrans));
    let datatrans = data;
    datatrans = {
      ...data,
      [name]: value,
    };

    setrefundtrans(datatrans);
    handleCalculateSurcharges(
      datatrans?.AmountNeedToRefund,
      datatrans?.PaymentGatewayTransactionHistoryDetails
    );
  };

  const handleCalculateSurcharges = (
    refundAmt: any,
    TaxItems = RefundTrans?.PaymentGatewayTransactionHistoryDetails
  ) => {
    const mainTaxItems = TaxItems?.filter((i: any) => i?.MetaData);
    const TaxesArray = mainTaxItems.map((i: any) => {
      const percentage = parseFloat(i?.MetaData?.split(":")[1]);
      const amount = (refundAmt * percentage) / 100;
      const calcAmt=CustomToFixed(amount,2)
      return {
        Description: i?.Description,
        Percentage: percentage,
        Amount: calcAmt,
      };
    });
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
      return acc + currentValue?.Amount
    }, 0);
    setTaxes(TaxesArray);
    setTaxAmountToRefund(taxAmt);
  };

  const showErrorMessage = (key: any, mainKey: any) => {
    if (key === "Comments") {
      if (!RefundTrans[key] || RefundTrans[key]?.length <= 0) {
        return <Error>{`${mainKey} is required`}</Error>;
      }
    } else if (key === "AmountNeedToRefund") {
      if (RefundTrans[key] === "" || RefundTrans[key] === null) {
        return <Error>{`${mainKey} is required`}</Error>;
      }
      if (
        AmountNeedToRefund === 0 ||
        AmountNeedToRefund > totalAmountToRefund
      ) {
        return (
          <Error>{`The Refund Amount should be in between $0.01 to ${totalAmountToRefund}`}</Error>
        );
      }
    }

    return <></>;
  };
  const amountCell = (props: any) => {
    return (
      <td className={"capitalize"}>
        {CurrencyFormat(props.dataItem.RefundedAmount.toFixed(2))}
      </td>
    );
  };
  const handleValidation = () => {
    if (!Comments || Comments === "") {
      return false;
    }
    if (!AmountNeedToRefund || AmountNeedToRefund === 0) {
      return false;
    }

    if (AmountNeedToRefund > totalAmountToRefund) {
      return false;
    }

    return true;
  };


const handleCreditsRefund=()=> {
  setShowConfirmDialog(false)
  setShowRefundDialog(true)
}
const handleCloseDialog=(refresh=false)=> {
  setShowRefundDialog(false)
  if(refresh){
    props?.handleNotificationMessage(
      `Refunded Successfully`,
      "success"
    );
  }
  props?.handleCloseViewDetail();
}
  const ConfirmDialog = () => {
    return (
      <Dialog onClose={() => handleDialog()} width={"45%"}>
        <p className={"dialogContainer"}>
            <div>
              <p>
                {`The transaction amount will be added back to credits after the refund. Would you like to return the credited amount to the original payment method?`}
              </p>
            </div>
        </p>
        <DialogActionsBar>
          <Button
            onClick={() => handleDialog()}
          >
            {"No"}
          </Button>
          <BuzopsButton
            label={"Yes"}
            onClick={() => handleCreditsRefund()}
          />
        </DialogActionsBar>
      </Dialog>
    );
  };

  const handleComplete = async () => {
    const check = handleValidation();
    if (!check) {
      return true;
    }
    setBtnLoading(true);
    RefundTrans.UserMemberId = props?.UserMemberId;
    const result = await service.SaveRefundTransaction(RefundTrans);
    setBtnLoading(false);
    if (result?.ResponseCode === 100) {
      props?.handleNotificationMessage(result?.SuccessMessage, "success");
      // if(props?.CardType ===8){
      //   setShowConfirmDialog(true)
      // }else{
        props?.handleCloseViewDetail();
      // }
    } else {
      const errorMsg =
        result?.ErrorMessages?.length > 0 ? result?.ErrorMessages[0] : "Error ";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  if (loading) {
    return <BuzopsLoader type={"list"} />;
  }

  const {
    TransactionId,
    TransactionDate,
    AmountPaidTotal,
    RefundedAmount, //
    AmountNeedToRefund,
    Comments,
    RefundedTransactions,
    ExternalTransactionStatus,
    TotalTaxAmount,
    PaymentMadeForId
  } = RefundTrans;
console.log(RefundTrans,'RefundTrans')
  return ExternalTransactionStatus == 2 ? (
    <Void
      UserMemberId={props?.userData?.UserMemberId}
      transachisid={TransactionId}
      data={RefundTrans}
      handleCloseViewDetail={props?.handleCloseViewDetail}
    />
  ) : (
    <>
      <h5 className="mb-3 bz_fs_1_5">
        <Button
          className="btn btn-primary bz-back-btn btn-sm back-arrow-btn"
          onClick={() => props?.handleCloseViewDetail()}
        >
          <i className="fa fa-arrow-left"></i>
        </Button>
        &nbsp;Refund Transaction
      </h5>{" "}
      <hr />
      <h6 className="mb-2 bz_fs_1_5">
        {" "}
        Refund a partial or full amount for the below Transaction{" "}
      </h6>
      <div className="bz-subsection-row bz-plan-overview bz-addon-cancellation">
        <div className="left-section">
          <div className="bz-mt-2rem bz-content-text-row-mobile">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Transaction ID</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div className="d-flex align-items-center">{TransactionId}</div>
              </div>
            </div>
          </div>
          <div className="bz-content-text-row-mobile">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                Transaction Date{" "}
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div className="d-flex align-items-center">
                  {TransactionDate}
                </div>
              </div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Amount</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div className="d-flex align-items-center">
                  {CurrencyFormat(AmountPaidTotal - RefundedAmount - TotalTaxAmount)}
                </div>
              </div>
            </div>
            {TotalTaxAmount > 0 && (
              <>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">
                    Surcharges Collected
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <div className="d-flex align-items-center">
                      {CurrencyFormat(TotalTaxAmount)}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                Refunded Amount{" "}
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div className="d-flex align-items-center">
                  {CurrencyFormat(RefundedAmount)}
                </div>
              </div>
            </div>
          </div>
          <div className="bz-content-text-row bz-notify-row">
            <div className="bz-content-title text-right">
              Enter Full/Partial Amount to Refund
              <span className="required-asterisk">*</span>
             
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
           
              <NumericTextBox
                id={`refundAmAmountNeedToRefundount`}
                name={`AmountNeedToRefund`}
                min={0}
                format={"c2"}
                className="k-dropdown-width-100"
                value={AmountNeedToRefund}
                onChange={(e) =>
                  handleChange(e.target.value, "AmountNeedToRefund")
                }
              />
              <Hint> <span>{`Available to refund : ${CurrencyFormat(
                totalAmountToRefund
              )}`}</span></Hint>
              {submit &&
                showErrorMessage("AmountNeedToRefund", "Amount To Refund")}
            </div>
          </div>
          {taxAmountToRefund >0 && (
              <>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">
                    <Tooltip
                      content={(tooltipProps: any) => {
                        return (
                          <TooltipContentRefundTax
                            title={tooltipProps.title}
                            data={taxes}
                            TotalTaxAmount={taxAmountToRefund}
                          />
                        );
                      }}
                      tooltipClassName="bz-tooltip-table"
                    >
                      <a
                        title="Surcharges"
                        style={{
                          borderBottom: "1px dotted #0d6efd",
                          cursor: "pointer",
                        }}
                      >
                        {"Surcharges to be refunded"}
                      </a>
                    </Tooltip>
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <div className="d-flex align-items-center">
                      {CurrencyFormat(taxAmountToRefund)}
                    </div>
                  </div>
                </div>
              </>
            )}
          <div className="bz-content-text-row bz-notify-row align-items-start">
            <div className="bz-content-title text-right">
              Comments<span className="required-asterisk">*</span>
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <textarea
                className="form-control"
                onChange={(e) => handleChange(e.target.value, "Comments")}
              ></textarea>
              {submit && showErrorMessage("Comments", "Comments")}
            </div>
          </div>

          {RefundedTransactions?.length > 0 && (
            <>
              <h5 className="mb-3 pt-3">Refunded Transactions</h5>
              <Grid
                data={RefundedTransactions}
                className="bzc-grid bz-refund-grid"
              >
                <Column
                  field="TransactionDate"
                  title="Transaction Date"
                  width={140}
                />
                <Column
                  field="PaymentGatewayTransactionHistoryId"
                  title="Payment Gateway Transaction History Id"
                  width={300}
                />
                <Column
                  field="RefundedAmount"
                  title="Refunded Amount"
                  width={150}
                  cell={amountCell}
                />
              </Grid>
            </>
          )}
          {PaymentMadeForId === 23 ? (<>
            <div className="bz-content-text-row">
            <div className="bz-content-title text-right"></div>
            <span className="bz-colon text-center"></span>
            <div className="bz-content-body">
              <Notify key="info" type={{ style: "info" }} className="mb-2">
              {`To initiate a refund for this transaction, please cancel the group appointments and issue the refund.`}
              </Notify>
            </div>
          </div>
          </>):(<> <div className="bz-content-text-row mt-3 ">
            <div className="bz-content-title text-right"></div>
            <span className="bz-colon text-center"></span>
            <div className="bz-content-body">
              <Button onClick={() => props?.handleCloseViewDetail()}>
                Cancel
              </Button>
              <BuzopsButton
                loading={btnLoading}
                disabled={btnLoading}
                label={"Complete"}
                onClick={() => handleComplete()}
              />
            </div>
          </div></>)}
        </div>
      </div>
      {showConfirmDialog && ConfirmDialog()}
            {showRefundDialog ? (
             <RefundCredits
                handleCloseDialog={handleCloseDialog}
                 UserMemberId={RefundTrans?.PrimaryUserMemberId}
                 walletAmt={RefundTrans?.AmountNeedToRefund}
              />
            ) : null}
    </>
  );
};

export default withNotification(Refund);
