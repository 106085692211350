import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import BuzopsButton from "generic-components/BuzopsButton";
import moment from "moment";
import { Prompt, useHistory } from "react-router-dom";
import { ClassService } from "services/class/index.service";
import { PackageService } from "services/package/index.service";
import { TenantService } from "services/tenant/index.service";
import { Error } from "@progress/kendo-react-labels";
import {
  checkHasTaxEnable,
  CheckUserMemberStatus,
  CustomToFixed,
} from "utils";
import images from "utils/images";
import { GetLocalStore } from "utils/storage";
import OrderSummary from "./OrderSummary";
import PaymentPanelForm from "./PaymentPanelForm";
import { CurrencyFormat } from "utils";
import withNotification from "components/Hoc/withNotification";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { CouponDurationTypeEnum, GenderTypeEnum, PackageInstanceStatusEnum, PackageTypesList, SessionTypeEnum } from "utils/form-utils";
import { useDispatch, useSelector } from 'react-redux'
import { PaymentMethodSelected } from '../../redux-slices/genericSlice'
import ClientNameWithMemberType from "components/summary/ClientNameWithMemberType";
import CheckPermissions from "utils/permissions";
import { PermissionEnum } from "utils/PermissionEnum";
import { filterBy } from "@progress/kendo-data-query";
import { ManageTaxesService } from "services/managetaxes/index.service";
import AdditionalPayment from "components/AdditionalPayment/AdditionalPayment";
import TermsAndConditions from "./TermsAndConditions";
import { defaultPaymentMethod } from "utils/constants";
import {
  DropDownList,
  DropDownListOpenEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import {
  billingPeriodItemRender,
  billingPeriodValueRender,
} from "components/Appointments/SessionSignOff";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { useApiClient } from "services/axios-service-utils";
import { shouldSetAsDefaultPaymentProfile } from "utils/utils";

export const addDates = (date: any, OcurrsEvery: number, unit: string) => {
  if (unit === "Daily") {
    let myDate = new Date(date);
    myDate.setDate(myDate.getDate() + OcurrsEvery);
    return myDate;
  } else {
    let myDate = new Date(date);
    myDate.setMonth(myDate.getMonth() + OcurrsEvery);
    return myDate;
  }
};
export const initialAdditionalPayment={
  dialog:false,
  selectedPaymentProfile:null
}

const Checkout = (props: any) => {
  const {axiosRequest}=useApiClient()
  const ComplimentaryPermission=CheckPermissions(PermissionEnum["ComplimentaryCheckout"])
  const redirectionObj = useSelector((state:any)=>state?.redirection)
  const history = useHistory();
  let staffPage = false;
  if (props?.location?.state?.staffPage || props?.staffPage) {
    staffPage = true;
  }
  let checkoutType = props.page
    ? props.page === "package"
      ? "inhouse-package"
      : "inhouse-class"
    : props?.location?.state?.session?.length > 0
    ? "online-class"
    : props?.location?.state?.package
    ? "online-package"
    : "service";
  const providerId = props.match?.params?.providerId;

  const [guestLayout] = useState<boolean>(
    providerId ? true : false
  );
  const sessionItem = props.page
    ? props.session
      ? props.session
      : []
    : props?.location?.state?.session?.length > 0
    ? props?.location?.state?.session?.length > 0
      ? props?.location?.state?.session
      : []
    : [];
  const packageItem = props.page
    ? props.package
      ? props.package
      : null
    : props?.location?.state?.package
    ? props?.location?.state?.package
    : null;

  const dispatch = useDispatch()
  const [submit, setSubmit] = useState(false);
  const [submittedClassSessions, setSubmittedClassSessions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [packageListToUse, setPackageListToUse] = useState([]);
  const [groupSelectedDates, setGroupSelectedDates] = useState([]);
  const [PackagesData, setPackagesData] = useState<any>([]);
  const [Taxes, setTaxes,TaxesRef] = useState<any>([]);
  const [PredictedTaxes, setPredictedTaxes] = useState<any>([]);
  const [session, setSession,sessionref] = useState(sessionItem);
  const [totalDiscountAmt,settotalDiscountAmt] = useState(0);
  console.log('sessionItem',session);
  const [packageSelection, setPackageSelection, packageSelectionRef] =
  useState<any>({
    dataItem: null,
    SelectedBillingPeriod: null,
  });
const [selectedAppt, setSelectedAppt, selectedApptRef] = useState<any>({
  BillingPeriod: null,
  Package: null,
});


  const [totalClassSessions, setTotalClassSessions] = useState(0);
  const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
  const [paymentGatewayType] =
    useState<any>(paymentGatewaytype);

  // to get the logged client details
  const userDetails = GetLocalStore("userDetails");
  // console.log(userDetails)


  const [confirmClientDialog, setConfirmClientDialog] = useState(false);
  const [purchaseCheckout, setPurchaseCheckout] = useState<any>({
    page:'',
    params:null
  });

  const [disable, setDisable] = useState(false);


  //base64 encode signature
  const [signature, setSignature] = useState("");

  const [signatureText, setSignatureText] = useState<any>("");
  const [signatureFont, setSignatureFont] = useState<any>("");
  const [signatureType, setSignatureType] = useState<any>("");

  //dirty Check
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const [IsTaxSkipped, setIsTaxSkipped] = useState<boolean>(true);
  const [TotalTaxAmount, setTotalTaxAmount] = useState<number>(0);
  const [PredictedTotalTaxAmount, setPredictedTotalTaxAmount] = useState<number>(0);

  //classes terms and conditions
  const [termsandConditions, setTermsandConditions] = useState<boolean>(true);
  const [termsandConditionsDialog, setTermsandConditionsDialog] =
    useState<boolean>(false);

  //for buzops button loading for paynow and build agreement
  const [btnLoading, setBtnLoading] = useState(false);

  //for buzops button loading for coupon
  const [couponBtnLoading, setCouponBtnLoading] = useState(false);

  //for buzops button loading for card submit
  const [cardSubmitBtnLoading, setCardSubmitBtnLoading] = useState(false);

  //for buzops button loading for client create submit

  const [clientSubmitBtnLoading, setClientSubmitBtnLoading] = useState(false);
  const [clientSearchLoading, setClientSearchLoading] = useState(false);

  //for buzops button loading for bank submit
  const [bankSubmitBtnLoading, setBankSubmitBtnLoading] = useState(false);
  const [customRate, setCustomRate] = useState(0);

  //to auto populate latest submitted payment profile
  const [populateProfile, setPopulateProfile] = useState<any>(null);
  let clientMemberId;
  if (checkoutType === "online-class") {
    clientMemberId =
      props?.location?.state?.userData?.UserMemberId ||
      userDetails?.UserSession?.UserMemberId;
  } else if (props?.UserMemberId) {
    clientMemberId = props?.UserMemberId;
  }
  let userSessionUserId: any;
  if (staffPage || props?.location?.state?.from === "calender") {
    const tenantUserSession = GetLocalStore("tenantUserSession");
    userSessionUserId = tenantUserSession?.UserId;
  }

  // to get User Member Id
  const [userMemberId, setUserMemberId] = useState<any>(
    clientMemberId || undefined
  );

  //visible coupon fields
  const [visibleCouponField, setvisibleCouponField] = useState(false);
  const [showAdditionalPayment, setShowAdditionalPayment] = useState<any>(initialAdditionalPayment);
  
  const [showPackageDialog, setShowPackageDialog] = useState(false);

 // to show payment profile type
  const [paymentMethod, setPaymentMethod] = useState(defaultPaymentMethod);
  // to select clientList AutoComplete
  const [clientsList, setClientsList] = useState<any>([]);
  const [AllClientsList, setAllClientsList] = useState<any>([]);
  // to select client Dropdown
  const [selectedClient, setSelectedClient] = useState<any>({
    type: "search",
    value: "",
  });
  const [showCheckout, setshowCheckout] = useState(false);

  // to expand and collpase panel bar items
  let panelbarItem = "4";
  if (checkoutType === "online-class") {
    panelbarItem = "2";
  }
  const [selected, setSelected] = useState<any>(panelbarItem);

  //selected payment profile
  const [selectedPaymentProfile, setSelectedPaymentProfile] = useState<any>({
    selectedPayment: undefined,
    AlternatePaymentProfileId: null,
  });

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  // entered coupon text
  const [coupon, setCoupon] = useState("");

  //captured coupon details
  const [couponDetails, setCouponDetails] = useState<any>({});

  const [appliedCoupons, setAppliedCoupons] = useState<any>([]);

  //selected coupon amount
  const [couponAmount, setCouponAmount] = useState(0);

  //selected sales person
  const [selectedSalesPerson, setSelectedSalesPerson] =
    useState<any>(undefined);

  const [salesPersons, setSalesPersons] = useState<any>([]);

  //package cost after discount
  const [packageCostAfterDiscount, setPackageCostAfterDiscount] = useState(0);
  const [useCreditAmount, setUseCreditAmount] = useState(0);
  const [useComplimentaryAmount, setUseComplimentaryAmount] = useState(0);
  const [payLaterAmount, setPayLaterAmount] = useState(0);

  //purcahse cost for individual  service
  const [packageCost, setPackageCost,packageCostRef] = useState(0);

  const [showPackageError,setShowPackageError]=useState(false)

  //saved payment profiles
  const [paymentProfile, setPaymentProfile] = useState([]);
  // down payment initial form values
  const InstallmentDate = addDates(new Date(), 1, "Months");

  const downPaymentInitialValues = {
    paidToday: 0,
    recurrenceUnit: { id: 4, text: "Months" },
    recurringStartDate: new Date(InstallmentDate),
    OcurrsEvery: 1,
    installments: 1,
  };

  const [downpaymentForm, setDownpaymentForm,downpaymentFormRef] = useState(
    downPaymentInitialValues
  );

  //  payment profile card initial form values
  const cardIntialValues = {
    MaskedCCNumber: undefined,
    CardTypeId: undefined,
    ExpiryMonthYear: undefined,
    ExpiryMonth: undefined,
    ExpiryYear: undefined,
    ZipCode: undefined,
    PaymentType: "Card",
    UserMemberId: userMemberId,
  };
  const [cardFormValues, setCardFormValues] = useState<any>(cardIntialValues);

  // credit cards image placeholder
  const [cardImageIcon, setcardImageIcon] = useState(images.placeholder);
  const [coupondata, setCouponData] = useState<any>([]);
  const [filterCoupondata, setfilterCouponData] = useState<any>([]);

  //  payment profile bank initial form values
  const bankIntialValues = {
    AccountName: undefined,
    BankAccountType: undefined,
    BankName: undefined,
    RoutingNumber: undefined,
    AccountNumber: undefined,
    UserMemberId: userMemberId,
    PaymentType: "Bank",
  };
  const [bankFormValues, setBankFormValues] = useState<any>(bankIntialValues);

  //client details
  const [clientFormValues, setClientFormValues] = useState<any>({});

  //To client details to submit
  const [clientSubmitDetails, setClientSubmitDetails] = useState<any>({});
  const tenantService = new TenantService();
  //payment profiles to submit
  const [paymentSubmitProfile, setPaymentSubmitProfile] = useState<any>({});

  // to get all initial data while loading
  useEffect(() => {
    fetchAllApis();
  }, [props?.location]);

  useEffect(() => {
    if (groupSelectedDates?.length > 0) {
      if (checkoutType === "inhouse-class") {
        fetchPackagesToUse(userMemberId);
      }
    }
  }, [userMemberId, groupSelectedDates]);

  const handleSummaryRedirection = (obj: any = {}) => {
    const { firstPathValue, staffuuid, clientMemberId } = redirectionObj;
    if (firstPathValue === "member") {
      history.push(`/${firstPathValue}/${staffuuid}/${clientMemberId}`, obj);
    } else if (firstPathValue === "external") {
      history.push(`/${firstPathValue}/summary`, obj);
    } else {
      history.push(`/summary`, obj);
    }
  };
  const handleHomeRedirection=(obj:any={})=>{
    const { firstPathValue, staffuuid, clientMemberId } = redirectionObj
    if (firstPathValue === 'member') {
      history.push(`/${firstPathValue}/${staffuuid}/${clientMemberId}`, obj);
    } else if (firstPathValue === 'external') {
      history.push(`/${firstPathValue}`, obj);
    } else {
      history.push(`/home`, obj);
    }
  }

  const handleTaxCalculation=(purchaseAmt:number,TaxItems=Taxes,Prediction=false)=>{
    const TaxesArray=TaxItems.map((i:any)=>{
      const amount=purchaseAmt*i?.Percentage/100;
      const calcAmt=CustomToFixed(amount,2)
        return {
          "TaxId": i?.TaxId,
          "TaxName": i?.TaxName,
          "Percentage": i?.Percentage,
          "Amount": calcAmt
        }
    })
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
          return acc + currentValue?.Amount
    }, 0);
    if(Prediction){
      setPredictedTaxes(TaxesArray)
      setPredictedTotalTaxAmount(taxAmt)
    }else{
      setTaxes(TaxesArray)
      setTotalTaxAmount(taxAmt)
    }
  }
  const constructTaxArray=(taxableAmt:any,TaxItems=TaxesRef?.current)=>{
    const TaxesArray=TaxItems.map((i:any)=>{
      const amount=taxableAmt*i?.Percentage/100;
      const calcAmt=CustomToFixed(amount,2)
        return {
          "TaxId": i?.TaxId,
          "TaxName": i?.TaxName,
          "Percentage": i?.Percentage,
          "Amount": calcAmt
        }
    })
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
          return acc + currentValue?.Amount;

    }, 0);

    return {taxAmt,TaxesArray}
  }
  const handleSessionTaxCalculation=(sessionData=session,TaxItems=Taxes,updatedPkListToUse=packageListToUse)=>{
    const updatedSessionData=sessionData.map((i:any)=>{
      if(i?.IsTaxSkipped || !i?.CanCollectTaxes){
        const itemValues=i?.values?.map((j:any)=>{
          return {...j,TotalTaxAmount:0,PredictedTotalTaxAmount:0,PredictedTaxes:[],Taxes:[],TotalTaxableAmount:0,PredictedTotalTaxableAmount:0}

        })
        return {...i,values:itemValues,TotalTaxAmount:0,PredictedTotalTaxAmount:0,PredictedTaxes:[],Taxes:[],TotalTaxableAmount:0,PredictedTotalTaxableAmount:0}
      }else{
        let classTaxableAmt=0
        let predictedclassTaxableAmt=0
        const itemValues=i?.values?.map((j:any)=>{
          let taxableAmt=j?.Cost
          let predictedTaxableAmt=j?.Cost
          const checkUseCreditVal = checkUseCredit(j,null,updatedPkListToUse);
          const checkComplimentary =j?.Complimentary
          if (checkUseCreditVal || checkComplimentary) {
            taxableAmt= 0;
            predictedTaxableAmt= 0;
          } else if (
            j?.PaymentOption === 2 ||
            j?.PaymentOption === 3  || j?.CanBookAsWaitlist
          ) {
            taxableAmt= 0;
            predictedTaxableAmt= j?.Cost - (j?.CouponAmount || 0);
          } else {
            taxableAmt= j?.Cost - (j?.CouponAmount || 0);
            predictedTaxableAmt= j?.Cost - (j?.CouponAmount || 0);
          }
   
          console.log(predictedTaxableAmt,'predictedTaxableAmt')
          console.log(taxableAmt,'taxableAmt')
          const taxableItem = constructTaxArray(taxableAmt)
          const predictedTaxableItem = constructTaxArray(predictedTaxableAmt)
          classTaxableAmt+=taxableAmt
          predictedclassTaxableAmt+=predictedTaxableAmt
          console.log(taxableItem,'taxableItem')
          console.log(predictedTaxableItem,'predictedTaxableItem')
          return {...j,TotalTaxAmount:taxableItem?.taxAmt,Taxes:taxableItem?.TaxesArray,TotalTaxableAmount:taxableAmt,PredictedTotalTaxAmount:predictedTaxableItem?.taxAmt,PredictedTaxes:predictedTaxableItem?.TaxesArray,PredictedTotalTaxableAmount:predictedTaxableAmt}
        })
        const ClassTaxableItem = constructTaxArray(classTaxableAmt)
        const PredictedClassTaxableItem = constructTaxArray(predictedclassTaxableAmt)
        return {...i,values:itemValues,TotalTaxAmount:ClassTaxableItem?.taxAmt,Taxes:ClassTaxableItem?.TaxesArray,TotalTaxableAmount:classTaxableAmt,PredictedTotalTaxAmount:PredictedClassTaxableItem?.taxAmt,PredictedTaxes:PredictedClassTaxableItem?.TaxesArray,PredictedTotalTaxableAmount:predictedclassTaxableAmt}
      }
    })
    console.log(updatedSessionData,'updatedSessionData')
    setSession(updatedSessionData)
  }

  const calculateTotalSessionTax = (prediction=false) => {
    const taxAmt = session?.reduce((acc: number, currentValue: any) => {
      console.log(currentValue,'currentValue')
      if(currentValue?.IsTaxSkipped){
        return acc
      }
      if(!prediction){
        return acc + CustomToFixed(currentValue?.TotalTaxAmount,2);

      }else{
        return acc + CustomToFixed(currentValue?.PredictedTotalTaxAmount,2);
      }
    }, 0);
    console.log(taxAmt,'taxAmt')
    return taxAmt
  }

  const getAllSubMembers = async (memberId: any) => {
    let selectedUserMemberId = memberId;
    let parentMemberId = memberId;
    if (props?.location?.state?.userData?.PrimaryMemberId) {
      parentMemberId = props?.location?.state?.userData?.PrimaryMemberId;
    }
    const res = await tenantService.GetSubMembersWithParent(parentMemberId);
    setAllClientsList(res);
    if (!staffPage && userDetails && !sessionItem) {
      selectedUserMemberId = parseInt(
        userDetails?.UserSession?.AdditionalAttributes?.LogedInMemberId ||
          memberId
      );
    }
    const selUserData = getCurrentClientData(res, selectedUserMemberId);
    updateSelectedClientData(selUserData);
    return selUserData?.ParentId || selUserData?.UserMemberId;
  };
  const fetchAllApis = async () => {
    setLoading(true);
    let memberId = userMemberId;
    let primaryMemberId = userMemberId;
    if (memberId) {
      const res = await getAllSubMembers(memberId);
      primaryMemberId = res;
    }
    if (staffPage) {
      await fetchSalesPerson();
    }
    if (checkoutType === "online-class") {
      const result = await fetchTaxes();
      await fetchClassData(session, true, result);
      if (props?.location?.state?.from !== "calender") {
        await fetchPaymentProfiles(primaryMemberId);
      }
      if(staffPage){
        for (let i = 0; i < session.length; i++) {
          await fetchCoupons(primaryMemberId, 'class', session[i]?.ClassId, i);
        }
      }
    } else {
      const result = await fetchTaxes();
      await fetchClassData(session, true, result);
      if (!userMemberId) {
        setSelected("1");
      }
      if (primaryMemberId) {
        await fetchPaymentProfiles(primaryMemberId);
      }
      if(staffPage){
        for (let i = 0; i < session.length; i++) {
          await fetchCoupons(primaryMemberId, 'class', session[i]?.ClassId, i);
        }
      }
    }
    // This needs to be set because `CouponApplyField` in version 1.0 in Client login has a re-rendering issue when props update the value.
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const checkBillingCycleValidation = (billingPeriodData: any) => {
    const records = billingPeriodData?.filter(
      (i: any) => i?.SchedulesToUse > 0
    );
    if (records?.length > 0) {
      return true;
    }
    return false;
  };

  const fetchPackagesToUse = async (
    memberId: any,
    dates = groupSelectedDates,
    sessionItem = session
  ) => {
    if(!memberId){
      return
    }
    const startDate = moment(dates[0]).format("YYYY-MM-DD");

    const FromDate = moment(startDate).format("YYYY-MM-DD") + "T00:00:00";
    const ToDate = moment(dates[dates?.length - 1]).format("YYYY-MM-DD") + "T23:59:59";

    const IsPastDate = moment(FromDate).isBefore(moment(), 'day');
    const req = {
      UserMemberId: memberId,
      FromDate,
      ToDate,
      PackageType: "Class",
      IsQuickSignOff: props?.SessionSignOff ? true : undefined,
      IsPastDate
    };

    const service = new PackageService();
    const resValue = await service.packageInstanceToUse(req);
    const result = resValue?.filter((i: any) => {
      if(i?.Status === PackageInstanceStatusEnum.ACTIVE || i?.Status === PackageInstanceStatusEnum.FUTUREPAID){
        
        if (i?.SessionType !== SessionTypeEnum.PRESET) {
          if (i?.BillingPeriods?.length > 0) {
            if (i?.SessionType === SessionTypeEnum.UNLIMITED) {
              return true;
            }
            return checkBillingCycleValidation(i?.BillingPeriods);
          }
          return i?.SchedulesToUse > 0;
        } else {
          return i?.SchedulesToUse > 0;
        }
      }
      return false
    });

    if (
      (!props?.SessionSignOff ||
        (checkoutType === "inhouse-class" && props?.SessionSignOff)) &&
      result?.length > 0
    ) {
      const resultItem = result?.map((j: any) => {
        let PackageExpireOn = j?.PackageExpireOn;
        if (PackageExpireOn) {
          PackageExpireOn = moment(PackageExpireOn, "MM/DD/YYYY hh:mm:ss A")
            .subtract(1, "days")
            .format("MM/DD/YYYY hh:mm:ss A");
        }
        return { ...j, PackageExpireOn };
      });

      const newArray = result?.map((j: any) => {
        let PackageExpireOn = j?.PackageExpireOn;
        if (PackageExpireOn) {
          PackageExpireOn = moment(PackageExpireOn, "MM/DD/YYYY hh:mm:ss A")
            .subtract(1, "days")
            .format("MM/DD/YYYY hh:mm:ss A");
        }
        return { ...j, ClassSessions: [], PackageExpireOn };
      });

      const newSessionArray = sessionItem?.map((item: any, index: number) => {
        const SessionCount = item.values.length;
        let IsTaxSkipped = true;
        const modifiedValues = item?.values.map((j: any) => {
          const PackagesList = resultItem?.filter((i: any) => {
            const billingPeriod = findBillingPeriod(startDate, i.BillingPeriods);
            const IsPastDate = moment(startDate).isBefore(moment(), 'day');

            const { ExpireFrom, ExpireOn } = IsPastDate && billingPeriod !== undefined
              ? billingPeriod
              : i;

            if (
              (i.ClassIds.includes(j?.ClassId) || i.ClassIds.includes(-1)) &&
              j?.Cost > 0
            ) {
              if (props?.SessionSignOff) {
                return true;
              } else if (
                moment(j?.ClassSessionStartDate).format("YYYY-MM-DD") >=
                  moment(ExpireFrom).format("YYYY-MM-DD") &&
                moment(j?.ClassSessionStartDate).format("YYYY-MM-DD") <=
                  moment(ExpireOn).format("YYYY-MM-DD")
              ) {
                return true;
              }
            }
          });
          IsTaxSkipped = true;
          if (j?.CanCollectTaxes && checkHasTaxEnable()) {
            IsTaxSkipped = false;
          }

          return {
            ...j,
            SelectedPackage: j?.SelectedPackage ? j?.SelectedPackage : null,
            PackagesList,
            Complimentary: false,
            IsTaxSkipped,
          };
        });
        return { ...item, SessionCount, values: modifiedValues, IsTaxSkipped };
      });
      handleSessionTaxCalculation(newSessionArray);
      setPackageListToUse(newArray);
      if (props?.SessionSignOff) {
        const filteredData = resultItem?.filter((i: any) => {
          if (
            i.ClassIds.includes(sessionItem[0]?.ClassId) ||
            i.ClassIds.includes(-1)
          ) {
            return true;
          }
        });
        setPackagesData(filteredData || []);
        if (filteredData?.length > 0) {
          setShowPackageDialog(true);
        }
      } else {
        setPackagesData(resultItem || []);
      }
      if (
        checkoutType === "inhouse-class" &&
        newArray?.length > 0 &&
        !props?.SessionSignOff
      ) {
        await handleAutoselectPackage(newSessionArray, newArray);
      }
    } else {
      const newSessionArray = sessionItem?.map((item: any, index: number) => {
        const SessionCount = item.values.length;
        let IsTaxSkipped = true;
        const modifiedValues = item?.values.map((j: any) => {
          const PackagesList: any = [];
          IsTaxSkipped = true;
          if (j?.CanCollectTaxes && checkHasTaxEnable()) {
            IsTaxSkipped = false;
          }

          return {
            ...j,
            SelectedPackage: null,
            PackagesList,
            Complimentary: false,
            IsTaxSkipped,
          };
        });
        return { ...item, SessionCount, values: modifiedValues, IsTaxSkipped };
      });
      handleSessionTaxCalculation(newSessionArray);
      setPackageListToUse([]);
      setPackagesData([]);
    }
  };

  const handleIsSessionTaxSkipped = (value: boolean, dataItem: any) => {
    const updatedSessionData = session.map((i: any) => {
      if (i?.ClassId === dataItem?.ClassId) {
        return { ...i, IsTaxSkipped: value };
      }
      return { ...i };
    });
    handleSessionTaxCalculation(updatedSessionData);
  };
  const fetchSalesPerson = async () => {
    const service = new TenantService();
    const includeAffiliates = staffPage ? false : true;
    const res = await service.SalesPerson(includeAffiliates);
    setSalesPersons(res || []);
    if (userSessionUserId) {
      const salesPersonRecord = res?.find(
        (item: any) => item?.UserId === userSessionUserId
      );
      if (salesPersonRecord) {
        setSelectedSalesPerson(salesPersonRecord);
      }
    }
  };

  // after submission of card or bank need to update payment profile with latest one
  useEffect(() => {
    const { selectedPayment } = selectedPaymentProfile;
    const { bank, card } = paymentMethod;

    if (
      populateProfile &&
      !selectedPayment &&
      paymentProfile &&
      !bank &&
      !card &&
      paymentProfile?.length > 0
    ) {
      const index = paymentProfile.findIndex(
        (i: any) =>
          i?.PaymentGatewayPaymentProfileID ===
          populateProfile?.PaymentGatewayPaymentProfileID
      );
      if (index !== -1) {
        const selectPayment: any = paymentProfile[index];
        setSelectedPaymentProfile({
          ...selectedPaymentProfile,
          selectedPayment: selectPayment,
        });
        generatePaymentProfileObj(selectPayment);
      }
    }
  }, [paymentProfile]);

  // to handle Apply Coupon text and button hide and show
  const handleVisibleCouponField = () => {
    setvisibleCouponField(!visibleCouponField);
  };

  const toggleClientConfirmDialog = () => {
    setConfirmClientDialog(!confirmClientDialog);
  };

  // handle client selection for class
  const handleClientSelecton = (e: any) => {
    if (e.value.isButton) {
      return;
    }

    if (e.value === "") {
      setSelectedClient({
        ...selectedClient,
        type: "select",
        value: e.value,
      });
      updateSelectedClientData(e.value);
      //setClientpopup(false);
      return;
    }
    if (e.syntheticEvent?.key === "Enter") {
      if (clientsList?.length > 0) {
        setSelectedClient({
          ...selectedClient,
          type: "select",
          value: clientsList[0],
        });
      }
      return true;
    }
    setSelectedClient({
      ...selectedClient,
      type: "search",
      value: e.value,
    });
    if (e.syntheticEvent?.type === "change" && e.value.length >= 3) {
      autoCompleteClients(e.value);
    }
    if (e.syntheticEvent?.type === "click") {
      handleChangeClient();
      setSelectedClient({
        ...selectedClient,
        type: "select",
        value: e.value,
      });
      updateSelectedClientData(e.value);
      if (selected === "0") {
        setSelected(undefined);
      } else {
        setSelected("2");
      }
    }
  };
  async function autoCompleteClients(str: string) {
    const req = {
      Where: {
        SearchKeyword: str,
        IsGloablSearch: true,
      },
    };
    setClientSearchLoading(true);
    const clientservice = new ClassService();
    const result = await clientservice.autoCompleteClients(req);
    setClientSearchLoading(false);
    setClientsList(result);
  }

  const calculateCost = (arrayItem: any, check: boolean, ignore = false) => {
    const cost = arrayItem?.reduce((acc: Number, currentValue: any) => {
      if (check) {
        return acc + currentValue?.Cost;
      } else {
        const checkUseCreditVal = checkUseCredit(currentValue);
        const checkComplimentary = currentValue?.Complimentary;
        if (!ignore && (checkUseCreditVal || checkComplimentary)) {
          return acc;
        } else if (
          currentValue?.PaymentOption === 2 ||
          currentValue?.PaymentOption === 3 ||
          currentValue?.CanBookAsWaitlist
        ) {
          return acc;
        } else {
          return acc + currentValue?.Cost;
        }
      }
    }, 0);
    return cost;
  };
  const calculatePayLaterCost = (arrayItem: any, ignore = false) => {
    const cost = arrayItem?.reduce((acc: Number, currentValue: any) => {
      const checkComplimentary = currentValue?.Complimentary;
      const checkUseCreditVal = checkUseCredit(currentValue);

      if (!ignore && (checkUseCreditVal || checkComplimentary)) {
        return acc;
      } else {
        if (
          currentValue?.PaymentOption === 2 ||
          currentValue?.PaymentOption === 3 ||
          currentValue?.CanBookAsWaitlist
        ) {
          return acc + currentValue?.Cost;
        } else {
          return acc;
        }
      }
    }, 0);
    return cost;
  };
  const calculateSessionCount = (arrayItem: any) => {
    const cost = arrayItem?.reduce((acc: number, currentValue: any) => {
      return acc + 1;
    }, 0);
    return cost;
  };

  const handleRemoveSession = async (dataItem: any) => {
    const classList = dataItem;
    let oldClassData = JSON.parse(JSON.stringify(session));
    const totalSession = oldClassData.reduce(
      (acc: Number, currentValue: any) => {
        return acc + calculateSessionCount(currentValue.values);
      },
      0
    );
    if (totalSession > 1) {
      unCheckRedeem(dataItem); // added to unredeem the package and below to remove session
      if (classList) {
        if (oldClassData.length > 0) {
          const classIndex = oldClassData.findIndex(
            (val1: any) => val1?.className == classList?.ClassName
          );
          if (classIndex !== -1) {
            const classValueIndex = oldClassData[classIndex].values.findIndex(
              (val2: any) => val2.ClassSessionId === classList.ClassSessionId
            );
            if (classValueIndex !== -1) {
              oldClassData[classIndex].values.splice(classValueIndex, 1);
              if (oldClassData[classIndex].values.length === 0) {
                oldClassData.splice(classIndex, 1);
              }
            }
          }
        }
      }

      const complimentaryCoupons = dataItem?.Coupons?.map((c: any) => c.CouponId);

      oldClassData = oldClassData.map((sessionData: any) => {
        return {
          ...sessionData,
          values: sessionData.values.map((value: any) => {
            let updatedCoupons = value.filterCoupondata.map((coupon: any) => {
              if (complimentaryCoupons?.includes(coupon?.CouponId)) {
                return {
                  ...coupon,
                  AvailableCount: coupon.AvailableCount + 1, // Increase AvailableCount when removed
                };
              }
              return coupon;
            });

            return {
              ...value,
              filterCoupondata: updatedCoupons,
            };
          }),
        };
      });

      let discountAmount = totalDiscountAmt - classList?.CouponAmount || 0;
      handleSessionTaxCalculation(oldClassData)
      await fetchClassData(oldClassData,false,Taxes);
      settotalDiscountAmt(discountAmount);
      const downpayment = downpaymentFormRef?.current?.paidToday - discountAmount;
      setDownpaymentForm({
        ...downpaymentForm,
        paidToday: CustomToFixed(downpayment,2),
      });
    } else {
      const errorMsg = "Minimum one appointment is required to purchase";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
  };

  // to fetch selected service to checkout
  const fetchClassData = async (
    session: any,
    initial = true,
    taxes = Taxes,
    memberId = userMemberId
  ) => {
    let totalCost = 0;
    let allsessionCost = 0;
    let totalSessions = 0;
    let payLaterCost = 0;

    totalCost = session.reduce((acc: Number, currentValue: any) => {
      return acc + calculateCost(currentValue.values, true, initial);
    }, 0);
    allsessionCost = session.reduce((acc: Number, currentValue: any) => {
      return acc + calculateCost(currentValue.values, false, initial);
    }, 0);
    payLaterCost = session.reduce((acc: Number, currentValue: any) => {
      return acc + calculatePayLaterCost(currentValue.values, initial);
    }, 0);
    totalSessions = session.reduce((acc: Number, currentValue: any) => {
      return acc + calculateSessionCount(currentValue.values);
    }, 0);

    const dates: any = [];
    session.forEach((i: any) => {
      if (i?.values?.length > 0) {
        i?.values?.forEach((j: any) => {
          dates.push(moment(j?.ClassSessionStartDate).format("YYYY-MM-DD"));
        });
      }
    });

    setTotalClassSessions(totalSessions);
    setPackageCost(totalCost);
    setPayLaterAmount(payLaterCost);
    setPackageCostAfterDiscount(allsessionCost);
    setDownpaymentForm({ ...downpaymentForm, paidToday: allsessionCost });
    if (initial) {
      setGroupSelectedDates(dates?.sort());
      setUseCreditAmount(0);
      setUseComplimentaryAmount(0);
      await fetchPackagesToUse(memberId, dates?.sort(), session);
    }
  };
  const fetchTaxes = async () => {
    const req = {};
    const taxService = new ManageTaxesService();
    const res = await taxService.getTaxes(req);
    setTaxes(res);
    return res;
  };

  const getCurrentClientData = (allUsersData: any, userMemberId: any) => {
    return (
      allUsersData?.find((val: any) => val.UserMemberId === userMemberId) || {}
    );
  };

  // to get client details from api
  async function fetchClientData(userMemberId: any) {
    const clientservice = new TenantService();
    const result = await clientservice.getClient(userMemberId);
    if (result) {
      const clientIntialValues = {
        FirstName: result?.User?.FirstName,
        LastName: result?.User?.LastName,
        PhoneNumber: result?.User?.Address?.Phone,
        Email: result?.User?.Email,
      };
      const clientSubmitDetails = {
        Email: result?.User?.Email,
        FirstName: result?.User?.FirstName,
        LastName: result?.User?.LastName,
        PrimaryMemberId: result?.UserMemberId,
        UserId: result?.UserId,
        UserMemberId: result?.UserMemberId,
        MemberNumber: result?.MemberNumber,
      };
      setUserMemberId(result?.UserMemberId);
      setClientFormValues(clientIntialValues);
      setClientSubmitDetails(clientSubmitDetails);
    }
  }
  // to get client details from api
  async function updateSelectedClientData(userData: any) {
    const result = userData;
    if (result) {
      const clientIntialValues = {
        FirstName: result?.FirstName,
        LastName: result?.LastName,
        PhoneNumber: result?.Phone,
        Email: result?.Email,
        UserMemberId: result?.UserMemberId,
      };
      const clientSubmitDetails = {
        Email: result?.Email,
        FirstName: result?.FirstName,
        LastName: result?.LastName,
        PrimaryMemberId:
          result?.ParentId || result?.MainMemberId || result?.UserMemberId,
        UserId: result?.UserId,
        UserMemberId: result?.UserMemberId,
        MemberNumber: result?.MemberNumber,
        Status: result?.UserMemberStatus || result?.Status,
      };
      setUserMemberId(result?.UserMemberId);
      setClientFormValues(clientIntialValues);
      setClientSubmitDetails(clientSubmitDetails);
      await fetchClassData(sessionItem, true, Taxes, result?.UserMemberId);
      await fetchPaymentProfiles(clientSubmitDetails?.PrimaryMemberId);
      for (let i = 0; i < session.length; i++) {
        await fetchCoupons(clientSubmitDetails?.PrimaryMemberId, 'class', session[i]?.ClassId, i);
      }     
      setCardFormValues({
        ...cardFormValues,
        UserMemberId: clientSubmitDetails?.PrimaryMemberId,
      });
      setBankFormValues({
        ...bankFormValues,
        UserMemberId: clientSubmitDetails?.PrimaryMemberId,
      });
    } else {
      handleChangeClient();
    }
  }
  const filterCouponChange = (e: any,originalCouponData:any) => {
    console.log('couponfiled',e.filter.value);
    const filteredData = filterData(e.filter, originalCouponData);
    setfilterCouponData(filteredData);
    setSession((prevSession: any) =>
      prevSession.map((session: any) => ({
        ...session,
        values: session.values.map((value: any) => ({
          ...value,
         // filterText:e.filter.value,
          filterCoupondata: filteredData, 
        })),
      }))
    );
  };

  const handleGroupCoupon=(value:any,classSessionId:any,taxData=Taxes,couponText:any="")=>{
    let final_coup_data: any[] = [];
    const couponsList = value
    const couponFixed = couponsList.filter(
      (res: any) => res?.TypeDescription === "Fixed"
    );
    if (couponFixed.length > 0) {
      final_coup_data.push(couponFixed[couponFixed.length-1]);
    }
    const couponPercentage = couponsList.filter(
      (res: any) => res?.TypeDescription === "Percentage"
    );
    if (couponPercentage.length > 0) {
      final_coup_data.push(couponPercentage[couponPercentage.length-1]);
    }
    let totalCost = session.reduce((acc: Number, currentValue: any) => {
      return acc + calculateCost(currentValue.values, true);
    }, 0);
    let acceptLimitedCoupon = true
    // if(staffPage){
    //   totalCost=dataItem?.DiscountedCost
    // }
    // if(dataItem?.IsProrated){
    //   totalCost=dataItem?.ProratedCost
    //   acceptLimitedCoupon=false
    // }
    let totalCouponAmt=0;
    let offerAmt = 0;
    let cost = totalCost;
    let downpayment = totalCost;
    if (final_coup_data.length > 0) {
        final_coup_data =final_coup_data.map((item: any) => {
        if (item.TypeDescription === "Fixed") {
          let coupAmt=item.Amount
          if (item?.DurationType === CouponDurationTypeEnum.LIMITED && !acceptLimitedCoupon) {
            coupAmt=0
          }
          cost = cost <= coupAmt ? 0 : cost - coupAmt;
          offerAmt += coupAmt;
          return {
            ...item,
            couponAmt: coupAmt,
            classSessionId, 
          };
        } else if (item.TypeDescription === "Percentage") {
          let sessionCost=0;
          const session = sessionref?.current
          ?.flatMap((item: any) => item.values)
          ?.find((value: any) => value.ClassSessionId === classSessionId);
        
          sessionCost = session?.Cost - offerAmt || 0;
          const coupvalue=sessionCost * (item.Amount / 100)
          let coupAmt=parseFloat(coupvalue.toFixed(2))
          if (item?.DurationType === CouponDurationTypeEnum.LIMITED && !acceptLimitedCoupon) {
            coupAmt=0
          }
          cost = item.Amount === 100 ? 0 : cost - coupAmt;
          offerAmt += coupAmt;
          return {
            ...item,
            couponAmt: Math.abs(coupAmt),
            classSessionId, 
          };
        }
      });
      downpayment= downpayment <= offerAmt ? 0 : downpayment - offerAmt;
    }
    console.log('finalCouponData',final_coup_data);
    
    let removedCouponId:any = null;
    let updatedSession = sessionref?.current?.map((sessionItem: any) => {
      return {
        ...sessionItem,
        values: sessionItem.values.map((value: any) => {
          if (value.ClassSessionId === classSessionId) {
            const prevCouponIds:any = new Set(value.Coupons?.map((c: any) => c.CouponId) || []);
            const currentCouponIds = new Set(final_coup_data.map((c: any) => c.CouponId));

            if (currentCouponIds.size < prevCouponIds.size) {
              removedCouponId = [...prevCouponIds].find((id) => !currentCouponIds.has(id));
            }

            const totalCouponAmt = final_coup_data.reduce((acc: number, coupon: any) => {
              return acc + (coupon?.couponAmt || 0);
            }, 0) || 0;
    
            const applicableCouponAmt = Math.min(totalCouponAmt, value.Cost);
    
            const adjustedCost = value.Cost - applicableCouponAmt;
    
            return {
              ...value,
              Coupons: final_coup_data,
              CouponAmount: applicableCouponAmt,
              adjustedCost,
              CouponsText: couponText || null,
            };
          }
          return value;
        }),
      };
    });

    updatedSession = updatedSession?.map((sessionData: any) => {
      return {
        ...sessionData,
        values: sessionData?.values?.map((value: any) => {
          let updatedCoupons = value?.filterCoupondata?.map((coupon: any) => {
            let updatedCoupon = { ...coupon };
    
            const isCouponInSession = value.Coupons?.some((c: any) => c.CouponId === coupon.CouponId);
            const isCouponApplied = final_coup_data.some((data: any) => data.CouponId === coupon.CouponId);
    
            if (isCouponApplied) {
              updatedCoupon = {
                ...coupon,
                IsSelected: value.ClassSessionId === classSessionId || isCouponInSession, 
                AvailableCount: Math.max(0, coupon.AvailableCount - 1),
              };
            } else if (!isCouponInSession && coupon.CouponId === removedCouponId) {
              updatedCoupon = {
                ...coupon,
                IsSelected: false,
                AvailableCount: coupon.AvailableCount + 1, 
              };
            }
    
            return updatedCoupon;
          });
    
          return {
            ...value,
            filterCoupondata: updatedCoupons,
            OriginalCouponData:updatedCoupons,
          };
        }),
      };
    });
    
    
  
    const taxupdatedSession = updateSessionsWithTotalTaxableAmount(updatedSession);
    setSession(taxupdatedSession);
    
    //const packagecostAfterDiscount = CustomToFixed(cost,2);
    //setAppliedCoupons(final_coup_data)
    setCouponAmount(offerAmt);
    //setPackageCostAfterDiscount(packagecostAfterDiscount);

    let totalCostAfterCoupons = sessionref?.current?.reduce((acc: number, sessionItem: any) => {
      sessionItem.values.forEach((value: any) => {
        if (value.Complimentary || checkUseCredit(value) ||    value?.PaymentOption === 2 ||
        value?.PaymentOption === 3 ||
        value?.CanBookAsWaitlist) {
          acc+=0;
        }
        else if(value.CouponAmount === value.Cost){
          acc+=value.adjustedCost;
        }
        else if (value.adjustedCost) {
          acc += value.adjustedCost;
        } else {
          acc += value.Cost; 
        }
      });
      return acc;
    }, 0);

    
    totalCostAfterCoupons = totalCostAfterCoupons <= 0 ? 0 : totalCostAfterCoupons;
    downpayment = totalCostAfterCoupons;
    setPackageCostAfterDiscount(totalCostAfterCoupons);
    // let totalCouponAmount = packageCostAfterDiscount - totalCostAfterCoupons;
    // if(totalCouponAmount < 0){
    //   totalCouponAmount = 0;
    // }

    // settotalDiscountAmt(totalCouponAmount);
    const totalOfferAmount = updatedSession?.reduce(
      (sum: number, sessionItem: any) => 
        sum + sessionItem.values.reduce((innerSum: number, value: any) => innerSum + (value?.CouponAmount || 0), 0),
      0
    );

    settotalDiscountAmt(totalOfferAmount);
    

      setDownpaymentForm({
        ...downpaymentForm,
        paidToday: CustomToFixed(downpayment,2),
      });
      handleTaxCalculation(downpayment,taxData)
      handleTaxCalculation(downpayment,taxData,true)
  }

  const updateSessionsWithTotalTaxableAmount = (sessions: any) => {
    if (!Array.isArray(sessions)) return [];
  
    return sessions.map(session => {
      if (!session?.values || !Array.isArray(session.values) || !session?.Taxes || !Array.isArray(session.Taxes)) {
        return { ...session, TotalTaxableAmount: 0,PredictedTotalTaxableAmount:0};
      }
  
      let totalAdjustedCost = 0;
      let totalTaxAmount:any = 0;
  
      const isExempt = session.values.some((item: any) => item.Complimentary === true || checkUseCredit(item));
  
      if (isExempt) {
        totalTaxAmount = 0;
        totalAdjustedCost = 0;
      } else {
        totalAdjustedCost = session.values.reduce((sum: number, item: any) => {
          return sum + (item.CouponAmount === item.Cost ? 0 : item.Cost - item.CouponAmount);
        }, 0);
  
        session.Taxes.forEach((tax: any) => {
          const calculatedTaxAmount = (tax.Percentage / 100) * totalAdjustedCost;
          totalTaxAmount += CustomToFixed(calculatedTaxAmount,2);
        });
      }
  
      const updatedTaxes = session.Taxes.map((tax: any) => ({
        ...tax,
        Amount: isExempt ? 0 : CustomToFixed((tax.Percentage / 100) * totalAdjustedCost, 2),
      }));
  
      return {
        ...session,
        Taxes: updatedTaxes,
        PredictedTaxes: updatedTaxes,
        PredictedTotalTaxAmount: totalTaxAmount,
        PredictedTotalTaxableAmount: totalAdjustedCost + totalTaxAmount,
        TotalTaxAmount: totalTaxAmount,
        TotalTaxableAmount: totalAdjustedCost + totalTaxAmount,
      };
    });
  };
  




  const filterData = (filter: any, itemData: any) => {
    const data = itemData.slice();
    return filterBy(data, filter);
  };

  const handleAddPaymentMode=async(description:string)=> {
    setPaymentMethod(defaultPaymentMethod);
    await fetchPaymentProfiles(clientSubmitDetails?.PrimaryMemberId || userMemberId,false,description)
  }


  //to get the payment profiles
  async function fetchPaymentProfiles(
    userMemberId: any,
    clearAdditionPP = false,
    modeVal = ""
  ) {
    const paymentProfileData = new TenantService();
    const result = await paymentProfileData.paymentProfile(userMemberId);
    if (result) {
      if (modeVal) {
        const record = result?.find((i: any) => i?.CardTypeId === -1);
        if (record) {
          const data = {
            ...record,
            CardTypeId: -2,
            MaskedCCNumber: modeVal,
            CardDescription: modeVal,
            CardDescriptionWithoutExpiryDate: modeVal,
          };
          const res = [...result];
          res.push(data);
          setPaymentProfile(JSON.parse(JSON.stringify(res)));
          handleChangeSavedCard(data);
        }
      } else {
        setPaymentProfile(result);

        let selectedCard;
        const defaultPP = result.find((card: any) => card.IsDefault);
        const cardType1 = result.find(
          (card: any) => card.CardTypeId >= 1 && card.CardTypeId < 8
        );
        const cardType2 = result.find((card: any) => card.CardTypeId === 0);
        const cardType3 = result.find(
          (card: any) => card.CardTypeId === 8 && card.Credit > 0
        );
        if(!defaultPP){
          if (cardType1) {
            selectedCard = cardType1;
          } else if (cardType2) {
            selectedCard = cardType2;
          } else if (cardType3) {
            selectedCard = cardType3;
          } else {
            selectedCard = result.find((card: any) => card.CardTypeId === -1);
          }
        }else{
          selectedCard=defaultPP
        }

        if (selectedCard) {
          handleChangeSavedCard(selectedCard);
        }
      }
    }
    if (clearAdditionPP) {
      return result;
    }
    setTermsandConditions(true);
  }
  //to get the package Coupons
  async function fetchCoupons(userMemberId: any,type:string,EntityId:any, index: number) {
    const service = new TenantService();
    let req;
    if(type==="package"){
      req = {
        UserMemberId: userMemberId,
        EntityType: 7,
        EntityId: EntityId,
        CouponType:1
      };
    }else{
      req = {
        UserMemberId: userMemberId,
        EntityId: EntityId ? EntityId:0,
        EntityType: type === "class" ? 3 : 6,
        CouponType:1
      };
    }
    const result = await service.AvailableCouponsToApply(req);
    if (result) {
      setCouponData(result);
      setfilterCouponData(result);
      setSession((prevSession: any) =>
        prevSession.map((sessionItem: any, i: number) =>
          i === index
            ? {
                ...sessionItem,
                values: sessionItem.values.map((value: any) => ({
                  ...value,
                  CouponAmount:0,
                  filterCoupondata: result,
                  OriginalCouponData:result,
                })),
              }
            : sessionItem
        )
      );
    }
  }

  // to handle credit card image icon
  const handleCardImageIcon = (imageUrl: any) => {
    setcardImageIcon(imageUrl);
  };


  //to saving signature
  const generatedImage = (
    imageString: string,
    text?: string,
    font?: string,
    Type?:string
  ) => {
    setIsFormChanged(true);
    setSignature(imageString);
    setSignatureText(text);
    setSignatureFont(font);
    setSignatureType(Type || "");
  };


  //to update client details
  const handleClientSubmit = async (dataItem: any) => {
    setClientFormValues(dataItem);
    if (selected === "0") {
      setSelected(undefined);
    } else {
      setSelected("2");
    }
  };

  //to update client details
  const handleChangeClient = () => {
    setClientFormValues({});
    setClientsList([]);
    setClientSubmitDetails({});
    setPaymentProfile([]);
    setPaymentSubmitProfile({});
    setSelectedPaymentProfile({
      selectedPayment: undefined,
      AlternatePaymentProfileId: null,
    });
    setSelectedClient({
      type: "search",
      value: "",
    });
    setSelectedAppt({
      BillingPeriod: null,
      Package: null,
    })
    setPackageSelection({
      dataItem: null,
      SelectedBillingPeriod: null,
    })
    setUserMemberId(null);
    setPackagesData([]);
    setPackageListToUse([]);
    let totalCost = 0;
    let allsessionCost = 0;
    let totalSessions = 0;
    let payLaterCost = 0;
    totalCost = sessionItem.reduce((acc: Number, currentValue: any) => {
      return acc + calculateCost(currentValue.values, true, true);
    }, 0);
    allsessionCost = sessionItem.reduce((acc: Number, currentValue: any) => {
      return acc + calculateCost(currentValue.values, false, true);
    }, 0);
    payLaterCost = sessionItem.reduce((acc: Number, currentValue: any) => {
      return acc + calculatePayLaterCost(currentValue.values, true);
    }, 0);
    totalSessions = sessionItem.reduce((acc: Number, currentValue: any) => {
      return acc + calculateSessionCount(currentValue.values);
    }, 0);

    const dates: any = [];
    sessionItem.forEach((i: any) => {
      if (i?.values?.length > 0) {
        i?.values?.forEach((j: any) => {
          dates.push(moment(j?.ClassSessionStartDate).format("YYYY-MM-DD"));
        });
      }
    });

    const updatedSession = sessionItem.map((item:any) => ({
      ...item,
      TotalTaxAmount: 0,
      PredictedTotalTaxAmount: 0
  }));
  
  

    setTotalClassSessions(totalSessions);
    setPackageCost(totalCost);
    setPayLaterAmount(payLaterCost);
    setPackageCostAfterDiscount(allsessionCost);
    setDownpaymentForm({ ...downpaymentForm, paidToday: allsessionCost });
    setUseCreditAmount(0);
    setUseComplimentaryAmount(0);
    setSession(updatedSession);
    settotalDiscountAmt(0);
  };

  const makePaymentProfileAsDefault = async (dataItem: any) => {
    const req = {
      PaymentGatewayPaymentProfileID: dataItem?.PaymentGatewayPaymentProfileID,
      UserMemberId: clientSubmitDetails?.PrimaryMemberId || userMemberId,
      CustomerProfileID:
        dataItem?.CustomerProfileID,
    };
    await axiosRequest.post(
      `PaymentProfile/update-preferred-payment-profile`,
      req,
      {
        successCallBack: async (response: any) => {
          props?.handleNotificationMessage(
            `Selected Payment profile marked as Preferred`,
            "success"
          );
          await fetchPaymentProfiles(clientSubmitDetails?.PrimaryMemberId || userMemberId);
        },
        errorCallBack: (response: any) => {
          props?.handleNotificationMessage(
            response?.response?.data?.Messages?.[0] ||
              response?.data?.Messages?.[0] ||
              "Internal server error",
            "error"
          );
        },
      }
    );
  };


  const handleAddClient = async (dataItem: any) => {
    const req = {
      FirstName: dataItem?.FirstName,
      LastName: dataItem?.LastName,
      Phone: dataItem?.PhoneNumber,
      Email: dataItem?.Email,
      SalesPersonId: 0,
      UserId: dataItem?.UserId,
      ReferredFrom: dataItem?.ReferredFrom?.ReferredFrom,
      SpecifyOthers: dataItem?.ReferredFrom?.ReferredFrom==="Other"? dataItem?.SpecifyOthers:null,
      Gender:dataItem?.Gender?.id,
      GenderSpecifyOthers:dataItem?.Gender?.id === GenderTypeEnum.Others ? dataItem?.GenderSpecifyOthers : null,
      IsPrimaryClub:dataItem?.IsPrimaryClub,
    };
    setClientSubmitBtnLoading(true);
    const checkoutClass = new ClassService();
    const result = await checkoutClass.addClient(req);
    if (result?.ResponseCode === 100) {
      const userMemberId = result?.Item?.UserMemberId;
      await fetchClientData(userMemberId);
      await fetchPaymentProfiles(userMemberId);
      const addedClient = {
        Email: result?.Item.Email,
        MemberNumber: result?.Item.MemberNumber,
        FullName: `${dataItem.FirstName} ${dataItem.LastName}`,
        MainMemberId: result?.Item.UserMemberId,
        UserId: result?.Item.UserId,
        UserMemberId: result?.Item.UserMemberId,
        FirstName: result?.Item.FirstName,
        LastName: result?.Item.LastName,
      };
      setSelectedClient({
        ...selectedClient,
        type: "select",
        value: addedClient,
      });
      setCardFormValues({ ...cardFormValues, UserMemberId: userMemberId });
      setBankFormValues({ ...bankFormValues, UserMemberId: userMemberId });
      const successMsg = "Client Added Successfully";
      props?.handleNotificationMessage(successMsg, "success");
    } else {
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
    setClientSubmitBtnLoading(false);
    if (selected === "0") {
      setSelected(undefined);
    } else {
      setSelected("2");
    }
  };

  // handle payment profile submit
  const handleSubmit = (dataItem: any = null) => {
    if (selected === "0") {
      setSelected(undefined);
    } else {
      setSelected("3");
    }
  };
  // handle UseCreditSubmit submit
  const handleUseCreditSubmit = () => {
    if (downpaymentForm?.paidToday > 0) {
      setSelected("2");
    } else {
      setSelected("3");
    }
  };
  const handleSalesPersonSelection = (dataItem: any) => {
    setSelectedSalesPerson(dataItem);
  };

  const handleTermsConditions = (val: boolean) => {
    setTermsandConditions(val);
    setTermsandConditionsDialog(false);
  };

  const handleIsTaxSkipped = () => {
    setIsTaxSkipped(!IsTaxSkipped);
  };

  const handleClientSelectionSubmit = async (e: any) => {
    e.preventDefault();
    if (selectedClient?.type === "select") {
      setClientSubmitBtnLoading(true);
      const userMemberId = selectedClient?.value?.UserMemberId;
      const mainMemberId = selectedClient?.value?.MainMemberId;
      await fetchClientData(userMemberId);
      await fetchClassData(sessionItem, true, Taxes, userMemberId);
      await fetchPaymentProfiles(mainMemberId);
      await setClientSubmitBtnLoading(false);
      setCardFormValues({ ...cardFormValues, UserMemberId: userMemberId });
      setBankFormValues({ ...bankFormValues, UserMemberId: userMemberId });
      if (selected === "0") {
        setSelected(undefined);
      } else {
        setSelected("2");
      }
    } else {
      const errorMsg = "Please Pick a Client";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  // handle bank form submit
  const handleBankSubmit = async (dataItem: any) => {
    setIsFormChanged(true);
    setBankSubmitBtnLoading(true);
    const saveBankDetails = new TenantService();
    let req = { ...dataItem, BankAccountType: dataItem?.BankAccountType?.id };
    if(shouldSetAsDefaultPaymentProfile(paymentProfile)){
      req={...req,IsDefault:true}
    }
    const result = await saveBankDetails.createPaymentProfile(req);
    setBankSubmitBtnLoading(false);
    if (result?.ResponseCode === 100) {
      setPopulateProfile(result?.Item);
      setSelected("0");
      const successMsg = "Payment creation successfully done";
      props?.handleNotificationMessage(successMsg, "success");
      setPaymentMethod(defaultPaymentMethod);
      fetchPaymentProfiles(dataItem?.UserMemberId);
      handleSubmit("text");
    } else {
      setBankFormValues(dataItem);
      setCardFormValues(cardIntialValues);
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  // to handle panel bar expand and collpase
  const onSelect = (e: any) => {
    setSelected(e?.target?.props?.id);
  };

  // handle card form submit
  const handleCardSubmit = async (dataItem: any) => {
    setIsFormChanged(true);
    setCardSubmitBtnLoading(true);
    const saveCardDetails = new TenantService();
    if (paymentGatewayType === 1) {
      dataItem.MaskedCCNumber = dataItem.MaskedCCNumber.split(" ").join("");
    }
   if(shouldSetAsDefaultPaymentProfile(paymentProfile)){
      dataItem.IsDefault=true
    }
    const result = await saveCardDetails.createPaymentProfile(dataItem);
    setCardSubmitBtnLoading(false);
    if (result?.ResponseCode === 100) {
      setPopulateProfile(result?.Item);
      setSelected("0");
      props?.handleNotificationMessage(
        `Payment creation successfully done`,
        "success"
      );
      setPaymentMethod(defaultPaymentMethod);
      setcardImageIcon(images.placeholder);
      fetchPaymentProfiles(dataItem?.UserMemberId);
      handleSubmit("text");
    } else {
      setCardFormValues(dataItem);
      setBankFormValues(bankIntialValues);
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleRemoveGroupCoupon = (item: any) => {
    const classSessionId = item.ClassSessionId;
  
    setSession((prevSession: any) =>
      prevSession.map((sessionItem: any) => ({
        ...sessionItem,
        values: sessionItem.values.map((value: any) => {
          if (value.ClassSessionId === classSessionId) {
            return {
              ...value,
              Coupons: [], 
              CouponsText: null, 
            };
          }
          return value;
        }),
      }))
    );

    const allCoupons = sessionref.current
      .flatMap((sessionItem: any) =>
        sessionItem.values.flatMap((value: any) => value.Coupons || [])
      )
      .filter((coupon: any) => coupon.ClassSessionId !== classSessionId); 

    handleGroupCoupon([],classSessionId);
  };
  

  const handleGroupChangeCoupon = async (val: any,item:any) => {
    
    setSession((prevSession: any) =>
      prevSession.map((sessionItem: any) => {
        return {
          ...sessionItem,
          values: sessionItem.values.map((value: any) => {
            if (value.ClassSessionId === item?.classSessionId) {
              
              return {
                ...value,
                CouponsText: val,                 
              };
            }
            return value;
          }),
        };
      })
    );
    await handleGroupCouponApply(val,item)
  };

  const calculateUsedCount = (sessions: any, selectedCouponID: any) => {
    let usedCount = 0;
  
    sessions?.forEach((session: any) => {
      session?.values?.forEach((value: any) => {
        value?.Coupons?.forEach((coupon: any) => {
          if (coupon?.CouponId === selectedCouponID) {
            usedCount++;
          }
        });
      });
    });
  
    return usedCount;
  };
  

  const handleGroupCouponApply = async (val: any,item:any) => {
    if (val !== "" && val !== undefined) {
      setCouponBtnLoading(true);
      const couponService = new TenantService();
      let req = {
        UserMemberId: userMemberId,
        EntityId: item?.ClassId,
        EntityType: 3 ,
        CouponName: val,
      };
      
      const result = await couponService.findCoupon(req);
      setCouponBtnLoading(false);
      if (result?.ResponseCode === 100) {
        if (result.Items.length === 0) {
          const errorMsg = "Please enter valid coupon";
          props?.handleNotificationMessage(errorMsg, "error");
        } else {
          const res = result.Items[0];

          const usedCount = calculateUsedCount(sessionref?.current, res?.CouponId);


  
          // Subtract the count from available count
          const remainingCoupons = res.AvailableCount - usedCount;
  
          if (remainingCoupons <= 0) {
            props?.handleNotificationMessage("Coupon usage limit reached", "error");
            return;
          }
          const data = [{
            Amount: res?.Amount,
            CouponId: res?.CouponId,
            CouponInformation: res?.CouponInformation,
            Description: res?.CouponInformation,
            Type: res?.Type,
            TypeDescription: res?.TypeDescription,
            AvailableCount: res?.AvailableCount,
            CouponAppliedCount: res?.CouponAppliedCount,
            DurationType: res?.DurationType,
          }];
          handleGroupCoupon(data,item.ClassSessionId,Taxes,val);
          
          const successMsg = "Coupon Applied Successfully";
          props?.handleNotificationMessage(successMsg, "success");
        }
      } else {
        const errorMsg = result?.ErrorMessages?.[0];
        props?.handleNotificationMessage(errorMsg, "error");
      }
    } else {
      const errorMsg = "Please enter Coupon";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  // to save entered coupon to state
  const handleChangeCoupon = (val: any) => {
    setIsFormChanged(true);
    setCoupon(val);
  };

  //to remove the applied coupon
  const handleRemoveCoupon = (index: number) => {
    const array = [...appliedCoupons];
    if (index !== -1) {
      array.splice(index, 1);
    }
    
      if (array.length === 0) {
        setvisibleCouponField(true);
      }
      setAppliedCoupons(array);
  };

  // to select the payment profile
  const handleChangeSavedCard = (val: any, dataItem: any = null) => {
    dispatch(PaymentMethodSelected(val?.CardTypeId));
    setIsFormChanged(true);
    setSelectedPaymentProfile({
      ...selectedPaymentProfile,
      selectedPayment: val,
      AlternatePaymentProfileId: null,
    });
    setCardFormValues(cardIntialValues);
    setBankFormValues(bankIntialValues);
    generatePaymentProfileObj(val, dataItem);
    setPaymentMethod(defaultPaymentMethod);
    // if (selected === "0") {
    //   setSelected(undefined);
    // } else {
    //   setSelected("3");
    // }
  };

  //payment profile object to send while build agreement and purchase
  const generatePaymentProfileObj = (val: any, dataItem: any = null) => {
    let paymentDetails: any = {
      CustomerProfileID: val?.CustomerProfileID,
      PaymentProfileID: val?.PaymentProfileID,
      PaymentGatewayPaymentProfileID: val?.PaymentGatewayPaymentProfileID,
      MaskedCCNumber: val?.MaskedCCNumber,
      CardTypeId: val?.CardTypeId,
      CardDescription: val?.CardDescription,
      BankAccountType: val?.BankAccountType,
      Credit: val?.Credit,
    };
    if (dataItem) {
      paymentDetails = {
        ...paymentDetails,
        Reference: dataItem?.Reference,
        ReferrenceDocument: dataItem?.ReferrenceDocument,
      };
    }
    setPaymentSubmitProfile(paymentDetails);
    setPopulateProfile(null);
  };

  // handle switching between saved payment  & card & bank forms
  const handleForm = (form: string) => {
    setcardImageIcon(images.placeholder);
    setSelectedPaymentProfile({
      ...selectedPaymentProfile,
      selectedPayment: undefined,
      AlternatePaymentProfileId: null,
    });
    setPaymentSubmitProfile({});
    setPaymentMethod({ ...defaultPaymentMethod, [form]: true });
  };

  // purchase Class validation
  const checkoutClassValidation = (checkTerms = true) => {
    setSelected("6");
    if (Object.keys(clientSubmitDetails).length === 0) {
      setSelected("1");
      const errorMsg = "Please pick/create Client";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    if (
      downpaymentForm?.paidToday > 0 ||
      (downpaymentForm?.paidToday === 0 && !checkPaymentProfileNotRequired(true))
    ) {
      if (Object.keys(paymentSubmitProfile).length === 0 || !paymentSubmitProfile?.PaymentGatewayPaymentProfileID) {
        setPaymentMethod(defaultPaymentMethod);
        setSelected("2");
        const errorMsg = "Please select/add Payment profile";
        props?.handleNotificationMessage(errorMsg, "error");
        return true;
      }else{
        let purchaseAmt= !IsTaxSkipped ? downpaymentForm?.paidToday + TotalTaxAmount : downpaymentForm?.paidToday
        purchaseAmt=CustomToFixed(purchaseAmt,2)
        if(paymentSubmitProfile?.CardTypeId === 8 && purchaseAmt > paymentSubmitProfile?.Credit && !selectedPaymentProfile?.AlternatePaymentProfileId){
          let defaultPaymentProfile=null
          const existedPP=paymentProfile?.filter((i:any)=> i?.CardTypeId !== -1 && i.CardTypeId !==8)
          if(existedPP?.length > 0){
            defaultPaymentProfile=existedPP[0]
          }
          setShowAdditionalPayment({
            dialog:true,
            selectedPaymentProfile:defaultPaymentProfile
          })
          return true;
        }
      }
    }
   
    if (staffPage && !selectedSalesPerson) {
      const errorMsg = "Please select Sales Person";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    if (checkTerms && !termsandConditions) {
      const errorMsg = "Please check the Terms & Conditions";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    return false;
  };

  //to handle build agreement
  const handleCheckoutPage = (page: string, cbParams:any=null) => {
    setConfirmClientDialog(false);
    if (!staffPage && !CheckUserMemberStatus(clientSubmitDetails)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (staffPage && !CheckUserMemberStatus(clientSubmitDetails)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    const check = checkoutClassValidation();
    if (check) {
      return;
    }
    handleClassPurchase();
  };
  const handleClassPurchase = async () => {
    let sessionIds: any = [];
    session.forEach((item: any) =>
      item?.values.forEach((item1: any) =>
        sessionIds.push({
          ClassId: item1.ClassId,
          EntityName:item1.ClassName,
          ClassSessionId: item1.ClassSessionId,
          ClassType: item1.ClassType,
          Cost: item1.Cost,
          CanBookAsWaitlist: item1.CanBookAsWaitlist,
          Complimentary: item1.Complimentary,
          CanCollectTaxes: checkHasTaxEnable() ? !item.IsTaxSkipped : false,
          Taxes: checkHasTaxEnable() ? item1.Taxes : [],
          TotalTaxAmount: checkHasTaxEnable() ? item1.TotalTaxAmount : 0,
          Coupons:item1.Coupons
        })
      )
    );
    let ClassSessionDetails: any = [];
    sessionIds.forEach((k: any) => {
      const selectedPackageForSessionId: any = packageListToUse.find(
        (val: any) =>
          val.ClassSessions.find(
            (val1: any) => val1?.ClassSessionId === k?.ClassSessionId
          )
      );
      const applicableCoupons = couponDetails?.AppliedCoupons || []; 
      const checkSkipPaymentStatus = checkSkipPayment(k?.ClassSessionId);
      const {
        ClassId,
        ClassSessionId,
        ClassType,
        Cost,
        TotalTaxAmount,
        Taxes,
        CanCollectTaxes,
        CanBookAsWaitlist,
        ClassSessionStartDate,
        EntityName,Coupons 
      } = k;
      if (selectedPackageForSessionId) {
        const { PackageInstanceId, Name, BillingPeriods } =
          selectedPackageForSessionId;
        let BillingPeriodFrom = undefined;
        if (props?.SessionSignOff) {
          BillingPeriodFrom =
            selectedAppt?.BillingPeriod?.ExpireFrom || undefined;
        } else {
          const billingPeriods = BillingPeriods?.find((i: any) => {
            const expireFrom = moment(i?.ExpireFrom).format("YYYY-MM-DD");
            const expireOn = moment(i?.ExpireOn).format("YYYY-MM-DD");
            const StartDateTime = moment(ClassSessionStartDate).format(
              "YYYY-MM-DD"
            );
            if (StartDateTime >= expireFrom && StartDateTime <= expireOn) {
              return true;
            }
            return false;
          });
          BillingPeriodFrom = billingPeriods?.ExpireFrom || undefined;
        }

        ClassSessionDetails.push({
          ClassId,
          ClassSessionId,
          EntityName: Name,
          PayBy: 1,
          PaymentSourceId: PackageInstanceId,
          TotalTaxAmount,
          Taxes,
          CanCollectTaxes,
          Cost,
          CanBookAsWaitlist,
          BillingPeriodFrom,
          Coupons,
          IsQuickSignOff: props?.SessionSignOff ? true : undefined
        });
      } else if (k?.Complimentary) {
        ClassSessionDetails.push({
          ClassId,
          ClassSessionId,
          PayBy: 5,
          PaymentSourceId: paymentSubmitProfile?.PaymentGatewayPaymentProfileID,
          TotalTaxAmount,
          Taxes,
          CanCollectTaxes,
          Cost,
          CanBookAsWaitlist,
          EntityName,
          Coupons,
          IsQuickSignOff: props?.SessionSignOff ? true : undefined
        });
      }
      // else if(CanBookAsWaitlist){
      //   ClassSessionDetails.push({
      //     ClassId,
      //     ClassSessionId,
      //     PayBy: 0,
      //     PaymentSourceId: paymentSubmitProfile?.PaymentGatewayPaymentProfileID,
      //     TotalTaxAmount,
      //     Taxes,
      //     CanCollectTaxes,
      //     Cost,
      //     CanBookAsWaitlist
      //   });
      // }
      else if (checkSkipPaymentStatus) {
        if (ClassType === 3 || Cost <= 0) {
          ClassSessionDetails.push({
            ClassId,
            ClassSessionId,
            PayBy: 3,
            PaymentSourceId:
              paymentSubmitProfile?.PaymentGatewayPaymentProfileID,
            TotalTaxAmount,
            Taxes,
            CanCollectTaxes,
            Cost,
            CanBookAsWaitlist,
            EntityName,
            Coupons,
            IsQuickSignOff: props?.SessionSignOff ? true : undefined,
          });
        } else {
          ClassSessionDetails.push({
            ClassId,
            ClassSessionId,
            PayBy: 2,
            PaymentSourceId:
              paymentSubmitProfile?.PaymentGatewayPaymentProfileID,
            TotalTaxAmount,
            Taxes,
            CanCollectTaxes,
            Cost,
            CanBookAsWaitlist,
            EntityName,
            Coupons,
            IsQuickSignOff: props?.SessionSignOff ? true : undefined,
          });
        }
      } else {
        ClassSessionDetails.push({
          ClassId,
          ClassSessionId,
          PayBy: 0,
          PaymentSourceId: paymentSubmitProfile?.PaymentGatewayPaymentProfileID,
          TotalTaxAmount,
          Taxes,
          CanCollectTaxes,
          Cost,
          CanBookAsWaitlist,
          EntityName,
          Coupons,
          IsQuickSignOff: props?.SessionSignOff ? true : undefined
        });
      }
    });

    const startDate = moment(groupSelectedDates[0]).format("YYYY-MM-DD");
    const IsPastDate = moment(startDate).isBefore(moment(), 'day');

    let req: any = {
      DownPayment: packageCostAfterDiscount || 0,
      //Coupons:couponDetails?.AppliedCoupons || [],
      // IsTransactionSucced: false,
      // Coupon: {},
      Client: clientSubmitDetails,
      ClassSessionDetails,
      HasTermsAndConditionAccepted: checkoutType ==="inhouse-class"? true : termsandConditions,
      IsPastDate
    };
    req = { ...req, PaymentProfile: paymentSubmitProfile };
    const taxAmt = calculateTotalSessionTax();
    let purchaseAmt= !IsTaxSkipped ? downpaymentForm?.paidToday : downpaymentForm?.paidToday + taxAmt
    if (paymentSubmitProfile?.CardTypeId === 8 && purchaseAmt > paymentSubmitProfile?.Credit) {
      const AlternatePaymentProfileId = selectedPaymentProfile?.AlternatePaymentProfileId
      const AmountChargeByAlternatePaymentProfile = CustomToFixed(purchaseAmt - paymentSubmitProfile?.Credit, 2)
      req = {
        ...req,
        PaymentProfile: {
          ...paymentSubmitProfile,
          AlternatePaymentProfiles: [
            {
              AlternatePaymentProfileId: AlternatePaymentProfileId,
              AmountChargeByAlternatePaymentProfile: AmountChargeByAlternatePaymentProfile
            }
          ]
        }
      };
    }else{
      req = {
        ...req,
        PaymentProfile: {
          ...paymentSubmitProfile,
          AlternatePaymentProfiles: [
            {
              AlternatePaymentProfileId: 0,
              AmountChargeByAlternatePaymentProfile: 0
            }
          ]
        }
      };
    }
   
    let PaymentObject={...req?.PaymentProfile}
    let paymentAttributes = null;
    if (paymentSubmitProfile?.CardTypeId === -2) {
      paymentAttributes = {
        Reference: paymentSubmitProfile?.Reference,
        OfflinePaymentDescription: paymentSubmitProfile?.MaskedCCNumber,
        ReferrenceDocumentName:
          paymentSubmitProfile?.ReferrenceDocument?.FileName || "",
        ReferrenceDocumentContent:
          paymentSubmitProfile?.ReferrenceDocument?.ImageAsBase64 || "",
      };
      PaymentObject = { ...paymentSubmitProfile, CardTypeId: -1 };
    } else if (selectedPaymentProfile?.CardTypeId === -1) {
      paymentAttributes = {
        Reference: "",
        OfflinePaymentDescription: "Cash",
        ReferrenceDocumentName: "",
        ReferrenceDocumentContent: "",
      };
      PaymentObject = { ...paymentSubmitProfile, CardTypeId: -1 };
    }
    req = {
      ...req,
      PaymentAttributes: paymentAttributes,
      PaymentProfile: PaymentObject,
    };
    setBtnLoading(true);
    setDisable(true);
    const checkoutClass = new ClassService();
    try {
      const result = await checkoutClass.purchaseClass(req);
      setBtnLoading(false);
      setDisable(false);

      if (result?.ResponseCode === 100) {
        setIsFormChanged(false);
        setSubmit(true);
        setSubmittedClassSessions(ClassSessionDetails);
      } else {
        const errorMsg = result?.ErrorMessages?.[0];
        props?.handleNotificationMessage(errorMsg, "error");
      }
    } catch (e) {
      console.log(e);
    }
  };


  const handleAddAdditionalPayment=()=>{
    if(!showAdditionalPayment?.selectedPaymentProfile?.PaymentGatewayPaymentProfileID){
      const errorMsg = "Please select/add Payment profile";
      props?.handleNotificationMessage(errorMsg, "error");
      return
    }
    setSelectedPaymentProfile({
      ...selectedPaymentProfile,AlternatePaymentProfileId:showAdditionalPayment?.selectedPaymentProfile?.PaymentGatewayPaymentProfileID
    })
    setShowAdditionalPayment(initialAdditionalPayment)
  }

  
  const handleClientConfirmation = (page: string, cbParams: any = null) => {
    const check = checkoutClassValidation();
    if (check) {
      return;
    }
    if (AllClientsList?.length > 1 && checkoutType !== "online-class") {
      // const selectedClientInfo=AllClientsList?.filter(
      //   (res: any) => res?.UserMemberId === clientFormValues?.UserMemberId
      // )[0]
      // if(selectedClientInfo?.MemberType===0){
      setPurchaseCheckout({
        ...purchaseCheckout,
        page: page,
        params: cbParams,
      });
      toggleClientConfirmDialog();
      return;
      // }else{
      //   handleCheckoutPage(page,cbParams)
      // }
    }else{
      handleCheckoutPage(page,cbParams)
    }
  };
  const handleChangeAdditionalPaymentProfile = async (value: any) => {
    setShowAdditionalPayment({
      ...showAdditionalPayment,selectedPaymentProfile:value
    })
  }

  const renderClientConfirmationDialog = () => {
    const selectedClientInfo = AllClientsList?.filter(
      (res: any) => res?.UserMemberId === clientFormValues?.UserMemberId
    )[0];
    return (
      <Dialog
        className="bz-book-Appoinment-dialog"
        title={"Please Confirm"}
        onClose={() => toggleClientConfirmDialog()}
        width={"35%"}
      >
        <div>
          <p className={"dialogContainer"}>
            {`Looks like you have additional family members on account, please confirm you are making purchases for the below client.`}
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <ClientNameWithMemberType
              clientName={selectedClientInfo?.FullName}
              memberType={selectedClientInfo?.MemberType}
            />
          </div>
          <div className="column-button-section book-appt-btns">
            <Button primary={false} onClick={() => toggleClientConfirmDialog()}>
              No
            </Button>
            <BuzopsButton
              label={"Yes"}
              onClick={() =>
                handleCheckoutPage(
                  purchaseCheckout?.page,
                  purchaseCheckout?.params
                )
              }
            />
          </div>
        </div>
      </Dialog>
    );
  };

  const handleCloseClassCheckout = () => {
    if (props?.location?.state?.from === "calender") {
      props.handleWindow(true);
      return true;
    }
    if (checkoutType === "inhouse-class") {
      if (props?.type === "bulk") {
        props?.handleParentWindow();
      } else {
        props?.handleWindow();
      }
    } else {
      if (!staffPage) {
        handleSummaryRedirection();
      } else {
        const urlRedirection = `/member/${props?.location?.state?.uuid}/${props?.location?.state?.userData?.PrimaryMemberId}/gsappointments`;
        history.push(urlRedirection);
      }
    }
  };

  const renderClassCheckoutDialog = () => {
    const classSessionData = submittedClassSessions?.filter(
      (i: any) => i?.CanCollectTaxes
    );
    const checkCanBookAsWaitlist = submittedClassSessions?.filter(
      (i: any) => i?.CanBookAsWaitlist
    );
    let taxEnabled = false;
    if (classSessionData?.length > 0 && checkHasTaxEnable()) {
      taxEnabled = true;
    }
    let CanBookAsWaitlist = false;
    if (checkCanBookAsWaitlist?.length > 0) {
      CanBookAsWaitlist = true;
    }
    return (
      <Dialog
        className="bz-book-Appoinment-dialog bz-purchaseClassSessionSuccess bz-groupEvent-success bz-colon-centered"
        title={"Purchased Group Successfully"}
        onClose={() => handleCloseClassCheckout()}
      >
        <div>
          {CanBookAsWaitlist && (
            <div className="alert alert-warning fade show" role="alert">
              {payLaterAmount > 0
                ? `Thank you for joining the waitlist! Your spot is reserved, and payment will be collected once your booking is confirmed. You will receive an email once confirmed.`
                : `Thank you for joining the waitlist! Your spot is reserved. You will receive an email once confirmed.`}
            </div>
          )}
          <div className="ready-to-book-appt-dialog">
            <div className="column-1-section">Client</div>
            <span>:</span>
            <div className="column-2-section">
              {`${clientSubmitDetails?.FirstName} ${clientSubmitDetails?.LastName}`}
            </div>
            <div className="column-1-section">Total Group Enrolled</div>
            <span>:</span>
            <div className="column-2-section">{totalClassSessions}</div>

            {useCreditAmount > 0 && (
              <>
                <div className="column-1-section">Redeemed via Package</div>
                <span>:</span>
                <div className="column-2-section">
                  {CurrencyFormat(useCreditAmount)}
                </div>
              </>
            )}
            {useComplimentaryAmount > 0 && (
              <>
                <div className="column-1-section">
                  Redeemed via Complimentary
                </div>
                <span>:</span>
                <div className="column-2-section">
                  {CurrencyFormat(useComplimentaryAmount)}
                </div>
              </>
            )}
            {payLaterAmount > 0 && (
              <>
                <div className="column-1-section">Pay at Class</div>
                <span>:</span>
                <div className="column-2-section">
                  {CurrencyFormat(
                    payLaterAmount +
                      calculateTotalSessionTax(true) -
                      calculateTotalSessionTax()
                  )}
                </div>
              </>
            )}
            {taxEnabled ? (
              <>
                <div className="column-1-section">
                  Pay Today (Inclusion of Surcharges)
                </div>
                <span>:</span>
                <div className="column-2-section">
                  {CurrencyFormat(
                    downpaymentForm.paidToday + calculateTotalSessionTax()
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="column-1-section">Pay Today</div>
                <span>:</span>
                <div className="column-2-section">
                  {CurrencyFormat(downpaymentForm.paidToday)}
                </div>
              </>
            )}
            <div className="dialog-btn-blk">
              <BuzopsButton
                className={"float-right mt-3 mr-0"}
                label={"Okay"}
                onClick={() => handleCloseClassCheckout()}
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  };
  // const handleTermsConditionDialog = () => {
  //   const check = checkoutClassValidation(false);
  //   if (check) {
  //     return;
  //   }
  //   setTermsandConditionsDialog(!termsandConditionsDialog);
  // };

  const handleTermsConditionDialog = (val: any) => {
    const check = checkoutClassValidation(false);
    if (check) {
      return;
    }
    if (val === true || val === false) {
      setTermsandConditions(val);
    } else {
      setTermsandConditionsDialog(!termsandConditionsDialog);
    }
  };

  const handleAddMoreClasses = () => {
    setSession(sessionItem);
    const AddMoreClasses = sessionref?.current?.map((item: any, index: number) => {
      const modifiedValues = item?.values.map((j: any) => {
        return { ...j, Complimentary: false };
      });
      return { ...item, values: modifiedValues };
    });
    if (checkoutType === "inhouse-class") {
      props.handleWindow();
    } else if (checkoutType === "online-class") {
      if (!staffPage) {
        handleSummaryRedirection({
          AddMoreClasses: AddMoreClasses,
          selectedUserMemberId: props?.location?.state?.userData?.UserMemberId,
        });
      } else {
        if (props?.location?.state?.from === "calender") {
          props?.handleClose(AddMoreClasses);
          return;
        }
        const urlRedirection = `/member/${props?.location?.state?.uuid}/${
          props?.location?.state?.userData?.PrimaryMemberId ||
          props?.location?.state?.userData?.UserMemberId
        }`;
        history.push(urlRedirection, {
          AddMoreClasses: AddMoreClasses,
          selectedUserMemberId: props?.location?.state?.userData?.UserMemberId,
        });
      }
    }
  };
  const handleNotNowRedirection = () => {
    if(showCheckout){
      setshowCheckout(false);
      return;
    }
    if (guestLayout) {
      if (redirectionObj?.firstPathValue === "external") {
        history.push(`/${redirectionObj?.firstPathValue}/${providerId}`);
      } else if (redirectionObj?.secondRoute !== "") {
        history.push(
          `/${redirectionObj?.firstPathValue}/${redirectionObj?.secondRoute}`
        );
      } else {
        history.push(`/${providerId}`);
      }
    } else {
      if (!staffPage) {
        if (checkoutType === "online-class") {
          handleSummaryRedirection({
            AddMoreClasses: session,
            selectedUserMemberId:
              props?.location?.state?.userData?.UserMemberId,
          });
        } else {
          handleSummaryRedirection();
        }
      } else {
        if (props?.location?.state?.from === "calender") {
          const updatedSession = session.map((classItem : any) => ({
            ...classItem,
            values: classItem.values.map((valueItem:any) => ({
              ...valueItem,
              Coupons: [],
              CouponAmount:0,
            }))
          }));
          setSession(updatedSession);
          props?.handleClose(updatedSession);
          return;
        }
        if (checkoutType === "online-class") {
          const urlRedirection = `/member/${props?.location?.state?.uuid}/${
            props?.location?.state?.userData?.PrimaryMemberId ||
            props?.location?.state?.userData?.UserMemberId
          }`;
          history.push(urlRedirection, {
            AddMoreClasses: session,
            selectedUserMemberId:
              props?.location?.state?.userData?.UserMemberId,
          });
        } else {
          const urlRedirection = `/member/${props?.location?.state?.uuid}/${props?.location?.state?.userData?.UserMemberId}`;
          history.push(urlRedirection);
        }
      }
    }
  };

  

  const checkBillingPeriods= (packageItem:any,date:any)=> {
    if(packageItem?.SessionType===1){
      const BillingPeriods=packageItem?.BillingPeriods
      return BillingPeriods.some((i:any) => {
          return (
            moment(date).format('YYYY-MM-DD') >= moment(i?.ExpireFrom).format('YYYY-MM-DD') && moment(date).format('YYYY-MM-DD') <= moment(i?.ExpireOn).format('YYYY-MM-DD') && 
              i.SchedulesToUse > 0
          );
      });
    } else {
      return true;
    }
  };

  const getExactBillingPeriod = (packageItem: any, date: any) => {
    const BillingPeriods = packageItem?.BillingPeriods;
    if (props?.SessionSignOff) {
      return selectedApptRef?.current?.BillingPeriod;
    } else {
      const dataItem = BillingPeriods.find((i: any) => {
        if (
          moment(date).format("YYYY-MM-DD") >=
            moment(i?.ExpireFrom).format("YYYY-MM-DD") &&
          moment(date).format("YYYY-MM-DD") <=
            moment(i?.ExpireOn).format("YYYY-MM-DD")
        ) {
          return true;
        }
        return false;
      });
      return dataItem;
    }
  };
  const checkUseCredit = (
    item: any,
    selectedPackage: any = null,
    packListToUse = packageListToUse
  ) => {
    if (!selectedPackage) {
      const test = packListToUse.findIndex((val: any) =>
        val.ClassSessions.find(
          (val1: any) => val1.ClassSessionId === item.ClassSessionId
        )
      );
      // if(test !==-1){
      //   const selectedPack=packListToUse[test]
      //   if(checkBillingPeriods(selectedPack,item?.ClassSessionStartDate)){
      //     return true
      //   }
      //   return false
      // }
      return test !== -1;
    } else {
      const test = packListToUse.findIndex(
        (val: any) =>
          selectedPackage?.PackageInstanceId === val?.PackageInstanceId &&
          val.ClassSessions.find(
            (val1: any) => val1.ClassSessionId === item.ClassSessionId
          ) &&
          checkBillingPeriods(selectedPackage, item?.ClassSessionStartDate)
      );
      return test !== -1;
    }
  };
  const checkSkipPayment = (sessionId: any) => {
    let sessions: any = [];
    session.forEach((j: any) => {
      j?.values?.forEach((k: any) => {
        sessions.push(k);
      });
    });
    const sessionIds = sessions?.findIndex(
      (kkk: any) =>
        kkk?.ClassSessionId === sessionId &&
        (kkk?.PaymentOption === 2 ||
          kkk?.PaymentOption === 3 ||
          kkk?.ClassType !== 1)
    );
    return sessionIds !== -1;
  };
  const checkPaymentProfileNotRequired = (submit = false) => {
    let sessions: any = [];
    session.forEach((j: any) => {
      j?.values?.forEach((k: any) => {
        sessions.push(k);
      });
    });
    let check = sessions.every((item: any) => item?.PaymentOption === 3);

    if (!check) {
      if (
        downpaymentForm?.paidToday === 0 &&
        packageCost > 0 &&
        packageCost === useCreditAmount + useComplimentaryAmount
      ) {
        check = true;
      }
    }
    return check;
  };
  const checkOfflineReferenceRequired = () => {
    let sessions: any = [];
    session.forEach((j: any) => {
      j?.values?.forEach((k: any) => {
        sessions.push(k);
      });
    });
    let check = sessions.every((item: any) => item?.PaymentOption === 3 || item?.PaymentOption === 2);

    if (!check) {
      if (downpaymentForm?.paidToday === 0 &&  packageCost > 0 && packageCost === useCreditAmount + useComplimentaryAmount) {
        check = true;
      }
    }
    return check;
  };

  function findBillingPeriod(ClassSessionStartDate:any, BillingPeriods:any) {
    const sessionDate = new Date(ClassSessionStartDate);
  
    return BillingPeriods.find((period:any) => {
      const expireFrom = new Date(period.ExpireFrom);
      const expireOn = new Date(period.ExpireOn);
      expireOn.setHours(23);
      expireOn.setMinutes(59);
      expireOn.setSeconds(0);
  
      return sessionDate >= expireFrom && sessionDate <= expireOn;
    });
  }

  const CheckExactPackageItem = (dataItem: any,sessionItem:any,packageList=packageListToUse) => {
    const IsPastDate = moment(sessionItem?.ClassSessionStartDate).isBefore(moment(), 'day');
    const packList=sessionItem?.PackagesList?.filter((i:any)=>{
      const billingPeriod = findBillingPeriod(sessionItem?.ClassSessionStartDate, i?.BillingPeriods);
      const IsPastDate = moment(sessionItem?.ClassSessionStartDate).isBefore(moment(), 'day');

      const { ExpireFrom, ExpireOn } = IsPastDate && billingPeriod !== undefined
        ? billingPeriod
        : i;
      if(moment(sessionItem?.ClassSessionStartDate).format('YYYY-MM-DD') >= moment(ExpireFrom).format('YYYY-MM-DD') && moment(sessionItem?.ClassSessionStartDate).format('YYYY-MM-DD') <= moment(ExpireOn).format('YYYY-MM-DD')){
        return i
      }
    })
    const UnlimitedPackages = packList.filter(
      (i: any) => i?.SessionType === 2
    );
    const LimitedPackages = packList.filter(
      (i: any) => i?.SessionType === 1
    );

    if (UnlimitedPackages?.length === 1) {
      return UnlimitedPackages[0];
    }
    if (UnlimitedPackages?.length > 1) {
      const selectedMinExpireOnPackage = UnlimitedPackages.reduce(
        (min: any, item: any) => {
          return new Date(min.ExpireOn) > new Date(item.ExpireOn) ? item : min;
        },
        UnlimitedPackages[0]
      );
      return selectedMinExpireOnPackage;
    }
    const checkSchedulestoUse = packageList?.filter(
      (i: any) => i?.SessionType === 1 && i?.SchedulesToUse > 0
    );
    const updatedLimitedPackage = LimitedPackages?.filter((j: any) => {
      const check = checkSchedulestoUse.findIndex(
        (k: any) => k?.PackageInstanceId === j?.PackageInstanceId
      );
      if (check === -1) {
        return false;
      }
      return true;
    });

    if (updatedLimitedPackage?.length > 0) {
      const selectedMinExpireOnPackage = updatedLimitedPackage.reduce(
        (min: any, item: any) => {
          const minExpireOn = min?.PackageExpireOn
            ? min?.PackageExpireOn
            : min?.ExpireOn;
          const itemExpireOn = item?.PackageExpireOn
            ? item?.PackageExpireOn
            : item?.ExpireOn;

          return new Date(minExpireOn) > new Date(itemExpireOn) ? item : min;
        },
        updatedLimitedPackage[0]
      );
      return selectedMinExpireOnPackage;
    }
    return null;
  };

  async function handleAutoselectPackage(
    sessionItems = session,
    packageToUse = packageListToUse
  ) {
    let sessionArray: any = sessionItems;
    let updatedPackageListToUse: any = packageToUse;
    let amount = packageCostAfterDiscount;
    let payLaterAmt = payLaterAmount;
    let complimentaryAmt = useComplimentaryAmount;
    let packageRedeemAmt = useCreditAmount;
    for (const item of sessionItems) {
      if (item?.values?.length > 0) {
        for (const sessionItem of item?.values) {
          if (
            sessionItem?.PackagesList &&
            sessionItem?.PackagesList?.length > 0
          ) {
            const selectedPackage =
              sessionItem?.SelectedPackage ||
              CheckExactPackageItem(item, sessionItem, updatedPackageListToUse);
            if (selectedPackage) {
              const updatedSession: any = await handlePackageSelectionForClass(
                selectedPackage,
                sessionItem,
                sessionArray,
                amount,
                payLaterAmt,
                updatedPackageListToUse,
                packageRedeemAmt,
                complimentaryAmt
              );
              sessionArray = updatedSession?.SessionArray;
              amount = updatedSession?.amount;
              payLaterAmt = updatedSession?.payLaterAmt;
              updatedPackageListToUse = updatedSession?.updatedPackageListToUse;
              packageRedeemAmt = updatedSession?.packageRedeemAmt;
              complimentaryAmt = updatedSession?.complimentaryAmt;
            }
          }
        }
      }
    }
  }

  const handlePackageSelectionForClass = (
    event: any,
    item: any,
    mainSession = session,
    amnt = packageCostAfterDiscount,
    payLatAmt = payLaterAmount,
    packListToUse = packageListToUse,
    packageRedeem = useCreditAmount,
    complimentaryamtVal = useComplimentaryAmount
  ) => {
    setIsFormChanged(true);
    const e = event;
    let amount = amnt;
    let payLaterAmt = payLatAmt;
    let complimentaryAmt = complimentaryamtVal;
    let packageRedeemAmt = packageRedeem;
    const SessionArray = mainSession.map((j: any) => {
      if (j.ClassId === item?.ClassId) {
        const modifiedValues = j.values?.map((sch: any) => {
          if (sch.ClassSessionId === item?.ClassSessionId) {
            if (sch?.Complimentary) {
              if (sch?.PaymentOption <= 1 && !sch?.CanBookAsWaitlist) {
                amount = amount + sch?.Cost;
              } else {
                payLaterAmt = payLaterAmt + sch?.Cost;
              }
              complimentaryAmt = complimentaryAmt - sch?.Cost;
            }
            return { ...sch, SelectedPackage: e, Complimentary: false };
          }
          return { ...sch };
        });
        return { ...j, values: modifiedValues };
      } else {
        return { ...j };
      }
    });
    let sessions: any = [];
    session.forEach((j: any) => {
      if (j.ClassId === item?.ClassId) {
        j?.values?.forEach((k: any) => {
          sessions.push(k);
        });
      }
    });
    const sessionIds = sessions?.map((kkk: any) => kkk?.ClassSessionId);

    const updatedPackageListToUse: any = packListToUse.map((i: any) => {
      let previousSessions = i.ClassSessions;
      let sessionCount = i.SchedulesToUse;
      const exactBillingPeriod=getExactBillingPeriod(i,item?.ClassSessionStartDate)
      if(i?.SessionType===1){

        sessionCount=exactBillingPeriod?.SchedulesToUse 
      }

      if (i.PackageInstanceId===e?.PackageInstanceId) {
        sessions.forEach((kk: any) => {
          if (!checkUseCredit(kk,e,packListToUse) && item?.ClassSessionId ===kk?.ClassSessionId) {
            const billingPeriod = findBillingPeriod(kk?.ClassSessionStartDate, i.BillingPeriods);
            const IsPastDate = moment(kk?.ClassSessionStartDate).isBefore(moment(), 'day');
            
            let { ExpireFrom, ExpireOn } = IsPastDate && billingPeriod !== undefined
              ? billingPeriod
              : i;

            sessionCount = IsPastDate ? billingPeriod?.SchedulesToUse  : i.SchedulesToUse;
             
            if (
              props?.SessionSignOff || (
                moment(ExpireOn).format('YYYY-MM-DD') >=
                moment(kk?.ClassSessionStartDate).format('YYYY-MM-DD') && moment(ExpireFrom).format('YYYY-MM-DD') <=
                moment(kk?.ClassSessionStartDate).format('YYYY-MM-DD')
              )
            ) {
              if (
                (i?.SessionType === 1 && sessionCount > 0) ||
                i?.SessionType === 2
              ) {
                previousSessions.push(kk);
                if (i?.SessionType === 1) {
                  sessionCount = sessionCount - 1;
                }

                if (kk?.PaymentOption <= 1 && !kk?.CanBookAsWaitlist) {
                  amount = amount - kk?.Cost;
                } else {
                  payLaterAmt = payLaterAmt - kk?.Cost;
                }
                packageRedeemAmt = packageRedeemAmt + kk?.Cost;
              }
            }
          }
        });
        if (i?.SessionType === 1) {
          const billingPeriods = i?.BillingPeriods?.map((i: any) => {
            if (
              (exactBillingPeriod?.ExpireFrom === i?.ExpireFrom &&
                exactBillingPeriod?.ExpireTo === i?.ExpireTo &&
                exactBillingPeriod?.PackageInstanceId ===
                  i?.PackageInstanceId) ||
              props?.SessionSignOff
            ) {
              return { ...i, SchedulesToUse: sessionCount };
            }
            return { ...i };
          });
          return {
            ...i,
            ClassSessions: previousSessions,
            SchedulesToUse: sessionCount,
            BillingPeriods: billingPeriods,
          };
        } else {
          return {
            ...i,
            ClassSessions: previousSessions,
            SchedulesToUse: sessionCount,
          };
        }
      } else {
        // let previousSessions=i.ClassSessions
        // let sessionCount=i.SchedulesRemaining
        if (sessionIds.length > 0 && i.ClassSessions.length > 0) {
          previousSessions = i.ClassSessions.filter((j: any) => {
            if (j.ClassSessionId === item?.ClassSessionId) {
              if (i?.SessionType === 1) {
                sessionCount = sessionCount + 1;
              }

              if (j?.PaymentOption <= 1 && !j?.CanBookAsWaitlist) {
                amount = amount + j?.Cost;
              } else {
                payLaterAmt = payLaterAmt + j?.Cost;
              }
              packageRedeemAmt = packageRedeemAmt - j?.Cost;
              return false;
            } else {
              return true;
            }
          });
          if (i?.SessionType === 1) {
            const billingPeriods = i?.BillingPeriods?.map((i: any) => {
              if (
                props?.SessionSignOff ||
                (exactBillingPeriod?.ExpireFrom === i?.ExpireFrom &&
                  exactBillingPeriod?.ExpireTo === i?.ExpireTo &&
                  exactBillingPeriod?.PackageInstanceId ===
                    i?.PackageInstanceId)
              ) {
                return { ...i, SchedulesToUse: sessionCount };
              }
              return { ...i };
            });
            return {
              ...i,
              ClassSessions: previousSessions,
              SchedulesToUse: sessionCount,
              BillingPeriods: billingPeriods,
            };
          } else {
            return {
              ...i,
              ClassSessions: previousSessions,
              SchedulesToUse: sessionCount,
            };
          }
        } else {
          return { ...i };
        }
      }
    });
    setPackageCostAfterDiscount(amount);
    // setPackageCost(amount)
    setPayLaterAmount(payLaterAmt);
    setUseCreditAmount(packageRedeemAmt);
    setUseComplimentaryAmount(complimentaryAmt);
    setDownpaymentForm({ ...downpaymentForm, paidToday: amount });
    setPackageListToUse(updatedPackageListToUse);
    handleSessionTaxCalculation(SessionArray, Taxes, updatedPackageListToUse);
    return {
      SessionArray,
      amount,
      payLaterAmt,
      updatedPackageListToUse,
      packageRedeemAmt,
      complimentaryAmt,
    };
  };

  const handleComplimentary = (val: any, sessionItem: any) => {
    let complimentaryAmt = useComplimentaryAmount;
    let packageRedeemAmt = useCreditAmount;
    let amount = packageCostAfterDiscount;
    let payLaterAmt = payLaterAmount;
    let mainSession = [...session];
    let updatedPkListToUse: any = packageListToUse;
    const targetedClassSessionId = sessionItem?.ClassSessionId;

    mainSession.forEach((session) => {
      session.values?.forEach((value: any) => {
        if (value.ClassSessionId === targetedClassSessionId) {
          amount += value.CouponAmount || 0;
        }
      });
    });

    if (val) {
      if (checkUseCredit(sessionItem)) {
        updatedPkListToUse = packageListToUse?.map((i: any) => {
          if (
            sessionItem?.SelectedPackage?.PackageInstanceId ===
            i.PackageInstanceId
          ) {
            let sessions = i.ClassSessions;
            let sessionCount = i.SchedulesToUse || 0;
            const exactBillingPeriod = getExactBillingPeriod(
              i,
              sessionItem?.ClassSessionStartDate
            );
            if (i?.SessionType === 1) {
              sessionCount = exactBillingPeriod?.SchedulesToUse || 0;
            }
            sessions = sessions.filter((j: any) => {
              if (j.ClassSessionId === sessionItem.ClassSessionId) {
                if (i?.SessionType === 1) {
                  sessionCount = sessionCount + 1;
                }
                if (
                  sessionItem?.PaymentOption <= 1 &&
                  !sessionItem?.CanBookAsWaitlist
                ) {
                  amount = amount + sessionItem?.Cost;
                } else {
                  payLaterAmt = payLaterAmt + sessionItem?.Cost;
                }
                packageRedeemAmt = packageRedeemAmt - sessionItem?.Cost;
                return false;
              }
              return true;
            });
            // return { ...i, ClassSessions: sessions, SchedulesToUse: sessionCount };

            if (i?.SessionType === 1) {
              const billingPeriods = i?.BillingPeriods?.map((i: any) => {
                if (
                  (exactBillingPeriod?.ExpireFrom === i?.ExpireFrom &&
                    exactBillingPeriod?.ExpireTo === i?.ExpireTo &&
                    exactBillingPeriod?.PackageInstanceId ===
                      i?.PackageInstanceId) ||
                  props?.SessionSignOff
                ) {
                  return { ...i, SchedulesToUse: sessionCount };
                }
                return { ...i };
              });
              return {
                ...i,
                ClassSessions: sessions,
                SchedulesToUse: sessionCount,
                BillingPeriods: billingPeriods,
              };
            } else {
              return {
                ...i,
                ClassSessions: sessions,
                SchedulesToUse: sessionCount,
              };
            }
          } else {
            return { ...i };
          }
        });
      }
      mainSession = mainSession.map((j: any) => {
        if (j.ClassId === sessionItem?.ClassId) {
          const modifiedValues = j.values?.map((sch: any) => {
            if (sch.ClassSessionId === sessionItem?.ClassSessionId) {
              if (
                sessionItem?.PaymentOption <= 1 &&
                !sessionItem?.CanBookAsWaitlist
              ) {
                amount = amount - sessionItem?.Cost;
              } else {
                payLaterAmt = payLaterAmt - sessionItem?.Cost;
              }
              complimentaryAmt = complimentaryAmt + sessionItem?.Cost;
              return { ...sch, Complimentary: val };
            }
            return { ...sch };
          });
          return { ...j, values: modifiedValues };
        } else {
          return { ...j };
        }
      });
    } else {
      mainSession = mainSession.map((j: any) => {
        if (j.ClassId === sessionItem?.ClassId) {
          const modifiedValues = j.values?.map((sch: any) => {
            if (sch.ClassSessionId === sessionItem?.ClassSessionId) {
              if (
                sessionItem?.PaymentOption <= 1 &&
                !sessionItem?.CanBookAsWaitlist
              ) {
                amount = amount + sessionItem?.Cost;
              } else {
                payLaterAmt = payLaterAmt + sessionItem?.Cost;
              }
              complimentaryAmt = complimentaryAmt - sessionItem?.Cost;
              return { ...sch, Complimentary: val };
            }
            return { ...sch };
          });
          return { ...j, values: modifiedValues };
        } else {
          return { ...j };
        }
      });
    }

    
    const complimentaryCoupons = sessionItem?.Coupons?.map((c: any) => c.CouponId);

    mainSession = mainSession.map((sessionData: any) => {
      return {
        ...sessionData,
        values: sessionData.values.map((value: any) => {
          let updatedCoupons = value.filterCoupondata.map((coupon: any) => {
            if (complimentaryCoupons?.includes(coupon?.CouponId)) {
              return {
                ...coupon,
                AvailableCount: coupon.AvailableCount + 1, // Increase AvailableCount when removed
              };
            }
            return coupon;
          });

          return {
            ...value,
            filterCoupondata: updatedCoupons,
            OriginalCouponData: updatedCoupons,
          };
        }),
      };
    });

    

    mainSession = mainSession?.map((sessionItem: any) => {
      return {
        ...sessionItem,
        values: sessionItem.values.map((value: any) => {
          if (value.ClassSessionId === targetedClassSessionId) {  
            return {
              ...value,
              Coupons: [],
              CouponAmount: 0,
              adjustedCost: value?.Cost,
              CouponsText: null,
            };
          }
          return value;
        }),
      };
    });

    const totalOfferAmount = mainSession?.reduce(
      (sum: number, sessionItem: any) => 
        sum + sessionItem.values.reduce((innerSum: number, value: any) => innerSum + (value.CouponAmount || 0), 0),
      0
    );

    settotalDiscountAmt(totalOfferAmount);

    //amount = packageCost - packageRedeemAmt - complimentaryAmt -  totalOfferAmount;
    if(amount < 0){
      amount = 0;
    }

    // setSession(mainSession)
    handleSessionTaxCalculation(mainSession);
    setPackageCostAfterDiscount(amount);
    setPayLaterAmount(payLaterAmt);
    setDownpaymentForm({ ...downpaymentForm, paidToday: amount });
    setUseCreditAmount(packageRedeemAmt);
    setUseComplimentaryAmount(complimentaryAmt);
    setPackageListToUse(updatedPkListToUse);
  };

  const handleUseCredit = (val: any, sessionItem: any) => {
    let check = true;
    let amount = packageCostAfterDiscount;
    let payLaterAmt = payLaterAmount;
    let complimentaryAmt = useComplimentaryAmount;
    let packageRedeemAmt = useCreditAmount;
    let updatedPkListToUse: any = packageListToUse;
    let mainSession = [...session];
    const targetedClassSessionId = sessionItem?.ClassSessionId;

    mainSession.forEach((session) => {
      session.values?.forEach((value: any) => {
        if (value.ClassSessionId === targetedClassSessionId) {
          amount += value.CouponAmount || 0;
        }
      });
    });

    if (val) {
      if (sessionItem?.Complimentary) {
        mainSession = mainSession.map((j: any) => {
          if (j.ClassId === sessionItem?.ClassId) {
            const modifiedValues = j.values?.map((sch: any) => {
              if (sch.ClassSessionId === sessionItem?.ClassSessionId) {
                if (
                  sessionItem?.PaymentOption <= 1 &&
                  !sessionItem?.CanBookAsWaitlist
                ) {
                  amount = amount + sessionItem?.Cost;
                } else {
                  payLaterAmt = payLaterAmt + sessionItem?.Cost;
                }
                complimentaryAmt = complimentaryAmt - sessionItem?.Cost;
                return { ...sch, Complimentary: false };
              }
              return { ...sch };
            });
            return { ...j, values: modifiedValues };
          } else {
            return { ...j };
          }
        });
      }
      updatedPkListToUse = packageListToUse?.map((i: any) => {
        if (
          sessionItem?.SelectedPackage?.PackageInstanceId ===
          i.PackageInstanceId
        ) {
          let sessions = i.ClassSessions;
          let sessionCount = i.SchedulesToUse;
          const exactBillingPeriod = getExactBillingPeriod(
            i,
            sessionItem?.ClassSessionStartDate
          );
          if (i?.SessionType === 1) {
            sessionCount = exactBillingPeriod?.SchedulesToUse;
          }
          const expireOn = i?.PackageExpireOn
            ? i?.PackageExpireOn
            : i?.ExpireOn;
          if (
            (moment(expireOn).format("YYYY-MM-DD") >=
              moment(sessionItem?.ClassSessionStartDate).format("YYYY-MM-DD") &&
              moment(i?.ExpireFrom).format("YYYY-MM-DD") <=
                moment(sessionItem?.ClassSessionStartDate).format(
                  "YYYY-MM-DD"
                )) ||
            props?.SessionSignOff
          ) {
            if (
              (i?.SessionType === 1 && sessionCount > 0) ||
              i?.SessionType === 2
            ) {
              sessions.push(sessionItem);
              if (i?.SessionType === 1) {
                sessionCount = sessionCount - 1;
              }
              if (
                sessionItem?.PaymentOption <= 1 &&
                !sessionItem?.CanBookAsWaitlist
              ) {
                amount = amount - sessionItem?.Cost;
              } else {
                payLaterAmt = payLaterAmt - sessionItem?.Cost;
              }
              packageRedeemAmt = packageRedeemAmt + sessionItem?.Cost;
              check = true;
            } else {
              check = false;
            }
          } else {
            check = false;
          }
          // return { ...i, ClassSessions: sessions, SchedulesToUse: sessionCount };

          if (i?.SessionType === 1) {
            const billingPeriods = i?.BillingPeriods?.map((i: any) => {
              if (
                (exactBillingPeriod?.ExpireFrom === i?.ExpireFrom &&
                  exactBillingPeriod?.ExpireTo === i?.ExpireTo &&
                  exactBillingPeriod?.PackageInstanceId ===
                    i?.PackageInstanceId) ||
                props?.SessionSignOff
              ) {
                return { ...i, SchedulesToUse: sessionCount };
              }
              return { ...i };
            });
            return {
              ...i,
              ClassSessions: sessions,
              SchedulesToUse: sessionCount,
              BillingPeriods: billingPeriods,
            };
          } else {
            return {
              ...i,
              ClassSessions: sessions,
              SchedulesToUse: sessionCount,
            };
          }
        } else {
          return { ...i };
        }
      });
    } else {
      updatedPkListToUse = packageListToUse?.map((i: any) => {
        if (
          sessionItem?.SelectedPackage?.PackageInstanceId ===
          i.PackageInstanceId
        ) {
          let sessions = i.ClassSessions;
          let sessionCount = i.SchedulesToUse;
          const exactBillingPeriod = getExactBillingPeriod(
            i,
            sessionItem?.ClassSessionStartDate
          );
          if (i?.SessionType === 1) {
            sessionCount = exactBillingPeriod?.SchedulesToUse;
          }
          sessions = sessions.filter((j: any) => {
            if (j.ClassSessionId === sessionItem.ClassSessionId) {
              if (i?.SessionType === 1) {
                sessionCount = sessionCount + 1;
              }
              if (
                sessionItem?.PaymentOption <= 1 &&
                !sessionItem?.CanBookAsWaitlist
              ) {
                amount = amount + sessionItem?.Cost;
              } else {
                payLaterAmt = payLaterAmt + sessionItem?.Cost;
              }
              packageRedeemAmt = packageRedeemAmt - sessionItem?.Cost;
              check = true;
              return false;
            }
            return true;
          });
          if (i?.SessionType === 1) {
            const billingPeriods = i?.BillingPeriods?.map((i: any) => {
              if (
                (exactBillingPeriod?.ExpireFrom === i?.ExpireFrom &&
                  exactBillingPeriod?.ExpireTo === i?.ExpireTo &&
                  exactBillingPeriod?.PackageInstanceId ===
                    i?.PackageInstanceId) ||
                props?.SessionSignOff
              ) {
                return { ...i, SchedulesToUse: sessionCount };
              }
              return { ...i };
            });
            return {
              ...i,
              ClassSessions: sessions,
              SchedulesToUse: sessionCount,
              BillingPeriods: billingPeriods,
            };
          } else {
            return {
              ...i,
              ClassSessions: sessions,
              SchedulesToUse: sessionCount,
            };
          }
        } else {
          return { ...i };
        }
      });
    }

    const complimentaryCoupons = sessionItem?.Coupons?.map((c: any) => c.CouponId);

    mainSession = mainSession.map((sessionData: any) => {
      return {
        ...sessionData,
        values: sessionData.values.map((value: any) => {
          let updatedCoupons = value.filterCoupondata.map((coupon: any) => {
            if (complimentaryCoupons?.includes(coupon.CouponId)) {
              return {
                ...coupon,
                AvailableCount: coupon.AvailableCount + 1,
              };
            }
            return coupon;
          });

          return {
            ...value,
            filterCoupondata: updatedCoupons,
          };
        }),
      };
    });

    mainSession = mainSession?.map((sessionItem: any) => {
      return {
        ...sessionItem,
        values: sessionItem.values.map((value: any) => {
          if (value.ClassSessionId === targetedClassSessionId) {  
            return {
              ...value,
              Coupons: [],
              CouponAmount: 0,
              adjustedCost: value?.Cost,
              CouponsText: null,
            };
          }
          return value;
        }),
      };
    });

    const totalOfferAmount = mainSession?.reduce(
      (sum: number, sessionItem: any) => 
        sum + sessionItem.values.reduce((innerSum: number, value: any) => innerSum + (value.CouponAmount || 0), 0),
      0
    );

    settotalDiscountAmt(totalOfferAmount);
    
    if(amount < 0){
      amount = 0;
    }
    
    // setSession(mainSession)
    handleSessionTaxCalculation(mainSession, Taxes, updatedPkListToUse);
    setPackageCostAfterDiscount(amount);
    setPayLaterAmount(payLaterAmt);
    setDownpaymentForm({ ...downpaymentForm, paidToday: amount });
    setUseCreditAmount(packageRedeemAmt);
    setUseComplimentaryAmount(complimentaryAmt);
    setPackageListToUse(updatedPkListToUse);

    if (!check) {
      props?.handleNotificationMessage(
        "Appointments are beyond the expiry date or reached the maximum count",
        "error"
      );
    }
  };

  const unCheckRedeem = (sessionItem: any) => {
    let amount = packageCostAfterDiscount;
    let payLaterAmt = payLaterAmount;
    let complimentaryAmt = useComplimentaryAmount;
    let packageRedeemAmt = useCreditAmount;
    const mainSession = [...session];
    const updatedPkListToUse: any = packageListToUse?.map((i: any) => {
      if (
        sessionItem?.SelectedPackage?.PackageInstanceId === i.PackageInstanceId
      ) {
        let sessions = i.ClassSessions;
        let sessionCount = i.SchedulesToUse;
        sessions = sessions.filter((j: any) => {
          if (j.ClassSessionId === sessionItem.ClassSessionId) {
            if (i?.SessionType === 1) {
              sessionCount = sessionCount + 1;
            }
            if (
              sessionItem?.PaymentOption <= 1 &&
              !sessionItem?.CanBookAsWaitlist
            ) {
              amount = amount + sessionItem?.Cost;
            } else {
              payLaterAmt = payLaterAmt + sessionItem?.Cost;
            }
            packageRedeemAmt = packageRedeemAmt - sessionItem?.Cost;
            return false;
          }
          return true;
        });
        return { ...i, ClassSessions: sessions, SchedulesToUse: sessionCount };
      } else {
        return { ...i };
      }
    });
    const sessionData = mainSession.map((j: any) => {
      if (j.ClassId === sessionItem?.ClassId) {
        const modifiedValues = j.values?.map((sch: any) => {
          if (
            sch.ClassSessionId === sessionItem?.ClassSessionId &&
            sessionItem?.Complimentary
          ) {
            if (
              sessionItem?.PaymentOption <= 1 &&
              !sessionItem?.CanBookAsWaitlist
            ) {
              amount = amount + sessionItem?.Cost;
            } else {
              payLaterAmt = payLaterAmt + sessionItem?.Cost;
            }
            complimentaryAmt = complimentaryAmt - sessionItem?.Cost;
            return { ...sch, Complimentary: false };
          }
          return { ...sch };
        });
        return { ...j, values: modifiedValues };
      } else {
        return { ...j };
      }
    });
    setPackageCostAfterDiscount(amount);
    setPayLaterAmount(payLaterAmt);
    setDownpaymentForm({ ...downpaymentForm, paidToday: amount });
    setUseCreditAmount(packageRedeemAmt);
    setUseComplimentaryAmount(complimentaryAmt);
    setPackageListToUse(updatedPkListToUse);
  };
  const BackButtonComponent = (props: any) => {
    return (
      <button
        type="button"
        className="btn btn-primary buz-back-btn btn-sm mb-2"
        onClick={() => props?.onClick()}
      >
        <i className="fas fa-arrow-left"></i>
      </button>
    );
  };

  const handleSelectPackageBillingPeriod = (value: any, dataItem: any) => {
    setPackageSelection({
      ...packageSelectionRef.current,
      SelectedBillingPeriod: value,
    });
  };

  const handleDropdownOpen = (event: DropDownListOpenEvent) => {
    event.nativeEvent.stopPropagation();
  };

  const handleCardClick = (e: React.MouseEvent, selectedItem: any) => {
    e.preventDefault();
    const target = e.target as HTMLElement;
    if (
      target.closest(".k-widget.k-dropdown.customDropdown.bz-oneonone-dropdown")
    ) {
      return;
    }

    handleSelection(selectedItem);
  };

  const handleClosePackageSelectionDialog = () => {
    setShowPackageDialog(false);
  };

  const MyPackagesItemRender = (props: any) => {
    const { dataItem, isSelected, handleSelection } = props;
    const item = dataItem;
    const schedulesToUse =
      item.SessionType === 2
        ? "Unlimited"
        : `${item?.SchedulesUsed} of ${
            item?.SchedulesToUse + item?.SchedulesUsed
          }`;

    const billingPeriods =
      item?.SessionType === SessionTypeEnum.LIMITED
        ? item?.BillingPeriods?.filter((i: any) => i?.SchedulesToUse > 0) || []
        : item?.BillingPeriods;

    const PackageType = PackageTypesList.find(
      (i: any) => i?.Id === item?.Type
    )?.Name;

    const findItem = isSelected
      ? packageSelection?.SelectedBillingPeriod?.PackageInstanceId ===
        item?.PackageInstanceId
        ? packageSelection?.SelectedBillingPeriod
        : null
      : null;

    return (
      <div
        key={item?.PackageInstanceId?.toString()}
        className={`card mb-1 mb-3 packagelistview pointer appthover`}
        style={isSelected ? { borderColor: "#008db9" } : {}}
        onClick={(e) => handleCardClick(e, item)}
      >
        <div className="card-body">
          <div className="package-details">
            <div className="flex items-center">
              <div>
                <div className="package-name d-flex justify-content-between">
                  <span className="">{PackageType}</span>
                  <input
                    type="radio"
                    name="package-selection"
                    checked={isSelected}
                    onChange={() => handleSelection(item)}
                    className="mr-2"
                  />
                </div>
                <div className="package-name">
                  <span className="primary-heading">{item?.Name}</span>
                </div>
                <div>
                  <div className="d-flex align-items-center mb-1">
                    <small className="" style={{ fontSize: "11px" }}>
                      {"Select a Period with Unused Sessions"}
                    </small>
                    <BuzopsTooltip
                      position={"bottom"}
                      html={
                        <a
                          title={`Choose the time period where the unused sessions were originally credited. This allows you to sign off and apply them for use.`}
                          className="pl-2 bz-cursor-pointer"
                        >
                          <i
                            style={{ fontSize: "16px" }}
                            className="fa fa-info-circle"
                          ></i>
                        </a>
                      }
                    />
                  </div>
                  <DropDownList
                    data={billingPeriods}
                    value={findItem}
                    disabled={!isSelected}
                    onChange={(event) => {
                      event.nativeEvent.stopPropagation();
                      handleSelectPackageBillingPeriod(event?.value, item);
                    }}
                    textField="ExpireFrom"
                    dataItemKey="ExpireFrom"
                    itemRender={(
                      li: React.ReactElement<HTMLLIElement>,
                      itemProps: ListItemProps
                    ) => billingPeriodItemRender(li, itemProps, item)}
                    valueRender={(
                      el: React.ReactElement<HTMLSpanElement>,
                      value: any
                    ) => billingPeriodValueRender(el, value, findItem, item)}
                    style={{ width: "100%" }}
                    className={"customDropdown bz-oneonone-dropdown"}
                    onOpen={handleDropdownOpen}
                    listNoDataRender={() => (
                      <span className={"bzo-noData-found"}>
                        No unused sessions available for sign-off
                      </span>
                    )}
                  />
                  {isSelected && (!packageSelection?.SelectedBillingPeriod || (packageSelection?.dataItem?.PackageInstanceId !== packageSelection?.SelectedBillingPeriod?.PackageInstanceId)) && (
                    <Error>{"Please select the Billing Period"}</Error>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleSelection = (dataItem: any) => {
    setPackageSelection({
      ...packageSelectionRef.current,
      dataItem: dataItem,
      // SelectedBillingPeriod:null
    });
  };

  const handlePackageSelection = (
    dataItem: any,
    SelectedBillingPeriod: any = null
  ) => {
    if (SelectedBillingPeriod) {
      setSelectedAppt({
        BillingPeriod: SelectedBillingPeriod,
        Package: dataItem,
      });
      if (
        selectedAppt?.Package?.PackageInstanceId !== dataItem?.PackageInstanceId
      ) {
        handlePackageSelectionForClass(dataItem, session[0]?.values?.[0]);
      }
    }
  };

  const handleProceedWithoutPackage=()=> {
    setShowPackageError(false)
    handleClosePackageSelectionDialog()
    setSelectedAppt({
      BillingPeriod: null,
      Package: null,
    })
    setPackageSelection({
      dataItem: null,
      SelectedBillingPeriod: null,
    })
    if(useCreditAmount > 0){
      handleUseCredit(false,session[0]?.values[0])
    }
  }

  const handleProceed = (withPackages = true) => {
    if (withPackages && !packageSelection?.dataItem) {
      setShowPackageError(true)
      // const errorMsg = "Please Select Package";
      // props?.handleNotificationMessage(errorMsg, "error");
      return;
    } else if (
      withPackages &&
      packageSelection?.dataItem?.BillingPeriods?.length > 0 &&
      !packageSelection?.SelectedBillingPeriod
    ) {
      setShowPackageError(false)

      // const errorMsg = "Please Select Billing Period";
      // props?.handleNotificationMessage(errorMsg, "error");
      return;
    }  else if (
      withPackages &&
      packageSelection?.dataItem?.BillingPeriods?.length > 0 &&
      packageSelection?.SelectedBillingPeriod?.PackageInstanceId !==packageSelection?.dataItem?.PackageInstanceId
    ) {
      setShowPackageError(false)
      // const errorMsg = "Please Select Billing Period";
      // props?.handleNotificationMessage(errorMsg, "error");
      return;
    }else if (withPackages) {
      setShowPackageError(false)

      setShowPackageDialog(false);
      handlePackageSelection(
        packageSelection?.dataItem,
        packageSelection?.SelectedBillingPeriod
      );
    }
  };

  const PackagesDialog = () => {
    const filteredPackages = PackagesData;
    return (
      <Dialog
        title={"Packages"}
        onClose={handleClosePackageSelectionDialog}
        width={"35%"}
        height={"550px"}
      >
        <div className=" mb-1">
          <span>
            {
              "Use your remaining sessions from the below packages to schedule past group sessions."
            }
          </span>
        </div>

        {showPackageError && !packageSelection?.dataItem && (
          <div className="alert alert-danger mb-1 py-2 px-3" role="alert">
            {"Please select Package"}
          </div>
        )}

        {filteredPackages?.map((i: any) => (
          <MyPackagesItemRender
            key={i?.PackageInstanceId}
            dataItem={i}
            isSelected={
              packageSelection?.dataItem?.PackageInstanceId ===
              i?.PackageInstanceId
            }
            handleSelection={handleSelection}
          />
        ))}
        <DialogActionsBar>
          <div className="d-flex w-100 justify-content-between align-items-end">
            <div>
              <Button
                className="btn btn-link d-block text-start text-left k-button k-primary"
                onClick={() => handleProceedWithoutPackage()}
              >
                Continue without package
              </Button>
            </div>
            <div>
              <Button primary={true} onClick={() => handleProceed(true)}>
                Continue with package
              </Button>
            </div>
          </div>
        </DialogActionsBar>
      </Dialog>
    );
  };
  const handleShowCheckout = () => {
    setshowCheckout(true);
  }

  console.log('showCheckout',showCheckout);
  

  const handleChangePackage = () => {
    setShowPackageDialog(true);
  };

  const widthOfDevice = window.innerWidth;
  if (loading) {
    return (
      <>
        <BuzopsLoader type="list" />
      </>
    );
  }
  const inHouseGroup =
    checkoutType === "inhouse-class"
      ? "bz-inhouse-order-summary"
      : "bz-online-order-summary";
  return (
    <div className={`col-12 bz-checkout-page bz-checkout-${checkoutType}`}>
      <div className="checkout-page row d-flex justify-content-between custom-grid-box">
        <div
          className={`payment-panel-form col-xs-12 col-sm-12 col-md-12 ${props?.location?.state?.from === "calender" ? "col-lg-12" : "col-lg-8"
            } px-0 mb-3 mb-lg-0`}
        >
          {!staffPage && (
            <Prompt
              when={isFormChanged}
              message={
                "You have unsaved changes, Are you sure you want to leave?"
              }
            />
          )}
          {checkoutType !== "inhouse-class" && (
            <BackButtonComponent onClick={() => handleNotNowRedirection()} />
          )}
             {(props?.location?.state?.from === "calender" && !showCheckout) && (
            <div style={{textAlign:"right"}}>
            <BuzopsButton
              label={"Checkout"}
              primary
              onClick={() => handleShowCheckout()}
            />
            </div>
          )}
          {(!showCheckout || props?.location?.state?.from !== "calender") && (
          <PaymentPanelForm
            IsFromCalendar={
              props?.location?.state?.from === "calender" ? true : false
            }
            staffPage={staffPage}
            SessionSignOff={props?.SessionSignOff}
            PackagesData={PackagesData}
            selectedAppt={selectedAppt}
            handleChangePackage={handleChangePackage}
            UserMemberId={userMemberId}
            handleAddPaymentMode={handleAddPaymentMode}
            handleSubmit={handleSubmit}
            // handlePackageSelection={handlePackageSelection}
            handleForm={(val: string) => handleForm(val)}
            handleRemoveSession={(item: any) => handleRemoveSession(item)}
            paymentMethod={paymentMethod}
            selected={selected}
            totalClassSessions={totalClassSessions}
            session={session}
            page={checkoutType}
            selectedClassesType={props?.type}
            paymentGatewayType={paymentGatewayType}
            bankFormValues={bankFormValues}
            cardImageIcon={cardImageIcon}
            clientFormValues={clientFormValues}
            packageCostAfterDiscount={packageCostAfterDiscount}
            packageCost={packageCost}
            clientSubmitDetails={clientSubmitDetails}
            selectedClient={selectedClient}
            selectedPaymentProfile={selectedPaymentProfile}
            handleBankSubmit={handleBankSubmit}
            handleClientSubmit={handleClientSubmit}
            handleAddClient={handleAddClient}
            handleClientSelectionSubmit={handleClientSelectionSubmit}
            handleUseCreditSubmit={handleUseCreditSubmit}
            cardFormValues={cardFormValues}
            paymentProfile={paymentProfile}
            clientsList={clientsList}
            checkPaymentProfileNotRequired={checkPaymentProfileNotRequired}
            handleCardSubmit={handleCardSubmit}
            cardSubmitBtnLoading={cardSubmitBtnLoading}
            bankSubmitBtnLoading={bankSubmitBtnLoading}
            handleChangeClient={handleChangeClient}
            IsTaxSkipped={IsTaxSkipped}
            checkOfflineReferenceRequired={checkOfflineReferenceRequired}
            originalPackage={packageItem}
            // packageToUse={packageToUse}
            packageListToUse={packageListToUse}
            paymentSubmitProfile={paymentSubmitProfile}
            // selectedPackageToUse={selectedPackageToUse}
            // selectedValue={selectedValue}
            // handlePickPackageOrCard={(val: string) => handlePickPackageOrCard(val)}
            downpaymentForm={downpaymentForm}
            clientSubmitBtnLoading={clientSubmitBtnLoading}
            clientSearchLoading={clientSearchLoading}
            handleAddMoreClasses={handleAddMoreClasses}
            handlePackageSelectionForClass={(val: any, item: any) =>
              handlePackageSelectionForClass(val, item)
            }
            handleAutoselectPackage={() => handleAutoselectPackage()}
            handleUseCredit={(val: any, sessionItem: any) =>
              handleUseCredit(val, sessionItem)
            }
            handleComplimentary={(val: any, sessionItem: any) =>
              handleComplimentary(val, sessionItem)
            }
            handleClientSelecton={(val: any) => handleClientSelecton(val)}
            handleChangeSavedCard={(val: any, dataItem = null) =>
              handleChangeSavedCard(val, dataItem)
            }
            handleCardImageIcon={(url: any) => handleCardImageIcon(url)}
            onSelect={(e: any) => onSelect(e)}
            checkUseCredit={(e: any) => checkUseCredit(e)}
            ComplimentaryPermission={ComplimentaryPermission}
            updateSelectedClientData={updateSelectedClientData}
            fetchClientData={fetchClientData}
            appliedCoupons={appliedCoupons}
            visibleCouponField={visibleCouponField}
            filterCoupondata={filterCoupondata}
            handleGroupCoupon={(val:any,item:any) => handleGroupCoupon(val,item)}
            handleGroupChangeCoupon={(val: string,item:any) => handleGroupChangeCoupon(val,item)}
            coupon={coupon}
            handleRemoveGroupCoupon={handleRemoveGroupCoupon}
            //filterCouponChange={filterCouponChange}
            makePaymentProfileAsDefault={makePaymentProfileAsDefault}
          />
        )}
        </div>
        {!(props?.location?.state?.from === "calender") || showCheckout ? (
        <div
        className={`order-summary-form groupordersummary col-xs-12 col-sm-12 col-md-12 ${
          props?.location?.state?.from === "calender" ? "col-lg-8" : "col-lg-4"
        } ${inHouseGroup}`}
      >
      
          <OrderSummary
            IsFromCalendar={
              props?.location?.state?.from === "calender" ? true : false
            }
            session={session}
            page={checkoutType}
            disable={disable}
            Taxes={Taxes}
            TotalTaxAmount={TotalTaxAmount}
            PredictedTaxes={PredictedTaxes}
            PredictedTotalTaxAmount={PredictedTotalTaxAmount}
            filterCoupondata={filterCoupondata}
            filterCouponChange={(e:any) => filterCouponChange(e)}
           
          
            packageCost={packageCost}
            packageCostAfterDiscount={packageCostAfterDiscount}
            couponAmount={couponAmount}
            useCreditAmount={useCreditAmount}
            useComplimentaryAmount={useComplimentaryAmount}
            payLaterAmount={payLaterAmount}
            termsandConditions={termsandConditions}
            handleIsTaxSkipped={handleIsTaxSkipped}
            handleIsSessionTaxSkipped={handleIsSessionTaxSkipped}
            appliedCoupons={appliedCoupons}
            btnLoading={btnLoading}
            salesPersons={salesPersons}
            customRate={customRate}
            IsCustomService={props?.location?.state?.customService ? true : false}
            selectedSalesPerson={selectedSalesPerson}
            selectedPaymentProfile={selectedPaymentProfile}
            downpaymentForm={downpaymentForm}
            clientFormValues={clientFormValues}
            IsTaxSkipped={IsTaxSkipped}
            visibleCouponField={visibleCouponField}
            handleSalesPersonSelection={(data: any) =>
              handleSalesPersonSelection(data)
            }
      
            handleTermsConditionDialog={(value:any) => handleTermsConditionDialog(value)}
            handleVisibleCouponField={() => handleVisibleCouponField()}
            handleRemoveCoupon={(index: number) => handleRemoveCoupon(index)}
            handleAgreement={(params: Object) => handleCheckoutPage("agreement", params)}
            signature={signature}
            signatureText={signatureText}
            signatureFont={signatureFont}
            signatureType={signatureType}
            coupon={coupon}
            staffPage={staffPage}
            couponBtnLoading={couponBtnLoading}
            handleChangeCoupon={(val: string) => handleChangeCoupon(val)}
            handlePayNow={(params: Object) => handleClientConfirmation('paynow', params)}
            allClientsList={AllClientsList}
            calculateTotalSessionTax={calculateTotalSessionTax}
            updateSelectedClientData={(params: Object) => updateSelectedClientData(params)}
            selectedClassesType={props?.type}
            SessionSignOff={props?.SessionSignOff}
            totalDiscountAmt={totalDiscountAmt}
          />
        </div>
        ) : null}
        {confirmClientDialog && renderClientConfirmationDialog()}
   
        {showAdditionalPayment?.dialog && 
        <AdditionalPayment 
            fetchPaymentProfiles={fetchPaymentProfiles}
            userMemberId={userMemberId}
            setShowAdditionalPayment={setShowAdditionalPayment}
            paymentProfile={paymentProfile}
            PurchaseAmount={!IsTaxSkipped ? downpaymentForm?.paidToday : downpaymentForm?.paidToday + calculateTotalSessionTax()}
            CreditAmount={paymentSubmitProfile?.Credit}
            showAdditionalPayment={showAdditionalPayment}
            handleChangeAdditionalPaymentProfile={handleChangeAdditionalPaymentProfile}
            handleAddAdditionalPayment={handleAddAdditionalPayment}
        />}
        {termsandConditionsDialog && <TermsAndConditions handleTermsConditionDialog={handleTermsConditionDialog} handleTermsConditions={handleTermsConditions} />}
        {submit && renderClassCheckoutDialog()}
        {showPackageDialog && PackagesDialog()}
      </div>
    </div>
  );
};

export default withNotification(Checkout);
