import React, { useState } from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import BuzopsButton from "generic-components/BuzopsButton";
import moment from "moment";
import { CurrencyFormat, checkIfUserAddPaymentGateway, checkIfUserCanAddBankAccount, paymentDropdownItems } from "utils";
import { UnfreezeRecurrenceOption } from "utils/form-utils";
import PrimaryMember from "../../../PrimaryMember.png";
import SubMember from "../../../SubMember.png";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import { Upload } from "@progress/kendo-react-upload";
const PlanUnfreeze = (props: any) => {
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);

  const [addNewItem, setAddNewItem] = useState<any>(null);

  const onCardOrBankAdd = (e: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };

  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
  };
  const handleSuccessClosePaymethodDialog = async (msg = null,modeVal = null) => {
    if (msg) {
      props?.handleNotificationMessage(msg, "success");
    }
    setShowPaymentDialog(!showPaymentDialog);
    await props?.fetchPaymentProfiles(props?.userData?.MainUserId,modeVal);
  };

  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
         className="bz-addCard-dialog"
         title={`${
          addNewItem !== "addOther"
            ? checkIfUserCanAddBankAccount()
              ? "Add Bank or Card"
              : "Add Card"
            : "Add Payment Mode"
        }`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation
            staffPage={props?.staffPage}
            addNewItem={addNewItem}
            handleClosePaymethodDialog={handleClosePaymethodDialog}
            handleSuccessClosePaymethodDialog={
              handleSuccessClosePaymethodDialog
            }
            UserMemberId={props?.userData?.UserMemberId}
          paymentProfile={props?.paymentProfiles}

          />
        </Dialog>
      </>
    );
  };
  return (
    <>
      {/* <div className="bz-plan-note">
        <p className="mb-0">
          <Notify key="info" type={{ style: "warning" }}>
            {`Add-on will freeze along with plan`}
          </Notify>
        </p>
      </div> */}
      <div className="card card-fluid bz-sub-card mb-2 bz-card bz-cancel-package bz-unfreeze-page">
        <div className="card-header py-2 bz-no-background">
          <div className="bz-row no-gutters">
            <div className="col-6 col-md-6 p-0 mb-sm-0">
              <span className="h6 bz-border-Inactive-sm bz--left-border pl-2">
                <span className="bz-card-heading text-capitalize">
                  <p
                    className={
                      "card-subtitle d-flex align-items-center mb-2 text-muted"
                    }
                  >
                    {props?.selectedPlan?.MemberType === 0 ? (
                      <img src={PrimaryMember} className="bz-member-icon" />
                    ) : (
                      <img src={SubMember} className="bz-member-icon" />
                    )}
                    {props?.selectedPlan?.MemberName}
                  </p>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="card-body p-0 position-relative">
          <div className="bz-row no-gutters">
            <div className="col">
              {props?.unfreezeDetails?.Result.map((dataItem: any) => {
                const {
                  EntityType,
                  EntityName,
                  ServiceType,
                  DurationType,
                  Duration,
                  EndDate,
                  FreezeStartDate,
                  FreezeEndDate,
                  FreezeRate,
                  EntityId,
                  FrozenDuration,
                  Occurrence,
                  Frequency,
                  FrequencyDescription,
                  RecurringAmount,
                  RepeatFrequency,
                  UnfreezeFee,
                  RemainingSessions,
                  ExcessAmount,
                  RemainingAmount,
                  PaidWorkOuts,
                  IsNeedToUnfreeze,
                  IsNeedToCharge,
                  CancellationStatus,
                  RecurrenceStartDate,
                  RecurrencePaymentOption,
                  NoOfInstallments,
                  AmountPaidToday,
                } = dataItem;
                return (
                  <>
                    <div className="p-4">
                      <h6 className="card-title bz-fw-600 bz-article">
                        {EntityName} <small>({ServiceType})</small>
                      </h6>
                      <div className="">
                        <div className="bz-row no-gutters">
                          {EntityType === 1 && (
                            <>
                              <div className="col-12 col-lg-12">
                                <p className="row">
                                  <span className="col bz-article">
                                    Duration
                                  </span>
                                  :{" "}
                                  <span className="col">
                                    {DurationType === 1
                                      ? `${Duration} Months`
                                      : "NA being month to month Membership"}
                                  </span>
                                </p>
                              </div>
                              <div className="col-12 col-lg-12">
                                <p className="row">
                                  <span className="col bz-article">
                                    End Date
                                  </span>
                                  :{" "}
                                  <span className="col">
                                    {DurationType === 1
                                      ? moment(EndDate).format("L")
                                      : "NA being month to month Membership"}
                                  </span>
                                </p>
                              </div>
                            </>
                          )}
                          {EntityType === 4 && (
                            <>
                                <div className="col-12 col-lg-12">
                                  <p className="row">
                                    <span className="col bz-article">
                                      Repeat Every
                                    </span>
                                    :{" "}
                                    <span className="col">
                                      {`${Frequency === 1 ? "NA" : RepeatFrequency} - ${FrequencyDescription}`}
                                    </span>
                                  </p>
                                </div>
                              <div className="col-12 col-lg-12">
                                <p className="row">
                                  <span className="col bz-article">End</span>:{" "}
                                  <span className="col">
                                    {Occurrence > 0
                                      ? `After ${Occurrence} Occurrences`
                                      : "as never"}
                                  </span>
                                </p>
                              </div>
                            </>
                          )}
                          <div className="col-12 col-lg-12">
                            <p className="row">
                              <span className="col bz-article">
                                Freeze Start Date
                              </span>
                              :{" "}
                              <span className="col">
                                {moment(FreezeStartDate).format("L")}
                              </span>
                            </p>
                          </div>
                          <div className="col-12 col-lg-12">
                            <p className="row">
                              <span className="col bz-article">
                                Freeze End Date
                              </span>
                              :{" "}
                              <span className="col">
                                {moment(FreezeEndDate).format("L")}
                              </span>
                            </p>
                          </div>
                          <div className="col-12 col-lg-12">
                            <p className="row">
                              <span className="col bz-article">
                                Frozen duration
                              </span>
                              : <span className="col">{FrozenDuration ? FrozenDuration : `NA since unfreezing on the same day`}</span>
                            </p>
                          </div>
                          {(EntityType === 1 || EntityType === 4) && (
                            <>
                              <div className="col-12 col-lg-12">
                                <p className="row">
                                  <span className="col bz-article">
                                    Recurring Amount
                                  </span>
                                  :{" "}
                                  <span className="col">
                                    {CurrencyFormat(RecurringAmount)}
                                  </span>
                                </p>
                              </div>
                              <div className="col-12 col-lg-12">
                                <p className="row">
                                  <span className="col bz-article">Prorated amount</span>
                                  :{" "}
                                  <span className="col">
                                    {CurrencyFormat(UnfreezeFee)}
                                  </span>
                                </p>
                              </div>
                            </>
                          )}
                          {EntityType === 3 && (
                            <>
                              <div className="col-12 col-lg-12">
                                <p className="row">
                                  <span className="col bz-article">
                                    Remaining Sessions
                                  </span>
                                  :{" "}
                                  <span className="col">
                                    {RemainingSessions}
                                  </span>
                                </p>
                              </div>
                              <div className="col-12 col-lg-12">
                                <p className="row">
                                  <span className="col bz-article">
                                    Individual Schedule cost ($)
                                  </span>
                                  :{" "}
                                  <span className="col">
                                    {RemainingSessions}
                                  </span>
                                </p>
                              </div>
                              <div className="col-12 col-lg-12">
                                <p className="row">
                                  <span className="col bz-article">
                                    Remaining Amount ($)
                                  </span>
                                  :{" "}
                                  <span className="col">{RemainingAmount}</span>
                                </p>
                              </div>
                              <div className="col-12 col-lg-12">
                                <p className="row">
                                  <span className="col bz-article">
                                    Paid Workouts
                                  </span>
                                  : <span className="col">{PaidWorkOuts}</span>
                                </p>
                              </div>
                              <div className="col-12 col-lg-12">
                                <p className="row">
                                  <span className="col bz-article">
                                    Excess Amount
                                  </span>
                                  : <span className="col">{ExcessAmount}</span>
                                </p>
                              </div>
                            </>
                          )}
                          <div className="form-group col-12 bz-payment-profile-options checkbox-container">
                            <Checkbox
                              value={IsNeedToUnfreeze}
                              disabled={EntityType !== 1}
                              onChange={(e) =>
                                props?.handleUnFreezeChange(
                                  e.value,
                                  "IsNeedToUnfreeze",
                                  EntityId
                                )
                              }
                              label={"Do you want to unfreeze today?"}
                            />
                          </div>
                          {IsNeedToUnfreeze && (
                            <div className="form-group col-12 bz-payment-profile-options checkbox-container">
                              <Checkbox
                                value={IsNeedToCharge}
                                disabled={!IsNeedToUnfreeze}
                                onChange={(e) =>
                                  props?.handleUnFreezeChange(
                                    e.value,
                                    "IsNeedToCharge",
                                    EntityId
                                  )
                                }
                                label={"Do you want to charge the amount?"}
                              />
                            </div>
                          )}
                          {EntityType === 3 && (
                            <>
                              {" "}
                              <div className="form-group col-12 bz-payment-profile-options">
                                <div className="bzc-provider-name">
                                  <label className="mb-2 d-inline-block k-label">
                                    Amount Pay Today
                                  </label>
                                  <div className="bzc-time-sec">
                                    <NumericTextBox
                                      id={`CancellationFee`}
                                      name={`CancellationFee`}
                                      min={0}
                                      format={"c2"}
                                      className="k-dropdown-width-100"
                                      value={FreezeRate}
                                      disabled={CancellationStatus !== 1}
                                      onChange={(e) =>
                                        props?.handleUnFreezeChange(
                                          e.target.value,
                                          "CancellationFee",
                                          EntityId
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-12 bz-payment-profile-options">
                                <div className="bzc-provider-name">
                                  <label className="mb-2 d-inline-block k-label">
                                    Reccurence Start Date
                                  </label>
                                  <div className="bzc-time-sec">
                                    <DatePicker
                                      id={`RecurrenceStartDate${EntityId}`}
                                      name={`RecurrenceStartDate${EntityId}`}
                                      value={RecurrenceStartDate}
                                      onChange={(e) =>
                                        props?.handleUnFreezeChange(
                                          e.value,
                                          "RecurrenceStartDate",
                                          EntityId
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-12 bz-payment-profile-options">
                                <div className="bzc-provider-name">
                                  <label className="mb-2 d-inline-block k-label">
                                    Reccurence Options
                                  </label>
                                  <div className="bzc-time-sec">
                                    <DropDownList
                                      id={`RecurrencePaymentOption${EntityId}`}
                                      name={`RecurrencePaymentOption${EntityId}`}
                                      data={UnfreezeRecurrenceOption}
                                      textField="text"
                                      dataItemKey="id"
                                      value={
                                        UnfreezeRecurrenceOption?.filter(
                                          (i: any) =>
                                            i?.id === RecurrencePaymentOption
                                        )?.[0]
                                      }
                                      onChange={(e) =>
                                        props?.handleUnFreezeChange(
                                          e?.value?.id,
                                          "RecurrencePaymentOption",
                                          EntityId
                                        )
                                      }
                                      disabled={
                                        AmountPaidToday === RemainingAmount
                                      }
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-12 bz-payment-profile-options">
                                <div className="bzc-provider-name">
                                  <label className="mb-2 d-inline-block k-label">
                                    Number of Installments
                                  </label>
                                  <div className="bzc-time-sec">
                                    <NumericTextBox
                                      id={`NoOfInstallments${EntityId}`}
                                      name={`NoOfInstallments${EntityId}`}
                                      min={0}
                                      className="k-dropdown-width-100"
                                      value={NoOfInstallments}
                                      disabled={
                                        AmountPaidToday === RemainingAmount
                                      }
                                      onChange={(e) =>
                                        props?.handleUnFreezeChange(
                                          e.target.value,
                                          "NoOfInstallments",
                                          EntityId
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="col-sm-6 col-lg-5 bzc-border-left position-relative">
              <div className="p-4 h-100">
                <div className={"bz-row no-gutters"}>
                  {props?.unFreezeTotalAmount > 0 && (
                    <>
                      <div className="form-group col-12 bz-payment-profile-options">
                        <div className="bzc-provider-name">
                          <label className="mb-2 d-inline-block k-label">
                            Payment Profile
                          </label>
                          <div className="">
                            <DropDownList
                              id={`PaymentGatewayProfileId`}
                              name={`PaymentGatewayProfileId`}
                              data={props?.paymentProfiles}
                              textField="MaskedCCNumber"
                              // dataItemKey="PaymentProfileID"
                              valueRender={(
                                el: React.ReactElement<HTMLSpanElement>,
                                value: any
                              ) => props?.valueRender(el, value)}
                              itemRender={props?.itemRender}
                              onChange={(e) => {
                                props?.handleUnFreezeChange(
                                  e.value,
                                  "paymentProfile",
                                  0
                                );
                              }}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                        {props?.showErrorMessage(
                          "PaymentGatewayProfileId",
                          "Payment Profile"
                        )}
                      </div>
                      {checkIfUserAddPaymentGateway() && <div className="addNewBtn mb-2">
                        <DropDownButton
                          text="Add New"
                          onItemClick={(e: any) => onCardOrBankAdd(e)}
                          items={paymentDropdownItems()?.filter((i:any)=>i?.visible===true)}
                          look="flat"
                          className="btn btn-link add-new-btn"
                        />
                      </div>}
                    </>
                  )}
                   {props?.unfreezeDetails?.CardTypeId === -2  && (
                <>
                  <div
                    className={`d-flex flex-column pt-2`}
                  >
                    <label className="mb-2 d-inline-block k-label">
                    Transaction Reference Number
                          </label>
                    <div className="bz-content-body">
                      <div className={"k-form-field-wrap"}>
                        <Input
                          onChange={(event) =>
                            props?.handleChangePaymentAttributes(
                              {
                                Reference: event?.target?.value,
                                ReferrenceDocument: props?.paymentAttributes?.ReferrenceDocument,
                              })
                          }
                          value={props?.paymentAttributes?.Reference}
                          type={"text"}
                          style={{ width: "100%" }}
                          id={"refNumber"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-12 bz-payment-profile-options pt-2">
                  <div className={`d-flex flex-column`}>
                    <label className="mb-2 d-inline-block k-label">
                    Reference Document
                    </label>
                    <div className="bz-content-body">
                      <div className={"k-form-field-wrap"}>
                        {/* <input type="file" /> */}
                        <Upload
                          autoUpload={false}
                          batch={false}
                          restrictions={{
                            // allowedExtensions: [".jpg", ".png", ".jpeg"],
                            maxFileSize: 1000000,
                          }}
                          showActionButtons={false}
                          multiple={false}
                          onAdd={props?.onAdd}
                          withCredentials={false}
                          onRemove={props?.onRemove}
                          files={props?.originalFiles}
                        />
                      </div>
                    </div>
                    </div>
                  </div>
                </>
              )}
                  <div className="form-group col-12 bz-payment-profile-options">
                    <div className="bzc-provider-name">
                      <label className="mb-2 d-inline-block k-label">
                        Comments
                      </label>
                      <div className="">
                        <textarea
                          className="form-control"
                          onChange={(e) =>
                            props?.handleUnFreezeChange(
                              e.target.value,
                              "Comments",
                              0
                            )
                          }
                        ></textarea>
                      </div>
                    </div>
                    {props?.showErrorMessage("Comments", "Comments")}
                  </div>
                </div>
                <div className="form-group bz-payment-profile-options mb-0 mb-sm-3">
                  <div className="d-flex align-items-center bz-refund-card justify-content-start mb-4 mb-sm-0 mr-md-3">
                    <div>
                      <div className="bz-badge-circle rounded-circle mr-2">
                        <i className="fas fa-dollar-sign"></i>
                      </div>
                    </div>
                    <div className="">
                      <div className="m-0 bz-bd-count-card-title">
                        {CurrencyFormat(props?.unFreezeTotalAmount)}
                      </div>
                      <span className="mb-0">Total Amount</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 mt-2">
          <div className="float-right">
            <BuzopsButton
              label={"UnFreeze"}
              loading={props?.btnLoading}
              onClick={() => props?.handleSubmitUnfreeze()}
              className="m-0"
            />
          </div>
        </div>
      </div>
      {showPaymentDialog && PaymethodDialog()}
    </>
  );
};

export default PlanUnfreeze;
