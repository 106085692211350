import withNotification from "components/Hoc/withNotification";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import { CheckUserMemberStatus } from "utils";
import ClientNameWithMemberType from "../ClientNameWithMemberType";
import { Button } from "@progress/kendo-react-buttons";
import BuzopsLongNameHandler from "generic-components/BuzopsLongNameHandler";
const Plans = (props: any) => {
  const [availPlans, setAvailPlans] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);
  const service = new TenantService();

  useEffect(() => {
    fetchMemberPlans();
  }, []);

  const fetchMemberPlans = async () => {
    let memberId = props?.userData?.UserMemberId;
    const req = {
      PageNumber: 0,
      PageSize: 32767,
      UserMemberId: memberId,
      FetchAllStatus:true,
      IncludeCustomFields:false
    };
    if (memberId) {
      setLoading(true);
      const res = await service?.getAllMemberPlanSummary(req);
      setLoading(false);
      setAvailPlans(res);
    }
  };

  
  const CheckSubscribeAddon=(SubscriptionStatus:any)=>{
    return SubscriptionStatus === "Suspended" || SubscriptionStatus === "In-Active" || SubscriptionStatus === "Expired" || SubscriptionStatus === "Cancelled"
  }

  const handleSubscribeAddons=(val:any)=>{
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    props.handleSelectionItem(val?.MemberPlanId, val?.planStatusDescription, "purchaseaddon",val);
  }
  return (
    <>
      {loading ? (
        <>
          {" "}
          <BuzopsLoader type={"list"} />
        </>
      ) : (
        <>
          {" "}
          {availPlans.length === 0 ? (
            <div className={"bz-norecords-available text-muted"}>
              No Subscribed Memberships{" "}
            </div>
          ) : (
            <div className={"row"}>
              {availPlans.map((val: any, ind: any) => {
                return (
                  <div
                    className={"col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4"}
                  >
                    <div
                      className={`card bz-service-card bz-plans-card pkg-card-padding bz-shadow bz-client-icon   ${val?.planStatusDescription} mb-3`}
                    >
                      <div
                        className={"card-body p-3"}
                        onClick={() => {
                          props.handleSelectionItem(
                            val?.MemberPlanId,
                            val?.planStatusDescription,
                            "plan",
                            val
                          );
                        }}
                      >
                        <div className={"bz-service-card-inner pb-0 pl-0"}>
                          <h5
                            className={"card-title d-flex align-items-center"}
                          >
                            <span className="bz-plan-title">
                              <BuzopsLongNameHandler
                                name={val.PlanName}
                                maxLength={24}
                                className="taxName-tooltip"
                              />
                            </span>

                            <span
                              className={`badge badge-status-${(((val?.RecurringDate !== "N/A" 
                                && val?.CancellationDate != null && 
                                val?.CancellationDate < val?.RecurringDate
                                ) || (val?.RecurringDate === "N/A" && val?.CancellationDate != null )  &&  val?.planStatusDescription == 'Active')) ?  "Cancel-in-Progress" : val?.planStatusDescription}`}
                            >
                              {(((val?.RecurringDate !== "N/A" 
                                && val?.CancellationDate != null && 
                                val?.CancellationDate < val?.RecurringDate
                                ) || (val?.RecurringDate === "N/A" && val?.CancellationDate != null )  &&  val?.planStatusDescription == 'Active')) ?  "Cancel-in-Progress" : val?.planStatusDescription}
                            </span>
                          </h5>

                          <p
                            className={"card-subtitle mb-2 text-muted flex-txt"}
                          >
                            <span className="txt-title">Next Recurrence</span>
                            <span className="title-column">:</span>
                            <span>
                              {val.RecurringDate !== "N/A"
                                ? moment(val.RecurringDate).format("L")
                                : "N/A"}
                            </span>
                          </p>
                          <p
                            className={"card-subtitle mb-2 text-muted flex-txt"}
                          >
                            <span className="txt-title">Subscribed On</span>{" "}
                            <span className="title-column">:</span>
                            <span>
                              {val.PurchasedOn !== "N/A"
                                ? moment(val.PurchasedOn).format("L")
                                : "N/A"}
                            </span>
                          </p>
                        </div>
                        <div className="services-member-schedule">
                          <div>
                            <span
                              className={
                                "bz-member-name d-flex align-items-center justify-content-start text-muted bz-fs-12px text-capitalize"
                              }
                            >
                              <ClientNameWithMemberType
                                memberType={val?.MemberType}
                                clientName={val?.MemberName}
                              />
                            </span>
                          </div>
                          <div>
                            {props?.staffPage &&
                              !CheckSubscribeAddon(
                                val?.planStatusDescription
                              ) && (
                                <>
                                  <span
                                    className={
                                      "bz-schedule-link-btn2 addon-link"
                                    }
                                  >
                                    <Button
                                      className="customtooltip"
                                      primary={false}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleSubscribeAddons(val);
                                      }}
                                    >
                                      Purchase Addon
                                    </Button>
                                  </span>
                                </>
                              )}
                          </div>
                        </div>
                        <span className={"bz-arrow-icon"}>
                          <i className={"fas fa-caret-right"}></i>
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default withNotification(Plans);
