import React, { useState, useEffect } from "react";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { useApiClient } from "services/axios-service-utils";
import withNotification from "components/Hoc/withNotification";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { Dialog } from "@progress/kendo-react-dialogs";
import BuzopsButton from "generic-components/BuzopsButton";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
interface IAppointmentRateState {
  ServiceRateId?: number;
  MaximumAndMinimumSessionRange?: string;
  MinimumServiceCount: number;
  MaximumServiceCount: number;
  Cost: number;
  IsActive?: boolean;
  IsDeleted?: boolean;
  UpdateBy?: null;
  UpdateOn?: null;
  ServiceTypeId: number;
  ServiceType?: string;
}
const AppointmentRates = (props: any) => {
  const [rates, setRates] = useState<IAppointmentRateState[]>([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const { axiosRequest } = useApiClient();

  useEffect(() => {
    if (submit) {
      let timer = 2000;
      let timer1 = setTimeout(() => {
        props?.handleAppointmentRates();
      }, timer);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [submit]);

  useEffect(() => {
    fetchRates();
  }, []);

  const errorCallBack = (message: string) => {
    props?.handleNotificationMessage(message, "error");
  };

  const fetchRates = async () => {
    setLoading(true);
    await axiosRequest.post(
      "service/Rates",
      {},
      {
        successCallBack: (response: any) => {
          const data = response.data.Items;
          setRates(data || []);
          setLoading(false);
        },
        errorCallBack: (response: any) => {
          setLoading(false);

          errorCallBack(
            response?.response?.data?.Messages?.[0] ||
            response?.data?.Messages?.[0] ||
            "Internal server error"
          );
        },
      }
    );
  };

  const handleSave = async () => {
    setButtonLoading(true);
    await axiosRequest.post("service/SaveRate", rates, {
      successCallBack: async (response: any) => {
        const sucessMsg = "Appointment Rates Updated Successfully";
        props?.handleNotificationMessage(sucessMsg, "success");
        setButtonLoading(false);
        // await fetchRates();
        setSubmit(true);
      },
      errorCallBack: (response: any) => {
        console.log(response?.response, "response");
        errorCallBack(
          response?.response?.data?.Messages?.[0] ||
          response?.data?.Messages?.[0] ||
          "Internal Server Error"
        );
        setButtonLoading(false);
      },
    });
  };
  const handleAddRate = () => {
    setRates([
      ...rates,
      {
        MinimumServiceCount: 0,
        MaximumServiceCount: 0,
        Cost: 0,
        ServiceRateId: 0,
        ServiceTypeId: 2,
        IsDeleted: false,
      },
    ]);
  };

  const handleDeleteRate = (index: number, dataItem: IAppointmentRateState) => {
    const newRates = [...rates];
    if (dataItem?.ServiceRateId) {
      const findIndex = newRates?.findIndex(
        (i: IAppointmentRateState) =>
          i?.ServiceRateId === dataItem?.ServiceRateId
      );
      newRates[findIndex].IsDeleted = true;
    } else {
      newRates.splice(index, 1);
    }
    setRates(newRates);
  };

  const handleChange = (index: number, field: string, value: number | null) => {
    const newRates = [...rates];
    newRates[index][field] = value;
    setRates(newRates);
  };

  const validateRates = () => {
    const mainRates = rates?.filter(
      (i: IAppointmentRateState) => !i?.IsDeleted
    );
    if (mainRates?.length === 0) {
      errorCallBack("Please provide Appointment Rate");
      return false;
    }
    for (let i = 0; i < mainRates.length - 1; i++) {
      if (
        mainRates[i].MaximumServiceCount >=
        mainRates[i + 1].MinimumServiceCount ||
        mainRates[i].MinimumServiceCount >= mainRates[i].MaximumServiceCount ||
        mainRates[i + 1].MinimumServiceCount >=
        mainRates[i + 1].MaximumServiceCount
      ) {
        const errorMessage =
          "Rates should be in incremental order and should not overlap.";
        errorCallBack(errorMessage);
        return false;
      }
      if (
        !mainRates[i]?.Cost ||
        mainRates[i]?.Cost <= 0 ||
        !mainRates[i + 1]?.Cost ||
        mainRates[i + 1]?.Cost <= 0
      ) {
        const errorMessage =
          "Appointment cost should not be lesser than or equal to 0";
        errorCallBack(errorMessage);
        return false;
      }
    }
    return true;
  };

  return (
    <Dialog
      className={
        "bz-mybookings-dialog-box bz-privacyPolicy-dialog bz-classes-dialog apptdailogrates"
      }
      title={
        <div className="d-flex flex-column">
          <span>Appointment Settings</span>
          <small>Add or edit Appointment Rates</small>
        </div>
      }
      onClose={props?.handleAppointmentRates}
      width={"75%"}
      height={"80%"}
    >

      {Loading ? (
        <BuzopsLoader />
      ) : (
        <>
          <div className="appointmentmandiv">
            <div className="appoitmentheader">
              <div className="d-flex justify-content-end mb-2">
                {" "}
                <BuzopsTooltip
                  html={
                    <div title="Add new Appointment Rate">
                      <div
                        className="mt-3 mb-3"
                        style={{ textAlign: "right" }}
                      >
                        <Button onClick={handleAddRate} className="k-button mr-0 noaddpck addbtnappointments">
                          <i className="fa fa-plus"></i>
                        </Button>
                      </div>
                    </div>
                  }
                  position="top"
                />
              </div>
            </div>
            {rates?.filter((i: any) => !i?.IsDeleted)?.length === 0 ? (
              <div
                className="bz-norecords-available"
                style={{ minHeight: "15rem" }}
              >
                No Data Available
              </div>
            ) : (<div className="appointment-table">   
              <table className="table table-striped appointment-rates-table">

              <tbody>
                {rates.map((rate: IAppointmentRateState, index: number) => {
                  if (rate?.IsDeleted) {
                    return <></>;
                  }
                  return (
                    <tr key={index} className="row col-md-12 m-0">
                      <td className="col-md-3">
                        <label htmlFor="">Min Appts Count</label>
                        <NumericTextBox
                          value={rate.MinimumServiceCount}
                          width={"100%"}
                          step={1}
                          min={1}
                          format="n0"
                          onChange={(e) => {
                            let val = e.value;
                            const stringValue: any = val?.toString();

                            if (stringValue?.length > 9) {
                              val = parseInt(stringValue.slice(0, 9), 10);
                            }
                            if (!e.value || e.value <= 0) {
                              val = 0;
                            }
                            handleChange(index, "MinimumServiceCount", val);
                          }}
                        />
                      </td>
                      <td className="col-md-4">
                        <label htmlFor="">Max Appts Count</label>
                        <NumericTextBox
                          value={rate.MaximumServiceCount}
                          width={"100%"}
                          step={1}
                          min={1}
                          format="n0"
                          onChange={(e) => {
                            let val = e.value;
                            const stringValue: any = val?.toString();

                            if (stringValue?.length > 9) {
                              val = parseInt(stringValue.slice(0, 9), 10);
                            }
                            if (!e.value || e.value <= 0) {
                              val = 0;
                            }
                            handleChange(index, "MaximumServiceCount", val);
                          }}
                        />
                      </td>
                      <td className="col-md-4">
                        <label htmlFor="">Cost</label>
                        <NumericTextBox
                          value={rate.Cost}
                          format={"c2"}
                          width={"100%"}
                          min={0}
                          onChange={(e) => {
                            let val = e.value;
                            if (!e.value || e.value <= 0) {
                              val = 0;
                            }
                            handleChange(index, "Cost", val);
                          }}
                        />
                      </td>
                      <td className="col-md-1 deleteappointmentrates">


                   
                        <BuzopsTooltip
                                html={
                                       <button
                                        title="Delete"
                                       className=" btn cursor-pointer marappts"
                                       role={"button"}
                                       style={{ fontSize: "16px" }}
                                       onClick={() => handleDeleteRate(index, rate)}
             
                                     >
                                       <i className="far fa-trash-alt text-danger"></i>
                                     </button>
                                }
                              />

                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table> </div>)}
            <div className="appointment-footer">
              <div className="fixed-footer" >
                <Button
                  disabled={buttonLoading}
                  onClick={() => props?.handleAppointmentRates()}
                >
                  {"Cancel"}
                </Button>
                <BuzopsButton
                  onClick={() => {
                    if (validateRates()) handleSave();
                  }}
                  disabled={buttonLoading || submit}
                  loading={buttonLoading}
                  label={"Submit"}
                  className="submitappts"
                />
              </div>
            </div>
          </div>



        </>
      )}
    </Dialog>
  );
};

export default withNotification(AppointmentRates);
