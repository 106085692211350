import React from "react";
import { BiMinusCircle, BiPlusCircle } from "react-icons/bi";
import { PackageInstanceStatusEnum, SessionTypeEnum } from "utils/form-utils";
import { PermissionEnum } from "utils/PermissionEnum";
import CheckPermissions from "utils/permissions";

const AppointmentsBox = ({
  selectedPackage,
  handleAppointmentsEdit,
  staffPage,
}: any) => {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h3
          style={{ fontSize: "32px", fontWeight: 700, color: "#bce2f4" }}
          className="mb-3"
        >
          Appointments
        </h3>
      </div>
      <div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Paid</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            <div className="d-inline-flex align-items-center" style={{lineHeight: 1.2}}>
              {selectedPackage?.PaidSchedules}

              {staffPage &&
               CheckPermissions(
                PermissionEnum["AddonAppointments"]
              ) &&
                selectedPackage?.Status !==
                  PackageInstanceStatusEnum.CANCELLED &&
                selectedPackage?.SessionType === SessionTypeEnum.PRESET && (
                  <span
                     className="appt-add-remove-link"
                    onClick={() =>
                      handleAppointmentsEdit(selectedPackage, "add")
                    }
                  >
                    <BiPlusCircle
                      color="#008DB9"
                      size={"16px"}
                      data-for={"add-appts-link"}
                      data-tip={"Add Appointments"}
                      style={{ margin: "0 0.2rem" }}
                    />
                    Add Appts
                  </span>
                )}
            </div>
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Used</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{selectedPackage?.Completed}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Unused</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            <div className="d-inline-flex align-items-center" style={{lineHeight: 1.2}}>
              {selectedPackage?.UnusedPaidSchedules}
              {selectedPackage?.UnusedPaidSchedules > 0 &&
                CheckPermissions(
                  PermissionEnum["AddonAppointments"]
                ) &&
                staffPage &&
                selectedPackage?.Status !==
                  PackageInstanceStatusEnum.CANCELLED &&
                selectedPackage?.SessionType === SessionTypeEnum.PRESET && (
                  <span
                    className="appt-add-remove-link"
                    onClick={() =>
                      handleAppointmentsEdit(selectedPackage, "remove")
                    }
                  >
                    <BiMinusCircle
                      color="#008DB9"
                      size={"16px"}
                      data-for={"remove-appts-link"}
                      data-tip={"Remove Appointments"}
                      style={{ margin: "0 0.2rem" }}
                    />
                    Remove Appts
                  </span>
                )}
            </div>
          </div>
        </div>
        <div className="bz-content-text-row mb-2">
          <div className="bz-content-title text-right">Unpaid</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {selectedPackage?.UnPaidSessionCount}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Scheduled</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{selectedPackage?.Scheduled}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Complimentary</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {selectedPackage?.Complimentary}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Removed</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{selectedPackage?.Removed}</div>
        </div>
      </div>
    </>
  );
};

export default AppointmentsBox;
