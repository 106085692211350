import { CommonServiceUtils } from "services/common-service-utils";
import { PermissionEnum } from "utils/PermissionEnum";
import CheckPermissions from "utils/permissions";
import {
  GetLocalStore,
  LocalStore,
  RemoveAllExceptTenant,
  RemoveAllLocalStore,
  RemoveLocalStore,
} from "utils/storage";
import TimeZoneSetup from "utils/time-zone-setup";

export class CheckInService extends CommonServiceUtils {
  async authenticateStaff(req: any): Promise<any> {
    const authentication: any = await this.postData(
      `${this.apiUrlCreator(`authentication/StaffAuthenticate`)}`,
      req
    );
    let successResponse = false;
    let unAuthorized = true;
    if (authentication?.ResponseCode === 100) {
      const CurrentApplicationType =
        authentication?.Item?.UserSession?.CurrentApplicationType;
      if (
        CurrentApplicationType === 1 ||
        (CurrentApplicationType === 8 &&
          CheckPermissions(
            PermissionEnum["checkinFeatureId"],
            CurrentApplicationType,
            authentication.Item.UserSession
          ))
      ) {
        LocalStore("userDetails", authentication.Item);
        LocalStore("tenantUserSession", {...authentication?.Item?.UserSession, DomainName: authentication.Item.DomainName, StripePaymentConfiguration: authentication.Item.StripePaymentConfiguration, ChargehubSquarePaymentProfile: authentication.Item.ChargehubSquarePaymentProfile,Configuration: authentication.Item.Configuration});
        LocalStore("CurrentApplicationType", CurrentApplicationType);
        LocalStore("Configuration", {...authentication.Item.Configuration,IsAffiliateFeatureEnabled:authentication.Item.IsAffiliateFeatureEnabled});
        LocalStore(
          "UserMemberId",
          authentication.Item.UserSession.UserMemberId
        );
        unAuthorized = false;
      } else {
        RemoveAllExceptTenant();
      }
      successResponse = true;
    }
    return {
      successResponse: successResponse,
      unAuthorized: unAuthorized,
      ErrorMessages: authentication?.ErrorMessages || [],
      data: authentication || null,
    };
  }

  async reAuthenticateStaff(): Promise<boolean> {
    const UserSession = GetLocalStore("userDetails")?.UserSession;
    if (!UserSession?.Email || !UserSession?.ExternalClubId) {
      return false; // can't refresh if we don't have these details
    }

    const req = {
      EmailId: UserSession.Email,
      ClubInternalId: UserSession.ExternalClubId,
    };
    const response: any = await this.postData(
      `${this.apiUrlCreator("authentication/select")}`,
      req
    );

    // If success, store new token in localStorage
    if (response?.ResponseCode === 100 && response.Item?.Token) {
      LocalStore("token", response.Item.Token);
      return true;
    }
    return false;
  }
  async getTenantDetails(tenant: string, loadAbout = false): Promise<any> {
    const getToken: any = await this.postData(
      `${this.apiUrlCreator(`authentication/issuetoken/${tenant}`)}`
    );
    if (tenant !== GetLocalStore("tenant")) {
      RemoveAllLocalStore();
    }
    RemoveLocalStore("tenant");
    if (getToken?.ResponseCode === 100) {
      LocalStore("tenant", tenant);
      if (!GetLocalStore("userDetails")) {
        LocalStore("token", getToken.Item.Token);
        LocalStore("tenantUserSession", {...getToken?.Item?.UserSession, DomainName: getToken.Item.DomainName, StripePaymentConfiguration: getToken.Item.StripePaymentConfiguration, ChargehubSquarePaymentProfile: getToken.Item.ChargehubSquarePaymentProfile,Configuration: getToken.Item.Configuration});
      }
      LocalStore("Configuration", {...getToken.Item.Configuration,IsAffiliateFeatureEnabled:getToken.Item.IsAffiliateFeatureEnabled});
      if(loadAbout){
        const getAboutDetails: any = await this.postData(
          `${this.apiUrlCreator(`site/about`)}`
        );
        if (getAboutDetails?.ResponseCode === 100) {
          LocalStore("tenantDetails", getAboutDetails.Item);
        }
      }
      TimeZoneSetup(
        getToken?.Item?.UserSession?.TenantTime,
        getToken?.Item.UserSession
      );
    }
    return getToken;
  }

  async saveCheckins(req: any): Promise<any> {
    const saveCheckins: any = await this.postData(
      this.apiUrlCreator(`Checkin/Save`),
      req
    );
    return saveCheckins;
  }

  async saveEventsCheckins(req: any): Promise<any> {
    const saveEventsCheckins: any = await this.postData(
      this.apiUrlCreator(`Checkin/SaveEvents`),
      req
    );
    return saveEventsCheckins;
  }

  async getCheckins(url: string, req: any): Promise<any> {
    const getCheckins: any = await this.postData(this.apiUrlCreator(url), req);
    return getCheckins;
  }

  async NotifyCheckinsRestricted(userMemberId: number): Promise<any> {
    const response: any = await this.postData(
      `${this.apiUrlCreator(
        `Checkin/NotifyCheckinsRestricted?memberId=${userMemberId}`
      )}`
    );
    return response;
  }

  async getTenantTime(): Promise<any> {
    const response: any = await this.getData(
      `${this.apiUrlCreator("site/CurrentTimeInTenantTimezone")}`
    );
    return response;
  }
}
