import { useApiClient } from "services/axios-service-utils";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { TenantService } from "services/tenant/index.service";
import { GetLocalStore } from "utils/storage";
import { UploadFileInfo, UploadOnAddEvent, UploadOnRemoveEvent } from "@progress/kendo-react-upload";

const schema = Yup.object({
  CreditAmount: Yup.number()
    .typeError("Please enter a valid amount for the credit top-up")
    .min(0.01, "Amount should be greater than 0")
    .max(15000, "Amount should not be greater than 15k")
    .required(),
  PaymentGatewayPaymentProfileID: Yup.string().required(
    "Payment Profile is required"
  ),
});
const usePaymentProfile = (props: any) => {

  const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [selectedPaymentProfile, setSelectedPaymentProfile] =
  React.useState<any>(null);
  const [addNewItem, setAddNewItem] = useState<any>(null);
  const [paymentProfile, setPaymentProfile] = useState<any>([]);
  const [refNumber, setRefNumber] = useState<any>(null);
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);
  const paymentProfileData = new TenantService();
  const { axiosRequest } = useApiClient();

  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);


  const constructFileWithBas64 = (records: any) => {
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
          handleChangeSavedCard(selectedPaymentProfile,{
            Reference:refNumber,
            ReferrenceDocument: fileData?.[0]
          })
        };
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
      handleChangeSavedCard(selectedPaymentProfile,{
        Reference:refNumber,
        ReferrenceDocument: null
      })
    }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
    
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };

  const formik = useFormik({
    initialValues: {
      CreditAmount: 0,
      PaymentGatewayPaymentProfileID: "",
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  
  const makePaymentProfileAsDefault = async (dataItem: any) => {
    const req = {
      PaymentGatewayPaymentProfileID: dataItem?.PaymentGatewayPaymentProfileID,
      UserMemberId: props?.UserMemberId,
      CustomerProfileID:
        dataItem?.CustomerProfileID,
    };
    await axiosRequest.post(
      `PaymentProfile/update-preferred-payment-profile`,
      req,
      {
        successCallBack: async (response: any) => {
          props?.handleNotificationMessage(
            `Selected Payment profile marked as Preferred`,
            "success"
          );
          await fetchPaymentProfiles(props?.UserMemberId);
        },
        errorCallBack: (response: any) => {
          props?.handleNotificationMessage(
            response?.response?.data?.Messages?.[0] ||
              response?.data?.Messages?.[0] ||
              "Internal server error",
            "error"
          );
        },
      }
    );
  };
  const fetchPaymentProfiles = async (userMemberId: any,modeVal=null) => {
    // setPaymentLoading(true);
    const result = await paymentProfileData.paymentProfile(userMemberId);
    // setPaymentLoading(false);
    if (result) {
      const pp=result?.map((i: any) => {
        if (i?.CardTypeId !== 8) {
          return { ...i };
        } else {
          return { ...i, MaskedCCNumber: "Credits" };
        }
      })
      if(modeVal){
        const record=pp?.find((i:any)=> i?.CardTypeId ===-1)
        if(record){
          const data={
            ...record,
            CardTypeId: -2,
            MaskedCCNumber: modeVal,
            CardDescription: modeVal,
            CardDescriptionWithoutExpiryDate: modeVal,
          };
          const res=[...pp]
          res.push(data)
          setPaymentProfile(JSON.parse(JSON.stringify(res)))
          handleChangeSavedCard(data, {
            Reference:refNumber,
            ReferrenceDocument: files?.[0]
          })
          formik.setFieldValue(
            "PaymentGatewayPaymentProfileID",
            data?.PaymentGatewayPaymentProfileID
          );
        }
      }
      else{
        setPaymentProfile(pp);

        let selectedCard;
        const defaultPP = result.find((card: any) => card.IsDefault);
        const cardType1 = pp.find((card:any) => card.CardTypeId >= 1 && card.CardTypeId < 8);
        const cardType2 = pp.find((card:any) => card.CardTypeId === 0);
        const cardType3 = pp.find((card: any) => card.CardTypeId === 8  && card.Credit > 0);
        if(!defaultPP){
          if (cardType1) {
            selectedCard = cardType1;
          } else if (cardType2) {
            selectedCard = cardType2;
          } else if (cardType3) {
            selectedCard = cardType3;
          } else {
            selectedCard = pp.find((card:any) => card.CardTypeId === -1);
          }
        }else{
          selectedCard=defaultPP
        }

        if (selectedCard) {
          handleChangeSavedCard(selectedCard,{
            Reference:refNumber,
            ReferrenceDocument: files?.[0] ||null
          });
        }
      }
    }
  }

  useEffect(() => {
    fetchAllApis();
  }, []);
  const fetchAllApis = async () => {
    if (props?.UserMemberId) {
      setLoading(true);
      await fetchPaymentProfiles(props?.UserMemberId);
      setLoading(false);
    }
  };
  const showError = (message: string) => {
    props?.handleNotificationMessage(message, "error");
  };

  const handleSubmit = async(values: any)=>{
    setBtnLoading(true);
    const payload = {
      UserMemberId: props?.UserMemberId,
      CreditAmount: values?.CreditAmount,
      PaymentGatewayPaymentProfileID: values?.PaymentGatewayPaymentProfileID,
      PaymentGatewayType: paymentGatewaytype,
    };

    await axiosRequest.post("PaymentProfile/addcredit", payload, {
      successCallBack: (response: any) => {
        console.log(response);
        handleReset();
        props?.handleCloseDialog(true);
        setBtnLoading(false);
      },
      errorCallBack: (response: any) => {
        console.log(response?.response, "response");
        showError(
          response?.response?.data?.Messages?.[0] || response?.data?.Messages?.[0] ||
            "Error occured while adding credits to wallet"
        );
        setBtnLoading(false);
      },
    });
  };

  const onCardOrBankAdd = (e: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };
  const handleChangeSavedCard = (val: any,dataItem:any) => {
    props?.onSelectedCard(val,dataItem);
    setSelectedPaymentProfile(val);
  };
  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
  };
  const handleSuccessClosePaymethodDialog = async (msg = null,modeVal = null) => {
    if (msg) {
      props?.handleNotificationMessage(msg, "success");
    }
    setShowPaymentDialog(!showPaymentDialog);
    fetchPaymentProfiles(props?.UserMemberId,modeVal);
  };

  const handleReset = () => {
    formik.resetForm();
    setSelectedPaymentProfile(undefined);
  };

  return {
    handleReset,
    handleSuccessClosePaymethodDialog,
    handleClosePaymethodDialog,
    handleChangeSavedCard,
    onCardOrBankAdd,
    addNewItem,
    loading,
    formik,
    paymentProfile,
    selectedPaymentProfile,
    showPaymentDialog,
    btnLoading,
    originalFiles,
    onAdd,
    onRemove,
    setRefNumber,refNumber,files,
    makePaymentProfileAsDefault
  };
};

export default usePaymentProfile;
