import React from "react";

const MembershipVisitsBox = ({
  selectedPlan,
  // handleAppointmentsEdit,
  staffPage,
}: any) => {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h3
          style={{ fontSize: "32px", fontWeight: 700, color: "#bce2f4" }}
          className="mb-3"
        >
          Membership Visits
        </h3>
      </div>
      <div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Total Visits</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            <div className="d-inline-flex align-items-center" style={{lineHeight: 1.2}}>
              {selectedPlan?.NoOfVisits}

              {/* {staffPage &&
               CheckPermissions(
                PermissionEnum["AddonAppointments"]
              ) &&
                  <span
                     className="appt-add-remove-link"
                    onClick={() =>
                     // handleAppointmentsEdit(selectedPackage, "add")
                     console.log("")
                    }
                  >
                    <BiPlusCircle
                      color="#008DB9"
                      size={"16px"}
                      data-for={"add-appts-link"}
                      data-tip={"Add Appointments"}
                      style={{ margin: "0 0.2rem" }}
                    />
                    Add Visits
                  </span>
                } */}
            </div>
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Used</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{selectedPlan?.UsedVisits}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Unused</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            <div className="d-inline-flex align-items-center" style={{lineHeight: 1.2}}>
              {selectedPlan?.NoOfVisits-selectedPlan?.UsedVisits}             
            </div>
          </div>
        </div>       
      </div>
    </>
  );
};

export default MembershipVisitsBox;
