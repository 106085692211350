import withNotification from "components/Hoc/withNotification";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { useEffect, useState } from "react";
import { ThirdPartyConfigService } from "services/thirdparty-configuration/index.service";
import { HardwareProviderType, SecuritySystemType } from "utils/constants";
import { ISecuritySystemConfiguration } from "utils/interfaces";
import OnsiteAccess from "./OnsiteAccess";
import OpenPathCredentials from "./OpenPathCredentials";
import SimpleCheckinAccess from "./SimpleCheckinAccess";
import PassportTechCredentials from "./PassportTechCredentials";
import { SimpleAccessService } from "services/simpleAccess/index.service";
import { CardBody } from "@progress/kendo-react-layout";
import SimpleAccessCheckIns from "components/summary/SimpleAccessCheckIns";

const AccessComponent = (props: any) => {
  const service = new ThirdPartyConfigService();
  const [loading, setLoading] = useState<boolean>(false);
  const [securityConfig, setSecuritySystemConfig] =
    useState<ISecuritySystemConfiguration>();
  const [listItems, setListItems] = useState<any>([]);
  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    setLoading(true);
    const getResult = await service.getSecuritySystemConfigs();
    if (getResult.success) {
      setSecuritySystemConfig(getResult?.data);
    } else {
      if (getResult.msg) {
        props?.handleNotificationMessage(getResult.msg, "error");
      }
    }
    await fetchAllApis();
    setLoading(false);
  };

  const fetchAllApis = async () => {
    const simpleAccessService = new SimpleAccessService();
    //setLoading(true);
    // add network calls
    const req = {
      UserMemberId: props?.userData?.UserMemberId,
      PrimaryUserMemberId: props?.userData?.MainUserId,
      IncludeSubMember: true,
    };
    const response = await simpleAccessService.getMembersWithAccess(req);
    setListItems(
      response.map((item: any) => {
        return { ...item, selectedArrow: false, history: [] };
      })
    );
    //setLoading(false);
  };

  const showComponentBasedOnSelectedSecType = () => {
    switch (securityConfig?.SecuritySystemType) {
      case SecuritySystemType.ProximityReaderSystem:
        switch (securityConfig?.HardwareSecuritySystemPreferences?.ProviderType){
          case HardwareProviderType.Brivo:
            return <OnsiteAccess {...props} />;
          case HardwareProviderType.Openpath:
            return <OpenPathCredentials
              UserMemberId={props?.userData?.UserMemberId}
              PrimaryUserMemberId={props?.userData?.MainUserId}
              handleNotification={props.handleNotificationMessage}
              userData={props.userData}
              ConfiguredGroups={securityConfig.HardwareSecuritySystemPreferences.OpenPathSettings.SiteConfiguration.ConfiguredGroups.filter(
                (g: any) => g.ForStaffOnly === false
              )}
            />
          case HardwareProviderType.Passport:
            return <PassportTechCredentials
              UserMemberId={props?.userData?.UserMemberId}
              PrimaryUserMemberId={props?.userData?.MainUserId}
              handleNotification={props.handleNotificationMessage}
              userData={props.userData}
            />
          default:
            return (
              <div className="d-flex text-center">
                <h5>
                  Please configure security system under third party integration to
                  proceed further.
                </h5>
              </div>
            );
        }
      case SecuritySystemType.ScannerSystem:
        return (
          <SimpleCheckinAccess {...props} securityConfig={securityConfig} />
        );
      default:
        return (
          <CardBody className="p-0">
            <div className="mb-3 pt-3">
              {/* <h5>Check Ins</h5> */}
              <SimpleAccessCheckIns
                {...props}
                userData={props?.userData}
                members={listItems}
                securitySystemType={SecuritySystemType.ScannerSystem}
              />
            </div>
          </CardBody>
        );
    }
  };

  if (loading) {
    return <BuzopsLoader type="list" />;
  } else {
    return showComponentBasedOnSelectedSecType();
  }
};

export default withNotification(AccessComponent);
