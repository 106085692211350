import {
  Card,
  CardBody,
} from "@progress/kendo-react-layout";
import AppointmentsBox from "./AppointmentsBox";
import AppointmentTabs from "./AppointmentTabs";

export const Appointments = (props: any) => {
  const selectedSer = props?.selectedService ? props?.selectedService : null;
  const selectedSerDetails = props?.serviceInstance
    ? props?.serviceInstance
    : null;
  const selectedService = selectedSer;
  const selectedServiceDetails = selectedSerDetails;
  const renderAppointments = () => {
    return (
      <>
        <AppointmentTabs
          {...props}
          userData={
            selectedServiceDetails
              ? {
                  ...props?.userData,
                  EntityUserMemberId: selectedServiceDetails?.UserMemberId,
                }
              : { ...props?.userData }
          }
          deleteGs={true}
          serviceId={selectedService}
          paynowGs={true}
        />
      </>
    );
  };

  return selectedServiceDetails !== null ? (
    <div className="bz-subsection-row bz-service-appoinment-info">
      <div className="left-section ">{renderAppointments()}</div>
      {/* <div className="right-section mt-3">
        {selectedServiceDetails && (
          <Card
            className={
              "bz-card bz-overview-cards bz-appoinments-overview-card pt-0"
            }
            style={{ marginTop: "85px" }}
          >
            <CardBody>{AppointmentsBox(selectedServiceDetails)}</CardBody>
          </Card>
        )}
      </div> */}
    </div>
  ) : (
    <>{renderAppointments()}</>
  );
};
