import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { Loader } from "@progress/kendo-react-indicators";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ClientNameWithMemberType from "./ClientNameWithMemberType";
import { CheckInService } from "services/checkin/index.service";
import {
  checkinModeEnum,
  checkinTypeEnum,
  checkinTypeStringEnum,
} from "utils/form-utils";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import CheckinsConstants from "components/CheckIns/contants";
import CustomCalendar from "atoms/CustomCalendar";
import { SecuritySystemType } from "utils/constants";

const SimpleAccessCheckIns = (props: any) => {
  const checkIns = new CheckInService();
  const [loading, setloading] = useState(true);
  const [fromDateToDate, setFromDateToDate] = useState({
    from: moment()
      .startOf("isoWeek")
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    to: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  });
  const [selectedCheckInTypeOption, setSelectedCheckInTypeOption] =
    useState<any>({
      id: 1,
      text: `All Check-Ins`,
    });
  const [selectedMember, setSelectedMember] = useState<any>({
    id: "-1",
    text: "All Members",
  });

  const [dateFilter, setDateFilter] = useState("This Week");
  const showMoreRef = useRef<any>();
  const [dataList, setDataList] = useState<any>({
    pageNumber: 0,
    maxPageNumber: 0,
    checkInsData: [],
  });
  const [pageLoading, setPageLoading] = useState(false);

  const getCheckinType = (type: checkinTypeEnum) => {
    switch (type) {
      case checkinTypeEnum.Location:
        return checkinTypeStringEnum.Location;
      case checkinTypeEnum.OneOnOne:
        return checkinTypeStringEnum.Appointments;
      case checkinTypeEnum.Group:
        return checkinTypeStringEnum.Group;
    }
  };

  const fetchCheckIns = async (pageNumber: number) => {
    if (pageNumber === 0) {
      setloading(true);
    }
    const pageSize = 12;
    let req: any = {
      FilterFields: [],
      PageNumber: pageNumber,
      PageSize: pageSize,
      PrimaryUserMemberId: props?.userData?.MainUserId,
      FromDate: fromDateToDate.from,
      ToDate: fromDateToDate.to,
      SortBy: "AccessEventId",
      SortDirection: "desc",
      CheckinType: selectedCheckInTypeOption?.id,
      UserId: null,
    };

    if (selectedCheckInTypeOption?.id === 1) {
      delete req["CheckinType"];
    }
    if (selectedMember?.id !== "-1") {
      delete req["PrimaryUserMemberId"];
      req["UserId"] = selectedMember["id"];
    } else {
      delete req["UserId"];
    }

    const url =
      props.securitySystemType === SecuritySystemType.ScannerSystem
        ? `SimpleAccess/Checkins`
        : `HardwareAccess/Checkins`;
    const result = await checkIns.getCheckins(url, req);
    if (pageNumber === 0) {
      setloading(false);
    }
    if (result?.ResponseCode === 100) {
      const maxPageNumber = result.Item?.TotalItems / pageSize;
      const data = result?.Item?.Result?.map((row: any) => {
        console.log(row);
        return {
          ...row,
          Type: getCheckinType(row?.Type),
          CheckinMode: checkinModeEnum[row?.CheckinMode],
          Details: `${row?.EntityName || ""}  ${row?.ScheduledStartTime || ""}`,
          TypeOfMember: row?.MemberType === 0 ? "Primary" : "Secondary",
        };
      });
      if (pageNumber === 0) {
        setDataList({
          ...dataList,
          pageNumber: pageNumber,
          maxPageNumber: maxPageNumber,
          checkInsData: data || [],
        });
      }
      return data || [];
    } else {
      props?.handleNotificationMessage("Invalid date range", "error");
    }
    return [];
  };

  const handlePageChange = async (status = "more") => {
    if (status === "more") {
      const check = await requestIfNeeded();
      if (check.status) {
        setPageLoading(true);
        const moreData = await fetchCheckIns(check.page);
        const test = dataList?.checkInsData?.concat(moreData);
        setPageLoading(false);
        if (moreData.length > 0) {
          setDataList({
            ...dataList,
            checkInsData: test,
            pageNumber: check.page,
          });
          if (showMoreRef.current) {
            showMoreRef.current?.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    }
  };

  const requestIfNeeded = () => {
    const nextPage = dataList.pageNumber + 1;
    const maxPageNum = dataList.maxPageNumber;

    if (nextPage <= maxPageNum) {
      return {
        status: true,
        page: nextPage,
      };
    } else {
      return {
        status: false,
        page: nextPage,
      };
    }
  };

  const _export = React.useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  useEffect(() => {
    if (fromDateToDate.from && fromDateToDate.to) {
      fetchCheckIns(0);
    }
  }, [selectedCheckInTypeOption, fromDateToDate, selectedMember]);

  return (
    <>
      <div style={{ minHeight: '380px' }}>
        <h5 className="mb-3 bz_fs_1_5">Check-Ins</h5>
        <div className="bz-checkins-date-filter">
          <GridToolbar>
            <DropDownList
              defaultValue={selectedCheckInTypeOption}
              textField="text"
              dataItemKey="id"
              data={CheckinsConstants.CheckinTypeOptions}
              value={selectedCheckInTypeOption}
              onChange={(e) => setSelectedCheckInTypeOption(e.target.value)}
            />
            <DropDownList
              defaultValue={selectedMember}
              textField="text"
              dataItemKey="id"
              data={[
                {
                  id: "-1",
                  text: "All Members",
                },
                ...props?.members?.map((member: any) => {
                  return {
                    id: member?.UserId,
                    text: member?.FullName,
                  };
                }),
              ]}
              value={selectedMember}
              onChange={(e) => setSelectedMember(e.target.value)}
            />
            <CustomCalendar
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              key={"no change"}
              setFromDateToDate={setFromDateToDate}
              selectedDateItem={dateFilter}
              // setDateFilter={setDateFilter}
              onlyPastDates
            />
            <button
              title="Download"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary ml-0"
              onClick={excelExport}
            >
              <BuzopsTooltip
                html={
                  <span title="Download">
                    <i className="fa fa-download"></i>
                  </span>
                }
              />
            </button>
          </GridToolbar>
        </div>
        {loading ? (
          <BuzopsLoader type={"list"} />
        ) : (
          <>
            <ExcelExport data={dataList?.checkInsData || []} ref={_export}>
              <ExcelExportColumn field="FullName" title="Name" />
              <ExcelExportColumn field="TypeOfMember" title="Member Type" />
              <ExcelExportColumn field="SiteName" title="Location" />
              <ExcelExportColumn title="Type" field="Type" />
              <ExcelExportColumn field="Occured" title="Date Time" />
              <ExcelExportColumn title="Check-In By" field="CheckinMode" />
              <ExcelExportColumn title="Details" field="Details" />
            </ExcelExport>
            <Grid
              data={dataList?.checkInsData || []}
              className="bzc-grid bz-checkins-grid bz-checkins-date-filter"
            >
              <Column
                field="FullName"
                title="Name"
                cell={(props) => {
                  return (
                    <td>
                      <ClientNameWithMemberType
                        memberType={props?.dataItem?.MemberType}
                        clientName={props?.dataItem?.FullName}
                      />
                    </td>
                  );
                }}
              />
              {/* <Column field="ClubName" title="Home Club" /> */}
              <Column field="SiteName" title="Location" />
              <Column title="Type" field="Type" />

              <Column field="Occured" title="Date Time" />
              <Column title="Check-In By" field="CheckinMode" />
              <Column
                title="Details"
                field="Details"
                cell={(props) => {
                  return (
                    <td>
                      <div>
                        {props?.dataItem?.EntityName}
                        <br />
                        {props?.dataItem?.ScheduledStartTime}
                      </div>
                    </td>
                  );
                }}
              />
            </Grid>

            <div className="d-flex justify-content-center align-items-center bz-services-showMore">
              <div ref={showMoreRef}>
                {pageLoading ? (
                  <>
                    <Loader type="converging-spinner" />
                  </>
                ) : (
                  <>
                    {dataList?.pageNumber + 1 < dataList?.maxPageNumber && (
                      <span
                        className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                        onClick={() => handlePageChange()}
                      >
                        View More <i className="fas fa-caret-down"></i>
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SimpleAccessCheckIns;
