import BuzopsButton from "generic-components/BuzopsButton";
import moment from "moment";
import React from "react";
import { getDateFormatWithDay, getFullDate } from "utils";
import { PrivateServiceTypeEnum } from "utils/form-utils";

interface ISessionSignoffSelectedSlots {
    mainState?: any;
    schedulerCalendarState?: any;
    refs?: any;
    callBacks?: any;
    checkoutPage: boolean;
}
const compareDates = (a: any, b: any) => {
    // Compare dates first
    if (a.date < b.date) return -1;
    if (a.date > b.date) return 1;
    // If dates are equal, compare times
    if (a.time < b.time) return -1;
    if (a.time > b.time) return 1;
    return 0;
};


const SessionSignoffSelectedSlots = ({
    callBacks,
    mainState,
    schedulerCalendarState,
    refs,
    checkoutPage,
}: ISessionSignoffSelectedSlots) => {

console.log('schedulerCalendarState?.selectedSchItems',schedulerCalendarState?.selectedSchItems);

    const selectedSlotListforQuickCheckout = () => {
        return schedulerCalendarState?.selectedSchItems?.map((item: any, index: number) => {
            const className = item.IsAvailable ? "available" : "not-available";
            let description = "Available";
            if (!item.IsAvailable) {
                description = item.Description;
            }
            const notes = item?.notes;
            return (
                <div className="card bz-shadow bz-border-blue bz-upcoming-apponments-sm-card mb-3">
                    <div className="card-body py-3">
                        <div className="media pr-4">
                            <div className="media-body">
                                <div style={{ lineHeight: 1.2 }}>
                                    <div className="mb-1">{item?.provider?.FullName}</div>
                                    <span className="bz-fw-600 mb-0">
                                        {" "}
                                        {moment(item?.date).format("MM/DD/YYYY")}{" "}
                                        {moment(item?.time,"hh:mm A").format("h:mm A")}
                                    </span>
                                    <div className="mt-0 mb-0">
                                        <small className={``}>{notes}</small>
                                    </div>
                                    {item?.BillingPeriodFrom && <div className="mt-0 mb-0">
                                        <small className={``}>{`Billing Period : ${moment(item?.BillingPeriodFrom).format("MM/DD/YYYY")} - ${moment(item?.BillingPeriodTo).format("MM/DD/YYYY")}`}</small>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <span
                            className="bz-close-icon"
                            role={"button"}
                            onClick={() => callBacks?.handleDeleteSlotforQC(item)}
                        >
                            <i className="far fa-trash-alt"></i>
                        </span>
                    </div>
                </div>
            );
        });
    };



    return (
        <>
        
            <div className={"selected-slot-header"}>
                <div>
                    {" "}
                    <h5 className="card-title mb-0  bz-fw-600">Selected Slots</h5>{" "}
                    <span className="small text-muted">
                        Number of selected slots: &nbsp;
                        {
                            schedulerCalendarState?.selectedSchItems &&
                            schedulerCalendarState?.selectedSchItems.length}
                    </span>
                </div>
                <div>
                    {" "}
                    {!schedulerCalendarState?.checkout && (
                        <span
                            className=" "
                            role={"button"}
                            title={"Clear All"}
                            onClick={() => callBacks?.handleClearAllSlots()}
                        >
                            <i
                                className="far fa-trash-alt"
                                style={{ fontSize: "1.2rem" }}
                            ></i>
                        </span>
                    )}
                </div>
            </div>
            <hr className="hrbreak" />
            <div className="bz-selected-appoinment-container" ref={refs.slotRef}>

                {schedulerCalendarState?.selectedSchItems &&
                    selectedSlotListforQuickCheckout()}
            </div>
            {!checkoutPage  && (
                <div className={"selected-slot-footer pt-2 text-right"}>
                    <BuzopsButton
                        disable={schedulerCalendarState?.btnLoading}
                        onClick={(schedulerCalendarState?.availExistingSch.length > 0 || mainState.Package || mainState?.Appointment?.PrivateServiceType === PrivateServiceTypeEnum.PrivateUnpaid || mainState?.Appointment?.PrivateServiceType === PrivateServiceTypeEnum.SemiPrivateUnpaid) ?  callBacks?.handleUpdate : callBacks?.onSubmit}
                        label={(schedulerCalendarState?.availExistingSch.length > 0 || mainState.Package || mainState?.Appointment?.PrivateServiceType === PrivateServiceTypeEnum.PrivateUnpaid || mainState?.Appointment?.PrivateServiceType === PrivateServiceTypeEnum.SemiPrivateUnpaid)  ?   "Sign off Appt" : "Add to Checkout"}
                        className="mr-0"
                        loading={schedulerCalendarState?.btnLoading}
                    />
                </div>
            )}
           
        </>
    );
};

export default SessionSignoffSelectedSlots;
