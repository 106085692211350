import { CommonServiceUtils } from "services/common-service-utils";
import {
  GetLocalStore,
  LocalStore,
  RemoveAllLocalStore,
  RemoveLocalStore,
} from "utils/storage";
import TimeZoneSetup from "utils/time-zone-setup";

export class ReportsService extends CommonServiceUtils {
  /**
   * class will be created with provided data
   * @param data
   *
   */
  async getTenantDetails(tenant: string): Promise<any> {
    const getToken: any = await this.postData(
      `${this.apiUrlCreator(`authentication/issuetoken/${tenant}`)}`
    );
    if (tenant !== GetLocalStore("tenant")) {
      RemoveAllLocalStore();
    }
    RemoveLocalStore("tenant");
    if (getToken?.ResponseCode === 100) {
      LocalStore("tenant", tenant);
      LocalStore("token", getToken.Item.Token);
      LocalStore("tenantUserSession", {...getToken?.Item?.UserSession, DomainName: getToken.Item.DomainName, StripePaymentConfiguration: getToken.Item.StripePaymentConfiguration, ChargehubSquarePaymentProfile: getToken.Item.ChargehubSquarePaymentProfile,Configuration: getToken.Item.Configuration});
      LocalStore("Configuration", {...getToken.Item.Configuration,IsAffiliateFeatureEnabled:getToken.Item.IsAffiliateFeatureEnabled});
      TimeZoneSetup(
        getToken?.Item?.UserSession?.TenantTime,
        getToken?.Item.UserSession
      );
    }
    return getToken;
  }

  async getInvoiceForDownloadPDF(
    ClubId: string,
    transactionId: string
  ): Promise<any> {
    const invoice: any = await this.getData(
      this.apiUrlCreator(
        `site/DownloadInvoice?clubId=${ClubId}&transactionId=${transactionId}`
      ),
      {
        responseType: "blob",
      }
    );
    return invoice;
  }

  // Subscribes
  async getSubscribersReport(req: any): Promise<any> {
    const getSubscribersReport: any = await this.postData(
      `${this.apiUrlCreator(`report/Get`)}`,
      req
    );
    let response = {};
    if (getSubscribersReport?.ResponseCode === 100) {
      response = getSubscribersReport.Item;
    }
    return response;
  }

  // Subscribers count
  async getSubscribersCountReport(data: any): Promise<any> {
    const getSubscribersCountReport: any = await this.postData(
      `${this.apiUrlCreator(`report/GetCountByStatus`)}`,
      data
    );
    let response = {};
    if (getSubscribersCountReport?.ResponseCode === 100) {
      response = getSubscribersCountReport.Item;
    }
    return response;
  }

  // Sales
  async getSalesReport(req: any, type: string): Promise<any> {
    let getSalesReport: any;
    if (type === "transaction" || type === "sales") {
      getSalesReport = await this.postData(
        `${this.apiUrlCreator(
          `Report/${type === "transaction" ? "Transactions" : "Sales"}`
        )}`,
        req
      );
    } else if (type === "revenue") {
      if (req.IsChartRendering) {
        getSalesReport = await this.postData(
          `${this.apiUrlCreator(`report/revenue/GetOverAll`)}`,
          req
        );
      } else {
        getSalesReport = await this.postData(
          `${this.apiUrlCreator(`report/revenue/getDrillDownInformation`)}`,
          req
        );
      }
    }
    let response = [];
    if (getSalesReport?.ResponseCode === 100) {
      response = getSalesReport;
    }
    return response;
  }
  // Transaction
  // async getTransactionData(req: any): Promise<any> {
  //   const getTransaction: any = await this.postData(
  //     `${this.apiUrlCreator(`report/Transactions`)}`,
  //     req
  //   );
  //   let response = {};
  //   if (getTransaction?.ResponseCode === 100) {
  //     response = getTransaction.Item;
  //   }
  //   return response;
  // }

  // Customers
  async getAllCustomers(req: any): Promise<any> {
    const getCustomer: any = await this.getData(
      `${this.apiUrlCreator(`Report/Customers`)}?pageSize=10&pageNumber=${
        req.pageNumber
      }`,
      req
    );
    // https://uatapi.buzops.com/api/Report/Customers?pageSize=10&pageNumber=1
    let response = [];
    if (getCustomer?.ResponseCode === 100) {
      response = getCustomer;
    }
    return response;
  }

  async getAllPayoutsHistory(req: any): Promise<any> {
    const getData: any = await this.postData(
      `${this.apiUrlCreator(`report/payouthistory/SearchTrainerPayouts`)}`,
      req
    );
    let response = [];
    if (getData?.ResponseCode === 100) {
      response = getData;
    }
    return response;
  }
  async getServiceLog(req: any): Promise<any> {
    const getData: any = await this.postData(
      `${this.apiUrlCreator(`report/attendance/Get`)}`,
      req
    );
    let response = [];
    if (getData?.ResponseCode === 100) {
      response = getData;
    }
    return response;
  }
  async getMRRData(req: any): Promise<any> {
    const getData: any = await this.postData(
      `${this.apiUrlCreator(`report/recurringrevenue/SearchEFTHistory`)}`,
      req
    );
    let response = [];
    if (getData?.ResponseCode === 100) {
      response = getData;
    }
    return response;
  }
  async getUsersForAccessLog(req?: any): Promise<any> {
    const getData: any = await this.postData(
      `${this.apiUrlCreator(`report/accesscard/SearchUser`)}`,
      req
    );
    let response = [];
    if (getData?.ResponseCode === 100) {
      response = getData;
    }
    return response;
  }
  async getClubsForAccessLog(req?: any): Promise<any> {
    const getData: any = await this.postData(
      `${this.apiUrlCreator(`report/accesscard/GetClubAccessPoints`)}`,
      req
    );
    let response = [];
    if (getData?.ResponseCode === 100) {
      response = getData;
    }
    return response;
  }

  async getAccessLog(req?: any): Promise<any> {
    const getData: any = await this.postData(
      `${this.apiUrlCreator(`report/accesscard/SearchAccessEvents`)}`,
      req
    );
    let response = [];
    if (getData?.ResponseCode === 100) {
      response = getData;
    }
    return response;
  }
  async getSubscriberStatistics(req?: any): Promise<any> {
    const getData: any = await this.postData(
      `${this.apiUrlCreator(`report/memberstatistics/SearchMemberStatistics`)}`,
      req
    );
    let response = [];
    if (getData?.ResponseCode === 100) {
      response = getData;
    }
    return response;
  }
  async getAuditDetails(req?: any): Promise<any> {
    const getData: any = await this.postData(
      `${this.apiUrlCreator(
        `report/memberstatistics/SearchMemberStatisticsByStatus`
      )}`,
      req
    );
    let response = [];
    if (getData?.ResponseCode === 100) {
      response = getData;
    }
    return response;
  }
}
